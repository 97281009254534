import React from "react";
import { IIcons } from "./icons";

const Dashboard = ({ fillColor }: IIcons.IconProps) => {
  return (
    <svg
      width="20"
      height="20"
      viewBox="0 0 20 20"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      className={fillColor}
    >
      <path
        d="M10 0.600006C9.79092 0.60002 9.59013 0.681906 9.44065 0.828131L0.714087 7.96563C0.704008 7.97321 0.694109 7.98102 0.684399 7.98907L0.654712 8.01407V8.01563C0.574474 8.09038 0.510462 8.18083 0.466649 8.28135C0.422836 8.38188 0.400158 8.49035 0.400024 8.60001C0.400024 8.81218 0.48431 9.01566 0.634339 9.16569C0.784368 9.31572 0.987851 9.40001 1.20002 9.40001H2.00002V18.2C2.00002 19.084 2.71602 19.8 3.60002 19.8H16.4C17.284 19.8 18 19.084 18 18.2V9.40001H18.8C19.0122 9.40001 19.2157 9.31572 19.3657 9.16569C19.5157 9.01566 19.6 8.81218 19.6 8.60001C19.6001 8.49008 19.5775 8.38132 19.5337 8.28051C19.4899 8.1797 19.4258 8.089 19.3453 8.01407L19.3328 8.00469C19.3153 7.98879 19.297 7.97367 19.2781 7.95938L18 6.91407V3.80001C18 3.35841 17.6416 3.00001 17.2 3.00001H16.4C15.9584 3.00001 15.6 3.35841 15.6 3.80001V4.95157L10.5422 0.814069C10.3948 0.677067 10.2012 0.600634 10 0.600006ZM12.4 11H15.6V17.4H12.4V11Z"
        fill="white"
      />
    </svg>
  );
};

export default Dashboard;
