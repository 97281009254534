/* eslint-disable */
import React, { useState } from "react";
import { CloseIcon } from "../../../../assets/Icons";
import Button from "../../../../components/ui/Button";
import Modal from "../../../../components/ui/Modal";
import clsx from "clsx";

type AccountTypeModalProps = {
  options: string[];
  showAccountTypeModal?: any;
  setshowAccountTypeModal?: any;
};

const AccountTypeModal: React.FC<AccountTypeModalProps> = ({
  options,
  showAccountTypeModal,
  setshowAccountTypeModal,
}) => {
  const [selectedOption, setSelectedOption] = useState<string>("");

  const handleOptionChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setSelectedOption(event.target.value);
  };

  const handleConfirm = () => {
    // Do something with the selected option
  };

  return (
    <div>
      <Modal
        header="Sending Money"
        visible={showAccountTypeModal}
        closeButtonIcon={<CloseIcon fill="white" />}
        closeHandler={() => setshowAccountTypeModal(false)}
        modalClass="md:h-[700px]  mt-0 ml-0 mr-0 pt-0 pl-0 pr-0 rounded-lg"
        headerClass="w-full md:w-[478px] bg-primary mt-0 ml-0 mr-0 pt-0 pl-4 pr-4 h-[76px] rounded-t-lg text-white font-bold colour-white"
      >
        <div className="flex flex-col items-center text-center ">
          {options.map((option) => (
            <label key={option} className="flex items-center my-2">
              <input
                type="checkbox"
                value={option}
                checked={selectedOption === option}
                onChange={handleOptionChange}
                className="mr-2"
              />
              <span>{option}</span>
            </label>
          ))}
          <Button
            bgColor={clsx(selectedOption !== "" ? "bg-primary" : "bg-[#9A9A9A]")}
            className="rounded-full w-[305px] h-[50px]  text-white mt-2"
            onClickHandler={handleConfirm}
          >
            Confirm
          </Button>
        </div>
      </Modal>
    </div>
  );
};

export default AccountTypeModal;
