import clsx from "clsx";
import mixpanel from "mixpanel-browser";
import React from "react";
import { useNavigate } from "react-router-dom";

import { CloseIcon, LeftArrowIcon } from "../../assets/Icons";
import Button from "../../components/ui/Button";
import Stepper from "../../components/ui/Stepper";
import IReviewPage from "./review";

const GoBackandStepperSection: React.FC<IReviewPage.GobackandStepperSectionProps> = ({
  setIsModalOpen,
  stepperSteps,
}) => {
  const navigate = useNavigate();
  const mixpanelToken: any = process.env.REACT_APP_MIXPANEL_TOKEN;
  mixpanel.init(mixpanelToken, {
    debug: true,
    api_host:process.env.REACT_APP_MIXPANEL_API,
  });
  return (
    <div>
      <div className="flex justify-between items-center bg-white md:hidden w-full py-[25px] pr-[22px] pl-7 mb-6">
        <div className="flex items-center w-fit" onClick={() => history.back()}>
          <LeftArrowIcon />
          <div className="ml-6 text-xl font-semibold text-grey-1100">Review Page</div>
        </div>
        <div className="flex items-center">
          <div
            className="mr-3 text-sm font-normal text-primary"
            onClick={() => navigate("/support")}
          >
            Need help ?
          </div>
          <div
            className="bg-gray-400 w-[30px] h-[30px] flex justify-center items-center rounded-full"
            onClick={() => setIsModalOpen("cancel")}
          >
            <CloseIcon />
          </div>
        </div>
      </div>
      <div className={clsx("hidden lg:flex justify-between items-center ml-10 mr-[69px]")}>
        <Button
          id="fo-baxk0-inhg"
          bgColor="bg-primary-450"
          fontStyle={{
            fontFamily: "",
            fontSize: "text-sm",
            fontWeight: "font-medium",
            fontColor: "text-primary",
          }}
          borderStyle={{
            borderRadius: "rounded",
          }}
          className="py-2 px-[18px]"
          onClickHandler={() => history.back()}
        >
          {"<< Go Back"}
        </Button>
        {/* <Button
          id="dfhjhjdaj"
          bgColor="bg-red-150"
          fontStyle={{
            fontFamily: "",
            fontSize: "text-sm",
            fontWeight: "font-medium",
            fontColor: "text-error",
          }}
          borderStyle={{
            borderRadius: "rounded",
          }}
          className="py-2 px-[18px]"
          onClickHandler={() => {
            setIsModalOpen("cancel");
            mixpanel.track("Cancel Transaction Clicked");
          }}
        >
          Cancel transaction
        </Button> */}
        <div
          onClick={() => {
            setIsModalOpen("cancelReasons");
            mixpanel.track("Cancel Transaction Clicked");
          }}
          className="cursor-pointer hover:scale-125 ttransition-all"
        >
          <CloseIcon fill="fill-grey-200" />
        </div>
      </div>
      <div className={clsx("hidden lg:flex justify-center items-center mt-[59px] mb-24")}>
        <div className={clsx("w-full px-[235px]")}>
          <Stepper numberOfSteps={5} steps={stepperSteps} activeStep={4} />
        </div>
      </div>
    </div>
  );
};

export default GoBackandStepperSection;
