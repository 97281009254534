import React from "react";

const UfoIcon = () => {
  return (
    <svg
      width="297"
      height="296"
      viewBox="0 0 297 296"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g clipPath="url(#clip0_2450_4867)">
        <path
          opacity="0.3"
          d="M296.865 281.976C296.865 289.142 255.043 295.052 201.056 295.897C187.043 296.116 172.693 295.993 158.876 295.506C112.594 293.881 78.5723 288.44 78.5723 281.976C78.5723 274.23 127.44 267.949 187.72 267.949C248 267.949 296.867 274.23 296.867 281.976H296.865Z"
          fill="#757575"
        />
        <path
          d="M168.846 277.556C169.958 277.538 170.509 277.53 171.606 277.513C177.177 277.33 182.725 278.406 188.218 279.158C192.214 279.658 196.279 280.051 200.307 280.021C204.342 279.991 206.95 279.547 206.431 278.8C205.344 277.232 202.817 275.712 200.991 274.214C204.671 273.206 208.283 272.077 211.89 270.828C215.046 273.78 220.551 276.906 221.664 279.284C223.324 282.833 210.767 289.099 206.562 289.132C198.892 289.189 191.805 284.709 184.283 282.143C179.772 280.606 174.717 280.465 169.948 280.548C169.126 280.553 168.716 280.556 167.894 280.561C168.227 279.362 168.3 279.806 168.846 277.556ZM153.964 276.229C156.479 276.316 163.368 278.24 163.067 279.347C162.706 280.681 154.661 281.17 151.484 281.185C149.255 281.198 141.022 280.134 141.094 279.469C141.229 278.248 150.262 276.098 153.962 276.226L153.964 276.229Z"
          fill="black"
        />
        <path
          d="M140.903 88.2075C177.179 82.7395 203.657 58.8126 200.043 34.7654C196.428 10.7181 164.09 -4.34333 127.813 1.12468C91.5368 6.59269 65.0591 30.5195 68.6737 54.5668C72.2883 78.6141 104.626 93.6755 140.903 88.2075Z"
          fill="#0F5BF1"
        />
        <path
          d="M139.775 80.7082C169.802 76.1822 191.719 56.3771 188.727 36.4724C185.735 16.5678 158.967 4.10096 128.94 8.62705C98.9124 13.1531 76.9957 32.9582 79.9877 52.8629C82.9796 72.7675 109.747 85.2343 139.775 80.7082Z"
          fill="#6E9FFF"
        />
        <path
          d="M147.208 13.9834C153.533 13.9447 158.642 10.9575 158.62 7.3114C158.598 3.66527 153.453 0.740864 147.128 0.779554C140.803 0.818245 135.694 3.80538 135.716 7.45151C135.738 11.0976 140.884 14.022 147.208 13.9834Z"
          fill="#0F5BF1"
        />
        <g opacity="0.5">
          <path
            d="M91.5426 21.584C87.65 24.8975 83.8201 29.1743 80.3809 34.7053C80.3809 34.7053 84.7819 32.9218 89.8242 32.4176C89.8242 32.4176 92.8952 28.1158 98.6063 23.1066L91.5426 21.584Z"
            fill="white"
          />
          <path
            d="M102.924 19.6115C107.706 16.0472 113.705 12.5329 120.801 10.2679C120.801 10.2679 109.033 9.66084 96.4863 17.8783L102.924 19.6115Z"
            fill="white"
          />
        </g>
        <path
          d="M254.404 39.3465C255.318 45.4218 249.534 52.079 238.789 58.5907C238.04 59.0447 237.273 59.4912 236.474 59.9452C216.425 71.3282 181.449 82.0891 140.908 88.202C100.367 94.3149 63.7778 94.3425 41.269 89.3759C40.3722 89.1777 39.508 88.9771 38.6589 88.7639C26.4727 85.7087 18.9906 81.0506 18.0763 74.9754C16.4106 63.9009 37.0432 50.8449 69.4538 40.3423C70.6537 39.9485 71.8811 39.5672 73.1185 39.186C90.104 33.9159 110.055 29.3657 131.575 26.1199C153.094 22.8765 173.501 21.3414 191.284 21.3715C192.576 21.3715 193.864 21.374 195.126 21.3966C229.187 21.8782 252.743 28.272 254.409 39.3465H254.404Z"
          fill="#DFC9BE"
        />
        <path
          d="M73.1133 39.1855C62.7782 56.0919 52.165 72.8278 41.2663 89.3755C40.3696 89.1773 39.5054 88.9767 38.6562 88.7634C49.1742 72.7927 59.4442 56.6463 69.4512 40.3444C70.651 39.9506 71.8784 39.5693 73.1158 39.1881L73.1133 39.1855Z"
          fill="#0F102E"
        />
        <path
          d="M238.787 58.5903C238.038 59.0443 237.271 59.4908 236.472 59.9448C221.193 47.3427 206.126 34.4798 191.279 21.3711C192.572 21.3711 193.859 21.3736 195.122 21.3962C209.48 34.0258 224.041 46.4297 238.787 58.5903Z"
          fill="#0F102E"
        />
        <path
          d="M140.903 88.2091C216.386 76.8314 275.713 55.2023 273.412 39.8991C271.112 24.5959 208.056 21.4137 132.573 32.7914C57.0897 44.1691 -2.23684 65.7982 0.0634163 81.1014C2.36367 96.4046 65.4196 99.5868 140.903 88.2091Z"
          fill="#7D716A"
        />
        <path
          d="M140.9 88.2074C216.384 76.8297 275.802 55.8091 273.614 41.2566C271.427 26.7042 208.462 24.1305 132.979 35.5082C57.4959 46.8859 -1.92206 67.9065 0.265351 82.4589C2.45277 97.0114 65.4173 99.5851 140.9 88.2074Z"
          fill="#E6E6E6"
        />
        <path
          d="M140.902 88.2059C196.498 79.8257 240.236 64.1713 238.593 53.2409C236.95 42.3105 190.549 40.2431 134.952 48.6232C79.3552 57.0034 35.6171 72.6578 37.26 83.5882C38.903 94.5186 85.3049 96.586 140.902 88.2059Z"
          fill="#D6D6D6"
        />
        <path
          d="M193.053 62.6146C194.494 72.189 171.243 83.6272 141.127 88.1673C111.011 92.7075 85.4313 88.6314 83.991 79.0544C83.6252 76.6213 84.8576 74.0653 87.3876 71.5293C94.8145 64.1046 113.456 56.8779 135.915 53.4916C158.373 50.1053 178.312 51.5151 187.595 56.4214C190.759 58.0995 192.688 60.179 193.053 62.6121V62.6146Z"
          fill="#151740"
        />
        <path
          d="M187.596 56.4234C180.169 63.8582 161.531 71.0823 139.069 74.4686C116.611 77.8549 96.6717 76.4452 87.3887 71.5313C94.8156 64.1065 113.457 56.8799 135.916 53.4936C158.374 50.1073 178.313 51.517 187.596 56.4234Z"
          fill="#0F102E"
        />
        <path
          opacity="0.2"
          d="M193.053 62.6133C194.493 72.1877 171.243 83.6259 141.126 88.166C111.01 92.7062 85.4305 88.6301 83.9902 79.0556L90.8586 264.833L284.909 281.009L193.053 62.6133Z"
          fill="url(#paint0_linear_2450_4867)"
        />
      </g>
      <defs>
        <linearGradient
          id="paint0_linear_2450_4867"
          x1="176.797"
          y1="272.002"
          x2="194.119"
          y2="64.2692"
          gradientUnits="userSpaceOnUse"
        >
          <stop offset="0.08" stopColor="#00FFFF" stopOpacity="0" />
          <stop offset="0.16" stopColor="#10FFFF" stopOpacity="0.12" />
          <stop offset="0.26" stopColor="#21FFFF" stopOpacity="0.26" />
          <stop offset="0.37" stopColor="#2FFFFF" stopOpacity="0.36" />
          <stop offset="0.48" stopColor="#39FFFF" stopOpacity="0.44" />
          <stop offset="0.59" stopColor="#3FFFFF" stopOpacity="0.49" />
          <stop offset="0.7" stopColor="#41FFFF" stopOpacity="0.5" />
          <stop offset="0.71" stopColor="#3EFFFF" stopOpacity="0.52" />
          <stop offset="0.85" stopColor="#1CFFFF" stopOpacity="0.78" />
          <stop offset="0.95" stopColor="#08FFFF" stopOpacity="0.94" />
          <stop offset="1" stopColor="#00FFFF" />
        </linearGradient>
        <clipPath id="clip0_2450_4867">
          <rect width="296.866" height="296" fill="white" />
        </clipPath>
      </defs>
    </svg>
  );
};

export default UfoIcon;
