/**
 * @description Component to use OTP boxes
 * @param {number} numberOfBoxes - How many OTP box are needed
 * @param {function} updateValue -  Update OTP value in the caller component to use it anywhere else
 * @param {string} errorMsg - What error message to be printed when needed
 * @param {boolean } disabled - Is the input disabled
 * @param {function} onBlur - Method to handle when onBlur event happens
 * @param {string} otpBoxClassName - Styings to be applied to individual otp box
 * @param {string} otpBoxGroupClassName - Styings to be applied to otp box group
 * @param {string} inputType - What is the input type of the OTP box
 * @param {string} errorMessageClassname - Styings to be applied to error message div
 * @param {string[]} id - ID passed to the input element.
 * @returns {React.FunctionComponent<IOtpBox.IProps>} - Returns JSX for OTP box
 */

import clsx from "clsx";
import React, { useState, useEffect } from "react";
import {
  BACKSPACE_KEY,
  DELETE_KEY,
  LEFT_ARROW_KEY,
  RIGHT_ARROW_KEY,
  SPACEBAR_KEY,
} from "../../../constants/KeyboardKeys";
import ErrorField from "../ErrorField";
import { IOtpBox } from "./OtpBox";
import SingleOtpBox from "./SingleOtpBox";

const INPUT_TYPE_TEL = "tel";

const OtpBox: React.FunctionComponent<IOtpBox.IProps> = ({
  numberOfBoxes = 4,
  updateValue,
  errorMsg,
  disabled = false,
  onBlur,
  otpBoxClassName = "",
  otpBoxGroupClassName = "",
  errorMessageClassname = "",
  id = [],
  inputType = INPUT_TYPE_TEL,
}) => {
  const [value, setValue] = useState<string[]>(Array(numberOfBoxes).fill(""));
  const [activeInput, setActiveInput] = useState<number>(0);

  useEffect(() => {

    const firstEmptyIndex = value.indexOf("");
    setActiveInput(firstEmptyIndex !== -1 ? firstEmptyIndex : value.length - 1);
  }, [value]);


  const isInputValueValid = (value: string) => {
    const isTypeValid = inputType === INPUT_TYPE_TEL ? !isNaN(parseInt(value, 10)) : typeof value === "string";
    return isTypeValid && value.trim().length === 1;
  };

  const changeCodeAtFocus = (newValue: string, inputIndex: number = activeInput) => {
    const otp = [...value];
    otp[inputIndex] = newValue; // Update the specific box
    setValue(otp);
    updateValue(otp.join(""));
  };

  const focusNextInput = () => {
    if (value[activeInput].trim() !== "") {
      const nextInput = activeInput + 1;
      if (nextInput < numberOfBoxes) {
        setActiveInput(nextInput);
      }
    }
  };
  const handleOnFocus = (index: number) => (e: React.FocusEvent<HTMLInputElement>) => {
    if (index === 0 || (index > 0 && value[index - 1].trim() !== "")) {
      e.target.select();
      setActiveInput(index);
    }
  };

  const focusPrevInput = () => {
    if (activeInput > 0) {
      setActiveInput(activeInput - 1);
    }
  };

  const onChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const { value: inputValue } = e.target;
    if (isInputValueValid(inputValue)) {
      changeCodeAtFocus(inputValue);
      focusNextInput();
    }
  };

  const handleOnKeyDown = (e: React.KeyboardEvent<HTMLInputElement>) => {
    switch (e.key) {
      case BACKSPACE_KEY.name:
      case "Backspace":
        e.preventDefault();
        if (value[activeInput] === "") {
          // Move focus to the previous box if the current box is already empty
          const previousInput = activeInput - 1;
          if (previousInput >= 0) {
            setActiveInput(previousInput);
            changeCodeAtFocus("", previousInput);  // Clear the previous input
          }
        } else {
          // If current box is not empty, clear it
          changeCodeAtFocus("");
        }
        break;
      case DELETE_KEY.name:
      case "Delete":
        e.preventDefault();
        changeCodeAtFocus("");
        break;
      case LEFT_ARROW_KEY.name:
      case "ArrowLeft":
        e.preventDefault();
        focusPrevInput();
        break;
      case RIGHT_ARROW_KEY.name:
      case "ArrowRight":
        e.preventDefault();
        focusNextInput();
        break;
      case SPACEBAR_KEY.name:
      case " ":
        e.preventDefault();
        break;
      default:
        break;
    }
  };

  const handleOnPaste = (e: React.ClipboardEvent<HTMLInputElement>) => {
    e.preventDefault();
    if (disabled) return;
    const pastedData = e.clipboardData.getData("text/plain").split("").filter(isInputValueValid);
    if (pastedData.length > 0) {
      const newValue = [...value];
      let nextActiveInput = activeInput;
      for (let i = 0; i < pastedData.length && nextActiveInput < numberOfBoxes; i++, nextActiveInput++) {
        newValue[nextActiveInput] = pastedData[i];
      }
      setValue(newValue);
      updateValue(newValue.join(""));
      setActiveInput(Math.min(nextActiveInput, numberOfBoxes - 1));
    }
  };

  return (
    <div className={clsx("flex flex-col w-fit", otpBoxGroupClassName)}>
      <div onBlur={onBlur}>
        {value.map((val, index) => (
          <SingleOtpBox
            inputType={inputType}
            value={val}
            id={id[index]}
            otpBoxClassName={otpBoxClassName}
            key={index}
            focus={activeInput === index}
            handleOnChange={onChange}
            handleOnFocus={handleOnFocus(index)}
            handleOnKeyDown={handleOnKeyDown}
            handleOnPaste={handleOnPaste}
            error={errorMsg !== ""}
            disabled={disabled}
          />
        ))}
      </div>
      {errorMsg && (
        <ErrorField centerAligned errorClassname={errorMessageClassname} errorMessage={errorMsg} />
      )}
    </div>
  );
};

export default OtpBox;
