import { FunctionComponent } from "react";
import { SendButton } from "../../../assets/Icons";

export const AnimatedButton: FunctionComponent<{ text: string; onClick: any }> = ({
  text,
  onClick,
}) => {
  return (
    <button
      onClick={onClick}
      className="relative inline-flex w-[260px] lg:w-[220px] items-center justify-center py-3 pl-4 pr-12 overflow-hidden rounded font-semibold text-blue-800 transition-all duration-150 ease-in-out cursor-pointer hover:pl-10 hover:pr-6 bg-gray-50 group"
    >
      <span className="absolute bottom-0 left-0 w-full transition-all duration-150 ease-in-out bg-blue-800 group-hover:h-full"></span>
      <span className="absolute right-0 pr-4 duration-200 ease-out group-hover:translate-x-12">
        <SendButton fillColor="rgb(30 64 175 / var(--tw-bg-opacity))" />
      </span>
      <span className="absolute left-0 pl-2.5 -translate-x-12 group-hover:translate-x-0 ease-out duration-200 ml-8">
        <SendButton fillColor="white" />
      </span>
      <span className="relative w-full text-left transition-colors duration-200 ease-in-out group-hover:text-white">
        <span className="ml-11 lg:text-[16px]">{text}</span>
      </span>
    </button>
  );
};
