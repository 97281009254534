import { BluePentagon } from "../../assets/Icons";

export const PrimeFeatures = () => {
  return (
    <div className="w-auto p-4 bg-blue-100 border border-blue-200 rounded-lg">
      <div id="regularTitle" className="flex flex-col">
        <div className="relative">
          <BluePentagon />
          <div className="absolute font-bold text-white text-[28px] -mt-20 ml-16">Prime</div>
        </div>
      </div>
      <div id="features" className="flex flex-col items-start space-y-3 mt-7">
        <span className="font-bold text-[#003399] lg:text-[20px]">Features Included</span>
        <span className="font-semibold lg:text-[16px] font-sans">Real Time Rates</span>
        <span className="font-semibold lg:text-[16px] font-sans">100% Digital</span>
        <span className="font-semibold lg:text-[16px] font-sans text-primary-200">
          T + 1 Settlement
        </span>
        <span className="font-semibold lg:text-[16px] font-sans text-primary-200">
          Priority Support
        </span>
        <span className="font-semibold lg:text-[16px] font-sans text-primary-200">
          Zero Transfer Fees
        </span>
        <span className="font-semibold lg:text-[16px] font-sans text-primary-200">
          Zero Bank Fees
        </span>
        <span className="font-semibold lg:text-[16px] font-sans text-primary-200">
          Zero NOSTRO Charges
        </span>
        <span className="font-semibold lg:text-[16px] font-sans text-primary-200">
          Multicurrency Bank Account
        </span>
      </div>
    </div>
  );
};
