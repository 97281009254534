//#Global Imports
import React from "react";
import clsx from "clsx";
//#end Global Imports

//#Local Imports
import ErrorField from "../../../../components/ui/ErrorField";
import TextField from "../../../../components/ui/TextField";
import Button from "../../../../components/ui/Button";
import Tooltip from "../../../../components/ui/Tooltip";
import IUserDocuments from "../../userDocuments";
import {
  InfoIcon,
  PassportIcon,
  ReUploadIcon,
  UploadIcon,
  VisibilityOnIcon,
} from "../../../../assets/Icons";
import mixpanel from "mixpanel-browser";
//#end Local Imports

const VoterIdCardSection: React.FC<IUserDocuments.IVoterIdCardSectionProps> = ({
  voterIDCardDetails,
  handlePreviewUploadedDoc,
  handleDeleteDoc,
  handleChangeUpload,
  setVoterIDCardDetails,
  handleEVerifyVoterCard,
  isPDFfile,
  buttonLoader,
  loading
}) => {
  const mixpanelToken: any = process.env.REACT_APP_MIXPANEL_TOKEN;
  mixpanel.init(mixpanelToken, {
    debug: true,
    api_host: process.env.REACT_APP_MIXPANEL_API,
  });
  return (
    <div className="col-span-4 lg:pl-10 lg:pt-7">
      <div>
        <div className="flex items-start justify-between">
          <div className="mb-5 text-base font-medium text-black font-lato">
            Upload Voter ID<span className="text-error">*</span>
          </div>
          {voterIDCardDetails?.uploadedDocError === "Please upload the right file format.." && (
            <div className="relative cursor-pointer">
              <div className="peer">
                <InfoIcon fillColor="fill-secondary" />
              </div>
              <Tooltip
                title="Your file cant upload"
                subtitle="Try file formats like jpg, png or pdf"
                positionOfTip="bottom"
                tooltipStyles="peer-hover:block hidden !top-8 !min-h-[96px] !min-w-[236px] !-translate-x-full z-1"
                titleClasses="font-semibold text-lg text-white !pt-3"
                subtitleClasses="text-sm font-normal text-white"
                buttonClasses="!w-0 h-0"
              />
            </div>
          )}
        </div>
        <div className="relative">
          <div className="flex items-center space-x-8">
            <Button
              id="djak0bsa7BS700n-daj"
              bgColor="bg-primary-350"
              fontStyle={{
                fontFamily: "",
                fontSize: "text-sm",
                fontWeight: "font-normal ",
                fontColor: "text-black-200",
              }}
              borderStyle={{
                borderColor: voterIDCardDetails.uploadedDocError
                  ? "border-error border"
                  : voterIDCardDetails.isVoterFrontUploaded || voterIDCardDetails.isVIDocVerified
                    ? "border-success border"
                    : "border-none",
                borderRadius: "rounded",
              }}
              className={clsx(
                voterIDCardDetails.uploadedDocError ? "" : "mb-5",
                "w-full h-[68px] lg:!w-[48%]",
              )}
            >
              <div className="flex items-center justify-between w-full px-4 lg:px-6">
                <div className="flex items-center space-x-2 lg:space-x-6">
                  <PassportIcon
                    width="23px"
                    height="29px"
                    fillColor={
                      voterIDCardDetails.isVIDocVerified ? "fill-success-150" : "fill-primary"
                    }
                  />
                  <div>Upload Voter ID Front</div>
                </div>
                <div className="flex items-center space-x-4">
                  {voterIDCardDetails.isVoterFrontUploaded &&
                    !voterIDCardDetails.isVIDocVerified &&
                    !isPDFfile && (
                      <div
                        className="z-50 cursor-pointer"
                        onClick={() => {
                          handlePreviewUploadedDoc("VOTER_CARD_FRONT");
                          mixpanel.track("Upload Voter ID Card Clicked");
                        }}
                      >
                        <VisibilityOnIcon fillColor="fill-black-150" />
                      </div>
                    )}
                  {voterIDCardDetails.isVoterFrontUploaded &&
                    !voterIDCardDetails.isVIDocVerified &&
                    !voterIDCardDetails.isVoterBackUploaded && (
                      <div className="z-50 cursor-pointer" onClick={handleDeleteDoc}>
                        <ReUploadIcon
                          fillColor={
                            voterIDCardDetails?.uploadedDocError ? "fill-error" : "fill-black"
                          }
                        />
                      </div>
                    )}
                  {!voterIDCardDetails.isVoterFrontUploaded && (
                    <UploadIcon
                      fillColor={
                        voterIDCardDetails.isVIDocVerified ? "fill-success-150" : "fill-primary"
                      }
                    />
                  )}
                </div>
              </div>
            </Button>
          </div>
          {voterIDCardDetails.uploadedDocError !== "" && (
            <ErrorField errorMessage={voterIDCardDetails.uploadedDocError} errorClassname="mb-5" />
          )}
          <form encType="multipart/form-data" action="">
            <TextField
              accept="image/*,application/pdf"
              name="VOTER_ID_FRONT"
              type="file"
              rootClassName="absolute top-0 opacity-0 !w-full lg:!w-[48%]"
              disabled={voterIDCardDetails.isVoterFrontUploaded}
              onChange={(e) => {
                handleChangeUpload(e, "VOTER_CARD");
              }}
              onClick={(event) => {
                event.currentTarget.value = "";
              }}
            />
          </form>
        </div>
        <div className="relative">
          <div className="flex items-center space-x-8">
            <Button
              id="dsankjsnajk-sak"
              bgColor="bg-primary-350"
              fontStyle={{
                fontFamily: "",
                fontSize: "text-sm",
                fontWeight: "font-normal ",
                fontColor: "text-black-200",
              }}
              borderStyle={{
                borderColor:
                  voterIDCardDetails.isVoterBackUploaded || voterIDCardDetails.isVIDocVerified
                    ? "border-success border"
                    : "border-none",
                borderRadius: "rounded",
              }}
              className="w-full mb-5 h-[68px] lg:!w-[48%]"
            >
              <div className="flex items-center justify-between w-full px-4 lg:px-6">
                <div className="flex items-center space-x-2 lg:space-x-6">
                  <PassportIcon
                    width="23px"
                    height="29px"
                    fillColor={
                      voterIDCardDetails.isVIDocVerified ? "fill-success-150" : "fill-primary"
                    }
                  />
                  <div>Upload Voter ID Back</div>
                </div>
                <div className="flex items-center space-x-4">
                  {voterIDCardDetails.isVoterBackUploaded &&
                    !voterIDCardDetails.isVIDocVerified &&
                    !isPDFfile && (
                      <div
                        className="z-50 cursor-pointer"
                        onClick={() => handlePreviewUploadedDoc("VOTER_CARD_BACK")}
                      >
                        <VisibilityOnIcon fillColor="fill-black-150" />
                      </div>
                    )}
                  {voterIDCardDetails.isVoterBackUploaded &&
                    !voterIDCardDetails.isVIDocVerified && (
                      <div className="z-50 cursor-pointer" onClick={handleDeleteDoc}>
                        <ReUploadIcon
                          fillColor={
                            voterIDCardDetails?.uploadedDocError ? "fill-error" : "fill-black"
                          }
                        />
                      </div>
                    )}
                  {!voterIDCardDetails.isVoterBackUploaded && (
                    <UploadIcon
                      fillColor={
                        voterIDCardDetails.isVIDocVerified ? "fill-success-150" : "fill-primary"
                      }
                    />
                  )}
                </div>
              </div>
            </Button>
          </div>
          <form encType="multipart/form-data" action="">
            <TextField
              accept="image/*,application/pdf"
              name="VOTER_ID_BACK"
              type="file"
              rootClassName="absolute top-0 opacity-0 !w-full lg:!w-[48%]"
              disabled={
                voterIDCardDetails.isVoterBackUploaded || !voterIDCardDetails.isVoterFrontUploaded
              }
              onChange={(e) =>
                setVoterIDCardDetails((preValue) => {
                  return {
                    ...preValue,
                    voterBackCardFile: URL.createObjectURL(e.target.files[0]),
                    isVoterBackUploaded: true,
                    VoterIdBackImageFileName: e.target.files[0].name,
                  };
                })
              }
              onClick={(event) => {
                event.currentTarget.value = "";
              }}
            />
          </form>
        </div>
        <div className="mb-5 text-base font-medium text-black font-lato">
          Voter ID No<span className="text-error">*</span>
        </div>
        <TextField
          name="voterCardID"
          type="text"
          placeholderStyle="!text-primary-850"
          inputClasses={clsx(
            voterIDCardDetails.isVIDocVerified
              ? "border-success border"
              : voterIDCardDetails.messageVIVerify === ""
                ? "border-none"
                : "border-error border",
            `bg-primary-350 !pt-1 lg:!w-[48%]`,
          )}
          value={voterIDCardDetails.voterNumber}
          errorMessage={voterIDCardDetails.messageVIVerify}
          error={voterIDCardDetails.messageVIVerify !== ""}
          fullWidth
          pattern="[a-zA-Z0-9 ]{1,}"
        />
        {!voterIDCardDetails.isVIDocVerified && (
          <Button
            id="dajk-dsank8y"
            bgColor={
              voterIDCardDetails.isVoterFrontUploaded && voterIDCardDetails.isVoterBackUploaded
                ? "bg-primary"
                : "bg-grey-450"
            }
            fontStyle={{
              fontFamily: "",
              fontSize: "text-base",
              fontWeight: "font-bold",
              fontColor:
                voterIDCardDetails.isVoterFrontUploaded && voterIDCardDetails.isVoterBackUploaded
                  ? "text-white"
                  : "text-black-650",
            }}
            borderStyle={{
              borderWidth: "border-none",
              borderRadius: "rounded-full lg:rounded",
            }}
            className={"py-5 w-4/5 m-auto lg:mx-0 mt-9 lg:!w-[28%]"}
            disabled={
              !voterIDCardDetails.isVoterFrontUploaded || !voterIDCardDetails.isVoterBackUploaded
            }
            onClickHandler={handleEVerifyVoterCard}
            isLoading={buttonLoader.voterCardLoader}
          >
            {loading ? (
              <div className="flex items-center justify-center animate-spin-slow py-1">
                <img src="/images/svg/smallLoader.svg" alt="" />
              </div>
            ) : (
              "Verify"
            )}
          </Button>
        )}
      </div>
    </div>
  );
};

export default VoterIdCardSection;
