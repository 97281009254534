import { Axios } from "../axios";
import { IAuth } from "../auth/auth";

const getUserTransaction = async (currentUser: IAuth.IIsAuthorisedProps) =>
  await Axios.get(`/remit/user/${currentUser.id}/transaction/getTransactionHistory`, {
    headers: {
      Authorization: `${currentUser.idToken}`,
    },
  });

const getUserDocs = async (currentUser: IAuth.IIsAuthorisedProps) =>
  await Axios.get(`/remit/users/${currentUser.id}/documents`, {
    headers: {
      Authorization: `${currentUser.idToken}`,
    },
  });

const getUserRecipients = async (currentUser: IAuth.IIsAuthorisedProps) =>
  await Axios.get(`/remit/user/${currentUser.id}/recipients`, {
    headers: {
      Authorization: `${currentUser.idToken}`,
    },
  });

const getPendingTransactions = async (currentUser: IAuth.IIsAuthorisedProps, userId: number,  recipientId: number ) => {
  return await Axios.get(`/remit/user/${userId}/getPendingTransactions/${recipientId}`, {
    headers: {
      Authorization: `${currentUser.idToken}`,
    },
  });
};

export { getUserTransaction, getUserDocs, getUserRecipients, getPendingTransactions };
