import React from "react";
import Button from "../../../../components/ui/Button";
import Modal from "../../../../components/ui/Modal";
import { KycUnsuccessful } from "../../../../assets/Icons";
import { useNavigate } from "react-router";

interface Props {
  showKycUnderReviewModal?: any;
  setShowKycUnderReviewModal?: any;
}

const UnderReview: React.FC<Props> = ({ showKycUnderReviewModal, setShowKycUnderReviewModal }) => {
  // const [visible, setVisible] = useState(true);

  const navigate = useNavigate();
  return (
    <div>
      {/* <Button
        id="open-Modal-basic-btn"
        bgColor="bg-primary"
        fontStyle={{
          fontFamily: "font-roboto",
          fontSize: "text-base",
          fontWeight: "font-bold",
          fontColor: "text-white",
        }}
        borderStyle={{
          borderRadius: "rounded",
        }}
        className={"py-4 px-14"}
        onClickHandler={() => setVisible(true)}
      >
        Open kycUnderReview
      </Button> */}
      <Modal
        visible={showKycUnderReviewModal}
        closeHandler={() =>
          location.pathname === "/send-money"
            ? setShowKycUnderReviewModal(false)
            : navigate("/send-money")
        }
        modalClass="h-4/5 md:h-3/5 w-full md:!w-[968px] p-4 justify-evenly "
        headerClass="mt-0"
      >
        <div className="flex flex-col items-center content-center text-center lg:flex-row lg:items-start lg:text-left ">
          <div className="">
            <h2 className="mt-10 mb-10 text-4xl font-bold md:mt-4">
              Your document verification is under review, Please try after some time.
            </h2>
            <p className="font-normal text-xl  text-[#949494] mt-12 mb-12">
              <span className="font-bold">Note</span> - The process is completed within 24 hrs. In
              case of any help, you can reach us at care@moneyhop.co
            </p>
            <Button
              bgColor="bg-primary"
              className="justify-center hidden w-40 h-10 font-bold text-white rounded-full md:flex mt-7 "
              onClickHandler={() =>
                location.pathname === "/send-money"
                  ? setShowKycUnderReviewModal(false)
                  : navigate("/send-money")
              }
            >
              Go Home
            </Button>
          </div>
          <div className="mt-16 md:mt-0">
            <KycUnsuccessful className="" />
          </div>
          <Button
            bgColor="bg-primary"
            className="w-3/5 h-10 mt-16 font-bold text-white rounded-full md:w-40 md:hidden"
            onClickHandler={() =>
              location.pathname === "/send-money"
                ? setShowKycUnderReviewModal(false)
                : navigate("/send-money")
            }
          >
            Go Home
          </Button>
        </div>
      </Modal>
    </div>
  );
};

export default UnderReview;
