import React, { useEffect, useRef, useState } from "react";
import { useRecoilState, useRecoilValue } from "recoil";

import TxDashboard from "./TxDashboard";
import KYCVerifiedDashboard from "./KYCVerifiedDashboard";
import UnverifiedKYCDashboard from "./UnverifiedKYCDashboard";
import { getUserDocs, getUserRecipients } from "../../services/userDetails";
import { kycDetails, recipientDetails, userDetails } from "../../store";
import { commaSeperation, DEAL_CARD_CONFIG } from "../../utils";
import Modal from "../../components/ui/Modal";
import { PrimeModalArt } from "../../assets/Icons";
import { motion } from "framer-motion";
import { getTransactionHistory } from "../../services/history";
import LoginSuccessFullLoader from "../auth/SignIn/LoginSuccessFullLoader";
import { useNavigate } from "react-router-dom";

const Home: React.FC = () => {
  const [history, setHistory] = useState([]);
  const [isKycVerified, setIsKycVerified] = useState<boolean>();
  const [recepientName, setRecepientName] = useState();
  const [isLoading, setIsLoading] = useState(true);
  const [totalRemitted, setTotalRemitted] = useState(0);
  const [totalTransactionCompleted, setTotalTransactionCompleted] = useState(0);
  const [loading, setLoading] = useState<boolean>(false);

  const setRecipient = useRecoilState(recipientDetails)[1];
  const setKycDetails = useRecoilState(kycDetails)[1];

  const isUseEffectFired = useRef(false);

  const userData = useRecoilValue(userDetails);
  const navigate = useNavigate();
  const currentUser = JSON.parse(sessionStorage.getItem("currentUser") || "{}");

  useEffect(() => {
    userData ? null : navigate("/auth/sign-in");
  }, []);

  useEffect(() => {
    let total = 0;
    let count = 0;
    history
      ?.filter((el: any) => {
        if (el.status === "COMPLETED") return el;
      })
      .map((item: any) => {
        count = count + 1;
        total = total + item?.userSend;
      });
    setTotalTransactionCompleted(count);
    setTotalRemitted(total);
  }, [history]);

  const getData = () => {
    setLoading(true);
    Promise.all([
      getTransactionHistory(currentUser),
      getUserDocs(currentUser),
      getUserRecipients(currentUser),
    ])
      .then((response: any) => {
        setLoading(false);
        const historyData = response[0].data;
        const kycData = response[1].data;
        const recepientData = response[2].data;
        setIsKycVerified(kycData.isDocVerified);
        const recipients = recepientData?.recipient?.map(
          (el: { id: any; firstName: any; lastName: any }) => ({
            fname: el?.firstName,
            lname: el?.lastName,
            id: el?.id,
          }),
        );
        setRecepientName(recipients);
        setKycDetails(kycData);
        setRecipient(recepientData.recipient);
        setHistory(() => {
          return historyData?.map((el: any) => {
            const historyObject = {
              accNo: el?.userTransaction?.recipient?.bankAccountNumber,
              baseCurrency: el?.userTransaction?.baseCurrency,
              conversionRate: el?.userTransaction?.conversionRate,
              recepientReceive: el?.userTransaction?.recipientRecieve,
              countryOfResidence: el?.userTransaction?.recipient.countryOfResidence,
              date: el?.userTransaction?.createdOn,
              destinationCurrency: el?.userTransaction?.destinationCurrency,
              paymentMode: el?.userTransaction?.transactionState?.paymentMode,
              status: el?.userTransaction?.status,
              totalDeduction: el?.userTransaction?.userSend,
              txNo: el?.userTransaction?.transactionNumber,
              totalPay: el?.userTransaction?.userSend,
              convertedAmount: el?.userTransaction?.conversionAmount,
              baseCountry: userData.country,
              userFname: userData.firstName,
              userLname: userData.lastName,
              receiverFname: el?.userTransaction?.recipient.firstName,
              receiverLname: el?.userTransaction?.recipient.lastName,
              userSend: el.userTransaction?.userSend,
            };
            return historyObject;
          });
        });
        setIsLoading(false);
      })
      .catch(() => {
        setIsLoading(false);
      });
  };

  useEffect(() => {
    if (!isUseEffectFired.current) {
      isUseEffectFired.current = true;
      getData();
    }
  }, []);

  const [visible, setVisible] = useState(true);
  return (
    <motion.div
      className="mt-9"
      initial={{ opacity: 0 }}
      animate={{ opacity: 1 }}
      exit={{ opacity: 0 }}
    >
      <Modal
        modalClass="rounded-xl"
        visible={visible && userData?.isPrime && totalRemitted === 1}
        closeHandler={() => setVisible(false)}
        closable={false}
      >
        <div className="relative z-50 flex flex-col lg:w-[750px] w-[330px] bg-yellow mb-10">
          <div className="">
            <div className="flex flex-col">
              <span className="lg:text-[45px] text-[24px] font-ssp font-bold">
                Great! You are now a
              </span>
              <span className="lg:text-[45px] text-[24px] font-ssp font-bold bg-yellow-400 text-white rounded lg:w-[320px] w-[230px]">
                <span className="ml-1">Prime Member</span>
              </span>
            </div>
            <div className="flex flex-col mt-8 space-y-2">
              <span className="text-[18px] lg:text-[22px] font-bold">Enjoy benefits like</span>
              <span className="text-[16px] lg:text-[20px] font-semibold">
                Zero Convenience Fees
              </span>
              <span className="text-[16px] lg:text-[20px] font-semibold">
                Quick Remittance & More
              </span>
            </div>
            <button
              className="px-10 lg:px-16 py-3 text-[16px] lg:text-[20px] ml-10 lg:ml-0 rounded-full mt-20 font-bold text-white bg-primary lg:mb-0 hover:bg-white hover:text-primary hover:border-primary border-2 border-white transition-all"
              onClick={() => setVisible(false)}
            >
              Get Started
            </button>
          </div>
          <div className="lg:block absolute hidden z-10 lg:mt-[72px] lg:ml-[272px] mt-[380px] -ml-[28px]">
            <PrimeModalArt />
          </div>
        </div>
      </Modal>
      {!loading ? (
        <div>
          {isLoading ? null : !isKycVerified ? (
            <UnverifiedKYCDashboard dealCard={DEAL_CARD_CONFIG} />
          ) : recepientName || history ? (
            <TxDashboard
              dealCard={DEAL_CARD_CONFIG}
              amountRemitted={commaSeperation(totalRemitted)} // TODO: Backend is not implemented for this
              chargesSaved={commaSeperation((0.0325 * totalRemitted).toFixed(2))} // TODO :Backend is not implemented for this
              noOfTx={totalTransactionCompleted}
              recepientName={recepientName}
              historyCardDetails={history}
            />
          ) : (
            <KYCVerifiedDashboard
              dealCard={DEAL_CARD_CONFIG}
              amountRemitted={commaSeperation(totalRemitted)}
              chargesSaved={commaSeperation((0.0325 * totalRemitted).toFixed(2))}
              noOfTx={totalTransactionCompleted}
            />
          )}
        </div>
      ) : (
        <LoginSuccessFullLoader />
      )}
    </motion.div>
  );
};

export default Home;
