import React from "react";
import clsx from "clsx";

import { IErrorField } from "./ErrorField";

const ErrorField: React.FC<IErrorField.IProps> = ({
  errorMessage,
  errorClassname,
  centerAligned,
  rightAligned,
  id,
  children,
}) => {
  const errorJSX = children ? (
    <>{children}</>
  ) : (
    <div
      id={id}
      className={clsx(
        "py-1 text-sm text-error",
        errorClassname,
        centerAligned && "text-center",
        rightAligned && "text-right",
      )}
    >
      {errorMessage}
    </div>
  );

  return errorJSX;
};

export default ErrorField;
