//#Global Imports
import React from "react";
import clsx from "clsx";
// import Countdown, { zeroPad } from "react-countdown";
//#end Global Imports

//#Local Imports
import OtpBox from "../../../../components/ui/OtpBox";
import Typograpy from "../../../../components/ui/Typography";
import Button from "../../../../components/ui/Button";
import TextField from "../../../../components/ui/TextField";
import ErrorField from "../../../../components/ui/ErrorField";
import Tooltip from "../../../../components/ui/Tooltip";
import {
  InfoIcon,
  PassportIcon,
  ReUploadIcon,
  UploadIcon,
  VisibilityOnIcon,
} from "../../../../assets/Icons";
import IUserDocuments from "../../userDocuments";
import mixpanel from "mixpanel-browser";
//#end Local Imports

const AadharCardSection: React.FC<IUserDocuments.IAadharCardSectionProps> = ({
  adharCardDetails,
  handlePreviewUploadedDoc,
  handleDeleteDoc,
  handleChangeUpload,
  entityId,
  saveAdharOTPValue,
  adharOTPError,
  isPDFfile,
  handleNoAadhaar,
  buttonLoader
  // dateKey,
  // date,
  // sendAdharOtp,
  // loading
}) => {
  const mixpanelToken: any = process.env.REACT_APP_MIXPANEL_TOKEN;
  mixpanel.init(mixpanelToken, {
    debug: true,
    api_host: process.env.REACT_APP_MIXPANEL_API,
  });
  return (
    <div className="col-span-4 lg:pl-10 lg:pt-7">
      <div className="w-full">
        <div className="flex items-start justify-between">
          <Typograpy
            tagType="div"
            className="mb-5 text-base font-medium text-black"
            text="Upload Aadhaar Card"
          />
          {adharCardDetails?.uploadedDocError === "Please upload the right file format.." && (
            <div className="relative cursor-pointer">
              <div className="peer">
                <InfoIcon fillColor="fill-secondary" />
              </div>
              <Tooltip
                title="Your file cant upload"
                subtitle="Try file formats like jpg, png or pdf"
                positionOfTip="bottom"
                tooltipStyles="peer-hover:block hidden !top-8 !min-h-[96px] !min-w-[236px] !-translate-x-full z-1"
                titleClasses="font-semibold text-lg text-white !pt-3"
                subtitleClasses="text-sm font-normal text-white"
                buttonClasses="!w-0 h-0"
              />
            </div>
          )}
        </div>
        <div className="relative">
          <div className="flex items-center space-x-8">
            <Button
              id="sfkdj902d987-dskdkik"
              bgColor="bg-primary-350"
              fontStyle={{
                fontFamily: "",
                fontSize: "text-sm",
                fontWeight: "font-normal ",
                fontColor: "text-black-200",
              }}
              borderStyle={{
                borderColor: adharCardDetails.uploadedDocError
                  ? "border-error border"
                  : adharCardDetails.isAadharDocUploaded || adharCardDetails.isAadharDocVerified
                    ? "border-success border"
                    : "border-none",
                borderRadius: "rounded",
              }}
              className={clsx(
                adharCardDetails.uploadedDocError ? "" : "mb-5",
                "w-full lg:w-[48%] h-[68px]",
              )}
            >
              <div className="flex items-center justify-between w-full px-4 lg:px-6">
                <div className="flex items-center space-x-2 lg:space-x-6">
                  <PassportIcon
                    width="23px"
                    height="29px"
                    fillColor={
                      adharCardDetails.isAadharDocUploaded ? "fill-success-150" : "fill-primary"
                    }
                  />
                  <div>Upload Aadhaar Front</div>
                </div>
                <div className="flex items-center space-x-4">
                  {adharCardDetails.isAadharDocUploaded &&
                    !adharCardDetails.isAadharDocVerified &&
                    !isPDFfile && (
                      <div
                        className="z-50 cursor-pointer"
                        onClick={() => {
                          handlePreviewUploadedDoc("AADHAR_CARD");
                          mixpanel.track("Upload Aadhar Clicked");
                        }}
                      >
                        <VisibilityOnIcon fillColor="fill-black-150" />
                      </div>
                    )}
                  {adharCardDetails.isAadharDocUploaded &&
                    !adharCardDetails.isAadharDocVerified && (
                      <div className="z-50 cursor-pointer" onClick={handleDeleteDoc}>
                        <ReUploadIcon
                          fillColor={
                            adharCardDetails?.uploadedDocError ? "fill-error" : "fill-black"
                          }
                        />
                      </div>
                    )}
                  {!adharCardDetails.isAadharDocUploaded && (
                    <UploadIcon
                      fillColor={
                        adharCardDetails.isAadharDocVerified ? "fill-success-150" : "fill-primary"
                      }
                    />
                  )}
                </div>
              </div>
            </Button>
          </div>
          {adharCardDetails.uploadedDocError !== "" && (
            <ErrorField errorMessage={adharCardDetails.uploadedDocError} errorClassname="mb-5" />
          )}
          <form encType="multipart/form-data" action="">
            <TextField
              accept="image/*,application/pdf"
              name="AADHAR_CARD"
              type="file"
              disabled={adharCardDetails.isAadharDocUploaded}
              rootClassName="absolute top-0 opacity-0 !w-full lg:!w-[48%]"
              onChange={(e) => {
                handleChangeUpload(e, "AADHAR_CARD");
              }}
              onClick={(event) => {
                event.currentTarget.value = "";
              }}
            />
          </form>
        </div>
        <div className="relative">
          <div className="flex items-center space-x-8">
            <Button
              id="sfkdj902d987-dskdkiks"
              bgColor="bg-primary-350"
              fontStyle={{
                fontFamily: "",
                fontSize: "text-sm",
                fontWeight: "font-normal ",
                fontColor: "text-black-200",
              }}
              borderStyle={{
                borderColor: adharCardDetails.uploadedBackError
                  ? "border-error border"
                  : adharCardDetails.isAadharBackUploaded
                    ? "border-success border"
                    : "border-none",
                borderRadius: "rounded",
              }}
              className={clsx(
                adharCardDetails.uploadedBackError ? "" : "mb-5",
                "w-full lg:w-[48%] h-[68px]",
              )}
            >
              <div className="flex items-center justify-between w-full px-4 lg:px-6">
                <div className="flex items-center space-x-2 lg:space-x-6">
                  <PassportIcon
                    width="23px"
                    height="29px"
                    fillColor={
                      adharCardDetails.isAadharBackUploaded ? "fill-success-150" : "fill-primary"
                    }
                  />
                  <div>Upload Aadhaar Back</div>
                </div>
                <div className="flex items-center space-x-4">
                  {adharCardDetails.isAadharBackUploaded && !isPDFfile && (
                    <div
                      className="z-50 cursor-pointer"
                      onClick={() => {
                        handlePreviewUploadedDoc("AADHAR_CARD_BACK");
                        mixpanel.track("Upload Aadhar Clicked");
                      }}
                    >
                      <VisibilityOnIcon fillColor="fill-black-150" />
                    </div>
                  )}
                  {adharCardDetails.isAadharBackUploaded && (
                    <div className="z-50 cursor-pointer" onClick={handleDeleteDoc}>
                      <ReUploadIcon
                        fillColor={
                          adharCardDetails?.uploadedBackError ? "fill-error" : "fill-black"
                        }
                      />
                    </div>
                  )}
                  {!adharCardDetails.isAadharBackUploaded && (
                    <UploadIcon
                      fillColor={
                        adharCardDetails.isAadharBackUploaded ? "fill-success-150" : "fill-primary"
                      }
                    />
                  )}
                </div>
              </div>
            </Button>
          </div>
          <form encType="multipart/form-data" action="">
            <TextField
              accept="image/*,application/pdf"
              name="AADHAR_CARD_BACK"
              type="file"
              // disabled={adharCardDetails.isAadharDocUploaded}
              rootClassName="absolute top-0 opacity-0 !w-full lg:!w-[48%]"
              onChange={(e) => {
                handleChangeUpload(e, "AADHAR_CARD_BACK");
              }}
              onClick={(event) => {
                event.currentTarget.value = "";
              }}
            />
          </form>
          {/* <form encType="multipart/form-data" action="">
            <TextField
              accept="image/*,application/pdf"
              name="AADHAR_CARD_BACK"
              type="file"
              // disabled={adharCardDetails.isAadharBackUploaded}
              rootClassName="absolute top-0 opacity-0 !w-full lg:!w-[48%]"
              onChange={(e) => {
                handleChangeUpload(e, "AADHAR_CARD_BACK");
              }}
              onClick={(event) => {
                event.currentTarget.value = "";
              }}
            />
          </form> */}
        </div>
        {/* <Typograpy
          tagType="div"
          className="mb-5 text-sm font-bold text-black lg:text-base font-lato"
          text="Enter Aadhaar number".
        /> */}
        {/* Adhaar no text */}
        <div className="mb-5 text-base font-medium text-black font-lato">
          Aadhaar No<span className="text-error">*</span>
        </div>
        <div className="flex flex-col items-center lg:items-start lg:w-3/4 lg:flex-row">
          <TextField
            name="adharNo"
            type="text"
            inputClasses={clsx(
              adharCardDetails.isAadharDocVerified
                ? "border-success border"
                : adharCardDetails.messageAadhar === ""
                  ? "border-none"
                  : "border-error border",
              `bg-primary-350 !pt-0`,
            )}
            value={adharCardDetails.aadharNumber ?? ""}
            errorMessage={adharCardDetails.messageAadhar}
            error={adharCardDetails.messageAadhar !== ""}
            fullWidth
            pattern="[a-zA-Z0-9 ]{1,}"
          />
          {/* {!adharCardDetails.isAadharDocVerified && (
            <Button
              id="sfkdj902d987-dskdkik-hasbh"
              bgColor={
                adharCardDetails.isAadharDocUploaded && adharCardDetails.isAadharBackUploaded
                  ? "bg-primary"
                  : "bg-grey-450"
              }
              fontStyle={{
                fontFamily: "",
                fontSize: "text-base",
                fontWeight: "font-bold",
                fontColor:
                  adharCardDetails.isAadharDocUploaded && adharCardDetails.isAadharBackUploaded
                    ? "text-white"
                    : "text-black-650",
              }}
              borderStyle={{
                borderWidth: "border-none",
                borderRadius: "rounded-[100px] lg:rounded",
              }}
              className="w-full py-5 mt-10 lg:ml-6 lg:w-1/2 lg:mt-0"
              disabled={
                !adharCardDetails.isAadharDocUploaded || !adharCardDetails.isAadharBackUploaded
              }
              onClickHandler={() => entityId === "" && sendAdharOtp()}
            >
              {entityId === "" ? (
                loading ? (
                  <div className="flex items-center justify-center animate-spin-slow py-1">
                    <img src="/images/svg/smallLoader.svg" alt="" />
                  </div>
                ) : (
                  "Send OTP"
                )
              ) : (
                <div className={clsx("flex flex-col items-center justify-center")}>
                  <div>
                    <Countdown
                      key={dateKey}
                      date={date}
                      renderer={({ minutes, seconds, completed }) => {
                        if (completed) {
                          return (
                            <div
                              className="text-lg font-bold text-white cursor-pointer lg:text-xs lg:text-black font-lato"
                              onClick={sendAdharOtp}
                            >
                              {loading ? (
                                <div className="flex items-center justify-center animate-spin-slow py-1">
                                  <img src="/images/svg/smallLoader.svg" alt="" />
                                </div>
                              ) : (
                                "Resend OTP"
                              )}
                            </div>
                          );
                        } else {
                          return (
                            <div className="flex items-center space-x-2">
                              <div
                                className="text-base font-bold text-white cursor-not-allowed lg:text-black font-lato"
                                onClick={() => ""}
                              >
                                Resend OTP
                              </div>
                              <div className="text-lg font-bold text-white lg:text-xs lg:text-primary font-lato">
                                {zeroPad(minutes)} :{zeroPad(seconds)}
                              </div>
                            </div>
                          );
                        }
                      }}
                    />
                  </div>
                </div>
              )}
            </Button>
          )} */}
        </div>
        {/* <div className="flex items-center my-8">
          <InfoIcon fillColor="fill-grey-950" />
          <Typograpy
            tagType="div"
            text="An OTP would be triggered to the Aadhaar-linked mobile number."
            className="ml-3 mr-6 text-base font-normal text-grey-550"
          />
        </div> */}
        <div className="flex flex-col items-center justify-between my-8 w-full">
          {/* <div className="flex items-center justify-start w-full">
              <InfoIcon fillColor="fill-grey-950" />
              <Typograpy
                tagType="div"
                text="Mobile No. is not linked with Aadhaar?"
                className="ml-3 mr-2 text-base font-normal text-grey-550"
              />
            </div> */}
          {/* <p className="underline underline-offset-4 text-base text-primary"> */}
          {/* Click here to continue. */}
          {/* </p> */}
          <div className="flex w-full justify-start">
            <Button
              id="2sn9nkj87-8987nx76"
              bgColor={
                adharCardDetails.isAadharDocUploaded && adharCardDetails.isAadharBackUploaded
                  ? "bg-primary"
                  : "bg-grey-450"
              }
              fontStyle={{
                fontFamily: "",
                fontSize: "text-base",
                fontWeight: "font-bold",
                fontColor:
                  adharCardDetails.isAadharDocUploaded && adharCardDetails.isAadharBackUploaded
                    ? "text-white"
                    : "text-black-650",
              }}
              borderStyle={{
                borderWidth: "border-none",
                borderRadius: "rounded-full lg:rounded",
              }}
              className={"py-5 w-4/5 m-auto lg:mx-0 mt-9 lg:!w-[24%]"}
              disabled={
                !adharCardDetails.isAadharDocUploaded || !adharCardDetails.isAadharBackUploaded
              }
              onClickHandler={handleNoAadhaar}
              isLoading={buttonLoader.aadhaarOtpLoader}
            >
              Verify
            </Button>
          </div>
        </div>
        {!adharCardDetails.isAadharDocVerified && (
          <div className={clsx(entityId === "" ? "hidden" : "block", "mt-12")}>
            <Typograpy
              tagType="div"
              className="mb-8 text-sm font-bold text-black font-lato"
              text="Verify your Aadhaar OTP"
            />
            <div className="flex flex-col items-center justify-between lg:w-3/4 lg:flex-row">
              <OtpBox
                numberOfBoxes={6}
                updateValue={(value) => saveAdharOTPValue(value)}
                errorMsg={adharOTPError ?? ""}
                otpBoxClassName={clsx(
                  adharOTPError === "" ? "border-none" : "border",
                  "bg-primary-350 !w-[52px] !mr-2",
                )}
              />
            </div>
          </div>
        )}
      </div>
    </div>
  );
};

export default AadharCardSection;
