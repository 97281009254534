import React, { FC } from "react";

interface TabPanelProps {
  isSwift: boolean;
  setIsSwift: React.Dispatch<React.SetStateAction<boolean>>;
  switchTab: boolean;
}

const TabPanel: FC<TabPanelProps> = ({ isSwift, setIsSwift, switchTab }) => {
  return (
    <div className="my-4 w-96">
      <div className="flex flex-row justify-evenly items-center">
        <button
          style={{
            boxShadow: ` ${isSwift && "0 0 5px rgba(15, 91, 239, 0.25)"}`,
          }}
          disabled={!switchTab}
          onClick={() => setIsSwift(true)}
          className={`flex-1 text-center py-2 ${
            isSwift
              ? "flex flex-row justify-evenly items-center bg-white-100 text-primary rounded-lg"
              : "bg-[#E3E3E3] w-1/2 flex justify-center items-center py-1.5 rounded-l-lg"
          }`}
        >
          SWIFT code
        </button>
        <button
          style={{
            boxShadow: ` ${!isSwift && "0 0 5px rgba(15, 91, 239, 0.25)"}`,
          }}
          disabled={!switchTab}
          onClick={() => setIsSwift(false)}
          className={`flex-1 text-center py-2 ${
            !isSwift
              ? "flex flex-row justify-evenly items-center bg-white-100 text-primary rounded-lg"
              : "bg-[#E3E3E3] w-1/2 flex justify-center items-center py-1.5 rounded-l-lg"
          }`}
        >
          IBAN
        </button>
      </div>
    </div>
  );
};

export default TabPanel;
