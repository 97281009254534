/* eslint-disable */
//#Global Imports
import React, { useEffect } from "react";
import clsx from "clsx";
import { useNavigate } from "react-router-dom";
//#end Global Imports

//#Local Imports
import Modal from "../../../components/ui/Modal";
import Typograpy from "../../../components/ui/Typography";
import Button from "../../../components/ui/Button";
import Checkbox from "../../../components/ui/Checkbox";
import { KYCCompleted, KYCVerification } from "../../../assets/Icons";
import IUserDocuments from "../userDocuments";
import mixpanel from "mixpanel-browser";
//#end Local Imports

const KycVerifiedAndIsAgreeModal: React.FC<IUserDocuments.IKycAVerifiedndIsAgreeModalProps> = ({
  isModalOpen,
  setIsModalOpen,
  setIsAgree,
  isAgree,
  // kycVerificationAgreementNote,
  selectedPurpose,
  listOfPurposeforAdditionalDocuments,
  setDocType,
  setIsMobileDocumentListOpen,
  state,
}) => {
  const navigate = useNavigate();
  const mixpanelToken: any = process.env.REACT_APP_MIXPANEL_TOKEN;
  mixpanel.init(mixpanelToken, {
    debug: true,
    api_host:process.env.REACT_APP_MIXPANEL_API,
  });

  useEffect(() => {
    if (location.pathname === "/inward/user-documents") {
      setIsAgree(true);
    }
  }, []);

  return (
    <Modal
      modalClass="!pt-6 md:pr-11 !rounded md:overflow-hidden overflow-y-scroll md:max-h-[80%] lg:w-[70%] md:w-[80%] sm:w-[90%] w-full xs:w-full"
      visible={isModalOpen === "1" || isModalOpen === "KYCVerified"}
      closeHandler={() => setIsModalOpen("")}
    >
      <div
        className={clsx(
          isModalOpen === "1" ? "mt-10" : "mt-0",
          "pb-5 md:pl-[30px] md:pr-[18px] flex item-start justify-between",
        )}
      >
        <div
          className={clsx(
            isModalOpen === "1" ? "items-center " : "items-center md:items-start",
            "flex flex-col md:mr-12 justify-between",
          )}
        >
          <div className="md:pb-[37px] pb-12">
            {isModalOpen === "1" && location.pathname !== "/inward/user-documents" && (
              <Typograpy
                tagType="div"
                text="Welcome to moneyHOP"
                className="mb-4 text-xl font-semibold text-black font-lato md:hidden"
              />
            )}
            {location.pathname !== "/inward/user-documents" && (
              <Typograpy
                tagType="div"
                text={isModalOpen === "1" ? "Welcome to" : "Hi! User"}
                className={clsx(
                  isModalOpen === "1" ? "hidden md:flex" : "flex",
                  "text-black font-semibold text-[28px] font-lato",
                )}
              />
            )}
            {location.pathname !== "/inward/user-documents" && (
              <Typograpy
                tagType="div"
                text={isModalOpen === "1" ? "moneyHOP" : "“Your one-time document verification is completed”"}
                className={clsx(
                  isModalOpen === "1" ? "hidden md:flex" : "flex",
                  "text-black font-semibold text-[38px] font-lato",
                )}
              />
            )}
            {location.pathname === "/inward/user-documents" && (
              <Typograpy
                tagType="div"
                text={"Lets Begin KYC"}
                className="mb-12 text-4xl font-bold"
              />
            )}

            {isModalOpen === "1" && (
              <Typograpy
                tagType="div"
                text="We will be verifying your documents to provide you the seamless experience."
                className="text-sm font-normal text-black md:text-lg font-lato"
              />
            )}

            {location.pathname === "/inward/user-documents" && (
              <Typograpy
                tagType="div"
                text={"Note : To ensure smooth processing, please upload clear images."}
                className="mx-auto my-12 text-base font-normal"
              />
            )}
          </div>
          <div className="md:hidden mb-[61px]">
            {isModalOpen === "1" ? (
              <KYCVerification width="293px" />
            ) : (
              <KYCCompleted width="293px" />
            )}
          </div>
          {isModalOpen === "1" && location.pathname !== "/inward/user-documents" && (
            <div className="mb-16">
              <Checkbox
                label={
                  "To ensure a smooth process, please provide proper documents that are legible. By proceeding, you agree to provide the necessary documents for verification authentication."
                }
                value="true"
                name="conditionalNote"
                labelClassName="w-full ml-[26px]"
                checkboxClassname="lg:m-[2px]"
                handleChange={(e) => {
                  setIsAgree(e.target.checked);
                }}
              />
            </div>
          )}
          <Button
            id="sfkdj902d987--090489"
            bgColor={isModalOpen === "1" ? (isAgree ? "bg-primary" : "bg-grey-350") : "bg-primary"}
            fontStyle={{
              fontFamily: "",
              fontSize: "text-lg",
              fontWeight: "font-bold",
              fontColor: "text-white",
            }}
            borderStyle={{
              borderWidth: "border-none",
              borderRadius: isModalOpen === "1" ? "rounded-[100px]" : "rounded-[44px]",
            }}
            disabled={
              isModalOpen === "1" && !isAgree && location.pathname !== "/inward/user-documents"
            }
            className={clsx(
              isModalOpen === "1" ? "py-[14px] px-[120px]" : "py-[11px] justify-start w-full",
            )}
            onClickHandler={() =>
              isModalOpen === "1"
                ? (setIsModalOpen("2"), setDocType("PAN_CARD"), setIsMobileDocumentListOpen(true))
                : (setIsModalOpen(""), navigate("/user-details", { state: state && state }), mixpanel.track("Proceed button_KYC complete"))
            }
          >
            {isModalOpen === "1" && location.pathname !== "/inward/user-documents"
              ? "Confirm"
              : isModalOpen === "1" && location.pathname === "/inward/user-documents"
              ? "Start KYC"
              : "Save & Continue"}
          </Button>
        </div>
        <div className="hidden md:flex">
          {isModalOpen === "1" ? <KYCVerification /> : <KYCCompleted />}
        </div>
      </div>
    </Modal>
  );
};

export default KycVerifiedAndIsAgreeModal;
