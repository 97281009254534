const TX_PROGRESS = {
  CANCELLED: {
    value: "Cancelled",
    progressCompleted: 100,
    progressColor: "!bg-error",
  },
  COMPLETED: {
    value: "Completed",
    progressCompleted: 100,
    progressColor: "!bg-success",
  },
  IN_TRANSIT: {
    value: "In Process",
    progressCompleted: 35,
    progressColor: "!bg-inProcess",
  },
  ASSIGNED_TO_PROCESSING: {
    value: "Assigned To Processing Team",
    progressCompleted: 65,
    progressColor: "!bg-inProcess",
  },
  PAYMENT_VERIFICATION_UNDER_PROCESS: {
    value: "Payment Under Verification",
    progressCompleted: 45,
    progressColor: "!bg-inProcess",
  },
  DOCUMENT_VERIFICATION_UNDER_PROCESS: {
    value: "Documents Under Verification",
    progressCompleted: 35,
    progressColor: "!bg-inProcess",
  },
  REQUIRE_MORE_DOCUMENTS: {
    value: "Awaiting Documents",
    progressCompleted: 35,
    progressColor: "!bg-error",
  },
  AWAITING_PAYMENT: {
    value: "Awaiting Payment",
    progressCompleted: 10,
    progressColor: "!bg-primary-200",
  },
  UNDER_PROCESS: {
    value: "Under Process",
    progressCompleted: 10,
    progressColor: "!bg-primary-200",
  },
  AWAITING_FULL_PAYMENT: {
    value: "Awaiting Full Payment",
    progressCompleted: 25,
    progressColor: "!bg-primary-1050",
  },
  INITIATE_REFUND: {
    value: "Refund Initiated",
    progressCompleted: 10,
    progressColor: "!bg-grey-200",
  },
  REFUND_COMPLETED: {
    value: "Refund Completed",
    progressCompleted: 100,
    progressColor: "!bg-black",
  },
  PAYMENT_VERIFICATION_ON_HOLD: {
    value: "Payment Verification On Hold",
    progressCompleted: 100,
    progressColor: "!bg-inProcess",
  },
};

export default TX_PROGRESS;
