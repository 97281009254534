import React from "react";
import { useLottie } from "lottie-react";
import * as circleLoader from "../../../assets/lottieAnimationsData/loginAnimation.json";

export const CircularLoader = () => {
  const options = {
    loop: true,
    autoplay: true,
    animationData: circleLoader,
    rendererSettings: {
      preserveAspectRatio: "xMidYMid slice",
    },
  };

  const { View } = useLottie(options);

  return <div className="justify-self-center">{View}</div>;
};
