//Global Imports
import clsx from "clsx";
import React from "react";
import { Controller, useForm } from "react-hook-form";
//End Global Imports

//Local Imports
import Button from "../../../components/ui/Button";
import Dropdown from "../../../components/ui/Dropdown";
import { regexForEmailAddress, regexForMobileNumber } from "../../../utils";
import { IForgotPassword } from "./ForgotPassword";
import { TextField } from "@mui/material";
//End Local Imports

const StepOne: React.FC<IForgotPassword.IStepOneProps> = ({
  registerEmail,
  selectionType,
  setShowDropdown,
  showDropdown,
  countryCodeDefaultValue,
  value,
  setValue,
  sendOtpError,
  setSendOtpError,
  setSelectedCountryCode,
}) => {
  type FormInputs = {
    forgotEmail?: string;
    phoneNumber?: string;
  };

  const {
    formState: { errors, isValid, isDirty },
    handleSubmit,
    control,
    getValues,
    setError,
  } = useForm<FormInputs>({ mode: "onChange" });

  const coutryList = [
    {
      name: "United States +1",
      icon: "USD.png",
      countryCode: "+1",
    },
    {
      name: "Canada +1",
      icon: "CAD.png",
      countryCode: "+1",
    },
    {
      name: "India +91",
      icon: "INR.png",
      countryCode: "+91",
    },
    {
      name: "United Kingdom +44",
      icon: "GBP.png",
      countryCode: "+44",
    },
  ];

  React.useEffect(() => {
    sendOtpError !== "" &&
      setError("phoneNumber", {
        type: sendOtpError,
      });
    sendOtpError !== "" &&
      setError("forgotEmail", {
        type: sendOtpError,
      });
  }, [sendOtpError]);

  return (
    <>
      <div className={clsx("pb-3 text-sm sm:text-base font-semibold text-black font-ssp md:pb-5")}>
        Enter your registered {selectionType === "email" ? "email" : "mobile number"}
      </div>
      <form
        onSubmit={handleSubmit(registerEmail)}
        className="flex flex-col items-center justify-between h-5/6"
      >
        {selectionType === "email" ? (
          <div className="w-full sm:w-[372px] pb-9 sm:pb-[229px] mx-7 sm:mx-14">
            <Controller
              control={control}
              name="forgotEmail"
              rules={{ required: true, pattern: regexForEmailAddress }}
              render={({ field: { name, value, onChange } }) => {
                return (
                  <TextField
                    name={name}
                    type="email"
                    label="Enter your registered email address"
                    className={clsx(
                      errors.forgotEmail
                        ? "border"
                        : isDirty && !errors.forgotEmail && getValues("forgotEmail") !== undefined
                        ? "border-success"
                        : "border-none ",
                      `bg-primary-350`,
                    )}
                    value={value}
                    onChange={(v) => onChange(v)}
                    required
                    fullWidth
                  />
                );
              }}
            />
          </div>
        ) : (
          <div className="flex w-screen px-6 sm:w-max sm:justify-between pb-7 sm:pb-0 sm:px-0">
            <div className="mr-5 sm:mr-[10px] w-fit sm:w-[120px]">
              <Dropdown
                dropdownClassname="w-[120px] h-[64px] flex justify-center px-[14px]"
                showDropdown={showDropdown}
                dropdownButtonOnClick={() =>
                  setShowDropdown((showDropdown: boolean) => !showDropdown)
                }
                dropdownLabel={countryCodeDefaultValue}
                countryList={coutryList}
                dropdownValue={value}
                listItemOnClick={(e) => {
                  e.stopPropagation();
                  // saveCountryCode("+" + e.target.textContent.split("+").pop());
                  const value = coutryList.find((country) => country.name === e.target.outerText);
                  value && setSelectedCountryCode(value?.countryCode);
                  setValue(
                    e.target.textContent == "United States +1" ? (
                      <span className="flex items-center justify-start space-x-2 text-base font-medium text-black font-lato">
                        <img src={`/images/png/USD.png`} alt="" width="35px" height="35px" />
                        <div>+1</div>
                      </span>
                    ) : e.target.textContent == "Canada +1" ? (
                      <span className="flex items-center justify-start space-x-2 text-base font-medium text-black font-lato">
                        <img src={`/images/png/CAD.png`} alt="" width="35px" height="35px" />
                        <div>+1</div>
                      </span>
                    ) : e.target.textContent == "India +91" ? (
                      <span className="flex items-center justify-start space-x-2 text-base font-medium text-black font-lato">
                        <img src={`/images/png/INR.png`} alt="" width="35px" height="35px" />
                        <div>+91</div>
                      </span>
                    ) : e.target.textContent == "United Kingdom +44" ? (
                      <span className="flex items-center justify-start space-x-2 text-base font-medium text-black font-lato">
                        <img src={`/images/png/GBP.png`} alt="" width="35px" height="35px" />
                        <div>+44</div>
                      </span>
                    ) : (
                      <></>
                    ),
                  );
                  setShowDropdown(false);
                }}
                onBlur={() => {
                  setShowDropdown(false);
                }}
              />
            </div>
            <div className="sm:w-[270px] w-full">
              <Controller
                control={control}
                name="phoneNumber"
                rules={{ required: true, pattern: regexForMobileNumber }}
                render={({ field: { name, value, onChange } }) => {
                  return (
                    <TextField
                      name={name}
                      type="number"
                      label="Mobile No."
                      className={clsx(
                        errors?.phoneNumber
                          ? "border"
                          : isDirty && !errors.phoneNumber && getValues("phoneNumber") !== undefined
                          ? "border-success border"
                          : "border-none",
                        "bg-primary-100",
                      )}
                      value={value}
                      onChange={(v) => {
                        onChange(v);
                        setSendOtpError("");
                      }}
                      required
                      fullWidth
                    />
                  );
                }}
              />
            </div>
          </div>
        )}
        <div>
          <Button
            id="step-1-confirm"
            bgColor={isValid ? "bg-primary" : "bg-grey-550"}
            fontStyle={{
              fontFamily: "font-ssp",
              fontSize: "text-lg",
              fontWeight: "font-bold",
              fontColor: "text-white",
            }}
            borderStyle={{
              borderRadius: "rounded-[100px]",
            }}
            className="w-[307px] h-[50px]"
            disabled={!isValid}
          >
            Confirm
          </Button>
        </div>
      </form>
    </>
  );
};

export default StepOne;
