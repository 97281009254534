import React, { useEffect } from "react";
import {
  // AreaChart,
  LineChart,
  // Area,
  XAxis,
  YAxis,
  Tooltip,
  ResponsiveContainer,
  CartesianGrid,
  Line,
} from "recharts";

const AreaCharts = (props: any) => {
  const { data } = props;

  const [graphData, setGraphData] = React.useState([]);

  useEffect(() => {
    if (data) {
      setGraphData(data);
    }
  }, [data]);

  return (
    <ResponsiveContainer width="100%" height="100%">
      <LineChart
        width={100}
        height={100}
        data={graphData}
        margin={{
          top: 5,
          right: 5,
          left: 0,
          bottom: 5,
        }}
      >
        <defs>
          <linearGradient id="colorUv" x1="0" y1="0" x2="0" y2="1">
            <stop offset="5%" stopColor="#354C7E" stopOpacity={1} />
            <stop offset="95%" stopColor="#354C7E" stopOpacity={0.2} />
          </linearGradient>
        </defs>
        <CartesianGrid strokeDasharray="3 5" vertical={false} />
        <XAxis
          dataKey="date"
          //   padding={{ left: 4 }}
          style={{ marginBottom: "20px", marginTop: "20px" }}
          tickCount={10}
        />
        <YAxis
          domain={["dataMin - 3", "dataMax + 3"]}
          type="number"
          tickFormatter={(value: any) => value.toFixed(2)}
          tickCount={15}
        />
        <Tooltip />
        <Line
          dataKey="rate"
          // fill="url(#colorUv)"
          stroke="#354C7E"
          strokeWidth={3}
          type="monotone"
        />
      </LineChart>
    </ResponsiveContainer>
  );
};

export default AreaCharts;
