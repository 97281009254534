//Global Imports
import clsx from "clsx";
import React from "react";
import { EmailOtpIcon, MobileOtpIcon } from "../../../assets/Icons";
//End Global Imports

//Local Imports
import Button from "../../../components/ui/Button";
import { IForgotPassword } from "./ForgotPassword";
//End Local Imports

const MobileEmailSelection: React.FC<IForgotPassword.IMobileEmailSelectionProps> = ({
  selectionType,
  setSelectionType,
  setStep,
}) => {
  // Helper function to handle selection and move to the next step
  const handleSelection = (type: string) => {
    setSelectionType(type);
    setStep(2);
  };

  return (
    <>
      <div className="mb-4 sm:mb-8 text-sm sm:text-base lg:text-lg font-bold">
        How you want to recover it
      </div>

      <div className="w-full mx-[52px] sm:mx-14">
        <Button
          id="mobile-otp-icon"
          bgColor={clsx(
            selectionType === "mobile"
              ? "bg-blue-300 hover:bg-blue-300 shadow-lg transition-all"
              : "bg-primary-350 hover:bg-blue-300 shadow-lg transition-all",
          )}
          fontStyle={{
            fontFamily: "",
            fontSize: "text-base",
            fontWeight: "font-normal",
            fontColor: "text-black",
          }}
          borderStyle={{
            borderRadius: "rounded",
          }}
          className="w-full mb-5 h-[68px] border-none"
          onClickHandler={() => handleSelection("mobile")}
        >
          <div className="flex flex-col sm:flex-row items-center justify-between w-full px-2 sm:px-4 lg:px-6">
            <div className="flex items-center space-x-1 sm:space-x-2 lg:space-x-6">
              <MobileOtpIcon />
              <div className="text-xs sm:text-sm lg:text-base">
                Send an SMS to registered mobile no.
              </div>
            </div>
          </div>
        </Button>
      </div>
      <div className="w-full mx-[52px] sm:mx-14 pb-8 sm:pb-[125px]">
        <Button
          id="email-otp-icon"
          bgColor={clsx(
            selectionType === "email"
              ? "bg-blue-300 hover:bg-blue-300 shadow-lg transition-all"
              : "bg-primary-350 hover:bg-blue-300 shadow-lg transition-all",
          )}
          fontStyle={{
            fontFamily: "",
            fontSize: "text-base",
            fontWeight: "font-normal",
            fontColor: "text-black-200",
          }}
          borderStyle={{
            borderRadius: "rounded",
          }}
          className="w-full mb-5 h-[68px] border-none"
          onClickHandler={() => handleSelection("email")}
        >
          <div className="flex flex-col sm:flex-row items-center justify-between w-full px-2 sm:px-4 lg:px-6">
            <div className="flex items-center space-x-1 sm:space-x-2 lg:space-x-6">
              <EmailOtpIcon />
              <div className="text-xs sm:text-sm lg:text-base">Send OTP to email address</div>
            </div>
          </div>
        </Button>
      </div>
    </>
  );
};

export default MobileEmailSelection;
