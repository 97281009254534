/* eslint-disable */
import { useState } from "react";

type DropdownOption = {
  label: string;
  value: string;
};

type Props = {
  options: DropdownOption[];
  errorMessage?: string;
  value?: string;
  handleDropdownChange?: (event: React.ChangeEvent<HTMLSelectElement>) => void;
  selectedValue?: string;
  disabled?: boolean;
};

const TextFiledWithDropdown = ({
  options,
  errorMessage,
  value,
  selectedValue,
  handleDropdownChange,
  disabled,
}: Props) => {
  const [name, setName] = useState("");

  const handleNameChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setName(event.target.value);
  };

  const [isNameFocused, setIsNameFocused] = useState(false);

  return (
    <div
      className={`rounded-md flex flex-row pl-4 items-center md:w-96 w-full border ${
        isNameFocused ? "border-blue-600 border-2" : "border-gray-400"
      }`}
    >
      {/* <div className="w-1/5">
        <select
          className="outline-none block w-full h-full border-spacing-8 border-r-2 py-3.5"
          value={selectedValue}
          onChange={handleDropdownChange}
        >
          {options.map((option) => (
            <option key={option.value} value={option.value}>
              {option.label}
            </option>
          ))}
        </select>
      </div> */}
      <div className="relative w-4/5">
        <input
          type="text"
          className={`outline-none block pl-4 py-3.5 w-full ${name?"bg-white/100":""} rounded-md shadow-sm text-gray-400 ${
            errorMessage ? "border-red-500" : ""
          }`}
          onChange={handleNameChange}
          onFocus={() => setIsNameFocused(true)}
          onBlur={() => setIsNameFocused(false)}
          value={value}
          disabled={disabled}
        />
        <label
          className={`absolute top-3 left-20  text-sm transition-all z-50 ${
            isNameFocused || name || value ? "-mt-6 -ml-20 text-gray-600 bg-white" : "text-gray-600"
          }`}
        >
          Name*
        </label>
        {errorMessage && (
          <div className="absolute bottom-0 text-xs text-red-500 left-2">{errorMessage}</div>
        )}
      </div>
    </div>
  );
};

export default TextFiledWithDropdown;
