import React, { useState } from "react";
import { CloseIcon } from "../../../../assets/Icons";
import Button from "../../../../components/ui/Button";
import Modal from "../../../../components/ui/Modal";
import { Tajmahal } from "../../../../assets/Icons";
import { Globe } from "../../../../assets/Icons";
import clsx from "clsx";
import { useNavigate } from "react-router-dom";

interface Props {
  showInwardModal?: any;
  setShowInwardModal?: any;
  setIsInward?: any;
}

const SendingMoney: React.FC<Props> = ({ showInwardModal, setShowInwardModal, setIsInward }) => {
  // const [visible, setVisible] = useState(false);

  const navigate = useNavigate();

  const [sendMoney, setSendMoney] = useState("");

  const handleConfirm = () => {
    if (sendMoney === "toIndia") {
      setIsInward(true);
      navigate("/inward/pick-recipient");
    } else if (sendMoney === "fromIndia") {
      setIsInward(false);
      navigate("/pick-recipient");
    }
    setShowInwardModal(false);
  };

  return (
    <div>
      {/* <Button
        id="open-Modal-basic-btn"
        bgColor="bg-primary"
        fontStyle={{
          fontFamily: "font-roboto",
          fontSize: "text-base",
          fontWeight: "font-bold",
          fontColor: "text-white",
        }}
        borderStyle={{
          borderRadius: "rounded",
        }}
        className={"py-4 px-14"}
        onClickHandler={() => setVisible(true)}
      >
        sendingMoney
      </Button> */}
      <Modal
        header="Sending Money"
        visible={showInwardModal}
        closeButtonIcon={<CloseIcon fill="white" />}
        closeHandler={() => setShowInwardModal(false)}
        modalClass="md:h-[700px]  mt-0 ml-0 mr-0 pt-0 pl-0 pr-0 rounded-lg"
        headerClass="w-full md:w-[478px] bg-primary mt-0 ml-0 mr-0 pt-0 pl-4 pr-4 h-[76px] rounded-t-lg text-white font-bold colour-white"
      >
        <div className="flex flex-col items-center text-center ">
          <Button
            bgColor={clsx(sendMoney === "fromIndia" ? "bg-blue-500" : "bg-[#F1F4FF]")}
            className={clsx(
              sendMoney === "fromIndia" ? "text-white" : "text-[#2A2C7A]",
              "rounded-lg w-[300px] h-[71px]  mt-16 font-bold",
            )}
            onClickHandler={() => {
              if (sendMoney === "" || sendMoney === "toIndia") {
                setSendMoney("fromIndia");
              } else {
                setSendMoney("");
              }
            }}
          >
            <Tajmahal className="mr-3" />
            From India
          </Button>

          <Button
            bgColor={clsx(sendMoney === "toIndia" ? "bg-blue-500" : "bg-[#F1F4FF]")}
            className={clsx(
              sendMoney === "toIndia" ? "text-white" : "text-[#2A2C7A]",
              "rounded-lg w-[300px] h-[71px]   mt-16 font-bold",
            )}
            onClickHandler={() => {
              if (sendMoney === "" || sendMoney === "fromIndia") {
                setSendMoney("toIndia");
              } else {
                setSendMoney("");
              }
            }}
          >
            <Globe className="mr-3" />
            To India
          </Button>
          <Button
            bgColor={clsx(sendMoney !== "" ? "bg-primary" : "bg-[#9A9A9A]")}
            className="rounded-full w-[305px] h-[50px]  text-white mt-[170px]"
            onClickHandler={handleConfirm}
          >
            Confirm
          </Button>
        </div>
      </Modal>
    </div>
  );
};

export default SendingMoney;
