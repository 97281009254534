//#Global Imports
import React from "react";
import clsx from "clsx";
import moment from "moment";
//#end Global Imports

//#Local Imports
import ErrorField from "../../../../components/ui/ErrorField";
import TextField from "../../../../components/ui/TextField";
import Button from "../../../../components/ui/Button";
import Tooltip from "../../../../components/ui/Tooltip";
import IUserDocuments from "../../userDocuments";
import {
  InfoIcon,
  PassportIcon,
  ReUploadIcon,
  UploadIcon,
  VisibilityOnIcon,
} from "../../../../assets/Icons";
import { DatePicker } from "../../../../components/ui/DatePicker";
import mixpanel from "mixpanel-browser";
//#end Local Imports

const DrivingLicenseSection: React.FC<IUserDocuments.IDrivingLicenseSectionProps> = ({
  drivingLicenseDetails,
  handlePreviewUploadedDoc,
  handleDeleteDoc,
  handleChangeUpload,
  setDrivingLicenseDetails,
  handleEverifyDL,
  isPDFfile,
  docDob,
  handleDlDobChangeHandler,
  buttonLoader,
  loading
}) => {
  const convertedDate = moment(docDob, "DD/MM/YYYY").toDate();
  const maxDate = moment(new Date()).format("YYYY-MM-DD").toString();
  const mixpanelToken: any = process.env.REACT_APP_MIXPANEL_TOKEN;
  mixpanel.init(mixpanelToken, {
    debug: true,
    api_host: process.env.REACT_APP_MIXPANEL_API,
  });

  return (
    <div className="col-span-4 lg:pl-10 lg:pt-7">
      <div>
        <div className="flex items-start justify-between">
          <div className="mb-5 text-base font-medium text-black font-lato">
            Upload Driving License<span className="text-error">*</span>
          </div>
          {drivingLicenseDetails?.uploadedDocError === "Please upload the right file format.." && (
            <div className="relative cursor-pointer">
              <div className="peer">
                <InfoIcon fillColor="fill-secondary" />
              </div>
              <Tooltip
                title="Your file cant upload"
                subtitle="Try file formats like jpg, png or pdf"
                positionOfTip="bottom"
                tooltipStyles="peer-hover:block hidden !top-8 !min-h-[96px] !min-w-[236px] !-translate-x-full z-1"
                titleClasses="font-semibold text-lg text-white !pt-3"
                subtitleClasses="text-sm font-normal text-white"
                buttonClasses="!w-0 h-0"
              />
            </div>
          )}
        </div>
        <div className="relative">
          <div className="flex items-center space-x-8">
            <Button
              id="sfkdj902d987-983nsa"
              bgColor="bg-primary-350"
              fontStyle={{
                fontFamily: "",
                fontSize: "text-sm",
                fontWeight: "font-normal ",
                fontColor: "text-black-200",
              }}
              borderStyle={{
                borderColor: drivingLicenseDetails.uploadedDocError
                  ? "border-error border"
                  : drivingLicenseDetails.isDLFrontUploaded || drivingLicenseDetails.isDlDocVerified
                    ? "border-success border"
                    : "border-none",
                borderRadius: "rounded",
              }}
              className={clsx(
                drivingLicenseDetails.uploadedDocError ? "" : "mb-5",
                "w-full h-[68px] lg:!w-[48%]",
              )}
            >
              <div className="flex items-center justify-between w-full px-4 lg:px-6">
                <div className="flex items-center space-x-2 lg:space-x-6">
                  <PassportIcon
                    width="23px"
                    height="29px"
                    fillColor={
                      drivingLicenseDetails.isDlDocVerified ? "fill-success-150" : "fill-primary"
                    }
                  />
                  <div>Upload DL Front</div>
                </div>
                <div className="flex items-center space-x-4">
                  {drivingLicenseDetails.isDLFrontUploaded &&
                    !drivingLicenseDetails.isDlDocVerified &&
                    !isPDFfile && (
                      <div
                        className="z-50 cursor-pointer"
                        onClick={() => handlePreviewUploadedDoc("DRIVING_LICENCE_FRONT")}
                      >
                        <VisibilityOnIcon fillColor="fill-black-150" />
                      </div>
                    )}
                  {drivingLicenseDetails.isDLFrontUploaded &&
                    !drivingLicenseDetails.isDlDocVerified &&
                    !drivingLicenseDetails.isDLBackUploaded && (
                      <div className="z-50 cursor-pointer" onClick={handleDeleteDoc}>
                        <ReUploadIcon
                          fillColor={
                            drivingLicenseDetails?.uploadedDocError ? "fill-error" : "fill-black"
                          }
                        />
                      </div>
                    )}
                  {!drivingLicenseDetails.isDLFrontUploaded && (
                    <UploadIcon
                      fillColor={
                        drivingLicenseDetails.isDlDocVerified ? "fill-success-150" : "fill-primary"
                      }
                    />
                  )}
                </div>
              </div>
            </Button>
          </div>
          {drivingLicenseDetails.uploadedDocError !== "" && (
            <ErrorField
              errorMessage={drivingLicenseDetails.uploadedDocError}
              errorClassname="mb-5"
            />
          )}
          <form encType="multipart/form-data" action="">
            <TextField
              accept="image/*,application/pdf"
              name="drivingLicenseFront"
              type="file"
              disabled={drivingLicenseDetails.isDLFrontUploaded}
              rootClassName="absolute top-0 opacity-0 !w-full lg:!w-[48%]"
              onChange={(e) => handleChangeUpload(e, "DRIVING_LICENCE")}
              onClick={(event) => {
                event.currentTarget.value = "";
              }}
            />
          </form>
        </div>
        <div className="relative">
          <div className="flex items-center space-x-8">
            <Button
              id="2sn9nkj87-983nsa"
              bgColor="bg-primary-350"
              fontStyle={{
                fontFamily: "",
                fontSize: "text-sm",
                fontWeight: "font-normal ",
                fontColor: "text-black-200",
              }}
              borderStyle={{
                borderColor:
                  drivingLicenseDetails.isDLBackUploaded || drivingLicenseDetails.isDlDocVerified
                    ? "border-success border"
                    : "border-none",
                borderRadius: "rounded",
              }}
              className="w-full mb-5 h-[68px] lg:!w-[48%]"
            >
              <div className="flex items-center justify-between w-full px-4 lg:px-6">
                <div className="flex items-center space-x-2 lg:space-x-6">
                  <PassportIcon
                    width="23px"
                    height="29px"
                    fillColor={
                      drivingLicenseDetails.isDlDocVerified ? "fill-success-150" : "fill-primary"
                    }
                  />
                  <div>Upload DL Back</div>
                </div>
                <div className="flex items-center space-x-4">
                  {drivingLicenseDetails.isDLBackUploaded &&
                    !drivingLicenseDetails.isDlDocVerified &&
                    !isPDFfile && (
                      <div
                        className="z-50 cursor-pointer"
                        onClick={() => {
                          handlePreviewUploadedDoc("DRIVING_LICENCE_BACK");
                          mixpanel.track("Upload Driving License Clicked");
                        }}
                      >
                        <VisibilityOnIcon fillColor="fill-black-150" />
                      </div>
                    )}
                  {drivingLicenseDetails.isDLBackUploaded &&
                    !drivingLicenseDetails.isDlDocVerified && (
                      <div className="z-50 cursor-pointer" onClick={handleDeleteDoc}>
                        <ReUploadIcon
                          fillColor={
                            drivingLicenseDetails?.uploadedDocError ? "fill-error" : "fill-black"
                          }
                        />
                      </div>
                    )}
                  {!drivingLicenseDetails.isDLBackUploaded && (
                    <UploadIcon
                      fillColor={
                        drivingLicenseDetails.isDlDocVerified ? "fill-success-150" : "fill-primary"
                      }
                    />
                  )}
                </div>
              </div>
            </Button>
          </div>
          <form encType="multipart/form-data" action="">
            <TextField
              accept="image/*,application/pdf"
              name="drivingLicenseBack"
              type="file"
              disabled={
                drivingLicenseDetails.isDLBackUploaded || !drivingLicenseDetails.isDLFrontUploaded
              }
              rootClassName="absolute top-0 opacity-0 !w-full lg:!w-[48%]"
              onChange={(e) =>
                setDrivingLicenseDetails((preValue) => {
                  return {
                    ...preValue,
                    dlBackFile: URL.createObjectURL(e.target.files[0]),
                    isDLBackUploaded: true,
                    dlBackImageFileName: e.target.files[0].name,
                  };
                })
              }
              onClick={(event) => {
                event.currentTarget.value = "";
              }}
            />
          </form>
        </div>
        <div className="flex flex-col items-start w-full space-x-8 lg:items-start lg:flex-row">
          <div className="w-full">
            <div className="mb-5 text-base font-medium text-black font-lato">
              Driving License No<span className="text-error">*</span>
            </div>
            <TextField
              name="drivingLicenseNumber"
              type="text"
              placeholderStyle="!text-primary-850"
              inputClasses={clsx(
                drivingLicenseDetails.isDlDocVerified ? "border-success-150" : "border-grey-150",
                "border !pt-1 lg:!w-full",
              )}
              value={
                drivingLicenseDetails?.isDLFrontUploaded ? drivingLicenseDetails?.dlNumber : ""
              }
              onChange={(event) => {
                setDrivingLicenseDetails((preValue: any) => {
                  return {
                    ...preValue,
                    dlNumber: event.target.value,
                  };
                });
              }}
              error={drivingLicenseDetails.errorMessageDLVerified !== ""}
              disabled={!drivingLicenseDetails?.isDLFrontUploaded}
              fullWidth
              pattern="[a-zA-Z0-9 ]{1,}"
            />
            <div
              className={clsx(
                drivingLicenseDetails.errorMessageDLVerified !== ""
                  ? "text-error"
                  : drivingLicenseDetails.messageDLVerified !== ""
                    ? "text-success"
                    : "",
                " font-ssp font-medium text-sm mt-7",
              )}
            >
              {drivingLicenseDetails.errorMessageDLVerified === ""
                ? drivingLicenseDetails.messageDLVerified
                : drivingLicenseDetails.errorMessageDLVerified}
            </div>
          </div>
          <div className="w-full !ml-0 md:!ml-8">
            <div className="mb-5 text-base font-medium text-black font-lato">DOB as per DL</div>
            <DatePicker
              selectedDate={
                docDob === "" ? new Date().toString() : moment(convertedDate).format("YYYY-MM-DD")
              }
              dateChangeHandler={handleDlDobChangeHandler}
              datepickerClassname="bg-transparent !p-4 !rounded border !border-grey-150 w-[400px] ml-0 lg:w-[300px]"
              disabled={
                !drivingLicenseDetails?.isDLFrontUploaded || drivingLicenseDetails?.isDlDocVerified
              }
              maxDate={maxDate}
            />
          </div>
        </div>

        {!drivingLicenseDetails.isDlDocVerified && (
          <Button
            id="2sn9nkj87-8987nx76"
            bgColor={
              drivingLicenseDetails.isDLFrontUploaded && drivingLicenseDetails.isDLBackUploaded
                ? "bg-primary"
                : "bg-grey-450"
            }
            fontStyle={{
              fontFamily: "",
              fontSize: "text-base",
              fontWeight: "font-bold",
              fontColor:
                drivingLicenseDetails.isDLFrontUploaded && drivingLicenseDetails.isDLBackUploaded
                  ? "text-white"
                  : "text-black-650",
            }}
            borderStyle={{
              borderWidth: "border-none",
              borderRadius: "rounded-full lg:rounded",
            }}
            className={"py-5 w-4/5 m-auto lg:mx-0 mt-9 lg:!w-[24%]"}
            disabled={
              !drivingLicenseDetails.isDLFrontUploaded || !drivingLicenseDetails.isDLBackUploaded
            }
            onClickHandler={handleEverifyDL}
            isLoading={buttonLoader.drivingLoader}
          >
            {loading ? (
              <div className="flex items-center justify-center animate-spin-slow py-1">
                <img src="/images/svg/smallLoader.svg" alt="" />
              </div>
            ) : (
              "Verify"
            )}
          </Button>
        )}
      </div>
    </div>
  );
};

export default DrivingLicenseSection;
