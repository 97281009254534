import React, { FunctionComponent } from "react";
import Button from "../../components/ui/Button";
import RecipientProps from "./Recipient";
import { motion } from "framer-motion";
import mixpanel from "mixpanel-browser";
// import TextField from "../../components/ui/TextField";
import { DropdownIcon } from "../../assets/Icons";
import clsx from "clsx";
import { TextField } from "@mui/material";

export const StepTwo: FunctionComponent<RecipientProps.StepTwo> = ({
  setIsModalOpen,
  category,
  setCity,
  city,
  setAddressOne,
  addressOne,
  setAddressTwo,
  addressTwo,
  country,
  setPin,
  pin,
  setStep,
  relationship,
}) => {
  const enabled =
    "w-[200px] h-12 rounded-full bg-primary text-white font-bold text-[18px] mt-10 lg:mt-26";
  const disabled =
    "w-[200px] h-12 rounded-full bg-gray-500 text-white font-bold text-[18px] mt-10 lg:mt-26";
  const btnDisable =
    (category === "Individual" && relationship === "") ||
    pin === "" ||
    addressOne === "" ||
    city === "" ||
    country === "";

  const mixpanelToken: any = process.env.REACT_APP_MIXPANEL_TOKEN;
  mixpanel.init(mixpanelToken, {
    debug: true,
    api_host:process.env.REACT_APP_MIXPANEL_API,
  });
  // const specialCharRegExp = "[a-zA-Z0-9 ]{1,}";

  return (
    <motion.div
      className="flex flex-col items-center w-full"
      initial={{ width: 0 }}
      animate={{ width: "100%" }}
      exit={{ x: window.innerWidth, transition: { duration: 0.5 } }}
    >
      <div className="w-[90%] lg:w-[70%] 2xl:w-[50%] mt-24">
        <div className="w-[85%] lg:w-[72%] mx-auto">
          <div className="flex flex-col ml-4">
            <div className="flex flex-col items-center justify-between w-full mb-4 space-y-4 lg:space-y-0 lg:space-x-8 lg:flex-row">
              <div
                onClick={() => {
                  setIsModalOpen("relationship");
                }}
                className={clsx(
                  // category === "Individual"
                    "flex items-center justify-between w-full p-5 text-gray-400 bg-white border-2 border-gray-300 rounded-md cursor-pointer h-14"
                    // : "flex items-center justify-between w-full p-5 text-gray-400 bg-gray-300 cursor-not-allowed  border-2 border-gray-300 rounded-md h-14",
                )}
              >
                {relationship ? (
                  <div className="flex items-center justify-between w-full">
                    <span className="text-black">{relationship}</span>
                    <div>
                      <DropdownIcon fillColor="" />
                    </div>
                  </div>
                ) : (
                  <div className="flex items-center justify-between w-full">
                    <span className="text-black text-[14px]">Relationship *</span>
                    {/* <DropdownIcon fillColor="" /> */}
                    <div>
                      <DropdownIcon fillColor="" />
                    </div>
                  </div>
                )}
              </div>
              <div
                onClick={() => setIsModalOpen("country")}
                className="flex items-center justify-between w-full p-5 h-full text-black text-[14px] bg-white border-2 border-gray-300 rounded-md cursor-pointer focus:outline-none focus:border mb-4"
              >
                {country ? (
                  <div className="flex items-center justify-between w-full">
                    <span className="text-black">{country}</span>
                    <div>
                      <DropdownIcon fillColor="" />
                    </div>
                  </div>
                ) : (
                  <div className="flex items-center justify-between w-full">
                    <span>Country of Residence *</span>
                    <div>
                      <DropdownIcon fillColor="" />
                    </div>
                  </div>
                )}
              </div>
            </div>
            <div className="flex flex-col items-center justify-between mb-4 lg:flex-row lg:space-x-8">
              <TextField
                // className="w-full p-5 py-[30px] font-medium text-black border-2 border-gray-300 rounded-md h-14"
                className="w-full mb-4 bg-white border-2 border-gray-300 rounded-md lg:mb-0"
                onChange={(e) => setCity(e.target.value)}
                value={city}
                type="text"
                label="City *"
                tabIndex={3}
              />
              <div className="mb-4 lg:hidden" />
              <TextField
                className="w-full mb-4 bg-white border-2 border-gray-300 rounded-md lg:mb-0"
                name="username"
                fullWidth
                required
                onChange={(e) => setPin(e.target.value)}
                value={pin}
                type="text"
                label="Pin Code"
                tabIndex={4}
              />
            </div>
            <div className="flex flex-col items-center justify-between mb-4 lg:flex-row lg:space-x-8">
              <TextField
                name="code"
                fullWidth
                onChange={(e) => setAddressOne(e.target.value)}
                value={addressOne}
                type="text"
                label="Address Line 1 "
                className="w-[300px] p-5 border-2 border-black h-14 bg-white rounded-md text-black font-medium"
                tabIndex={5}
                required
              />
              <div className="mb-4 lg:hidden" />
              <TextField
                name="refrence"
                fullWidth
                onChange={(e) => setAddressTwo(e.target.value)}
                value={addressTwo}
                type="text"
                label="Address Line 2"
                className="w-[300px] p-5 border-2 border-black h-14 bg-white rounded-md text-black font-medium"
                tabIndex={6}
              />
            </div>
          </div>
        </div>
      </div>

      <div className="flex items-center space-x-5">
        <Button
          id="goo-back-btn-step-3"
          onClickHandler={() => setStep(1)}
          className="w-[200px] bg-blue-200 h-12 rounded-full text-primary font-bold text-[18px] mt-10 lg:mt-26"
        >
          Go Back
        </Button>
        <Button
          id="submit-btn-step-3"
          onClickHandler={() => {
            mixpanel.track("Save and Proceed on Add Recipient Step 2", {
              "Recipient Category": category,
              "Country Of Residence": country,
              "City ": city,
              "Pin Code": pin,
              "Address Line 1": addressOne,
              "Address Line 2": addressTwo,
            });
            setStep(3);
          }}
          disabled={btnDisable}
          className={btnDisable ? disabled : enabled}
        >
          Save & Proceed
        </Button>
      </div>
    </motion.div>
  );
};
