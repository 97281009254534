/* eslint-disable */
import { Alert, AlertTitle, Snackbar } from "@mui/material";
import React, { useEffect, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import { CloseIcon, FlagIcon, LeftArrowIcon } from "../../../assets/Icons";
import {
  inwardKycStatus,
  uploadInwardAddressDocuments,
  uploadInwardDocuments,
} from "../../../services/inward";
import { inwardAddressProofDocList, inwardIdentityProofDocList } from "../../../utils";
import KycIncomplete from "../../userDocuments/KycModals/kycStatusModals/kycIncomplete";
import UnverifiedKycModal from "../../userDocuments/KycModals/UnverifiedKycModal";
import { InwardDocumentWindows } from "./documentWindows";
import { Modals } from "./Modals";
import KycVerifiedAndIsAgreeModal from "../../userDocuments/KycModals/KycVerifiedAndIsAgreeModal";
import { useRecoilState, useRecoilValue } from "recoil";
import { confirmedPurpose, transactionDetails } from "../../../store";
import { additionalDocumentsList } from "../../../utils";
import { additionalIsTcsDocumentsList } from "../../../utils";
import { ToastContainer, toast } from "react-toastify";
import UnderReview from "../../userDocuments/KycModals/kycStatusModals/underReview";
import { format } from "date-fns";
import mixpanel from "mixpanel-browser";

export const InwardDocs = () => {
  const { state } = useLocation();
  let navigationState = state;

  const navigate = useNavigate();

  const [documentWindow, setDocumentWindow] = useState<string>("");
  const [identityError, setIdentityError] = useState<string>("");
  const [stepCompleted, setStepCompleted] = useState({
    identityApproved: false,
    addressApproved: false,
  });

  const [showKycIncompleteModal, setShowKycIncompleteModal] = useState(false);
  const [showStartKycModal, setShowStartKycModal] = useState("");
  const [isModalOpen, setIsModalOpen] = useState("");
  const [sourceOfFund, setSourceOfFund] = useState("");
  const [remmitanceFrequency, setRemmitanceFrequency] = useState("");
  const [docType, setDocType] = useState("");
  const [isLoading, setIsLoading] = useState(false);
  const [isAgree, setIsAgree] = React.useState<boolean>(false);
  const [selectedPurpose, setSelectedPurpose] = useRecoilState(confirmedPurpose);
  const [showKycUnderReviewModal, setShowKycUnderReviewModal] = React.useState(false);
  const [selectedOption, setSelectedOption] = React.useState("");

  useEffect(() => {
    if (navigationState?.kycStatus === "AWAITING_FOR_DOCUMENT") {
      setStepCompleted({ ...stepCompleted, identityApproved: true });
      setShowKycIncompleteModal(true);
    } else if (navigationState?.kycStatus === "YET_TO_START") {
      setShowStartKycModal("1");
    }
  }, [navigationState]);

  useEffect(() => {
    console.log("entered");
    console.log(documentWindow);
    if (documentWindow === "utilitybill" || documentWindow === "statement") {
      setAddressData({ ...addressData, doc_type: "BANK_STATEMENT_OR_UTILITY_BILL" });
    } else if (documentWindow === "taxbill") {
      setAddressData({ ...addressData, doc_type: "LOCAL_AUTHORITY_TAX_BILL" });
    } else if (documentWindow === "passport") {
      setIdentityData({ ...identityData, doc_type_code: "PASSPORT" });
    } else if (documentWindow === "dl") {
      setIdentityData({ ...identityData, doc_type_code: "EEA_OR_UK_PHOTO_CARD_DRIVING_LICENCE" });
    } else if (documentWindow === "idcard") {
      setIdentityData({ ...identityData, doc_type_code: "EEA_NATIONAL_ID_CARD" });
    } else if (documentWindow === "permit") {
      setIdentityData({ ...identityData, doc_type_code: "EU_NATIONAL_RESIDENT_PERMIT" });
    }
  }, [documentWindow]);

  interface IdentityData {
    file: any;
    docNumber: string;
    name: string;
    gender: string;
    doc_dob: string;
    validTill: string;
    title: string;
    doc_type_code: string;
    nationality: string;
    issueCountry: string;
  }
  interface AddressData {
    file: any;
    addOne: any;
    addTwo: any;
    country: any;
    pincode: any;
    city: any;
    state: any;
    source: any;
    frequency: any;
    doc_type: any;
  }

  const [identityData, setIdentityData] = useState<IdentityData>({
    file: null,
    docNumber: "",
    name: "",
    gender: "",
    doc_dob: "",
    validTill: "",
    title: "MR.",
    nationality: "",
    doc_type_code: "PASSPORT",
    issueCountry: "",
  });

  const [addressData, setAddressData] = useState<AddressData>({
    file: null,
    addOne: "",
    addTwo: "",
    country: "",
    pincode: "",
    city: "",
    state: "",
    source: "",
    frequency: "",
    doc_type: "BANK_STATEMENT_OR_UTILITY_BILL",
  });

  const stepOneDisabled = Object.values(identityData).some(
    (value) => value === "" || value === null,
  );

  const stepTwoDisabled = Object.values(addressData).some(
    (value) => value === "" || value === null,
  );

  useEffect(() => {
    console.log(identityData, "hahahahha");
    console.log(addressData);
    console.log(stepOneDisabled);
    console.log(stepTwoDisabled);
  });
  const dob_date = new Date(identityData?.doc_dob);
  const valid_date = new Date(identityData?.validTill);
  const dobFormattedDate = `${dob_date.getDate()}/${
    dob_date.getMonth() + 1
  }/${dob_date.getFullYear()}`;
  const validFormattedDate = `${valid_date.getDate()}/${
    valid_date.getMonth() + 1
  }/${valid_date.getFullYear()}`;

  const handleIdentitySubmit = () => {
    mixpanel.track("Submit 1 of 2")
    setIsLoading(true);
    const data = new FormData();
    data.append("file", identityData?.file);
    data.append("identityNumber", identityData?.docNumber);
    data.append("title", identityData?.title);
    data.append("name", identityData?.name);
    data.append("gender", identityData?.gender);
    data.append("doc_dob", dobFormattedDate);
    data.append("docValidity", validFormattedDate);
    data.append("doc_type_code", identityData?.doc_type_code);
    data.append("nationality", identityData?.nationality);
    data.append("identityIssuedCountry", identityData?.issueCountry);
    uploadInwardDocuments(data)
      .then((response: any) => {
        setIsLoading(false);
        console.log("hahahaha", response?.response?.data?.data?.error?.type);
        setIdentityError(response?.response?.data?.data?.error?.type);
        setTimeout(() => {
          setIdentityError("");
        }, 3000);
        // setDocumentWindow("taxbill");
        setDocumentWindow("");
        setStepCompleted({ ...stepCompleted, identityApproved: true });
      })
      .catch((error) => {
        setIsLoading(false);
        console.log("hahahaha", error?.response?.data?.data?.error?.type);
      });
  };

  const handleAddressProofSubmit = () => {
    setIsLoading(true);
    const data = new FormData();
    data.append("file", addressData?.file); // this thing aint wokring lmfao
    data.append("addressLine11", addressData?.addOne);
    data.append("addressLine2", addressData?.addTwo);
    data.append("city", addressData?.city);
    data.append("state", addressData?.state);
    // data.append("country", addressData?.country);
    data.append("country", addressData?.country);
    // data.append("docValidity", moment(addressData?.validTill).format("DD/MM/YYYY"));
    data.append("pincode", addressData?.pincode);
    data.append("sourceOfFunds", addressData?.source);
    data.append("frequency", addressData?.frequency);
    data.append("doc_type_code", addressData?.doc_type);
    console.log("entered here");
    uploadInwardAddressDocuments(data)
      .then((response: any) => {
        console.log("entered here");
        setStepCompleted({ ...stepCompleted, addressApproved: true });
        if (response?.response?.data?.data?.error?.type) {
          console.log("entered here");
          setIdentityError(response?.response?.data?.data?.error?.type);
          setIsLoading(false);
          toast.error("Inward client address upload failed, Please try again after some time.");
        } else {
          console.log("entered here");
          inwardKycStatus()
            .then((res) => {
              console.log("entered here");
              setIsLoading(false);
              if (res?.data?.status === "VERIFIED") {
                console.log("entered here");
                navigate("/inward/pick-recipient");
              } else if (
                res?.data?.status === "PENDING" ||
                res?.data?.status === "DOCUMENTS_UPLOADED" ||
                res?.data?.status === "NEW"
              ) {
                console.log("entered here");
                setShowKycUnderReviewModal(true);
                // navigate("/pick-recipient");
              }
            })
            .catch((err) => {
              console.log("entered here");
              window.console.log(err);
            });
        }
        // setTimeout(() => {
        //   setIdentityError("");
        // }, 3000);
        // setDocumentWindow("taxbill");
        // navigate('/send-money)
      })
      .catch((error) => {
        setIsLoading(false);
        alert("error");
        console.log("hahahaha", error?.response?.data?.data?.error?.type);
      });
  };

  const transactionDetailsData = useRecoilValue(transactionDetails);

  const [additionalDocData, setAdditionalDocData] = React.useState(
    transactionDetailsData?.tcs === 0 ? additionalDocumentsList : additionalIsTcsDocumentsList,
  );

  const listOfPurposeforAdditionalDocuments = additionalDocData.map((data) => data.name);

  const [isMobileDocumentListOpen, setIsMobileDocumentListOpen] = React.useState<boolean>(false);

  const boxClass =
    "flex items-center justify-between w-full px-8 py-4 transition-all bg-white border border-gray-600 rounded-lg cursor-pointer hover:scale-105 hover:shadow-xl hover:bg-blue-100";
  const selected =
    "flex items-center justify-between w-full px-8 py-4 transition-all bg-gradient-to-br from-blue-100 via-blue-300 to-blue-500 border border-gray-600 rounded-lg cursor-pointer hover:scale-105 hover:shadow-xl hover:bg-blue-100";

  return (
    <div className="flex flex-col w-full h-full mt-0 lg:justify-center lg:py-4 lg:flex-row lg:items-start lg:mt-10">
      <ToastContainer />
      {showKycUnderReviewModal && (
        <UnderReview
          showKycUnderReviewModal={showKycUnderReviewModal}
          setShowKycUnderReviewModal={setShowKycUnderReviewModal}
        />
      )}
      <div className="flex justify-between items-center bg-white md:hidden w-full py-[25px] pr-[22px] pl-7 mb-6">
        <div className="flex items-center w-fit" onClick={() => setDocumentWindow("")}>
          <LeftArrowIcon />
          <div className="ml-6 text-xl font-semibold text-grey-1100">KYC</div>
        </div>
        <div className="flex items-center">
          <div
            className="mr-3 text-sm font-normal text-primary"
            onClick={() => navigate("/support")}
          >
            Need help ?
          </div>
          <div
            className="bg-gray-400 w-[30px] h-[30px] flex justify-center items-center rounded-full"
            onClick={() => setIsModalOpen("cancelReasons")}
          >
            <CloseIcon />
          </div>
        </div>
      </div>
      <KycIncomplete
        showKycIncompleteModal={showKycIncompleteModal}
        setShowKycIncompleteModal={setShowKycIncompleteModal}
        setDocumentWindow={setDocumentWindow}
      />
      <UnverifiedKycModal isModalOpen={showStartKycModal} setIsModalOpen={setShowStartKycModal} />
      <KycVerifiedAndIsAgreeModal
        isModalOpen={showStartKycModal}
        setIsModalOpen={setShowStartKycModal}
        isAgree={isAgree}
        setIsAgree={setIsAgree}
        kycVerificationAgreementNote={""}
        selectedPurpose={selectedPurpose}
        listOfPurposeforAdditionalDocuments={listOfPurposeforAdditionalDocuments}
        // selectedPurpose={}
        // listOfPurposeforAdditionalDocuments={listOfPurposeforAdditionalDocuments}
        setDocType={setDocType}
        setIsMobileDocumentListOpen={setIsMobileDocumentListOpen}
      />
      {/* <div>Upload</div> */}
      <div className="flex flex-col lg:items-start items-center justify-between h-auto lg:flex-row lg:w-[85%]">
        <div
          id="left-side"
          className={`flex flex-col lg:w-[30%] border shadow-2xl rounded-xl bg-white ${
            documentWindow !== "" ? "hidden lg:flex" : "flex"
          }`}
        >
          <div
            id="proofIdentity"
            // className="mt-8"
            className={`mt-8 ${
              stepCompleted.identityApproved ? "opacity-50 pointer-events-none" : ""
            }`}
          >
            <span onClick={() => setDocumentWindow("")} className="font-bold text-[22px] p-5">
              Select Proof of Identity (any one)
            </span>
            <div className="px-5 mt-8 space-y-2">
              {inwardIdentityProofDocList?.map((item, key) => {
                return (
                  <div
                    key={key}
                    className={documentWindow === item.slug ? selected : boxClass}
                    onClick={() => {
                      setDocumentWindow(item.slug);
                      setDocType(item?.docKey);
                    }}
                  >
                    <div id="left" className="flex flex-col justify-evenly">
                      <span className="font-bold lg:text-[18px]">{item.title}</span>
                      <span>{item.subtitle}</span>
                    </div>
                    <div id="right">
                      <FlagIcon fillColor="fill-grey-550" />
                    </div>
                  </div>
                );
              })}
            </div>
          </div>
          <div
            id="proofIdentity"
            onClick={() => setSelectedOption("")}
            className={`my-8 ${
              stepCompleted?.addressApproved === false && stepCompleted?.identityApproved == false
                ? "opacity-50 pointer-events-none"
                : ""
            }`}
          >
            <span className="font-bold text-[22px] p-5">Select Proof of Address (any one)</span>
            <div className="px-5 mt-8 space-y-2">
              {inwardAddressProofDocList?.map((item, key) => {
                return (
                  <div
                    key={key}
                    className={documentWindow === item.slug ? selected : boxClass}
                    onClick={() => {
                      setDocumentWindow(item.slug);
                      setDocType(item?.docKey);
                    }}
                  >
                    <div id="left" className="flex flex-col justify-evenly">
                      <span className="font-bold lg:text-[18px]">{item.title}</span>
                      <span>{item.subtitle}</span>
                    </div>
                    <div id="right">
                      <FlagIcon fillColor="fill-grey-550" />
                    </div>
                  </div>
                );
              })}
            </div>
          </div>
        </div>
        <div id="right-side" className="w-full h-full lg:w-2/3">
          <div className="flex flex-col items-center w-full h-full">
            <div className="w-full">
              <InwardDocumentWindows
                documentWindow={documentWindow}
                setDocumentWindow={setDocumentWindow}
                handleIdentitySubmit={handleIdentitySubmit}
                identityData={identityData}
                setIdentityData={setIdentityData}
                addressData={addressData}
                setAddressData={setAddressData}
                stepCompleted={stepCompleted}
                remmitanceFrequencyP={remmitanceFrequency}
                setRemmitanceFrequencyP={setRemmitanceFrequency}
                sourceOfFundP={sourceOfFund}
                setSourceOfFundP={setSourceOfFund}
                isModalOpenP={isModalOpen}
                setIsModalOpenP={setIsModalOpen}
                selectedOption={selectedOption}
                setSelectedOption={setSelectedOption}
              />
            </div>
            {(documentWindow === "passport" ||
              documentWindow === "dl" ||
              documentWindow === "permit" ||
              documentWindow === "idcard") && (
              <div className="flex flex-col items-center w-full mt-6 lg:mt-0 lg:mr-20">
                <button
                  onClick={handleIdentitySubmit}
                  disabled={isLoading || stepOneDisabled}
                  className={`flex px-20 py-5 font-bold text-white transition-all border rounded-lg ${
                    isLoading || stepOneDisabled
                      ? "bg-gray-400 cursor-not-allowed"
                      : "bg-primary hover:text-primary hover:bg-white hover:border-2 hover:border-blue-400"
                  }`}
                >
                  <div>Submit 1 of 2</div>
                  {isLoading ? (
                    <div className="flex items-center justify-center ml-2 animate-spin-slow">
                      <img src="/images/svg/smallLoader.svg" alt="" />
                    </div>
                  ) : null}
                </button>
                {identityError && (
                  <Snackbar open={identityError !== ""} autoHideDuration={6000}>
                    <Alert severity="error" variant="filled">
                      <AlertTitle>Error</AlertTitle>
                      <strong>{identityError}</strong>
                    </Alert>
                  </Snackbar>
                )}
              </div>
            )}
            {(documentWindow === "taxbill" ||
              documentWindow === "utilitybill" ||
              documentWindow === "statement") && (
              <div className="flex justify-center w-full lg:mr-20">
                <button
                  onClick={handleAddressProofSubmit}
                  className={`flex px-20 py-5 font-bold text-white transition-all border rounded-lg ${
                    isLoading || stepTwoDisabled
                      ? "bg-[#9A9A9A] cursor-not-allowed"
                      : "bg-primary hover:text-primary hover:bg-white hover:border-2 hover:border-blue-400"
                  }`}
                  disabled={isLoading || stepTwoDisabled}
                >
                  <span>Submit 2 of 2</span>
                  {isLoading ? (
                    <div className="flex items-center justify-center ml-2 animate-spin-slow">
                      <img src="/images/svg/smallLoader.svg" alt="" />
                    </div>
                  ) : null}
                </button>
              </div>
            )}
            {documentWindow === "" && (
              <div className="flex-col items-center justify-center hidden w-full md:flex">
                <div className="font-bold text-[28px] font-sans">
                  Select a card from the section on the left
                </div>
                <div>
                  <img src="/images/png/inward_image.png" alt="" className="hidden lg:flex" />
                </div>
              </div>
            )}
          </div>
        </div>
      </div>
      <Modals
        isModalOpenP={isModalOpen}
        setIsModalOpenP={setIsModalOpen}
        remmitanceFrequencyP={remmitanceFrequency}
        setRemmitanceFrequencyP={setRemmitanceFrequency}
        sourceOfFundP={sourceOfFund}
        setSourceOfFundP={setSourceOfFund}
      />
    </div>
  );
};
