import { IButton } from "../Button/Button";
import React, { useEffect, useState } from "react";

const CountdownTimer: React.FC<IButton.ICountdown> = ({ timeInSeconds }) => {
  const [countdown, setCountdown] = useState({
    hours: 0,
    minutes: 0,
    seconds: 0,
  });

  useEffect(() => {
    const interval = setInterval(() => {
      const hours = Math.floor(timeInSeconds / 3600);
      const minutes = Math.floor((timeInSeconds % 3600) / 60);
      const seconds = Math.floor(timeInSeconds % 60);

      setCountdown({ hours, minutes, seconds });

      if (timeInSeconds > 0) {
        timeInSeconds--;
      } else {
        clearInterval(interval);
      }
    }, 1000);

    return () => {
      clearInterval(interval);
    };
  }, [timeInSeconds]);

  return (
    <span className="px-3 py-2 rounded-md text-[20px] text-red-500 space-x-3 font-bold">
      {countdown.hours}:{countdown.minutes}:{countdown.seconds}
    </span>
  );
};

export default CountdownTimer;
