import React from "react";
import { IIcons } from "./icons";

const Two = ({ fillColor }: IIcons.IconProps) => (
  <svg
    width="71"
    height="71"
    viewBox="0 0 71 71"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    className={fillColor}
  >
    <path
      d="M65.2473 40.0438C67.9231 23.4431 56.7289 7.80114 40.2442 5.10647C23.7596 2.4118 8.22692 13.6849 5.55109 30.2855C2.87525 46.8862 14.0695 62.5282 30.5542 65.2229C47.0388 67.9176 62.5715 56.6445 65.2473 40.0438Z"
      fill="#FF5B00"
    />
    <path
      d="M51.2149 9.17545C54.2267 13.909 55.9721 19.5391 55.9721 25.5766C55.9721 42.3922 42.4349 56.0282 25.7333 56.0282C19.9331 56.0282 14.5161 54.3813 9.91504 51.5305C15.2895 59.9794 24.6939 65.5774 35.3966 65.5774C52.0947 65.5774 65.6354 51.9449 65.6354 35.1258C65.6354 24.1476 59.8672 14.5306 51.2184 9.17188L51.2149 9.17545Z"
      fill="#EA4C00"
    />
    <path
      d="M9.26526 38.3814C9.26526 22.7662 21.834 10.1089 37.3401 10.1089C46.4039 10.1089 54.4602 14.4352 59.5935 21.1478C54.744 12.674 45.6589 6.96875 35.2506 6.96875C19.7445 6.96875 7.17578 19.626 7.17578 35.2412C7.17578 41.7288 9.34684 47.7055 12.9972 52.4747C10.6239 48.3271 9.26526 43.515 9.26526 38.3849V38.3814Z"
      fill="#FF823D"
    />
    <path
      d="M24.2542 33.2774C25.4846 33.2774 26.482 31.5836 26.482 29.4942C26.482 27.4047 25.4846 25.7109 24.2542 25.7109C23.0238 25.7109 22.0264 27.4047 22.0264 29.4942C22.0264 31.5836 23.0238 33.2774 24.2542 33.2774Z"
      fill="#303030"
    />
    <path
      d="M48.7642 29.4942C48.7642 31.5841 47.7674 33.2774 46.5364 33.2774C45.3054 33.2774 44.3086 31.5841 44.3086 29.4942C44.3086 27.4043 45.3054 25.7109 46.5364 25.7109C47.7674 25.7109 48.7642 27.4043 48.7642 29.4942Z"
      fill="#303030"
    />
    <path
      d="M47.7881 48.6966C48.5685 49.4575 49.8137 48.6144 49.4128 47.5999C47.1779 41.9768 41.7218 38.0078 35.3399 38.0078C28.958 38.0078 23.6829 41.8482 21.3841 47.3212C20.9655 48.3179 22.2213 49.1825 22.9947 48.4287C27.4184 44.1096 36.8157 37.9614 47.7881 48.6966V48.6966Z"
      fill="#5E4939"
    />
    <path
      d="M23.769 17.4198L21.7422 19.4609L28.5075 26.2739L30.5343 24.2328L23.769 17.4198Z"
      fill="#303030"
    />
    <path
      d="M49.0473 19.463L47.0205 17.4219L40.2552 24.2348L42.282 26.2759L49.0473 19.463Z"
      fill="#303030"
    />
  </svg>
);
export default Two;
