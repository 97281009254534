/* eslint-disable */
import React from "react";
import Button from "../../../../components/ui/Button";
import Modal from "../../../../components/ui/Modal";
import { Kycapproved } from "../../../../assets/Icons";
import mixpanel from "mixpanel-browser";

interface Props {
  showKycVerified?: any;
  setShowKycVerified?: any;
  setDocumentWindow?: any;
  handleNewlyVerifiedKyc?: any;
}

const KycApproved: React.FC<Props> = ({
  showKycVerified,
  setShowKycVerified,
  handleNewlyVerifiedKyc,
}) => {
  // const [visible, setVisible] = useState(false);
  const mixpanelToken: any = process.env.REACT_APP_MIXPANEL_TOKEN;
  mixpanel.init(mixpanelToken, {
    debug: true,
    api_host:process.env.REACT_APP_MIXPANEL_API,
  });
  return (
    <div>
      {/* <Button
        id="open-Modal-basic-btn"
        bgColor="bg-primary"
        fontStyle={{
          fontFamily: "font-roboto",
          fontSize: "text-base",
          fontWeight: "font-bold",
          fontColor: "text-white",
        }}
        borderStyle={{
          borderRadius: "rounded",
        }}
        className={"py-4 px-14"}
        onClickHandler={() => sets(true)}
      >
        kycApproved
      </Button> */}
      <Modal
        visible={showKycVerified}
        closeHandler={handleNewlyVerifiedKyc}
        modalClass="h-4/5 md:h-3/5 w-full md:!w-[968px] p-4 justify-evenly "
        headerClass="mt-0"
      >
        <div className="flex flex-col items-center text-center lg:flex-row lg:items-start lg:text-left">
          <div className="relative">
            <h2 className="mt-10 mb-10 text-4xl font-bold md:mt-4">Your document verification has been approved</h2>
            {/* <p className="font-normal text-xl  text-[#949494] mt-12 mb-12">
              <span className="font-bold">Note</span> - The process is completed within 24 hrs. In
              case of any help, you can reach us at care@moneyhop.co
            </p> */}
            <Button
              bgColor="bg-primary"
              className="hidden w-1/2 h-10 font-bold text-white rounded-full md:flex mt-7 "
              onClickHandler={()=>{
                mixpanel.track("KYC completed popup")
                handleNewlyVerifiedKyc()}}
            >
              Initiate Transaction
            </Button>
          </div>
          <div className="mt-16 md:mt-0">
            <Kycapproved className="" />
          </div>
          <Button
            bgColor="bg-primary"
            className="w-4/5 h-10 mt-16 font-bold text-white rounded-full md:w-40 md:hidden"
            onClickHandler={()=>{
              mixpanel.track("KYC completed popup")
              handleNewlyVerifiedKyc()}}
          >
            Initiate Transaction
          </Button>
        </div>
      </Modal>
    </div>
  );
};

export default KycApproved;
