import React from "react";
import { IIcons } from "./icons";

const DoubleVerifiedIcon = ({ fillColor }: IIcons.IconProps) => (
  <svg
    width="13"
    height="7"
    viewBox="0 0 13 7"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    className={fillColor}
  >
    <path
      d="M1.25 3.57143L3.19953 5.5L7.74845 1"
      stroke={fillColor || "#0F5BF1"}
      strokeLinecap="round"
    />
    <path
      d="M5.14935 3.57143L7.09889 5.5L11.6478 1"
      stroke={fillColor || "#0F5BF1"}
      strokeLinecap="round"
    />
  </svg>
);
export default DoubleVerifiedIcon;
