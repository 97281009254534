import React, { useState } from "react";
import { ICarousel } from "./Carousel";
// import Button from "../Button";

// const MIN_SWIPE_DISTANCE = 100;
const Carousel: React.FunctionComponent<ICarousel.IProps> = ({ children, visibleSlides }) => {
  const [activeSlide, setActiveSlide] = useState(0);
  const [touchStart, setTouchStart] = useState(0);
  const [touchEnd, setTouchEnd] = useState(0);

  const onDotsClicked = (index: number) => setActiveSlide(index);

  const onTouchStart = (e: React.TouchEvent) => {
    e.persist();
    setTouchEnd(0);
    setTouchStart(e.targetTouches[0].clientX);
  };

  const onTouchMove = (e: React.TouchEvent) => setTouchEnd(e.targetTouches[0].clientX);

  const onTouchEnd = () => {
    const noOfItems = React.Children.count(children);
    if (!touchStart || !touchEnd) return;
    const distance = touchStart - touchEnd;
    const minSwipeDistance = window.innerWidth / visibleSlides;

    if (distance < -minSwipeDistance) {
      setActiveSlide((prev) => (prev === 0 ? prev : prev - 1));
      return;
    }
    if (distance > minSwipeDistance) {
      setActiveSlide((prev) => (prev === noOfItems - 1 ? prev : prev + 1));
      return;
    }
  };

  const slideWidth = 100 / visibleSlides; // Calculate the width of each slide

  return (
    <>
      <div
        onTouchStart={onTouchStart}
        onTouchMove={onTouchMove}
        onTouchEnd={onTouchEnd}
        style={{ overflow: "hidden", width: "100vw", margin: "0 auto" }}
      >
        <div
          className="whitespace-nowrap"
          style={{
            transition: "all 0.3s linear",
            transform: `translateX(-${activeSlide * slideWidth}%)`,
          }}
        >
          <div className="inline-flex">{children}</div>
        </div>
      </div>
      <div className="flex justify-center gap-2 mt-4">
        {React.Children.map(children, (_, index) => (
          <button
            key={index}
            onClick={() => onDotsClicked(index)}
            className={`w-2 h-2 rounded-full ${
              index === activeSlide ? "bg-primary" : "bg-grey-950"
            }`}
          />
        ))}
      </div>
    </>
  );
};

export default Carousel;
