/* eslint-disable */
import React from "react";
import { motion } from "framer-motion";
// import { IForgotPassword } from "./ForgotPassword";
import { IChangePassword } from "./ChangePassword";
import Modal from "../../../components/ui/Modal";
import { forgotPassword, sendOtp, validateOtp } from "../../../services/auth";
import StepTwo from "../../auth/SignUp/StepTwo";
import StepThree from "./StepThree";
import ForgotPasswordModal from "../../../components/ui/ForgotPasswordModal";
import { CloseIcon, ForgotPasswordSuccessBanner } from "../../../assets/Icons";

const ForgotPassword: React.FC<IChangePassword.IProps> = ({
  isForgotPassword,
  setIsForgotPassword,
}) => {
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const [data, saveData] = React.useState({ email: "", mobile: "" });
  const [step, setStep] = React.useState<number>(1);
  const [otpValue, saveOtpValue] = React.useState<string>("");
  const [otpError, setOTPError] = React.useState<boolean>(false);
  const [isOTPVerified, setOTPVerified] = React.useState<boolean>(false);
  const [otpErrorMessage, setOTPErrorMessage] = React.useState<string>("");
  const [date, saveDate] = React.useState(Date.now() + 120000);
  const [dateKey, saveDateKey] = React.useState<number>(0);
  const [error, setError] = React.useState<string>("");
  const [selectionType, setSelectionType] = React.useState<string>("email");
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const [sendOtpError, setSendOtpError] = React.useState<string>("");
  const [newPassword, setNewPassword] = React.useState("");
  const [confirmPassword, setConfirmPassword] = React.useState("");
  const userEmail: any = sessionStorage.getItem("email");

  const verifyOTP = () => {
    validateOtp({
      email_phone: userEmail,
      otp: otpValue,
      otpType: selectionType,
    })
      .then((response: any) => {
        if (response?.success) {
          setOTPError(false);
          setOTPVerified(true);
          setTimeout(() => {
            setOTPErrorMessage("");
            setOTPVerified(false);
            // setStep(4);
            const commonBody = {
              otp: otpValue,
              newPassword: newPassword,
              confirmPassword: confirmPassword,
            };

            const body = { email: userEmail, ...commonBody };
            // selectionType === "mobile"
            //   ? { phoneNumber: data.mobile, ...commonBody }
            //   : { email: data.email, ...commonBody };

            forgotPassword(body)
              .then((response: any) => {
                if (response?.success) {
                  setIsForgotPassword(false);
                  setStep(5);
                } else {
                  setError(response?.response?.data?.data?.error?.type);
                  setTimeout(() => {
                    setError("");
                  }, 3000);
                }
              })
              .catch((error) => {
                setError(error.response.data.error.type);
                setTimeout(() => {
                  setStep(2);
                  setError("");
                }, 1000);
                console.error(error);
              });
          }, 1000);
        } else {
          setOTPErrorMessage(response?.data?.message);

          setOTPError(true);
        }
      })
      .catch((error) => {
        setOTPErrorMessage(error.response?.data?.error?.type);
        setOTPError(true);
      });
  };

  const resendOTP = () => {
    const body = { email: userEmail, otpType: selectionType };
    // selectionType === "mobile"
    //   ? { phoneNumber: data?.mobile, otpType: selectionType }
    //   : { email: data?.email, otpType: selectionType };
    sendOtp({
      body,
      apiType: "resend",
    })
      .then(() => {
        setOTPError(false);
        saveOtpValue("");
        setOTPErrorMessage("");
        saveDate(Date.now() + 120000);
        saveDateKey(dateKey + 1);
      })
      .catch((error) => {
        console.error(error);
      });
  };

  const saveNewPassword = (values: any) => {
    setNewPassword(values.password);
    setConfirmPassword(values.confirmPassword);

    const body = { email: userEmail, otpType: selectionType };
    sendOtp({
      body,
      apiType: "send",
    })
      .then(() => {
        setStep(2);
      })
      .catch((error) => {
        if (!error?.response?.data?.error?.success) {
          setSendOtpError(error?.response?.data?.error?.type);
        }
      });
  };

  return (
    <motion.div>
      <ForgotPasswordModal
        visible={isForgotPassword}
        step={step}
        modalClass="!w-full lg:!w-fit"
        closeHandler={() => {
          setIsForgotPassword(false),
            setStep(1),
            setOTPError(false),
            setSendOtpError(""),
            // setSelectionType(""),
            setOTPErrorMessage("");
        }}
      >
        <div className="flex flex-col items-center justify-between bg-opacity-100 mt-[88px] h-5/6">
          {/* {step === 4 && (
            <div className={"flex flex-col items-center px-3 h-full"}>
              <MobileEmailSelection
                selectionType={selectionType}
                setSelectionType={setSelectionType}
                setStep={setStep}
              />
            </div>
          )} */}
          {/* {step === 3 && (
            <div className={"flex flex-col items-center px-3 h-full"}>
              <StepOne
                registerEmail={registerEmail}
                selectionType={selectionType}
                showDropdown={showDropdown}
                setShowDropdown={setShowDropdown}
                countryCodeDefaultValue={countryCodeDefaultValue}
                value={value}
                setValue={setValue}
                sendOtpError={sendOtpError}
                setSendOtpError={setSendOtpError}
              />
            </div>
          )} */}
          {step === 2 && (
            <div className={"flex w-screen sm:w-max justify-center mx-[60px]"}>
              <StepTwo
                title="Enter OTP sent to your email"
                otpValue={otpValue}
                saveOtpValue={saveOtpValue}
                otpError={otpError}
                verifyOTP={verifyOTP}
                email={data.email}
                isOTPVerified={isOTPVerified}
                otpErrorMessage={otpErrorMessage}
                resendOTP={resendOTP}
                dateKey={dateKey}
                date={date}
                buttonText="Verify"
              />
            </div>
          )}
          {step === 1 && (
            <div className="h-full">
              <StepThree saveNewPassword={saveNewPassword} />
            </div>
          )}
          {error !== "" && <div className="mt-4 text-sm text-error">{error}</div>}
          <div
            className="mt-6 text-lg font-bold cursor-pointer sm:flex pb-7 text-primary font-ssp"
            onClick={() => {
              setIsForgotPassword(false), setStep(1);
              setOTPError(false);
              setOTPErrorMessage("");
            }}
          >
            Go back to login
          </div>
        </div>
      </ForgotPasswordModal>
      <Modal
        visible={step === 5}
        closeHandler={() => (setIsForgotPassword(false), setStep(1))}
        closable={true}
        closeButtonIcon={<CloseIcon fill="fill-grey-1200" />}
        modalClass="!w-full lg:!w-[968px]"
      >
        <div className="flex flex-col items-center justify-between bg-opacity-100 w-full lg:w-[968px]">
          {step === 5 && (
            <div className="flex flex-col items-center justify-center md:pb-0 pb-9">
              <div className="md:pb-[60px] pb-10">
                <ForgotPasswordSuccessBanner />
              </div>
              <div className="w-full mb-2 font-bold text-center text-black text-[32px]">
                Great Hopper!
              </div>
              <div className="text-[32px] font-normal text-center text-black w-2/3">
                Your password has been successfully updated
              </div>
            </div>
          )}
          <div
            className="mt-10 text-lg font-bold cursor-pointer sm:flex pb-7 text-primary-1000 font-ssp"
            onClick={() => {
              setIsForgotPassword(false), setStep(1);
              setOTPError(false);
              setOTPErrorMessage("");
            }}
          >
            Go back to login
          </div>
        </div>
      </Modal>
    </motion.div>
  );
};

export default ForgotPassword;
