import React from "react";
import { IIcons } from "./icons";

const RefreshIcon = ({ fillColor }: IIcons.IconProps) => (
  <svg width="36" height="36" viewBox="0 0 36 36" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      d="M17.73 1.46257C14.0203 1.52163 10.2881 2.80413 7.24501 5.40007C3.55782 8.54444 1.44282 13.1541 1.44001 18.0001C1.44001 18.4838 1.43439 18.976 1.50751 19.5076L1.57501 20.1601H5.91751L5.85001 19.3501C5.12157 12.6282 9.97314 6.59819 16.695 5.87257C20.8266 5.42538 24.8991 7.12132 27.5175 10.3501L24.1875 13.6801H33.84V4.02757L30.6 7.26757C27.2616 3.35257 22.5 1.38663 17.73 1.46257ZM30.0825 15.8401L30.15 16.6501C30.8785 23.3719 26.0269 29.4019 19.305 30.1276C15.1735 30.5748 11.1009 28.8788 8.48251 25.6501L11.8125 22.3201H2.16001V31.9726L5.40001 28.7326C11.3344 35.6907 21.7969 36.5344 28.755 30.6001C32.4422 27.4557 34.5572 22.846 34.56 18.0001C34.56 17.5163 34.5656 17.0241 34.4925 16.4926L34.425 15.8401H30.0825Z"
      fill={fillColor || "white"}
    />
  </svg>
);
export default RefreshIcon;
