import * as React from "react";
import TextField from "@mui/material/TextField";
import Autocomplete from "@mui/material/Autocomplete";
import clsx from "clsx";
import { Popper } from "@mui/material";

type SearchableDropdownProps = {
  options: string[];
  label: string;
  mainClassname: string;
  onChange?: any;
};

export const SearchableDropdown: React.FC<SearchableDropdownProps> = ({
  options,
  label,
  mainClassname,
  onChange,
}) => (
  <div className="flex relative">
    <Autocomplete
      disablePortal
      onChange={onChange}
      options={options}
      PopperComponent={(props) => (
        <Popper
          {...props}
          placement="bottom-start"
          className="z-50 w-full pl-5 xs:pl-5 sm:pl-4 md:pl-4 lg:pl-3"
          // style={{ marginLeft: "4px", width: "full", marginTop: 0, minWidth: "full" }}
        />
      )}
      renderInput={(params: any) => (
        <div className="w-full flex min-w-full">
          <TextField
            sx={{ backgroundColor: "white", marginTop: 0, minWidth: "100%", width: "100%" }}
            className={clsx("w-full min-w-full")}
            {...params}
            label={label}
          />
        </div>
      )}
      className={clsx(mainClassname)}
    />
  </div>
);
