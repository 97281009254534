/* eslint-disable */
import React, { useEffect } from "react";
import { mix, motion } from "framer-motion";
import { IForgotPassword } from "./ForgotPassword";
import Modal from "../../../components/ui/Modal";
import { forgotPassword, getRequestTime, sendOtp, validateOtp } from "../../../services/auth";
import StepTwo from "../SignUp/StepTwo";
import StepThree from "./StepThree";
import StepOne from "./StepOne";
import ForgotPasswordModal from "../../../components/ui/ForgotPasswordModal";
import { CloseIcon, ForgotPasswordSuccessBanner } from "../../../assets/Icons";
import MobileEmailSelection from "./MobileEmailSelection";
import moment from "moment";
import mixpanel from "mixpanel-browser";

const ForgotPassword: React.FC<IForgotPassword.IProps> = ({
  isForgotPassword,
  setIsForgotPassword,
}) => {
  const countryCodeDefaultValue = (
    <span className="flex items-center justify-start space-x-2 text-base font-medium text-black font-lato">
      <img src={`/images/png/INR.png`} alt="" width="35px" height="35px" />
      <div>+91</div>
    </span>
  );

  const [data, saveData] = React.useState({ email: "", mobile: "" });
  const [step, setStep] = React.useState<number>(1);
  const [otpValue, saveOtpValue] = React.useState<string>("");
  const [otpError, setOTPError] = React.useState<boolean>(false);
  const [isOTPVerified, setOTPVerified] = React.useState<boolean>(false);
  const [otpErrorMessage, setOTPErrorMessage] = React.useState<string>("");
  const [date, saveDate] = React.useState(Date.now() + 120000);
  const [dateKey, saveDateKey] = React.useState<number>(0);
  const [error, setError] = React.useState<string>("");
  const [selectionType, setSelectionType] = React.useState<string>("");
  const [showDropdown, setShowDropdown] = React.useState<boolean>(false);
  const [value, setValue] = React.useState(countryCodeDefaultValue);
  const [sendOtpError, setSendOtpError] = React.useState<string>("");
  const [selectedCountryCode,setSelectedCountryCode] = React.useState<string>("+91");

  const mixpanelToken: any = process.env.REACT_APP_MIXPANEL_TOKEN;
  mixpanel.init(mixpanelToken, {
    debug: true,
    api_host: process.env.REACT_APP_MIXPANEL_API,
  });

  useEffect(() => {
    let timer: any;
    if (step === 5) {
      timer = setTimeout(() => {
        handleBackToLogin();
      }, 8000);
    }
    return () => clearTimeout(timer);
  }, [step]);

  useEffect(() => {
    if (step === 3) {
      saveDate(Date.now() + 120000);
      saveDateKey(dateKey + 1);
    }
  }, [step]);

  const registerEmail = (data: any) => {
    saveData((preValue) => {
      return {
        ...preValue,
        [selectionType]: selectionType === "mobile" ? selectedCountryCode+data.phoneNumber : data.forgotEmail,
      };
    });

    const body =
      selectionType === "mobile"
        ? { phoneNumber: selectedCountryCode+data.phoneNumber, otpType: selectionType }
        : { email: data.forgotEmail, otpType: selectionType };

    sendOtp({
      body,
      apiType: "send",
    })
      .then(() => {
        setStep(3);
        selectionType === "mobile" ?
          mixpanel.track("Send_Password_SMS", {
            phoneNumber: selectedCountryCode+data.phoneNumber,
            otpType: selectionType
          })
          :
          mixpanel.track("Send_Password_EMAIL", {
            email: data.forgotEmail,
            otpType: selectionType
          })
      })
      .catch((error) => {
        if (!error?.response?.data?.error?.success) {
          setSendOtpError(error?.response?.data?.error?.type);
        }
      });
  };

  const verifyOTP = () => {
    getRequestTime().then((response: any) => {
      const reqTime = response.data.currentTime;

      validateOtp({
        email_phone: selectionType === "email" ? data?.email : data?.mobile,
        otp: otpValue,
        otpType: selectionType,
      })
        .then((response: any) => {
          const responseTime = response?.data?.timeStamp;

          const timeDifference = moment(responseTime, "hh:mm:ss").diff(
            moment(reqTime, "hh:mm:ss"),
            "seconds",
          );

          if (response?.success) {
            setOTPErrorMessage("");
            setOTPError(false);
            setOTPVerified(true);
            setTimeout(() => {
              setOTPVerified(false);
              setStep(4);
            }, 3000);
          } else if (response?.data?.is_invalid) {
            setOTPError(true);
            setOTPVerified(false);
            setOTPErrorMessage(response?.data?.message);
          } else {
            setOTPError(true);
            setOTPVerified(false);
            setOTPErrorMessage(response?.response?.data?.error?.type);
          }
        })
        .catch((error) => {
          setOTPErrorMessage(error.response?.data?.error?.type);
          setOTPError(true);
        });
    });
  };

  const resendOTP = () => {
    const body =
      selectionType === "mobile"
        ? { phoneNumber: data?.mobile, otpType: selectionType }
        : { email: data?.email, otpType: selectionType };
    sendOtp({
      body,
      apiType: "resend",
    })
      .then(() => {
        setOTPError(false);
        saveOtpValue("");
        setOTPErrorMessage("");
        saveDate(Date.now() + 120000);
        saveDateKey(dateKey + 1);
      })
      .catch((error) => {
        console.error(error);
      });
  };

  const saveNewPassword = (values: any) => {
    const commonBody = {
      otp: otpValue,
      newPassword: values.password,
      confirmPassword: values.confirmPassword,
    };

    const body =
      selectionType === "mobile"
        ? { phoneNumber: data.mobile, ...commonBody }
        : { email: data.email, ...commonBody };

    forgotPassword(body)
      .then((response: any) => {
        if (response?.success) {
          setIsForgotPassword(false);
          mixpanel.track("Confirm_new_password")
          setStep(5);
        } else {
          setError(response?.data?.message);
        }
      })
      .catch((error) => {
        setError(error.response.data.error.type);
        setTimeout(() => {
          setStep(2);
          setError("");
        }, 1000);
        console.error(error);
      });
  };

  const handleBackToLogin = () => {
    setIsForgotPassword(false);
    setStep(1);
    setOTPError(false);
    setOTPErrorMessage("");
    setSelectionType("");
    saveDate(Date.now() + 120000);
    saveDateKey(0);
    setOTPVerified(false);
    setSendOtpError("");
  };

  return (
    <motion.div>
      <ForgotPasswordModal
        visible={isForgotPassword}
        step={step}
        modalClass="!w-full lg:!w-fit"
        selectionType={selectionType}
        closeHandler={() => handleBackToLogin()}
      >
        <div className="flex flex-col items-center justify-between bg-opacity-100 mt-[88px] h-5/6">
          {step === 1 && (
            <div className={"flex flex-col items-center px-3 h-full"}>
              <MobileEmailSelection
                selectionType={selectionType}
                setSelectionType={setSelectionType}
                setStep={setStep}
              />
            </div>
          )}
          {step === 2 && (
            <div className={"flex flex-col items-center px-3 h-full"}>
              <StepOne
                registerEmail={registerEmail}
                selectionType={selectionType}
                showDropdown={showDropdown}
                setShowDropdown={setShowDropdown}
                countryCodeDefaultValue={countryCodeDefaultValue}
                value={value}
                setValue={setValue}
                sendOtpError={sendOtpError}
                setSelectedCountryCode={setSelectedCountryCode}
                setSendOtpError={setSendOtpError}
              />
            </div>
          )}
          {step === 3 && (
            <div className={"flex w-screen sm:w-max justify-center mx-[60px]"}>
              <StepTwo
                title={`Enter OTP sent to your ${selectionType}`}
                otpValue={otpValue}
                saveOtpValue={saveOtpValue}
                otpError={otpError}
                verifyOTP={verifyOTP}
                email={data.email}
                isOTPVerified={isOTPVerified}
                resendOTP={resendOTP}
                dateKey={dateKey}
                date={date}
                buttonText="Verify"
                otpErrorMessage={otpErrorMessage}
              />
            </div>
          )}
          {step === 4 && (
            <div className="h-full">
              <StepThree saveNewPassword={saveNewPassword} />
            </div>
          )}
          {error !== "" && <div className="mt-4 text-sm text-error">{error}</div>}
          {/* <div
            className="mt-6 text-lg font-bold cursor-pointer sm:flex pb-7 text-primary font-ssp"
            onClick={() => handleBackToLogin()}
          >
            Go back to login
          </div> */}
        </div>
      </ForgotPasswordModal>
      <Modal
        visible={step === 5}
        closeHandler={() => (setIsForgotPassword(false), setStep(1))}
        closable={true}
        closeButtonIcon={<CloseIcon fill="fill-grey-1200" />}
        modalClass="!w-full lg:!w-[968px]"
      >
        <div className="flex flex-col items-center justify-between bg-opacity-100 w-full lg:w-[968px]">
          {step === 5 && (

            <div className="flex flex-col items-center justify-center md:pb-0 pb-9">
              <div className="md:pb-[60px] pb-10">
                <ForgotPasswordSuccessBanner />

              </div>
              <div className="w-full mb-2 font-bold text-center text-black text-[32px]">
                Great Hopper!
              </div>
              <div className="text-[32px] font-normal text-center text-black w-2/3">
                Your password has been successfully updated
              </div>
            </div>
          )}
          {/* <div
            className="mt-10 text-lg font-bold cursor-pointer sm:flex pb-7 text-primary-1000 font-ssp"
            onClick={() => handleBackToLogin()}
          >
            Go back to login
          </div> */}

        </div>
      </Modal>
    </motion.div>
  );
};

export default ForgotPassword;
