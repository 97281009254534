/* eslint-disable */
import React, { useRef } from "react";
import clsx from "clsx";
import { useRecoilValue } from "recoil";
import mixpanel from "mixpanel-browser";
import Avatar from "../../../components/ui/Avatar";
import IReviewPage from "../../reviewPage/review";
import { RightFilledBG, Dartboard } from "../../../assets/Icons";
import Typograpy from "../../../components/ui/Typography";
import { userDetails, transactionDetails } from "../../../store";

const InwardPaymentOverview: React.FunctionComponent<IReviewPage.PaymentOverviewProps> = ({
  errorMessage,
  sendCurrency,
  handleClick,
  destCurrency,
  handleA2Form,
  setIsTermsModalOpen,
  isAdvancePay,
  setIsAdvancePay,
  a2Loading,
  loading,
}) => {
  const userData = useRecoilValue(userDetails);
  const transactionData = useRecoilValue(transactionDetails);
  const name = sessionStorage.getItem("recipient_name") || "";
  const firstName = userData.firstName || "";
  const lastName = userData.lastName || "";
  const mixpanelToken: any = process.env.REACT_APP_MIXPANEL_TOKEN;
  mixpanel.init(mixpanelToken, {
    debug: true,
    api_host:process.env.REACT_APP_MIXPANEL_API,
  });
  const myRef: any = useRef(null);
  const on2PercentClick = () => {
    setIsAdvancePay(!isAdvancePay);
    window.scrollTo({
      top: myRef.current.offsetTop,
      behavior: "smooth",
    });
  };

  //   const navigate = useNavigate();

  React.useEffect(() => {
    window.console.log(transactionData);
  }, [transactionData]);

  return (
    <div className="flex flex-col mx-4 lg:w-[50%] lg:px-[5%] px-[2%] mt-3 lg:mt-0">
      <div id="overview-names" className="flex items-center justify-between mb-6 space-y-4 md:mb-2">
        <div id="left-of-arrow" className="flex mt-5 space-x-2 md:space-x-5">
          <Avatar
            name={`${firstName.charAt(0)}${lastName.charAt(0)}`}
            customClassname="!w-[50px] !h-[50px] md:!w-20 md:!h-20"
            textClassname="text-[40px]"
          />
          <div className="flex flex-col">
            <Typograpy
              className="font-medium md:text-[20px] text-[14px] font-sans overflow-hidden"
              tagType={"span"}
            >
              {userData.firstName}
            </Typograpy>
            <div className="flex items-center mt-1">
              <img
                src={`/images/png/${sendCurrency}.png`}
                className="rounded-[200px] w-[32px] h-[32px] hidden md:flex lg:flex"
              />
              <Typograpy className="ml-2 font-bold text-gray-500" tagType={"symbol"}>
                {transactionData.base_currency}
              </Typograpy>
            </div>
          </div>
        </div>
        <div className="">
          <RightFilledBG />
        </div>
        <div id="right-of-arrow" className="flex mt-5 space-x-2 md:space-x-5">
          <Avatar
            name={`${name.charAt(0)}`}
            customClassname="!w-[50px] !h-[50px] md:!w-20 md:!h-20"
          />
          <div className="flex flex-col">
            <Typograpy
              className="font-medium md:text-[20px] text-[14px] font-sans"
              tagType={"span"}
            >
              {name}
            </Typograpy>
            <div className="flex items-center mt-1">
              <img
                src={`/images/png/${destCurrency}.png`}
                className="rounded-[200px] w-[32px] h-[32px] hidden md:flex lg:flex"
              />
              <Typograpy className="ml-2 font-bold text-gray-500" tagType={"symbol"}>
                {transactionData.dest_currency}
              </Typograpy>
            </div>
          </div>
        </div>
      </div>
      <div
        id="purpose-payment"
        className="flex items-center justify-between mt-5 space-x-16 lg:mt-16"
      >
        <div id="left-side" className="flex items-center space-x-2 md:space-x-5">
          <Dartboard />
          <Typograpy className="font-medium text-[12px] lg:text-[18px]" tagType={"span"}>
            Purpose Of Payment
          </Typograpy>
        </div>
        <span className="font-bold text-[12px] lg:text-[18px] text-gray-500">
          {transactionData.transaction_purpose}
        </span>
      </div>
      {/* <div id="a2-form" className="flex flex-row items-center justify-start mt-5 space-x-8 lg:mt-8">
        <div className="relative">
          <div id="left-side" className="flex items-center cursor-pointer peer">
            <InfographicIcon fill="gray" width={30} height={30} />
          </div>
          <Tooltip
            positionOfTip="top"
            bgColor="bg-primary"
            tooltipStyles="absolute cursor-default !min-h-[fit-content] opacity-0 peer-hover:opacity-100 sm:-translate-x-[12%] min-w-[300px] md:min-w-[400px] md:!-translate-y-[90%]"
          >
            <Typograpy
              tagType="div"
              text="Under the Liberalised Remittance Scheme (LRS), the Indian government and the Reserve Bank of India (RBI) now require an LRS Declaration form (fully titled “A2 cum LRS Declaration”) to transfer funds abroad."
              className="mx-4 my-5 text-sm font-medium text-center text-white"
            />
          </Tooltip>
        </div>
        <div id="right-side" className="mb-1">
          <Button
            id="dhjadadonwo-dajk-"
            onClickHandler={(e: any) => {
              handleA2Form(e);
              mixpanel.track("Download A2 Form");
            }}
            className="lg:h-[50px] lg:w-[350px] px-10 py-3 rounded-md font-medium text-white bg-black flex items-center justify-center"
          >
            <div className="flex items-center justify-evenly">
              <span>DOWNLOAD A2 FORM</span>
              {a2Loading ? (
                <div className="">
                  <CircularLoader />
                </div>
              ) : null}
            </div>
          </Button>
        </div>
      </div> */}
      <div className="w-[105%] border border-cyan-600 items-center justify-center ml-[-12px] mt-8"></div>
      <div id="checkbox-group" className="mt-[32px] justify-center items-center">
        <form className="space-y-4" onSubmit={handleClick}>
          {/* <div>
            <input type="checkbox" onChange={on2PercentClick} />
            <label className="ml-2 font-medium font-sans text-[12px] lg:text-[18px]">
              {`Pay 2% advance (${transactionData.advance_pay} INR)`}
            </label>
          </div> */}
          {/* {isAdvancePay && (
            <div
              ref={myRef}
              className="border border-[#0F5BF1] rounded-md justify-between bg-[#D5E3FF] flex flex-col lg:w-[120%] w-[110%] lg:ml-[-8%] ml-[-5%] py-[4%] lg:px-[6%] px-[4%] space-x-4"
            >
              <div className="z-20 flex flex-col lg:flex-row lg:space-x-8">
                <div className="flex flex-col items-center text-base font-semibold w-[90%]">
                  <div>
                    Pay just 2% of the transaction value and you get to lock-in the exchange rates
                    for the next 48 hours. You can make the rest of the payment later in the next
                    two working days!
                  </div>
                </div>
                <div className="flex flex-col w-full mt-2 lg:mt-0">
                  <div className="mb-4 font-bold">Payment Details</div>
                  <div className="flex flex-col">
                    <div className="flex items-center justify-between">
                      <span>You Send</span>
                      <span>{transactionData?.user_send}</span>
                    </div>
                    <div className="flex items-center justify-between">
                      <span>Advance (2%)</span>
                      <span>{transactionData?.advance_pay}</span>
                    </div>
                  </div>
                  <div className="w-[105%] border border-solid border-cyan-600 items-center justify-center ml-[-8px] mt-4"></div>
                  <div className="flex items-center justify-between mt-3">
                    <span>Amount to be paid later</span>
                    <span>{transactionData?.user_send - transactionData?.advance_pay}</span>
                  </div>
                </div>
              </div>
              <div className="text-[#0F5BF1] mt-4">
                <span className="font-bold">Note: </span>
                The advance would be forfeited in case you fail to make full payment in the next 2
                working days.
              </div>
            </div>
          )} */}
          <div>
            <input type="checkbox" required />
            <label className="ml-2 font-medium font-sans text-[12px] lg:text-[18px]">
              I agree to{" "}
              <span
                className="text-blue-600 cursor-pointer hover:underline"
                onClick={() => setIsTermsModalOpen(true)}
              >
                Terms & Conditions
              </span>
              <span> and the</span>
              <span>Privacy Policy.</span>
            </label>
          </div>
          <div className="mt-8">
            <input type="checkbox" className="bg-primary-200" required />
            <label className="ml-2 font-medium font-sans text-[8px] lg:text-[14px]">
              {clsx(
                "I agree & understand that the Payment Services provided through the website URL https://hopremit.moneyhop.co/ United Kingdom will be processed by our compliance partner Rational Foreign Exchange Ltd. Rational Foreign Exchange Ltd is authorised by the Financial Conduct Authority (FRN: 507958) under the Payment Services Regulations 2009, for the provision of payment services. Issued by HM Revenue & Customs (HMRC) Rational Foreign Exchange Ltd trading as RationalFX is a registered Money Service Business (MSB) - Money Laundering Regulation number: 12206957.",
              )}
            </label>
          </div>
          <div className="flex justify-center w-full rounded-lg">
            <div className="flex items-center w-1/3 h-full rounded-lg justify-evenly bg-primary">
              <input
                type={"submit"}
                className="py-3 font-bold text-white rounded-lg cursor-pointer justify-evenly bg-primary text-[18px]"
                value={`Pay ${
                  isAdvancePay ? transactionData.advance_pay : transactionData.total_pay
                } ${transactionData.base_currency}`}
                // onClick={() => navigate("/inward/payment-options")}
              />
              {loading ? (
                <div className="flex items-center justify-center animate-spin-slow">
                  <img src="/images/svg/smallLoader.svg" alt="" />
                </div>
              ) : null}
            </div>
          </div>
          <div>
            <span>{errorMessage}</span>
          </div>
        </form>
      </div>
    </div>
  );
};

export default InwardPaymentOverview;
