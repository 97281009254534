import React from "react";
import clsx from "clsx";
import { Link } from "react-router-dom";
import { MultipleLeftArrowIcon, SendMoneyBanner } from "../../assets/Icons";
import Typograpy from "../../components/ui/Typography";
import { DealCardContainer, IDealCardContainerProps } from "./DashboardContainerComponents";
import { GAP_FROM_SIDEBAR } from "../../components/layout/ProtectedPageLayout";
import { HowToSend } from "../../utils";
// import { showThirdTour } from "../../store";
import Modal from "../../components/ui/Modal";
import Button from "../../components/ui/Button";
//#end Local Imports

const VerifyKYC = () => (
  <div className={clsx("flex flex-col md:flex-row gap-10 mb-8", GAP_FROM_SIDEBAR)}>
    <div className="flex flex-col items-center flex-1 gap-16 py-6 pr-10 transition-all bg-white rounded-md shadow-lg group pl-14 md:flex-row hover:shadow-2xl hover:rounded-md">
      <div className="transition-all duration-300 group-hover:translate-x-6 group-hover:scale-105">
        <SendMoneyBanner />
      </div>
      <div className="flex flex-col items-center flex-1 lg:items-start">
        <Typograpy
          tagType="span"
          text="Send money within 5 minutes"
          className="text-lg font-bold leading-6 transition-all text-black-200 group-hover:translate-x-8"
        />
        <Typograpy
          tagType="span"
          text="A revolutionary new way of remittances that is fast, secure and affordable."
          className="hidden mt-6 font-semibold leading-5 transition-all duration-300 md:block text-black-200 group-hover:translate-x-8"
        />
        <Typograpy
          tagType="span"
          text="Click on the button below to begin making a transaction."
          className="mt-2 text-sm transition-all duration-300 text-black-200 group-hover:translate-x-8"
        />
        {/* <Typograpy
          tagType="span"
          text="50% completed"
          className="hidden mt-2 mb-4 text-sm md:block text-black-200"
        />
        <Progress
          progressColor="!bg-primary-400"
          progressContainerClassname="hidden md:block"
          progressCompleted={50}
        /> */}
        <Link
          to={"/send-money"}
          className="px-9 py-3 group-hover:translate-x-8 duration-500 text-white mt-9 w-[180px] rounded-lg font-semibold bg-primary-400 text-center shadow-xl border border-primary-400 hover:bg-white hover:text-primary-400 transition-all hover:shadow-md hover:shadow-primary-400"
        >
          Start Now!
        </Link>
      </div>
    </div>
    {/* <div className="bg-rewardButton hover:scale-110 group flex max-w-[380px] md:pl-7 md:pt-7 cursor-pointer pb-2 mb:pb-0 pt-2 pl-5 ml-8 hover:shadow-xl hover:shadow-rewardButton hover:rounded-lg transition-all">
      <div className="flex items-center gap-1 rounded-lg md:block">
        <InfographicIcon className="w-[14px] h-[14px] md:h-[40px] md:w-[40px] group-hover:scale-110 transition-all" />
        <div className="md:max-w-[140px] md:mt-4 rounded-lg">
          <Typograpy
            tagType="span"
            // text="Sorry we are unable to help you until you finish your KYC"
            text="Sorry, we won't be able to help until your KYC is complete."
            className="text-white font-bold text-xs md:text-[24px] max-w-[146px] md:leading-7"
          />
        </div>
      </div>
      <div className="items-end hidden transition-all md:flex group-hover:scale-125">
        <LazyMan />
      </div>
    </div> */}
  </div>
);

const UnverifiedKYCDashboard: React.FunctionComponent<IDealCardContainerProps> = ({ dealCard }) => {
  // const setShowThirdTours = useSetRecoilState(showThirdTour);
  // const secondTour = useRecoilValue(showThirdTour);

  const [isClosingModalVisble, setIsClosingModalVisble] = React.useState(false);

  return (
    <div className="flex flex-col md:block">
      <VerifyKYC />
      <div className="">
        <div className={clsx("group mt-8 transition-all mx-8", GAP_FROM_SIDEBAR)}>
          <Typograpy
            tagType="span"
            text="How to send money"
            className="text-[26px] font-ssp font-medium transition-all"
          />
          <div className="flex flex-col w-full mt-4 transition-all duration-200 bg-white shadow-lg hover:shadow-xl group-hover:rounded-xl duration-1500 md:flex-row py-9 md:px-14 group-hover:bg-gradient-to-r from-white via-white to-blue-100">
            {HowToSend.map((el, id) => (
              <div
                className="flex flex-col items-center flex-1 gap-6 transition-all md:flex-row md:gap-0"
                key={el.title}
              >
                <div className="flex items-center flex-1 lg:duration-500 md:block md:gap-6 group-hover:translate-x-3">
                  <div className="w-[100px] md:w-fit">
                    <img src={el.image} alt={el.alt} />
                  </div>
                  <div className="w-[130px] md:w-fit">
                    <Typograpy
                      className="mt-6 text-base font-bold md:text-lg"
                      text={el.title}
                      tagType="h6"
                    />
                    <Typograpy
                      className="mt-1 text-sm md:text-base md:mt-4 font-ssp"
                      text={el.subtitle}
                      tagType="pre"
                    />
                  </div>
                </div>
                {id !== 3 && (
                  <MultipleLeftArrowIcon className="flex-1 mb-4 transition-all duration-300 rotate-90 md:mb-0 md:rotate-0 group-hover:scale-105 group-hover:translate-x-4" />
                )}
              </div>
            ))}
          </div>
        </div>
      </div>
      <div id="dealsContainer" className="order-1">
        <DealCardContainer dealCard={dealCard} />
      </div>
      <div
        id="hero2"
        className="flex flex-col p-5 m-auto mt-3 bg-blue-500 rounded lg:w-screen w-[90%] lg:mt-7 lg:bg-gradient-to-r from-blue-900 via-sky-500 to-green-200"
      >
        <span id="text" className="font-bold text-[20px] lg:text-[32px] text-white">
          Earn HOP Coins to save on International remittance
        </span>
        <span id="text" className="font-semibold text-[13px] lg:text-[24px] text-white ">
          It’s easy to earn HOP coins, you just have to do transactions. the more you transact the
          more you earn
        </span>
      </div>
      <Modal
        visible={isClosingModalVisble}
        closeHandler={() => setIsClosingModalVisble(false)}
        modalClass="py-2 px-6 rounded-md !w-full md:!w-fit"
      >
        <div className="flex flex-col md:flex-row md:justify-between">
          <div className="flex flex-col justify-between md:w-[40%] py-4 ml-4">
            <div className="flex flex-col">
              <div className="flex flex-col">
                <div className="mb-2 text-2xl font-medium md:font-bold md:text-3xl">
                  Great! <br /> You completed the
                </div>
                <div className="flex mb-8 text-4xl font-bold md:ml-8">{`"Easy tour"`}</div>
              </div>
              <div className="text-lg text-gray-600">
                You Just need to complete document verification before you are ready to do your first International
                remitance!
              </div>
            </div>
            <div className="hidden mb-4 md:block">
              <Button
                id="get-started-modal-3"
                bgColor="bg-primary"
                fontStyle={{
                  fontFamily: "font-roboto",
                  fontSize: "text-base",
                  fontWeight: "font-bold",
                  fontColor: "text-white",
                }}
                borderStyle={{
                  borderRadius: "rounded-[44px]",
                }}
                className="px-20 py-4"
                onClickHandler={() => {
                  setIsClosingModalVisble(false);
                }}
              >
                Get Started
              </Button>
            </div>
          </div>
          <div className="flex items-center justify-center mt-6 mb-4 md:hidden">
            <Button
              id="get-started-modal-4"
              bgColor="bg-primary"
              fontStyle={{
                fontFamily: "font-roboto",
                fontSize: "text-base",
                fontWeight: "font-bold",
                fontColor: "text-white",
              }}
              borderStyle={{
                borderRadius: "rounded-[44px]",
              }}
              className="px-20 py-4"
              onClickHandler={() => {
                setIsClosingModalVisble(false);
              }}
            >
              Get Started
            </Button>
          </div>
        </div>
      </Modal>
    </div>
  );
};

export default UnverifiedKYCDashboard;
