import React from "react";
import { IIcons } from "./icons";

const Five = ({ fillColor }: IIcons.IconProps) => (
  <svg
    width="87"
    height="87"
    viewBox="0 0 87 87"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    className={fillColor}
  >
    <path
      d="M65.1432 64.6966C76.9522 52.8045 76.9522 33.5236 65.1432 21.6315C53.3342 9.73941 34.1881 9.73941 22.3791 21.6315C10.5701 33.5236 10.5701 52.8045 22.3791 64.6966C34.1881 76.5887 53.3342 76.5887 65.1432 64.6966Z"
      fill="#FFC32F"
    />
    <path
      d="M59.5801 17.1833C62.5919 21.9168 64.3373 27.547 64.3373 33.5844C64.3373 50.4 50.8001 64.036 34.0985 64.036C28.2984 64.036 22.8814 62.3891 18.2803 59.5383C23.6547 67.9872 33.0591 73.5852 43.7619 73.5852C60.4599 73.5852 74.0006 59.9527 74.0006 43.1336C74.0006 32.1554 68.2324 22.5384 59.5836 17.1797L59.5801 17.1833Z"
      fill="#FFAB06"
    />
    <path
      d="M17.6344 46.3814C17.6344 30.7662 30.2032 18.1089 45.7092 18.1089C54.773 18.1089 62.8294 22.4352 67.9626 29.1478C63.1132 20.674 54.0281 14.9688 43.6197 14.9688C28.1137 14.9688 15.5449 27.626 15.5449 43.2412C15.5449 49.7288 17.716 55.7055 21.3664 60.4747C18.9931 56.3271 17.6344 51.515 17.6344 46.3849V46.3814Z"
      fill="#FFD77F"
    />
    <path
      d="M31.3664 49.5594C30.586 48.7984 29.3408 49.6415 29.7416 50.6561C31.9765 56.2792 37.4326 60.2482 43.8145 60.2482C50.1965 60.2482 55.4716 56.4078 57.7703 50.9348C58.1889 49.9381 56.9331 49.0735 56.1598 49.8273C51.7361 54.1464 42.3388 60.2946 31.3664 49.5594V49.5594Z"
      fill="#5E4939"
    />
    <path
      d="M65.1028 31.6185L52.825 32.6438C52.6795 32.6438 52.5376 32.6509 52.3886 32.6652C52.2787 32.6795 48.3232 33.1546 43.9172 33.4154C43.8144 33.4226 43.7079 33.4226 43.6051 33.4154C39.1991 33.1546 35.2401 32.6795 35.1266 32.6652C34.9847 32.6509 34.8428 32.6438 34.6973 32.6438L22.4194 31.6185C20.0036 31.572 18.6698 32.7688 18.7975 34.8837C18.801 34.9659 19.6666 39.778 19.6666 39.778C19.9469 41.432 20.252 42.786 21.2559 44.2793C21.7774 45.0581 22.5543 45.6404 23.3844 46.0691C23.8065 46.287 24.2535 46.4549 24.7147 46.5657C25.1014 46.6585 25.5306 46.655 25.9457 46.6764C26.3111 46.6978 26.9106 46.7264 27.276 46.7264H31.2456C32.544 46.7264 33.8636 46.4192 35.0237 45.8369C36.8471 44.9223 37.9042 43.404 38.6598 41.5606C39.3764 39.8209 40.8061 38.5026 42.3279 37.4988C43.1971 36.9236 44.3181 36.9236 45.1872 37.4988C46.0102 38.0418 46.7729 38.6812 47.4328 39.4136C47.9188 39.953 48.3126 40.6318 48.7099 41.2534C49.1569 41.9572 49.4442 42.7931 49.9125 43.5076C50.3807 44.2293 50.9022 44.8187 51.6224 45.3189C52.8285 46.1512 54.2901 46.6085 55.741 46.7086C55.9184 46.7193 56.0957 46.7264 56.2731 46.7264H60.2427C60.6081 46.7264 61.2077 46.6978 61.573 46.6764C61.9881 46.655 62.4138 46.6585 62.8041 46.5657C63.2652 46.4549 63.7122 46.287 64.1344 46.0691C64.9645 45.6404 65.7414 45.0545 66.2629 44.2793C67.2668 42.786 67.5719 41.4285 67.8521 39.778C67.8521 39.778 68.7141 34.9659 68.7212 34.8837C68.8454 32.7688 67.5151 31.572 65.0993 31.6185H65.1028Z"
      fill="#2D292A"
    />
    <path
      d="M56.2774 46.7262H60.247C60.6124 46.7262 61.2119 46.6976 61.5773 46.6762C61.9924 46.6547 62.4181 46.6583 62.8083 46.5654C63.2695 46.4547 63.7165 46.2868 64.1386 46.0689C64.9688 45.6402 65.7457 45.0543 66.2671 44.2791C67.2711 42.7858 67.5762 41.4282 67.8564 39.7778C67.8564 39.7778 68.7184 34.9657 68.7255 34.8835C68.7433 34.587 68.7291 34.3119 68.6901 34.0547C63.0354 42.8715 56.1745 45.0043 51.7686 45.4187C52.9463 46.1903 54.344 46.619 55.7417 46.7119C55.9191 46.7226 56.0965 46.7298 56.2738 46.7298L56.2774 46.7262Z"
      fill="#414042"
    />
    <path
      d="M23.3848 46.0705C23.8069 46.2884 24.2539 46.4564 24.7151 46.5671C25.1018 46.66 25.531 46.6564 25.9461 46.6778C26.3115 46.6993 26.911 46.7279 27.2764 46.7279H31.246C32.5444 46.7279 33.864 46.4206 35.0241 45.8383C36.8475 44.9238 37.9046 43.4055 38.6602 41.5621C39.0398 40.6368 39.6252 39.8366 40.3169 39.1328C32.14 44.5915 25.3323 45.4489 22.5156 45.5418C22.7923 45.7383 23.0832 45.9133 23.3848 46.0705V46.0705Z"
      fill="#414042"
    />
  </svg>
);
export default Five;
