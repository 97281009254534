/**
 * @description Component for using tooltip
 * @param {object} titleTextStyle - Styling for the title of the tooltip
 * @param {string} title -  Title of the tooltip
 * @param {string} subtitle - Subtitle of the tooltip
 * @param {object} subtitleTextStyle - Styling for the subtitle of the tooltip
 * @param {React.ReactNode} children - Children to customize the tooltip
 * @param {function} onButtonClick - Method to handle the click when CTA button is pressed
 * @param {string} buttonLabel - Button label that need to be displayed
 * @param {string} tooltipStyles - Method to handle when onBlur event happens
 * @param {function} dropdownButtonOnClick - Method to handle when button is clicked
 * @param {string} buttonLabel - Button label that need to be displayed
 * @param {string} subtitleClasses - Classes added to the subtitle
 * @param {string} titleClasses - Classes added to the title
 * @param {string} buttonClasses - Classes added to the button
 * @param {string} positionOfTip - Where do we need the tip position
 * @param {LegacyRef<HTMLDivElement>} ref - Ref to access the tooltip
 * @returns {React.FunctionComponent<ITooltip.IProps>} - Returns JSX for tooltip
 */
import clsx from "clsx";
import React from "react";

import { ITooltip } from "./Tooltip";

const Tooltip: React.FunctionComponent<ITooltip.IProps> = ({
  titleTextStyle = { fontWeight: "bold", fontSize: "text-lg" },
  title,
  subtitle,
  subtitleTextStyle = { fontSize: "text-sm" },
  children,
  onButtonClick,
  buttonLabel,
  tooltipStyles = "text-white",
  titleClasses,
  subtitleClasses,
  buttonClasses = "text-sm",
  positionOfTip = "left",
  ref,
  bgColor = "bg-secondary",
}) => {
  return (
    <div
      ref={ref}
      className={clsx(
        bgColor,
        `w-[-webkit-fill-available] sm:min-w-[200px] sm:min-h-[150px] sm:w-fit rounded before:${bgColor} before:absolute before:content-none md:before:content-[''] before:h-4 before:w-4  before:rotate-45 before:translate-x-2/4 before:translate-y-2/4  sm:-translate-x-2/4 sm:left-2/4 bottom-32 top-[30%] sm:top-0 z-10 sm:z-0 left-0 mx-6 sm:mx-0`,
        positionOfTip === "top"
          ? "before:bottom-0 before:left-[calc(50%-16px)] md:-top-[calc(100%)] !-translate-y-[calc(100%+16px)] md:!-translate-y-[100%] -translate-x-2/3 md:!-translate-x-2/4 md:!left-2/4"
          : "",
        positionOfTip === "right"
          ? "before:left-none before:-right-0 before:top-[calc(50%-16px)] md:-left-[16px] md:!-translate-x-full md:-translate-y-[60%] md:top-2/4"
          : "",
        positionOfTip === "left"
          ? "before:top-[calc(50%-16px)] before:-left-4  md:left-[calc(100%+16px)] md:!translate-x-0  md:-translate-y-2/4 md:top-2/4"
          : "",
        positionOfTip === "bottom"
          ? "before:bottom-0 before:left-[calc(50%-16px)] md:bottom-[calc(100%+16px)] md:-translate-x-2/4 md:left-2/4"
          : "",
        tooltipStyles,
      )}
    >
      {children ? (
        children
      ) : (
        <>
          <div
            className={clsx(
              "flex flex-col px-4 pt-4 mb-1.5",
              titleTextStyle.fontColor,
              titleTextStyle.fontFamily,
              titleTextStyle.fontWeight,
              titleTextStyle.fontSize,
              titleClasses,
            )}
          >
            {title}
          </div>
          <div
            className={clsx(
              "px-4  mb-4",
              subtitleTextStyle.fontColor,
              subtitleTextStyle.fontFamily,
              subtitleTextStyle.fontWeight,
              subtitleTextStyle.fontSize,
              subtitleClasses,
            )}
          >
            {subtitle}
          </div>
          <div className="w-full text-center">
            <button className={clsx("px-4 pb-4", buttonClasses)} onClick={onButtonClick}>
              {buttonLabel}
            </button>
          </div>
        </>
      )}
    </div>
  );
};

export default Tooltip;
