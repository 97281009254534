import React from "react";
import { IIcons } from "./icons";

const FlagIcon = ({ fillColor }: IIcons.IconProps) => (
  <svg width="30" height="29" viewBox="0 0 30 29" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      d="M1.06181 3.68101C0.777432 3.77118 0.539572 3.9674 0.400432 4.22661C0.261292 4.48581 0.232239 4.78682 0.319649 5.06357L7.25466 28.1469C7.29441 28.2866 7.36228 28.4167 7.45434 28.5297C7.5464 28.6427 7.6608 28.7364 7.79091 28.8053C7.92101 28.8741 8.06421 28.9168 8.21219 28.9309C8.36018 28.9449 8.50999 28.93 8.65292 28.8871C8.79586 28.8442 8.92906 28.774 9.0448 28.6807C9.16054 28.5874 9.2565 28.4729 9.3271 28.3437C9.39771 28.2146 9.44155 28.0734 9.45608 27.9283C9.47061 27.7833 9.45555 27.6373 9.41175 27.4989L5.94425 15.9572L15.6512 13.0409C16.2465 12.862 16.5885 12.2468 16.4145 11.6676L13.5775 2.22444C13.2291 1.06502 11.9817 0.415957 10.7899 0.774012L1.24939 3.6403C1.18587 3.64864 1.12311 3.66227 1.06181 3.68101ZM16.365 3.67486L18.8868 12.0688C19.2351 13.2282 18.552 14.4573 17.3602 14.8154L11.9674 16.4355L12.5979 18.534C12.9462 19.6934 14.1937 20.3425 15.3854 19.9844L29.4066 15.772L25.0429 9.07539L24.9934 1.08261L16.365 3.67486Z"
      fill="#BEBEBE"
      className={fillColor}
    />
  </svg>
);
export default FlagIcon;
