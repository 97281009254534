/*eslint-disable*/
import React, { useEffect, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import clsx from "clsx";
import { motion } from "framer-motion";
import { Modals } from "./Modals";
import { StepOne } from "./StepOne";
import { StepTwo } from "./StepTwo";
import { StepThree } from "./StepThree";
import Button from "../../components/ui/Button";
import { receivingCountryData } from "../../utils";
import { sendRecipients, validateRecipient } from "../../services/recipientDetailsPage";
import { CloseIcon, LeftArrowIcon, RightArrowFilledIcon } from "../../assets/Icons";
import mixpanel from "mixpanel-browser";
import { StepFour } from "./StepFour";

const RecipeintDetails = () => {
  const [step, setStep] = useState<number>(1);
  const [name, setName] = useState<string>("");
  const [mobile, setMobile] = useState<string>("");
  const [isModalOpen, setIsModalOpen] = useState<string>("");
  const [relationship, setRelationship] = useState<string>("");
  const [category, setCategory] = useState<string>("");
  const [city, setCity] = useState<string>("");
  const [addressOne, setAddressOne] = useState<string>("");
  const [addressTwo, setAddressTwo] = useState<string>("");
  const [country, setCountry] = useState<string>("");
  const [pin, setPin] = useState<string>("");
  const [bankCountry, setBankCountry] = useState<string>("");
  const [bankName, setBankName] = useState<string>("");
  const [acNumber, setAcNumber] = useState<string>("");
  const [confirmAcNumber, setConfirmAcNumber] = React.useState("");
  const [swiftCode, setSwiftCode] = useState<string>("");
  const [IBAN, setIBAN] = useState<string>("");
  const [routingNumber, setRoutingNumber] = useState<string>("");
  const [transitCode, setTransitCode] = useState<string>("");
  const [bspCode, setBspCode] = useState<string>("");
  const [sortCode, setSortCode] = useState<string>("");
  const [refrence, setRefrence] = useState<string>("");
  const [bankAddress, setBankAddress] = useState("");
  const [unitNumber, setUnitNumber] = useState<string>("");
  const [errorMessage, setErrorMessage] = useState<string>("");
  const [countryData, setCountryData] = useState<any>([]);
  const [isCorrespondingAccount, setIsCorrespondingAccount] = useState(false);
  const [bankAccountNameCorresponding, setBankAccountNameCorresponding] = useState("");
  const [bankAccountNumberCorresponding, setBankAccountNumberCorresponding] = useState<string>("");
  const [refrenceCorresponding, setRefrenceCorresponding] = useState("");
  const [loading, setLoading] = useState<boolean>(false);
  const navigate = useNavigate();
  const { state } = useLocation();
  const isRecipientSelected = sessionStorage.getItem("isRecipientSelected");
  const mixpanelToken: any = process.env.REACT_APP_MIXPANEL_TOKEN;

  mixpanel.init(mixpanelToken, {
    debug: true,
    api_host:process.env.REACT_APP_MIXPANEL_API,
  });

  const handleFinalClick = () => {
    mixpanel.track("Save and Proceed on Add Recipient Step 3");
    setLoading(true);
    const body = {
      achCode: routingNumber,
      bankAccountCountry: bankCountry,
      bankAccountNumber: acNumber,
      bankName: bankName,
      bankAddress: bankAddress,
      bsbCode: bspCode,
      city: city,
      countryOfResidence: country,
      email: "",
      firstName: name,
      ibanCode: IBAN,
      lastName: "",
      middleName: "",
      nickName: "",
      phoneNumber: mobile,
      pincode: pin,
      recipientAddress: addressOne + addressTwo,
      recipientLs: "",
      refrence: refrence,
      sortCode: sortCode,
      swiftCode: swiftCode,
      transitCode: transitCode,
      unitNumber: unitNumber,
      relationship: relationship,
      type: category.replace(/\s+/g, '_'),
      correspondingBankName: isCorrespondingAccount ? bankAccountNameCorresponding : null,
      correspondingBankAccountNumber: isCorrespondingAccount
        ? bankAccountNumberCorresponding
        : null,
    };
    sendRecipients(body)
      .then(() => {
        navigate("/recipients");
        // mixpanel.track("Submit _Step 3", {
        //   "Bank Account Country": bankCountry,
        //   "Bank Name": bankName,
        //   "Bank Account Number": acNumber,
        //   "SWIFT/BIC Code": swiftCode,
        //   "IBAN Code": IBAN,
        //   "Unit Number": unitNumber,
        //   "Reference ID": refrence,
        // });
      })
      .catch((error) => {
        setErrorMessage(error.response.data.error.type);
      }).finally(()=>{
        setLoading(false)
      })
  };

  const [cancellationReasons, setCancellationReasons] = useState<string>("");
  const [otherReason, setOtherReason] = useState<string>("");
  const [isVerified, setIsVerified] = React.useState(false);
  const [validationError, setValidationError] = useState("");

  const handleVerify = () => {
    setBankAddress("");
    setBankName("");
    setAcNumber("");
    setConfirmAcNumber("");
    setBankCountry("");
    setLoading(true);

    if (swiftCode && IBAN) setValidationError("Please enter either Swift Code or IBAN");
    else if (swiftCode || IBAN) {
      const type = swiftCode ? "swift" : "iban";
      const number = swiftCode ? swiftCode : IBAN;

      validateRecipient(type, number)
        .then((res) => {
          setValidationError("");
          mixpanel.track("SWIFT Verified");
          mixpanel.track("IBAN Verified");
          setIsVerified(true);
          setValidationError("");
          setSortCode(res?.data?.branch_code);
          if (type === "swift") {
            setBankAddress(res?.data?.address);
            setBankName(res?.data?.name);
            if (res?.data?.country_name === "AUSTRALIA") {
              setBankCountry("Australia");
            } else if (res?.data?.country_name === "CANADA") {
              setBankCountry("Canada");
            } else if (res?.data?.country_name === "UNITED KINGDOM") {
              setBankCountry("United Kingdom");
            } else if (res?.data?.country_name === "UNITED STATES OF AMERICA") {
              setBankCountry("United States");
            } else setBankCountry(res?.data?.country_name);
          } else if (type === "iban") {
            setBankAddress(res?.data?.address);
            setBankName(res?.data?.bank);
            setAcNumber(res?.data?.account);
            setConfirmAcNumber(res?.data?.account);

            if (res?.data?.country === "AUSTRALIA") {
              setBankCountry("Australia");
            } else if (res?.data?.country === "CANADA") {
              setBankCountry("Canada");
            } else if (res?.data?.country === "UNITED KINGDOM") {
              setBankCountry("United Kingdom");
            } else if (res?.data?.country === "UNITED STATES OF AMERICA") {
              setBankCountry("United States");
            } else setBankCountry(res?.data?.country);
            setSwiftCode(res?.data?.bic);
          }
        })
        .catch((error) => {
          setValidationError(error.response.data.error.type);
          setTimeout(() => {
            setValidationError("");
          }, 3000);
        }).finally(()=>{
          setLoading(false)
        })
    }
  };

  return (
    <motion.div
      className="h-screen"
      initial={{ width: 0 }}
      animate={{ width: "100%" }}
      exit={{ x: window.innerWidth, transition: { duration: 0.5 } }}
    >
      <div className="flex justify-between items-center bg-white md:hidden w-full py-[25px] pr-[22px] pl-7 mb-6">
        <div className="flex items-center w-fit" onClick={() => history.back()}>
          <LeftArrowIcon />
          <div className="ml-6 text-xl font-semibold text-grey-1100">Recipient Details</div>
        </div>
        <div className="flex items-center">
          <div
            className="mr-3 text-sm font-normal text-primary"
            onClick={() => navigate("/support")}
          >
            Need help ?
          </div>
          <div
            className="bg-gray-400 w-[30px] h-[30px] flex justify-center items-center rounded-full"
            onClick={() => setIsModalOpen("cancelReasons")}
          >
            <CloseIcon />
          </div>
        </div>
      </div>
      <div className={clsx("hidden md:flex justify-between items-center ml-10 mr-[69px]")}>
        <Button
          id="go-back-btn-receipient"
          bgColor="bg-primary-450"
          fontStyle={{
            fontFamily: "",
            fontSize: "text-sm",
            fontWeight: "font-medium",
            fontColor: "text-primary",
          }}
          borderStyle={{
            borderRadius: "rounded",
          }}
          className="py-2 px-[18px]"
          onClickHandler={()=>{
            if(step > 1){
              setStep(step-1)
            }
            else{
              history.back();
            }
          }}
        >
          {"<< Go Back"}
        </Button>
        {/* <Button
          id="cancel-txn-receipe"
          bgColor="bg-red-150"
          fontStyle={{
            fontFamily: "",
            fontSize: "text-sm",
            fontWeight: "font-medium",
            fontColor: "text-error",
          }}
          borderStyle={{
            borderRadius: "rounded",
          }}
          className="py-2 px-[18px]"
          onClickHandler={() => {
            setIsModalOpen("cancelReasons");
            mixpanel.track("Cancel Transaction Clicked");
          }}
        >
          Cancel transaction
        </Button> */}
        <div
          onClick={() => {
            setIsModalOpen("cancelReasons");
            mixpanel.track("Cancel Transaction Clicked");
          }}
          className="cursor-pointer hover:scale-125 ttransition-all"
        >
          <CloseIcon fill="fill-grey-200" />
        </div>
      </div>
      <div className="flex flex-col items-center">
        <div className="flex -ml-10 md:hidden text-[25px] font-bold font-ssp border">
          Step {step} of 3
        </div>
        <div id="steps" className="items-center justify-center hidden mt-16 md:flex">
          <div className="flex items-center space-x-20">
            <div
              id="step1"
              // onClick={() => setStep(1)}
              className={
                step !== 1
                  ? "flex flex-col items-center justify-center text-gray-400"
                  : "flex flex-col items-center justify-center"
              }
            >
              <span className="text-lg font-bold font-ssp">Step 1</span>
              <span>Recipient Details</span>
            </div>
            <RightArrowFilledIcon />
            <div
              id="step1"
              // onClick={() => setStep(2)}
              className={
                step !== 2
                  ? "flex flex-col items-center justify-center text-gray-400"
                  : "flex flex-col items-center justify-center"
              }
            >
              <span className="text-lg font-bold font-ssp">Step 2</span>
              <span>Recipient Address</span>
            </div>
            <RightArrowFilledIcon />
            <div
              id="step1"
              // onClick={() => setStep(3)}
              className={
                step !== 3
                  ? "flex flex-col items-center justify-center text-gray-400"
                  : "flex flex-col items-center justify-center"
              }
            >
              <span className="text-lg font-bold font-ssp">Step 3</span>
              <span>Recipient Bank Details</span>
            </div>
            {isCorrespondingAccount && <RightArrowFilledIcon />}
            {isCorrespondingAccount && (
              <div
                id="step1"
                // onClick={() => setStep(3)}
                className={
                  step !== 1
                    ? "flex flex-col items-center justify-center text-gray-400"
                    : "flex flex-col items-center justify-center"
                }
              >
                <span className="text-lg font-bold font-ssp">Step 4</span>
                <span>Corresponding Bank Details</span>
              </div>
            )}
          </div>
        </div>
        {step === 1 && (
          <StepOne
            setCategory={setCategory}
            setIsModalOpen={setIsModalOpen}
            setStep={setStep}
            relationship={relationship}
            setName={setName}
            name={name}
            setMobile={setMobile}
            mobile={mobile}
            category={category}
            fromSendMoney={state?.fromSendMoney}
          />
        )}
        {step === 2 && (
          <StepTwo
            setIsModalOpen={setIsModalOpen}
            category={category}
            setCity={setCity}
            city={city}
            setAddressOne={setAddressOne}
            addressOne={addressOne}
            setAddressTwo={setAddressTwo}
            addressTwo={addressTwo}
            country={country}
            setPin={setPin}
            pin={pin}
            setStep={setStep}
            relationship={relationship}
          />
        )}
        {step === 3 && (
          // <div className="flex items-center justify-center w-[800px]">
          <StepThree
            setErrorMessage={setErrorMessage}
            errorMessage={errorMessage}
            setStep={setStep}
            setIsModalOpen={setIsModalOpen}
            bankCountry={bankCountry}
            setAcNumber={setAcNumber}
            acNumber={acNumber}
            confirmAcNumber={confirmAcNumber}
            setConfirmAcNumber={setConfirmAcNumber}
            setIBAN={setIBAN}
            IBAN={IBAN}
            setBankName={setBankName}
            bankName={bankName}
            setSwiftCode={setSwiftCode}
            swiftCode={swiftCode}
            setUnitNumber={setUnitNumber}
            unitNumber={unitNumber}
            routingNumber={routingNumber}
            setRoutingNumber={setRoutingNumber}
            transitCode={transitCode}
            setTransitCode={setTransitCode}
            bspCode={bspCode}
            setBspCode={setBspCode}
            sortCode={sortCode}
            setSortCode={setSortCode}
            refrence={refrence}
            setRefrence={setRefrence}
            bankAddress={bankAddress}
            setBankAddress={setBankAddress}
            handleFinalClick={handleFinalClick}
            isCorrespondingAccount={isCorrespondingAccount}
            setIsCorrespondingAccount={setIsCorrespondingAccount}
            isVerified={isVerified}
            setIsVerified={setIsVerified}
            handleVerify={handleVerify}
            validationError={validationError}
            country={country}
            loading={loading}
          />
          // </div>
        )}
        {step === 4 && (
          <StepFour
            bankAccountNameCorresponding={bankAccountNameCorresponding}
            setBankAccountNameCorresponding={setBankAccountNameCorresponding}
            bankAccountNumberCorresponding={bankAccountNumberCorresponding}
            setBankAccountNumberCorresponding={setBankAccountNumberCorresponding}
            refrenceCorresponding={refrenceCorresponding}
            setRefrenceCorresponding={setRefrenceCorresponding}
            setStep={setStep}
            handleFinalClick={handleFinalClick}
            setIsCorrespondingAccount={setIsCorrespondingAccount}
            errorMessage={errorMessage}
            setErrorMessage={setErrorMessage}
            loading={loading}
          />
        )}
      </div>
      <Modals
        setIsModalOpen={setIsModalOpen}
        isModalOpen={isModalOpen}
        setRelationship={setRelationship}
        relationship={relationship}
        category={category}
        setCountry={setCountry}
        country={country}
        receivingCountryModalData={receivingCountryData}
        setBankCountry={setBankCountry}
        cancellationReasons={cancellationReasons}
        setCancellationReasons={setCancellationReasons}
        otherReason={otherReason}
        setOtherReason={setOtherReason}
        countryData={countryData}
        setCountryData={setCountryData}
      />
    </motion.div>
  );
};

export default RecipeintDetails;
