const BACKSPACE_KEY = {
  key: 8,
  name: "Backspace",
};
const LEFT_ARROW_KEY = {
  key: 37,
  name: "ArrowLeft",
};
const RIGHT_ARROW_KEY = {
  key: 39,
  name: "ArrowRight",
};
const DELETE_KEY = {
  key: 46,
  name: "Delete",
};
const SPACEBAR_KEY = {
  key: 32,
  name: "Spacebar",
  altName: "Space",
};

export { BACKSPACE_KEY, LEFT_ARROW_KEY, RIGHT_ARROW_KEY, DELETE_KEY, SPACEBAR_KEY };
