import clsx from "clsx";
import mixpanel from "mixpanel-browser";
import React from "react";
import { useNavigate } from "react-router-dom";

import { CancellationBanner, CloseIcon, LeftArrowIcon } from "../../../assets/Icons";
import Button from "../../../components/ui/Button";
import Checkbox from "../../../components/ui/Checkbox";
import Modal from "../../../components/ui/Modal";
import Stepper from "../../../components/ui/Stepper";
import { TransactioncancellationReasons } from "../../../utils";
import PaymentProps from "../payment";
import { handleTransactionComplete } from "../../../services/transaction";
import { transactionDetails } from "../../../store";
import { useResetRecoilState } from "recoil";
// import { transactionDetails } from "../../store";

const GoBackandStepperSection: React.FC<PaymentProps.GobackandStepperSectionProps> = ({
  // setIsModalOpen,
  stepperSteps,
}) => {
  const navigate = useNavigate();
  const mixpanelToken: any = process.env.REACT_APP_MIXPANEL_TOKEN;
  mixpanel.init(mixpanelToken, {
    debug: true,
    api_host:process.env.REACT_APP_MIXPANEL_API,
  });

  const [cancellationReasons, setCancellationReasons] = React.useState<string>("");
  const [otherReason, setOtherReason] = React.useState<string>("");
  const [isModalOpen, setIsModalOpen] = React.useState<string>("");
  const resetTransactionDetails = useResetRecoilState(transactionDetails);

  const handleNavigate = () => {
    handleTransactionComplete();
    resetTransactionDetails();
    navigate("/");
  };

  return (
    <div>
      <div className="flex justify-between items-center bg-white md:hidden w-full py-[25px] pr-[22px] pl-7 mb-6">
        <div className="flex items-center w-fit" onClick={() => history.back()}>
          <LeftArrowIcon />
          <div className="ml-6 text-xl font-semibold text-grey-1100">Pay</div>
        </div>
        <div className="flex items-center">
          <div
            className="mr-3 text-sm font-normal text-primary"
            onClick={() => navigate("/support")}
          >
            Need help ?
          </div>
          <div
            className="bg-gray-300 w-[30px] h-[30px] flex justify-center items-center rounded-full"
            onClick={() => setIsModalOpen("cancelReasons")}
          >
            <CloseIcon />
          </div>
        </div>
      </div>
      <div className={clsx("hidden lg:flex justify-between items-center ml-10 mr-[69px]")}>
        <Button
          id="<< Go Back"
          bgColor="bg-primary-450"
          fontStyle={{
            fontFamily: "",
            fontSize: "text-sm",
            fontWeight: "font-medium",
            fontColor: "text-primary",
          }}
          borderStyle={{
            borderRadius: "rounded",
          }}
          className="py-2 px-[18px]"
          onClickHandler={() => history.back()}
        >
          {"<< Go Back"}
        </Button>
        <div
          onClick={() => {
            setIsModalOpen("cancelReasons");
            mixpanel.track("Cancel Transaction Clicked");
          }}
          className="cursor-pointer hover:scale-125 ttransition-all"
        >
          <CloseIcon fill="fill-grey-200" />
        </div>
      </div>
      <div className={clsx("hidden lg:flex justify-center items-center mt-[59px] mb-24")}>
        <div className={clsx("w-full px-[235px]")}>
          <Stepper numberOfSteps={5} steps={stepperSteps} activeStep={5} />
        </div>
      </div>
      <Modal
        headerClass="!m-0"
        modalClass="!p-0 !rounded !overflow-hidden !w-full lg:!w-min"
        visible={isModalOpen === "cancelReasons"}
        closeHandler={() => setIsModalOpen("")}
        closable={false}
      >
        <div className="flex items-start">
          <div className="px-[34px] pt-[34px] pb-[54px] md:pb-0">
            <div className="pb-3 text-lg font-bold text-black px-7">Reason for cancellation</div>
            {TransactioncancellationReasons.map((data, index) => {
              return (
                <div className="space-x-2 px-7 border-b-[1px] py-6 first:pt-0" key={index}>
                  <Checkbox
                    label={data}
                    value={data}
                    name={"cancellationReason"}
                    handleChange={(e) => {
                      setCancellationReasons(e.target.checked ? e.target.value : "");
                    }}
                  />
                </div>
              );
            })}
            {cancellationReasons === "Other Reason..." && (
              <div className="flex items-center justify-center mt-2">
                <textarea
                  name="otherReasonDescription"
                  value={otherReason}
                  rows={3}
                  cols={40}
                  placeholder="Write your reason here ..... in less than 50 words"
                  onChange={(e) => {
                    setOtherReason(e.target.value);
                  }}
                  className="w-full p-3 bg-white-100 focus-visible:border-0 placeholder:text-center"
                />
              </div>
            )}
            <div
              className={clsx(
                cancellationReasons === "Other Reason..." ? "mt-7" : "mt-24",
                "flex items-center justify-center px-[78px]",
              )}
            >
              <Button
                bgColor={`${
                  cancellationReasons === "" ||
                  (cancellationReasons === "Other Reason..." && otherReason === "")
                    ? "bg-grey-550"
                    : "bg-primary"
                }`}
                fontStyle={{
                  fontFamily: "",
                  fontSize: "text-lg",
                  fontWeight: "font-bold",
                  fontColor: "text-white",
                }}
                borderStyle={{
                  borderWidth: "border-none",
                  borderRadius: "rounded-[100px]",
                }}
                disabled={
                  cancellationReasons === "" ||
                  (cancellationReasons === "Other Reason..." && otherReason === "")
                }
                className={"py-3 px-28"}
                onClickHandler={() =>
                  cancellationReasons !== "" && cancellationReasons === "Other Reason..."
                    ? otherReason !== "" && handleNavigate()
                    : handleNavigate()
                }
              >
                Confirm
              </Button>
            </div>
          </div>
          <div className="hidden w-1/2 h-full pb-6 pt-7 pl-9 pr-11 lg:block bg-red-250">
            <button className="float-right" onClick={() => setIsModalOpen("")}>
              <CloseIcon fill="fill-grey-1200" />
            </button>
            <div id="text" className="flex flex-col mt-10">
              <span
                className={clsx(
                  cancellationReasons === "" ? "text-[32px]" : "text-[26px]",
                  "text-black font-semibold",
                )}
              >
                {cancellationReasons === "" ? "Sorry!" : "Did we made any problems??"}
              </span>
              <span className="mt-2 text-lg font-normal text-black">
                {cancellationReasons === ""
                  ? "Did you face any issue?"
                  : "Somwtimes there might be an issue so relax and try again."}
              </span>
            </div>
            <div className="flex justify-center px-4 mt-10">
              <CancellationBanner />
            </div>
          </div>
        </div>
      </Modal>
    </div>
  );
};

export default GoBackandStepperSection;
