import React from "react";
import { IIcons } from "./icons";

const Plus = ({ fillColor }: IIcons.IconProps) => {
  return (
    <svg
      width="30"
      height="30"
      viewBox="0 0 30 30"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      className={`${fillColor} w-5`}
    >
      <circle cx="15" cy="15" r="15" fill="#727272" />
      <path d="M20.0354 13.4316V15.0946H10.4971V13.4316H20.0354Z" fill="white" />
      <path
        d="M16.0977 19.0324L14.4347 19.0324L14.4347 9.49414L16.0977 9.49414L16.0977 19.0324Z"
        fill="white"
      />
    </svg>
  );
};

export default Plus;
