/* eslint-disable */
import React, { useEffect, useMemo, useState } from "react";
import clsx from "clsx";
import { Controller, useForm } from "react-hook-form";

import { DropdownIcon, RefreshIcon } from "../../assets/Icons";
import Button from "../../components/ui/Button";
import TextField from "../../components/ui/TextField";
import ISendMoney from "./sendMoney";
import { formatNumber, getSendMoneyErrorMessage } from "../../utils";
import ErrorField from "../../components/ui/ErrorField";
import { useRecoilState } from "recoil";
import { isInwardTransaction } from "../../store";

const SendReceiveMoney: React.FC<ISendMoney.ISendReceiveMoneyProps> = ({
  sendingCurrency,
  receiveCurrency,
  sendingAmount,
  receivingAmount,
  setIsModalOpen,
  setSendingAmount,
  setReceivingAmount,
  setOnChangeHandleType,
  handleAmountChnage,
  showInwardGBPModal,
  setShowInwardGBPModal,
  sendAmount,
  setSendAmount,
  error,
}) => {
  type FormInputs = {
    sendingAmount: number;
  };

  const {
    formState: { errors },
    control,
  } = useForm<FormInputs>({ mode: "onChange" });
  const [formattedSendAmount, setFormattedSendAmount] = useState('');
  const [formattedReceiveAmount, setFormattedReceiveAmount] = useState('');
  const email = sessionStorage.getItem("email") || "";
  const [isInward, setIsInward] = useRecoilState(isInwardTransaction);

  useEffect(() => {
    const formatted = formatNumber(sendingAmount);
    console.log("formatted", formatted);
    setFormattedSendAmount(formatted);
  }, [sendingAmount]);

  useEffect(()=>{
    const formatted = formatNumber(receivingAmount);
    console.log("RECEIVE", formatted)
    setFormattedReceiveAmount(formatted)
  },[receivingAmount])

  return (
    <div
      className={clsx("flex flex-col lg:flex-row lg:justify-between lg:items-start lg:ml-[83px]")}
    >
      <div className="flex items-center mb-2 text-xs font-bold text-black md:justify-center lg:hidden">
        Send
      </div>
      <div
        className={clsx(
          "flex justify-center sm:justify-center lg:flex-col lg:space-y-5 lg:justify-center mb-6 lg:mb-0",
        )}
      >
        <Button
          id="fjksjkfka-dsdjsd"
          bgColor="bg-primary"
          fontStyle={{
            fontFamily: "",
            fontSize: "text-base lg:text-lg",
            fontWeight: "font-semibold lg:font-bold",
            fontColor: "text-white",
          }}
          borderStyle={{
            borderRadius: "rounded",
          }}
          className="w-[111px] lg:w-auto h-[50px]"
          onClickHandler={() => {
            if (email.includes("@moneyhop.co")) {
              setIsModalOpen("sendCurrency");
            }
          }}
        >
          <div
            className={clsx(
              "flex justify-between group px-2 lg:px-0 lg:justify-end items-center w-full",
            )}
          >
            <div className={clsx("flex lg:justify-center items-center lg:flex-1 mr-3 sm:mr-0")}>
              <div className={clsx("w-8 h-8 flex justify-center items-center mr-2 lg:mr-7")}>
                <img
                  src={`/images/png/${sendingCurrency}.png`}
                  alt="sendingCurrency-image"
                  className="rounded-[200px] w-full h-full"
                />
              </div>
              <div>{sendingCurrency}</div>
            </div>
            <div
              className={clsx(
                "lg:flex lg:items-center group-hover:translate-y-1 transition-all lg:mr-9 mt-1 lg:mt-0",
              )}
            >
              {email.includes("@moneyhop.co") && <DropdownIcon fillColor="stroke-white" />}
            </div>
          </div>
        </Button>
        <div
          className={clsx(
            getSendMoneyErrorMessage(
              receiveCurrency === "USD" ? receiveCurrency : sendingCurrency,
              sendingAmount,
              error,
            ) && "border-error border",
            "w-full sm:w-[auto] lg:bg-white h-[50px] lg:h-32 lg:p-4 lg:pb-7 rounded lg:shadow-none lg:max-h-[124px] lg:w-full",
          )}
        >
          <div className="hidden lg:flex mb-2.5 lg:mb-3 lg:h-[40px] xl:h-auto items-center gap-2">
            <div className="text-[10px] lg:text-sm font-medium text-primary-550 text-sm">
              You Send
            </div>
          </div>
          <div className="!w-full lg:w-[292px] overflow-auto">
          <Controller
          control={control}
          name="sendingAmount"
          render={({ field: { name, onChange } }) => {
            return (
              <TextField
                name={name}
                type="text"
                inputClasses="!h-[50px] border lg:border-none bg-white lg:bg-transparent text-2xl leading-8 lg:leading-tight font-normal lg:text-4xl text-black ml-3 lg:!pl-0 !pt-0 lg:h-full !pl-4"
                placeholderStyle="text-black font-normal text-base font-ssp"
                value={formattedSendAmount}
                onChange={(event) => {
                  const newAmount = event.target.value.replace(/,/g, '');
                  onChange(newAmount);
                  setSendingAmount(newAmount);
                  setSendAmount(newAmount);
                  setOnChangeHandleType('send');
                  handleAmountChnage(newAmount);
                }}
                error={errors?.sendingAmount}
                fullWidth
              />
            );
          }}
        />
          </div>
        </div>
        <ErrorField
          errorMessage={getSendMoneyErrorMessage(
            receiveCurrency === "USD" ? receiveCurrency : sendingCurrency,
            sendingAmount,
            error,
          )}
        />
      </div>
      <div className="items-center justify-center hidden w-16 h-16 mx-12 mt-24 transition-all duration-500 rounded-full cursor-pointer hover:animate-spin-fast lg:flex bg-primary lg:p-4 2xl:p-0">
        <RefreshIcon />
      </div>
      <div className="flex items-center mb-2 text-xs font-bold md:justify-center lg:hidden tetx-black">
        Receive
      </div>
      <div
        className={clsx(
          "flex justify-center sm:justify-center lg:flex-col lg:justify-center lg:space-y-5",
        )}
      >
        <Button
          id="fjksjkfka-dsdjsd-sdak8jsajF"
          bgColor="bg-primary"
          fontStyle={{
            fontFamily: "",
            fontSize: "text-base lg:text-lg",
            fontWeight: "font-semibold lg:font-bold",
            fontColor: "text-white",
          }}
          borderStyle={{
            borderRadius: "rounded",
          }}
          className="w-[111px] lg:w-auto h-[50px]"
          onClickHandler={() => {
            isInward && setShowInwardGBPModal(true);
            sendingCurrency === "INR" && setIsModalOpen("receiveCurrency");
          }}
        >
          <div
            className={clsx(
              "flex justify-between group px-2 lg:px-0 lg:justify-end items-center w-full",
            )}
          >
            <div className={clsx("flex lg:justify-center items-center lg:flex-1 mr-3 sm:mr-0")}>
              <div className={clsx("w-8 h-8 flex justify-center items-center mr-2 lg:mr-7")}>
                <img
                  src={`/images/png/${receiveCurrency}.png`}
                  alt="receiveCurrency-image"
                  className="rounded-[200px] w-full h-full"
                />
              </div>
              <div>{receiveCurrency}</div>
            </div>
            <div
              className={clsx(
                "lg:flex lg:items-center group-hover:translate-y-1 transition-all lg:mr-9 mt-1 lg:mt-0",
              )}
            >
              <DropdownIcon fillColor="stroke-white" />
            </div>
          </div>
        </Button>
        <div className="w-full sm:w-[auto] lg:bg-white h-[50px] lg:h-32 lg:p-4 lg:pb-7 rounded lg:shadow-none lg:max-h-[124px] lg:w-full">
          <div className="hidden lg:flex mb-2.5 lg:mb-3 lg:h-[40px] xl:h-auto items-center gap-2">
            <div className="text-[10px] lg:text-sm font-medium text-primary-550 text-sm">
              They Receive
            </div>
          </div>
          <div className="lg:w-[292px] !w-full overflow-auto">
            <TextField
              name="receivingAmount"
              type="text"
              inputClasses="!h-[50px] border lg:border-none bg-white lg:bg-transparent text-2xl leading-8 lg:leading-tight font-normal lg:text-4xl text-black ml-3 lg:!pl-0 !pt-0 lg:h-full !pl-4"
              value={formattedReceiveAmount}
              onChange={(e) => {
                const newAmount = e.target.value.replace(/,/g, '');
                setReceivingAmount(newAmount), setOnChangeHandleType("receive");
              }}
              fullWidth
            />
          </div>
        </div>
      </div>
    </div>
  );
};

export default SendReceiveMoney;
