import React from "react";
import { IIcons } from "./icons";

const Minus = ({ fillColor }: IIcons.IconProps) => {
  return (
    <svg
      width="20"
      height="20"
      viewBox="0 0 20 20"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      className={fillColor}
    >
      <circle cx="10" cy="10" r="10" fill="#D3E2FF" />
      <path d="M13.3569 8.95312V10.0618H6.99805V8.95312H13.3569Z" fill="#0042C2" />
      <path d="M13.3569 8.95312V10.0618H6.99805V8.95312H13.3569Z" fill="#0042C2" />
    </svg>
  );
};

export default Minus;
