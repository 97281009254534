import * as React from "react";

const TelegramEllipse = () => (
  <svg width="56" height="56" viewBox="0 0 56 56" fill="none" xmlns="http://www.w3.org/2000/svg">
    <circle opacity="0.5" cx="28" cy="28" r="26.5" stroke="#0F5BF1" strokeWidth="3" />
    <g opacity="0.5">
      <path
        d="M36.2083 18C36.1324 18.0005 36.0568 18.012 35.9841 18.034C35.9627 18.0398 35.9416 18.0465 35.9207 18.0541L18.5149 23.5896V23.5927C18.3639 23.649 18.2337 23.7502 18.1416 23.8825C18.0496 24.0149 18.0002 24.1721 18 24.3333C18.0003 24.4693 18.0356 24.6029 18.1025 24.7212C18.1694 24.8395 18.2656 24.9387 18.3819 25.009L23.6715 29.2055L34.1287 20.8713L25.7945 31.3285L29.9879 36.615C30.0581 36.7323 30.1576 36.8295 30.2765 36.8969C30.3955 36.9644 30.5299 36.9999 30.6667 37C30.8279 36.9998 30.9851 36.9504 31.1175 36.8584C31.2498 36.7663 31.351 36.6361 31.4073 36.4851H31.4104L36.9505 19.0638C36.9562 19.048 36.9613 19.032 36.966 19.0159C36.988 18.9432 36.9995 18.8676 37 18.7917C37 18.5817 36.9166 18.3803 36.7681 18.2319C36.6197 18.0834 36.4183 18 36.2083 18Z"
        fill="#0F5BF1"
      />
    </g>
  </svg>
);

export default TelegramEllipse;
