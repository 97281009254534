import clsx from "clsx";
import React, { useState, useEffect, useRef } from "react";

import { CloseIcon, ForgotModalBanner } from "../../../assets/Icons";
import { sideBannerSubText } from "../../../utils";
import { IforgtPasswordModal } from "./ForgotPasswordModal";

const defaultAnimationClass = {
  beforeAnimation: "md:bottom-0",
  afterAnimation: "md:bottom-2/4",
};

const DELAY_BEFORE_TRNASITION_START = 10;
const TRNASITION_TIME = 500;

const ForgotPasswordModal: React.FunctionComponent<IforgtPasswordModal.IProps> = ({
  children,
  closeHandler,
  modalClass,
  maskClass,
  visible,
  maskClosable = true,
  mask = true,
  animation = true,
  animationClass = defaultAnimationClass,
  id,
  step,
  selectionType,
}) => {
  const [animatingclass, setAnimatingClass] = useState(false);

  const modalRef = useRef<HTMLDivElement>(null);

  const handleClose = (event: React.MouseEvent) => {
    if (animation) {
      setAnimatingClass(false);
      setTimeout(() => closeHandler(event), TRNASITION_TIME);
    } else {
      setAnimatingClass(false);
      closeHandler(event);
    }
  };

  const defaultMaskClass = mask && "bg-black opacity-100";

  const onMaskClick = (event: React.MouseEvent) => {
    if (maskClosable) {
      if (modalRef?.current) {
        if (!modalRef || modalRef?.current.contains(event.target as HTMLElement)) {
          return;
        } else {
          handleClose(event);
        }
      }
    }
  };

  useEffect(() => {
    if (animation) {
      if (visible) {
        setTimeout(() => {
          setAnimatingClass(true);
        }, DELAY_BEFORE_TRNASITION_START);
      } else setAnimatingClass(false);
    }
  }, [visible]);

  const modalJSX = (
    <div
      onClick={onMaskClick}
      className={clsx(
        "top-0 left-0 w-full h-full fixed z-50 bg-opacity-40",
        defaultMaskClass,
        maskClass,
      )}
    >
      <div
        id={id}
        ref={modalRef}
        className={clsx(
          "bottom-0 w-screen md:w-fit bg-white left-2/4  absolute -translate-x-1/2 md:translate-y-1/2  transition-[bottom]  duration-500",
          animatingclass
            ? animationClass?.afterAnimation
            : animation
            ? animationClass?.beforeAnimation
            : animationClass?.afterAnimation,
          modalClass,
        )}
      >
        <div className="flex justify-end w-full p-6 md:hidden">
          <button onClick={handleClose}>
            <CloseIcon fill="fill-grey-1200" />
          </button>
        </div>
        <div className="flex w-full h-[577px] justify-between">
          <div className="w-full lg:w-1/2">{children}</div>
          <div className="hidden w-1/2 p-6 lg:block bg-primary-1200">
            <button className="float-right" onClick={handleClose}>
              <CloseIcon fill="fill-grey-1200" />
            </button>
            <div id="text" className="flex flex-col mt-10 ml-4">
              <span className="text-[26px] text-black font-semibold">Forgot your password?</span>
              <span
                className={clsx(
                  step === 3 ? "font-bold" : "font-normal",
                  "mt-2 text-lg text-black",
                )}
              >
                {step === 3 && selectionType === "mobile"
                  ? "We have sent an OTP to your registered mobile number."
                  : sideBannerSubText[step]}
              </span>
            </div>
            <div className="flex justify-center mt-10">
              <ForgotModalBanner />
            </div>
          </div>
        </div>
      </div>
    </div>
  );

  return visible ? modalJSX : <></>;
};

export default ForgotPasswordModal;
