/* eslint-disable */
import React, { useEffect, useState } from "react";
import { Confirmation, Five, Four, One, Three, Two } from "../../assets/Icons";
import Button from "../../components/ui/Button";
import Typograpy from "../../components/ui/Typography";
import { useNavigate } from "react-router-dom";
import { paymentReview } from "../../services/confirmation";
import { motion } from "framer-motion";
import { useRecoilState, useRecoilValue } from "recoil";
import { isInwardTransaction, transactionId } from "../../store";
import mixpanel from "mixpanel-browser";

export const ConfirmationPage = () => {
  const navigate = useNavigate();
  const [rating, setRating] = useState<number>(0);
  const [comment, setComment] = useState<string>("");
  const [isInward, setIsInward] = useRecoilState(isInwardTransaction);
  const transactionNumber = useRecoilValue(transactionId);
  const [error, setError] = useState("");
  const mixpanelToken: any = process.env.REACT_APP_MIXPANEL_TOKEN;
  mixpanel.init(mixpanelToken, {
    debug: true,
    api_host:process.env.REACT_APP_MIXPANEL_API,
  });
  const currentUser = JSON.parse(sessionStorage.getItem("currentUser") || "{}");
  const handleSubmit = () => {
    setError("");
    const body = {
      comment: comment,
      rating: rating,
      transaction_number: transactionNumber,
    };
    paymentReview(body, currentUser.idToken)
      .then(() => {
        navigate("/");
      })
      .catch((error) => {
        setError(error?.response?.data?.error?.type);
      });
  };

  useEffect(() => {
    mixpanel.track("Thankyou screen ");
  }, []);

  const handlegoToHistory = () => {
    navigate("/history");
    // const body = {
    //   comment: comment,
    //   rating: rating,
    //   transaction_id: "",
    // };
    // paymentReview(body).then(() => {
    //   navigate("/");
    // });
  };

  const rateTransaction = [
    {
      rate: 1,
      image: <One />,
    },
    {
      rate: 2,
      image: <Two />,
    },
    {
      rate: 3,
      image: <Three />,
    },
    {
      rate: 4,
      image: <Four />,
    },
    {
      rate: 5,
      image: <Five />,
    },
  ];

  return (
    <motion.div
      className="flex flex-col items-center justify-center w-full h-full space-y-5"
      // initial={{ width: 0 }}
      // animate={{ width: "100%" }}
      // exit={{ x: window.innerWidth, transition: { duration: 0.5 } }}
    >
      <Confirmation />
      <Typograpy tagType="span" className="font-bold text-[22px]">
        Thank you for transacting with us! 
        Give us your feedback and earn 100 hop coins which can be redeemed on your next transaction!!
      </Typograpy>
      <p className="mt-2 text-[18px]">
        <span className="font-bold">Transaction ID</span> : {transactionNumber}
      </p>
      {/* <Typograpy tagType="div" className="sm:ml-20">
        In case of any query reach out to Hop support at care@moneyhop.co
      </Typograpy> */}
      {isInward ? <div className="">Reach out to Hop support at care@moneyhop.co</div> : null}
      {/* <div className="">Reach out to Hop support at care@moneyhop.co</div> */}
      <div id="Review" className="flex mt-6 lg:space-x-5">
        {rateTransaction.map((element, index) => {
          return (
            <div key={index} onClick={() => setRating(element.rate)} className={"cursor-pointer"}>
              {element.image}
            </div>
          );
        })}
      </div>
      {rating !== 0 && <span>Selected Rating: {rating}</span>}
      <div>
        <input
          type="text"
          placeholder="Enter your comment here..."
          onChange={(e) => setComment(e.target.value)}
          className="w-[500px] h-[60px] p-3 rounded-lg"
          autoComplete="off"
          pattern="[a-zA-Z0-9 ]{1,}"
        />
      </div>
      <div className="mt-8">
        {isInward === false ? (
          <Button
            id="submit-confirm-page"
            onClickHandler={handleSubmit}
            className="w-[300px] h-12 rounded-3xl font-bold text-white bg-blue-800"
          >
            Submit
          </Button>
        ) : (
          <>
            <Button
              id="submit-confirm-page"
              onClickHandler={handleSubmit}
              className="w-[300px] h-12 rounded-3xl font-bold text-white bg-[#0F5BF1] "
            >
              Go to Dashboard
            </Button>
            <Button
              id="submit-confirm-page"
              onClickHandler={handlegoToHistory}
              className="w-[300px] h-12 rounded-3xl font-bold text-[#0F5BF1] bg-[#F1F4FF] mt-10"
            >
              Go to History
            </Button>
          </>
        )}
        {error && (
          <div className="flex justify-center mt-2">
            <span className="text-red-600">{error}</span>
          </div>
        )}
      </div>
    </motion.div>
  );
};
