import React, { useState } from "react";
import { useRecoilValue } from "recoil";

import {
  // ConvenienceFeeIcon,
  DivisionIcon,
  DownIcon,
  Equal,
  Plus,
  RightNavigationIcon,
} from "../../assets/Icons";
import { isInwardTransaction, transactionDetails } from "../../store";
import { convertToTwoDigit, formatNumber } from "../../utils";
import IReviewPage from "./review";

const PaymentDetailsCard: React.FC<IReviewPage.PaymentDetailsProps> = () => {
  const transactionSummaryData = useRecoilValue(transactionDetails);
  const [sendStep, setSendStep] = useState<boolean>(false);
  const [taxStep, setTaxStep] = useState<boolean>(false);
  const [discountStep, setDiscountStep] = useState<boolean>(false);
  const hopCoinsApplied: any = transactionSummaryData?.hop_coins;
  const isInward = useRecoilValue(isInwardTransaction);

  const tcs = convertToTwoDigit(transactionSummaryData?.tcs);
  const service_tax = convertToTwoDigit(transactionSummaryData?.service_tax);
  const nostro_charges = convertToTwoDigit(transactionSummaryData?.nostro_charges);
  // const convenience_fees = convertToTwoDigit(transactionSummaryData?.hop_fees);
  const taxPrices = service_tax + nostro_charges + tcs;

  return (
    <>
      <div className="flex items-center justify-start mt-2 mb-3 text-sm font-bold text-black sm:mx-3 lg:hidden">
        Review
      </div>
      <div className="flex flex-col bg-white lg:px-[3%] px-[5%] py-[2%] flex-grow mr-[2%]">
        <div className="hidden lg:flex text-sm font-bold text-black mb-[30px]">Payment Details</div>
        <div className="flex items-center justify-between mb-9">
          <div className="flex flex-col items-start justify-between lg:space-y-6">
            <div className="text-3xl font-normal text-black mb-[6px] lg:mb-0">
              {formatNumber(transactionSummaryData.user_send ?? 1000)}
            </div>
            <div className="flex items-center justify-center flex-1">
              <div className="flex items-center justify-center w-8 h-8 mr-3 lg:mr-7">
                <img
                  src={`/images/png/${transactionSummaryData.base_currency ?? "INR"}.png`}
                  alt="profile-image"
                  className="rounded-[200px] w-full h-full"
                />
              </div>
              <div className="font-bold text-primary lg:text-[18px] text-sm">
                {transactionSummaryData.base_currency ?? "INR"}
              </div>
            </div>
          </div>
          <div className="flex items-center justify-center w-20 h-20 rounded-full bg-primary-450">
            <RightNavigationIcon />
          </div>
          <div className="flex flex-col items-start justify-between lg:space-y-6">
            <div className="text-3xl font-normal text-black mb-[6px] lg:mb-0">
              {formatNumber(transactionSummaryData.reciepient_recieve) ?? 10.17}
            </div>
            <div className="flex items-center justify-center flex-1">
              <div className="flex items-center justify-center w-8 h-8 mr-3 lg:mr-7">
                <img
                  src={`/images/png/${transactionSummaryData.dest_currency ?? "USD"}.png`}
                  alt="profile-image"
                  className="rounded-[200px] w-full h-full"
                />
              </div>
              <div className="font-bold text-primary lg:text-[18px] text-sm">
                {transactionSummaryData.dest_currency ?? "USD"}
              </div>
            </div>
          </div>
        </div>
        <div className="mb-5 text-sm font-bold text-black">Calculation</div>
        <div>
          <div className="flex items-center justify-between mb-8">
            <div className="flex flex-col flex-1 -ml-2">
              <div
                onClick={() => setSendStep(!sendStep)}
                className="flex items-center justify-between mb-0 cursor-pointer hover:bg-gray-100"
              >
                <div className="flex items-center space-x-2 text-xs font-bold text-black lg:text-sm">
                  <div className="mr-3">
                    <DownIcon />
                  </div>
                  You Send
                </div>
                <div className="flex items-center justify-between space-x-5 lg:space-x-16">
                  <div className="text-xs font-bold text-black lg:text-base">
                    {formatNumber(transactionSummaryData?.user_send)}
                  </div>
                  <div className="text-xs font-bold text-black lg:text-sm">
                    {" "}
                    {isInward ? "GBP" : "INR"}
                  </div>
                </div>
              </div>
              {sendStep === true && (
                <div className="-ml-1">
                  <div className="flex items-center justify-between mt-5 ml-2">
                    <div className="flex items-center text-xs font-normal text-black lg:text-sm">
                      <div className="mr-3">
                        <Equal />
                      </div>
                      Conversion amount
                    </div>
                    <div className="flex items-center justify-between space-x-5 lg:space-x-16">
                      <div className="text-xs font-normal text-black lg:font-bold lg:text-base">
                        {isInward
                          ? formatNumber(transactionSummaryData?.reciepient_recieve)
                          : formatNumber(transactionSummaryData?.conversion_amount)}
                      </div>
                      <div className="text-xs font-normal text-black lg:font-bold lg:text-sm">
                        INR
                      </div>
                    </div>
                  </div>
                </div>
              )}
            </div>
          </div>
          <div className="flex items-center justify-between mb-8">
            <div className="bg-grey-1350 w-[30px] h-[30px] rounded-full flex justify-center items-center -ml-2">
              <DivisionIcon />
            </div>
            <div className="flex items-center justify-between flex-1 ml-5">
              <div className="text-xs font-bold lg:text-sm text-grey-150">Exchange rate</div>
              <div className="flex items-center justify-between space-x-5 lg:space-x-16">
                <div className="text-xs font-bold lg:text-base text-grey-150">
                  {transactionSummaryData?.conversion_rate?.toFixed(2)}
                </div>
                <div className="text-xs font-bold lg:text-sm text-grey-150">INR</div>
              </div>
            </div>
          </div>
          <div className="flex items-center justify-between mb-8">
            <div className="flex flex-col flex-1 -ml-2">
              <div
                onClick={() => (isInward ? null : setTaxStep(!taxStep))}
                className="flex items-center justify-between cursor-pointer hover:bg-gray-100"
              >
                <div className="flex items-center text-xs font-bold text-black lg:text-sm">
                  <div className="mr-3">
                    <DownIcon />
                  </div>
                  Taxes and fees
                </div>
                <div className="flex items-center justify-between space-x-5 lg:space-x-16">
                  <div className="text-xs font-bold text-black lg:text-base">{formatNumber(taxPrices)}</div>
                  <div className="text-xs font-bold text-black lg:text-sm">
                    {isInward ? "GBP" : "INR"}
                  </div>
                </div>
              </div>
              {taxStep === true && (
                <div className="mt-5 ml-1">
                  <div className="flex items-center justify-between mb-4">
                    <div className="flex items-center text-xs font-normal text-black lg:text-sm">
                      <div className="mr-3">
                        <Plus />
                      </div>
                      GST
                    </div>
                    <div className="flex items-center justify-between space-x-5 lg:space-x-16">
                      <div className="text-xs font-normal text-black lg:text-sm">
                        {formatNumber(transactionSummaryData?.service_tax)}
                      </div>
                      <div className="text-xs font-normal text-black lg:text-sm">INR</div>
                    </div>
                  </div>
                  {/* <div className="flex items-center justify-between mb-4">
                    <div className="flex items-center text-xs font-normal text-black lg:text-sm">
                      <div className="mr-3">
                        <Plus />
                      </div>
                      Convenience Fees
                      <ConvenienceFeeIcon />
                    </div>
                    <div className="flex items-center justify-between space-x-5 lg:space-x-16">
                      {isInward ? (
                        <div className="text-xs font-normal text-black lg:text-sm">
                          <span
                            className={
                              isInward
                                ? "mr-2 line-through text-[#FF7A00] font-bold"
                                : "mr-2 line-through"
                            }
                          >
                            {transactionSummaryData?.hop_fees}
                          </span>
                          <span className="text-xs font-normal text-black lg:text-sm">0</span>
                        </div>
                      ) : (
                        <div className="text-xs font-normal text-black lg:text-sm">
                          {transactionSummaryData?.hop_fees}
                        </div>
                      )}
                      <div
                        className={
                          isInward
                            ? "text-xs font-bold text-[#FF7A00] lg:text-sm"
                            : "text-xs font-normal text-black lg:text-sm"
                        }
                      >
                        {isInward ? "GBP" : "INR"}
                      </div>
                    </div>
                  </div> */}
                  <div className="flex items-center justify-between mb-5">
                    <div className="flex items-center text-xs font-normal text-black lg:text-sm">
                      <div className="mr-3">
                        <Plus />
                      </div>
                      {transactionSummaryData?.purposeCode === "S0305" && transactionSummaryData?.hop_fees>0 ? (
                          <span>Bank & Convenience Fees </span>
                        ) : (
                          <span>Bank Fee</span>
                        )}
                    </div>
                    <div className="flex items-center justify-between space-x-5 lg:space-x-16">
                      <div className="text-xs font-normal text-black lg:font-bold lg:text-base">
                        {formatNumber(transactionSummaryData?.nostro_charges)}
                      </div>
                      <div className="text-xs font-normal text-black lg:font-bold lg:text-sm">
                        INR
                      </div>
                    </div>
                  </div>
                  <div className="flex items-center justify-between">
                    <div className="flex items-center text-xs font-normal text-black lg:text-sm">
                      <div className="mr-3">
                        <Plus />
                      </div>
                      TCS
                    </div>
                    <div className="flex items-center justify-between space-x-5 lg:space-x-16">
                      <div className="text-xs font-normal text-black lg:font-bold lg:text-base">
                        {formatNumber(tcs)}
                      </div>
                      <div className="text-xs font-normal text-black lg:font-bold lg:text-sm">
                        INR
                      </div>
                    </div>
                  </div>
                </div>
              )}
            </div>
          </div>
          <div className="flex items-center justify-between mb-8">
            <div className="flex flex-col flex-1 -ml-2">
              <div
                onClick={() => setDiscountStep(!discountStep)}
                className="flex items-center justify-between cursor-pointer hover:bg-gray-100"
              >
                <div className="flex items-center text-xs font-bold text-black lg:text-sm">
                  <div className="mr-3">
                    <DownIcon />
                  </div>
                  Total Discount
                </div>
                <div className="flex items-center justify-between space-x-5 lg:space-x-16">
                  <div className="text-xs font-bold text-black lg:text-base">
                    {isInward
                      ? "0"
                      : transactionSummaryData?.promoCodeDetails?.promoCodeDiscount +
                        hopCoinsApplied / 2}
                  </div>
                  <div className="text-xs font-bold text-black lg:text-sm">
                    {isInward ? "GBP" : "INR"}
                  </div>
                </div>
              </div>
              {discountStep === true && (
                <div className="mt-5 ml-1">
                  <div className="flex items-center justify-between mb-4">
                    <div className="flex items-center text-xs font-normal text-black lg:text-sm">
                      <div className="mr-3">
                        <Plus />
                      </div>
                      {transactionSummaryData?.promoCodeDetails?.promoCodeApplied
                        ? `${transactionSummaryData?.promoCodeDetails?.promoCode} applied`
                        : "Promo Code "}
                    </div>
                    <div className="flex items-center justify-between space-x-5 lg:space-x-16">
                      <div className="text-xs font-normal text-black lg:text-sm">
                        {transactionSummaryData?.promoCodeDetails?.promoCodeDiscount
                          ? transactionSummaryData?.promoCodeDetails?.promoCodeDiscount
                          : 0}
                      </div>
                      <div className="text-xs font-normal text-black lg:text-sm">
                        {isInward ? "GBP" : "INR"}
                      </div>
                    </div>
                  </div>
                  <div className="flex items-center justify-between mb-5">
                    <div className="flex items-center text-xs font-normal text-black lg:text-sm">
                      <div className="mr-3">
                        <Plus />
                      </div>
                      HOP Coins
                    </div>
                    <div className="flex items-center justify-between space-x-5 lg:space-x-16">
                      <div className="text-xs font-normal text-black lg:font-bold lg:text-base">
                        {hopCoinsApplied / 2}
                      </div>
                      <div className="text-xs font-normal text-black lg:font-bold lg:text-sm">
                        {isInward ? "GBP" : "INR"}
                      </div>
                    </div>
                  </div>
                </div>
              )}
              {isInward && (
                <div className="flex items-center justify-between mt-5 ml-3">
                  <div className="flex flex-col flex-1 -ml-2">
                    <div className="flex items-center justify-between cursor-pointer hover:bg-gray-100">
                      <div className="flex items-center text-xs group-hover:text-[16px] transition-all font-bold text-black lg:text-sm">
                        <div className="mr-3 transition-all group-hover:-rotate-90 animate-pulse">
                          <Equal />
                        </div>
                        They Recieve
                      </div>
                      <div className="flex items-center justify-between space-x-5 lg:space-x-16">
                        <div className="text-xs font-bold text-black lg:text-base group-hover:text-[16px] transition-all">
                          {formatNumber(transactionSummaryData?.reciepient_recieve)}
                        </div>
                        <div className="text-xs font-bold text-black lg:text-sm group-hover:text-[16px] transition-all">
                          INR
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              )}
              {isInward && (
                <div className="flex items-center justify-between ml-3">
                  <div className="flex flex-col flex-1 -ml-2">
                    <div className="flex items-center justify-between cursor-pointer hover:bg-gray-100">
                      <div className="flex items-center text-xs group-hover:text-[16px] transition-all font-bold text-black lg:text-sm">
                        <div className="mr-3 transition-all group-hover:-rotate-90 animate-pulse">
                          <Equal size={40} />
                        </div>
                        You Pay
                      </div>
                      <div className="flex items-center justify-between space-x-5 lg:space-x-16">
                        <div className="text-xs font-bold text-black lg:text-base group-hover:text-[16px] transition-all">
                          {formatNumber(transactionSummaryData?.user_send)}
                        </div>
                        <div className="text-xs font-bold text-black lg:text-sm group-hover:text-[16px] transition-all">
                          {isInward ? "GBP" : "INR"}
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              )}
            </div>
          </div>
          {/* <div className="flex items-center justify-between mb-8">
            <div className="bg-success w-[30px] h-[30px] rounded-full flex justify-center items-center -ml-2">
              <div className="w-2 h-[1.5px] bg-white" />
            </div>
            <div className="flex items-center justify-between flex-1 ml-5">
              <div className="text-xs font-bold lg:text-sm text-success">
                Promo Code {transactionSummaryData?.promo_code}
              </div>
              <div className="flex items-center justify-between space-x-5 lg:space-x-16">
                <div className="text-xs font-bold lg:text-base text-success">
                  {transactionSummaryData?.promoCodeDetails?.promoCodeDiscount
                    ? transactionSummaryData?.promoCodeDetails?.promoCodeDiscount
                    : 0}
                </div>
                <div className="text-xs font-bold lg:text-sm text-success">INR</div>
              </div>
            </div>
          </div>
          <div className="flex items-center justify-between mb-8">
            <div className="bg-success w-[30px] transition-all group-hover:scale-125 h-[30px] rounded-full flex justify-center items-center -ml-2 cursor-pointer">
              <div className="w-2 h-[1.5px] bg-white" />
            </div>
            <div className="flex items-center justify-between flex-1 ml-5">
              <div className="text-xs font-bold transition-all lg:text-sm text-success group-hover:text-[16px]">
                {hopCoinsApplied} HOP Coins Added
              </div>
              <div className="flex items-center justify-between space-x-5 lg:space-x-16">
                <div className="text-xs font-bold lg:text-base text-success group-hover:text-[16px] transition-all">
                  {hopCoinsApplied / 2}
                </div>
                <div className="text-xs font-bold lg:text-sm text-success group-hover:text-[16px] transition-all">
                  INR
                </div>
              </div>
            </div>
          </div> */}
        </div>
        <div className="hidden md:flex bg-grey-250 h-[1px]"></div>
        <div className="items-center justify-between hidden mt-5 mb-6 lg:flex">
          <div className="text-[22px] font-bold text-black">Total Payable</div>
          <div className="flex items-center justify-between space-x-10">
            <div className="text-[22px] font-bold text-black">
              {formatNumber(transactionSummaryData?.total_pay)}
            </div>
            <div className="text-[22px] font-bold text-black">{isInward ? "GBP" : "INR"}</div>
          </div>
        </div>
      </div>
    </>
  );
};

export default PaymentDetailsCard;
