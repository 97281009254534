import React from "react";
import { IIcons } from "./icons";

const DeleteBin = ({ fillColor }: IIcons.IconProps) => {
  return (
    <svg
      width="24"
      height="30"
      viewBox="0 0 17 19"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      className={fillColor}
    >
      <path
        d="M7.52362 0.638672C7.05255 0.638672 6.60247 0.824836 6.26898 1.15832L5.87746 1.54984H1.32161C1.20087 1.54814 1.08099 1.57044 0.968947 1.61547C0.856902 1.66049 0.754922 1.72733 0.668936 1.81211C0.582949 1.89689 0.514669 1.99792 0.468065 2.10931C0.42146 2.22071 0.397461 2.34026 0.397461 2.46101C0.397461 2.58177 0.42146 2.70132 0.468065 2.81271C0.514669 2.92411 0.582949 3.02513 0.668936 3.10991C0.754922 3.19469 0.856902 3.26154 0.968947 3.30656C1.08099 3.35159 1.20087 3.37389 1.32161 3.37219H15.9003C16.0211 3.37389 16.141 3.35159 16.253 3.30656C16.3651 3.26154 16.467 3.19469 16.553 3.10991C16.639 3.02513 16.7073 2.92411 16.7539 2.81271C16.8005 2.70132 16.8245 2.58177 16.8245 2.46101C16.8245 2.34026 16.8005 2.22071 16.7539 2.10931C16.7073 1.99792 16.639 1.89689 16.553 1.81211C16.467 1.72733 16.3651 1.66049 16.253 1.61547C16.141 1.57044 16.0211 1.54814 15.9003 1.54984H11.3445L10.953 1.15832C10.6204 0.824836 10.1694 0.638672 9.69833 0.638672H7.52362ZM1.6544 5.19453L3.04607 17.28C3.16635 18.1821 3.94305 18.8621 4.8524 18.8621H12.3678C13.2771 18.8621 14.0547 18.1831 14.1759 17.2729L15.5676 5.19453H1.6544Z"
        fill="white"
      />
    </svg>
  );
};

export default DeleteBin;
