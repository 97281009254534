/*eslint-disable */
import React, { useState } from "react";
import clsx from "clsx";
//#end Global Imports

//#Local Imports
import { DropdownIcon } from "../../assets/Icons";
import Button from "../../components/ui/Button";
import ISendMoney from "./sendMoney";
import { purposeCodeList, purposeCodeListInward } from "../../utils";
//#end Local Imports

const SelectPurpose: React.FC<ISendMoney.ISelectPurposeProps> = ({
  confirmedPurpose,
  setConfirmedPurpose,
  setIsModalOpen,
  isInward,
  setIsInward,
  isTcsPresent,
  purposeError,
  purposeErrorInward,
}) => {
  const [isOpen, setIsOpen] = useState(false);
  const [selectedPurpose, setSelectedPurpose] = useState("");

  const getSelectedPurpose = (purposeCode: any) => {
    if (isInward) {
      return purposeCodeListInward.filter((data) => data.value === purposeCode)[0];
    } else {
      return purposeCodeList.filter((data) => data.value === purposeCode)[0];
    }
  };

  const handleOptionClick = (selectedValue: any) => {
    setSelectedPurpose(selectedValue)
    if (selectedValue === "Overseas Education - University Fees" || selectedValue === "Overseas Education - Living Expenses") {
      if (isTcsPresent) {
        setIsModalOpen("showLoanConfirmation");
      } else {
        // setConfirmedPurpose(getSelectedPurpose(selectedValue));
      }
    } else if (selectedValue === "Family Maintenance") {
      setIsModalOpen("showFamilyMaintenance");
      // setConfirmedPurpose(getSelectedPurpose(selectedValue))
    } else if (selectedValue === "Personal Gift or Donation") {

      setIsModalOpen("PersonalGiftOrDonation");
      // setConfirmedPurpose(getSelectedPurpose(selectedValue))
    } else {
      // setConfirmedPurpose(getSelectedPurpose(selectedValue));
    }
    setConfirmedPurpose(getSelectedPurpose(selectedValue));
    setIsOpen(false);
  };
  return (
    <div className="flex flex-col lg:items-start items-center">
      <div className={clsx("flex justify-center lg:justify-start items-center lg:ml-[83px] mt-7")}>
        <div className="flex items-center">
          <div className="hidden mr-12 font-medium text-black lg:text-base lg:flex">
            Select Purpose of Payment
          </div>
          <div className="flex flex-col items-center">
            <button className="bg-white py-4 px-10 rounded flex justify-between items-center" onClick={() => setIsOpen(!isOpen)}>
              {selectedPurpose || "Select an option"}
              <div className="ml-5">
                <DropdownIcon fillColor="stroke-black" />
              </div>
            </button>
            {isOpen && (
              <div className="absolute mt-16 bg-white rounded shadow-lg z-50">
                {purposeCodeList.map((purposeCode) => (
                  <button
                    key={purposeCode.value}
                    className="block w-full text-black py-2 px-4 hover:bg-gray-100"
                    onClick={() => handleOptionClick(purposeCode.value)}
                  >
                    {purposeCode.value}
                  </button>
                ))}
              </div>
            )}
          </div>
        </div>
        {/* <div className="flex flex-col">
          <Button
            id="dkadkaj"
            bgColor="bg-white"
            fontStyle={{
              fontFamily: "",
              fontSize: "text-lg",
              fontWeight: "font-bold ",
              fontColor: "text-white",
            }}
            borderStyle={{
              borderRadius: "rounded",
            }}
            className="w-[378px] 2xl:w-[374px] lg:w-[300px] h-[50px]"
            onClickHandler={() => setIsModalOpen("selectPurpose")}
          >
            <div className={clsx("flex justify-between items-center w-full px-6")}>
              <div className="font-normal text-black text-[12px] lg:text-base overflow-hidden text-ellipsis whitespace-nowrap max-w-full">
                {confirmedPurpose.value}
              </div>
              <DropdownIcon fillColor="stroke-primary-550" />
            </div>
          </Button>
          {purposeError || purposeErrorInward ? (
            <span className="text-[14px] text-red-500 font-bold hidden lg:inline lg:ml-4">
              Please select a purpose code.
            </span>
          ) : null}
        </div> */}
      </div>
      {/* {confirmedPurpose?.code === "S1301" && (
        <div className="text-[15px] w-96 mt-5 lg:w-auto text-red-500 text-center lg:ml-[83px] font-bold relative">
          As per the RBI guidelines, we do not process self-transfers under Family Maintenance
          Purpose Code. Please proceed only if you are sending money to your blood relatives.
        </div>
      )}
      {confirmedPurpose?.code === "S1302" && (
        <div className="text-[15px] w-96 mt-5 lg:w-auto text-red-500 text-center lg:ml-[83px] font-bold relative">
          As per the RBI guidelines, we do not process self-transfers under Gift purpose code.
          Please proceed only if you are gifting this amount to other than yourself.
        </div>
      )} */}
      {confirmedPurpose?.value === "Overseas Education - Living Expenses" && (
        <div className="text-[15px] w-96 mt-5 lg:w-auto text-red-500 text-center lg:ml-[83px] font-bold relative">
          If the student has already moved out of India, self-transfer will not get processed.
          Transactions can only be processed using the parent’s KYC.
        </div>
      )}
    </div>
  );
};

export default SelectPurpose;
