/* eslint-disable */
import React, { FunctionComponent } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import Button from "../../../components/ui/Button";
import { CopyIconBlue } from "../../../assets/Icons";
export const InwardPayment: FunctionComponent<any> = ({
  accountName,
  bankName,
  sortCode,
  iban,
  swift,
  address,
}) => {
  const navigate = useNavigate();

  const { state } = useLocation();
  const handleCopyClick = async (text: any) => {
    try {
      await navigator.clipboard.writeText(text);
    } catch (err) {
      console.error("Failed to copy: ", err);
    }
  };

  return (
    <div className="flex flex-col items-center m-10 md:items-start md:m-20">
      <p className="font-bold text-[40px] text-[#858585]">You Pay {state?.totalPay} GBP</p>
      <p className="font-bold text-[20px] text-[#858585] mt-6">
        Kindly make a fund transfer to the mentioned account
      </p>
      <p className="my-2 font-bold">Bank account details</p>
      <hr className="w-3/5 my-6 border-black" />
      <div className="lg:text-xl lg:space-y-2">
        <div className="flex flex-row justify-start">
          <span className="font-semibold">Account Name :</span>
          <span className="font-normal text-[#4D4D4D] ml-1"> Rational Foreign Exchange Ltd </span>
          <span className="mt-1 ml-1">
            <CopyIconBlue
              onClick={() => {
                handleCopyClick("Rational Foreign Exchange Ltd");
                alert("Account name copied");
              }}
            />
          </span>
        </div>

        <div className="flex flex-row justify-start">
          <span className="font-semibold">Bank Name :</span>
          <span className="font-normal text-[#4D4D4D] ml-1"> Clear Bank Ltd</span>
          <span className="mt-1 ml-1">
            <CopyIconBlue
              onClick={() => {
                handleCopyClick("Clear Bank Ltd");
                alert("Bank name copied");
              }}
            />
          </span>
        </div>
        <div className="flex flex-row justify-start">
          <span className="font-semibold">Account number :</span>
          <span className="font-normal text-[#4D4D4D] ml-1"> 00000030</span>
          <span className="mt-1 ml-1">
            <CopyIconBlue
              onClick={() => {
                handleCopyClick("00000030");
                alert("Account number copied");
              }}
            />
          </span>
        </div>
        <div className="flex flex-row justify-start">
          <span className="font-semibold">SORT Code :</span>
          <span className="font-normal text-[#4D4D4D] ml-1"> 041363</span>
          <span className="mt-1 ml-1">
            <CopyIconBlue
              onClick={() => {
                handleCopyClick("00000030");
                alert("SORT CODE copied");
              }}
            />
          </span>
        </div>
        <div className="flex flex-row justify-start">
          {" "}
          <span className="font-semibold">IBAN :</span>
          <span className="font-normal text-[#4D4D4D] ml-1"> GB87RFXL04136300000030</span>{" "}
          <span className="mt-1 ml-1">
            <CopyIconBlue
              onClick={() => {
                handleCopyClick("GB87RFXL04136300000030");
                alert("IBAN copied");
              }}
            />
          </span>
        </div>

        <div className="flex flex-row justify-start">
          <span className="font-semibold">SWIFT :</span>
          <span className="font-normal text-[#4D4D4D] ml-1"> RFXLGB22</span>
          <span className="mt-1 ml-1">
            <CopyIconBlue
              onClick={() => {
                handleCopyClick(" RFXLGB22");
                alert("SWIFT copied");
              }}
            />
          </span>
        </div>
        <div className="flex flex-row justify-start mb-4">
          <span className="font-semibold">Address :</span>
          <span className="font-normal text-[#4D4D4D] ml-1F">
            Level 32 One Canada Square, Canary Wharf London
          </span>
          <span className="mt-1 ml-1">
            <CopyIconBlue
              onClick={() => {
                handleCopyClick("Level 32 One Canada Square, Canary Wharf London");
                alert("Address copied");
              }}
            />
          </span>
        </div>

        {/* <Button className="w-52 h-16 bg-[#CCCCCC] my-10 m-auto">Share A/C Details</Button> */}
      </div>
      <p className="text-primary text-[16px] font-semibold lg:mt-14">Note :</p>
      <p className="text-primary text-[16px] font-normal">
        The payment should be made in the next 3 working hours.
      </p>
      <p className="text-primary text-[16px] font-normal">
        Please make sure that the payment is being made from the remitter’s account only otherwise
        the transation might be rejected.
      </p>
      <Button
        className="w-1/5 m-auto my-16 text-white rounded-full h-14 bg-primary "
        onClickHandler={() => navigate("/confirmation")}
      >
        Confirm
      </Button>
    </div>
  );
};
