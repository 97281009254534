import React from "react";
import { IIcons } from "./icons";

const Recipients = ({ fillColor }: IIcons.IconProps) => {
  return (
    <svg
      width="18"
      height="19"
      viewBox="0 0 18 19"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      className={fillColor}
    >
      <path
        d="M9.0008 0C6.28922 0 4.09148 2.21887 4.09148 4.95652V5.78261C4.09148 8.52026 6.28922 10.7391 9.0008 10.7391C11.7124 10.7391 13.9101 8.52026 13.9101 5.78261V4.95652C13.9101 2.21887 11.7124 0 9.0008 0ZM8.9992 13.2174C5.72141 13.2174 1.51577 15.0074 0.30562 16.596C-0.442233 17.5782 0.269682 19 1.49619 19H16.5038C17.7303 19 18.4422 17.5782 17.6944 16.596C16.4842 15.0082 12.277 13.2174 8.9992 13.2174Z"
        fill="white"
      />
    </svg>
  );
};

export default Recipients;
