//#Global Imports
import React, { useState } from "react";
import clsx from "clsx";
//#end Global Imports

//#Local Imports
import { CloseIcon, LeftArrowIcon } from "../../assets/Icons";
import Typograpy from "../../components/ui/Typography";
import Button from "../../components/ui/Button";
import Stepper from "../../components/ui/Stepper";
import IUserDocuments from "./userDocuments";
import { TransactioncancellationReasons } from "../../utils";
import Modal from "../../components/ui/Modal";
import Checkbox from "../../components/ui/Checkbox";
import { useNavigate } from "react-router-dom";
import { handleTransactionComplete } from "../../services/transaction";
import { transactionDetails } from "../../store";
import { useResetRecoilState } from "recoil";
import { getUserDocs } from "../../services/userDetails";
//#end Local Imports

const NavbarAndStepperSection: React.FC<IUserDocuments.INavbarAndStepperSectionProps> = ({
  stepperSteps,
}) => {
  const [cancellationReasons, setCancellationReasons] = useState<string>("");
  const [otherReason, setOtherReason] = useState<string>("");
  const [isModalOpen, setIsModalOpen] = useState<string>("");
  const [isKycCompleted, setIsKycCompleted] = useState(true);
  const resetTransactionDetails = useResetRecoilState(transactionDetails);
  const navigate = useNavigate();
  const currentUser = JSON.parse(sessionStorage.getItem("currentUser") || "{}");

  React.useEffect(() => {
    getUserDocs(currentUser)
      .then((response) => {
        if (!response?.data?.isDocVerified) {
          setIsKycCompleted(false);
        } else {
          setIsKycCompleted(true);
        }
      })
      .catch((error) => {
        console.error(error);
      });
  }, []);

  const handleNavigate = () => {
    handleTransactionComplete();
    resetTransactionDetails();
    navigate("/");
  };
  return (
    <div>
      <div className="flex justify-between items-center bg-white md:hidden w-full py-[25px] mb-6 px-7">
        <div className="flex items-center w-fit" onClick={() => history.back()}>
          <LeftArrowIcon />
          <Typograpy
            text="Document Upload"
            className="ml-6 text-xl font-semibold text-grey-1100"
            tagType="div"
          />
        </div>
        <div className="flex items-center">
          <div
            className="mr-3 text-sm font-normal text-primary"
            onClick={() => navigate("/support")}
          >
            Need help ?
          </div>
          <div
            className="bg-gray-400 w-[30px] h-[30px] flex justify-center items-center rounded-full"
            onClick={() => setIsModalOpen("cancelReasons")}
          >
            <CloseIcon />
          </div>
        </div>
      </div>
      <div className={clsx("hidden md:flex justify-between items-center mr-[69px] pl-10")}>
        <Button
          id="sa-sakj-njksaj"
          bgColor="bg-primary-450"
          fontStyle={{
            fontFamily: "",
            fontSize: "text-sm",
            fontWeight: "font-medium",
            fontColor: "text-primary",
          }}
          borderStyle={{
            borderRadius: "rounded",
          }}
          className="py-2 px-[18px]"
          onClickHandler={() => history.back()}
        >
          {"<< Go Back"}
        </Button>
      </div>
      <div className={clsx("hidden lg:flex justify-center items-center mt-7 mb-[51px] pl-10")}>
        <div className={clsx("w-3/4")}>
          <Stepper
            numberOfSteps={isKycCompleted ? 5 : 6}
            steps={stepperSteps}
            activeStep={isKycCompleted ? 3 : 2}
          />
        </div>
      </div>
      <Modal
        header="Reason for cancellation"
        headerClass={"bg-white !my-0 py-6 px-12 font-bold text-lg"}
        modalClass="!p-0 !rounded !overflow-hidden !w-full"
        visible={isModalOpen === "cancelReasons"}
        closeHandler={() => setIsModalOpen("")}
        closable={false}
      >
        <div className="px-5 pb-5">
          {TransactioncancellationReasons.map((data, index) => {
            return (
              <div className="space-x-2 px-7 border-b-[1px] py-6 first:pt-0" key={index}>
                <Checkbox
                  label={data}
                  value={data}
                  name={"cancellationReason"}
                  handleChange={(e) => {
                    setCancellationReasons(e.target.value);
                  }}
                />
              </div>
            );
          })}
          {cancellationReasons === "Other Reason..." && (
            <div className="flex items-center justify-center mt-2">
              <textarea
                name="otherReasonDescription"
                value={otherReason}
                rows={4}
                cols={50}
                placeholder="Write your reason here....."
                onChange={(e) => {
                  setOtherReason(e.target.value);
                }}
                className="w-full p-3 bg-white-100 focus-visible:border-0 placeholder:text-center"
              />
            </div>
          )}
          <div className="flex items-center justify-center mt-7 px-[78px]">
            <Button
              id="dsajkk=akk=-jka"
              bgColor={`${cancellationReasons === "" ? "bg-grey-350" : "bg-primary"}`}
              fontStyle={{
                fontFamily: "",
                fontSize: "text-lg",
                fontWeight: "font-bold",
                fontColor: "text-white",
              }}
              borderStyle={{
                borderWidth: "border-none",
                borderRadius: "rounded-[100px]",
              }}
              disabled={cancellationReasons === ""}
              className={"py-3 px-28"}
              onClickHandler={() =>
                cancellationReasons !== "" && cancellationReasons === "Other Reason..."
                  ? otherReason !== "" && handleNavigate()
                  : handleNavigate()
              }
            >
              Submit
            </Button>
          </div>
        </div>
      </Modal>
    </div>
  );
};

export default NavbarAndStepperSection;
