/**
 * @description Component to create OTP box
 * @param {string} inputType - What is the input type of the OTP box
 * @param {function} handleOnFocus -  Method to handle onFocus event
 * @param {function} handleOnChange -  Method to handle onChange event
 * @param {boolean } disabled - Is the input disabled
 * @param {function} handleOnFocus -  Method to handle onFocus event
 * @param {string} otpBoxClassName - Styings to be applied to individual otp box
 * @param {function} handleOnKeyDown -  Method to handle onKeyDown event
 * @param {function} handleOnPaste -  Method to handle onPaste event
 * @param {boolean} error - Styings to be applied when there is error
 * @param {string} id - ID passed to the input element.
 * @param {boolean} focus - Is the current input on focus or not
 * @returns {React.FunctionComponent<ISingleOtpBox.IProps>} - Returns JSX for a single OTP box
 */
import clsx from "clsx";
import React, { useEffect, useRef } from "react";

import { ISingleOtpBox } from "./OtpBox";

const SingleOtpBox: React.FunctionComponent<ISingleOtpBox.IProps> = ({
  inputType,
  value,
  otpBoxClassName,
  disabled = false,
  focus,
  id,
  handleOnFocus,
  handleOnChange,
  handleOnKeyDown,
  handleOnPaste,
  error,
}) => {
  const inputRef = useRef<HTMLInputElement | null>(null);

  const { current: inputEl } = inputRef;

  useEffect(() => {
    if (focus) {
      if (inputEl) {
        inputEl.focus();
        inputEl?.select();
      }
    }
  }, [focus]);

  return (
    <input
      type={inputType}
      value={value ? value : ""}
      className={clsx(
        "w-[52px] mr-2 sm:mr-6 sm:w-16 h-16 last:mr-0 text-center text-xl rounded-lg sm:rounded border border-[#ccc]",
        otpBoxClassName,
        error ? "!border-error border" : value ? "!border-success border" : "",
        disabled && "cursor-not-allowed",
      )}
      disabled={disabled}
      autoComplete="off"
      maxLength={1}
      ref={inputRef}
      id={id}
      onFocus={handleOnFocus}
      onChange={handleOnChange}
      onKeyDown={handleOnKeyDown}
      onPaste={handleOnPaste}
    />
  );
};

export default SingleOtpBox;
