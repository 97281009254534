import clsx from "clsx";
import { FunctionComponent } from "react";

export const AnimatedSwipeLeftButton: FunctionComponent<{
  text: string;
  onClick?: () => void;
  disabled?: boolean;
  isLoading: boolean;
  style?: string;
}> = ({ text, onClick, disabled, isLoading }) => {
  return (
    <button
      id="login-btn"
      onClick={onClick}
      className={clsx(
        disabled ? "cursor-not-allowed" : "cursor-pointer",
        `w-[300px] relative hover:shadow-success inline-flex items-center justify-center p-4 px-6 py-4 overflow-hidden font-medium transition duration-300 ease-out border-2 rounded-full shadow-md text-success border-success group`,
      )}
      disabled={disabled}
    >
      <span className="absolute inset-0 flex items-center justify-center w-full h-full text-white duration-300 -translate-x-full bg-success group-hover:translate-x-0 ease">
        <svg
          className="w-6 h-6"
          fill="none"
          stroke="currentColor"
          viewBox="0 0 24 24"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            strokeLinecap="round"
            strokeLinejoin="round"
            strokeWidth="2"
            d="M14 5l7 7m0 0l-7 7m7-7H3"
          ></path>
        </svg>
      </span>
      <span className="absolute flex items-center justify-center w-full h-full transition-all duration-300 transform text-success group-hover:translate-x-full ease">
        {!isLoading && text}
      </span>
      <span className="relative invisible">{text}</span>
    </button>
  );
};
