/* eslint-disable */
import React, { useEffect, useState } from "react";
import { Checkbox, Typography } from "@mui/material";
import BasicDetails from "./basicDetails";
import {
  USERDETAILS_DATA,
  additionalDocumentsList,
  additionalIsTcsDocumentsList,
} from "../../utils";
import AddressDetails from "./addressDetails";
import OtherDetails from "./otherDetails";
import { getRemitterDetails, getUserDetails } from "../../services/kyc";
import Modal from "../../components/ui/Modal";
import Radio from "../../components/ui/Radio";
import Button from "../../components/ui/Button";
import ErrorField from "../../components/ui/ErrorField";
import { useLocation, useNavigate } from "react-router-dom";
import { useRecoilState, useRecoilValue } from "recoil";
import { confirmedPurpose, transactionDetails, userDetails } from "../../store";
import mixpanel from "mixpanel-browser";

const UserDetails = () => {
  const locationData = useLocation();
  const [name, setName] = useState<string>("");
  const [dob, setDob] = useState<string>("");
  const [address, setAddress] = useState<string>("");
  const [pinCode, setPinCode] = useState<string>("");
  const [city, setCity] = useState<string>("");
  const [state, setState] = useState<string>("");
  const [currentState, setCurrentState] = useState<string>("");
  const [currentAddress, setCurrentAddress] = useState<string>("");
  const [currentCity, setCurrentCity] = useState<string>("");
  const [currentPinCode, setCurrentPinCode] = useState<string>("");
  const [isSameAddress, setIsSameAddress] = useState();
  const [modalVisible, setModalVisible] = useState<boolean>(true);
  const [isPolitical, setIsPolitical] = useState<boolean>(true);
  const [enableBtn, setEnableBtn] = useState<boolean>(true);
  const [citizen, setCitizenship] = useState<string>("");
  const [sourceOfFund, setSourceOfFund] = useState<string>("");
  const [employmentStatus, setEmploymentStatus] = useState<string>("");
  const [industryType, setIndustryType] = useState<string>("");
  const [gender, setGender] = useState<string>("");
  const [error, setError] = useState<string>("");
  const [loading, setLoading] = useState(false);
  const transactionDetailsData = useRecoilValue(transactionDetails);

  const nationalityList = USERDETAILS_DATA.nationality;
  const industryTypeList = USERDETAILS_DATA.industryType;
  const statesList = USERDETAILS_DATA.states;
  const sourceOfFundList = USERDETAILS_DATA.fundSource;
  const employmentStatusList = USERDETAILS_DATA.employmentStatus;
  const genderList = USERDETAILS_DATA.gender;

  const [userData, setUserData] = useRecoilState(userDetails);
  const [additionalDocData, setAdditionalDocData] = React.useState(
    transactionDetailsData?.tcs === 0 ? additionalDocumentsList : additionalIsTcsDocumentsList,
  );
  const [selectedPurpose, setSelectedPurpose] = useRecoilState(confirmedPurpose);

  const listOfPurposeforAdditionalDocuments = additionalDocData.map((data) => data.name);

  const navigate = useNavigate();
  console.log("state data", locationData);

  useEffect(() => {
    getRemitterDetails()
      .then((res: any) => {
        const fullName = `${res?.data?.firstName && res?.data?.firstName} ${
          res?.data?.middleName && res?.data?.middleName
        } ${res?.data?.lastName && res?.data?.lastName}`;
        const fullAddress = `${res?.data?.addressLine1 && res?.data?.addressLine1} ${
          res?.data?.addressLine2 && res?.data?.addressLine2
        }`;
        setName(fullName);
        setGender(res?.data?.gender);
        setDob(res?.data?.dob);
        setAddress(fullAddress);
        setPinCode(res?.data?.pinCode);
        setCity(res?.data?.city);
        setState(res?.data?.state);
      })
      .catch((err: any) => {
        setError(err?.response?.data?.error?.Type);
      });
  }, []);

  useEffect(() => {
    address && pinCode && city && citizen && sourceOfFund && industryType && employmentStatus
      ? // (isSameAddress || (currentAddress && currentPinCode && currentCity && currentState)) &&
        // isPolitical === "no"
        setEnableBtn(true)
      : setEnableBtn(false);
  }, [
    // isPolitical,
    name,
    dob,
    address,
    pinCode,
    city,
    citizen,
    sourceOfFund,
    employmentStatus,
    industryType,
    currentAddress,
    currentPinCode,
    currentCity,
    // isSameAddress,
    currentState,
  ]);

  const handleSave = () => {
    setLoading(true);
    if (gender === undefined) {
      setError("Please Select Gender");
    } else {
      const body = {
        firstName: name,
        middleName: "",
        lastName: "",
        gender: gender,
        phoneNumber: "",
        nationality: citizen,
        addressLine1: address,
        addressLine2: "",
        state: state,
        city: city,
        pinCode: pinCode,
        dob: dob,
        isPhoneVerified: true,
        hop_coins: 0,
        isPolitical: isPolitical,
        employmentStatus: employmentStatus,
        industryType: industryType,
        sourceOfFunds: sourceOfFund,
        isDocVerified: true,
        comAddressLine1: currentAddress,
        comAddressLine2: "",
        comState: currentState,
        comCity: currentCity,
        comPinCode: currentPinCode,
        createdOn: "",
        modifiedOn: "",
        isMasterVendor: false,
      };
      mixpanel.track("Remitter_Details_Save");
      getUserDetails(body)
        .then((response) => {
          setError("");
          setUserData(response?.data);
          // listOfPurposeforAdditionalDocuments?.includes(selectedPurpose?.value)
          //   ? navigate("/user-documents")
          //   : navigate("/pick-recipient");
          navigate("/pick-recipient");
        })
        .catch((error) => {
          setError(error?.response?.data?.error?.type);
        })
        .finally(() => {
          setLoading(false);
        });
    }
  };
  const mixpanelToken: any = process.env.REACT_APP_MIXPANEL_TOKEN;
  mixpanel.init(mixpanelToken, {
    debug: true,
    api_host: process.env.REACT_APP_MIXPANEL_API,
  });

  const onSelectCitizenship = (e: any) => {
    setCitizenship(e);
  };

  const onSelectSourceOfFunds = (e: any) => {
    setSourceOfFund(e);
    mixpanel.track("Remitter_Source_of_funds", { e });
  };

  const onSelectEmploymentStatus = (e: any) => {
    setEmploymentStatus(e);
    mixpanel.track("Remitter_Employment_Status", { e });
  };

  const onSelectIndustryType = (e: any) => {
    setIndustryType(e);
    mixpanel.track("Remitter_Industry_Type", { e });
  };
  const onSelectCurrentState = (e: any) => {
    setCurrentState(e);
  };
  const onSelectState = (e: any) => {
    setState(e);
  };
  const onSelectGender = (e: any) => {
    console.log("e", e);
    setGender(e);
  };
  return (
    <div className="flex relative w-full h-full justify-center items-center">
      <div className="flex absolute z-50 w-full h-full justify-center items-start p-6">
        <div className="overflow-y-auto flex w-full flex-col justify-start min-h-full max-h-[calc(100vh-24px)]">
          <div className="w-full bg-white flex justify-center items-center">
            <h3 className="text-black w-full text-center p-3 text-xl font-semibold">
              Remitter Details
            </h3>
          </div>
          <div className="px-6">
            <BasicDetails
              DOB={dob}
              name={name}
              genderList={genderList}
              nationalityList={nationalityList}
              onSelectCitizenship={onSelectCitizenship}
              onSelectGender={onSelectGender}
              gender={gender}
            />
            <AddressDetails
              address={address}
              pinCode={pinCode}
              city={city}
              stateList={statesList}
              state={state}
              onSelectState={onSelectState}
              setAddress={setAddress}
              setPinCode={setPinCode}
              setCity={setCity}
            />
            <OtherDetails
              sourceOfFunds={sourceOfFundList}
              industryType={industryTypeList}
              employmentStatus={employmentStatusList}
              onSelectSourceOfFunds={onSelectSourceOfFunds}
              onSelectEmploymentStatus={onSelectEmploymentStatus}
              onSelectIndustryType={onSelectIndustryType}
            />
            <div
              id="last"
              className="flex xs:flex-col md:flex-row md:justify-around items-center mt-10 md:mt-32 mb-5"
            >
              <div className="flex xs:mb-5 mb-0 xl:justify-start xl:ml-[3/6] xs:justify-center items-center">
                <div className="md:mb-0">
                  <h1 className="font-semibold md:text-[16px] text-[14px]">
                    I am not a politically exposed person.
                  </h1>
                </div>

                <Checkbox checked={isPolitical} onChange={() => setIsPolitical(!isPolitical)} />
              </div>
              <Button
                disabled={!enableBtn}
                bgColor={enableBtn ? "bg-primary" : "bg-grey-550"}
                fontStyle={{
                  fontFamily: "font-ssp",
                  fontSize: "text-lg",
                  fontWeight: "font-bold",
                  fontColor: "text-white",
                }}
                borderStyle={{
                  borderRadius: "rounded-[100px]",
                }}
                className="w-[307px] h-[50px] xs:mt-5 md:mt-0"
                onClickHandler={handleSave}
              >
                {loading ? (
                  <div className="flex items-center justify-center animate-spin-slow py-1">
                    <img src="/images/svg/smallLoader.svg" alt="" />
                  </div>
                ) : (
                  "Save"
                )}
              </Button>
            </div>
            {error && <ErrorField errorClassname="flex justify-center" errorMessage={error} />}
          </div>
        </div>
      </div>
    </div>
  );
};

export default UserDetails;
