import React from "react";
import { IIcons } from "./icons";

const ForgotModalBanner = ({ fillColor }: IIcons.IconProps) => {
  return (
    <svg
      width="385"
      height="350"
      viewBox="0 0 385 350"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      className={fillColor}
    >
      <ellipse cx="233.279" cy="319" rx="151" ry="31" fill="#C4D8FF" />
      <path
        d="M181.159 108.112L167.268 125.603L173.525 97.8434L279.323 61L181.159 108.112Z"
        fill="white"
      />
      <path
        opacity="0.13"
        d="M173.503 98.0585L235.721 81.7852L180.768 108.603L167.412 125.314L173.503 98.0585Z"
        fill="#6D6E6F"
      />
      <path d="M167.268 125.604L180.805 108.35L279.05 61.2383L167.268 125.604Z" fill="#3B455A" />
      <path d="M279.051 61.2379L279.323 61L278.053 61.59L279.051 61.2379Z" fill="#707F78" />
      <path
        d="M279.051 61.2383L278.052 61.5904L180.858 108.231L180.748 108.269L201.938 138.008L279.051 61.2383Z"
        fill="#0F5BF1"
      />
      <path
        d="M279.324 61L155.293 72.5341L173.373 97.91L278.053 61.59L279.324 61Z"
        fill="#0F5BF1"
      />
      <path d="M334.678 215.191L185.832 226.354L263.752 266.995L334.678 215.191Z" fill="#0F5BF1" />
      <path
        d="M181.165 229.762L187.079 308.036C187.795 317.538 196.112 324.661 205.656 323.947L327.909 314.778C338.461 313.988 346.329 304.239 345.479 292.999L339.799 217.861L264.201 273.072L181.16 229.757L181.165 229.762Z"
        fill="#0F5BF1"
      />
      <path
        d="M307.97 217.195C307.874 218.47 307.87 219.764 307.97 221.077C308.276 225.122 309.513 228.881 311.457 232.15L334.677 215.191L307.97 217.195Z"
        fill="#292949"
      />
      <path
        d="M335.476 244.635C337.654 244.474 339.751 244.041 341.734 243.379L339.804 217.865L314.539 236.313C319.669 241.947 327.265 245.249 335.471 244.635H335.476Z"
        fill="#292949"
      />
      <path
        d="M357.763 233.591C367.781 223.613 367.781 207.434 357.763 197.455C347.745 187.477 331.504 187.477 321.486 197.455C311.469 207.434 311.469 223.613 321.486 233.591C331.504 243.57 347.745 243.57 357.763 233.591Z"
        fill="#0F5BF1"
      />
      <path
        d="M344.967 229.491L338.958 229.848L337.783 210.153L334.028 210.377L333.67 204.391L343.429 203.811L344.967 229.491Z"
        fill="white"
      />
      <path
        d="M158.534 294.97C158.085 294.728 157.106 294.718 155.701 294.871"
        stroke="#497CE2"
        strokeWidth="3"
        strokeMiterlimit="10"
        strokeLinecap="round"
      />
      <path
        d="M150.098 295.769C128.664 299.723 76.4244 311.552 94.629 257.555C116.583 192.438 170.734 242.976 78.0438 260.957C-12.5203 278.525 77.3798 160.4 152.882 128.115"
        stroke="#497CE2"
        strokeWidth="3"
        strokeMiterlimit="10"
        strokeLinecap="round"
      />
      <path
        d="M155.5 127.022C156.393 126.665 157.282 126.317 158.165 125.984"
        stroke="#497CE2"
        strokeWidth="3"
        strokeMiterlimit="10"
        strokeLinecap="round"
      />
      <circle cx="18" cy="159" r="18" fill="#C4D8FF" />
      <circle r="10.5" transform="matrix(-1 0 0 1 369.5 27.5)" fill="#C4D8FF" />
      <circle cx="24" cy="121" r="8" fill="#C4D8FF" />
      <ellipse rx="5" ry="4.5" transform="matrix(-1 0 0 1 366 4.5)" fill="#C4D8FF" />
    </svg>
  );
};

export default ForgotModalBanner;
