import React from "react";
import clsx from "clsx";

import { ITypography } from "./Typography";

const Typograpy: React.FC<ITypography.IProps> = ({
  children,
  className,
  fontStyle,
  tagType,
  text,
}) => {
  // eslint-disable-next-line no-undef
  const CustomTag = tagType as keyof JSX.IntrinsicElements;

  return (
    <CustomTag
      className={clsx(
        fontStyle?.fontColor,
        fontStyle?.fontFamily,
        fontStyle?.fontSize,
        fontStyle?.fontWeight,
        className,
      )}
    >
      {children ? children : text}
    </CustomTag>
  );
};

export default Typograpy;
