import React from "react";
import { IIcons } from "./icons";

const EmailOtpIcon = ({ fillColor, width, height }: IIcons.IconProps) => (
  <svg
    width={width || "21"}
    height={height || "20"}
    viewBox="0 0 21 20"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M10.2285 0.496094L1.2207 5.75195C0.607378 6.10956 0.228516 6.76859 0.228516 7.47852V17.5039C0.228516 18.597 1.13545 19.5039 2.22852 19.5039H18.2285C19.3216 19.5039 20.2285 18.597 20.2285 17.5039V7.47852C20.2285 6.76859 19.8497 6.10956 19.2363 5.75195L10.2285 0.496094ZM10.2285 2.8125L18.2285 7.47852V7.52734L10.2285 12.5039L2.22852 7.52734V7.47852L10.2285 2.8125ZM10.2285 4.50391L7.22852 7.50391H9.22852V9.88281L10.2285 10.5039L11.2285 9.88281V7.50391H13.2285L10.2285 4.50391Z"
      fill="#354C7E"
      className={fillColor}
    />
  </svg>
);
export default EmailOtpIcon;
