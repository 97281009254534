import React from "react";
import { useLottie } from "lottie-react";
import * as remitLoader from "../../../assets/lottieAnimationsData/data.json";

export const RemitLoader = (size?: any) => {
  const style = {
    height: size ? size : 163,
    width: size ? size : 163,
  };

  const options = {
    loop: true,
    autoplay: true,
    animationData: remitLoader,
    rendererSettings: {
      preserveAspectRatio: "xMidYMid slice",
    },
  };

  const { View } = useLottie(options, style);

  return <div className="w-full">{View}</div>;
};
