import React from "react";
import { useLottie } from "lottie-react";
import * as circleLoader from "../../../assets/lottieAnimationsData/loginAnimation.json";

export const LoginAnimation = () => {
  const options = {
    animationData: circleLoader,
    loop: true,
  };

  const { View } = useLottie(options);

  return <div className="w-full">{View}</div>;
};
