import React from "react";

const SignUpSuccessFullLoader = () => {
  return (
    <div className="flex flex-col items-center justify-center h-screen bg-primary-100">
      <div className="w-[163px] h-[163px] rounded-full bg-primary-1000 mb-[88px]" />
      <div className="font-bold text-[32px] text-primary-1050">Signup Successfull</div>
      <div className="text-2xl font-bold my-7 text-primary-1100">Just there! please wait</div>
      <div className="text-lg sm:text-2xl italic font-normal text-center w-[54%] sm:w-1/6 text-primary-1150">
        We are creating your profile, just hold back and relax!
      </div>
    </div>
  );
};

export default SignUpSuccessFullLoader;
