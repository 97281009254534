import React from "react";
import { IIcons } from "./icons";

const Three = ({ fillColor }: IIcons.IconProps) => (
  <svg
    width="86"
    height="87"
    viewBox="0 0 86 87"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    className={fillColor}
  >
    <path
      d="M64.6041 64.6966C76.4131 52.8045 76.4131 33.5236 64.6041 21.6315C52.7952 9.73941 33.649 9.73941 21.8401 21.6315C10.0311 33.5236 10.0311 52.8045 21.8401 64.6966C33.649 76.5887 52.7952 76.5887 64.6041 64.6966Z"
      fill="#FFC32F"
    />
    <path
      d="M59.0401 17.1833C62.0519 21.9168 63.7972 27.547 63.7972 33.5844C63.7972 50.4 50.26 64.036 33.5585 64.036C27.7583 64.036 22.3413 62.3891 17.7402 59.5383C23.1147 67.9872 32.5191 73.5852 43.2218 73.5852C59.9198 73.5852 73.4606 59.9527 73.4606 43.1336C73.4606 32.1554 67.6924 22.5384 59.0436 17.1797L59.0401 17.1833Z"
      fill="#FFAB06"
    />
    <path
      d="M17.0944 46.3814C17.0944 30.7662 29.6631 18.1089 45.1692 18.1089C54.233 18.1089 62.2893 22.4352 67.4226 29.1478C62.5731 20.674 53.488 14.9688 43.0797 14.9688C27.5736 14.9688 15.0049 27.626 15.0049 43.2412C15.0049 49.7288 17.1759 55.7055 20.8263 60.4747C18.453 56.3271 17.0944 51.515 17.0944 46.3849V46.3814Z"
      fill="#FFD77F"
    />
    <path
      d="M32.0833 41.2852C33.3137 41.2852 34.3111 39.5914 34.3111 37.502C34.3111 35.4126 33.3137 33.7188 32.0833 33.7188C30.8529 33.7188 29.8555 35.4126 29.8555 37.502C29.8555 39.5914 30.8529 41.2852 32.0833 41.2852Z"
      fill="#303030"
    />
    <path
      d="M56.5894 37.502C56.5894 39.5919 55.5926 41.2852 54.3616 41.2852C53.1306 41.2852 52.1338 39.5919 52.1338 37.502C52.1338 35.4121 53.1306 33.7188 54.3616 33.7188C55.5926 33.7188 56.5894 35.4121 56.5894 37.502Z"
      fill="#303030"
    />
    <path
      d="M31.5981 25.4276L29.5713 27.4688L36.3366 34.2817L38.3634 32.2406L31.5981 25.4276Z"
      fill="#303030"
    />
    <path
      d="M56.8735 27.463L54.8467 25.4219L48.0814 32.2348L50.1082 34.2759L56.8735 27.463Z"
      fill="#303030"
    />
    <path
      d="M54.3612 57.1039C53.7156 50.3805 48.9584 45.1612 43.1973 45.1719C37.4326 45.1826 32.7003 50.4234 32.083 57.1503L54.3612 57.1003V57.1039Z"
      fill="#695342"
    />
    <path
      d="M32.083 57.1569C32.083 57.1569 41.4626 47.6791 54.3612 57.1069L32.083 57.1569Z"
      fill="#F06669"
    />
  </svg>
);
export default Three;
