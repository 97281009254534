import React from "react";
import { IIcons } from "./icons";

const DivisionIcon = ({ fillColor }: IIcons.IconProps) => (
  <svg width="13" height="11" viewBox="0 0 13 11" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      d="M6.5 0C6.21336 0 5.93847 0.115892 5.73578 0.322182C5.5331 0.528473 5.41923 0.808262 5.41923 1.1C5.41923 1.39174 5.5331 1.67153 5.73578 1.87782C5.93847 2.08411 6.21336 2.2 6.5 2.2C6.78664 2.2 7.06154 2.08411 7.26422 1.87782C7.4669 1.67153 7.58077 1.39174 7.58077 1.1C7.58077 0.808262 7.4669 0.528473 7.26422 0.322182C7.06154 0.115892 6.78664 0 6.5 0ZM1.09616 4.4C0.952946 4.39794 0.810757 4.42487 0.677857 4.47922C0.544957 4.53358 0.423996 4.61427 0.322005 4.71662C0.220013 4.81897 0.139024 4.94093 0.0837454 5.07542C0.0284666 5.2099 0 5.35422 0 5.5C0 5.64578 0.0284666 5.7901 0.0837454 5.92458C0.139024 6.05907 0.220013 6.18103 0.322005 6.28338C0.423996 6.38573 0.544957 6.46642 0.677857 6.52078C0.810757 6.57513 0.952946 6.60206 1.09616 6.6H11.9038C12.0471 6.60206 12.1892 6.57513 12.3221 6.52078C12.455 6.46642 12.576 6.38573 12.678 6.28338C12.78 6.18103 12.861 6.05907 12.9163 5.92458C12.9715 5.7901 13 5.64578 13 5.5C13 5.35422 12.9715 5.2099 12.9163 5.07542C12.861 4.94093 12.78 4.81897 12.678 4.71662C12.576 4.61427 12.455 4.53358 12.3221 4.47922C12.1892 4.42487 12.0471 4.39794 11.9038 4.4H1.09616ZM6.5 8.8C6.21336 8.8 5.93847 8.91589 5.73578 9.12218C5.5331 9.32847 5.41923 9.60826 5.41923 9.9C5.41923 10.1917 5.5331 10.4715 5.73578 10.6778C5.93847 10.8841 6.21336 11 6.5 11C6.78664 11 7.06154 10.8841 7.26422 10.6778C7.4669 10.4715 7.58077 10.1917 7.58077 9.9C7.58077 9.60826 7.4669 9.32847 7.26422 9.12218C7.06154 8.91589 6.78664 8.8 6.5 8.8Z"
      fill={fillColor || "#808080"}
    />
  </svg>
);
export default DivisionIcon;
