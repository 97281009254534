import React, { useEffect } from "react";
import clsx from "clsx";
//#end Global Imports

//#Local Imports
import Modal from "../../../../components/ui/Modal";
// import Button from "../../../../components/ui/Button";
import DocumentsListSection from "./DocumentsListSection";
import PanCardSection from "./PanCardSection";
import DrivingLicenseSection from "./DrivingLicenseSection";
import IndianPassportSection from "./IndianPassportSection";
import VoterIdCardSection from "./VoterIdCardSection";
import AadharCardSection from "./AadharCardSection";
import IUserDocuments from "../../userDocuments";
import { CloseIcon } from "../../../../assets/Icons";
// import CKYCSection from "./CKYCSection";
import mixpanel from "mixpanel-browser";
//#end Local Imports

const UploadDocumentsModal: React.FC<IUserDocuments.IUploadDocumentsModalProps> = ({
  isModalOpen,
  KycDetailsData,
  setIsModalOpen,
  docType,
  numberOfVerifiedDoc,
  panCardDetails,
  setDocType,
  verifiedDocumentsData,
  getColor,
  handlePreviewUploadedDoc,
  handleDeleteDoc,
  handleChangeUpload,
  handleEVerifyPanCard,
  adharCardDetails,
  setDrivingLicenseDetails,
  sendAdharOtp,
  entityId,
  dateKey,
  date,
  saveAdharOTPValue,
  adharOTPError,
  drivingLicenseDetails,
  handleEverifyDL,
  passportDetails,
  handlePassportBackVerification,
  handlePassportVerification,
  voterIDCardDetails,
  setVoterIDCardDetails,
  handleEVerifyVoterCard,
  isPDFfile,
  setPanCardDetails,
  setIsMobileDocumentListOpen,
  docDob,
  setPassportDetails,
  handlePassportDobChangeHandler,
  handleDlDobChangeHandler,
  // ckycPhase,
  // validateCkycOtpHandler,
  // verifyCKYCHandler,
  // downloadCkycHandler,
  // sendCkycOtpHandler,
  // saveCkycAdharOTPValue,
  // ckycMessage,
  ckycStatus,
  // ckycData,
  aadhaarLinked,
  handleNoAadhaar,
  buttonLoader,
  loading
}) => {
  const mixpanelToken: any = process.env.REACT_APP_MIXPANEL_TOKEN;
  mixpanel.init(mixpanelToken, {
    debug: true,
    api_host:process.env.REACT_APP_MIXPANEL_API,
  });

  useEffect(() => {
    if (KycDetailsData && !KycDetailsData?.isDocVerified) {
      if (numberOfVerifiedDoc === 1) {
        setDocType("AADHAR_CARD");
      } else if (numberOfVerifiedDoc === 2) {
        setIsModalOpen("KYCVerified");
        setIsMobileDocumentListOpen(false);
      } else {
        setDocType("PAN_CARD");
      }
    }
  }, [numberOfVerifiedDoc, KycDetailsData]);
  return (
    <Modal
      modalClass="!pt-4 md:pr-11 !rounded xl:overflow-hidden !overflow-y-auto lg:max-h-[90%] !w-full lg:!w-[80vw] mb-15 md:mb-0"
      headerClass="mt-2 bg-red"
      visible={isModalOpen === "2"}
      closeHandler={() => setIsModalOpen("")}
      closeButtonIcon={<CloseIcon fill="fill-grey-1200" />}
    >
      <div
        className={clsx(
          docType === "" ? "flex" : "hidden lg:flex",
          "text-lg font-bold justify-center text-black-200 lg:mb-[62px]",
        )}
      >
        Upload document for verification
      </div>
      <div className="flex flex-col items-start mb-4 -lg:mt-6 lg:w-[70vw]">
        <div className="grid w-full grid-1 lg:grid-cols-6 lg:divide-black lg:divide-x-[1px] lg:divide-opacity-[0.15]">
          <div
            className={clsx(
              docType === ""
                ? "col-span-2 pt-7"
                : "hidden lg:block lg:col-span-2 lg:mr-10 lg:pt-7 overflow-auto",
            )}
          >
            <DocumentsListSection
              docType={docType}
              panCardDetails={panCardDetails}
              setDocType={setDocType}
              verifiedDocumentsData={verifiedDocumentsData}
              getColor={getColor}
              setIsModalOpen={setIsModalOpen}
              ckycStatus={ckycStatus}
              adharCardDetails={adharCardDetails}
              drivingLicenseDetails={drivingLicenseDetails}
              passportDetails={passportDetails}
              voterIDCardDetails={voterIDCardDetails}
            />
          </div>
          {docType === "PAN_CARD" && (
            <PanCardSection
              panCardDetails={panCardDetails}
              handlePreviewUploadedDoc={handlePreviewUploadedDoc}
              handleDeleteDoc={handleDeleteDoc}
              handleChangeUpload={handleChangeUpload}
              handleEVerifyPanCard={handleEVerifyPanCard}
              isPDFfile={isPDFfile}
              setPanCardDetails={setPanCardDetails}
              aadhaarLinked={aadhaarLinked}
              loading={loading}
              buttonLoader={buttonLoader}
            />
          )}

          {/* {docType === "CKYC" && (
            <div className="w-96" onClick={() => mixpanel.track("CKYC verify clicked")}>
              {/* <CKYCSection
                ckycPhase={ckycPhase}
                validateCkycOtpHandler={validateCkycOtpHandler}
                verifyCKYCHandler={verifyCKYCHandler}
                downloadCkycHandler={downloadCkycHandler}
                sendCkycOtpHandler={sendCkycOtpHandler}
                saveCkycAdharOTPValue={saveCkycAdharOTPValue}
                ckycMessage={ckycMessage}
                setDocType={setDocType}
                ckycStatus={ckycStatus}
                ckycData={ckycData}
                date={date}
                dateKey={dateKey}
              /> */}
            {/* </div> */}
          {/* )} */}
          {docType === "AADHAR_CARD" && (
            <AadharCardSection
              adharCardDetails={adharCardDetails}
              handlePreviewUploadedDoc={handlePreviewUploadedDoc}
              handleDeleteDoc={handleDeleteDoc}
              handleChangeUpload={handleChangeUpload}
              sendAdharOtp={sendAdharOtp}
              entityId={entityId}
              dateKey={dateKey}
              date={date}
              saveAdharOTPValue={saveAdharOTPValue}
              adharOTPError={adharOTPError}
              isPDFfile={isPDFfile}
              handleNoAadhaar={handleNoAadhaar}
              loading={loading}
              buttonLoader={buttonLoader}
            />
          )}
          {docType === "DRIVING_LICENCE" && (
            <DrivingLicenseSection
              drivingLicenseDetails={drivingLicenseDetails}
              handlePreviewUploadedDoc={handlePreviewUploadedDoc}
              handleDeleteDoc={handleDeleteDoc}
              handleChangeUpload={handleChangeUpload}
              setDrivingLicenseDetails={setDrivingLicenseDetails}
              handleEverifyDL={handleEverifyDL}
              isPDFfile={isPDFfile}
              docDob={docDob}
              handleDlDobChangeHandler={handleDlDobChangeHandler}
              loading={loading}
              buttonLoader={buttonLoader}
            />
          )}
          {docType === "INDIAN_PASSPORT" && (
            <IndianPassportSection
              passportDetails={passportDetails}
              handlePreviewUploadedDoc={handlePreviewUploadedDoc}
              handleDeleteDoc={handleDeleteDoc}
              handleChangeUpload={handleChangeUpload}
              handlePassportBackVerification={handlePassportBackVerification}
              handlePassportVerification={handlePassportVerification}
              isPDFfile={isPDFfile}
              docDob={docDob}
              setPassportDetails={setPassportDetails}
              handlePassportDobChangeHandler={handlePassportDobChangeHandler}
              loading={loading}
              buttonLoader={buttonLoader}
            />
          )}
          {docType === "VOTER_CARD" && (
            <VoterIdCardSection
              voterIDCardDetails={voterIDCardDetails}
              handlePreviewUploadedDoc={handlePreviewUploadedDoc}
              handleDeleteDoc={handleDeleteDoc}
              handleChangeUpload={handleChangeUpload}
              setVoterIDCardDetails={setVoterIDCardDetails}
              handleEVerifyVoterCard={handleEVerifyVoterCard}
              isPDFfile={isPDFfile}
              loading={loading}
              buttonLoader={buttonLoader}
            />
          )}
        </div>
      </div>
      {/* <div
        className={clsx(
          docType === "" ? "flex" : "hidden lg:flex",
          "items-center justify-end w-full mt-8 relative bottom-28",
        )}
      >
        <Button
          id="2sn9nkj87-4398ndsjs8"
          bgColor={numberOfVerifiedDoc > 1 ? "bg-primary" : "bg-grey-550"}
          fontStyle={{
            fontFamily: "",
            fontSize: "text-lg",
            fontWeight: "font-bold",
            fontColor: "text-white",
          }}
          borderStyle={{
            borderWidth: "border-none",
            borderRadius: "rounded-[75px]",
          }}
          className={"py-2 lg:py-3 w-6/12 lg:w-1/4 flex justify-center text-[13px] md:text-[18px]"}
          onClickHandler={() => (
            setIsModalOpen("KYCVerified"),
            setIsMobileDocumentListOpen(false),
            numberOfVerifiedDoc === 1 && mixpanel.track("Submit 1 of 2"),
            numberOfVerifiedDoc === 2 && mixpanel.track("Submit 2 of 2")
          )}
          disabled={numberOfVerifiedDoc !== 2}
        >
          {numberOfVerifiedDoc === 0
            ? "Submit 0 of 2"
            : numberOfVerifiedDoc === 2
            ? "Submit 2 of 2"
            : panCardDetails?.isPanUploaded && "Submit 1 of 2"}
        </Button>
      </div> */}
    </Modal>
  );
};

export default UploadDocumentsModal;
