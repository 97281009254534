/* eslint-disable */
import React from "react";
import { BrowserRouter as Router } from "react-router-dom";
import { RecoilRoot } from "recoil";
import { AnimatedRoutes } from "./components/layout/AnimatedRoutes";
import { CookiesProvider } from "react-cookie";

function App() {
  return (
    <CookiesProvider>
      <RecoilRoot>
        <Router>
          <AnimatedRoutes />
        </Router>
      </RecoilRoot>
    </CookiesProvider>
  );
}

export default App;
