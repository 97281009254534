import React from "react";
import { IIcons } from "./icons";

interface Props extends IIcons.IconProps {
  size?: number;
}

const Equal = ({ fillColor, size = 30 }: Props) => {
  return (
    <svg
      width={size}
      height={size}
      viewBox="0 0 30 30"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      className={`${fillColor} w-5`}
    >
      <circle cx="15" cy="15" r="15" fill="#727272" />
      <path
        d="M10 12.4375H20.5V14.5483H10V12.4375ZM10 16.0767H20.5V18.1875H10V16.0767Z"
        fill="white"
      />
    </svg>
  );
};

export default Equal;
