import React, { FC } from "react";
import { TextField, Typography } from "@mui/material";
import TextFiledWithDropdown from "../../components/ui/TextFieldWithDropdown";
import FloatingDropDown from "../../components/ui/FloatingDropDown";

const BasicDetails: FC<UserDetailsProps.BasicDetailsProps> = ({
  nationalityList,
  genderList,
  name,
  DOB,
  onSelectCitizenship,
  onSelectGender,
  gender,
}) => {
  return (
    <div className="mt-10 w-full md:flex justify-center">
      <div>
        <Typography className="text-primary">Basic Details</Typography>
        <div className="lg:grid xs:grid xs:grid-cols-1 xs:gap-x-24 xs:gap-y-5 lg:grid-cols-3 md:grid md:grid-cols-2 md:gap-x-24 md:gap-y-5 md:space-x-0 pace-y-5 lg:gap-x-24 lg:space-x-5 my-5">
          <TextFiledWithDropdown
            selectedValue={gender}
            handleDropdownChange={onSelectGender}
            value={name}
            options={genderList}
            disabled={true}
          />
          <TextField
            variant="outlined"
            id="outlined-basic"
            value={DOB}
            required
            label="DOB"
            className="px-5 md:w-96 w-full"
            disabled
          />
          <FloatingDropDown
            options={nationalityList}
            onSelect={onSelectCitizenship}
            placeHolder="Citizenship"
            required
            dropdownClassname="px-5 md:w-96 w-full"
            firstDivMargin="0"
          />
          <FloatingDropDown
            options={genderList}
            onSelect={onSelectGender}
            dropDownvalue={gender}
            placeHolder="Gender"
            required
            dropdownClassname="px-5 md:w-96 w-full"
          />
        </div>
      </div>
    </div>
  );
};

export default BasicDetails;
