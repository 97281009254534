import React, { useState } from "react";

type DropdownOption = {
  label: string;
  value: string;
};

type DropdownProps = {
  options: DropdownOption[];
  onSelect: (selectedValue: string) => void;
  outerBoxClass?: string;
  dropdownBoxClass?: string;
};

export const SimpleDropdown: React.FC<DropdownProps> = ({ options, onSelect }) => {
  const [selectedOption, setSelectedOption] = useState<DropdownOption | undefined>(undefined);

  const handleOptionSelect = (option: DropdownOption): void => {
    setSelectedOption(option);
    onSelect(option.value);
  };

  return (
    <div className="">
      <select
        className="w-full h-full border-t border-b border-l border-black rounded-l-lg"
        id="dropdown"
        name="dropdown"
        value={selectedOption?.value}
        onChange={(event) =>
          handleOptionSelect(
            options.find((option) => option.value === event.target.value) as DropdownOption,
          )
        }
      >
        {options?.map((option) => (
          <option key={option.value} value={option.value} className="">
            <span className="px-4">{option.label}</span>
          </option>
        ))}
      </select>
    </div>
  );
};
