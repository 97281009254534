import { Axios } from "../axios";

export const sendAppLink = (body: any) => {
  const currentUser = JSON.parse(sessionStorage.getItem("currentUser") || "{}");
  return Axios.post(`/remit/auth/sendAppLink`, body, {
    headers: {
      Authorization: `${currentUser.idToken}`,
    },
  });
};
