import mixpanel from "mixpanel-browser";
import React, { FunctionComponent } from "react";
import { EyeIcon, PassportIcon } from "../../../assets/Icons";

export const DocumentCard: FunctionComponent<{
  docName?: string;
  onClick?: () => void;
  date?: string;
}> = ({ docName, onClick, date }) => {
  const mixpanelToken: any = process.env.REACT_APP_MIXPANEL_TOKEN;
  mixpanel.init(mixpanelToken, {
    debug: true,
    api_host:process.env.REACT_APP_MIXPANEL_API,
  });
  return (
    <div>
      <div className="flex flex-wrap justify-between px-5 py-5 bg-gray-100 lg:px-7 rounded-xl">
        <div id="left" className="flex items-center space-x-5">
          <PassportIcon width="30px" />
          <span className="font-semibold font-ssp text-[12px] lg:text-[18px]">{docName}</span>
        </div>
        <div id="right" className="flex items-center space-x-4">
          <div className="items-center hidden space-x-2 lg:flex">
            <span className="font-ssp text-gray-500 text-[16px]">Uploaded Date:</span>
            <span className="font-ssp text-[16px]">{date}</span>
          </div>
          <div
            className="transition-all cursor-pointer hover:scale-150"
            onClick={() => {
              mixpanel.track(`View ${docName} clicked`);
              onClick && onClick();
            }}
          >
            <EyeIcon />
          </div>
        </div>
      </div>
    </div>
  );
};
