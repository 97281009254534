import * as React from "react";

import { IIcons } from "./icons";

const SvgSearchIcon = ({ width, height, fillColor }: IIcons.IconProps) => (
  <svg
    width={width || "20"}
    height={height || "20"}
    viewBox="0 0 20 20"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M7.97654 0C3.56672 0 0 3.48496 0 7.7937C0 12.1024 3.56672 15.5874 7.97654 15.5874C9.55095 15.5874 11.0081 15.1361 12.2434 14.3696L18.0059 20L20 18.0516L14.3109 12.5072C15.3336 11.1963 15.9531 9.57199 15.9531 7.7937C15.9531 3.48496 12.3864 0 7.97654 0ZM7.97654 1.83381C11.3545 1.83381 14.0762 4.49319 14.0762 7.7937C14.0762 11.0942 11.3545 13.7536 7.97654 13.7536C4.59861 13.7536 1.87683 11.0942 1.87683 7.7937C1.87683 4.49319 4.59861 1.83381 7.97654 1.83381Z"
      fill="#0F5BF1"
      className={fillColor}
    />
  </svg>
);

export default SvgSearchIcon;
