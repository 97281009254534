/*eslint-disable*/
import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import clsx from "clsx";
import { motion } from "framer-motion";
import mixpanel from "mixpanel-browser";
import { LeftArrowIcon, CloseIcon, RightArrowFilledIcon } from "../../../assets/Icons";
import Button from "../../../components/ui/Button";
import { sendRecipients, validateRecipient } from "../../../services/recipientDetailsPage";
import { StepOne } from "./StepOne";
import { StepTwo } from "./StepTwo";
import { Modals } from "./Modals";
import RecipientProps from "./recipientDetails";
import { sendInwardRecipients, validateAccount } from "../../../services/inward";

const InwardRecipientDetails = () => {
  const [step, setStep] = useState<number>(1);
  const [name, setName] = useState<string>("");
  const [firstName, setFirstName] = useState<string>("");
  const [lastName, setLastName] = useState<string>("");
  const [mobile, setMobile] = useState<string>("");
  const [isModalOpen, setIsModalOpen] = useState<string>("");
  const [relationship, setRelationship] = useState<string>("");
  const [category, setCategory] = useState<string>("");
  const [city, setCity] = useState<string>("");
  const [addressOne, setAddressOne] = useState<string>("");
  const [addressTwo, setAddressTwo] = useState<string>("");
  const [country, setCountry] = useState<string>("");
  const [pin, setPin] = useState<string>("");
  const [bankCountry, setBankCountry] = useState<string>("");
  const [bankName, setBankName] = useState<string>("");
  const [acNumber, setAcNumber] = useState<string>("");
  const [confirmAcNumber, setConfirmAcNumber] = React.useState("");
  const [isfcCode, setIsfcCode] = useState("");
  const [branchName, setBranchName] = useState("");
  const [swiftCode, setSwiftCode] = useState<string>("");
  const [IBAN, setIBAN] = useState<string>("");
  const [routingNumber, setRoutingNumber] = useState<string>("");
  const [transitCode, setTransitCode] = useState<string>("");
  const [accountType, setAccountType] = useState<string>("");
  const [bspCode, setBspCode] = useState<string>("");
  const [sortCode, setSortCode] = useState<string>("");
  const [refrence, setRefrence] = useState<string>("");
  const [bankAddress, setBankAddress] = useState("");
  const [unitNumber, setUnitNumber] = useState<string>("");
  const [errorMessage, setErrorMessage] = useState<string>("");
  const [countryData, setCountryData] = useState<any>([]);
  const [isCorrespondingAccount, setIsCorrespondingAccount] = useState(false);
  const [bankAccountNameCorresponding, setBankAccountNameCorresponding] = useState("");
  const [bankAccountNumberCorresponding, setBankAccountNumberCorresponding] = useState<string>("");
  const [refrenceCorresponding, setRefrenceCorresponding] = useState("");
  const navigate = useNavigate();
  const isRecipientSelected = sessionStorage.getItem("isRecipientSelected");
  const phoneNumber = sessionStorage.getItem("phoneNumber") || "";
  const mixpanelToken: any = process.env.REACT_APP_MIXPANEL_TOKEN;

  const [email, setEmail] = useState("");
  const [state, setState] = useState("");

  mixpanel.init(mixpanelToken, {
    debug: true,
    api_host:process.env.REACT_APP_MIXPANEL_API,
  });

  const handleFinalClick = () => {
    mixpanel.track("Submit _Step 2")
    const body = {
      // achCode: routingNumber,
      bankAccountCountry: "India",
      bankAccountNumber: acNumber,
      bankName: bankName,
      bankAddress: bankAddress,
      bsbCode: "",
      city: city,
      countryOfResidence: "India",
      email: "", //ask vikas why this is empty
      firstName: firstName,
      ibanCode: "",
      lastName: lastName,
      middleName: "",
      nickName: "",
      phoneNumber: mobile,
      pincode: pin,
      recipientAddress: addressOne + addressTwo,
      recipientLs: "",
      refrence: "",
      sortCode: "",
      swiftCode: "",
      transitCode: "",
      unitNumber: "",
      relationship: relationship,
      category: "",
      correspondingBankName: "",
      correspondingBankAccountNumber: "",
      ifscCode: isfcCode,
      state: state,
    };
    sendInwardRecipients(body)
      .then((res: any) => {
        window.console.log(res);
        navigate("/inward/pick-recipient");
        mixpanel.track("Recipient Details Submit Button 3", {
          "Bank Account Country": bankCountry,
          "Bank Name": bankName,
          "Bank Account Number": acNumber,
          "SWIFT/BIC Code": swiftCode,
          "IBAN Code": IBAN,
          "Unit Number": unitNumber,
          "Reference ID": refrence,
        });
      })
      .catch((error: any) => {
        setErrorMessage(error.response.data.error.type);
      });
  };

  const [cancellationReasons, setCancellationReasons] = useState<string>("");
  const [otherReason, setOtherReason] = useState<string>("");
  const [isVerified, setIsVerified] = React.useState(false);
  const [validationError, setValidationError] = useState("");

  const handleVerify = () => {
    setBankAddress("");
    setBankName("");
    setAcNumber("");
    setConfirmAcNumber("");
    setBankCountry("");

    if (swiftCode && IBAN) setValidationError("Please enter either Swift Code or IBAN");
    else if (swiftCode || IBAN) {
      const type = swiftCode ? "swift" : "iban";
      const number = swiftCode ? swiftCode : IBAN;

      validateRecipient(type, number)
        .then((res) => {
          setIsVerified(true);
          setValidationError("");
          if (type === "swift") {
            setBankAddress(res?.data?.address);
            setBankName(res?.data?.name);
            if (res?.data?.country_name === "AUSTRALIA") {
              setBankCountry("Australia");
            } else if (res?.data?.country_name === "CANADA") {
              setBankCountry("Canada");
            } else if (res?.data?.country_name === "UNITED KINGDOM") {
              setBankCountry("United Kingdom");
            } else if (res?.data?.country_name === "UNITED STATES OF AMERICA") {
              setBankCountry("United States");
            } else setBankCountry(res?.data?.country_name);
          } else if (type === "iban") {
            setBankAddress(res?.data?.address);
            setBankName(res?.data?.bank);
            setAcNumber(res?.data?.account);
            setConfirmAcNumber(res?.data?.account);
            if (res?.data?.country === "AUSTRALIA") {
              setBankCountry("Australia");
            } else if (res?.data?.country === "CANADA") {
              setBankCountry("Canada");
            } else if (res?.data?.country === "UNITED KINGDOM") {
              setBankCountry("United Kingdom");
            } else if (res?.data?.country === "UNITED STATES OF AMERICA") {
              setBankCountry("United States");
            } else setBankCountry(res?.data?.country);
            setSwiftCode(res?.data?.bic);
          }
        })
        .catch((error) => {
          setValidationError(error.response.data.error.type);
        });
    }
  };

  return (
    <motion.div
      className="h-screen"
      initial={{ width: 0 }}
      animate={{ width: "100%" }}
      exit={{ x: window.innerWidth, transition: { duration: 0.5 } }}
    >
      <div className="flex justify-between items-center bg-white md:hidden w-full py-[25px] pr-[22px] pl-7 mb-6">
        <div className="flex items-center w-fit" onClick={() => history.back()}>
          <LeftArrowIcon />
          <div className="ml-6 text-xl font-semibold text-grey-1100">Recipient Details</div>
        </div>
        <div className="flex items-center">
          <div
            className="mr-3 text-sm font-normal text-primary"
            onClick={() => navigate("/support")}
          >
            Need help ?
          </div>
          <div
            className="bg-gray-400 w-[30px] h-[30px] flex justify-center items-center rounded-full"
            onClick={() => setIsModalOpen("cancelReasons")}
          >
            <CloseIcon />
          </div>
        </div>
      </div>
      {/* <div className={clsx("hidden md:flex justify-between items-center ml-10 mr-[69px]")}>
        <Button
          id="go-back-btn-receipient"
          bgColor="bg-primary-450"
          fontStyle={{
            fontFamily: "",
            fontSize: "text-sm",
            fontWeight: "font-medium",
            fontColor: "text-primary",
          }}
          borderStyle={{
            borderRadius: "rounded",
          }}
          className="py-2 px-[18px]"
          onClickHandler={() => history.back()}
        >
          {"<< Go Back"}
        </Button>
        <div
          onClick={() => {
            setIsModalOpen("cancelReasons");
            mixpanel.track("Cancel Transaction Clicked");
          }}
          className="cursor-pointer hover:scale-125 ttransition-all"
        >
          <CloseIcon fill="fill-grey-200" />
        </div>
      </div> */}
      <div className="flex flex-col items-center justify-start md:items-start md:px-12 md:py-10">
        <div id="newOne" className="p-3 md:p-0">
          <h2 className="mb-3 text-2xl ">Recipient’s Details</h2>
          <p className="mb-2 text-xl">Please fill recipient details and proceed your transaction</p>
        </div>
        <div className="flex -ml-10 md:hidden text-[20px] font-bold font-ssp border">
          Step {step} of 2
        </div>
        <div id="steps" className="items-center justify-center hidden m-auto mt-16 mb-16 md:flex">
          <div className="flex items-center space-x-32">
            <div
              id="step1"
              onClick={() => setStep(1)}
              className={
                step !== 1
                  ? "flex flex-col items-center justify-center text-gray-400"
                  : "flex flex-col items-center justify-center"
              }
            >
              <span className="text-xl font-bold font-ssp">Step 1</span>
              <span className="text-lg">Bank Details</span>
            </div>
            <RightArrowFilledIcon />
            <div
              id="step1"
              onClick={() => setStep(2)}
              className={
                step !== 2
                  ? "flex flex-col items-center justify-center text-gray-400"
                  : "flex flex-col items-center justify-center"
              }
            >
              <span className="text-xl font-bold font-ssp">Step 2</span>
              <span className="text-lg">Recipient Details {isModalOpen}</span>
            </div>
            {isCorrespondingAccount && <RightArrowFilledIcon />}
            {isCorrespondingAccount && (
              <div
                id="step1"
                // onClick={() => setStep(2)}
                className={
                  step !== 1
                    ? "flex flex-col items-center justify-center text-gray-400"
                    : "flex flex-col items-center justify-center"
                }
              >
                <span className="text-lg font-bold font-ssp">Step 4</span>
                <span>Corresponding Bank Details</span>
              </div>
            )}
          </div>
        </div>
        {step === 1 && (
          <StepOne
            setStep={setStep}
            acNumber={acNumber}
            setAcNumber={setAcNumber}
            ifscCode={isfcCode}
            setIsfcCode={setIsfcCode}
            bankName={bankName}
            setBankName={setBankName}
            setFirstName={setFirstName}
            setLastName={setLastName}
            lastName={lastName}
            firstName={firstName}
            name={name}
            setName={setName}
            accountType={accountType}
            setAccountType={setAccountType}
            branchName={branchName}
            setBranchName={setBranchName}
            setIsModalOpen={setIsModalOpen}
            // handleValidateAccount={handleValidateAccount}
          />
        )}
        {step === 2 && (
          <StepTwo
            setIsModalOpen={setIsModalOpen}
            relationship={relationship}
            phoneNumber={mobile}
            setPhoneNumber={setMobile}
            emailId={email}
            setEmailId={setEmail}
            pinCode={pin}
            setPinCode={setPin}
            addressLine1={addressOne}
            setAddressLine1={setAddressOne}
            addressLine2={addressTwo}
            setAddressLine2={setAddressTwo}
            city={city}
            setCity={setCity}
            state={state}
            setState={setState}
            handleFinalClick={handleFinalClick}
          />
        )}
      </div>
      <Modals
        isModalOpen={isModalOpen}
        setIsModalOpen={setIsModalOpen}
        relationship={relationship}
        setRelationship={setRelationship}
        accountType={accountType}
        setAccountType={setAccountType}
      />
    </motion.div>
  );
};

export default InwardRecipientDetails;
