/* eslint-disable react/no-unescaped-entities */
import React from "react";

import { IStepOne } from "./StepOne/StepOne";
import Modal from "../../../components/ui/Modal";
import Button from "../../../components/ui/Button";

export const TermsModal: React.FC<IStepOne.ITermsModalProps> = ({
  isTermsModalOpen,
  setIsTermsModalOpen,
  modalTitle,
}) => {
  return (
    <Modal
      modalClass="!pt-4 md:pr-11 !rounded max-h-[100%] sm:max-h-[90%] overflow-scroll lg:!w-10/12 !w-full"
      headerClass="mt-2 bg-red sticky py-8 left-8 right-20 -top-6 bg-white font-bold text-2xl"
      header={modalTitle ? modalTitle : "Terms and Conditions"}
      visible={isTermsModalOpen}
      closeHandler={() => setIsTermsModalOpen(false)}
    >
      <div className="flex flex-col justify-between w-full h-full">
        <div className="w-full overflow-scroll md:overflow-hidden max-h-1/2 text-justify">
          This document is an electronic record in terms of the Information Technology Act, 2000 and
          rules there under as applicable and the amended provisions pertaining to electronic
          records in various statutes as amended by the Information Technology Act, 2000. This
          electronic record is generated by a computer system and does not require any physical or
          digital signatures.
          <br />
          <br />
          This document is published in accordance with the provisions of Rule 3(1) of the
          Information Technology (Intermediaries guidelines) Rules, 2011 that require publishing the
          rules and regulations, privacy policy, and Terms and Conditions for access or usage of the
          Website.
          <br />
          <br />
          The domain name www.moneyhop.co (hereinafter referred to as "Website") is owned by Unobanc
          Private Limited, a company incorporated under the Companies Act, 2013 with its registered
          office at office at No.84/2, Shop No.1, First Floor, Clement Town, Lane No.11, Dehradun,
          Uttarakhand, India - 248002 (hereinafter referred to as “us” , “we, “our”, "Company" or
          "moneyHOP").
          <br />
          <br />
          The use of the Website, services including Remittance Facility (defined hereinafter) and
          tools are governed by the following terms and conditions (hereinafter these terms and
          conditions shall be referred to as "Terms and Conditions" or “T&C”) as applicable to the
          Website including the applicable policies which are incorporated herein, by way of
          reference.
          <br />
          <br />
          By using the website, you agree to be subject to the policies that are applicable to the
          Website for such transactions. By mere use of the Website, you shall be contracting with
          the Company and these terms and conditions,including the policies constitute your binding
          obligations, with the Company.
          <br />
          <br />
          For the purpose of these Terms of Use, wherever the context so requires "You" “I”, or
          "User" shall mean any natural or legal person who has agreed to become a purchaser,
          receiver, seller or remitter on the Website by providing information and data on the
          Website as required for using the computer systems.
          <br />
          <br />
          The Company allows the User to browse the Website but does not allow the user to make
          purchases, sale and remittance without providing the information as required from a
          natural or legal person for transactions qua 'Foreign Exchange products' on the Website or
          otherwise.
          <br />
          <br />
          When a user uses any of the services provided by the company through the Website, the user
          will be subject to the rules, guidelines, policies, terms, and conditions applicable to
          such service, and they shall be deemed to be incorporated into these Terms and Conditions
          and shall be considered as part and parcel of these Terms and Conditions.
          <br />
          <br />
          The company reserve the right, at our sole discretion, to change, modify, add or remove
          portions of these Terms and Conditions, at any time without any prior written notice to
          the user. It is user’s responsibility to review these Terms and Conditions periodically
          for updates / changes. User’s continued use of the Website following the posting of
          changes will mean that the user accepts and agrees to the revisions or updates as the case
          may be. As long as the user complies with these Terms and Conditions, the company grant
          the user a personal, non-exclusive, non-transferable, limited privilege to enter and use
          the Website.personal, non-exclusive, non-transferable, limited privilege to enter and use
          the Website.
          <br />
          <br />
          Use of the website is available only to visitors/users who agree to all the T&C, Privacy
          Policy, Disclaimer Policy and all other Policies/ Terms published by the Company on it’s
          website/ Platforms and who can form legally binding contracts under The Indian Contract
          Act, 1872. Persons who are "incompetent to contract" within the meaning of the Indian
          Contract Act, 1872 including but not limited to minors, un-discharged insolvent etc. are
          not eligible to use the Website. If the user/ visitor is a minor, i.e. under the age of 18
          years, you shall not be eligible to be a User of the Website and shall not transact on or
          use the Website. As a minor if You wish to use or transact on the Website, such use or
          transaction may be made by Your legal guardian or parents on the Website. The Company
          reserves the right to refuse to provide You with access to the Website on any ground,
          including but not limited to on the ground that if it is brought to our notice or if it is
          discovered that You are under the age of 18 years.
          <br />
          <br />
          <h2 className="font-bold">Overview of Remittance Facility</h2>
          The Remittance Facility on the Website (as defined below and elsewhere in these T&C), is
          made available by the company and is processed by Banks or authorised dealers who are
          lawfully authorised under applicable law to process such remittance transaction
          (hereinafter referred to as ‘authorised partners’) and the company provides the User with
          a technology platform to place requests for the Remittance Facility, which can be
          entertained by one its authorised partners and also to track the status of such requests
          through the Website on a no-liability and no-obligation basis.
          <br />
          <br />
          In order to avail the facilities described above, the User should make the transfer of
          funds from the User’s own resident individual account maintained with his/her bank in
          India and subject to other applicable laws and internal rules of the company and its
          authorised partner. Remittance Facility requests can only be made by the User for the
          purposes permitted by the authorised partner.
          <br />
          <br />
          <h2 className="font-bold"> Fees and Charges</h2>
          The company can set its own fees and charges for facilities (including the Remittance
          Facility) and may revise the same at any time and the availability of facilities displayed
          or offered on or through the Website are subject to change. Nothing contained on the
          Website constitutes an offer, promise or commitment to grant or provide any facility.
          While the authorised partners endeavours to post accurate and updated information on the
          Website, the User must verify the same before taking any action or entering into any
          transaction. The Company and / referrer may charge a commission / conveyance fee which may
          or may not be specifically mentioned in the price breakup but which shall be included in
          the total cost shown to the User before initiating the transaction.
          <br />
          <br />
          The Company does not control and is not responsible in any manner for any fees or charges,
          if any, that may be imposed by any authorised partners involved in the international wire
          transfer, including but not limited to correspondent or Beneficiary Bank or such financial
          institution(s) or any other third parties in connection with the wire transfer.
          <br />
          <br />
          Registered Users acknowledge and understand that when the funds are remitted by a
          Registered User, hop would be required to effect the conversion at the applicable exchange
          rate prevailing on the day of conversion, subject to availability of exchange rate. The
          Registered User agrees that the prevailing exchange rate is the exchange rate applicable
          at the time of conversion of funds which will be applicable for processing the user's
          transaction request.
          <br />
          <br />
          Neither the User nor the Beneficiary/ recipient will be entitled to any interest for the
          period during which the funds to be remitted are with the authorised partner, are in the
          course of remittance, or for any other period.
          <br />
          <br />
          The Referrer shall be given an option to fix their charges for each transaction in the K2
          portal subject to a capping limit.
          <br />
          <br />
          <h2 className="font-bold underline underline-offset-4 "> Refund Policy</h2>
          The Customer may cancel the request before initiating the transaction/ paying the amount
          to be remitted. Any cancellation done after the initiating the transaction will not be
          refunded if such transaction amount has reached our Authorised Partner/ virtual Bank
          account. Company will provide it’s fullest support to convey the issue of the User to it’s
          authorised partner on best effort basis, however the customer understands that the refund
          process will be at the sole discretion of the Authorised Partner/ Bank. The Commitment
          from the Company would only be to transfer the amount refunded by our Authorised Partner
          to such User. User shall not be entitled to claim refund of Bank charges, Nostro charges,
          processing fee, GST or for damages for delay etc.
          <br />
          <br />
          <h2 className="font-bold"> Eligible Users</h2>
          In order to access and use the product and avail of the Remittance Facility, the User must
          be an individual of at least 18 years of age having the capacity to enter into legally
          binding contracts under the law applicable to the User. The User must be a resident
          individual holding a bank account with any bank in India, must hold a Pan card and Aadhaar
          card. Further the customer/s whose account has been classified as delinquent/ PEP/ High
          Risk by any the company, authorised partner, any Bank/Financial institution/ regulator,
          shall not be eligible to avail the Remittance Facility. Residents of India (collectively,
          "Residents") shall access, use and avail of the Website and the remittance facilities only
          to the extent the domestic laws of India and such countries permit them to access, use and
          avail of the Website and the facilities.
          <br />
          <br />
          All non-residents understand that by accessing, using and availing of the Website and the
          facilities, they may be violating local laws in India and/or such other countries. The
          User agrees to have read all the declarations given in subsequent portions of these T&C
          and has agreed to give such declarations. The User agrees that it will be solely and
          absolutely liable for any liability incurred in this regard and the User will indemnify
          the company and it’s authorised partner against any liability incurred by the company and
          it’s authorised partner in this regard.
          <br />
          <br />
          The User undertakes that the bank account, details provided to the bank, pertain to
          his/her own resident savings bank account, and the funds being remitted are his/her own
          funds and such funds and are not originating from an NRO (non-resident ordinary) account.
          The User agrees that it will not request a remittance payment that would result in a
          violation of the laws or regulations of India and/or applicable law in the country of the
          Beneficiary
          <br />
          <br />
          <h2 className="font-bold"> Registration Information</h2>
          Only limited access to the Website is available to non-Users (visitors). Non-Users are not
          permitted to avail the Remittance Facility. In order to obtain increased access to the
          Website and in order to avail of the Remittance Facility, every Person who is desirous of
          availing such facilities is required to register on the Website as a Registered User
          (referred as “user” in these T&C). Subject to other T&C, upon registration as a User, the
          Website will register the relevant User ID and User Password. The User hereby agrees that
          he shall not get registered more than once in the Website for availing the Remittance
          Facility.
          <br />
          <br />
          The User agrees to provide true, accurate, current and complete information, as prompted
          by the registration form (for self or Beneficiary) on the Website, and to update this
          information to keep it true, accurate, current and complete at all times. If, in the
          judgment of or to the knowledge of the authorised partner , any information provided by
          the User is untrue, inaccurate, not current or incomplete, the company and it’s authorised
          partners retain the right to terminate the User's registration and refuse the User access
          to or use of the Website or any facilities, including the cancellation of pending
          transactions.
          <br />
          <br />
          The company and it’s authorised partners reserve the right to request the User, at any
          time and from time to time, for additional information and/or proof of authenticity of any
          information; the Users continued access to the Website and the facilities, including the
          completion of any ongoing transaction, may be subject to the receipt by the company and
          it’s authorised partner of such additional information or proof thereof.
          <br />
          <br />
          The User will be solely and absolutely responsible for maintaining the secrecy and
          confidentiality of all User IDs and User Passwords and be fully responsible and liable for
          all transactions and activities that occur under the Users ID and using the Users
          Password, including any unauthorised use or misuse of such User ID and/or User Password
          and the company and it’s authorised partner shall have no obligation to verify the
          authenticity of any such transaction or activity.
          <br />
          <br />
          In consideration of the company and it’s authorised partner agreeing to provide the
          Remittance Facility as per the applicable T&C, the User hereby unconditionally agrees to
          indemnify the company and it’s authorised partner and hold the company and it’s authorised
          partner harmless against any liability, costs, damages, claims, suits, and proceedings
          based upon or relating to such unauthorized access and use. Without prejudice to the
          aforesaid, the User agrees to log out from his or her User Account at the end of each
          session and to immediately notify the company and it’s authorised partner via E-mail on
          the Website, or through the Call Centre, or registered post address of any suspected loss,
          theft, unauthorised usage of the User ID or Users Password, any other breach of security,
          or any receipt by the User of confirmation of a transaction, funds or other activity which
          the User did not authorise; or any inaccurate information in the Users account balances or
          transaction history.
          <br />
          <br />
          You agree that by informing company and it’s authorised partner, you will not be absolved
          of your duties and liabilities.
          <br />
          <br />
          If the User forgets his/her User Password, the company and it’s authorised partner may,
          subject to verification and satisfaction as to the Users identity, allow the User to reset
          his/her password or enable user ID as per the process.
          <br />
          <br />
          The company and it’s authorised partner reserves the right to reject registration of
          remitter as a User if the company and authorised partner is not satisfied with results of
          the Know Your Customer (“KYC”) and anti-money laundering diligence checks conducted by the
          company and it’s authorised partner. The company retains the right to terminate the
          registration of any User and refuse the User access to or use of the Website or any
          facilities, including the cancellation of pending transactions, as per its discretion.
          <br />
          <br />
          <h2 className="font-bold"> Acceptable Use</h2>
          A User/Visitor shall not:
          <br />
          <br />
          1. Use or access the Website or any facility to do, facilitate or support any act that
          violates the any T&C or any rules and regulations of any jurisdiction, including but not
          limited to rules and regulations relating to money laundering, illegal gambling
          activities, fraud, or funding of terrorist organizations.
          <br />
          <br />
          2. Restrict or inhibit any other Person from accessing, using and enjoying the Website or
          the facilities.
          <br />
          <br />
          3. Modify, copy, distribute, transmit, display, perform, publish, licence, create
          derivative works from, transfer or sell any information, designs, logos, trademarks,
          software, facilities, products or services obtained on or through the Website, except as
          permitted by the copyright owner or other right holder thereof.
          <br />
          <br />
          4. Post or transmit any unlawful, fraudulent, libellous, defamatory, obscene,
          pornographic, profane, threatening, abusive, hateful, offensive, or otherwise
          objectionable information or statement of any kind including, without limitation, any
          information or statement constituting or encouraging conduct that would constitute a
          criminal offence, give rise to civil liability, or otherwise violate any local, state,
          national, foreign or other law.
          <br />
          <br />
          5. Post or transmit any advertisements, solicitations, chain letters, pyramid schemes,
          investment opportunities or schemes or other unsolicited commercial communication (except
          as otherwise expressly permitted by the company in writing) or engage in spamming or
          flooding.
          <br />
          <br />
          6. Post or transmit any information or software which contains a virus, trojan horse, worm
          or other harmful component.
          <br />
          <br />
          7. Post, publish, transmit, reproduce, distribute or in any way exploit any information,
          software or other material obtained from or through the Website for commercial purposes.
          <br />
          <br />
          8. Upload, post, publish, transmit, reproduce or distribute in any way, information,
          software or other material obtained on or through the Website which is protected by
          copyright or other proprietary right, or derivative works with respect thereto, except as
          permitted by the copyright owner or other right holder thereof.
          <br />
          <br />
          9. Upload, post, publish, reproduce, transmit or distribute in any way any component of
          the Website itself or derivative works with respect thereto, except as permitted by the
          Company or the copyright owner or other right holder thereof, the Website being
          copyrighted under the relevant laws.
          <br />
          <br />
          10. Attempt to decompile or reverse engineer any of the software available on the Website.
          <br />
          <br />
          11. Make any attempt to hack into the Website or otherwise attempt to subvert any firewall
          or other security measure of the Website and if the User/ Visitor becomes aware of any
          shortcoming in the security on the Website the Visitor shall
          <br />
          <br />
          forthwith inform the Company of the same in writing. 12. Reproduce, duplicate, copy, sell,
          resell or exploit for any commercial purposes, any portion of the Website, use of the
          facilities, or access to the Website or the facilities, other than as provided within the
          scope of the service.
          <br />
          <br />
          13. Disclose to any other Person, in any manner whatsoever, any information of a
          confidential nature relating to the Company or the affiliate partners obtained in the
          course of using or accessing the Website or availing of any facility.
          <br />
          <br />
          14. The User may not resell the User Account access or use the Remittance Facility to make
          remittance payments on behalf of, or as agent for, another Person.
          <br />
          <br />
          If any person violates the Acceptable usage policy, the company and it’s authorised
          partner may in addition to exercising appropriate legal action, off board such Registered,
          suspend/ cancel any transactions/use of the website and it’s facilities user without
          giving any notice and at its sole discretion.
          <br />
          <br />
          <h2 className="font-bold"> Limitations of Offering</h2>
          <br />
          <br />
          The company and it’s authorised partner shall not be under any duty to assess the prudence
          or otherwise of any instruction or transaction given or entered by the User. Nothing
          provided on the Website should be construed as advice of any nature and the Users are
          advised to consult professionals in this regard prior to taking any decision.
          <br />
          <br />
          The User hereby authorizes the company and it’s authorised partner to verify any
          information provided and to share such information with third parties for lawful purposes.
          The company and it’s authorised partner shall not be responsible for any error or omission
          made by the User in keying in or otherwise providing the information required to avail of
          the Remittance Facility or any consequences as a result of such error or omission.
          <br />
          <br />
          If you provide an incorrect account number for the Beneficiary/ recipient or an incorrect
          identification number for the Beneficiary/recipients financial institution, you may lose
          the entire amount of the funds transferred and the company and it’s authorised partner
          will not be liable for the same in any manner whatsoever.
          <br />
          <br />
          The company and it’s authorised partner shall endeavour to carry out the requests received
          under the Remittance Facility, however, the company and it’s authorised partner shall be
          entitled, in its sole and absolute discretion, to refuse to comply with all or any of a
          Users instructions or requests without assigning any reason.
          <br />
          <br />
          The User cannot amend, cancel or revoke any request made to the company under the
          Remittance Facility on submitting the transaction request and successfully transferring
          the funds to the advised account of the company or its authorised partner. In the event
          the User desires to revoke /cancel after successfully submitting the transaction for
          processing, the company or it’s authorised partner may accept such cancellation at its
          discretion and on a best effort basis, provided that exchange loss incurred by the company
          or it’s authorised partner in this connection shall be reimbursed.
          <br />
          <br />
          The company will be entitled to debit such amounts due to it from the funds paid to the
          the authorised partner by the User for executing such transaction. Any scheme for awarding
          or securing reward points or other rewards or prizes (by whatever name called) is
          available only to Persons who are eligible for the same under the rules of the concerned
          scheme and the applicable laws of India and other concerned jurisdictions. The User is
          therefore required to verify the eligibility before participating in any such scheme.
          <br />
          <br />
          The company and it’s authorised partner is not responsible or liable for the performance,
          quality or any other aspect of any rewards, prizes or items manufactured or supplied by
          third parties against any redemption of the points or otherwise pursuant to such scheme,
          and the User's recourse for the same will be only against the Persons who have
          manufactured or supplied the same.
          <br />
          <br />
          The company shall be entitled to have more than one promotional offer in existence and
          applicable at any given time. However, a User shall be entitled to avail of only one offer
          per transaction and in case of multiple transactions under a given offer, he/she shall be
          eligible only for the first eligible transaction. It shall be entirely at the discretion
          of the company to consider any exceptions to the above.
          <br />
          <br />
          The user understands that the company/authorised partner/bank/ financial institution/
          regulator involved in the processing the remittance, whether in Indian or in recipient
          country may put the remittance request and the fund transferred, on hold, if, in its sole
          discretion, it finds that the remitter/ beneficiary /transaction as suspicious, without
          disclosing the reason for such suspicion. The user also understands that the company or
          the authorised partner shall not be responsible to get the transaction completed in such a
          scenario.
          <br />
          <br />
          The company and it’s authorised partner has no control over, and is not responsible for,
          the quality, safety, legality, or delivery of any goods or services that the User may pay
          for using the Website and/or the facilities. Such use will be at the Users own risk.
          <br />
          <br />
          In order to avail of specific facilities, the User may have to agree to other terms and
          conditions in addition terms and conditions contains herein and may also have to execute
          agreements, undertakings, declarations, powers of attorney and other writings and abide by
          the procedures as may be specified by the company and it’s authorised partner.
          <br />
          <br />
          The company retains the right to terminate the registration of any User and refuse the
          User’s access to or use of the Website or any facilities, including the cancellation of
          pending transactions, as per its discretion.
          <br />
          <br />
          <h2 className="font-bold">Disclaimers</h2>
          <br />
          <br />
          The Website, all information on or available through the Website and the facilities
          provided by company are made available to the Users as is and without any representation
          or warranty of any kind, either express or implied, including without limitation, any
          representation or warranty for accuracy, continuity, uninterrupted access, timeliness,
          sequence, quality, performance, fitness for any particular purpose, completeness, title,
          compatibility, warranty of non-infringement, freedom from computer virus, warranties of
          merchantability or fitness for a particular purpose, each to the extent permissible by
          applicable law.
          <br />
          <br />
          Although the company adopts security measures that it considers appropriate for the
          Website, it does not represent or warrant that the Website is immune from denial of
          service attacks, unauthorised access or other illegal subversion of these security
          measures that might impair the working of the Website, the availability of the facilities
          or the integrity and confidentiality of User’s personal and login information or accounts
          and the company disclaims liability arising from any such acts or occurrences.
          <br />
          <br />
          Furthermore, while the company and its authorised Partners takes reasonable precautions to
          avoid such eventualities, the User understands that communications through internet are
          not always secure and agrees that the Company shall not be responsible for any
          unauthorised or illegal interception of E-mail or other communication to or from the
          company and it’s authorised partner.
          <br />
          <br />
          Services are subject to the activities taken by other international banks and Service
          Providers involved in the wire transfer. The Company disclaims any responsibility for any
          delay or failure caused due to any action of any authorised Partners, international banks
          and Service Providers, regulators involved in the remittance.
          <br />
          <br />
          Information and facilities on the Website may be displayed and offered in a phased manner
          at the discretion of the Company. The company shall have the right to, from time to time
          and in its discretion, introduce new information and facilities and add to, modify, or
          withdraw any information or facility or the terms thereof in whole or in part. While the
          Company makes every endeavour to ensure that the facilities are offered to a User without
          interruption or delay, continued provision of the facilities may be impractical or
          impossible due to the failure of operational systems for reasons including but not limited
          to virus attacks, natural calamity, floods, fire and other natural disasters, legal
          restraints, faults in the telecommunication network or network failure, system
          maintenance, software or hardware error, labour problems, strikes or any other reason
          beyond the control of the Company, and the Company makes/provides no guarantee that any
          instructions or transactions will definitely be communicated, processed or performed using
          the Website or the facilities.
          <br />
          <br />
          The Company operates and offers the Website and the facilities strictly on a no-liability
          and no-obligation basis. The Company shall not be liable to the User or any other third
          party for any claim for direct, indirect, incidental, special, exemplary, punitive,
          consequential or other damages (including, without limitation, loss of profits, loss or
          corruption of data, loss of profit or goodwill, work stoppage, computer failure or
          malfunction, or interruption of business) whether arising under contract, tort or any
          other theory arising out of or in connection with the Website, the inability to access the
          Website or any facilities, products or services mentioned or advertised on or accessed or
          availed on or through the Website. The Users remedies for errors or unauthorized
          transactions shall be limited to those arising under the applicable laws and regulations,
          and as described on the Website and in the communications provided by the company to the
          User.
          <br />
          <br />
          Notwithstanding anything, the maximum liability of the company and it’s authorised partner
          to the User shall not exceed the amount of fees paid/payable by the User to the company
          and it’s authorised partner for the specific facility availed by the User through the
          Website and under an issue.
          <br />
          <br />
          The User/ Visitor/ Referrer shall ensure that he/she shall not be in violation of any data
          protection laws in force at the time of referring potential users for the service to the
          company or when registering for the Remittance Facility (as the case may be).
          <br />
          <br />
          <h2 className="font-bold"> Facilities Provided by Third Parties</h2>
          <br />
          <br />
          Some of the facilities mentioned or offered on or through the Website require the User to
          independently contract for and use services provided by third party Service Providers and
          the company makes no warranty on behalf of such third parties. The company shall not be
          liable for any loss or damage of any sort incurred as a result of any transactions or
          dealings between the Users and such third-party Service Providers.
          <br />
          <br />
          The User may be charged for such transactions /dealings by such third-party Service
          Provider. Such third-party Service Providers may have their own agreements, terms and
          conditions which will govern the services provided by them, which may be specific to each
          such Service Provider and vary from facility to facility. The company recommends that the
          User obtain the full text of such terms and warranties from such Service Providers before
          availing of any such service. All of the User's rights and remedies and all maintenance,
          update, warranty, liability or any other obligations related to any such services shall be
          governed by the applicable terms, policies and procedures of the concerned Service
          Provider and the company shall not be liable or responsible for the same in any manner.
          <br />
          <br />
          The User shall have no claim, rights or remedies whatsoever against the company in respect
          of any such services availed or sought to be availed by the User from any such Service
          Provider. The User agrees not to hold the company liable for any loss or damage of any
          sort incurred as a result of any such dealings with any Service Provider.
          <br />
          <br />
          <h2 className="font-bold"> Terms of specific facilities</h2>
          <br />
          <br />
          The following (among other) facilities are proposed to be offered on the Website. However,
          all the facilities or all the various components and features of a facility may not be
          offered at all times. The Company may discontinue, modify, change, and alter any of the
          features of the facility at its sole discretion.
          <br />
          <br />
          Alerts: the Company shall endeavour to ensure that Alerts are communicated to the User in
          accordance with the User's instructions. However, neither the company nor authorised
          partners would be responsible or liable for non-dispatch or delay in dispatch of the
          Alerts or any delay in receipt or non-receipt of the Alerts for any reason whatsoever.
          <br />
          <br />
          Under no circumstances shall the company and authorised partners be liable for any costs,
          damages or other amount whatsoever for such non-dispatch or delay in dispatch or any
          non-receipt or delay in receipt of the Alerts. Non- receipt of Alerts will not discharge
          or reduce the User's liability to pay any amount to the company or its authorised partners
          which would have been payable in the event of proper receipt of the Alerts.
          <br />
          <br />
          Remittance Facility: Subject to the relevant regulatory approvals, terms and conditions
          imposed while granting the necessary approvals and other terms of the Remittance Facility,
          such facility enables the User to avail of a technology platform for initiating outward
          remittances. The authorised partners shall, under the Remittance Facility, convert the
          funds received from the User in to the relevant currency of the Beneficiary as instructed
          by the Register User. The User should pay the total remittance amount including any
          charges and taxes as may be applicable, as per the instructions stated on the Website by
          an acceptable mode described on the Website.
          <br />
          <br />
          The funds will be remitted from India only after matching the name of the User against the
          sender’s (Remitter’s) name as mentioned in the electronic fund transfer message received
          by the authorised partner. Thereafter, after deducting the appropriate charges/fees as may
          be applicable at the time of remittance, the money will be remitted to the Beneficiary
          Bank account in the Relevant Country as designated by the User.
          <br />
          <br />
          The authorised partner will process the request only upon receipt of clear funds from the
          remitters account and a swift message will be sent to the correspondent bank. The
          authorised partner will provide confirmation on processing the transaction, which shall
          mean that the bank has sent the swift message to the correspondent bank. The authorised
          partner will not be able to confirm whether the Beneficiary account has been credited,
          however, if the funds are returned, then status will be updated accordingly and the funds
          will be refunded to the same account from which funds were received after deducting
          applicable charges.
          <br />
          <br />
          The authorised partner will process the remittance request only if the funds are received
          in the designated account of the bank within the validity period as mentioned on
          submitting the transaction along with the transaction's unique reference number. In the
          event, the funds are received after the validity period the company and its authorised
          partner reserve the right to cancel the transaction as per its sole discretion. Validity
          period for the purpose of this clause shall mean the period up to which the funds are
          received by the authorised partner for processing the transaction. On expiry of the
          validity period, the company and its authorised partner reserves the right to either
          process the transaction on receiving the funds at the prevailing exchange rate applicable
          at the time of conversion or may reject the transaction and return the funds to the
          remitting account at its sole discretion.
          <br />
          <br />
          While it shall be the company’s endeavour to adhere to the time schedule indicated by it
          on the Website, the company and its authorised partner hereby disclaims liability or
          responsibility for any changes in the time schedule for execution of the User's
          instructions or remittance or credit of funds for any reason, except as otherwise required
          by applicable law or regulation and as described to the User on the Website and in
          communications provided by the company and its authorised partner to the User. The company
          and its authorised partner shall in no way be held responsible and/or be liable for any
          queries, errors, disputes or delays in messaging, money transmission, currency conversion,
          conversion rates offered, payment to the beneficiaries of the remittances or any other
          query, claim or dispute.
          <br />
          <br />
          The company will, however, use reasonable efforts to assist the User in contacting the
          authorised partner or any third party to facilitate resolution of such queries, claims and
          disputes. The User hereby authorizes the bank to check or to use a third-party agency to
          check the User's credit history/identity details at the time of and/or prior or subsequent
          to enrolment. The User hereby agrees that, where the authorised partner so deems advisable
          or necessary as per its due diligence criteria, it may hold the money (to be remitted to
          the Relevant Country) for longer than the usual time for remittance, and may delay the
          remittance. The User hereby agrees that the company or is authorised partner has no
          control over when the Beneficiary's financial institution (through whom the Beneficiary
          chooses to receive the remittance) makes such funds available for the Beneficiary's use
          and the bank shall not be responsible for any delay or default on the part of such
          financial institution.
          <br />
          <br />
          The User hereby agrees that the purpose of the remittances supported by the Remittance
          Facility will be strictly limited to the purposes as permitted by law at the time of using
          the Remittance Facility. The purpose declared by the User while using the Remittance
          Facility will be treated as final and binding and cannot be changed at a later date by the
          User or the intended Beneficiary.
          <br />
          <br />
          The regulatory reporting by the authorised partner in India will be done on the basis of
          the purpose selected by the User through the Remittance Facility. The User shall provide
          to the bank all the information as may be required, in absence of which the User shall not
          avail of the Remittance Facility. The User hereby undertakes that by sending funds to the
          Relevant Country, he/she is not violating any exchange control laws stipulated by the
          Government of India and/or the Reserve Bank of India and the rules prevailing in such
          destination country.
          <br />
          <br />
          The User agrees to keep the company and its authorised partner indemnified for any such
          violation the User hereby acknowledges that the obligation shall lie on the User to ensure
          that there are no violations of any law, regulation, authorization including the limits on
          outward remittances listed in the Foreign Exchange Management Act, of India or exchange
          control and the User shall be solely liable for any violation thereof.
          <br />
          <br />
          The User hereby agrees and acknowledges that the maximum value for any remittance
          transaction or the cumulative values of a number of remittance transaction using the
          Remittance Facility shall not exceed the limit of USD 250,000 for a particular financial
          year or such other limit that may be communicated by the company or it’s authorised
          partner to the User from time to time. Besides, the User will be able submit requests for
          foreign outward remittance through net-banking within the applicable third-Party funds
          transfer limit only, as applicable to his/her account.
          <br />
          <br />
          By agreeing to these Terms and Conditions, the user/customer, hereby provide his/her explicit consent
          for Unobanc to use his digital signature on all documents necessary for the processing of transactions
          related to the services provided by the Unobanc. This consent includes transaction-related documents
          that require your signature for the purpose of conducting business electronically.
          <br />
          <br />
          User/Customer hereby acknowledge and agree that his/her digital signature, once affixed to a document,
          shall be considered as valid, binding, and enforceable as his/her handwritten signature.
          User/Customer further agree that they will not dispute the legality, validity, or enforceability of any
          transaction or document solely because a digital signature was used in its execution.
          <br />
          <br />
          This consent remains in effect until expressly revoked in writing, subject to any completed or pending
          transactions initiated prior to the revocation of consent. Revocation of this consent may result in the
          termination of your ability to transact electronically with Unobanc.
          <br />
          <br />
          The company or it’s authorised partner may specify to the User through the Website from
          time to time the limit on the funds that can be transferred from the Users account to the
          Beneficiary account. The User acknowledges that he/she has read, understood and complied
          with all laws and regulation including those mentioned in the Declaration and the bank
          shall not be responsible for any non-compliance of the limits mentioned therein by the
          User.
          <br />
          <br />
          The company or it’s authorised partner shall assume no responsibility for the performance
          of any entity involved in the process; and for any loss or damage incurred or suffered by
          the User for any error, defect, failure or interruption of the facility or consequences
          arising out of delayed payments. The company or it’s authorised partner shall not be
          responsible for impounding of funds by any regulators/ regulatory authority in the
          Relevant Country.
          <br />
          <br />
          <h2 className="font-bold">Others</h2>
          <br />
          <br />
          The User understands that submission of request does not necessarily imply processing of
          the transaction. The company and it’s authorised partner has full rights to reject the
          transaction based on regulatory and internal guidelines. The User will ensure that the
          limit submitted is correct and has not been exhausted in the current financial year for
          any of the accounts held individually or collectively with any bank. The User understands
          that on submitting the request if the service request number is not displayed then he
          should check if the email confirmation is received for successful submission of the
          request and should check if the debit is posted in the account. Any re-initiation without
          confirming will result in duplicate debit into his/her account. The User agrees to adhere
          to the turn-around time and cut off timings for raising the request.
          <br />
          <br />
          Funding the account for remittance to be processed as per informed timelines on the
          Website the above cut off timing is applicable on working days (presently Monday to Friday
          excluding bank holidays in India and the country of remittance). The User confirms that
          he/she is not a foreign national. Further, this facility is only for telegraphic
          transfer/wire transfer. The User agrees and acknowledges that the company or it’s
          authorised partner is not liable in case the funds are not credited or credited with the
          delay for any reason at Beneficiary Bank/intermediary bank's end for any query or
          suggestion kindly contact our customer service desk.
          <br />
          <br />
          The exchange rate applicable to the remittance request will be applicable only if the
          designated account of the bank is funded by the User within the validity period. The
          authorised partner will process the transaction request only if the funds are transferred
          from a self-resident savings account of the remitter, in case the funds are transferred
          from a joint account, the User must be the primary account holder and does not require any
          permission from the joint account holder. If the funds are received from an account where
          the User is not primary account holder and the name does not appear in the fund transfer
          details received from his/her bank, bank would reject such transaction and return the
          funds within 10 working days.
          <br />
          <br />
          User will be responsible for correctness of the information provided for the Referral
          Transaction, any rejection/delay due to incorrect details provided, will not be the
          responsibility of the company or its authorised partner.
          <br />
          <br />
          The customer declaration taken from the User, shall be correct and the customer shall be
          responsible for the correctness of the same.
          <br />
          <br />
          <h2 className="font-bold"> Referral Transactions(K2)</h2>
          <br />
          <br />
          The transactions in relation to the recommendations/ referrals made by the Referrer will
          be authorised partner’s transactions (“Referral Transactions”) and the Referrer
          understands that any decision made by the authorised partner in relation to the Referral
          Transactions shall be final.
          <br />
          <br />
          The Referrer agrees to assist and conduct its searches to confirm the legality and
          validity of the KYC documents in relation to the Referral Transactions in accordance with
          the instructions provided by the authorised partner and shall ensure the remitters
          referred by such Referrer have gone through these T&C and agree to them unconditionally.
          The authorised partner will be responsible for the compliance of the KYC Master Directions
          (Master Direction DBR.AML.BC. No.81/14.01.001/2015-16) for the purpose of such Referral
          Transactions.
          <br />
          <br />
          The Referral Transactions will be subject to applicable Nostro Account charges for the
          payments made to the Beneficiary.
          <br />
          <br />
          The Company will not be held liable for any claims/complaints or liabilities arising in
          relation to the Referral Transactions.
          <br />
          <br />
          If a Person/ customer/ Referrer’s complaint comes to the Company, the Company will within
          1 (one) business day transfer the complaint to the authorised partner.
          <br />
          <br />
          Any refund under the Referral Transaction will be on the basis of return credit received
          in the Nostro Account, and subject to deduction of any conversion charges. In case of any
          refunds due to return from overseas, the customer fee, GST, Tax Collected at Source
          (“TCS”) or any other amounts will not be refunded.
          <br />
          <br />
          Standard charges applicable and also corresponding taxes will be applicable upon refunds,
          while converting the amount back to Indian currency.
          <br />
          <br />
          TCS collected by the authorised partner under the Referral Transactions, will not be
          refunded under any circumstances and the customer/ User/ Person may adjust the same during
          their ITR filing.
          <br />
          <br />
          <h2 className="font-bold"> Proprietary and Intellectual Property Rights</h2>
          <br />
          <br />
          The Company is the owner and/or authorized user of any trademark, registered trademark
          and/or service mark appearing on the Website, and is the copyright owner or licensee of
          the content and/or information on the Website including but not limited to any text,
          links, images, logos, illustrations, audio clips, video clips, screens and product and
          Users or any Visitor shall not download and/or save a copy of the Website or any part
          thereof including any of the screens or part thereof and/or reproduce, store it in a
          retrieval system or transmit it in any form or by any means - electronic, electrostatic,
          magnetic tape, mechanical printing, photocopying, recording or otherwise including the
          right of translation in any language without the express permission of the Company (except
          as otherwise provided on the Website or in the T&C for any purpose) or use or modify it in
          any manner that is likely to cause confusion or deception among Persons or in any manner
          disparages or discredits the bank or any Service Providers.
          <br />
          <br />
          However, Visitors may print a copy of the information on this Website for the Personal use
          or records. The Company does not grant any license or other authorization to, and none
          should be implied or presumed, for use of their respective trademarks, registered
          trademarks, service marks, or other copyrightable material or other intellectual property
          by placing them on the Website.
          <br />
          <br />
          This Website is for the Visitors personal use. If the Visitor makes any other use of this
          Website, except as otherwise provided above, the Visitor may violate copyright, trademark
          and other laws of India and other countries, and may be subject to penalties.
          <br />
          <br />
          <h2 className="font-bold"> Use of Information</h2>
          <br />
          <br />
          By agreeing to the T&C, the User and the Visitor consents to the privacy policy, a copy of
          which may be found on the Website.
          <br />
          <br />
          The User/ Visitor undertakes and authorises the company, its group companies to exchange,
          share or part with all the information, data or documents relating to his/her application
          any of its authorised partners/ financial institutions / credit bureaus / agencies /
          statutory bodies /tax authorities /central information bureaus/ such other Persons as the
          Company / its group companies may deem necessary or appropriate as may be required for use
          or processing of the said information / data by such Person/s or furnishing of the
          processed information / data / products thereof to other banks / financial institutions /
          credit providers / users registered with such Persons and shall not hold the Company and/
          or its group companies liable for use of this information.
          <br />
          <br />
          You/ hereby irrevocably authorise the Company to extract your call records or related
          information as may have been disclosed by you with/ to any third-party Service Providers
          in relation to services and facilities offered to the User by such third party Service
          Providers on account of User’s existing business relationship with the Company.
          <br />
          <br />
          From time to time, it will be necessary for User(s)/ Visitors to supply the company with
          necessary additional and updated data in connection with the provision of services and
          facilities. Failure to supply such data may result in the Company being unable to provide
          any of the above services or facilities. It is also the case that data is collected from
          User/ Visitors in the ordinary course of usage of the website.
          <br />
          <br />
          The purpose(s) for which User/ Visitor data may be used are as follows: i. Verification
          ii. Designing financial services or related products for User/ Visitors' use; iii.
          Marketing financial services or related products to User/ Visitors; iv. Meeting the
          requirements to make disclosure under the requirements of any law, rule, regulation,
          order, ruling, judicial interpretation or directive (whether or not having the force of
          law) applicable to the Company or (any of its branches) and its agents and affiliates; v.
          Any other purposes permitted by law; and vi. Purposes relating to any of the above.
          <br />
          <br />
          Data held by the Company relating to User/ Visitors will be kept confidential but it may,
          at its sole discretion, provide such information to the categories of Persons set out
          below.
          <br />
          <br /> i. Its head office, affiliates or any other branches or subsidiaries of the
          company;
          <br />
          <br />
          ii. Its auditors, professional advisers and any other Person(s) under a duty of
          confidentiality to the bank;
          <br />
          <br />
          iii. Vendors, installers, maintainers or servicers of the it's computer systems;
          <br />
          <br />
          iv. Any exchange, market, or other authority or regulatory body having jurisdiction over
          the bank, its head office or any other branch of the bank or over any transactions
          effected by the User/ Visitor or for the User/ Visitor's account;
          <br />
          <br />
          v. Any party lawfully authorised by law to make such demand or request;
          <br />
          <br />
          vi. Any Person with whom the company contracts or proposes to contract with regard to the
          sale or transfer or sharing of any of its rights, obligations or risks under the T&C;
          <br />
          <br />
          vii. Any Person (including any agent, contractor or third-party Service Provider) with
          whom the company contracts or proposes to contract with regard to the provision of
          services and/or facilities in respect of the User/ Visitor's (as the case may be) or in
          connection with the operation of the bank's business;
          <br />
          <br />
          viii. Any Person employed with, or engaged as an agent by, the bank or its head office or
          affiliates, including any relationship officers, whether in India or elsewhere, for the
          purposes of or in connection with interactions with the User/ Visitors or providing
          services to the User/ Visitors or processing transactions pertaining to the User/
          Visitors' accounts or facilities;
          <br />
          <br />
          ix. (to enable the authorised partner to centralise or outsource its data processing and
          other administrative operations) to the Company's/ authorised partner’s head office, its
          affiliates or third parties engaged by the bank for any such services/operations;
          <br />
          <br />
          x. In case of a wire transfer to the Beneficiary Bank to whom the transfer is being made.
          Xi. A drawee bank providing a copy of a paid cheque (which may contain information about
          the payee) to the drawee;
          <br />
          <br />
          xii. A Person making payment into the User/ Visitor's account (by providing a copy of a
          deposit confirmation slip which may contain the name of the User/ Visitor);
          <br />
          <br />
          xiii. Any Person to whom the Company and its authorised partners are under an obligation
          to make disclosure under the requirement of any law; and
          <br />
          <br />
          xiv. Any Person to whom disclosure may be required by applicable law and regulation.
          <br />
          <br />
          The User/ Visitor hereby agrees and consents that the company shall be entitled, in
          connection with the User/ Visitor's application for any account, facilities or services
          provided by the company, or during the course of the User/ Visitor's relationship with the
          company , to obtain and procure information pertaining to the User/ Visitor any of his/
          her/ its accounts, legal or financial position from whatever sources available to the
          company.
          <br />
          <br />
          Data may be transferred overseas pursuant to the provisions of this clause. The
          information disclosed pursuant to this clause may be subject to further disclosure by the
          recipient to other parties in accordance with the laws of the country in which the
          recipient is located. Such laws may be wider in scope and implemented under less
          restrictive terms than would otherwise be the case in India due to the difference in
          applicable laws and regulations.
          <br />
          <br />
          <h2 className="font-bold"> No Agency or Banking Relationship</h2>
          <br />
          <br />
          The T&C and the use by the User or access to the Website or any facilities are not
          intended to create an agency, partnership, joint-venture, employer-employee or
          banker-customer relationship between the User or the Beneficiary of the one part and the
          company / authorised partner of the other part, except where otherwise specifically agreed
          or appointed. Specifically, the use of Remittance Facility does not establish a checking
          account or any other form of a bank account for the User or the Beneficiary with the
          company / authorised partner and neither does it constitute or create a fiduciary or
          escrow capacity between the Visitor or the Beneficiary and the Company.
          <br />
          <br />
          Funds held while processing a remittance payment request are not insured by any government
          authority. None of the facilities offered hereby is a funds transfer or transmission
          service, and a User’s request or instruction while using a facility is not a payment order
          or other request for a funds transfer.
          <br />
          <br />
          <h2 className="font-bold">Indemnity</h2>
          <br />
          <br />
          Each Visitor and/or User agrees to defend, indemnify and hold the Company, and authorised
          partners and each of their respective affiliates, directors, officers and employees,
          harmless from any and all claims, liabilities, damages, costs, expenses and proceedings,
          including reasonable attorneys' fees, arising in any way from the Visitors or User's use
          of the Website, whether in India or any other jurisdiction, the inaccuracy or
          incompleteness of registration information, or the placement or transmission of any
          message, information, software or other materials through the Website by the Visitor, the
          User or users of the User's ID and password or related to any violation of the T&C by the
          Visitor or User or users of the User's ID and password, and any claims dispute or
          differences between the Visitor or User and any supplier of services that are part of the
          facilities. The User hereby agrees unconditionally to indemnify the hold the Company, and
          authorised partners and hold the hold the Company, and authorised partners harmless
          against any liability, costs, damages, claims, suits, and proceedings based upon or
          relating to such untrue/false/misleading information.
          <br />
          <br />
          <h2 className="font-bold"> Suspension or Termination of Access</h2>
          <br />
          <br />
          The User acknowledges and agrees that the Company may hold, without notice, suspend or
          terminate the User's ID, password or account or deny the User access to all or part of the
          Website or any facilities if the User engages in any conduct or activity that the Company,
          in its sole discretion, believes violates any of the T&C, violates the rights of the
          Company, or is otherwise inappropriate for continued access, or if the Company learns of
          the User's death, bankruptcy or lack of legal capacity or of circumstances that impact the
          User's creditworthiness or for any other reason which the Company thinks fit and proper.
          Until suspension or termination of the User’s ID takes effect, the User will remain
          responsible for any transactions entered into using the User’s ID and all obligations
          incurred thereby or otherwise by the User.
          <br />
          <br />
          <h2 className="font-bold"> Governing Law and Jurisdiction</h2>
          <br />
          <br />
          The Website, the facilities, the T&C, all transactions entered into on or through the
          Website or through the availment of any facility and the relationship between the
          User/Visitor and the Company shall be governed by and construed in accordance with the
          laws of India and no other nation, without regard to the laws relating to conflicts of
          law. The User and the Company agree that all claims, differences and disputes
          (collectively, “Disputes”) arising under or in connection with or pursuant to the Website,
          the availment of any facility, this T&C, any transactions entered into on or through the
          Website or through the availment of any facility or the relationship between the User and
          the Company shall be subject to the exclusive jurisdiction of the competent courts located
          in the city of Bangalore, Karnataka, India and the User hereby accedes to and accepts the
          jurisdiction of such courts.
          <br />
          <br />
          Notwithstanding the aforementioned, if the Company thinks fit, the Company may institute
          proceedings against a User in any other court or tribunal having jurisdiction under
          applicable law. Disputes shall include not only claims made directly by the Visitor, but
          also made by anyone connected with the Visitor or claiming through a Visitor, such as a
          recipient of funds. Disputes include not only claims that relate directly to the Company ,
          but also its successors, assignees, employees, and agents, and claims for which the
          Company may be directly or indirectly liable, even if it is not properly named at the time
          the claim is made.
          <br />
          <br />
          Disputes include claims based on any theory of law, contract, statute, regulation, tort
          (including fraud or any intentional tort), or any other legal or equitable ground.
          Disputes include claims made as part of a class action or other representative action, it
          being expressly understood and agreed to that the resolution of such claims must proceed
          on an individual (non-class, non-representative) basis.
          <br />
          <br />
          The Company hereby disclaims all liability, direct or indirect, for non-compliance with
          the laws of any jurisdiction other than India. The fact that it is technologically
          possible to access or use the Website and avail of any facility in any jurisdiction other
          India should not in itself suggest that it is legal to do so.
          <br />
          <br />
          It shall be the sole responsibility of the Visitor to verify whether the Website and the
          facilities can be legally and legitimately accessed, utilized and availed in the relevant
          jurisdiction and the Visitor understands that by accessing, using and availing of the
          Website and the facilities, he or she may be violating the local laws in India and/or such
          jurisdiction. Further, the Company hereby explicitly disclaims any responsibility, direct
          or indirect, for non-compliance by a Visitor of the laws of any jurisdiction whatsoever.
          <br />
          <br />
          <h2 className="font-bold"> No Waiver</h2>
          <br />
          <br />
          The failure or delay of the Company to exercise or enforce any right or provision of the
          T&C shall not constitute a waiver of such right or provision. No waiver on the part of the
          Company shall be valid unless it is in writing signed by or on behalf of the Company . A
          waiver of any right or provision by the Company on a particular occasion shall not prevent
          the Company from enforcing such right or provision on a subsequent occasion.
          <br />
          <br />
          <h2 className="font-bold">Severability</h2>
          <br />
          <br />
          If any provision of the T&C shall be held to be invalid or unenforceable by reason of any
          law or any rule, order, judgement, decree, award or decision of any court, tribunal or
          regulatory or self-regulatory agency or body, such invalidity or unenforceability shall
          attach only to such provision or condition, and the Visitor, User, the Company, the court,
          tribunal or regulatory or self-regulatory agency or body should endeavour to give effect
          to the parties' intentions as reflected in the provision to the extent possible. The
          validity of the remaining provisions and conditions shall not be affected thereby and
          these T&C shall be carried out as if any such invalid or unenforceable provision or
          condition was not contained herein.
          <br />
          <br />
          <h2 className="font-bold">Limitation</h2>
          <br />
          <br />
          Notwithstanding any statute or law to the contrary, but to the extent permitted by law,
          any claim or cause of action arising out of or related to access or use of the Website or
          any facility or the T&C must be filed within three (3) months after such claim or cause of
          action arose failing which it shall be forever barred.
          <br />
          <br />
          <h2 className="font-bold">Notices</h2>
          <br />
          <br />
          The Company may give notice to the User by E-mail, letter, telephone or any other means as
          the bank may deem fit to the address last given by the User. Notices under the T&C may be
          given to the Company by the User in writing by sending them by post to the registered and
          corporate address of the Company as mentioned on the Website. The Company may, but shall
          not be bound to, act upon notices and instructions given by the User to the Company by
          E-mail, letter, telephone or any other means as the Company may deem fit.
          <br />
          <br />
          In addition, the Company may (but shall not be bound to) also publish notices of general
          nature, which are applicable to all Visitors and/or Users in a newspaper circulating in
          India and/or on its Website. Such notices will have the same effect as a notice served
          individually to each Visitor and/or User. Documents which may be sent by electronic
          communication between the parties may be in the form of an e-mail, an e-mail attachment,
          or in the form of an available download from the Website.
          <br />
          <br />
          The Company shall be deemed to have duly communicated and delivered any communication or
          document to the User if such communication or document is sent via e-mail to the e-mail
          address provided by the User. The Company shall also be entitled to act on the basis of
          any instructions received or purported to be received from the User by e-mail or other
          electronic means or via the internet. The Company shall also be entitled (but not bound)
          to act upon fax instructions and communications.
          <br />
          <br />
          <h2 className="font-bold">Miscellaneous</h2>
          <br />
          <br />
          The Company may sub-contract or employ agents to carry out any functions or services
          relating to the Website, any facility or any of its obligations under the T&C. In order to
          access the Website and avail of the facilities, the Visitor will, at their own cost, have
          to:
          <br /> i. Obtain access to the world wide web and pay any service fees, telephone charges
          and online service usage associated therewith, and; <br /> ii. Obtain access to all
          equipment necessary for the User to connect to the world wide web, including a computer
          and modem.
          <br />
          <br />
          The content presented on or through the Website may vary depending upon the Visitors
          browser limitations and the bank disclaims any responsibility for the unavailability of
          any facility or any error in processing of a transaction through the Website caused by the
          Visitors browser limitations. All communications under these T&C shall be conducted in the
          English language.
          <br />
          <br />
          <h2 className="font-bold"> Definitions and Interpretations</h2>
          <br />
          <br />
          In these T&C, unless the context otherwise requires, the following words and phrases shall
          have the meanings assigned to them hereunder: "Alerts" means notices relating to various
          matters issued or to be issued by the bank to the Visitor from time to time e.g. notices
          of market information, reminders for bill payments, etc.
          <br />
          <br />
          "Beneficiary" means a person resident outside India as defined in Foreign Exchange
          Management Act (FEMA), 1999, for all purposes except for remittance to own account abroad
          and whose details have been provided by the User to the company for the transfer of funds.
          <br />
          <br />
          "Beneficiary Bank" means the bank in the Relevant Country, with whom the Beneficiary holds
          an account, and which has been so mentioned by the User.
          <br />
          <br />
          "Call Centre" means the call centre which may be set up by the Company to assist
          Visitors/Users in connection with the Website including the facilities displayed or
          offered thereon and any transactions entered into or proposed to be entered into by the
          Visitors in respect of such facilities.
          <br />
          <br />
          "Declaration" means a declaration submitted by the User at the time of registration and
          transaction in the form and manner acceptable to the bank.
          <br />
          <br />
          “Nostro Account” means a bank account established in a foreign country usually in the
          currency of that country for the purpose of carrying out transactions there.
          <br />
          <br />
          "Person" includes a resident individual.
          <br />
          <br />
          "Referrer" means an individual or a Person, who may be an existing User, who
          refers/recommends/suggests to another individual or Person the services offered by the
          bank, irrespective of whether the concerned individual/Person avails the service.
          <br />
          <br />
          "User" means any Visitor who has registered himself, herself with the Website in the
          appropriate manner or utilises service offered through this Website or notand shall have
          the same meaning as Visitor.
          <br />
          <br />
          "User Account" means an online account created by User on the Website to avail the
          Remittance Facility.
          <br />
          <br />
          "User ID" means the username that is chosen by the User and registered by the Website
          which, along with the User Password, will enable the User to - Avail of facilities and
          enter transactions in respect of the facilities; Access its User Accounts, download
          application forms for facilities and register a change in address; and Do any other acts
          as the Website may permit.
          <br />
          <br />
          "User Password" means the password that is chosen by the User and registered by the
          Website for access to the Website which, along with the User ID, will enable the User to -
          Avail of facilities and enter transactions in respect of the facilities offered on the
          Website; Access its User Accounts, download application forms for facilities change in
          nominee details, payments, payment of dues, bill payments, trading and make online
          applications register a change in address; and Do any other acts as the Website may
          permit.
          <br />
          <br />
          "Relevant Country” means a country where the User may transfer funds to the Beneficiary
          under the Remittance Facility.
          <br />
          <br />
          "Remittance Facility" means an instruction for transfer of funds from the Users account in
          India to a Beneficiary in Relevant Country through the Website for select purposes
          detailed herein, the services provided by the bank on the Website for the transmission of
          funds by a User to a Beneficiary in the Relevant Country.
          <br />
          <br />
          "Service Provider" means a Person who provides a service to the bank in order to enable
          the bank to operate and/or maintain the Website, provide any feature on the Website or
          provide any facility which is provided by the bank.
          <br />
          <br />
          "Visitor" means any Person who accesses or visits the Website, whether utilises the
          facilities or not.
          <br />
          <br />
          In these T&C, unless the context otherwise requires: Words of any gender are deemed to
          include each other gender; Words using the singular or plural also include the plural or
          singular, respectively; The terms "hereof", "herein", "hereby", "hereto" and derivative or
          similar words refer to this entire Website; The term "article" refers to the specified
          clause of these T&C; Headings and bold typeface are only for convenience and shall be
          ignored for the purposes of interpretation;
          <br />
          <br />
          Reference to any legislation or law or to any provision thereof shall include references
          to any such law as it may, after the date hereof, from time to time, be amended,
          supplemented or re-enacted, and any reference to a statutory provision shall include any
          subordinate legislation made from time to time under that provision; Any term or
          expression used but not defined herein or under the terms shall have the same meaning
          attributable to it under applicable law; References to the word "include" or "including"
          shall be construed without limitation.
          <br />
          <br />
          <h2 className="font-bold"> DECLARATIONS</h2>
          <br />
          <br />
          <h2 className="font-bold">
            Financial limits and Declarations deemed to be understood and given by the User:
          </h2>
          <br />
          <br />
          I hereby declare that:
          <br />
          <br />
          I am a resident Indian.
          <br />
          <br />
          I am not a holder of the Beneficiary’s account to which the remittance is being made,
          except for remittances sent under the purpose of "remittance to own account abroad".
          <br />
          <br />
          The total amount of foreign exchange purchased from or remitted through, all sources in
          India during this financial year including this proposed remittance is within USD 250,000
          (USD two hundred and fifty thousand only) the limit prescribed by Reserve Bank of India
          for the said purpose under the Foreign Exchange Management Act, 1999.
          <br />
          <br />
          Foreign exchange proposed to be remitted by me is for the purpose mentioned in this
          application.
          <br />
          <br />
          The transaction, details of which are specifically mentioned in the application, does not
          cross the limit for the financial year or any other limit prescribed by the Reserve Bank
          of India under the Foreign Exchange Management Act, 1999 for the said purpose.
          <br />
          <br />
          I am aware that the Finance Act, 2015 has amended Section 195(6) of the Income Tax Act,
          1961 whereby the person responsible for making any payments to non-residents, whether or
          not chargeable to tax in India, is compulsorily required to furnish information in such
          form and manner as may be prescribed. I am aware of the penal provisions that would be
          applicable to me/us as a remitter in case of non-compliance. However, the rules
          prescribing the forms have not been notified by Central Board of Direct Taxes (CBDT)/
          Finance Ministry.
          <br />
          <br />
          Currently, Rule 37bb prescribing requirement for furnishing of Form 15ca and certificate
          in Form 15cb is applicable only in respect of payment chargeable to tax. In my view, the
          current Rule 37 bb is not applicable for payments which are not chargeable to tax and
          hence, I am unable to comply with the requirement for the payment not chargeable to tax
          until the new rules are notified by the CBDT/ Finance Ministry.
          <br />
          <br />
          I hereby confirm that amount to be remitted is not chargeable to be taxed in India.
          Therefore, Form 15ca and Form 15cb as per provisions of Rule 37bb of the Income Tax Rules,
          1962 are not required to be submitted. In the event if there is any income tax
          query/inquiry/notice demanding details/information in India in respect of this particular
          remittance, I/we undertake to provide all the necessary information/document as required by the Income Tax Department. Further, I shall indemnify the Company and
          its authorised Partner for any tax/interest or penalty levied by the Income Tax/Income Tax
          Appellate Authorities/courts in India for non-deduction of tax or non-compliance with the
          relevant provisions of income tax law in regard to this remittance.
          <br />
          <br />
          I, hereby confirm that in case it has been identified at any time during the financial
          year, that I have breached the permissible limit of remittances under the extant
          regulations then Company and its authorised partners will not be responsible for any such
          breach by me and I agree to adhere to the penal provisions prescribed by the Reserve Bank
          of India in this regard.
          <br />
          <br />
          In the event if there is any enquiry from any statutory/regulatory authorities in respect
          of this particular remittance, I undertake to provide all the necessary
          information/document(s) to company as may be required in this regard. Further, I undertake
          to indemnify company and authorised partners for any tax/interest or penalty levied by the
          Income Tax/Income Tax Appellate Authorities/courts in India for non-deduction of tax or
          non-compliance with the relevant provisions of income tax law with regard to this
          remittance.
          <br />
          <br />
          I, being a Person resident in India, hereby declare that transaction proposed to be done
          under the liberalized remittance scheme, issued and amended by the Reserve Bank of India
          from time to time, is not in the nature of remittance for margins or margin calls to
          overseas exchanges/overseas counter party or for purpose prohibited transactions under the
          Foreign Exchange Management Act, 1999 or any rules, regulations or guidelines issued under
          the said act, like remittances for purchase of FCCBs issued by Indian companies in
          overseas secondary markets, remittance for foreign exchange trading abroad, etc. I hereby
          declare that the proposed remittance does not involve, and is not designed for any purpose
          for which the drawal of foreign exchange is prohibited viz travel to Nepal and/or Bhutan,
          a transaction with a Person resident in Nepal or Bhutan and those under Rule 3 and
          transactions which require prior approval of the Central Government under Rule 4 of the
          Foreign Exchange Management (Current Account Transactions) Rules, 2000 read with Schedule
          I and Schedule II thereof viz, I hereby declare and affirm that the remittances
          initiated/requested by me are not Prohibited Transactions or transactions requiring prior
          approval of the central government.
          <br />
          <br />
          <h2 className="font-bold">OTHER DECLARATION</h2>
          <br />
          <br />
          I hereby declare that the purpose of remittance and transaction details as mentioned above
          are true to the best of my knowledge and does not involve, and is not designed for the
          purpose of any contravention or evasion of the provisions of the Foreign Exchange
          Management Act, 1999 or any rule, regulation, notification, direction or order made
          thereunder. I agree that I shall be responsible and liable for any incorrect detail
          provided by me.
          <br />
          <br />
          I also hereby agree and undertake to give such information/documents as will reasonably
          satisfy the company about this transaction in terms of the above declaration. I further
          agree that once the funds remitted by me have been transmitted by company or authorised
          partner to the correspondent and/or Beneficiary Banks, company or authorised partner shall
          not be responsible for any delays in the disbursement of such funds including the
          withholding of such funds by the correspondent and/or Beneficiary Banks.
          <br />
          <br />
          I agree that in the event the transaction gets rejected by the Beneficiary Bank because of
          any incorrect information submitted by me then any charges levied by the Beneficiary Bank
          and/or intermediary bank and exchange loss incurred in this connection can be recovered by
          company or authorised partner from the amount returned by the correspondent bank. I also
          understand that if I refuse to comply with any such requirement or make any unsatisfactory
          compliance therewith, company or authorised partner may refuse to undertake the
          transaction and shall, if it has reason to believe that any contravention/evasion is
          contemplated by me, report that matter to the Reserve Bank of India.
          <br />
          <br />
          I agree that in the event the transaction is cancelled or revoked by me after submitting
          the request for processing to company or authorised partner, which the company or
          authorised partner may accept at its discretion and on a best effort basis, any exchange
          loss incurred in this connection can be debited from the funds paid to company or
          authorised partner for executing the transaction.
          <br />
          <br />
          <h2 className="font-bold">Non exhaustive list of Prohibited transactions:</h2>
          <br />
          <br />
          Remittance out of lottery winnings. Remittance of income from racing/riding etc. or any
          other hobby. Remittance for purchase of lottery tickets, banned/proscribed magazines,
          football pools, sweepstakes, etc. Payment of commission on exports made towards equity
          investment in joint ventures / wholly owned subsidiaries abroad of Indian companies.
          Remittance of dividend by any company to which the requirement of dividend balancing is
          applicable. Payment of commission on exports under Rupee State Credit Route, except
          commission up to 10% of invoice value of exports of tea and tobacco. Payment related to
          "Call Back Services" of telephones. Remittance of interest income on funds held in
          Non-Resident Special Rupee (Account) Scheme.
          <br />
          <br />
          <h2 className="font-bold">
            {" "}
            Transactions requiring prior approval of central government
          </h2>
          <br />
          <h2 className="font-bold"> Cultural tours</h2>
          <br />
          <br />
          Advertisement in foreign print media for purposes other than promotion of tourism, foreign
          investments and international bidding (exceeding USD 10,000) by a State Government and its
          public sector undertakings. Remittance of freight of vessels chartered by a public sector
          undertaking. Payment of import through ocean transport by a Government Department or a
          public sector undertaking on C.F.I basis (i.e. other than F.O.B. and F.A.S basis).
          Multi-modal transport operators making remittance to their agents abroad. Remittance of
          hiring charges of transponders by a) tv channels b) internet service providers. Remittance
          of container detention charges exceeding the rate prescribed by the Director General of
          Shipping. Remittance of prize money/sponsorship of sports activity abroad by a person
          other than international / national / state level sports bodies, if the amount involved
          exceeds USD 100,000. Remittance for membership of P&I Club.
          <br />
          <br />
          <h2 className="font-bold"> About Us</h2>
          <br />
          <br />
          moneyHOP is India's first full stack cross-border neobanking platform, enabling Indian
          millennials to send and spend in
          <br />
          The registered entity to moneyHOP is Unobanc Private Limited, registered in India with GST
          no. 05AACCU3717F1ZM. Unobanc Pvt Ltd is also a FFMC license holder from the RBI with
          license no. KAN 091/FFMC Unobanc Pvt Ltd is a wholly owned subsidiary of Hop Financial
          Solutions in India. The parent company HOP Financial Solution is a UK registered entity.
        </div>
        <div className="sticky right-0 flex items-center justify-end gap-8 p-8 bg-white -bottom-10">
          <Button
            id="tnc-btn"
            bgColor="bg-secondary-600"
            fontStyle={{
              fontFamily: "font-ssp",
              fontSize: "text-base",
              fontWeight: "font-semibold",
              fontColor: "text-white",
            }}
            borderStyle={{
              borderRadius: "rounded",
            }}
            className="py-2 px-9"
            onClickHandler={() => setIsTermsModalOpen(false)}
          >
            I Agree
          </Button>
        </div>
      </div>
    </Modal>
  );
};

export default TermsModal;
