import React from "react";
import clsx from "clsx";

import { IDealCard } from "./DealCard";
import Button from "../Button";

const DealCard: React.FunctionComponent<IDealCard.IProps> = ({
  text,
  onClick,
  bgUrl,
  cardClassname,
  textClassname,
  buttonText,
  buttonTextClassname,
  wholeCardClickable = true,
  cardContentClassname,
  fullWidth = false,
}) => (
  <div className={clsx(fullWidth && "w-screen")}>
    <div
      onClick={() => {
        wholeCardClickable && onClick();
        // navigator.clipboard.writeText(text);
        // window.alert(`Code ${text} copied to clipboard`);
      }}
      style={{ backgroundImage: bgUrl }}
      className={clsx(
        "relative w-[350px] h-[150px] md:w-[362px] md:h-[170px] mx-auto md:mx-none cursor-pointer rounded group-hover:scale-105 transition-all md:rounded-none",
        cardClassname,
      )}
    >
      <div className={clsx("absolute top-[45%] left-5 width-full", cardContentClassname)}>
        <div className={textClassname}>{text}</div>
        <Button
          id="deal-card-btn"
          onClickHandler={onClick}
          className={clsx("bg-transparent absolute", buttonTextClassname)}
        >
          {buttonText}
        </Button>
      </div>
    </div>
  </div>
);

export default DealCard;
