/* eslint-disable */
import React from "react";
import { motion } from "framer-motion";
import { IDocumentUpload } from "./DocumentUpload";
import Modal from "../../../components/ui/Modal";
import clsx from "clsx";
import Typograpy from "../../../components/ui/Typography";
import { text } from "stream/consumers";
import { KYCVerification } from "../../../assets/Icons";
import Checkbox from "../../../components/ui/Checkbox";
import Button from "../../../components/ui/Button";
import { useNavigate } from "react-router-dom";
import mixpanel from "mixpanel-browser";

const DocumentUpload: React.FC<IDocumentUpload.IProps> = ({
  isKycModalOpen,
  setIsKycModalOpen,
}) => {
  const navigate = useNavigate();
  const [isAgree, setIsAgree] = React.useState<boolean>(false);
  const [showKycVerification, setShowKycVerification] = React.useState<boolean>();
  const mixpanelToken: any = process.env.REACT_APP_MIXPANEL_TOKEN;
  mixpanel.init(mixpanelToken, {
    debug: true,
    api_host:process.env.REACT_APP_MIXPANEL_API,
  });
  return (
    <motion.div id="#documentUpload">
      <Modal
        modalClass="!bottom-0 md:!bottom-1/2 !w-full md:!w-fit"
        visible={isKycModalOpen}
        closeHandler={() => {
          setIsKycModalOpen(false);
          setShowKycVerification(false);
        }}
      >
        <div className="flex flex-row justify-between md:w-full">
          <div className="flex flex-col items-center md:items-start md:w-[60%]">
            <Typograpy className="text-[24px] font-bold text-center w-[75%] md:w-full md:text-[32px] pb-2" tagType={"div"}>
              Your one-time document verification is about to begin
            </Typograpy>
            <Typograpy
              className="text-[16px] py-5 text-center w-[75%] md:w-full md:text-start font-bold leading-[22px]"
              tagType={"div"}
            >
              You would require the following documents to complete your document verification
            </Typograpy>
            <div className="flex flex-col items-center md:items-start ml-0 md:ml-5">
              <ol className="list-decimal text-[16px] font-bold leading-[22px]">
                <li>PAN Card</li>
                <li>Aadhaar/DL/Passport/Voter ID</li>
              </ol>
            </div>
            <div className="flex md:hidden">
              <KYCVerification width={"293px"} />
            </div>
            <div className="mt-16 w-[75%] md:w-full">
              <Checkbox
                label={
                  "To make everything go super smoothly, please share clear and east-to-read documents. Just a heads up, by moving forward, you're all set to provide the essential documents for verification authentication."
                }
                value="true"
                name="conditionalNote"
                labelClassName="w-full ml-[26px]"
                checkboxClassname="lg:m-[2px]"
                handleChange={(e) => {
                  setIsAgree(e.target.checked);
                }}
              />
            </div>
            <div className="flex flex-row items-center justify-center mt-10">
              <Button
                id="sfkdj902d987--090489"
                // bgColor={"bg-grey-350"}
                bgColor={isAgree ? "bg-primary" : "bg-grey-350"}
                disabled={!isAgree && location.pathname !== "/inward/user-documents"}
                fontStyle={{
                  fontFamily: "",
                  fontSize: "text-lg",
                  fontWeight: "font-bold",
                  fontColor: "text-white",
                }}
                className="py-[14px] px-[120px] 2xs:w-10"
                borderStyle={{
                  borderWidth: "border-none",
                  borderRadius: "rounded-[100px]",
                }}
                onClickHandler={() => {
                  setIsKycModalOpen(false);
                  // setShowKycVerification(true)
                  mixpanel.track("Confirm KYC button");
                  navigate("/user-documents", {
                    state: {
                      from: "send-money",
                      showKycSidePopUp: false,
                    },
                  });
                }}
              >
                Confirm
              </Button>
            </div>
          </div>
          <div className="hidden md:flex">
            <KYCVerification />
          </div>
        </div>
      </Modal>
    </motion.div>
  );
};

export default DocumentUpload;
