/* eslint-disable no-redeclare */
//#Global Imports
import React, { useState, useEffect } from "react";
//#end Global Imports

//#Local Imports
import Modal from "../../../components/ui/Modal";
import IUserDocuments from "../userDocuments";
import { Document, Page, pdfjs } from "react-pdf";
//#end Local Imports

const UploadedDocPreviewModal: React.FC<IUserDocuments.IUploadedDocPreviewModalProps> = ({
  isModalOpen,
  setIsModalOpen,
  preViewModalHeader,
  // preViewModalButton,
  preViewImageSrc,
  // docType,
}) => {
  const [pageNumber, setPageNumber] = useState(1);
  const [type, setType] = useState("pdf");
  window.console.log("preViewImageSrc",preViewImageSrc);

  useEffect(() => {
    pdfjs.GlobalWorkerOptions.workerSrc = `//cdnjs.cloudflare.com/ajax/libs/pdf.js/${pdfjs.version}/pdf.worker.js`;
    setType("pdf");
  }, [isModalOpen]);

  const onDocumentLoadSuccess = ({ numPages }: any) => {
    setPageNumber(numPages);
    setType("pdf");
  };

  const onDocumentLoadError = ({ name }: any) => {
    if (name == "InvalidPDFException") {
      setType("image");
    }
  };

  return (
    <Modal
      modalClass="!rounded xl:overflow-hidden overflow-y-scroll max-h-[90%] lg:max-h-[100%] !w-full lg:!w-2/3 !p-0"
      header={preViewModalHeader}
      // closeButtonIcon={docType !== "" && preViewModalButton}
      headerClass="mt-2 px-4 lg:!px-12 py-4 lg:!py-7"
      visible={isModalOpen === "preViewUploadedDoc"}
      closeHandler={() => (setIsModalOpen(""))}
    >
<div className="flex items-center justify-center object-contain py-24 bg-white-100">
        <div className="w-1/2 lg:w-1/4">
          {type === "pdf" ? (
            <Document
              file={preViewImageSrc}
              onLoadError={onDocumentLoadError}
              onLoadSuccess={onDocumentLoadSuccess}
            >
              {[...Array(pageNumber)].map((_, index) => (
                <Page key={`page_${index + 1}`} pageNumber={index + 1} />
              ))}
            </Document>
          ) : (
            <img src={preViewImageSrc} alt="Preview" />
          )}
        </div>
      </div>
    </Modal>
  );
};
export default UploadedDocPreviewModal;
