//#Global Imports
import React from "react";
import clsx from "clsx";
//#end Global Imports

//#Local Imports
import ErrorField from "../../../../components/ui/ErrorField";
import TextField from "../../../../components/ui/TextField";
import Typograpy from "../../../../components/ui/Typography";
import Button from "../../../../components/ui/Button";
import Tooltip from "../../../../components/ui/Tooltip";
import {
  InfoIcon,
  PassportIcon,
  ReUploadIcon,
  UploadIcon,
  VisibilityOnIcon,
  WarningIcon,
} from "../../../../assets/Icons";
import IUserDocuments from "../../userDocuments";
import mixpanel from "mixpanel-browser";
//#end Local Imports

const PanCardSection: React.FC<IUserDocuments.IPanCardSectionProps> = ({
  panCardDetails,
  handlePreviewUploadedDoc,
  handleDeleteDoc,
  handleChangeUpload,
  handleEVerifyPanCard,
  isPDFfile,
  setPanCardDetails,
  aadhaarLinked,
  buttonLoader,
  loading
}) => {
  const mixpanelToken: any = process.env.REACT_APP_MIXPANEL_TOKEN;
  mixpanel.init(mixpanelToken, {
    debug: true,
    api_host:process.env.REACT_APP_MIXPANEL_API,
  });
  return (
    <div className="col-span-4 lg:pl-10 lg:pt-7">
      <div>
        <div className="flex items-start justify-between cursor-pointer">
          <Typograpy
            tagType="div"
            className="mb-5 text-base font-medium text-black"
            text="Upload PAN"
          />
          {panCardDetails?.uploadedDocError === "Please upload the right file format.." && (
            <div className="relative cursor-pointer">
              <div className="peer">
                <InfoIcon fillColor="fill-secondary" />
              </div>
              <Tooltip
                title="Your file cant upload"
                subtitle="Try file formats like jpg, png or pdf"
                positionOfTip="bottom"
                tooltipStyles="peer-hover:block hidden !top-8 !min-h-[96px] !min-w-[236px] !-translate-x-full z-1"
                titleClasses="font-semibold text-lg text-white !pt-3"
                subtitleClasses="text-sm font-normal text-white"
                buttonClasses="!w-0 h-0"
              />
            </div>
          )}
        </div>
        <div className="relative">
          <div className="flex items-center space-x-8">
            <Button
              id="dajkk83hdws8-dsj87F"
              bgColor="bg-green-350"
              fontStyle={{
                fontFamily: "",
                fontSize: "text-sm",
                fontWeight: "font-normal ",
                fontColor: "text-black-200",
              }}
              borderStyle={{
                borderRadius: "rounded",
                borderColor: panCardDetails.uploadedDocError
                  ? "border-error border"
                  : panCardDetails.isPanUploaded || panCardDetails.isPanVerified
                  ? "border-success border"
                  : "border-none",
              }}
              className={clsx(
                panCardDetails.uploadedDocError ? "" : "mb-5",
                "w-full lg:w-[48%] h-[68px]",
              )}
            >
              <div className="flex items-center justify-between w-full px-4 cursor-pointer lg:px-6">
                <div className="flex items-center space-x-2 lg:space-x-6">
                  <PassportIcon
                    width="23px"
                    height="29px"
                    fillColor={panCardDetails.isPanVerified ? "fill-success-150" : "fill-primary"}
                  />
                  <div>Upload PAN Front</div>
                </div>
                <div className="flex items-center space-x-4">
                  {panCardDetails.isPanUploaded && !panCardDetails.isPanVerified && !isPDFfile && (
                    <div
                      className="z-50 cursor-pointer"
                      onClick={() => {
                        handlePreviewUploadedDoc("PAN_CARD");
                        mixpanel.track("Upload PAN");
                      }}
                    >
                      <VisibilityOnIcon fillColor="fill-black-600" />
                    </div>
                  )}
                  {panCardDetails.isPanUploaded && !panCardDetails.isPanVerified && (
                    <div className="z-50 cursor-pointer" onClick={handleDeleteDoc}>
                      <ReUploadIcon
                        fillColor={panCardDetails?.uploadedDocError ? "fill-error" : "fill-black"}
                      />
                    </div>
                  )}
                  {!panCardDetails.isPanUploaded && (
                    <UploadIcon
                      fillColor={panCardDetails.isPanVerified ? "fill-success-150" : "fill-primary"}
                    />
                  )}
                </div>
              </div>
            </Button>
          </div>
          {panCardDetails.uploadedDocError !== "" && (
            <ErrorField errorMessage={panCardDetails.uploadedDocError} errorClassname="mb-5" />
          )}
          <form encType="multipart/form-data" action="">
            <TextField
              accept="image/*,application/pdf"
              name="PAN_CARD"
              type="file"
              disabled={panCardDetails.isPanUploaded}
              rootClassName="absolute top-0 opacity-0 !w-full lg:!w-[48%]"
              onChange={(e) => {
                handleChangeUpload(e, "PAN_CARD");
                mixpanel.track("Upload PAN");
              }}
              onClick={(event) => {
                event.currentTarget.value = "";
              }}
            />
          </form>
        </div>
        <Typograpy
          tagType="div"
          className="mb-5 text-base font-medium text-black"
          text="PAN Card No"
        />
        <TextField
          name="panNo"
          type="text"
          inputClasses={clsx(
            panCardDetails.isPanVerified
              ? "border-success border"
              : panCardDetails.messagePan === ""
              ? "border-none"
              : "border-error border",
            `bg-primary-350 !pt-1 lg:!w-[48%]`,
          )}
          value={panCardDetails.panNumber ?? ""}
          errorMessage={panCardDetails.messagePan}
          error={panCardDetails.messagePan !== ""}
          onChange={(event) =>
            setPanCardDetails((preValue) => {
              return { ...preValue, panNumber: event.target.value };
            })
          }
          disabled={!panCardDetails?.isPanUploaded || panCardDetails?.isPanVerified}
          fullWidth
          pattern="[a-zA-Z0-9 ]{1,}"
        />
        {!panCardDetails.isPanVerified && (
          <Button
            id="sdajksdka-sdanj"
            bgColor={clsx(panCardDetails.isPanUploaded ? "bg-primary" : "bg-grey-450")}
            fontStyle={{
              fontFamily: "",
              fontSize: "text-base",
              fontWeight: "font-bold",
              fontColor: panCardDetails.isPanUploaded ? "text-white" : "text-black-650",
            }}
            borderStyle={{
              borderWidth: "border-none",
              borderRadius: "rounded-[100px] lg:rounded",
            }}
            className="py-3 lg:py-5 h-16 w-4/5 m-auto lg:mx-0 lg:!w-[24%] mt-9"
            disabled={!panCardDetails.isPanUploaded}
            onClickHandler={handleEVerifyPanCard}
            isLoading={buttonLoader.panLoader}
          >
            {loading ? (
              <div className="flex items-center justify-center animate-spin-slow py-1">
                <img src="/images/svg/smallLoader.svg" alt="" />
              </div>
            ) : (
              "Verify"
            )}
          </Button>
        )}
      </div>

      {!aadhaarLinked && (
        <div className="bg-[#FFFAE6] p-4 flex items-start mt-[5%]">
          <div className="text-yellow-400 mr-4 mt-1">
            <WarningIcon />
          </div>

          <div>
            <h3 className="font-bold text-black">PAN and Aadhar not linked</h3>
            <p className="text-black mt-3">
              You can initiate your transaction, but it will be put on hold. We will wait for 3
              working days for you to get this linked, post that the transaction will get cancelled
              and the amount will be refunded.&nbsp;
              <a
                href="https://www.moneyhop.co/blog/all-you-need-to-know-about-pan-aadhar-linking"
                target="_blank"
                rel="noopener noreferrer"
                style={{ color: "#156BFF", textDecoration: "none", cursor: "pointer" }}
              >
                Know more
              </a>
            </p>
          </div>
        </div>
      )}
    </div>
  );
};

export default PanCardSection;
