import React from "react";
import { IIcons } from "./icons";

const LockIcon = ({ fillColor }: IIcons.IconProps) => (
  <svg width="16" height="20" viewBox="0 0 16 20" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      d="M8 0C5.34062 0 3.2 2.05829 3.2 4.61538V7.69231C1.44062 7.69231 0 9.07752 0 10.7692V16.9231C0 18.6148 1.44062 20 3.2 20H12.8C14.5594 20 16 18.6148 16 16.9231V10.7692C16 9.07752 14.5594 7.69231 12.8 7.69231V4.61538C12.8 2.05829 10.6594 0 8 0ZM8 1.53846C9.82188 1.53846 11.2 2.86358 11.2 4.61538V7.69231H4.8V4.61538C4.8 2.86358 6.17812 1.53846 8 1.53846ZM8 11.5385C8.88125 11.5385 9.6 12.2296 9.6 13.0769C9.6 13.6148 9.28125 14.1436 8.8 14.375V16.1538C8.8 16.6166 8.48125 16.9231 8 16.9231C7.51875 16.9231 7.2 16.6166 7.2 16.1538V14.375C6.71875 14.1436 6.4 13.6148 6.4 13.0769C6.4 12.2296 7.11875 11.5385 8 11.5385Z"
      fill="#9C9C9C"
      className={fillColor}
    />
  </svg>
);
export default LockIcon;
