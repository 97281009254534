import React from "react";
import clsx from "clsx";
import { IStepper } from "./Stepper";

const Stepper: React.FunctionComponent<IStepper.IProps> = ({
  numberOfSteps,
  steps,
  activeStep,
  inactiveProgressbarClassname,
  activeProgressbarClassname,
  inactivePointsClassname,
  activePointsClassname,
}) => {
  return (
    <div
      id="progressbar"
      className={clsx(
        "relative flex justify-between items-center before:content-[''] before:absolute before:top-1/2 before:-scale-y-50 before:h-1 before:w-full before:bg-blue-300 before:z-10 before:-mt-0.5",
        inactiveProgressbarClassname,
      )}
    >
      <div
        id="progress"
        style={{ width: `${(100 / (numberOfSteps - 1)) * (activeStep - 1)}%` }}
        className={clsx(
          "content-[''] absolute top-1/2 -scale-y-50 h-1 bg-yellow-300 z-10 -mt-0.5",
          activeProgressbarClassname,
        )}
      ></div>
      {steps.map((step, idx) => {
        return idx >= activeStep ? (
          <div
            id="progress-step"
            className={clsx(
              "relative w-5 h-5 rounded-2xl after:content-[attr(data-title)] after:w-max after:absolute after:text-xs after:top-[calc(100%_+_10px)] after:-left-1/2 bg-primary-750 text-primary-750 z-20 font-normal text-sm",
              inactivePointsClassname,
            )}
            data-title={step}
          />
        ) : (
          <div
            id="progress-step"
            className={clsx(
              "relative w-3 h-3 rounded-2xl after:content-[attr(data-title)] after:w-max after:absolute after:text-xs after:top-[calc(100%_+_10px)] after:-left-3/4 bg-white shadow-[0_0px_0px_5px_rgba(255,240,1)] z-20 text-primary-900 font-normal text-sm",
              activePointsClassname,
            )}
            data-title={step}
          />
        );
      })}
    </div>
  );
};

export default Stepper;
