/* eslint-disable */
import React, { FunctionComponent } from "react";
import Modal from "../../../components/ui/Modal";
import { remmitanceFrequencyArray } from "../../../utils";
import { sourceOfFundArray } from "../../../utils";
import InwardDocumentProps from "./userDocument";

export const Modals: FunctionComponent<InwardDocumentProps.Modals> = ({
  isModalOpenP,
  setIsModalOpenP,
  sourceOfFundP,
  setSourceOfFundP,
  remmitanceFrequencyP,
  setRemmitanceFrequencyP,
}) => {
  return (
    <div>
      {/* SourceOfFund */}
      <Modal
        header="Select Source Of Fund"
        headerClass="flex justify-center font-bold text-[18px] bg-primary text-white !mt-0 !p-7 !w-full"
        modalClass="!w-full lg:!w-1/3 xl:!w-1/5 !p-0 max-h-[600px] max-w-[500px] overflow-x-hidden"
        visible={isModalOpenP === "sourceOfFund"}
        closeHandler={() => setIsModalOpenP("")}
      >
        <div>
          <div>
            <div>
              <ol className="flex flex-col justify-center px-8 my-10 space-y-8 over">
                {sourceOfFundArray?.map((item, index) => {
                  return (
                    <div
                      key={index}
                      onClick={() => {
                        setSourceOfFundP(item);
                        setIsModalOpenP("");
                      }}
                      className="pb-3 z-50 font-medium border-b-2 cursor-pointer hover:font-bold hover:text-[18px] transition-all"
                    >
                      {item}
                    </div>
                  );
                })}
              </ol>
            </div>
          </div>
        </div>
      </Modal>
      <Modal
        header="Select Remittance Frequency"
        headerClass="flex justify-center font-bold text-[18px] bg-primary text-white !mt-0 !p-7 !w-full"
        modalClass="!w-full lg:!w-1/3 xl:!w-1/5 !p-0 max-h-[600px] max-w-[500px] overflow-hidden"
        visible={isModalOpenP === "remmitanceFrequency"}
        closeHandler={() => setIsModalOpenP("")}
      >
        <div>
          <div>
            <div>
              <ol className="flex flex-col justify-center px-8 my-10 space-y-8 over">
                {remmitanceFrequencyArray?.map((item, index) => {
                  return (
                    <div
                      key={index}
                      onClick={() => {
                        setRemmitanceFrequencyP(item);
                        setIsModalOpenP("");
                      }}
                      className="pb-3 z-50 font-medium border-b-2 cursor-pointer hover:font-bold hover:text-[18px] transition-all"
                    >
                      {item}
                    </div>
                  );
                })}
              </ol>
            </div>
          </div>
        </div>
      </Modal>
    </div>
  );
};
