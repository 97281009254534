import React, { useEffect, useState } from "react";
import GoBackandStepperSection from "./GoBackandStepper";
import { findNetBankingName, formatNumber, stepperSteps } from "../../../utils";
import { useLocation } from "react-router-dom";
import "./style.css";
import {
  bankDownTime,
  getBankNames,
  getPersonalAccountDetails,
  pennyDropAPI,
  razorPayResponse,
  requestRazor,
} from "../../../services/payments";
import { Modals } from "../Modals";
import { customAlert } from "../../../utils/customAlert";
import { useRecoilValue } from "recoil";
import { userDetails } from "../../../store";
import { UserDetails } from "../../../store/store";
import mixpanel from "mixpanel-browser";
import ErrorField from "../../../components/ui/ErrorField";

const PaymentMethod = () => {
  const [bankDetails, setBankDetails] = useState<any>([]);
  const [loading, setLoading] = useState<boolean>(false);
  const [isModalOpen, setIsModalOpen] = useState<string>("");
  const [bankList, setBankList] = useState<any>([]);
  const [bankName, setBankName] = useState<string>("");
  const userData: UserDetails = useRecoilValue(userDetails);
  const [netBankError, setNetBankError] = useState<string>("");
  const [acNumber, setAcNumber] = useState<string>("");
  const [pennyError, setPennyError] = useState<string>("");
  const [pennyDropID, setPennyDropID] = useState<string>("");
  const [ifsc, setIfsc] = useState<string>("");
  const [showLoader, setShowLoader] = useState(false);
  // const [isRazorpay, setIsRazorpay] = useState<boolean>(true);
  const [downNetBanks, setDownNetBanks] = useState<any>([]);
  const [cashfreeBackdrop, setCashfreeBackdrop] = useState<boolean>(false);
  const [pennyLoading, setPennyLoading] = useState<boolean>(false);
  const { state } = useLocation();
  const { transactionData } = state;
  const [netbankList, setNetbankList] = useState<any>();
  const currentUser = JSON.parse(sessionStorage.getItem("currentUser") || "{}");
  const localTransactionId: any = sessionStorage.getItem("transactionId");
  const [downVpas, setDownVpas] = useState<any>([]);
  const [bankId, SetBankID] = useState<any>("");
  const [selectedBank, setSelectedBank] = useState<any>("");
  const [, setIsRazorpay] = useState<boolean>(true);

  const findBankId = (value: any) => {
    const Id = bankList.find((bank: any) => bank?.bankCode === value);
    if (Id) {
      SetBankID(Id?.bankId);
    }
  };

  useEffect(() => {
    findBankId(bankName);
  }, [bankName]);

  const getBankList = () => {
    getBankNames(currentUser)
      .then((response: any) => {
        setBankList(response?.data?.banks);
      })
      .catch((error) => {
        setNetBankError(error?.response?.data?.error?.type);
      });
  };

  useEffect(() => {
    getBankList();
  }, []);

  const handleBankDownTime = () => {
    bankDownTime().then((res: any) => {
      setDownNetBanks(res?.data?.banks);
      setDownVpas(res?.data?.vpas);
    });
  };

  const handleClick = (data: any) => {
    if (data) {
      const { AccountNumber, Ifsc } = data;
      setAcNumber(AccountNumber);
      setIfsc(Ifsc);
      setSelectedBank(data?.index);
      setPennyDropID(data?.pennyDropId);
      setBankName(data?.bankCode);
      // handleNetBanking();
    }
  };

  const handleAddNewBank = () => {
    if (acNumber) {
      setAcNumber("");
      setIfsc("");
      setSelectedBank("");
      setPennyDropID("");
      setBankName("");
      handleBankDownTime();
      setIsModalOpen("netBanking");
      mixpanel.track("Payment mode_Net Banking");
    } else {
      handleBankDownTime();
      setIsModalOpen("netBanking");
      mixpanel.track("Payment mode_Net Banking");
      // handleAddNetBanking();
    }
  };

  const getBankDetails = () => {
    setLoading(true);
    getPersonalAccountDetails(currentUser)
      .then((res) => {
        if (res?.data?.AccountDetails?.length > 0) {
          setBankDetails(res?.data?.AccountDetails);
          setLoading(false);
        } else {
          setBankDetails([]);
          setLoading(false);
          handleAddNewBank();
        }
      })
      .catch((err) => {
        window.console.error("Error", err);
        setLoading(false);
      });
  };

  const handleNetBanking = () => {
    mixpanel.track("Payment mode_Net Banking Bank_select", {
      "Account Number": acNumber,
      IFSC: ifsc,
      "Payment Mode": "NET_BANKING",
    });
    // setPennyLoading(true);
    setShowLoader(true);
    const body = {
      accNum: acNumber,
      ifsc: ifsc,
      paymentMode: "NET_BANKING",
      transactionID: transactionData?.id_transaction
        ? transactionData?.id_transaction
        : localTransactionId,
      userId: 0,
      userKey: currentUser.id,
      bankId: bankId || "",
      pennyDropId: pennyDropID,
    };
    requestRazor(body)
      .then((response) => {
        if (response?.data?.openRazorpay === false) {
          setIsRazorpay(false);
          setIsModalOpen("");
          window.console.log('response by razorpay1', response);
          window.console.log('bankID1', bankId);
          window.console.log('bankName1', bankName);

          // const cashfree: any = new window.Cashfree(response?.data?.order);
          // cashfree.drop(document.getElementById("payment-form"), dropinConfig);
          // cashfree.redirect();
          const cashfree = window.Cashfree({
            mode: `${process.env.REACT_APP_CASHFREE_ENV}`, //or production
          });
          const bank = findNetBankingName(bankName);
          window.console.log('bank1', bank);
          const netbanking = cashfree.create("netbanking", {
            values: {
              netbankingBankName: bank,
            },
          });
          netbanking.on("loaderror", function (data: any) {
            window.console.error("Error", data);
          });
          netbanking.mount("#payment-form");
          const paymentOptions = {
            paymentMethod: netbanking,
            paymentSessionId: response?.data?.order,
            // returnUrl: "https://test.cashfree.com/pgappsdemos/v3success.php?myorder={order_id}",
          };
          netbanking.on("ready", function () {
            cashfree
              .pay(paymentOptions)
              .then(function () {
                setCashfreeBackdrop(false);
              })
              .catch((error: any) => customAlert(error?.message));
          });
          setCashfreeBackdrop(true);
        } else {
          const data = {
            key_id: response?.data?.key,
            amount: response?.data?.amount,
            order_id: response?.data?.orderID,
            currency: "INR",
            email: userData?.email ?? "user@email.com",
            // email: "lmfao@lmao.com",
            contact: userData?.phoneNumber,
            method: "netbanking",
            bank: bankName,
          };
          openRazorpayWindow(data);
        }
      })
      .catch((err) => {
        setPennyError(err?.response?.data?.error?.type);
      })
      .finally(() => {
        setShowLoader(false);
        setPennyLoading(false);
      });
  };

  const handleAddNetBanking = () => {
    setPennyLoading(true);
    const body = {
      cusAcc: acNumber,
      ifscCode: ifsc,
      isVpa: false,
      transactionID: transactionData?.id_transaction
        ? transactionData?.id_transaction
        : localTransactionId,
      userId: 0,
      userKey: currentUser.id,
      vpa: "",
      saveAccount: true,
      bankId: bankId,
    };
    pennyDropAPI(body).then((response) => {
      window.console.log(response, "response");
      setPennyLoading(false);
      const body = {
        accNum: acNumber,
        ifsc: ifsc,
        paymentMode: "NET_BANKING",
        transactionID: transactionData?.id_transaction
          ? transactionData?.id_transaction
          : localTransactionId,
        userId: 0,
        bankId: bankId || "",
        userKey: currentUser.id,
      };
      requestRazor(body)
        .then((response) => {
          if (response?.data?.openRazorpay === false) {
            setIsRazorpay(false);
            setIsModalOpen("");
            window.console.log('response by razorpay2', response);
            window.console.log('bankID2', bankId);
            window.console.log('bankName2', bankName);

            // const cashfree: any = new window.Cashfree(response?.data?.order);
            // cashfree.drop(document.getElementById("payment-form"), dropinConfig);
            // cashfree.redirect();
            const cashfree = window.Cashfree({
              mode: `${process.env.REACT_APP_CASHFREE_ENV}`, //or production
            });
            const bank = findNetBankingName(bankName);
            window.console.log('bank2', bank);
            const netbanking = cashfree.create("netbanking", {
              values: {
                netbankingBankName: bank,
              },
            });
            netbanking.on("loaderror", function (data: any) {
              window.console.error("Error", data);
            });
            netbanking.mount("#payment-form");
            const paymentOptions = {
              paymentMethod: netbanking,
              paymentSessionId: response?.data?.order,
              // returnUrl: "https://test.cashfree.com/pgappsdemos/v3success.php?myorder={order_id}",
            };
            netbanking.on("ready", function () {
              cashfree
                .pay(paymentOptions)
                .then(function () {
                  setCashfreeBackdrop(false);
                })
                .catch((error: any) => customAlert(error?.message));
            });
            setCashfreeBackdrop(true);
          } else {
            const data = {
              key_id: response?.data?.key,
              amount: response?.data?.amount,
              order_id: response?.data?.orderID,
              currency: "INR",
              email: userData?.email ?? "user@email.com",
              // email: "lmfao@lmao.com",
              contact: userData?.phoneNumber,
              method: "netbanking",
              bank: bankName,
            };
            openRazorpayWindow(data);
          }
        })
        .catch((err) => {
          setPennyError(err?.response?.data?.error?.type);
        })
        .finally(() => {
          setShowLoader(false);
          setPennyLoading(false);
        });
    });
  };

  const openRazorpayWindow = (data: any) => {
    const iframe = document.createElement("iframe");
    iframe.setAttribute("id", "razorpayFrame");
    iframe.style.display = "none";
    document.body.appendChild(iframe);

    const script = document.createElement("script");
    script.src = "https://checkout.razorpay.com/v1/razorpay.js";
    script.async = true;
    script.onload = () => {
      const nbInstance = new window.Razorpay({
        // key: "rzp_live_68nZN0xjIPxJjI",
        key: `${process.env.REACT_APP_RAZORPAY_KEY}`,
        image: "https://i.imgur.com/n5tjHFD.png",
      });
      const instance = new window.Razorpay({
        key: `${process.env.REACT_APP_RAZORPAY_KEY_NETBANKING}`,
        // key: "rzp_live_XxICSungzwAMpM",
        image: "https://i.imgur.com/n5tjHFD.png",
      });
      instance.once("ready", function (response: any) {
        setNetbankList(response?.methods?.netbanking);
      });
      const dataArray = [];
      for (const code in netbankList) {
        dataArray.push({ code: code, name: netbankList[code] });
      }
      //netbanking initialisation
      if (data.method === "netbanking") {
        nbInstance.createPayment(data);
        nbInstance.on("payment.success", (resp: any) => {
          const data = {
            razorpay_order_id: resp.razorpay_order_id ?? "",
            razorpay_payment_id: resp.razorpay_payment_id ?? "",
            razorpay_signature: resp.razorpay_signature ?? "",
            isRazorPay: true,
          };
          razorPayResponse(data)
            .then((response: any) => {
              if (response.status === 200) {
                sessionStorage.setItem("matchingUrl", response.data.redirectUrl);
                window.location.href = "/confirmation";
                mixpanel.track("Payment Successful");
              }
            })
            .catch((err: any) => {
              console.error(err, "razorPayResponseError");
            })
            .finally(() => {
              setShowLoader(false);
            });
        });

        nbInstance.on("payment.error", (resp: any) => {
          alert(resp.error.description);
          setIsModalOpen("");
        });
      } else {
        instance.createPayment(data);
        instance.on("payment.success", (resp: any) => {
          const data = {
            razorpay_order_id: resp.razorpay_order_id ?? "",
            razorpay_payment_id: resp.razorpay_payment_id ?? "",
            razorpay_signature: resp.razorpay_signature ?? "",
            isRazorPay: true,
          };
          razorPayResponse(data)
            .then((response: any) => {
              if (response.status === 200) {
                sessionStorage.setItem("matchingUrl", response.data.redirectUrl);
                window.location.href = "/confirmation";
                mixpanel.track("Payment Successful");
              }
            })
            .catch((err: any) => {
              console.error(err, "razorPayResponseError");
            })
            .finally(() => {
              setShowLoader(false);
            });
        });

        instance.on("payment.error", (resp: any) => {
          alert(resp.error.description);
          setIsModalOpen("");
        });
      }
    };
    document.body.appendChild(script);
  };

  useEffect(() => getBankDetails(), []);
  return (
    <div>
      <GoBackandStepperSection setIsModalOpen={undefined} stepperSteps={stepperSteps} />
      <div id="youPay header" className="flex flex-col items-center pt-3">
        <span className="text-[35px] md:text-[28px] sm:text-[40px] font-semibold space-x-4">
          You Pay{" "}
          <span className="mr-1">
            {transactionData?.isAdvancePay
              ? formatNumber(transactionData?.advance_pay)
              : formatNumber(transactionData?.total_pay)}
          </span>
          {transactionData?.base_currency}
        </span>
      </div>
      <div className="w-full my-16 px-12">
        <div className="w-full h-0.5 bg-primary-200" />
      </div>
      {loading ? (
        <div className="flex w-full justify-center text-center">
          <span>Loading Banking Details...</span>
        </div>
      ) : (
        <div className="w-full mt-4">
          <h3 className="text-center font-semibold text-xl">Select your account</h3>
          <div className="w-full flex items-center justify-center my-4">
            <div className="xl:w-2/5 lg:w-3/5 md:4/5 sm:w-full w-full xl:p-8 lg:p-6 md:p-5 sm:p-4 p-4 rounded-md bg-white">
              <h4 className="font-semibold text-xl">Net Banking</h4>
              <div className="my-3 max-h-[450px] overflow-y-scroll">
                {/* net banking details */}
                {bankDetails?.length > 0 ? (
                  bankDetails?.map((bank: any, index: number) => (
                    <div
                      key={index}
                      className={`border cursor-pointer xl:p-6 lg:p-5 md:p-4 sm:p-3 p-3 my-4 mr-4 rounded-md flex justify-start items-center ${acNumber && selectedBank === index
                        ? "border-primary-300"
                        : "border-[#E3E3E3]"
                        }`}
                      onClick={() => {
                        const newData = { ...bank, index };
                        handleClick(newData);
                      }}
                    >
                      <div className="w-16 h-16">
                        <img src={bank?.logo} className="w-full h-full object-contain" />
                      </div>
                      <div className="w-full flex justify-center flex-col pl-4 ">
                        <div className="w-full flex justify-between">
                          <h3 className="text-2xl font-bold">{bank?.name}</h3>
                          <h3 className="xl:text-2xl xl:font-semibold text-xl font-medium text-[#37C820]">
                            Verified
                          </h3>
                        </div>
                        <div className="w-full lg:flex-row xl:flex-row md:flex-row sm:flex-col flex-col flex py-0.5 text-[16px]">
                          <span className="text-xl flex pr-5 text-black-100 font-bold ">
                            A/c no.:&nbsp;<span className="font-normal">{bank?.AccountNumber}</span>
                          </span>
                          <span className="text-xl flex text-black-100 font-bold ">
                            IFSC Code&nbsp;:<span className="font-normal">&nbsp;{bank?.Ifsc}</span>
                          </span>
                        </div>
                      </div>
                    </div>
                  ))
                ) : (
                  <div className="w-full flex justify-center items-center text-center">
                    <span>No Bank Found please add..!</span>
                  </div>
                )}
              </div>
            </div>
          </div>
          {/* add new account button */}
          <div className="flex justify-center mt-4 w-full items-center">
            <button onClick={() => handleAddNewBank()}>
              <span className="text-primary-200 text-xl">+ &nbsp;Add new account</span>
            </button>
          </div>
          <div>
            {bankDetails?.length > 0 && (
              <div
              onClick={() => {
                !showLoader ? acNumber && handleNetBanking() : null;
              }}
               className="flex justify-center w-full rounded-lg my-2">
                <div className="flex hover:cursor-pointer items-center w-1/3 h-full rounded-lg justify-evenly bg-primary">
                  <div
                    
                    className="py-3 font-bold text-white rounded-lg cursor-pointer justify-evenly bg-primary text-[18px]"
                  // value={`Pay`}
                  >
                    {showLoader ? (
                      <div className="flex items-center justify-center animate-spin-slow py-3">
                        <img src="/images/svg/smallLoader.svg" alt="" />
                      </div>
                    ) : !acNumber ? (
                      "Choose Bank"
                    ) : (
                      "Pay"
                    )}
                  </div>
                </div>
              </div>
            )}
            <div className="font-bold text-blue-500 cursor-pointer flex flex-col items-center">
              {pennyError && (
                <ErrorField errorMessage={pennyError} errorClassname="self-center font-normal" />
              )}
              {netBankError && (
                <ErrorField errorMessage={netBankError} errorClassname="self-center font-normal" />
              )}
            </div>
            <div
            style={{
              position: "fixed",
              left: "0px",
              top: "0px",
              height: "100vh",
              width: "100vw",
              backgroundColor: "rgba(0,0,0,0.5)",
              display: cashfreeBackdrop ? "block" : "none",
            }}
          >
            <div className="flex items-center justify-center w-full h-full">
              <div id="payment-form" className="w-[500px] h-[500px] z-50"></div>
            </div>
          </div>
          </div>
        </div>
      )}
      <Modals
        setIsModalOpen={setIsModalOpen}
        isModalOpen={isModalOpen}
        bankList={bankList}
        bankName={bankName}
        setBankName={setBankName}
        acNumber={acNumber}
        setAcNumber={setAcNumber}
        ifsc={ifsc}
        setIfsc={setIfsc}
        handleNetBanking={handleAddNetBanking}
        pennyLoading={pennyLoading}
        downNetBanks={downNetBanks}
        setNetBankError={setNetBankError}
        setPennyError={setPennyError}
        downVpas={downVpas}
        totalPay={transactionData?.total_pay}
        pennyError={pennyError}
        netBankError={netBankError}
      />
    </div>
  );
};

export default PaymentMethod;
