import React, { FunctionComponent, useState } from "react";
import { countryOfResidence, relationShips, TransactioncancellationReasons } from "../../utils";
import Modal from "../../components/ui/Modal";
import RecipientProps from "./Recipient";
import Button from "../../components/ui/Button";
import Checkbox from "../../components/ui/Checkbox";
import { useNavigate } from "react-router-dom";
import TextField from "../../components/ui/TextField";
import { SearchIcon } from "../../assets/Icons";
import clsx from "clsx";
import { handleTransactionComplete } from "../../services/transaction";
import { transactionDetails } from "../../store";
import { useResetRecoilState } from "recoil";

export const Modals: FunctionComponent<RecipientProps.Modals> = ({
  setIsModalOpen,
  isModalOpen,
  setRelationship,
  // setCategory,
  // receivingCountryModalData,
  setCountry,
  setBankCountry,
  setCancellationReasons,
  cancellationReasons,
  otherReason,
  setOtherReason,
  countryData,
  setCountryData,
}) => {
  const navigate = useNavigate();
  const [searchInput, setSearchInput] = useState("");
  const resetTransactionDetails = useResetRecoilState(transactionDetails);
  const specialCharRegExp = "[a-zA-Z0-9 ]{1,}";
  React.useEffect(() => {
    const countryList = countryOfResidence;
    let tempdata = [...countryList];
    if (searchInput) {
      tempdata = tempdata?.filter((data: any) =>
        data?.toLowerCase()?.includes(searchInput?.toLowerCase()),
      );
    }
    setCountryData(tempdata);
  }, [searchInput]);

  const handleNavigate = () => {
    handleTransactionComplete();
    resetTransactionDetails();
    navigate("/");
  };

  return (
    <div>
      {/* Relationship Modal */}
      <Modal
        header="You are sending money to your"
        headerClass="flex justify-center font-bold text-[18px] bg-primary text-white !mt-0 !p-7 !w-full"
        modalClass="!w-full lg:!w-1/3 xl:!w-1/5 !p-0"
        visible={isModalOpen === "relationship"}
        closeHandler={() => setIsModalOpen("")}
      >
        <div>
          <div>
            <div>
              <ol className="flex flex-col justify-center px-8 my-10 space-y-8">
                {relationShips?.map((item, index) => {
                  return (
                    <li
                      key={index}
                      onClick={() => {
                        setRelationship(item);
                        setIsModalOpen("");
                      }}
                      className="pb-3 font-medium border-b-2 cursor-pointer hover:font-bold hover:text-[18px] transition-all"
                    >
                      {item}
                    </li>
                  );
                })}
              </ol>
            </div>
          </div>
        </div>
      </Modal>

      {/* Recipient Category */}

      {/* <Modal
        header="Select Recipient Category"
        headerClass="flex justify-center font-bold text-[18px] bg-primary text-white !mt-0 !p-7 !w-full"
        modalClass="!w-full lg:!w-1/3 xl:!w-1/5 !p-0"
        visible={isModalOpen === "category"}
        closeHandler={() => setIsModalOpen("")}
      >
        <div>
          <div>
            <div>
              <ol className="flex flex-col justify-center px-8 my-10 space-y-8">
                <li
                  onClick={() => {
                    setCategory("Individual");
                    setIsModalOpen("");
                  }}
                  className="pb-3 font-medium border-b-2 cursor-pointer"
                >
                  Individual
                </li>
                <li
                  onClick={() => {
                    setCategory("Institution");
                    setIsModalOpen("");
                  }}
                  className="pb-3 font-medium cursor-pointer"
                >
                  Institution
                </li>
              </ol>
            </div>
          </div>
        </div>
      </Modal> */}

      {/* Country of Residence Modal */}

      <Modal
        header="Country of Origin"
        headerClass={"bg-primary !my-0 py-6 pr-6 lg:pl-12 pl-[30px] text-xl font-medium text-white"}
        modalClass="!p-0 !rounded-t-[10px] overflow-y-scroll max-h-[90%] !w-full lg:!w-auto"
        visible={isModalOpen === "country"}
        closeHandler={() => {
          setIsModalOpen("");
          setSearchInput("");
        }}
      >
        <div className="flex flex-col items-center px-2 pt-4 lg:px-9">
          <div className="h-12 lg:border-none w-full flex justify-center lg:w-[404px] mb-9">
            <TextField
              name="searchValue"
              type="text"
              placeholder={
                <div className="flex items-center justify-between gap-2 mt-3">
                  <span>Search by country or currency</span>
                  <SearchIcon fillColor="fill-primary" />
                </div>
              }
              value={searchInput}
              fullWidth
              inputClasses="lg:!rounded-md h-12 !pt-1 bg-primary-100 w-full"
              placeholderStyle={clsx(
                searchInput && "hidden",
                "peer-placeholder-shown:!top-4 text-sm text-gray-600 peer-focus:hidden w-full px-6",
              )}
              onChange={(e) => setSearchInput(e.target.value)}
              pattern={specialCharRegExp}
            />
          </div>
          <div className="lg:mx-[68px] overflow-y-auto">
            {countryData?.length === 0 ? (
              <div className="pb-64 text-xl font-normal text-center text-primary-650">
                {`"${searchInput}" cannot be found`}
              </div>
            ) : (
              countryData.map((data: any, index: any) => {
                return (
                  <div
                    className="flex items-center justify-between w-full py-6 transition-all border-b cursor-pointer lg:mb-11 lg:border-none lg:py-0 border-grey-1000"
                    key={index}
                    onClick={() => {
                      setCountry(data);
                      setIsModalOpen("");
                    }}
                  >
                    <div className="mr-8 text-[18px] hover:text-[20px] transition-all hover:font-bold font-normal text-primary-650">
                      {data}
                    </div>
                  </div>
                );
              })
            )}
          </div>
        </div>
      </Modal>

      {/* Country of Residence Modal */}

      <Modal
        header="Country of Origin"
        headerClass={"bg-primary !my-0 py-6 pr-6 lg:pl-12 pl-[30px] text-xl font-medium text-white"}
        modalClass="!p-0 !rounded-t-[10px] overflow-y-scroll flex flex-col max-h-[90%] !w-full lg:!w-auto"
        visible={isModalOpen === "bankCountry"}
        closeHandler={() => setIsModalOpen("")}
      >
        <div className="px-6 pt-4 lg:px-9">
          <div className="h-12 lg:border-none w-full flex justify-center lg:w-[404px] mb-9">
            <TextField
              name="searchValue"
              type="text"
              placeholder={
                <div className="flex items-center justify-between gap-2">
                  <span>Search by country or currency</span>
                  <SearchIcon fillColor="fill-primary" />
                </div>
              }
              value={searchInput}
              fullWidth
              inputClasses="lg:!rounded-md h-12 !pt-1 bg-primary-100 w-full"
              placeholderStyle={clsx(
                searchInput && "hidden",
                "peer-placeholder-shown:!top-4 text-sm text-gray-600 peer-focus:hidden w-full px-6",
              )}
              onChange={(e) => setSearchInput(e.target.value)}
              pattern={specialCharRegExp}
            />
          </div>
          <div className="lg:mx-[68px] overflow-y-auto">
            {countryData?.length === 0 ? (
              <div className="pb-64 text-xl font-normal text-center text-primary-650">
                {`"${searchInput}" cannot be found`}
              </div>
            ) : (
              countryData.map((data: any, index: any) => {
                return (
                  <div
                    className="flex items-center justify-between w-full py-6 border-b cursor-pointer lg:mb-11 lg:border-none lg:py-0 border-grey-1000"
                    key={index}
                    onClick={() => {
                      setBankCountry(data);
                      setIsModalOpen("");
                    }}
                  >
                    <div className="mr-8 text-[18px] hover:text-[20px] hover:font-bold transition-all font-normal text-primary-650">
                      {data}
                    </div>
                  </div>
                );
              })
            )}
          </div>
        </div>
      </Modal>
      <Modal
        header="Reason for cancellation"
        headerClass={"bg-white !my-0 py-6 px-12 font-bold text-lg"}
        modalClass="!p-0 !rounded !overflow-hidden !w-full lg:!w-auto"
        visible={isModalOpen === "cancelReasons"}
        closeHandler={() => setIsModalOpen("")}
        closable={false}
      >
        <div className="px-5 pb-5">
          {TransactioncancellationReasons.map((data, index) => {
            return (
              <div className="space-x-2 px-7 border-b-[1px] py-6 first:pt-0" key={index}>
                <Checkbox
                  label={data}
                  value={data}
                  name={"cancellationReason"}
                  handleChange={(e) => {
                    setCancellationReasons(e.target.value);
                  }}
                />
              </div>
            );
          })}
          {cancellationReasons === "Other Reason..." && (
            <div className="flex items-center justify-center mt-2">
              <textarea
                name="otherReasonDescription"
                value={otherReason}
                rows={4}
                cols={50}
                placeholder="Write your reason here....."
                onChange={(e) => {
                  setOtherReason(e.target.value);
                }}
                className="w-full p-3 bg-white-100 focus-visible:border-0 placeholder:text-center"
              />
            </div>
          )}
          <div className="flex items-center justify-center mt-7 px-[78px]">
            <Button
              id="receipient-modal-brn"
              bgColor={`${cancellationReasons === "" ? "bg-grey-350" : "bg-primary"}`}
              fontStyle={{
                fontFamily: "",
                fontSize: "text-lg",
                fontWeight: "font-bold",
                fontColor: "text-white",
              }}
              borderStyle={{
                borderWidth: "border-none",
                borderRadius: "rounded-[100px]",
              }}
              disabled={cancellationReasons === ""}
              className={"py-3 px-28"}
              onClickHandler={() =>
                cancellationReasons !== "" && cancellationReasons === "Other Reason..."
                  ? otherReason !== "" && handleNavigate()
                  : handleNavigate()
              }
            >
              Submit
            </Button>
          </div>
        </div>
      </Modal>
    </div>
  );
};
