import React from "react";
import { IIcons } from "./icons";

const Upi = ({ fillColor }: IIcons.IconProps) => (
  <svg
    width="66"
    height="66"
    viewBox="0 0 66 66"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    className={fillColor}
  >
    <path
      d="M8.8125 0.75C4.39156 0.75 0.75 4.39156 0.75 8.8125V21.3542C0.75 25.7751 4.39156 29.4167 8.8125 29.4167H21.3542C25.7751 29.4167 29.4167 25.7751 29.4167 21.3542V8.8125C29.4167 4.39156 25.7751 0.75 21.3542 0.75H8.8125ZM44.6458 0.75C40.2249 0.75 36.5833 4.39156 36.5833 8.8125V21.3542C36.5833 25.7751 40.2249 29.4167 44.6458 29.4167H57.1875C61.6084 29.4167 65.25 25.7751 65.25 21.3542V8.8125C65.25 4.39156 61.6084 0.75 57.1875 0.75H44.6458ZM8.8125 6.125H21.3542C22.8708 6.125 24.0417 7.29586 24.0417 8.8125V21.3542C24.0417 22.8708 22.8708 24.0417 21.3542 24.0417H8.8125C7.29586 24.0417 6.125 22.8708 6.125 21.3542V8.8125C6.125 7.29586 7.29586 6.125 8.8125 6.125ZM44.6458 6.125H57.1875C58.7041 6.125 59.875 7.29586 59.875 8.8125V21.3542C59.875 22.8708 58.7041 24.0417 57.1875 24.0417H44.6458C43.1292 24.0417 41.9583 22.8708 41.9583 21.3542V8.8125C41.9583 7.29586 43.1292 6.125 44.6458 6.125ZM11.5 11.5V18.6667H18.6667V11.5H11.5ZM47.3333 11.5V18.6667H54.5V11.5H47.3333ZM8.8125 36.5833C4.39156 36.5833 0.75 40.2249 0.75 44.6458V57.1875C0.75 61.6084 4.39156 65.25 8.8125 65.25H21.3542C25.7751 65.25 29.4167 61.6084 29.4167 57.1875V44.6458C29.4167 40.2249 25.7751 36.5833 21.3542 36.5833H8.8125ZM36.5833 36.5833V46.4375H46.4375V36.5833H36.5833ZM46.4375 46.4375V55.3958H55.3958V46.4375H46.4375ZM55.3958 46.4375H65.25V36.5833H55.3958V46.4375ZM55.3958 55.3958V65.25H65.25V55.3958H55.3958ZM46.4375 55.3958H36.5833V65.25H46.4375V55.3958ZM8.8125 41.9583H21.3542C22.8708 41.9583 24.0417 43.1292 24.0417 44.6458V57.1875C24.0417 58.7041 22.8708 59.875 21.3542 59.875H8.8125C7.29586 59.875 6.125 58.7041 6.125 57.1875V44.6458C6.125 43.1292 7.29586 41.9583 8.8125 41.9583ZM11.5 47.3333V54.5H18.6667V47.3333H11.5Z"
      fill="#0F5BF1"
    />
  </svg>
);
export default Upi;
