import React from "react";

const BugReport = () => {
  return (
    <div className="flex items-center justify-center w-full h-full font-bold text-7xl">
      Coming Soon Report bug Page
    </div>
  );
};

export default BugReport;
