import { IIcons } from "./icons";

const SvgDropdownIcon = ({ fillColor }: IIcons.IconProps) => (
  <svg width="14" height="8" viewBox="0 0 14 8" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      d="M1 1L6.69457 6L12.3891 1"
      stroke="black"
      strokeWidth="2"
      strokeLinecap="round"
      className={fillColor}
    />
  </svg>
);

export default SvgDropdownIcon;
