import React from "react";
import { IIcons } from "./icons";

const ProfilePhotoIcon = ({ fillColor }: IIcons.IconProps) => {
  return (
    <svg
      width="65"
      height="65"
      viewBox="0 0 65 65"
      fill="none"
      xmlns="http://www.w3.org/2000/svg "
      className={fillColor}
    >
      <circle r="32.5" transform="matrix(1 0 0 -1 32.5 32.5)" fill="#0F5BF1" />
      <circle cx="31.5" cy="24.5" r="6.5" fill="#F5F8FF" />
      <ellipse cx="32" cy="42" rx="13" ry="7" fill="#F5F8FF" />
    </svg>
  );
};

export default ProfilePhotoIcon;
