import React from "react";
import { useRecoilState } from "recoil";
import { isInwardTransaction } from "../../store";

import {
  DealCardContainer,
  HistoryCardContainer,
  IDealCardContainerProps,
  IHistoryCardContainerProps,
  IInfographicCardContainerProps,
  InfographicCardContainer,
  IRecepientContainerProps,
  RecepientContainer,
} from "./DashboardContainerComponents";

interface ITxDashboard
  extends IDealCardContainerProps,
    IInfographicCardContainerProps,
    IRecepientContainerProps,
    IHistoryCardContainerProps {}

const TxDashboard: React.FunctionComponent<ITxDashboard> = ({
  dealCard,
  historyCardDetails,
  recepientName,
  noOfTx,
  amountRemitted,
  chargesSaved,
}) => {
  // const secondTour = useRecoilValue(showThirdTour);
  // const setShowThirdTours = useSetRecoilState(showThirdTour);
  // const setShowFirstTours = useSetRecoilState(showFirstTour);
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const [isInward, setIsInward] = useRecoilState(isInwardTransaction);

  return (
    <div id="second">
      <RecepientContainer
        recepientName={recepientName}
        isInward={isInward}
        setIsInward={setIsInward}
      />
      <InfographicCardContainer
        noOfTx={noOfTx}
        amountRemitted={amountRemitted}
        chargesSaved={chargesSaved}
      />
      <div id="dealsContainer">
        <DealCardContainer dealCard={dealCard} />
      </div>
      <div id="historyCard" className="z-20">
        <HistoryCardContainer historyCardDetails={historyCardDetails} />
      </div>
      {/* <Modal
        visible={isClosingModalVisble}
        closeHandler={() => setIsClosingModalVisble(false)}
        modalClass="py-2 px-6 rounded-md !w-full md:!w-fit"
      >
        <div className="flex flex-row justify-between">
          <div className="flex flex-col justify-between w-1/2 py-4 ml-4">
            <div className="flex flex-col">
              <div className="flex flex-col">
                <div className="mb-2 text-2xl font-bold">
                  Great! <br /> You completed the
                </div>
                <div className="flex mb-8 text-4xl font-bold">{`"Easy tour"`}</div>
              </div>
              <div className="text-lg text-gray-600">
                You Just need to complete KYC before you are ready to do your first International
                remitance!
              </div>
            </div>
            <div className="mb-4">
              <Button
                id="get-started-modal-2"
                bgColor="bg-primary"
                fontStyle={{
                  fontFamily: "font-roboto",
                  fontSize: "text-base",
                  fontWeight: "font-bold",
                  fontColor: "text-white",
                }}
                borderStyle={{
                  borderRadius: "rounded-[44px]",
                }}
                className="px-20 py-4"
                onClickHandler={() => {
                  setIsClosingModalVisble(false);
                }}
              >
                Get Started
              </Button>
            </div>
          </div>
          <div className="mt-8 h-3/4">
            <AppTourModalBack />
          </div>
        </div>
      </Modal> */}
    </div>
  );
};

export default TxDashboard;
