/* eslint-disable */
import React, { FunctionComponent, useState } from "react";
import { useNavigate } from "react-router-dom";
import Modal from "../../../components/ui/Modal";
import { relationShips } from "../../../utils";
import { accountTypes } from "../../../utils";
import RecipientProps from "./recipientDetails";

export const Modals: FunctionComponent<RecipientProps.Modals> = ({
  isModalOpen,
  setIsModalOpen,
  setRelationship,
  setAccountType,
}) => {
  const navigate = useNavigate();

  return (
    <div>
      {/* Relationship Modal */}
      <Modal
        header="You are sending money to your"
        headerClass="flex justify-center font-bold text-[18px] bg-primary text-white !mt-0 !p-7 !w-full"
        modalClass="!w-full lg:!w-1/3 xl:!w-1/5 !p-0 max-h-[600px] max-w-[500px] overflow-x-scroll"
        visible={isModalOpen === "relationship"}
        closeHandler={() => setIsModalOpen("")}
      >
        <div>
          <div>
            <div>
              <ol className="flex flex-col justify-center px-8 my-10 space-y-8 over">
                {relationShips?.map((item, index) => {
                  return (
                    <div
                      key={index}
                      onClick={() => {
                        setRelationship(item);
                        setIsModalOpen("");
                      }}
                      className="pb-3 z-50 font-medium border-b-2 cursor-pointer hover:font-bold hover:text-[18px] transition-all"
                    >
                      {item}
                    </div>
                  );
                })}
              </ol>
            </div>
          </div>
        </div>
      </Modal>
      <Modal
        header="Recipient Account Type"
        headerClass="flex justify-center font-bold text-[18px] bg-primary text-white !mt-0 !p-7 !w-full"
        modalClass="!w-full lg:!w-1/3 xl:!w-1/5 !p-0 max-h-[600px] max-w-[500px] overflow-x-scroll"
        visible={isModalOpen === "accountType"}
        closeHandler={() => setIsModalOpen("")}
      >
        <div>
          <div>
            <div>
              <ol className="flex flex-col justify-center px-8 my-10 space-y-8 over">
                {accountTypes?.map((item, index) => {
                  return (
                    <div
                      key={index}
                      onClick={() => {
                        setAccountType(item);
                        setIsModalOpen("");
                      }}
                      className="pb-3 z-50 font-medium border-b-2 cursor-pointer hover:font-bold hover:text-[18px] transition-all"
                    >
                      {item}
                    </div>
                  );
                })}
              </ol>
            </div>
          </div>
        </div>
      </Modal>
    </div>
  );
};
