import React from "react";
import clsx from "clsx";

import Typograpy from "../Typography";
import { IInfographicCard } from "./InfographicCard";

const InfographicCard: React.FunctionComponent<IInfographicCard.IProps> = ({
  icon,
  title,
  subtitle,
  cardClassname = "w-44 xl:w-72",
  titleClassname = "text-grey-600",
  subtitleClassname,
  children,
}) => {
  return (
    <div
      className={clsx(
        "bg-white h-20 md:h-32 xl:h-32 p-3 md:p-4 !pb-3 rounded shadow-infographicCard hover:shadow-md hover:rounded-xl transition-all group cursor-pointer md:shadow-none max-h-[116px]",
        cardClassname,
      )}
    >
      {children ? (
        children
      ) : (
        <>
          <div className="flex mb-2.5 md:mb-3 md:h-[40px] xl:!h-auto items-center gap-2 group-hover:scale-105 transition-all">
            {icon}
            <Typograpy
              className={clsx("text-[10px] md:text-sm font-medium", titleClassname)}
              tagType="span"
              text={title}
            />
          </div>
          <div className="transition-all group-hover:scale-105">
            <Typograpy
              className={clsx(
                "text-[24px] md:text-[36px] xl:text-[45px] leading-8 md:leading-tight",
                subtitleClassname,
              )}
              tagType="span"
              text={subtitle}
            />
          </div>
        </>
      )}
    </div>
  );
};

export default InfographicCard;
