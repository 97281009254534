import clsx from "clsx";
import React, { useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { useRecoilValue, useResetRecoilState } from "recoil";
import {
  CancellationBanner,
  CloseIcon,
  LeftArrowIcon,
  PrimeTag,
  ProfilePhotoIcon,
} from "../../assets/Icons";
import { motion } from "framer-motion";
import Accordion from "../../components/ui/Accordion";
import Button from "../../components/ui/Button";
// import { Link } from "react-router-dom";
import Checkbox from "../../components/ui/Checkbox";
import { DocumentCard } from "../../components/ui/DocumentCard";
import Modal from "../../components/ui/Modal";
import KYCNotVerifiedDashboard from "../../assets/images/kycNotVerifiedDashboard.png";
import { getUserDocs } from "../../services/userDetails";
import { transactionDetails, userDetails } from "../../store";
import { TransactioncancellationReasons } from "../../utils";
import Typograpy from "../../components/ui/Typography";
import ForgotPassword from "./ChangePassword";
import mixpanel from "mixpanel-browser";
import DocumentUpload from "./DocumentUpload";
import { handleTransactionComplete } from "../../services/transaction";

const ProfilePage = () => {
  const [isForgotPassword, setIsForgotPassword] = React.useState(false);
  const [isKycModalOpen, setIsKycModalOpen] = React.useState(false);
  const [cancellationReasons, setCancellationReasons] = React.useState<string>("");
  const [otherReason, setOtherReason] = React.useState<string>("");
  const [isModalOpen, setIsModalOpen] = React.useState<string>("");
  const [isDocVerified, setIsDocVerified] = React.useState<boolean>();
  const [documentList, setDocumentList] = React.useState<any>([]);
  const userData: any = useRecoilValue(userDetails);
  const navigate = useNavigate();
  const currentUser = JSON.parse(sessionStorage.getItem("currentUser") || "{}");
  const mixpanelToken: any = process.env.REACT_APP_MIXPANEL_TOKEN;
  const resetTransactionDetails = useResetRecoilState(transactionDetails);
  mixpanel.init(mixpanelToken, {
    debug: true,
    api_host:process.env.REACT_APP_MIXPANEL_API,
  });

  useEffect(() => {
    getUserDocs(currentUser)
      .then((response) => {
        setIsDocVerified(response?.data?.isDocVerified);
        setDocumentList(response?.data?.userDocs);
      })
      .catch((error) => {
        window.alert(error);
      });
  }, []);

  const handleNavigate = () => {
    handleTransactionComplete();
    resetTransactionDetails();
    navigate("/");
  };

  return (
    <motion.div
      className="overflow-hidden"
      initial={{ opacity: 0 }}
      animate={{ opacity: 1 }}
      exit={{ opacity: 0 }}
    >
      <div>
        <div className="flex justify-between items-center bg-white md:hidden w-full py-[25px] pr-[22px] pl-7 mb-6">
          <div className="flex items-center w-fit" onClick={() => history.back()}>
            <LeftArrowIcon />
            <div className="ml-6 text-[15px] lg:text-xl font-semibold text-grey-1100">
              Profile Page
            </div>
          </div>
          <div className="flex items-center">
            <div
              className="mr-3 text-sm font-normal text-primary"
              onClick={() => navigate("/support")}
            >
              Need help ?
            </div>
            <div
              className="bg-gray-400 w-[30px] h-[30px] flex justify-center items-center rounded-full"
              onClick={() => setIsModalOpen("cancelReasons")}
            >
              <CloseIcon />
            </div>
          </div>
        </div>
        <div className="w-full mb-12 overflow-hidden border border-primary-800"></div>
        <div className="px-6 lg:px-8">
          <div className="flex flex-col lg:flex-row">
            <div className="flex items-center mb-12 space-x-2 lg:px-6">
              <div className="lg:mr-8">
                <ProfilePhotoIcon fillColor="lg:h-36 lg:w-36 h-24 w-24" />
              </div>
              <div>
                <div className="font-bold lg:text-4xl text-[22px] space-x-2">
                  <span>{userData?.firstName}</span>
                  <span>{userData?.lastName}</span>
                </div>
                <div className="lg:text-lg text-[#898989] text-[16px] lg:text-[20px]">
                  {userData?.email}
                </div>
              </div>
            </div>
            <div className="mb-10 ml-24 -mt-8 lg:ml-0 lg:mb-0 lg:w-1/4 lg:mt-5">
              {userData?.isPrime && (
                <div className="w-4/6 mt-4 lg:mt-8 mb-2 px-5 py-2 bg-[#FFE092] flex space-x-2 rounded-lg items-center justify-center">
                  <PrimeTag />
                  <span className="text-[#4B3D17] font-semibold font-ssp text-[14px]">
                    Prime Member
                  </span>
                </div>
              )}
            </div>
          </div>
          <Accordion
            customClassname="mb-4 font-semibold font-ssp lg:text-[20px] p-1"
            title={"Profile Update"}
          >
            <div className="">
              <div className="border w-full border-[#0F5BF1]"></div>
              <div className="flex flex-col items-start p-2 pb-0 mb-4 lg:items-center lg:p-12 lg:flex-row ">
                <div className="w-full lg:w-1/2">
                  <div className="flex flex-row items-center justify-between mb-2 lg:justify-start">
                    <div className="w-[20%] font-bold text-[15px] lg:text-lg">Name</div>
                    <input
                      className="lg:w-[40%] p-1 bg-gray-100 border rounded-sm outline-none px-2 text-[15px] lg:text-lg font-light"
                      disabled
                      value={
                        userData?.firstName
                          ? userData?.firstName
                          : "" + userData?.lastName
                          ? userData?.lastName
                          : ""
                      }
                      autoComplete="off"
                    />
                  </div>
                  <div className="flex flex-row items-center justify-between mb-2 lg:justify-start">
                    <div className="w-[20%] font-bold text-[15px] lg:text-lg">DOB</div>
                    <input
                      className="lg:w-[40%] p-1 bg-gray-100 border rounded-sm outline-none px-2 text-[15px] lg:text-lg font-light"
                      disabled
                      value={userData?.dob ? userData?.dob : ""}
                    />
                  </div>
                  {userData?.nationality && (
                    <div className="flex flex-row items-center justify-between mb-2 lg:justify-start">
                      <div className="w-[20%] font-bold text-[15px] lg:text-lg">Nationality</div>
                      <input
                        className="lg:w-[40%] p-1 bg-gray-100 border rounded-sm outline-none px-2 text-[15px] lg:text-lg font-light"
                        disabled
                        value={userData?.nationality ? userData?.nationality : ""}
                        autoComplete="off"
                      />
                    </div>
                  )}
                  <div className="flex flex-row items-center justify-between mb-2 lg:justify-start">
                    <div className="w-[20%] font-bold text-[15px] lg:text-lg">Gender</div>
                    <input
                      className="lg:w-[40%] p-1 bg-gray-100 border rounded-sm outline-none px-2 text-[15px] lg:text-lg font-light"
                      disabled
                      value={userData?.gender ? userData?.gender : ""}
                    />
                  </div>
                  <div className="flex flex-row items-center justify-between mb-2 lg:justify-start">
                    <div className="w-[20%] font-bold text-[15px] lg:text-lg">KYC</div>
                    <div className="ml-2 text-[15px] lg:text-lg text-success">
                      {userData?.inwardUserDetails?.kycStatus
                        ? userData?.inwardUserDetails?.kycStatus
                        : ""}
                    </div>
                  </div>
                </div>
                <div className="lg:w-1/2">
                  <div className="flex flex-row items-center justify-between mb-2 lg:justify-start">
                    <div className="lg:w-[22%] w-1/5 font-bold text-[15px] lg:text-lg">
                      Permanent Address
                    </div>
                    <textarea
                      className="lg:w-[45%] w-[52%] p-1 bg-gray-100 border rounded-sm outline-none px-2 text-[15px] lg:text-lg font-light"
                      disabled
                      rows={3}
                      value={userData?.addressLine1
                        + " " +  userData?.addressLine2
                        + ", " +  userData?.city
                        + ", " + userData?.pinCode
                        + ", " + userData?.state
                      }
                    />
                  </div>
                  {/* <div className="flex flex-row items-center justify-between mb-2 lg:justify-start">
                    <div className="lg:w-[22%] w-1/5 font-bold text-[15px] lg:text-lg">
                      Communication Address
                    </div>
                    <textarea
                      className="lg:w-[45%] w-[52%] p-1 bg-gray-100 border rounded-sm outline-none px-8 text-[15px] lg:text-lg font-light"
                      disabled
                      rows={3}
                      value={
                        userData?.comAddressLine1
                          ? userData?.comAddressLine1
                          : "" + " " + userData?.comAddressLine2
                          ? userData?.comAddressLine2
                          : "" + " " + userData?.comCity
                          ? userData?.comCity
                          : "" + " " + userData?.comState
                          ? userData?.comState
                          : "" + " " + userData?.comPinCode
                          ? userData?.comPinCode
                          : "" + " "
                      }
                    />
                  </div> */}
                </div>
              </div>
              <div className="w-full mb-2 border border-gray-200"></div>
              <div className="flex items-center justify-between p-5 px-12 mb-2">
                <div className="flex-row items-center hidden mb-2 lg:flex">
                  <div className="w-[40%] font-bold text-lg mr-[55%]">Email</div>
                  <div className="ml-2 text-lg font-light">{userData?.email}</div>
                </div>
                <Button
                  id="Change Password-btn"
                  fontStyle={{
                    fontFamily: "font-roboto",
                    fontSize: "text-base",
                    fontWeight: "font-bold",
                    fontColor: "text-white",
                  }}
                  borderStyle={{
                    borderRadius: "rounded",
                  }}
                  className="py-3 transition-all bg-gray-600 px-14 hover:bg-white hover:text-gray-600 hover:border-2 hover:border-gray-600"
                  onClickHandler={() => {
                    setIsForgotPassword(true);
                    mixpanel.track("Change Password Clicked");
                  }}
                >
                  Change Password
                </Button>
              </div>
            </div>
          </Accordion>
          <Accordion
            customClassname="font-semibold font-ssp text-[15px] lg:text-[20px] mb-[540px]"
            title={"Document upload history"}
          >
            <div>
              {isDocVerified ? (
                <div className="p-2">
                  {documentList?.map((doc: any, index: any) => {
                    return (
                      <div key={index} className="py-2">
                        <DocumentCard
                          docName={doc.docName}
                          // date={doc.createdOn.substring(0, 10)}
                          onClick={() => window.open(doc.docUrl, "_blank")}
                        />
                      </div>
                    );
                  })}
                </div>
              ) : (
                <div className="flex flex-col items-center flex-1 gap-16 py-6 pr-10 bg-white pl-14 md:flex-row">
                  <img src={KYCNotVerifiedDashboard} />
                  <div className="flex flex-col items-center flex-1 lg:items-start">
                    <Typograpy
                      tagType="span"
                      text="It seems like you haven't completed your document verification yet."
                      className="text-lg font-bold text-[28px] leading-10 text-black-200"
                    />
                    <Typograpy
                      tagType="span"
                      text="Get Verified in an Instant"
                      className="mt-5 text-lg font-bold leading-6 text-black-200"
                    />
                    <Typograpy
                      tagType="span"
                      text="With instant document verification your  work gets done faster"
                      className="mt-2 text-sm text-black-200"
                    />
                    <Button
                      onClickHandler={() => setIsKycModalOpen(true)}
                      className="px-9 py-3 text-white mt-9 w-[180px] hover:bg-white hover:text-primary-400 hover:border-2 hover:border-primary-400 transition-all rounded-lg text-sm font-semibold bg-primary-400 text-center"
                    >
                      Complete KYC
                    </Button>
                  </div>
                </div>
              )}
            </div>
          </Accordion>
        </div>
        <ForgotPassword
          isForgotPassword={isForgotPassword}
          setIsForgotPassword={setIsForgotPassword}
        />
        <DocumentUpload isKycModalOpen={isKycModalOpen} setIsKycModalOpen={setIsKycModalOpen} />
      </div>
      <Modal
        headerClass="!m-0"
        modalClass="!p-0 !rounded !overflow-hidden !w-full lg:!w-min"
        visible={isModalOpen === "cancelReasons"}
        closeHandler={() => setIsModalOpen("")}
        closable={false}
      >
        <div className="flex items-start">
          <div className="px-[34px] pt-[34px] pb-[54px] md:pb-0">
            <div className="pb-3 text-lg font-bold text-black px-7">Reason for cancellation</div>
            {TransactioncancellationReasons.map((data, index) => {
              return (
                <div className="space-x-2 px-7 border-b-[1px] py-6 first:pt-0" key={index}>
                  <Checkbox
                    label={data}
                    value={data}
                    name={"cancellationReason"}
                    handleChange={(e) => {
                      setCancellationReasons(e.target.checked ? e.target.value : "");
                    }}
                  />
                </div>
              );
            })}
            {cancellationReasons === "Other Reason..." && (
              <div className="flex items-center justify-center mt-2">
                <textarea
                  name="otherReasonDescription"
                  value={otherReason}
                  rows={3}
                  cols={40}
                  placeholder="Write your reason here ..... in less than 50 words"
                  onChange={(e) => {
                    setOtherReason(e.target.value);
                  }}
                  className="w-full p-3 bg-white-100 focus-visible:border-0 placeholder:text-center"
                />
              </div>
            )}
            <div
              className={clsx(
                cancellationReasons === "Other Reason..." ? "mt-7" : "mt-24",
                "flex items-center justify-center px-[78px]",
              )}
            >
              <Button
                id="Change Password-cancel"
                bgColor={`${
                  cancellationReasons === "" ||
                  (cancellationReasons === "Other Reason..." && otherReason === "")
                    ? "bg-grey-550"
                    : "bg-primary"
                }`}
                fontStyle={{
                  fontFamily: "",
                  fontSize: "text-lg",
                  fontWeight: "font-bold",
                  fontColor: "text-white",
                }}
                borderStyle={{
                  borderWidth: "border-none",
                  borderRadius: "rounded-[100px]",
                }}
                disabled={
                  cancellationReasons === "" ||
                  (cancellationReasons === "Other Reason..." && otherReason === "")
                }
                className={"py-3 px-28"}
                onClickHandler={() =>
                  cancellationReasons !== "" && cancellationReasons === "Other Reason..."
                    ? otherReason !== "" && handleNavigate()
                    : handleNavigate()
                }
              >
                Confirm
              </Button>
            </div>
          </div>
          <div className="hidden w-1/2 h-full pb-6 pt-7 pl-9 pr-11 lg:block bg-red-250">
            <button className="float-right" onClick={() => setIsModalOpen("")}>
              <CloseIcon fill="fill-grey-1200" />
            </button>
            <div id="text" className="flex flex-col mt-10">
              <span
                className={clsx(
                  cancellationReasons === "" ? "text-[32px]" : "text-[26px]",
                  "text-black font-semibold",
                )}
              >
                {cancellationReasons === "" ? "Sorry!" : "Did we made any problems??"}
              </span>
              <span className="mt-2 text-lg font-normal text-black">
                {cancellationReasons === ""
                  ? "Did you face any issue?"
                  : "Somwtimes there might be an issue so relax and try again."}
              </span>
            </div>
            <div className="flex justify-center px-4 mt-10">
              <CancellationBanner />
            </div>
          </div>
        </div>
      </Modal>
    </motion.div>
  );
};

export default ProfilePage;
