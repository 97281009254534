import React from "react";
import { IIcons } from "./icons";

const ProfileOutline = ({ fillColor }: IIcons.IconProps) => (
  <svg
    className={fillColor}
    width="23"
    height="23"
    viewBox="0 0 23 23"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M11.6667 11.4981C13.6919 11.4981 15.3333 9.85644 15.3333 7.83146C15.3333 5.80649 13.6919 4.16479 11.6667 4.16479C9.64141 4.16479 8 5.80649 8 7.83146C8 9.85644 9.64141 11.4981 11.6667 11.4981ZM11.6667 5.08146C13.1832 5.08146 14.4167 6.31495 14.4167 7.83146C14.4167 9.34769 13.1832 10.5815 11.6667 10.5815C10.1502 10.5815 8.91667 9.34683 8.91667 7.83146C8.91667 6.31495 10.1513 5.08146 11.6667 5.08146ZM13.119 12.8731H10.2143C7.4732 12.8731 5.25 15.096 5.25 17.8375C5.25 18.3857 5.69458 18.8306 6.24286 18.8306H17.091C17.6393 18.8315 18.0833 18.3875 18.0833 17.8375C18.0833 15.096 15.8604 12.8731 13.119 12.8731ZM17.0893 17.9148H6.24286C6.20104 17.9148 6.16667 17.8804 6.16667 17.8375C6.16667 15.6059 7.98281 13.7898 10.2143 13.7898H13.1161C15.3505 13.7898 17.1667 15.6059 17.1667 17.8375C17.1667 17.8804 17.1323 17.9148 17.0893 17.9148Z"
      fill={fillColor || "#111112"}
    />
  </svg>
);
export default ProfileOutline;
