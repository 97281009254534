import React, { useEffect, useState } from "react";
import Sharing from "../../assets/svg/sharing.svg";
import Banner from "../../assets/svg/HopCoinsBanner.svg";
import Coins from "../../assets/svg/Coins.svg";
import { hopCoinsRewardsFAQ } from "../../utils";
import { NewAccordion } from "../../components/ui/NewAccordian";
import { getReferralDetails } from "../../services/rewards";
import Modal from "../../components/ui/Modal";
// import Modal from "../../components/ui/Modal";

declare global {
  interface Window {
    invite_referrals?: any;
  }
}

interface DetailsDataProps {
  date: string;
  msg: string;
  coins: number;
  transactionNumber: string;
  event: string;
  type: string;
  email: string;
}

interface DataProps {
  coinsBalance: number;
  totalCoinsUsed: number;
  details: [DetailsDataProps];
  totalCoinsEarned: 1500;
}

// SharingButton Component
export const SharingButton:React.FC<{handleRefferal:()=>void}> = ({handleRefferal}) => (
  <div className="header-component">
    <div className="flex w-full justify-between items-center flex-wrap">
      <div className="sharing-text">
        <div className="content-box">
          <p className="text-3xl">
            Refer and earn <span className="font-bold">1500 HOPCOINS</span>
          </p>
          <p className="text-[28px] text-[#586372] font-semibold">
            Help them know a smarter way to send money abroad
          </p>
        </div>
      </div>
      <div className="sharing-button">
        <button onClick={()=>handleRefferal()} className="flex justify-center items-center w-[223px] h-[57.1px] bg-[#006EFF] rounded-lg shadow-md shadow-[#0F5BEF40]">
          <img src={Sharing} className="w-4 h-8 mr-2" alt="Share Icon" />
          <span className="text-white text-[21.07px] font-semibold">Share</span>
        </button>
      </div>
    </div>
  </div>
);

// CoinsAndBanner Component
export const CoinsAndBanner: React.FC<{ coinsBalance: number }> = ({ coinsBalance }) => (
  <div className="hop-coins-banner py-4">
    <div className="banners-grid grid xl:grid-cols-2 gap-4 grid-cols-1">
      <div className="first-banner flex items-center justify-between px-[15%] w-full bg-white min-h-[124px] rounded-xl border border-[#CECECE] p-2 col-span-1">
        <div className="showing-balance text-center w-40">
          <p className="text-[#0F5BF1] font-extrabold text-[38.4px]">{coinsBalance}</p>
          <p className="font-semibold text-base text-[#586372]">Your current Hop Coins Balance</p>
        </div>
        <div className="coins-image-div">
          <img src={Coins} alt="Coins Icon" />
        </div>
      </div>
      <div className="second-banner">
        <div className="w-full">
          <img src={Banner} className="w-full h-auto" alt="Hop Coins Banner" />
        </div>
      </div>
    </div>
  </div>
);

// HopCoinsHistory Component
export const HopCoinsHistory: React.FC<{ details: [DetailsDataProps] | [] }> = ({ details }) => (
  <div className="hop-coins-history">
    <div className="main-component bg-[#006EFF0A]">
      <div className="heading p-4">
        <h3 className="text-[29.88px] font-semibold text-[#001847] px-6">Hop Coins history</h3>
      </div>
      <div className="history bg-[#006EFF14]">
        <div className="flex justify-between w-full items-center px-10 p-4">
          <h4 className="text-[20px] font-semibold">Events</h4>
          <h4 className="text-[20px] font-semibold">Hop Coins</h4>
        </div>
        {details?.length > 0
          ? details?.map((events, index) => (
              <div
                key={index}
                className="history-entry flex justify-between w-full items-center px-10 p-4 bg-white"
              >
                <h4 className="text-[16px] font-semibold">{events?.msg}</h4>
                <h4
                  className={`text-[16px] font-semibold ${
                    events?.type === "credit" ? "text-[#16A34A]" : "text-[#B91C1C]"
                  }`}
                >
                  {events?.coins}
                </h4>
              </div>
            ))
          : null}
      </div>
    </div>
  </div>
);

// NewRewards Component
const NewRewards = () => {
  const currentUser = JSON.parse(sessionStorage.getItem("currentUser") || "{}");
  const [data, setData] = useState<DataProps>();
  const [modalOpen, setModalOpen] = useState(false);
  const email = sessionStorage.getItem('email') || '';
  const phoneNumber = sessionStorage.getItem('phoneNumber') || '';
  const referAndEarnBid = process.env.REACT_APP_REFFER_AND_EARN_BID;
  const referAndEarnCid = process.env.REACT_APP_REFFER_AND_EARN_CID;

  useEffect(() => {
    getReferralDetails(currentUser).then((response) => {
      setData(response?.data);
    });
  }, []);

  const handleRefferal = () => {
    setModalOpen(true);
  };

  // const closeModal = () => {
  //   setModalOpen(false);
  // };

  return (
    <div className="p-6">
      {/* <Modal /> */}
      <Modal visible={modalOpen} closeHandler={() => setModalOpen(false)}>
        <iframe
          style={{ maxWidth: "100%" }}
          width="650px"
          height="750px"
          src={`https://www.ref-r.com/campaign_user/p?brandid=${referAndEarnBid}&campaignid=${referAndEarnCid}&bid_e=B826B39CE7AFFA104E48D93083EA8FC7&t=420&email=${email}&fname=${email}&mobile=${phoneNumber}`}
        ></iframe>
      </Modal>
      <SharingButton handleRefferal={handleRefferal} />
      <CoinsAndBanner coinsBalance={data ? data?.coinsBalance : 0} />
      <div className="py-4">
        {hopCoinsRewardsFAQ?.map((data, index) => (
          <NewAccordion key={index} data={data} showNumber={false} number={index+1} />
        ))}
      </div>
      <HopCoinsHistory details={data ? data?.details : []} />
    </div>
  );
};

export default NewRewards;
