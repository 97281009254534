/* eslint-disable */
import { useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { SignupSuccessfulLoader } from "../components/ui/SuccessfulSignupLoader";

export const Gtm = () => {
  const navigate = useNavigate();
  useEffect(() => {
    setTimeout(() => {
      navigate("/auth/sign-in");
    }, 1500);
  }, []);
  return (
    <div className="flex items-center justify-center w-screen h-screen scale-200">
      <SignupSuccessfulLoader />
    </div>
  );
};
