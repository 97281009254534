import React from "react";
import { IIcons } from "./icons";

const DownCircleIcon = ({ fillColor }: IIcons.IconProps) => {
  return (
    <svg width="22" height="26" viewBox="0 0 22 26" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M3 0C1.34375 0 0 1.34375 0 3V23C0 24.6562 1.34375 26 3 26H17C18.6562 26 20 24.6562 20 23V17.8125L18 19.5625V23C18 23.5508 17.5508 24 17 24H3C2.44922 24 2 23.5508 2 23V3C2 2.44922 2.44922 2 3 2H17C17.5508 2 18 2.44922 18 3V6.4375L20 8.1875V3C20 1.34375 18.6562 0 17 0H3ZM13.5 6.46875C13.25 6.54297 13 6.79688 13 7.46875V10H7C6.44922 10 6 10.4492 6 11V15C6 15.5508 6.44922 16 7 16H13V18.5C13 19.7812 14 19.5 14 19.5L21.5 13L14 6.5C14 6.5 13.75 6.39453 13.5 6.46875Z"
        fill="#F5F8FF"
        className={fillColor}
      />
    </svg>
  );
};

export default DownCircleIcon;
