import React from "react";
import { IIcons } from "./icons";

const RightFilledBG = ({ fillColor }: IIcons.IconProps) => (
  <svg
    width="65"
    height="65"
    viewBox="0 0 65 65"
    fill="none"
    className={fillColor}
    xmlns="http://www.w3.org/2000/svg"
  >
    <circle cx="32.5" cy="32.5" r="32.5" fill="#D3E2FF" />
    <path
      d="M34.4796 41.7403C34.3791 41.7403 34.2786 41.7171 34.1883 41.6707C33.9614 41.5598 33.8196 41.3329 33.8196 41.0803V35.8003H19.2996C18.9361 35.8003 18.6396 35.5064 18.6396 35.1403V29.8603C18.6396 29.4968 18.9361 29.2003 19.2996 29.2003H33.8196V23.9203C33.8196 23.6702 33.9614 23.4407 34.1883 23.3299C34.4126 23.219 34.6807 23.2448 34.8818 23.3969L46.1018 31.9769C46.2643 32.1007 46.3596 32.294 46.3596 32.5003C46.3596 32.7065 46.2643 32.8999 46.0993 33.0236L34.8793 41.6036C34.7632 41.6939 34.6214 41.7403 34.4796 41.7403Z"
      fill="#0F5BF1"
    />
  </svg>
);
export default RightFilledBG;
