import { isEmpty } from "../../utils";
import { IAuth } from "../auth/auth";
import { Axios } from "../axios";

export const getHopCoins = async (currentUser: IAuth.IIsAuthorisedProps) => {
  
  if (!(currentUser === null || currentUser === undefined || isEmpty(currentUser))) {
    return await Axios.get(`/remit/auth/user/${currentUser.id}`, {
      headers: {
        Authorization: `${currentUser.idToken}`,
      },
    });
  }
};

export const getReferralDetails = async (currentUser: IAuth.IIsAuthorisedProps) => {
  if (!(currentUser === null || currentUser === undefined || isEmpty(currentUser))) {
    return await Axios.get(`/remit/auth/user/${currentUser.id}/referralDetails`, {
      headers: {
        Authorization: `${currentUser.idToken}`,
      },
    });
  }
};
