import React from "react";
import clsx from "clsx";

import { IAccordion } from "./Accordion";
import { DownIcon } from "../../../assets/Icons";

const Accordion: React.FC<IAccordion.AccordionProps> = ({
  title,
  width,
  children,
  customClassname,
  openAccordion = <DownIcon fillColor="" />,
  closeAccordion = <DownIcon fillColor="" />,
  handleOnClick = () => true,
  titleClassname,
}) => {
  const [isAccordionOpen, setOpenAccordion] = React.useState<boolean>(false);

  const handleOnClicks: any = () => {
    setOpenAccordion((isAccordionOpen) => !isAccordionOpen);
    handleOnClick();
  };

  return (
    <div
      className={clsx(
        "flex flex-col justify-center h-auto rounded-xl transition-all w-auto lg:px-8 px-4 py-4 bg-white group shadow-xl hover:shadow-blue-200 hover:shadow-lg",
        width,
        customClassname,
      )}
    >
      <div onClick={handleOnClicks} className="flex items-center cursor-pointer">
        <div className={clsx("flex-1 cursor-pointer", titleClassname)}>{title}</div>
        <div className="transition-all cursor-pointer">
          {isAccordionOpen ? closeAccordion : openAccordion}
        </div>
      </div>
      {isAccordionOpen && <div className="mt-4">{children}</div>}
    </div>
  );
};

export default Accordion;
