import * as React from "react";

import { IIcons } from "./icons";

const SendButton = ({ fillColor }: IIcons.IconProps) => (
  <svg width="19" height="19" viewBox="0 0 19 19" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      d="M18.2083 0C18.1324 0.0005212 18.0568 0.0119777 17.9841 0.0340169C17.9627 0.0398263 17.9416 0.0465325 17.9207 0.0541178L0.514893 5.5896V5.59269C0.363873 5.64905 0.233654 5.75016 0.141644 5.88251C0.0496342 6.01486 0.000217429 6.17214 0 6.33333C0.000288378 6.46928 0.0355778 6.60285 0.102469 6.7212C0.169361 6.83955 0.2656 6.93867 0.381917 7.00903L5.67155 11.2055L16.1287 2.87134L7.79452 13.3285L11.9879 18.615C12.0581 18.7323 12.1576 18.8295 12.2765 18.8969C12.3955 18.9644 12.5299 18.9999 12.6667 19C12.8279 18.9998 12.9851 18.9504 13.1175 18.8584C13.2498 18.7663 13.351 18.6361 13.4073 18.4851H13.4104L18.9505 1.0638C18.9562 1.04799 18.9613 1.03201 18.966 1.01587C18.988 0.943155 18.9995 0.867646 19 0.791667C19 0.581704 18.9166 0.38034 18.7681 0.231874C18.6197 0.0834075 18.4183 0 18.2083 0Z"
      fill={fillColor}
    />
  </svg>
);

export default SendButton;
