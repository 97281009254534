/* eslint-disable */
import React, { FC } from "react";
import FloatingDropDownProps from "./FloatingDropDown";
import clsx from "clsx";

const FloatingDropDown: FC<FloatingDropDownProps.FloatingDropDownIProps> = ({
  options,
  onSelect,
  placeHolder,
  required,
  dropdownClassname,
  dropDownvalue,
  disabled,
  bgColor,
  selectedBgColor,
  firstDivMargin,
}) => {
  const [selectedOption, setSelectedOption] = React.useState<any>();
  const [optionSelected, setOptionSelected] = React.useState<boolean>(false);

  const handleOptionSelect = (option: any): void => {
    setOptionSelected(true);
    setSelectedOption(option);
    onSelect(option.value);
  };

  return (
    <div id="first-div" style={{marginLeft:`${firstDivMargin?firstDivMargin:0}`}} className="ml-0">
      <div id="main-div" className="w-full relative ml-0" style={{marginLeft:"0"}}>
        <span
          className={`absolute left-5 text-gray-600 text-[14px] -top-1.5 mix-blend-normal ${bgColor ? bgColor : 'bg-white/100'} ${
            optionSelected ? "text-xs tracking-wider px-1" : "hidden"
          }`}
        >
          {placeHolder}
          {`${required ? " *" : ""}`}
        </span>
        <select
          className={clsx("w-full border border-gray-300 rounded-md py-4 px-2", dropdownClassname, optionSelected ? selectedBgColor : '')}
          id={placeHolder}
          name={placeHolder}
          value={dropDownvalue ? dropDownvalue : selectedOption?.value}
          onChange={(event) => {
            handleOptionSelect(options.find((option) => option.value === event.target.value));
          }}
          disabled={disabled}
        >
          <option value="" disabled selected>
            {placeHolder}
            {`${required ? "*" : ""}`}
          </option>
          {options.map((option: any) => (
            <option key={option.value} value={option.value} className="">
              <span className="px-4 md:text-sm xs:text-xs py-4 text-gray-600">{option.label}</span>
            </option>
          ))}
        </select>
      </div>
    </div>
  );
};

export default FloatingDropDown;
