/* eslint-disable */
//#Global Imports
import React from "react";
import clsx from "clsx";
//#end GlobalImports

//#Local Imports
import Button from "../../components/ui/Button";
import ISendMoney from "./sendMoney";
import { isInwardTransaction, transactionDetails } from "../../store";
import { useRecoilState, useRecoilValue } from "recoil";
import { purposeCodeList } from "../../utils";
// import { offersModalData } from "../../utils";


const ApplyPromoCode: React.FC<ISendMoney.IApplyPromoCodeProps> = ({
  selectedOfferCode,
  setIsModalOpen,
  confirmedPurposeValue
}) => {
  const isInward = useRecoilValue(isInwardTransaction);
  const transactionData = useRecoilValue(transactionDetails);


  return (
    <div
      className={clsx(
        "flex justify-center lg:justify-start items-center lg:ml-[83px] mb-[50px] mt-10",
      )}
    >
      <div className="hidden text-base font-medium text-black mr-[6.5rem] lg:flex">
        Offers and benefits
      </div>
      <Button
        id="selectedOfferCode-icon-is"
        bgColor={selectedOfferCode === "" ? "bg-white" : "bg-success"}
        fontStyle={{
          fontFamily: "",
          fontSize: "text-lg",
          fontWeight: "font-bold",
          fontColor: "text-success",
        }}
        borderStyle={{
          borderRadius: "rounded",
        }}
        // className="h-[50px] w-3/5"
        className="w-[378px] lg:w-[300px] 2xl:w-[374px] h-[50px]"
        onClickHandler={() => setIsModalOpen("offers")}
        disabled={isInward || confirmedPurposeValue?.value === purposeCodeList?.[2]?.value || confirmedPurposeValue?.value === purposeCodeList?.[3]?.value}
      >
        <div
          className={clsx(
            selectedOfferCode === "" ? "text-success" : "text-white",
            isInward ? "lg:text-sm" : "lg:text-base",
            "lg:text-base text-[12px] sm:text-[8px] font-bold truncate uppercase",
          )}
        >
          {isInward
            ? "Offer Applied - Zero Convenience Fees"
            : selectedOfferCode === ""
            ? // ? `Apply Coupon - ${offersModalData[0]?.code}`
              `Apply Coupon`
            : transactionData.promoCodeDetails.message === "Invalid promo code"
            ? `Apply Coupon`
            : selectedOfferCode}
        </div>
      </Button>
    </div>
  );
};

export default ApplyPromoCode;
