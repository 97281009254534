/* eslint-disable */
import React, { useRef, useEffect, useState } from "react";
import { useNavigate,useSearchParams } from "react-router-dom";
import { Controller, useForm } from "react-hook-form";
import clsx from "clsx";
import Button from "../../../components/ui/Button";
// import TextField from "../../../components/ui/TextField";?
import {
  getErrorMessage,
  getParameterByName,
  signUpPurposeCodeList,
  regexForEmailAddress,
  regexForMobileNumber,
  regexForPassword,
  regExpForSpecialChar,
} from "../../../utils";
import {
  getRequestTime,
  handleLogin,
  initialXSRFToken,
  registerUserData,
  sendOtp,
  validateOtp,
  verifyReCaptcha,
} from "../../../services/auth";
import ForgotPassword from "../ForgotPassword";
import { useRecoilState, useSetRecoilState } from "recoil";
import { isFirstTimeUser, isSignupSuccess } from "../../../store";
import { AnimatedSwipeLeftButton } from "../../../components/ui/AnimatedSwipeLeftButton";
import mixpanel from "mixpanel-browser";
import { getDetails } from "../../../services/payments";
import SignUpSuccessFullLoader from "./SignUpSuccessFullLoader";
import NewLoginLaptop from "../../../assets/Icons/NewLoginLaptop";
import { LoginAnimation } from "../../../components/ui/LoginAnimation";
import { useSpring, animated, useChain, useSpringRef } from "@react-spring/web";
import ReCAPTCHA from "react-google-recaptcha";
import Dropdown from "../../../components/ui/Dropdown";
import Tooltip from "../../../components/ui/Tooltip";
import { CloseIcon, DoubleVerifiedIcon, DownIcon } from "../../../assets/Icons";
import { title } from "process";
import Countdown, { zeroPad } from "react-countdown";
import OtpBox from "../../../components/ui/OtpBox";
import { Snackbar, Alert, AlertTitle, TextField } from "@mui/material";
import { SearchableDropdown } from "../../../components/ui/SearchableDropdown";
import TermsModal from "./TermsModal";
import ErrorField from "../../../components/ui/ErrorField";
import CustomSwiper from "../../../components/swiper";
import IconButton from "@material-ui/core/IconButton";
import Visibility from "@material-ui/icons/Visibility";
import VisibilityOff from "@material-ui/icons/VisibilityOff";
import FooterView from "../../../components/ui/FooterView";
import MobileIconNew from "../../../assets/Icons/MobileIconNew";

const SignUp = () => {
  const navigate = useNavigate();
  const [isIncorrectPassword, setInorrectPassword] = React.useState<boolean>(false);
  const [isUserRegistered, setIsUserRegistered] = React.useState<string>("");
  const [isForgotPassword, setIsForgotPassword] = React.useState<boolean>(false);
  const [isLoading, setIsLoading] = React.useState<boolean>(false);
  const [isDisabled, setIsDisabled] = React.useState<boolean>(true);
  const setIsFirstTimeLogin = useSetRecoilState(isFirstTimeUser);
  const phoneNumber = sessionStorage.getItem("phoneNumber") || "";
  const isUseEffectFired = React.useRef(false);
  const [countryCode, saveCountryCode] = React.useState<string>("+91");
  const [showDropdown, setShowDropdown] = React.useState<boolean>(false);
  const [isActiveStep, setActiveStep] = React.useState<number>(1);
  const [otpValue, saveOtpValue] = React.useState<string>("");
  const [otpError, setOTPError] = React.useState<boolean>(false);
  const [isOTPVerified, setOTPVerified] = React.useState<boolean>(false);
  const [otpErrorMessage, setVerifyOtpError] = React.useState<string>("");
  const [dateKey, saveDateKey] = React.useState(0);
  const [date, saveDate] = React.useState(Date.now() + 120000);
  const [errorText, setErrorText] = React.useState("");
  const [signUpPurpose, setSignUpPurpose] = React.useState("");
  const [isTermsModalOpen, setIsTermsModalOpen] = React.useState<boolean>();
  const [isTermsModalPrivacyOpen, setIsTermsModalPrivacyOpen] = React.useState<boolean>();
  const [showPassword, setShowPassword] = React.useState<boolean>();
  const [showConfirmPassword, setShowConfirmPassword] = React.useState<boolean>();
  const [queryParameters] = useSearchParams();
  const [userData, saveUserData] = React.useState({
    mobileNumber: "",
    email: "",
    password: "",
    confirmpassword: "",
    refer: "",
    purposeCode: ""
  });
  const [passwordFocus, setPasswordFocus] = useState<boolean>(false);
  const [buttonLoader,setButtonLoader] = useState(false);
  const [mobileAppBanner, setMobileAppBanner] = useState(true);

  const ir_co = queryParameters.get("ir_co");

  const userAgent = navigator.userAgent || navigator.vendor;
  const isIOS = /iPad|iPhone|iPod/.test(userAgent);

  const baseClasses = 'flex flex-col items-center p-16 px-8 space-y-5 bg-transparent bg-white rounded-lg shadow-xl lg:w-2/3 justify-evenly sm:mt-0';
  const iosClass = 'xs:mt-[250%]';

  const containerClass = isIOS ? `${baseClasses} ${iosClass}` : baseClasses;

  useEffect(()=>{
    if(ir_co){
      saveUserData({...userData,refer:ir_co})
    }
  },[ir_co]);

  const handleFocus = () => {
    setPasswordFocus(true)
  }

  const handleBlur = () => {
    setPasswordFocus(false)
  }


  const handleClickShowPassword = () => {
    setShowPassword(!showPassword);
  };

  const countryCodeDefaultValue = (
    <span className="flex items-center justify-start space-x-2 text-base font-medium text-black font-lato">
      <img src={`/images/png/INR.png`} alt="" width="35px" height="35px" />
      <div>+91</div>
    </span>
  );
  const [value, setValue] = React.useState(countryCodeDefaultValue);
  const recaptchaRef = React.useRef<any | null>(null);

  const countryList = [
    {
      name: "United States +1",
      icon: "USD.png",
    },
    {
      name: "Canada +1",
      icon: "CAD.png",
    },
    {
      name: "India +91",
      icon: "INR.png",
    },
    {
      name: "United Kingdom +44",
      icon: "GBP.png",
    },
  ];

  const mixpanelToken: any = process.env.REACT_APP_MIXPANEL_TOKEN;
  mixpanel.init(mixpanelToken, {
    debug: true,
    api_host:process.env.REACT_APP_MIXPANEL_API,
  });

  const {
    formState: { errors, isValid, isDirty },
    getValues,
    handleSubmit,
    setError,
    control,
  }: any = useForm({ mode: "onChange",defaultValues:{...userData,refer:ir_co} });

  const signupBanners = ["signUpBanner1", "signUpBanner2", "signUpBanner3", "signUpBanner4"];

  const [signupSuccess, setSignupSuccess] = useRecoilState(isSignupSuccess);

  const bannerRef: any = useSpringRef();
  const loginRef: any = useSpringRef();
  const swiper = useRef() as any;

  const loginStyle = useSpring({
    from: {
      x: 620,
    },
    delay: 1000,
    to: {
      x: 0,
    },
    ref: loginRef,
  });

  const bannerStyle = useSpring({
    from: {
      opacity: 0,
    },
    to: {
      opacity: 1,
    },
    ref: bannerRef,
  });

  useChain([loginRef, bannerRef]);

  const onSubmit = (data: any) => {
    saveUserData(data);
    setButtonLoader(true);
    sendOtp({
      body: {
        phoneNumber: countryCode + data.mobileNumber,
        otpType: "mobile",
        actionType: "SIGN_UP",
      },
      apiType: "send",
    })
      .then((response: any) => {
        if (response?.response?.status === 200 || response?.data?.success === true) {
          setActiveStep(2);
          mixpanel.track("OTP sent _signup");
        }
        setErrorText(response?.response?.data?.data?.error?.type);
        setTimeout(() => {
          setErrorText("");
        }, 5000);
        if (response?.response?.status === 401) {
          setErrorText(
            "You've entered an incorrect OTP too many times,Please Try after 30 minutes.",
          );
          setTimeout(() => {
            setErrorText("");
          }, 5000);
        }
      })
      .catch((error: any) => {
        console.error(error, "Errors");
        if (error?.response?.data?.error?.code === 2542) {
          setError("mobileNumber", {
            message: error?.response?.data?.error?.type,
          });
        }
        if (error?.response?.data?.error?.code === 2537) {
          setError("mobileNumber", {
            message: error?.response?.data?.error?.type,
          });
        }
      })
      .finally(()=>setButtonLoader(false));
  };

  const captchaValidate = (token: string | null) => {
    if (token) {
      verifyReCaptcha({ response: token })
        .then((response: any) => {
          if (response.data) {
            setIsDisabled(false);
          } else {
            setIsDisabled(true);
          }
        })
        .catch(() => {
          setIsDisabled(true);
        });
    }
  };

  React.useEffect(() => {
    if (!isUseEffectFired.current) {
      isUseEffectFired.current = true;
      initialXSRFToken();
    }
  }, []);

  const resendOTP = () => {
    sendOtp({
      body: {
        phoneNumber: countryCode + userData.mobileNumber,
        otpType: "mobile",
      },
      apiType: "resend",
    })
      .then(() => {
        saveDate(Date.now() + 120000);
        saveDateKey(dateKey + 1);
      })
      .catch((error) => {
        console.error(error);
      });
  };

  const verifyOTP = () => {
    setButtonLoader(true);
    const myData = {
      email: userData.email,
      password: userData.password,
    }
    getRequestTime()
      .then((response: any) => {
        const reqTime = response.data.currentTime;

        validateOtp({
          email_phone: countryCode + userData.mobileNumber,
          otp: otpValue,
          otpType: "mobile",
        })
          .then((response: any) => {
            if (response?.data?.success) {
              getRequestTime()
                .then((res: any) => {
                  // mixpanel.track("OTP Verified");
                  // mixpanel.track("Signed Up Successfully", {
                  //   email: userData.email,
                  //   is_email_verified: true,
                  //   is_phone_verified: true,
                  //   phoneNumber: userData.mobileNumber,
                  // });

                  registerUserData({
                    email: userData.email,
                    password: userData.password,
                    is_email_verified: true,
                    is_phone_verified: true,
                    phoneNumber: `+91${userData.mobileNumber}`,
                    partnerType: "moneyhop",
                    referrerCode: getParameterByName("ir_co", window.location.search) ?? userData?.refer,
                    purposeCode:  userData.purposeCode
                  })
                    .then((res: any) => {
                      setIsLoading(true);
                      if (res?.response?.data?.data?.success === false) {
                        setOTPVerified(false);
                        setVerifyOtpError(res?.response?.data?.data?.error?.type);
                        setTimeout(() => {
                          setVerifyOtpError("");
                          setActiveStep(1);
                        }, 3000);
                        setIsLoading(false);
                      } else if (
                        (res?.data?.is_email_verified ?? res?.data?.is_email_verified) &&
                        (res?.data?.is_phone_verified ?? res?.data?.is_phone_verified)
                      ) {
                        mixpanel.track("OTP verified_signup", {
                          email: userData.email,
                          is_email_verified: true,
                          is_phone_verified: true,
                          phoneNumber: userData.mobileNumber,
                        });
                        setSignupSuccess(true);
                        setIsLoading(false);
                        navigate("/auth/sign-in", { state: { myData } });
                      }
                    })
                    .catch((error) => {
                      setOTPVerified(false);
                      setVerifyOtpError(error?.response?.data?.error?.type);
                      setTimeout(() => {
                        setVerifyOtpError("");
                        setActiveStep(1);
                      }, 3000);
                      console.error(error);
                    });
                })
                .catch((error) => {
                  console.error(error, "xxxxxerr");
                  setVerifyOtpError(error?.response?.data?.message);
                });
            } else {
              setOTPError(true);
              setOTPVerified(false);
              setVerifyOtpError(response?.data?.message);
            }
          })
          .catch((error) => {
            setOTPError(true);
            setVerifyOtpError(error?.response?.data?.error?.type);
          });
      })
      .catch((error) => {
        setVerifyOtpError(error?.response?.data?.message);
      })
      .finally(()=>setButtonLoader(false));
  };

  return (
    <div className="relative h-screen w-full bg-gradient-to-r from-blue-500 to-blue-700">
      {isLoading ? (
        <SignUpSuccessFullLoader />
      ) : (
        <div
          style={{ zoom: "80%" }}
          className="top-0 left-0 flex flex-col items-center w-full h-full overflow-visible bg-gradient-to-r from-blue-500 to-blue-700 justify-evenly"
        >
          <div className="bottom-0 right-0 hidden cursor-default lg:absolute">
            <LoginAnimation />
          </div>
          <div className=" flex absolute-mobile top-0 lg:justify-start justify-center sm:w-full">
            <div className="w-full pl-4 sm:pl-[67px] h-[140px]  flex items-center">
              <img src={"/images/svg/whitelogo.svg"} alt="whitelogo" />
            </div>
          </div>
          <div className="flex flex-col items-center w-full bg-gradient-to-r from-blue-500 to-blue-700 lg:flex-row">
            {isActiveStep === 1 && (
              <div className="flex items-center justify-center mt-48 sm:mt-5 lg:py-10 lg:w-5/12">
                <animated.div
                  style={loginStyle}
                  className={containerClass}
                >
                  <div className=" font-sans text-2xl font-bold lg:-mt-12 sm:flex pb-7 text-primary">
                    Sign Up
                  </div>
                  {/* <div className="flex flex-col items-center space-y-3 pb-[73px]">
                    <img
                      src="https://k2.moneyhop.co/images/svg/moneyhop-logo.svg"
                      className="mt-[35vh]"
                    />
                    <div className="pt-10 text-xl font-bold text-primary font-lato">Sign Up</div>
                    <div className="text-base font-normal font-lato text-black-100">
                      Send money abroad anytime anywhere
                    </div>
                  </div> */}
                  <form
                    onSubmit={handleSubmit(onSubmit)}
                    className="flex flex-col items-center space-y-5 justify-evenly"
                    autoComplete="off"
                  >
                    <div className="flex w-screen h-full px-6 sm:w-max sm:justify-between pb-2 sm:pb-0 sm:px-0 justify-evenly">
                      {/* <div className="mr-5 sm:mr-[10px] w-fit sm:w-[120px]">
                        <Dropdown
                          dropdownClassname="w-[120px] h-[56px] border border-grey-450 flex justify-center bg-white px-[14px]"
                          showDropdown={showDropdown}
                          dropdownButtonOnClick={() => setShowDropdown((prev) => !prev)}
                          dropdownLabel={countryCodeDefaultValue}
                          countryList={countryList}
                          dropdownValue={value}
                          listItemOnClick={(e) => {
                            e.stopPropagation();
                            saveCountryCode("+" + e.target.textContent.split("+").pop());
                            setValue(
                              e.target.textContent == "United States +1" ? (
                                <span className="flex items-center justify-start space-x-2 text-base font-medium text-black font-lato">
                                  <img
                                    src={`/images/png/USD.png`}
                                    alt=""
                                    width="35px"
                                    height="35px"
                                  />
                                  <div>+1</div>
                                </span>
                              ) : e.target.textContent == "Canada +1" ? (
                                <span className="flex items-center justify-start space-x-2 text-base font-medium text-black font-lato">
                                  <img
                                    src={`/images/png/CAD.png`}
                                    alt=""
                                    width="35px"
                                    height="35px"
                                  />
                                  <div>+1</div>
                                </span>
                              ) : e.target.textContent == "India +91" ? (
                                <span className="flex items-center justify-start space-x-2 text-base font-medium text-black font-lato">
                                  <img
                                    src={`/images/png/INR.png`}
                                    alt=""
                                    width="35px"
                                    height="35px"
                                  />
                                  <div>+91</div>
                                </span>
                              ) : e.target.textContent == "United Kingdom +44" ? (
                                <span className="flex items-center justify-start space-x-2 text-base font-medium text-black font-lato">
                                  <img
                                    src={`/images/png/GBP.png`}
                                    alt=""
                                    width="35px"
                                    height="35px"
                                  />
                                  <div>+44</div>
                                </span>
                              ) : (
                                <></>
                              ),
                            );
                            setShowDropdown(false);
                          }}
                          onBlur={() => {
                            setShowDropdown(false);
                          }}
                        />
                      </div> */}
                      <div className="w-screen sm:w-[400px] px-6 sm:px-0">
                        <Controller
                          control={control}
                          name="mobileNumber"
                          rules={{ required: true, pattern: regexForMobileNumber }}
                          render={({ field: { name, value, onChange } }) => {
                            return (
                              <TextField
                                name={name}
                                id="number_signup"
                                type="number"
                                label="Mobile No."
                                className={clsx(
                                  errors.mobileNumber
                                    ? "border"
                                    : isDirty &&
                                      !errors.mobileNumber &&
                                      getValues("mobileNumber") !== undefined
                                    ? "border-success"
                                    : "border border-grey-450",
                                )}
                                value={value}
                                error={errors.mobileNumber}
                                onChange={(v) => onChange(v)}
                                required
                                fullWidth
                              />
                            );
                          }}
                        />
                      </div>
                    </div>
                    <div className="w-screen sm:w-[400px] px-6 sm:px-0">
                      <Controller
                        control={control}
                        name="email"
                        rules={{ required: true, pattern: regexForEmailAddress }}
                        render={({ field: { name, value, onChange } }) => {
                          return (
                            <TextField
                              name={name}
                              id="email_signup"
                              type="email"
                              label="Email Id"
                              className={clsx(
                                errors?.email
                                  ? "border"
                                  : isDirty && !errors.email && getValues("email") !== undefined
                                  ? "border-success border"
                                  : "border border-grey-450",
                              )}
                              value={value}
                              error={errors.email}
                              onChange={(v) => onChange(v)}
                              required
                              fullWidth
                            />
                          );
                        }}
                      />
                    </div>
                    <div className="w-screen relative sm:w-[400px] sm:pb-0 px-6 sm:px-0">
                      <Controller
                        control={control}
                        name="password"
                        rules={{ required: true, pattern: regexForPassword }}
                        render={({ field: { name, value, onChange, onBlur } }) => (
                          <div style={{ position: "relative" }}>
                            <TextField
                              name={name}
                              id="password_signup"
                              type={showPassword ? "text" : "password"}
                              label="Password"
                              className={clsx(
                                errors?.password
                                  ? "border"
                                  : isDirty &&
                                    !errors.password &&
                                    getValues("password") !== undefined
                                  ? "border-success border"
                                  : "border border-grey-450",
                              )}
                              value={value}
                              error={errors.password}
                              onChange={onChange}
                              onBlur={handleBlur}
                              onFocus={handleFocus}
                              fullWidth
                            />
                            <IconButton
                              edge="end"
                              onClick={handleClickShowPassword}
                              style={{
                                position: "absolute",
                                right: "10px",
                                top: "50%",
                                transform: "translateY(-50%)",
                              }}
                            >
                              {showPassword ? <VisibilityOff /> : <Visibility />}
                            </IconButton>
                          </div>
                        )}
                      />
                      {errors.password && passwordFocus ? (
                        <div className="z-10 rounded-md sm:w-full xs:w-[86%] self-center items-center absolute flex flex-col bg-blue-200">
                          <div className="p-[10px] text-white font-ssp font-bold text-sm w-full flex justify-center items-center bg-blue-500">
                            Password criteria
                          </div>
                          <div className="flex items-center pb-2 mt-3 text-sm font-normal font-ssp text-primary">
                            <span className="mr-1">
                              <DoubleVerifiedIcon />
                            </span>
                            Should be 8 characters at least
                          </div>
                          <div className="flex items-center pb-2 text-sm font-normal font-ssp text-primary">
                            <span className="mr-1">
                              <DoubleVerifiedIcon />
                            </span>
                            Should contain the special characters @$#+*
                          </div>
                          <div className="flex items-center pb-2 text-sm font-normal font-ssp text-primary">
                            <span className="mr-1">
                              <DoubleVerifiedIcon />
                            </span>
                            Should contain a numeric value 1,2..
                          </div>
                          <div className="flex items-center pb-2 text-sm font-normal font-ssp text-primary">
                            <span className="mr-1">
                              <DoubleVerifiedIcon />
                            </span>
                            Should not be the same as your email address
                          </div>
                          <div className="flex items-center pb-2 text-sm font-normal font-ssp text-primary">
                            <span className="mr-1">
                              <DoubleVerifiedIcon />
                            </span>
                            Should contain at least one uppercase letter
                          </div>
                          <div className="flex items-center pb-2 text-sm font-normal font-ssp text-primary">
                            <span className="mr-1">
                              <DoubleVerifiedIcon />
                            </span>
                            Should contain at least one lowercase letter
                          </div>
                        </div>
                      ) : null}
                    </div>

                    <div className="w-screen sm:w-[400px] px-6 sm:px-0 -mt-2 lg:mt-0">
                      <Controller
                        control={control}
                        name="confirmPassword"
                        rules={{
                          required: true,
                          validate: {
                            sameAs: () => {
                              return getValues("password") === getValues("confirmPassword");
                            },
                          },
                        }}
                        render={({ field: { name, value, onChange } }) => (
                          <div style={{ position: "relative" }}>
                            <TextField
                              name={name}
                              id="confirmPassword_signup"
                              type={showConfirmPassword ? "text" : "password"}
                              label="Confirm Password"
                              className={clsx(
                                errors?.confirmPassword
                                  ? "border"
                                  : isDirty &&
                                    !errors.confirmPassword &&
                                    getValues("confirmPassword") !== undefined
                                  ? "border-success"
                                  : "border border-grey-450",
                              )}
                              value={value}
                              error={errors.confirmPassword}
                              onChange={onChange}
                              fullWidth
                            />
                            <IconButton
                              edge="end"
                              onClick={() => setShowConfirmPassword(!showConfirmPassword)}
                              style={{
                                position: "absolute",
                                right: "10px",
                                top: "50%",
                                transform: "translateY(-50%)",
                              }}
                            >
                              {showConfirmPassword ? <VisibilityOff /> : <Visibility />}
                            </IconButton>
                          </div>
                        )}
                      />
                      {errors.confirmPassword && (
                        <p className="text-red-500">Passwords do not match</p>
                      )}
                    </div>
                    <div className="w-screen sm:w-[400px] px-6 sm:px-0 bg-white rounded">
                      <Controller
                        control={control}
                        name="purposeCode"
                        rules={{
                          required: true,
                        }}
                        render={({ field: { name, value, onChange } }) => {
                          return (
                            <SearchableDropdown
                              options={signUpPurposeCodeList}
                              label="Purpose of Transaction"
                              mainClassname={"bg-white w-full rounded pt-1"} // add an onclick prop here ffs retard
                              onChange={(e: any, v: any) => onChange(v)}
                            />
                          );
                        }}
                      />
                    </div>
                    <div className="w-screen sm:w-[400px] px-6 sm:px-0">
                      <Controller
                        control={control}
                        name="refer"
                        // rules={{ pattern: regExpForSpecialChar }}
                        render={({ field: { value, name, onChange } }) => {
                          return (
                            <TextField
                              name={name}
                              type="text"
                              label="Referral Code"
                              className={clsx("border border-grey-450")}
                              value={userData?.refer != "" ? userData?.refer : value}
                              error={errors.refer}
                              disabled={
                                getParameterByName("ir_co", window.location.search) ? true : false
                              }
                              onChange={(v) => onChange(v)}
                              fullWidth
                            />
                          );
                        }}
                      />
                    </div>
                    <div className="justify-center w-full px-10 text-black">
                      <span>By registering, you accept our&nbsp;</span>
                      <span onClick={() => setIsTermsModalOpen(true)} className="text-primary cursor-pointer">
                        Terms of Use
                      </span>
                      <span className="text-black">&nbsp;&&nbsp;</span>
                      <span
                        onClick={() => setIsTermsModalPrivacyOpen(true)}
                        className="text-primary cursor-pointer"
                      >
                        Privacy Policy.
                      </span>
                    </div>
                    {/* <span style={{ width: "full" }}>
                      <ReCAPTCHA
                        sitekey={process.env.REACT_APP_CAPTCHA_SITE_KEY as string}
                        onChange={(e) => {
                          captchaValidate(e);
                        }}
                        ref={recaptchaRef}
                        size="invisible"
                      />
                    </span> */}
                    <div className="mt-10">
                      <Button
                        id="signup-verify-otp"
                        bgColor={`${isValid ? "bg-green-500" : "bg-grey-550"}`}
                        fontStyle={{
                          fontFamily: "font-ssp",
                          fontSize: "text-base",
                          fontWeight: "font-bold",
                          fontColor: "text-white",
                        }}
                        borderStyle={{
                          borderWidth: "border-none",
                          borderRadius: "rounded-[105px]",
                        }}
                        className={"py-[14px] sm:py-[18px] px-32 z-50 transition-all duration-500"}
                        disabled={!isValid}
                        isLoading={buttonLoader}
                      >
                        Verify
                      </Button>
                    </div>
                    {errors.mobileNumber && (
                      <ErrorField errorMessage={errors.mobileNumber.message} />
                    )}
                    {errorText && (
                      <Snackbar open={errorText !== ""} autoHideDuration={6000}>
                        <Alert severity="error" variant="filled">
                          <AlertTitle>Error</AlertTitle>
                          <strong>{errorText}</strong>
                        </Alert>
                      </Snackbar>
                    )}
                    <div
                      onClick={() => navigate("/auth/sign-in")}
                      className="transition-all duration-500 cursor-pointer hover:skew-x-2"
                    >
                      <span className="font-bold text-primary">{"< Go Back to Login"}</span>
                    </div>
                  </form>
                </animated.div>
              </div>
            )}
            {isActiveStep === 2 && (
              <div
                className={clsx(
                  "flex flex-col justify-center lg:mt-5 py-0 xs:mt-[100%] sm:mt-[10%] md:mt-5 mt-3 lg:py-10 lg:w-5/12 items-center",
                )}
              >
                <div className="flex flex-col xs:mt-[200%] sm:mt-[10%] md:mt-5 lg:mt-0 mt-10 items-center">
                  <div className={clsx("mb-6")}>
                    <span className="text-sm font-medium md:text-base text-grey-500 font-lato">
                      Enter Phone Verification OTP
                    </span>
                  </div>
                  <div>
                    <OtpBox
                      updateValue={(value) => saveOtpValue(value)}
                      errorMsg={otpErrorMessage ? otpErrorMessage : ""}
                      errorMessageClassname={
                        otpError
                          ? "border-error bg-transparent text-center font-medium text-sm mt-6"
                          : ""
                      }
                      numberOfBoxes={6}
                    />
                  </div>
                </div>
                <div
                  className={clsx(
                    isOTPVerified ? "flex" : "hidden",
                    "text-success font-lato font-medium text-sm mt-7",
                  )}
                >
                  OTP Verified
                </div>
                <div
                  className={clsx(
                    otpValue.length >= 1 ? "mt-[106px] sm:mt-[131px]" : "mt-[106px]",
                    isOTPVerified && "mt-[72px] md:mt-[136px]",
                    "flex flex-col items-center sm:justify-center justify-end h-full",
                  )}
                >
                  <div className={clsx("pb-8")}>
                    <Countdown
                      key={dateKey}
                      date={date}
                      renderer={({ minutes, seconds, completed }) => {
                        if (completed) {
                          return (
                            <div
                              className="text-xs font-bold text-white cursor-pointer font-lato"
                              onClick={resendOTP}
                            >
                              Resend OTP
                            </div>
                          );
                        } else {
                          return (
                            <div className="flex items-center space-x-2">
                              <div className="text-xs font-bold text-white font-lato">
                                Resend OTP
                              </div>
                              <div className={"text-xs font-bold text-white font-lato"}>
                                {zeroPad(minutes)} :{zeroPad(seconds)}
                              </div>
                            </div>
                          );
                        }
                      }}
                    />
                  </div>
                  <div className="flex">
                    <Button
                      id="signup-step2-confirm"
                      bgColor={`${otpValue.length == 6 ? "bg-green-500" : "bg-grey-500"}`}
                      fontStyle={{
                        fontFamily: "font-ssp",
                        fontSize: "text-lg sm:text-base ",
                        fontWeight: "font-bold",
                        fontColor: "text-white",
                      }}
                      borderStyle={{
                        borderWidth: "border-none",
                        borderRadius: "rounded-[105px]",
                      }}
                      className={
                        "py-[14px] sm:py-[18px] px-[123px] transition-all shadow-xl duration-500"
                      }
                      onClickHandler={verifyOTP}
                      isLoading={buttonLoader}
                    >
                      {"Continue"}
                    </Button>
                  </div>
                </div>
              </div>
            )}
            <FooterView showOffer={true} />
          </div>
          {mobileAppBanner && (
            <div className="fixed top-0 bg-[#eaf5ff] z-50 w-full shadow-lg md:hidden">
              <div className="w-full relative">
                <div className="flex flex-row py-5 justify-center items-center px-4">
                  <MobileIconNew width="10%" height="10%" />
                  <div className="text-[16px] md:text-[20px] font-bold text-center md:text-left px-3">
                    Download the HOP Remit App
                  </div>
                </div>
                <div className="text-[14px] md:text-[16px] font-medium text-center">
                  Smart, swift & secure international money transfers
                </div>
                <div className="text-[14px] md:text-[16px] font-medium text-center">
                  with maximum savings and superior mobile user experience
                </div>
                <div className="flex flex-col content-center mt-4 items-center justify-center py-3">
                  <a
                    href="https://onelink.to/v7au6v?utm_source=nudge&utm_medium=signuphopremit&utm_campaign=appinstall"
                    className="bg-gradient-to-r from-[#50aefe] font-bold to-[#0c61f4] text-white py-2 px-12 md:px-28 text-sm md:text-lg rounded-xl shadow-[#50aefe] shadow-md hover:from-[#0c61f4] hover:to-[#50aefe] transition duration-300"
                  >
                    INSTALL NOW
                  </a>
                  <div className="text-[14px] md:text-[16px] font-bold text-black mt-2 text-center">
                    Get 100% OFF on Convenience Fees on App
                  </div>
                </div>
              </div>
              <div
                onClick={() => setMobileAppBanner(false)}
                className="absolute right-5 top-5 md:right-10 md:top-10 p-2 opacity-50"
              >
                <CloseIcon fill="black" />
              </div>
            </div>
          )}
          <div
            id="divider"
            className="hidden w-5/6 border-2 rounded-full lg:flex border-b-[#093EA7]"
          />
          <div
            id="footer"
            className="flex items-center justify-between w-full py-10 bg-gradient-to-r from-blue-500 to-blue-700 md:py-3 md:px-8"
          >
            <div
              id="left"
              className="flex items-center justify-center w-full space-x-4 md:w-auto md:px-5 md:justify-evenly"
            >
              <span
                onClick={() => setIsTermsModalOpen(true)}
                className="text-white text-[17px] cursor-pointer hover:scale-125 transition-all"
              >
                Terms & Conditions
              </span>
              {/* <span className="text-white text-[17px]">|</span>
              <span className="text-white text-[17px] cursor-pointer hover:scale-125 transition-all">
                Help
              </span> */}
              <span className="text-white text-[17px]">|</span>
              <span
                onClick={() => setIsTermsModalPrivacyOpen(true)}
                className="text-white text-[17px] cursor-pointer hover:scale-125 transition-all"
              >
                Privacy Policy
              </span>
            </div>
            <div id="right" className="text-white font-bold text-[14px] hidden lg:flex">
              Copyright by moneyhop.co ©
            </div>
          </div>
        </div>
      )}
      <ForgotPassword
        isForgotPassword={isForgotPassword}
        setIsForgotPassword={setIsForgotPassword}
      />
      <TermsModal
        isTermsModalOpen={isTermsModalOpen}
        setIsTermsModalOpen={setIsTermsModalOpen}
        modalTitle="Terms and Conditions"
      />
      <TermsModal
        isTermsModalOpen={isTermsModalPrivacyOpen}
        setIsTermsModalOpen={setIsTermsModalPrivacyOpen}
        modalTitle="Privacy Policy"
      />
    </div>
  );
};

export default SignUp;
