//#Global Imports
import { kycDocsId } from "../../utils";
import { Axios } from "../axios";
//#end Global Imports

//#Local Imports
import IKyc from "./kyc";
//#end Local Imports

// const currentUser = JSON.parse(sessionStorage.getItem("currentUser") || "{}");
const vendorKey = process.env.REACT_APP_VENDOR_KEY;

export const uploadDocuments = async (body: FormData) => {
  const currentUser = JSON.parse(sessionStorage.getItem("currentUser") || "{}");
  return await Axios.post(`/remit/users/${currentUser.id}/senders/1/documents`, body, {
    headers: {
      Authorization: `${currentUser.idToken}`,
    },
  });
};

export const eVerifyVoterIdCard = async (body: FormData) => {
  const currentUser = JSON.parse(sessionStorage.getItem("currentUser") || "{}");
  return await Axios.post(
    `/remit/users/${currentUser.id}/documents/${kycDocsId.E_VERIFY_VOTERID}/verifyVoterID`,
    body,
    {
      headers: {
        Authorization: `${currentUser.idToken}`,
      },
    },
  );
};

export const verifyVoterIdCardDocument = async (body: FormData) => {
  const currentUser = JSON.parse(sessionStorage.getItem("currentUser") || "{}");
  return await Axios.post(
    `/remit/users/${currentUser.id}/documents/${kycDocsId.VERIFY_VOTERID_DOC}/verifyDocument`,
    body,
    {
      headers: {
        Authorization: `${currentUser.idToken}`,
      },
    },
  );
};

export const eVerifyDL = async (body: FormData) => {
  const currentUser = JSON.parse(sessionStorage.getItem("currentUser") || "{}");
  return await Axios.post(
    `/remit/users/${currentUser.id}/documents/${kycDocsId.E_VERIFY_DL}/verifyDL`,
    body,
    {
      headers: {
        Authorization: `${currentUser.idToken}`,
      },
    },
  );
};

export const verifyDrivingLicenseDocument = async (body: FormData) => {
  const currentUser = JSON.parse(sessionStorage.getItem("currentUser") || "{}");
  return await Axios.post(
    `/remit/users/${currentUser.id}/documents/${kycDocsId.VERIFY_DL_DOC}/verifyDocument`,
    body,
    {
      headers: {
        Authorization: `${currentUser.idToken}`,
      },
    },
  );
};

export const eVerifyPan = async (body: FormData) => {
  const currentUser = JSON.parse(sessionStorage.getItem("currentUser") || "{}");
  return await Axios.post(
    `/remit/users/${currentUser.id}/documents/${kycDocsId.E_VERIFY_PAN}/verifyPan`,
    body,
    {
      headers: {
        Authorization: `${currentUser.idToken}`,
      },
    },
  );
};

export const uploadAadharBack = async (body: FormData) => {
  const currentUser = JSON.parse(sessionStorage.getItem("currentUser") || "{}");
  return await Axios.put(
    `/remit/users/${currentUser.id}/documents/${kycDocsId.UPLOAD_AADHAR_BACK}`,
    body,
    {
      headers: {
        Authorization: `${currentUser.idToken}`,
      },
    },
  );
};

export const sendAdhatOTP = async (body: IKyc.ISendAdharOtpProps) => {
  const currentUser = JSON.parse(sessionStorage.getItem("currentUser") || "{}");
  return await Axios.post(`/remit/users/aadhar/sendOtp`, body, {
    headers: {
      Authorization: `${currentUser.idToken}`,
    },
  });
};

export const validateAdharOTP = async (body: IKyc.IValidateAdharOtpProps) => {
  const currentUser = JSON.parse(sessionStorage.getItem("currentUser") || "{}");
  return await Axios.post(`/remit/users/aadhar/validateOtp`, body, {
    headers: {
      Authorization: `${currentUser.idToken}`,
    },
  });
};

export const verifyAadharCard = async (body: FormData) => {
  const currentUser = JSON.parse(sessionStorage.getItem("currentUser") || "{}");
  return await Axios.post(
    `/remit/users/${currentUser.id}/documents/${kycDocsId.VERIFY_AADHAR_CARD}/verifyDoc`,
    body,
    {
      headers: {
        Authorization: `${currentUser.idToken}`,
      },
    },
  );
};

export const verifyPassportFront = async (body: FormData) => {
  const currentUser = JSON.parse(sessionStorage.getItem("currentUser") || "{}");
  return await Axios.post(
    `/remit/users/${currentUser.id}/documents/${kycDocsId.PASSPORT_FRONT}/verifyDoc`,
    body,
    {
      headers: {
        Authorization: `${currentUser.idToken}`,
      },
    },
  );
};

export const verifyPassportBack = async (body: FormData) => {
  const currentUser = JSON.parse(sessionStorage.getItem("currentUser") || "{}");
  return await Axios.post(
    `/remit/users/${currentUser.id}/documents/${kycDocsId.PASSPORT_BACK}/verifyPassport`,
    body,
    {
      headers: {
        Authorization: `${currentUser.idToken}`,
      },
    },
  );
};

export const uploadAdditionalDocs = async ({ body, code, purpose }: IKyc.IUploadAdditionalDocs) => {
  const currentUser = JSON.parse(sessionStorage.getItem("currentUser") || "{}");
  return await Axios.post(
    `/remit/user/${vendorKey}/lead/upload/doc?docType=${code}&purpose=${purpose}`,
    body,
    {
      headers: {
        Authorization: `${currentUser.idToken}`,
      },
    },
  );
};

export const getCkycStatus = async (ckycUserId: number) => {
  const currentUser = JSON.parse(sessionStorage.getItem("currentUser") || "{}");

  return await Axios.get(`/remit/kyc/ckyc/status/${ckycUserId}`, {
    headers: {
      Authorization: `${currentUser.idToken}`,
    },
  });
};

export const searchCkyc = async (ckycUserId: number) => {
  const currentUser = JSON.parse(sessionStorage.getItem("currentUser") || "{}");

  return await Axios.post(
    `/remit/kyc/ckyc/search/${ckycUserId}`,
    {},
    {
      headers: {
        Authorization: `${currentUser?.idToken}`,
      },
    },
  );
};

export const downloadCKYC = async (ckycNumber: string, ckycUserId: number) => {
  const currentUser = JSON.parse(sessionStorage.getItem("currentUser") || "{}");

  return await Axios.post(
    `/remit/kyc/ckyc/download/${ckycUserId}/ckycNumber/${ckycNumber}`,
    {},
    {
      headers: {
        Authorization: `${currentUser.idToken}`,
      },
    },
  );
};

export const sendCkycOtp = async (ckycNumber: string, ckycUserId: number) => {
  const currentUser = JSON.parse(sessionStorage.getItem("currentUser") || "{}");

  return await Axios.get(`remit/kyc/ckyc/sendMobileOtp/${ckycUserId}/ckycNumber/${ckycNumber}`, {
    headers: {
      Authorization: `${currentUser.idToken}`,
    },
  });
};

export const validateCkycOtp = async (body: IKyc.IvalidateCkycOtpProps) => {
  const currentUser = JSON.parse(sessionStorage.getItem("currentUser") || "{}");

  return await Axios.post(`remit/kyc/ckyc/validateMobileOTP`, body, {
    headers: {
      Authorization: `${currentUser.idToken}`,
    },
  });
};

export const getAdditionalDox = async(purposeCode:string)=>{
  const currentUser = JSON.parse(sessionStorage.getItem("currentUser") || "{}");
  const recipient = JSON.parse(sessionStorage.getItem("recipient_id") || "{}");
  if (currentUser !== null && currentUser !== undefined) {
    return await Axios.get(`remit/user/${currentUser.id}/lead/upload/doc/${recipient}?purposeCode=${purposeCode}`, {
      headers: {
        Authorization: `${currentUser.idToken}`,
      },
    });
  }
};

export const getAdditionalDocFile = async (docId: string) => {
  const currentUser = JSON.parse(sessionStorage.getItem("currentUser") || "{}");
  if (currentUser !== null && currentUser !== undefined) {
    return await Axios.get(`remit/user/${currentUser.id}/recipient/docId/${docId}`, {
      responseType: 'blob',
      headers: {
        Authorization: `${currentUser.idToken}`,
        'Content-Type': 'application/json'
      },
    });
  }
};

export const getRemitterDetails = async () => {
  const currentUser = JSON.parse(sessionStorage.getItem("currentUser") || "{}");
  if (currentUser !== null && currentUser !== undefined) {
    return await Axios.get(`/remit/auth/user/${currentUser.id}`, {
      headers: {
        Authorization: `${currentUser.idToken}`,
      },
    });
  }
};

export const getUserDetails = async (body: IKyc.UserDetailProps) => {
  const currentUser = JSON.parse(sessionStorage.getItem("currentUser") || "{}");
  if (currentUser !== null || currentUser !== undefined) {
    return await Axios.put(`remit/auth/user/${currentUser.id}`, body, {
      headers: {
        Authorization: `${currentUser.idToken}`,
      },
    });
  }
};

export const noAadharValidation = async (body?: any) => {
  const currentUser = JSON.parse(sessionStorage.getItem("currentUser") || "{}");
  if(currentUser !== null || currentUser !== undefined) {
    return await Axios.post(`remit/users/${currentUser.id}/validate/aadhar?docName=AADHAR_CARD`, body,  {
      headers: {
        Authorization: `${currentUser.idToken}`,
      },
    });
  }
};
