import React from "react";

const NoHistoryBanner = () => (
  <svg
    width="369"
    height="180"
    viewBox="0 0 369 180"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M107.344 66.892C107.344 66.892 112.375 83.3802 114.929 86.9125C117.006 89.7813 146.174 97.2668 155.749 99.7417L159.005 87.9993C158.041 87.5057 128.426 76.4063 128.426 76.4063L123.47 60.5L107.342 66.8942L107.344 66.892Z"
      fill="#F9B498"
    />
    <path
      d="M166.32 90.386L166.687 90.2433C167.454 90.5558 169.032 91.0834 170.844 91.8034C170.332 93.3635 169.977 94.5839 170.156 94.6722L169.999 94.9983C169.961 95.082 169.959 95.1726 169.986 95.2541C169.282 96.7734 168.34 98.6822 167.658 100.331C167.173 101.501 167.146 102.656 165.953 102.93C165.262 103.088 162.299 102.045 161.273 101.596L154.17 99.2098C155.091 97.19 158.105 87.927 158.576 87.7617L166.318 90.386H166.32Z"
      fill="#F9B498"
    />
    <path
      d="M174.463 86.6924L172.333 85.4017L172.192 85.318L170.062 84.0273L153.716 107.213C153.257 107.865 153.447 108.771 154.128 109.183L159.549 112.466C160.23 112.881 161.12 112.627 161.487 111.918L174.463 86.6901V86.6924Z"
      fill="#4CB7D8"
    />
    <path
      d="M170.645 80.8633L176.958 84.6871L175.405 87.2513C175.233 87.536 174.863 87.6269 174.578 87.4545L169.295 84.2547C169.01 84.0823 168.919 83.7123 169.092 83.4276L170.645 80.8633Z"
      fill="#F99247"
    />
    <path
      d="M173.147 86.7347L169.71 84.6539C169.103 84.2871 168.908 83.4946 169.275 82.8878L170.536 80.8047L170.747 80.9315L169.486 83.0146C169.189 83.5059 169.345 84.1467 169.837 84.4433L173.274 86.5242L173.147 86.7347Z"
      fill="#21111D"
    />
    <g>
      <g>
        <path
          d="M191.497 79.3084C196.058 73.4174 194.98 64.944 189.089 60.3827C183.198 55.8213 174.725 56.8993 170.163 62.7903C165.602 68.6814 166.68 77.1547 172.571 81.7161C178.462 86.2774 186.935 85.1995 191.497 79.3084Z"
          fill="#D4EEF4"
        />
      </g>
    </g>
    <g opacity="0.6">
      <path
        d="M190.228 73.7614C188.93 74.0807 187.336 72.1357 186.668 69.4186C186 66.7015 186.512 64.2403 187.809 63.921C189.107 63.6018 190.701 65.5468 191.369 68.2639C192.037 70.981 191.525 73.4422 190.228 73.7614Z"
        fill="#F3FFFE"
      />
    </g>
    <path
      d="M169.671 84.4808L153.406 107.461L153.606 107.602L169.871 84.6221L169.671 84.4808Z"
      fill="#21111D"
    />
    <path
      d="M177.989 59.7328L177.9 59.5041C177.93 59.4927 180.803 58.4082 183.59 59.3705L183.511 59.6014C180.81 58.6686 178.016 59.7214 177.989 59.7328Z"
      fill="#21111D"
    />
    <path
      d="M177.866 61.4163L177.777 61.1877C177.807 61.1763 180.68 60.0918 183.467 61.0541L183.388 61.285C180.687 60.3521 177.893 61.405 177.866 61.4163Z"
      fill="#21111D"
    />
    <path
      d="M169.296 64.0714C173.156 57.6998 181.45 55.662 187.822 59.5203C194.193 63.3808 196.231 71.6747 192.371 78.0463C188.51 84.4179 180.216 86.4557 173.845 82.5975C167.473 78.7369 165.435 70.443 169.296 64.0714ZM172.735 84.4315C180.121 88.9057 189.735 86.544 194.207 79.1581C198.681 71.7721 196.319 62.1581 188.933 57.6862C181.547 53.2121 171.933 55.5737 167.462 62.9596C162.987 70.3456 165.349 79.9596 172.735 84.4315Z"
      fill="#FEC786"
    />
    <path
      d="M172.67 84.5364C165.239 80.035 162.855 70.326 167.356 62.8947C171.857 55.4634 181.566 53.0792 188.998 57.5805C192.482 59.6908 194.984 63.017 196.046 66.9477L195.808 67.0111C194.765 63.1415 192.299 59.8674 188.869 57.7888C181.553 53.3577 171.995 55.7057 167.564 63.0215C163.133 70.3373 165.481 79.8947 172.797 84.3258C176.193 86.3817 180.199 87.0565 184.08 86.2255L184.132 86.4655C180.19 87.3101 176.121 86.624 172.67 84.5364Z"
      fill="#21111D"
    />
    <path
      d="M170.819 96.3532C170.529 97.234 169.809 97.4944 169.662 97.4514L165.086 96.1041C164.531 95.9411 164.232 95.2301 164.443 94.4694C164.671 93.6339 165.376 93.0927 165.973 93.267L170.529 94.6075C170.529 94.6075 171.109 95.4724 170.819 96.3532Z"
      fill="#F9B498"
    />
    <path
      d="M170.024 98.8932C169.747 99.7786 169.091 100.342 168.944 100.302L163.969 98.9793C163.41 98.8298 163.1 98.1302 163.299 97.3671C163.516 96.5294 164.214 95.9724 164.816 96.1331L169.917 97.4894C169.917 97.4894 170.302 98.0102 170.024 98.8932Z"
      fill="#F9B498"
    />
    <path
      d="M169.978 97.8059C170.075 97.8059 170.175 97.7176 170.216 97.5908C170.263 97.4436 170.216 97.2987 170.105 97.267L165.169 95.8405C164.704 95.7047 164.473 95.1205 164.641 94.5114C164.732 94.1854 164.91 93.9023 165.148 93.7167C165.386 93.531 165.646 93.4653 165.884 93.5355L170.395 94.8624C170.503 94.8918 170.63 94.8012 170.678 94.6541C170.725 94.5069 170.678 94.362 170.569 94.3303L166.058 93.0034C165.719 92.9038 165.327 92.9989 164.983 93.2683C164.634 93.54 164.372 93.9544 164.24 94.4299C163.996 95.322 164.329 96.1734 164.999 96.3704L169.935 97.7968C169.948 97.8014 169.964 97.8036 169.978 97.8036V97.8059Z"
      fill="#F07162"
    />
    <path
      d="M171.504 93.1967C171.241 94.0866 170.564 94.639 170.417 94.5983L165.465 93.2307C164.919 93.079 164.605 92.4042 164.786 91.6525C164.994 90.7921 165.707 90.217 166.319 90.3845L170.94 91.6615C171.454 91.8042 171.655 92.6805 171.504 93.1967Z"
      fill="#F9B498"
    />
    <path
      d="M170.378 94.8482C170.476 94.8482 170.575 94.7576 170.616 94.6308C170.661 94.4836 170.612 94.341 170.503 94.3115L165.54 92.9666C165.073 92.8375 164.836 92.2601 164.996 91.6488C165.082 91.3227 165.261 91.0374 165.497 90.8472C165.734 90.6571 165.995 90.5891 166.233 90.6548L170.766 91.9069C170.874 91.9341 171.001 91.8413 171.046 91.6941C171.094 91.5469 171.042 91.4042 170.933 91.3725L166.4 90.1204C166.058 90.0253 165.667 90.1295 165.325 90.4012C164.978 90.6774 164.718 91.094 164.591 91.5718C164.353 92.4639 164.695 93.3107 165.37 93.4964L170.333 94.8414C170.347 94.8459 170.363 94.8459 170.376 94.8459L170.378 94.8482Z"
      fill="#F07162"
    />
    <path
      d="M168.785 100.387C168.885 100.387 168.987 100.294 169.025 100.162C169.071 100.015 169.016 99.8725 168.908 99.8454L164.033 98.6453C163.562 98.5208 163.342 98.0091 163.498 97.4022C163.582 97.0762 163.759 96.7909 163.996 96.5984C164.232 96.406 164.492 96.3358 164.732 96.3992L169.831 97.7555C169.94 97.7849 170.067 97.6875 170.112 97.5404C170.157 97.3932 170.108 97.2505 169.997 97.2211L164.898 95.8648C164.553 95.772 164.162 95.8784 163.822 96.1569C163.478 96.4377 163.22 96.8543 163.095 97.3321C162.864 98.2219 163.19 98.9985 163.87 99.1797L168.747 100.38C168.76 100.382 168.772 100.384 168.785 100.384V100.387Z"
      fill="#F07162"
    />
    <path
      d="M160.378 90.6416C159.832 91.4387 159.624 93.1436 159.755 93.2365L164.32 95.5437C164.8 95.8856 165.554 95.6977 166.04 95.0547C166.6 94.321 166.595 93.3655 166.056 92.9829L161.987 90.0869C161.535 89.7654 160.692 90.1775 160.375 90.6416H160.378Z"
      fill="#F9B498"
    />
    <path
      d="M159.331 93.3964C159.27 93.3171 159.274 93.1768 159.351 93.0613C159.44 92.9277 159.589 92.8734 159.684 92.9435L164.485 95.3052C164.894 95.5972 165.519 95.4093 165.906 94.8795C166.113 94.5942 166.226 94.2636 166.223 93.9489C166.223 93.6341 166.106 93.376 165.895 93.2288L161.903 90.3872C161.808 90.317 161.801 90.154 161.89 90.0204C161.978 89.8868 162.127 89.8325 162.223 89.9004L166.214 92.742C166.516 92.9571 166.69 93.3398 166.694 93.7972C166.697 94.2591 166.534 94.7436 166.23 95.158C165.664 95.9369 164.756 96.2154 164.163 95.792L159.363 93.4304C159.349 93.4213 159.34 93.41 159.329 93.3987L159.331 93.3964Z"
      fill="#F07162"
    />
    <path
      d="M168.673 101.564C168.421 102.391 167.815 102.923 167.676 102.889L163.021 101.729C162.498 101.598 162.202 100.953 162.38 100.242C162.575 99.4606 163.223 98.933 163.786 99.0734L168.56 100.262C168.56 100.262 168.924 100.74 168.673 101.566V101.564Z"
      fill="#F9B498"
    />
    <path
      d="M167.651 102.967C167.75 102.967 167.852 102.874 167.891 102.745C167.936 102.598 167.882 102.455 167.773 102.428L163.118 101.269C162.688 101.162 162.459 100.639 162.599 100.08C162.674 99.7811 162.835 99.5162 163.05 99.3396C163.265 99.1607 163.503 99.0928 163.722 99.1471L168.495 100.336C168.604 100.363 168.729 100.266 168.774 100.118C168.819 99.9713 168.765 99.8287 168.656 99.8015L163.883 98.6128C163.557 98.5312 163.188 98.6377 162.869 98.9026C162.545 99.172 162.305 99.566 162.192 100.019C161.981 100.863 162.316 101.647 162.955 101.808L167.61 102.967C167.624 102.969 167.635 102.971 167.649 102.971L167.651 102.967Z"
      fill="#F07162"
    />
    <path
      d="M114.534 33.6041C114.534 33.6041 115.843 45.2921 115.363 53.6223C114.883 61.9547 110.76 118.296 110.76 118.296C110.76 118.296 93.3475 120.449 82.3161 119.295C71.2846 118.142 54.5156 111.376 54.5156 111.376L60.8012 56.1447C60.8012 56.1447 63.9145 33.1082 66.532 29.9518C69.1495 26.7955 88.5654 28.625 88.5654 28.625C88.5654 28.625 109.659 31.5776 114.534 33.6018V33.6041Z"
      fill="#C6D9FF"
    />
    <path
      d="M94.3072 4.8343L108.49 9.59374L107.625 21.5988C107.625 21.5988 107.501 25.6699 103.937 25.9212L104.206 31.3826C104.306 33.4136 102.972 35.2545 101.007 35.7277C100.047 35.9586 98.9104 36.0968 97.6379 36.0062C93.6053 35.7164 91.6399 32.5147 91.4135 30.9682C91.1871 29.4218 92.7177 22.2237 92.7177 22.2237L92.8513 18.6417L94.3072 4.83203V4.8343Z"
      fill="#F9B59D"
    />
    <path
      d="M102.486 14.4778C100.725 12.8271 98.5373 14.7676 98.4467 14.8514C98.4105 14.8831 98.3652 14.8967 98.3221 14.8944C98.2814 14.8921 98.2406 14.8718 98.2089 14.8378C98.1478 14.7676 98.1523 14.6612 98.2203 14.5978C98.2452 14.5751 100.727 12.363 102.717 14.2264C102.785 14.2898 102.79 14.3963 102.726 14.4665C102.663 14.5344 102.556 14.5389 102.488 14.4755L102.486 14.4778Z"
      fill="black"
    />
    <path
      d="M107.718 13.9484C106.973 13.2057 105.447 14.3378 105.434 14.3491C105.4 14.374 105.359 14.3854 105.32 14.3831C105.273 14.3786 105.228 14.3559 105.198 14.3152C105.141 14.2405 105.157 14.134 105.232 14.0774C105.305 14.0231 107.007 12.7596 107.956 13.7061C108.022 13.7718 108.022 13.8782 107.956 13.9461C107.89 14.0118 107.784 14.014 107.718 13.9461V13.9484Z"
      fill="black"
    />
    <path
      d="M100.662 15.7536C100.682 15.4728 100.924 15.2622 101.203 15.2826C101.481 15.303 101.69 15.5453 101.669 15.826C101.649 16.1068 101.407 16.3174 101.128 16.297C100.849 16.2766 100.641 16.0343 100.659 15.7536H100.662Z"
      fill="black"
    />
    <path
      d="M106.335 15.7536C106.356 15.4728 106.598 15.2622 106.877 15.2826C107.155 15.303 107.363 15.5453 107.343 15.826C107.323 16.1068 107.08 16.3174 106.802 16.297C106.523 16.2766 106.315 16.0343 106.333 15.7536H106.335Z"
      fill="black"
    />
    <path
      d="M96.854 10.0707L94.2184 6.76493C94.2184 6.76493 94.001 6.13321 93.7565 5.18449C93.5119 4.23577 96.4555 2.09153 98.9054 0.909589C101.355 -0.274613 102.653 2.524 104.736 1.76774C108.551 0.382019 111.422 2.5806 111.984 3.59951C113.123 5.66677 115.74 7.87215 111.087 11.5017C106.434 15.1313 96.8517 10.0707 96.8517 10.0707H96.854Z"
      fill="black"
    />
    <path
      d="M93.6875 22.3827C88.3779 22.5661 89.6775 15.0692 89.7206 13.9461C89.7636 12.8231 88.4503 10.7513 89.8021 7.07189C91.1561 3.39249 95.0212 3.85892 95.0212 3.85892L94.5321 4.9118L96.0084 10.0109L93.6875 22.3827Z"
      fill="black"
    />
    <path
      d="M91.4708 14.9157C91.5908 13.2356 93.0445 11.9699 94.7155 12.0899C96.3865 12.2099 97.6409 13.6704 97.5209 15.3504C97.4009 17.0305 95.9472 18.2962 94.2762 18.1762C92.6052 18.0562 91.3508 16.5958 91.4708 14.9157Z"
      fill="#F9B59D"
    />
    <path
      d="M94.6225 13.967C94.0496 13.5934 93.2933 13.7813 93.2865 13.7836C93.2413 13.7949 93.196 13.7677 93.1847 13.7224C93.1733 13.6771 93.2005 13.6319 93.2458 13.6183C93.2798 13.6092 94.079 13.41 94.713 13.8243C95.0798 14.0621 95.3131 14.4538 95.4059 14.9881L95.458 15.287L95.2587 15.0583C95.2315 15.0266 94.5613 14.2704 93.6737 14.7391C93.1824 14.9995 93.1009 15.5316 93.1824 15.9097C93.2662 16.3014 93.5696 16.7701 94.147 16.8109C94.1538 16.8109 94.1583 16.8109 94.1651 16.8109C94.2104 16.8131 94.2466 16.8539 94.2421 16.9014C94.2398 16.949 94.199 16.9829 94.1538 16.9807H94.1356C93.5084 16.9354 93.1213 16.4282 93.0171 15.9436C92.8948 15.3708 93.1213 14.8387 93.5945 14.5874C94.2941 14.216 94.8828 14.5285 95.1795 14.7572C95.073 14.4017 94.8874 14.1368 94.6225 13.9647V13.967Z"
      fill="#F07162"
    />
    <path
      d="M96.0028 21.8867C98.3893 26.6348 103.934 25.9239 103.934 25.9239L104.134 29.952C95.7514 28.6953 96.0028 21.8867 96.0028 21.8867Z"
      fill="#F07162"
    />
    <path
      d="M104.745 14.6527C104.792 14.6482 104.831 14.6844 104.833 14.732L105.086 18.8982L103.228 19.3714C103.228 19.3714 103.209 19.3737 103.2 19.3737C103.164 19.3714 103.135 19.3465 103.126 19.3103C103.114 19.265 103.141 19.2197 103.187 19.2084L104.912 18.7691L104.668 14.7433C104.665 14.6957 104.699 14.6573 104.747 14.6527H104.745Z"
      fill="#F07162"
    />
    <path
      d="M105.017 22.3875C104.964 22.3943 101.684 22.2358 101.684 22.2358C101.684 22.2358 102.193 20.9022 103.413 20.9588C104.634 21.0154 105.019 22.3898 105.019 22.3898L105.017 22.3875Z"
      fill="#FBFDFC"
    />
    <path
      d="M114.534 33.6016C114.534 33.6016 121.719 36.1194 122.996 40.7362C124.273 45.353 128.881 75.8841 128.881 75.8841L159.289 86.8204L154.17 100.386C154.17 100.386 116.923 89.5873 115.469 88.5616C114.016 87.5359 110.76 82.1516 110.76 82.1516C110.76 82.1516 109.016 54.9693 109.401 52.9179C109.786 50.8664 114.534 33.6038 114.534 33.6038V33.6016Z"
      fill="#C6D9FF"
    />
    <path
      d="M89.7701 120.065C86.1518 120.065 82.5811 119.877 79.5357 119.375C79.3976 119.352 79.307 119.223 79.3296 119.085C79.3523 118.947 79.4813 118.854 79.6195 118.879C90.5784 120.695 108.434 118.362 110.526 118.077C110.776 114.642 114.036 69.4411 114.622 61.0679C115.227 52.4162 114.856 38.1492 114.853 38.0043C114.849 37.8661 114.96 37.7507 115.098 37.7461C115.234 37.7439 115.352 37.8525 115.356 37.9907C115.361 38.1333 115.732 52.4275 115.125 61.1018C114.518 69.7649 111.049 117.833 111.013 118.317L111 118.519L110.798 118.548C110.655 118.568 100.023 120.067 89.7723 120.067L89.7701 120.065Z"
      fill="black"
    />
    <g>
      <g>
        <path
          d="M123.08 81.3689C122.647 80.6716 123.99 80.0806 125.691 78.2986C127.221 76.9469 128.74 74.9204 128.883 76.4012C128.439 76.8065 123.519 82.1388 123.08 81.3689Z"
          fill="#C6D9FF"
        />
      </g>
    </g>
    <path
      d="M91.1143 28.9336L90.9219 31.7707C90.9219 31.7707 91.7868 34.5104 91.9068 34.6553C92.0268 34.8003 93.879 36.0977 94.1914 36.2901C94.5039 36.4826 97.341 37.0351 97.6535 37.18C97.966 37.3249 100.586 37.1324 100.851 37.0124C101.116 36.8924 102.34 36.6026 102.703 36.2199C103.063 35.835 103.905 34.0553 104.025 33.7429C104.145 33.4304 104.53 31.7231 104.435 31.5782C104.34 31.4333 102.993 30.5933 102.488 30.5933C101.983 30.5933 99.0007 30.6408 98.3056 30.3057C97.6082 29.9684 91.1166 28.9359 91.1166 28.9359L91.1143 28.9336Z"
      fill="#C6D9FF"
    />
    <g>
      <g>
        <path
          d="M95.8778 99.8352L95.0332 99.6472C96.7042 92.0982 100.336 34.1584 100.372 33.5742L101.235 33.6286C101.086 36.0173 97.5646 92.2205 95.8778 99.8329V99.8352Z"
          fill="#C6D9FF"
        />
      </g>
    </g>
    <path
      d="M99.4824 30.9258C100.051 32.4632 100.886 33.7267 101.654 35.0218C114.34 56.4484 151.701 46.7778 135.93 74.5873C134.873 69.7056 136.485 63.1528 132.561 58.9436C132.008 58.3526 131.345 57.8771 130.614 57.5307C118.767 51.9199 100.345 49.9002 99.4847 30.9258H99.4824Z"
      fill="#33192C"
    />
    <path
      d="M98.5098 33.4431L100.09 35.0326L101.594 34.9353L102.898 32.431L101.243 30.4883L99.0804 30.5155L98.5098 33.4431Z"
      fill="#49263E"
    />
    <path
      d="M100.325 29.6557L103.903 27.5952L105.275 31.3448L102.361 34.2068L100.239 31.2157L98.328 34.0687L90.5254 28.9107L92.0968 25.3672L100.325 29.6557Z"
      fill="#9EBEE5"
    />
    <path
      d="M44.7073 177.615H54.6474C54.6474 177.615 55.7478 170.664 54.6474 170.664H48.3482L43.5254 175.487L44.7073 177.615Z"
      fill="#F9B498"
    />
    <path d="M122.949 170.145H122.754V174.92H122.949V170.145Z" fill="black" />
    <path
      d="M125.203 174.049L125.311 173.883L124.075 173.027L123.969 173.193L125.203 174.049Z"
      fill="black"
    />
    <path
      d="M54.2857 166.127L43.7909 170.567L41.547 177.122L31.7542 175.66L30.0786 176.368C29.5012 176.613 28.8016 176.436 28.4461 175.9C27.9684 175.18 28.2604 174.249 28.9963 173.936L53.3959 163.614C53.5747 163.537 53.783 163.625 53.8623 163.809L54.5529 165.439C54.6661 165.708 54.5461 166.016 54.2834 166.125L54.2857 166.127Z"
      fill="#3D1A26"
    />
    <path
      d="M54.1867 166.156L56.057 170.576C56.057 170.576 55.3211 173.764 52.1036 173.658C52.1036 173.658 46.7804 172.417 44.7086 177.521L34.0847 177.235C34.0847 177.235 31.2884 177.43 30.0566 176.366L54.1867 166.159V166.156Z"
      fill="#FFA077"
    />
    <path
      d="M28.2575 174.503C28.0764 174.876 28.0651 175.311 28.2281 175.698C28.536 176.425 29.3602 176.771 30.0689 176.472L54.1945 166.267C54.5749 166.107 54.7492 165.661 54.5839 165.271L54.0677 164.051L53.8888 164.126L54.405 165.346C54.5273 165.633 54.3982 165.966 54.1175 166.084L29.9919 176.289C29.3828 176.547 28.6719 176.248 28.407 175.623C28.2666 175.291 28.2756 174.915 28.4319 174.593L28.2575 174.503Z"
      fill="black"
    />
    <path
      d="M123.446 177.588H54.0449V164.741C61.406 161.098 77.9418 156.608 79.6038 156.164C79.6921 156.139 79.7396 156.128 79.7396 156.128C79.7396 156.128 71.9732 149.396 64.4899 140.319C60.6361 134.826 59.1463 129.804 58.6527 126.027C58.4059 122.775 58.7727 120.165 58.9923 118.981C59.0761 118.537 59.1372 118.29 59.1372 118.29L75.2745 115.156L92.2903 126.55C92.2903 126.55 119.276 159.418 121.234 166.156C123.195 172.892 123.449 177.591 123.449 177.591L123.446 177.588Z"
      fill="#33192C"
    />
    <path
      d="M82.7514 158.234C82.7287 158.311 82.6608 158.363 82.5838 158.363L82.5385 158.356C82.4932 158.345 81.8932 158.155 79.6018 156.162C77.5821 154.407 74.2469 151.246 68.8195 145.568C67.1167 143.786 65.688 142.029 64.488 140.317C60.6342 134.824 59.1443 129.802 58.6507 126.025C58.1005 121.825 58.7775 119.162 58.7911 119.106C58.8137 119.017 58.9021 118.965 58.9881 118.979C58.9926 118.979 58.9994 118.979 59.0062 118.981C59.099 119.006 59.1534 119.101 59.1307 119.196C59.099 119.321 55.9857 131.625 69.0753 145.323C80.435 157.213 82.5499 157.992 82.6359 158.019C82.7242 158.048 82.7785 158.146 82.7514 158.236V158.234Z"
      fill="#20111D"
    />
    <path
      d="M54.0442 177.766C53.9537 177.766 53.8767 177.698 53.8699 177.607C53.6367 175.119 53.8586 165.163 53.8699 164.739V164.626L53.9763 164.581C54.1212 164.518 68.5354 158.28 79.7027 155.954L79.8748 155.918L79.911 156.09C79.911 156.09 80.074 156.844 80.7556 158.105C80.8008 158.191 80.7691 158.298 80.6831 158.345C80.5993 158.391 80.4906 158.359 80.4454 158.273C79.9201 157.301 79.6959 156.631 79.6099 156.332C69.0019 158.572 55.5341 164.293 54.2186 164.859C54.1937 165.992 54.0012 175.246 54.2186 177.573C54.2276 177.671 54.1552 177.757 54.0601 177.766H54.042H54.0442Z"
      fill="#20111D"
    />
    <path
      d="M57.1861 175.403C57.0978 175.403 57.0209 175.338 57.0118 175.247C57.005 175.175 56.2284 167.992 57.2405 163.308C57.2609 163.213 57.3537 163.152 57.4488 163.172C57.5439 163.192 57.605 163.287 57.5846 163.38C56.5838 168.011 57.3537 175.136 57.3605 175.209C57.3718 175.306 57.3016 175.392 57.2043 175.403H57.1839H57.1861Z"
      fill="#20111D"
    />
    <path
      d="M121.65 173.76L105.853 173.837L105.717 160.188L121.462 158.508L121.65 173.76Z"
      fill="#F9B498"
    />
    <path
      d="M105.702 175.549H119.107L124.541 169.477L134.481 175.549H136.621C137.359 175.549 138.033 176.061 138.174 176.804C138.362 177.804 137.619 178.678 136.677 178.678H105.512C105.283 178.678 105.098 178.488 105.098 178.253V176.17C105.098 175.826 105.369 175.549 105.704 175.549H105.702Z"
      fill="#3D1A26"
    />
    <path
      d="M105.82 175.567V169.922C105.82 169.922 108.078 166.805 111.515 168.396C111.515 168.396 116.714 172.18 121.296 167.602L132.677 172.78C132.677 172.78 135.797 173.851 136.641 175.567H105.82Z"
      fill="#FFA077"
    />
    <path
      d="M137.737 178.409C138.106 178.09 138.317 177.621 138.317 177.128C138.317 176.199 137.581 175.445 136.677 175.445H105.861C105.376 175.445 104.982 175.851 104.982 176.346V177.907H105.211V176.346C105.211 175.977 105.503 175.679 105.861 175.679H136.677C137.456 175.679 138.088 176.328 138.088 177.125C138.088 177.551 137.907 177.954 137.59 178.23L137.737 178.409Z"
      fill="black"
    />
    <path
      d="M136.572 175.611L136.779 175.512C136.754 175.455 136.129 174.144 133.799 173.125C131.51 172.124 121.443 167.544 121.341 167.496L121.248 167.711C121.35 167.756 131.419 172.339 133.71 173.34C135.952 174.321 136.568 175.598 136.572 175.611Z"
      fill="black"
    />
    <path
      d="M55.7036 108.644L110.956 94.7389C116.85 93.2558 122.859 96.6952 124.578 102.523C125.223 104.711 125.635 106.954 125.861 108.841C126.31 112.552 125.95 120.298 125.884 125.243C125.728 136.836 122.594 167.229 122.594 167.229H105.202L103.155 129.117C102.456 129.307 76.2019 141.953 58.706 130.471C53.4552 127.025 53.9171 114.479 54.2296 110.267C54.2907 109.452 54.8976 108.784 55.7014 108.644H55.7036Z"
      fill="#49263E"
    />
    <path
      d="M105.003 167.241L102.951 129.05L106.144 125.277L106.452 125.538L103.361 129.188L105.403 167.218L105.003 167.241Z"
      fill="#1D1D1B"
    />
    <path
      d="M78.1792 135.466C78.0886 135.466 77.998 135.466 77.9075 135.466C66.9485 135.312 58.7134 132.244 55.8809 127.258L56.2296 127.059C58.9919 131.918 67.0979 134.911 77.912 135.063C86.7901 135.187 102.921 128.995 103.084 128.931L103.229 129.307C103.068 129.37 87.1909 135.468 78.1792 135.468V135.466Z"
      fill="#1D1D1B"
    />
    <path
      d="M59.8884 162.859L0.0488281 150.442L8.39485 110.227L23.1872 113.297L23.0219 114.094C22.9766 114.314 23.117 114.531 23.3389 114.576L30.2131 116.003C30.435 116.048 30.6501 115.905 30.6954 115.686L30.8607 114.889L45.3134 117.889L45.1481 118.686C45.1028 118.905 45.2432 119.123 45.4651 119.168L52.3394 120.595C52.559 120.64 52.7764 120.497 52.8216 120.278L52.9869 119.481L68.2344 122.644L59.8906 162.859H59.8884Z"
      fill="#F99247"
    />
    <g opacity="0.5">
      <path
        d="M22.17 113.084L20.9551 112.832L12.6119 153.049L13.8268 153.301L22.17 113.084Z"
        fill="#21111D"
      />
    </g>
    <g opacity="0.5">
      <path
        d="M55.5548 120.01L54.3398 119.758L45.9966 159.975L47.2116 160.227L55.5548 120.01Z"
        fill="#21111D"
      />
    </g>
    <path
      d="M28.4001 114.375L25.7871 113.832L26.5728 109.72C27.0257 107.542 29.1654 106.136 31.3436 106.589L48.3254 110.112C50.5036 110.565 51.9097 112.704 51.4569 114.885L50.601 118.981L47.9994 118.442L48.8553 114.346C49.0092 113.601 48.5292 112.87 47.7865 112.716L30.8047 109.193C30.0597 109.039 29.3284 109.519 29.1744 110.264L28.4001 114.378V114.375Z"
      fill="#280F22"
    />
    <path
      d="M30.2114 115.999L23.3371 114.572C23.1175 114.525 22.9748 114.31 23.0201 114.09L23.1854 113.293L30.8589 114.887L30.6936 115.684C30.6484 115.904 30.4333 116.046 30.2114 116.001V115.999Z"
      fill="#FEC786"
    />
    <path
      d="M52.3364 120.592L45.4621 119.166C45.2425 119.118 45.0998 118.903 45.1451 118.684L45.3104 117.887L52.9839 119.478L52.8186 120.275C52.7734 120.495 52.556 120.638 52.3364 120.592Z"
      fill="#FEC786"
    />
    <path
      d="M44.1399 111.401C44.1399 111.401 43.7821 115.286 42.6591 115.515C41.536 115.744 40.5805 115.107 40.5805 115.107C40.5805 115.107 38.9729 115.737 37.3947 114.392C37.3947 114.392 35.889 114.5 34.7206 113.821C34.7206 113.821 33.0858 114.129 32.513 113.162C32.513 113.162 31.6548 110.432 31.5892 108.711C31.5235 106.99 34.0368 98.7617 34.0368 98.7617C34.0368 98.7617 41.6651 100.247 43.01 101.184C43.01 101.184 43.8229 102.994 44.3188 104.363C45.7294 108.258 47.8646 109.836 44.1421 111.398L44.1399 111.401Z"
      fill="#F9B498"
    />
    <path
      d="M34.4375 110.309C34.4647 110.171 34.5982 110.081 34.7364 110.106C34.8767 110.131 34.9673 110.264 34.9424 110.405C34.6005 112.275 34.9651 113.744 34.9673 113.76C35.0013 113.898 34.9198 114.039 34.7816 114.073C34.6435 114.107 34.5054 114.025 34.4692 113.887C34.4533 113.824 34.0729 112.291 34.4352 110.314L34.4375 110.309Z"
      fill="#F07162"
    />
    <path
      d="M40.8367 111.41C40.8616 111.278 40.9884 111.186 41.122 111.206C41.2624 111.224 41.362 111.355 41.3416 111.496C41.1084 113.208 40.8322 115.146 40.8322 115.146C40.8163 115.273 40.6827 115.383 40.5423 115.363C40.4019 115.343 40.3046 115.214 40.325 115.073C40.325 115.073 40.6012 113.137 40.8322 111.426C40.8322 111.421 40.8322 111.414 40.8344 111.41H40.8367Z"
      fill="#F07162"
    />
    <path
      d="M37.7664 110.601C37.7913 110.47 37.9181 110.377 38.0539 110.397C38.1943 110.415 38.2939 110.547 38.2736 110.687C37.9294 113.219 37.7981 114.527 37.7981 114.539C37.78 114.684 37.6577 114.783 37.5173 114.77C37.3769 114.756 37.2728 114.629 37.2863 114.489C37.2863 114.475 37.4199 113.16 37.7641 110.617C37.7641 110.612 37.7641 110.606 37.7664 110.601Z"
      fill="#F07162"
    />
    <path
      d="M43.7624 106.669C43.8009 106.57 43.8982 106.5 44.0114 106.504C44.1541 106.509 44.265 106.626 44.2605 106.769L44.102 111.798C44.0975 111.941 43.9797 112.052 43.8371 112.047C43.6944 112.042 43.5835 111.925 43.588 111.782L43.7465 106.753C43.7465 106.724 43.7533 106.697 43.7624 106.669Z"
      fill="#F07162"
    />
    <path
      d="M44.7279 112.102L44.0645 112.025L44.1799 109.254L46.5551 109.745L45.4275 112.227L44.7279 112.102Z"
      fill="#280F22"
    />
    <path
      d="M53.7629 74.3788C54.8181 72.0737 62.6546 65.7859 68.5598 60.2295C72.1577 56.8422 75.4454 53.1401 78.3844 49.1664L78.5542 48.9354C79.8924 46.293 80.9362 43.0393 81.1015 39.1924C81.6064 27.3073 68.5258 28.6727 68.5258 28.6727C60.1232 33.0268 40.7888 59.3011 35.9909 69.1597C31.1929 79.0182 31.125 101.321 31.125 101.321C31.125 101.321 36.5615 104.12 44.9686 102.546C44.9686 102.546 52.1667 77.8634 53.7652 74.3788H53.7629Z"
      fill="#C6D9FF"
    />
    <path
      d="M40.0854 103.316C34.7214 103.316 31.0488 101.595 31.0012 101.573C30.8608 101.505 30.7997 101.337 30.8676 101.195C30.9356 101.054 31.1031 100.995 31.2458 101.061C31.3046 101.088 37.0989 103.796 44.743 102.297C45.4562 99.8405 52.5048 75.638 53.5328 74.2092C54.5811 72.751 67.8043 60.5354 68.3658 60.0169C68.4813 59.9105 68.6602 59.9173 68.7666 60.0328C68.873 60.1482 68.8662 60.3271 68.7508 60.4335C68.6126 60.5603 55.0068 73.1292 53.9924 74.5398C53.1592 75.6968 47.3921 95.2033 45.2388 102.621L45.1913 102.786L45.0237 102.82C43.2712 103.176 41.6092 103.316 40.0854 103.316Z"
      fill="black"
    />
    <path
      opacity="0.3"
      d="M368.027 166.724C368.027 173.193 330.224 178.528 281.422 179.291C268.756 179.488 255.784 179.377 243.295 178.938C201.458 177.47 170.705 172.559 170.705 166.724C170.705 159.732 214.878 154.062 269.367 154.062C323.857 154.062 368.03 159.732 368.03 166.724H368.027Z"
      fill="#757575"
    />
    <path
      d="M301.67 164.786C301.747 169.855 288.173 170.992 285.13 171.32C279.723 171.47 275.095 170.118 269.905 168.907C268.288 168.551 267.874 168.01 268.171 167.202C269.239 165.388 269.088 162.589 268.413 160.69C268.241 159.881 268.877 159.449 269.694 159.259C277.952 157.726 285.476 157.522 293.446 160.445C296.025 161.391 299.899 162.492 301.67 164.786ZM260.216 160.637C260.216 160.637 257.893 160.633 254.241 160.921C251.567 161.213 243.898 161.588 243.234 165.343C243.988 170.1 250.815 169.907 254.316 169.536C256.498 169.276 258.672 169.015 260.83 168.585C260.755 165.92 260.239 163.305 260.216 160.637Z"
      fill="#060606"
    />
  </svg>
);
export default NoHistoryBanner;
