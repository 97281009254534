/* eslint-disable */
import React, { useState, useEffect } from "react";
import { useRecoilValue } from "recoil";
import { searchValue, userDetails } from "../../store";
import clsx from "clsx";
import download from "downloadjs";
import { motion } from "framer-motion";
import Typograpy from "../../components/ui/Typography";
import InfographicCard from "../../components/ui/InfographicCard";
import { NumberIcon, MoneySavedIcon, DoubleArrowsIcon, NoHistoryBanner } from "../../assets/Icons";
import Button from "../../components/ui/Button";
import { downloadInvoice, getTransactionHistory } from "../../services/history";
import HistoryCard from "../../components/ui/HistoryCard";
import Pagination from "../../components/ui/Pagination";
import { useNavigate } from "react-router-dom";
import { commaSeperation } from "../../utils";
import { RemitLoader } from "../../components/ui/Loader";

const HistoryPage = () => {
  const currentUser = JSON.parse(sessionStorage.getItem("currentUser") || "{}");
  const navigate = useNavigate();
  const [transactions, setTransactions] = useState([]);
  const [filteredTransactions, setFilteredTransactions] = useState([]);
  const [completedTransaction, setCompletedTransaction] = useState([]);
  const [totalRemitted, setTotalRemitted] = useState(0);
  const [filterCriteria, setFilterCriteria] = useState("ALL");
  const [currentPage, setCurrentPage] = useState<number>(1);
  const [loading, setLoading] = useState<boolean>(false);
  const recordsPerPage = 8;

  const userData = useRecoilValue(userDetails);
  const searchTerm = useRecoilValue(searchValue);

  const indexOfLastRecord = currentPage * recordsPerPage;
  const indexOfFirstRecord = indexOfLastRecord - recordsPerPage;

  useEffect(() => {
    const completedList = transactions?.filter((el: any) => {
      if (el?.userTransaction?.status === "COMPLETED") return el;
    });
    setCompletedTransaction(completedList);
  }, [transactions]);

  const handlePageChange = (pageNumber: number) => {
    setCurrentPage(pageNumber);
  };

  const handleFilterDataCriteria = () => {
    const filteredList = transactions?.filter((el: any) => {
      if (filterCriteria === "" || filterCriteria === "ALL") {
        return el;
      } else if (filterCriteria && el?.userTransaction?.status === filterCriteria) {
        return el;
      }
    });
    console.log("transactins",filteredTransactions);

    setFilteredTransactions(filteredList);
  };

  const handleFilterSearchBar = () => {
    if (searchTerm) {
      const filteredList = transactions?.filter((el: any) => {
        if (el.transactionNumber.toString().includes(searchTerm)) {
          return el;
        }
      });
      setFilteredTransactions(filteredList);
    }
  };

  const downloadInvoiceHandler = (id: string) => {
    downloadInvoice(currentUser, id, "RECEIPT")
      .then((response: any) => {
        const content = response.headers["content-type"];
        download(response.data, "download_" + "RECEIPT" + ".pdf", content);
      })
      .catch((error) => {
        console.error(error);
      });
  };

  useEffect(() => {
    handleFilterSearchBar();
  }, [searchTerm]);

  useEffect(() => {
    handleFilterDataCriteria();
  }, [transactions, filterCriteria]);

  useEffect(() => {
    setLoading(true);
    getTransactionHistory(currentUser).then((res: any) => {
      setLoading(false);
      if (res.data) setTransactions(res.data);
    });
  }, []);

  useEffect(() => {
    let total: any = 0;
    completedTransaction?.map((trans: any) => {
      total = total + trans?.userTransaction?.userSend;
    });
    setTotalRemitted(total);
  }, [completedTransaction]);

  console.log("filteredTransactions", filteredTransactions);

  return (
    <motion.div
      id="container"
      className={clsx("flex overflow-hidden")}
      initial={{ opacity: 0 }}
      animate={{ opacity: 1 }}
      exit={{ opacity: 0 }}
    >
      <div id="body" className={clsx("flex flex-col flex-1 lg:p-8 p-4 rounded-md bg-primary-100")}>
        <div id="title" className={clsx("flex items-center justify-between mb-4")}>
          <Typograpy tagType="span" className={clsx("font-bold")}>
            History
          </Typograpy>
          <div className={clsx("hidden")}>June 2021</div>
        </div>
        <div id="infographic" className={clsx("flex flex-wrap flex-1 items-center")}>
          <InfographicCard
            title="No. Of Transactions done"
            subtitle={completedTransaction?.length}
            icon={<NumberIcon />}
            cardClassname="mb-4 grow md:grow-0 mr-4 shrink"
          />
          <InfographicCard
            title="Amount Remitted"
            subtitle={`₹ ${commaSeperation(totalRemitted)}`}
            icon={<DoubleArrowsIcon />}
            cardClassname="mb-4 grow md:grow-0 md:mr-4"
          />
          <InfographicCard
            title="Saved in hidden charges"
            subtitle={`₹ ${commaSeperation((0.0325 * totalRemitted).toFixed(2))}`}
            icon={<MoneySavedIcon />}
            cardClassname="mb-4 w-[53%] md:w-auto"
          />
        </div>
        
        {/* <div
          id="filters"
          className={clsx("flex flex-col justify-between md:flex-row mt-2 md:mt-0")}
        >
          <div className={clsx("flex flex-wrap items-center space-x-3 text-base md:text-xs")}>
            <div className={clsx("items-center mb-2")}>
              <Typograpy
                tagType="span"
                className={clsx("mb-2.5 items-center font-bold md:font-normal")}
              >
                Select filters
              </Typograpy>
            </div>
            <div className={clsx("flex flex-wrap items-center md:space-x-3 text-xs lg:text-base")}>
              <Button
                className={clsx(
                  "px-4 py-1.5 mb-2 rounded-sm font-semibold mr-2 md:mr-0 grow md:grow-0",
                )}
                bgColor={filterCriteria === "ALL" ? "bg-primary text-white" : "bg-primary-150"}
                key="ALL"
                onClickHandler={() => {
                  setCurrentPage(1);
                  setFilterCriteria("ALL");
                }}
              >
                ALL
              </Button>
              <Button
                className={clsx(
                  "px-4 py-1.5 mb-2 mr-2 md:mr-0 rounded-sm font-semibold grow md:grow-0",
                )}
                bgColor={
                  filterCriteria === "COMPLETED" ? "bg-primary text-white" : "bg-primary-150"
                }
                key="COMPLETED"
                onClickHandler={() => {
                  setCurrentPage(1);
                  setFilterCriteria("COMPLETED");
                }}
              >
                Completed Transactions
              </Button>
              <Button
                className={clsx(
                  "px-4 py-1.5 mb-2 rounded-sm font-semibold mr-2 md:mr-0 shrink md:shrink-0",
                )}
                bgColor={
                  filterCriteria === "IN_TRANSIT" ? "bg-primary text-white" : "bg-primary-150"
                }
                key="IN_TRANSIT"
                onClickHandler={() => {
                  setCurrentPage(1);
                  setFilterCriteria("IN_TRANSIT");
                }}
              >
                In progress Transaction
              </Button>
              <Button
                className={clsx(
                  "px-4 py-1.5 mb-2 rounded-sm font-semibold mr-2 md:mr-0 grow md:grow-0",
                )}
                bgColor={
                  filterCriteria === "CANCELLED" ? "bg-primary text-white" : "bg-primary-150"
                }
                key="CANCELLED"
                onClickHandler={() => {
                  setCurrentPage(1);
                  setFilterCriteria("CANCELLED");
                }}
              >
                Failed Transaction
              </Button>
              <Button
                className={clsx(
                  "px-4 py-1.5 mb-2 rounded-sm font-semibold mr-2 md:mr-0 grow md:grow-0",
                )}
                bgColor={
                  filterCriteria === "INITIATE_REFUND" ? "bg-primary text-white" : "bg-primary-150"
                }
                key="INITIATE_REFUND"
                onClickHandler={() => {
                  setCurrentPage(1);
                  setFilterCriteria("INITIATE_REFUND");
                }}
              >
                Refund Initiated
              </Button> 
              <Button
                className={clsx("px-4 py-1.5 mb-2 rounded-sm font-semibold grow md:grow-0")}
                bgColor={
                  filterCriteria === "REFUND_COMPLETED" ? "bg-primary text-white" : "bg-primary-150"
                }
                key="REFUND_COMPLETED"
                onClickHandler={() => {
                  setCurrentPage(1);
                  setFilterCriteria("REFUND_COMPLETED");
                }}
              >
                Refund Completed
              </Button>
            </div>
          </div>
          <div className={filteredTransactions?.length > 0 ? "block" : "hidden"}>
            <Pagination
              currentPage={currentPage}
              totalItems={filteredTransactions?.length}
              pageSize={8}
              handlePageChange={handlePageChange}
            />
          </div>
        </div> */}
        
        {!loading ? (
          <div className={clsx("mt-4")}>
            {filteredTransactions?.length > 0 ? (
              filteredTransactions
                .slice(indexOfFirstRecord, indexOfLastRecord)
                .map((el: any, idx) => {
                  return (
                    <div key={idx} className={clsx("mb-2.5")}>
                      <HistoryCard
                        accNo={el?.userTransaction?.recipient?.bankAccountNumber}
                        baseCurrency={el?.userTransaction?.baseCurrency}
                        conversionRate={el?.userTransaction?.conversionRate}
                        recepientReceive={el?.userTransaction?.recipientRecieve}
                        countryOfResidence={el?.userTransaction?.recipient?.countryOfResidence}
                        date={el?.userTransaction?.createdOn}
                        destinationCurrency={el?.userTransaction?.destinationCurrency}
                        paymentMode={el?.userTransaction?.transactionState?.paymentMode}
                        status={el?.userTransaction?.status}
                        statusToBeShown={
                          el?.userTransaction?.status === "INITIATE_REFUND" ||
                          el?.userTransaction?.status === "REFUND_COMPLETED"
                            ? el?.userTransaction?.status
                            : el?.statusToBeShown
                        }
                        totalDeduction={el?.userTransaction?.userSend}
                        txNo={el?.userTransaction?.transactionNumber}
                        baseCountry={userData?.country}
                        userFname={userData?.firstName}
                        userLname={userData?.lastName}
                        receiverFname={el?.userTransaction?.recipient?.firstName}
                        receiverLname={el?.userTransaction?.recipient?.lastName}
                        totalPay={el?.userTransaction?.userSend}
                        convertedAmount={el?.userTransaction?.conversionAmount}
                        id={el?.userTransaction?.id}
                        purpose={el?.userTransaction?.transactionPurpose}
                        downloadInvoiceHandler={downloadInvoiceHandler}
                        is_fullPaid={el?.userTransaction?.transactionState?.is_fullPaid}
                        isTotalPay={el?.userTransaction?.isTotalPay}
                        total_pay={el?.userTransaction?.totalPay}
                        advancePay={el?.userTransaction?.advancePay}
                        isAdvancePay={el?.userTransaction?.isAdvancePay}
                        isInward={el?.userTransaction?.isInward}
                        taxAndFees={
                          el?.userTransaction?.transactionTaxDetails?.nostroCharges +
                          el?.userTransaction?.transactionTaxDetails?.tcs +
                          el?.userTransaction?.transactionTaxDetails?.serviceTax
                        }
                        promoCode={
                          el?.userTransaction?.transactionState?.is_promoApplied
                            ? el?.userTransaction?.transactionState?.promoCode
                            : "0"
                        }
                        totalPayable={el?.userTransaction?.totalPay}
                      />
                    </div>
                  );
                })
            ) : (
              <div className="flex flex-col items-center md:p-20 space-y-60">
                <div className="flex flex-col items-center justify-center mt-11 md:mt-0">
                  <NoHistoryBanner />
                  <Typograpy tagType="h1" className="p-4 text-5xl text-primary-600 mt-11">
                    {filterCriteria === "IN_TRANSIT" &&
                      "You do not have any cancelled transactions."}
                    {filterCriteria === "REFUND_COMPLETED" &&
                      "You do not have any refund completed transactions."}
                  </Typograpy>
                  {filterCriteria === "IN_TRANSIT" ||
                  filterCriteria === "REFUND_COMPLETED" ? null : (
                    <>
                      <div className="hidden text-5xl md:flex md:mx-auto text-singleHistoryText">
                        Try the{" "}
                        <span
                          className="ml-2 mr-2 cursor-pointer text-primary-600"
                          onClick={() => navigate("/send-money")}
                        >
                          start
                        </span>{" "}
                        button to create a transaction
                      </div>
                      <div className="mt-12 text-xl font-medium md:hidden">
                        Try the{" "}
                        <span
                          className="ml-1 mr-1 text-blue-600"
                          onClick={() => navigate("/send-money")}
                        >
                          start
                        </span>{" "}
                        button to create a transaction{" "}
                      </div>
                    </>
                  )}
                </div>
                <Button
                  onClickHandler={() => navigate("/send-money")}
                  className="px-24 py-4 font-semibold text-white rounded-full"
                >
                  Make a transaction
                </Button>
              </div>
            )}
          </div>
        ) : (
          <div className="flex items-center justify-center w-full lg:mt-[10%]">
            <div className="h-[250px] w-[250px]">
              <RemitLoader />
            </div>
          </div>
        )}
      </div>
    </motion.div>
  );
};

export default HistoryPage;
