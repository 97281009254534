import { atom } from "recoil";

import RECOIL_KEYS from "../constants/recoilKeys";
import {
  AdditionalDocList,
  EsignedStatus,
  KycDetails,
  RecipientDetais,
  UserDetails,
  UserTransactionDetails,
  Categories,
  UploadDocument,
} from "./store";

export const userDetails = atom({
  key: RECOIL_KEYS.USER_DETAILS,
  default: {} as UserDetails,
});

export const searchValue = atom({
  key: RECOIL_KEYS.SEARCH_VALUE,
  default: "",
});

export const recipientDetails = atom({
  key: RECOIL_KEYS.RECIPIENT_DETAILS,
  default: [] as RecipientDetais,
});

export const transactionDetails = atom({
  key: RECOIL_KEYS.USER_TRANSACTION_DETAILS,
  default: {} as UserTransactionDetails,
});

export const kycDetails = atom({
  key: RECOIL_KEYS.KYC_DETAILS,
  default: {} as KycDetails,
});

export const additionalData = atom({
  key: RECOIL_KEYS.ADDITIONAL_DATA,
  default: [] as Categories,
});

export const additionalDocs = atom({
  key: RECOIL_KEYS.ADDITIONAL_DOC,
  default: {} as UploadDocument
});

export const confirmedPurpose = atom({
  key: RECOIL_KEYS.CONFIRMED_PURPOSE,
  default: { value: "Select the Purpose", code: "" },
});

export const isFirstTimeUser = atom({
  key: RECOIL_KEYS.FIRST_LOGIN,
  default: false,
});

export const openSidebar = atom({
  key: RECOIL_KEYS.OPEN_SIDEBAR,
  default: false,
});

export const transactionLeadIDS = atom({
  key: RECOIL_KEYS.TRANSACTION_LEADIDS,
  default: [] as any,
});

export const userAdditionalDocs = atom({
  key: RECOIL_KEYS.USER_ADDITIONAL_DOC,
  default: {} as AdditionalDocList,
});

export const isInwardTransaction = atom({
  key: RECOIL_KEYS.INWARD_TRANSACTION,
  default: false,
});

export const isSignupSuccess = atom({
  key: RECOIL_KEYS.SIGNUP_SUCCESS,
  default: false,
});

export const isEsigned = atom({
  key: RECOIL_KEYS.ESIGN_STATUS,
  default: {} as EsignedStatus,
});

export const transactionId = atom({
  key: RECOIL_KEYS.TRANSACTION_ID,
  default: "" as string,
});
