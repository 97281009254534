import { Route, Routes, useLocation } from "react-router-dom";
import PrivateRoute from "../../hoc/PrivateRoute";
import SignIn from "../../pages/auth/SignIn";
import BugReport from "../../pages/bugReport";
import { ConfirmationPage } from "../../pages/confirmationPage";
import FxRates from "../../pages/fxRates";
import HistoryPage from "../../pages/history";
import Home from "../../pages/home";
import PaymentOptionsPage from "../../pages/paymentOptions";
import PickRecipient from "../../pages/pickRecipient";
import HomePickRecipient from "../../pages/pickRecipient/HomePickRecipient";
import ProfilePage from "../../pages/profile";
import { RecipientProfile } from "../../pages/RecipientProfile";
import RecipientSearch from "../../pages/recipientSearch";
import ReviewPage from "../../pages/reviewPage";
import SendMoney from "../../pages/sendMoney";
import { SupportPage } from "../../pages/supportPage";
import UserDocuments from "../../pages/userDocuments";
// import { AnimatePresence } from "framer-motion";
import RewardsPage from "../../pages/rewards";
import { UnderMaintenance } from "../../pages/UnderMaintenance";
import { PrimePage } from "../../pages/prime/prime";
import { HopApp } from "../../pages/hopApp";
import RecipeintDetails from "../../pages/reciepientDetails";
import InwardRecipientDetails from "../../pages/inward/recipientDetails";
import { InwardPayment } from "../../pages/inward/paymentOptions/index";
import { InwardDocs } from "../../pages/inward/userDocuments";
import PickInwardRecipient from "../../pages/inward/pickRecipient";
// import PaymentOverviewInward from "../../pages/inward/review";
import ReviewPageInWard from "../../pages/inward/review";
import { ESignPage } from "../../pages/eSign";
import { Gtm } from "../../pages/gtm";
import SignUp from "../../pages/auth/SignUp";
import UserDetails from "../../pages/userDetails";
import PaymentMethod from "../../pages/paymentOptions/paymentMethod";

export const AnimatedRoutes = () => {
  const location = useLocation();
  return (
    // <AnimatePresence>
    <Routes location={location} key={location.pathname}>
      <Route path="/under-maintenance" element={<UnderMaintenance />} />
      <Route path="/auth/sign-in" element={<SignIn />} />
      <Route path="/auth/sign-up" element={<SignUp />} />
      <Route path={"/e-sign"} element={<ESignPage />} />
      <Route path="/signup-success" element={<Gtm />} />
      <Route path="/" element={<PrivateRoute />}>
        <Route path="/" element={<Home />} />
        <Route path="/recipient-search" element={<RecipientSearch />} />
        <Route path="/confirmation" element={<ConfirmationPage />} />
        <Route path="/payment-method" element={<PaymentMethod />} />
        <Route path="/send-money" element={<SendMoney />} />
        <Route path="/pick-recipient" element={<PickRecipient />} />
        <Route path="/recipients" element={<HomePickRecipient />} />
        <Route path="/user-documents" element={<UserDocuments />} />
        <Route path="/user-details" element={<UserDetails />} />
        <Route path="/recipient-details" element={<RecipeintDetails />} />
        <Route path="/rewards" element={<RewardsPage />} />
        <Route path="/history" element={<HistoryPage />} />
        <Route path="/review" element={<ReviewPage />} />
        <Route path="/confirmation" element={<ConfirmationPage />} />
        <Route path="/support" element={<SupportPage />} />
        <Route path="/profile" element={<ProfilePage />} />
        <Route path="/fxrates" element={<FxRates />} />
        <Route path="/payment-options" element={<PaymentOptionsPage />} />
        <Route path="/bug-report" element={<BugReport />} />
        <Route path="/recipient-profile" element={<RecipientProfile />} />
        <Route path="/hopprime" element={<PrimePage />} />
        <Route path="/hopapp" element={<HopApp />} />
        <Route path="/inward/recipient-details" element={<InwardRecipientDetails />} />
        <Route path="/inward/payment-options" element={<InwardPayment />} />
        <Route path="/inward/user-documents" element={<InwardDocs />} />
        <Route path="/inward/pick-recipient" element={<PickInwardRecipient />} />
        <Route path="/inward/review" element={<ReviewPageInWard />} />
      </Route>
    </Routes>
    // </AnimatePresence>
  );
};
