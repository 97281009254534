//Global Imports
import React, { useCallback, useEffect, useRef, useState } from "react";
import { Navigate, Outlet, useNavigate } from "react-router-dom";
import { useRecoilState } from "recoil";
//End Global Imports

//Local Imports
import { isAuthorised, refreshToken } from "../services/auth";
import ProtectedPageLayout from "../components/layout/ProtectedPageLayout";
import { userDetails } from "../store";
import mixpanel from "mixpanel-browser";
import Cookies from "universal-cookie";
import { useIdleTimer } from "react-idle-timer";
//End Local Imports

const useTokenRefresh = (idleTime: any) => {
  const [isActive, setIsActive] = useState(false);

  useEffect(() => {
    if (isActive) {
      window.console.log("Setting up token refresh due to user activity...");
      const interval = setInterval(() => {
        window.console.log("Refreshing token...");
        refreshToken()
          .then((response: any) => {
            if (response?.data) {
              sessionStorage.setItem("idToken", response?.data?.token);
            }
          })
          .catch((err) => {
            console.error(err);
          });
      }, idleTime); // Refresh token every `idleTime` milliseconds

      // Cleanup interval on effect cleanup
      return () => clearInterval(interval);
    }
  }, [isActive, idleTime]);

  const signalActivity = useCallback(() => {
    setIsActive(true);
  }, []);
  return { signalActivity };
};

const PrivateRoute = () => {
  const navigate = useNavigate();
  const idleTime = 1000 * 60 * 30; //30 mins in milli seconds
  const { signalActivity } = useTokenRefresh(idleTime);
  const idleTimerRef = useRef(null);

  const handleOnIdle = useCallback(() => {
    localStorage.clear();
    sessionStorage.clear();

    mixpanel.track("Logout");
    mixpanel.reset();
    new Cookies().remove("XSRF-TOKEN");
    navigate("/auth/sign-in");
  }, []);
  const currentUser = JSON.parse(sessionStorage.getItem("currentUser") || "{}");

  useIdleTimer({
    ref: idleTimerRef,
    timeout: idleTime,
    onIdle: handleOnIdle,
    onActive: signalActivity,
    onAction: signalActivity,
    debounce: 500,
    crossTab: true,
    leaderElection: true,
    syncTimers: 200,
  });
  const [isLoggedIn, setIsLoggedIn] = React.useState<boolean>(false);
  const [isLoading, setIsLoading] = React.useState<boolean>(true);
  const [hopCoins, setHopCoins] = React.useState<number>(0);
  const [zoom, setZoom] = React.useState("100%");
  const setUserDetails = useRecoilState(userDetails)[1];

  const updateZoom = () => {
    if (window.innerWidth >= 1024) {
      setZoom("80%"); // Set zoom to 80% for screens wider than 768px
    } else {
      setZoom("60%"); // Reset zoom to 100% for screens smaller than 769px
    }
  };

  React.useEffect(() => {
    updateZoom();
    window.addEventListener("resize", updateZoom);

    // Clean up the event listener on unmount
    return () => {
      window.removeEventListener("resize", updateZoom);
    };
  }, []);

  const isUseEffectFired = useRef(false);

  React.useEffect(() => {
    // Added this check as useEffect fires twice orelse
    if (!isUseEffectFired.current) {
      setHopCoins(0);
      isUseEffectFired.current = true;
      isAuthorised(currentUser)
        .then((response: any) => {
          setIsLoggedIn(true);
          setIsLoading(false);
          setUserDetails(response.data);
          setHopCoins(response?.data?.active_hop_coins ?? 0);
        })
        .catch((error) => {
          console.error(error);
          setIsLoggedIn(false);
          setIsLoading(true);
          console.error("error in private route", error);
          navigate("/auth/sign-in");
        });
    }
  }, []);

  return isLoading ? null : isLoggedIn ? (
    <div className="h-full">
      <ProtectedPageLayout hopCoins={hopCoins}>
        <div style={{ zoom }} className="h-full">
          <Outlet />
        </div>
      </ProtectedPageLayout>
    </div>
  ) : (
    <Navigate to="/auth/sign-in" />
  );
};

export default PrivateRoute;
