import React from "react";

import {
  DealCardContainer,
  HistoryCardContainer,
  IDealCardContainerProps,
  IInfographicCardContainerProps,
  InfographicCardContainer,
  RecepientContainer,
} from "./DashboardContainerComponents";

interface IKYCVerifiedDashboard extends IDealCardContainerProps, IInfographicCardContainerProps {}

const KYCVerifiedDashboard: React.FunctionComponent<IKYCVerifiedDashboard> = ({
  dealCard,
  noOfTx,
  amountRemitted,
  chargesSaved,
}) => (
  <>
    <RecepientContainer />
    <InfographicCardContainer
      noOfTx={noOfTx}
      amountRemitted={amountRemitted}
      chargesSaved={chargesSaved}
    />
    <DealCardContainer dealCard={dealCard} />
    <HistoryCardContainer />
  </>
);

export default KYCVerifiedDashboard;
