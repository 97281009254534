import React from "react";
import { useLottie } from "lottie-react";
import * as signupSuccessful from "../../../assets/lottieAnimationsData/signupSuccessful.json";

export const SignupSuccessfulLoader = () => {
  const options = {
    loop: true,
    autoplay: true,
    animationData: signupSuccessful,
    rendererSettings: {
      preserveAspectRatio: "xMidYMid slice",
    },
  };

  const { View } = useLottie(options);

  return <div className="justify-self-center">{View}</div>;
};
