import React from "react";
import clsx from "clsx";

import { IProgress } from "./Progress";

const Progress: React.FunctionComponent<IProgress.IProps> = ({
  progressColor,
  progressCompleted,
  showProgressNumber = false,
  progressClassname,
  progressContainerClassname,
}) => (
  <div
    className={clsx(
      "w-full bg-grey-500 h-1.5 rounded-full",
      showProgressNumber && "!h-4",
      progressContainerClassname,
    )}
  >
    <div
      style={{ width: `${progressCompleted}%` }}
      className={clsx(
        "bg-success h-1.5 rounded-full",
        showProgressNumber &&
          "text-xs font-medium text-blue-100 text-center p-0.5 leading-none rounded-full !h-4",
        progressColor,
        progressClassname,
      )}
    >
      {showProgressNumber ? progressCompleted : ""}
    </div>
  </div>
);

export default Progress;
