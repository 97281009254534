/**
 * @description Component for using Modal
 * @param {React.ReactNode} children - How many radio buttons do we need
 * @param {boolean} closable - Is modal closable through a close icon
 * @param {React.ReactNode} closeButtonIcon - How should the close icon look
 * @param {function} closeHandler -Handler to handle the modal close
 * @param {string | React.ReactNode} header - String or react node for the header
 * @param {strng} headerClass - Classes added to the header of the modal
 * @param {string} modalClass - Custom classes needed to modify the modal
 * @param {string} maskClass - Custom classes to modify the mask
 * @param {boolean} visible - Flag to show if modal is visble or not
 * @param {boolean} maskClosable - Flag to close the modal when clicked outside the modal
 * @param {boolean} mask - Flag to show the modal
 * @param {string} animation - Flag to show animation
 * @param {object} animatonClass - styling to be applied for animation
 * @param {string} id - ID passed to the modal element.
 * @returns {React.FunctionComponent<IModal.IProps>} - Returns JSX for modal if it's visible group
 */
import clsx from "clsx";
import React, { useState, useEffect, useRef } from "react";

import { CloseIcon } from "../../../assets/Icons";
import { IModal } from "./Modal";

const defaultAnimationClass = {
  beforeAnimation: "md:bottom-0",
  afterAnimation: "md:bottom-2/4",
};

const DELAY_BEFORE_TRNASITION_START = 10;
const TRNASITION_TIME = 500;

const Modal: React.FunctionComponent<IModal.IProps> = ({
  children,
  closable = true,
  closeButtonIcon = <CloseIcon fill="black" />,
  closeHandler,
  header,
  headerClass,
  modalClass,
  maskClass,
  visible,
  maskClosable = true,
  mask = true,
  animation = true,
  animationClass = defaultAnimationClass,
  id,
}) => {
  const [animatingclass, setAnimatingClass] = useState(false);
  const location = window.location.href;
  const modalRef = useRef<HTMLDivElement>(null);

  const handleClose = (event: React.MouseEvent) => {
    if (animation) {
      setAnimatingClass(false);
      setTimeout(() => closeHandler(event), TRNASITION_TIME);
    } else {
      setAnimatingClass(false);
      closeHandler(event);
    }
  };

  const defaultMaskClass = mask && "bg-black opacity-100";

  const onMaskClick = (event: React.MouseEvent) => {
    if (maskClosable) {
      if (modalRef?.current) {
        if (!modalRef || modalRef?.current.contains(event.target as HTMLElement)) {
          return;
        } else {
          handleClose(event);
        }
      }
    }
  };

  useEffect(() => {
    if (animation) {
      if (visible) {
        setTimeout(() => {
          setAnimatingClass(true);
        }, DELAY_BEFORE_TRNASITION_START);
      } else setAnimatingClass(false);
    }
  }, [visible]);

  const modalJSX = (
    <div
      onClick={onMaskClick}
      className={clsx(
        "top-0 left-0 right-0 bottom-0 fixed z-50 bg-opacity-40 shadow-2xl",
        defaultMaskClass,
        maskClass,
      )}
    >
      <div
        id={id}
        ref={modalRef}
        className={clsx(
          "w-screen md:w-fit bg-white p-8 left-2/4 bottom-1/3 z-50 absolute -translate-x-1/2 md:translate-y-1/2 shadow-2xl transition-[bottom]  duration-500",
          animatingclass
            ? animationClass?.afterAnimation
            : animation
            ? animationClass?.beforeAnimation
            : animationClass?.afterAnimation,
          modalClass,
        )}
      >
        <div className={clsx("flex md:flex-row flex-col my-2 items-center justify-center", headerClass)}>
          <div className="flex-1">{header}</div>
          <div
            className={clsx(
              location?.includes("/user-documents")
                ? ""
                : "transition-all duration-500 hover:rotate-90",
            )}
          >
            {closable ? (
              <button className={clsx()} onClick={handleClose}>
                {closeButtonIcon}
              </button>
            ) : (
              <></>
            )}
          </div>
        </div>
        {children}
      </div>
    </div>
  );

  return visible ? modalJSX : <></>;
};

export default Modal;
