import React from "react";
import clsx from "clsx";
import Countdown, { zeroPad } from "react-countdown";
import Button from "../../../../components/ui/Button";
import OtpBox from "../../../../components/ui/OtpBox";
import { IStepTwo } from "./StepTwo";

const StepTwo: React.FC<IStepTwo.IProps> = ({
  otpValue,
  otpError,
  saveOtpValue,
  verifyOTP,
  isOTPVerified,
  resendOTP,
  dateKey,
  date,
  buttonText,
  title,
  rootClass,
  otpErrorMessage,
}) => {
  return (
    <div className={clsx(rootClass, "flex flex-col items-center")}>
      <div className="flex flex-col items-center">
        <div className={clsx(title ? "hidden" : "mb-6")}>
          <span className="text-sm font-medium md:text-base text-grey-500 font-lato">Enter</span>{" "}
          <span className="text-sm font-medium text-black md:text-base font-lato">Phone</span>{" "}
          <span className="text-sm font-medium md:text-base text-grey-500 font-lato">
            verification OTP
          </span>
        </div>
        <div
          className={clsx(
            title ? "text-sm md:text-base font-semibold text-black font-ssp mb-6" : "hidden",
          )}
        >
          {title}
        </div>
        <div>
          <OtpBox
            updateValue={(value) => saveOtpValue(value)}
            errorMsg={otpErrorMessage ? otpErrorMessage : ""}
            errorMessageClassname={
              otpError ? "border-error bg-transparent text-center font-medium text-sm mt-6" : ""
            }
            numberOfBoxes={6}
          />
        </div>
      </div>
      <div
        className={clsx(
          isOTPVerified ? "flex" : "hidden",
          "text-success font-lato font-medium text-sm mt-7",
        )}
      >
        OTP Verified
      </div>
      <div
        className={clsx(
          otpValue.length >= 1 ? "mt-[106px] sm:mt-[131px]" : "mt-[106px]",
          isOTPVerified && "mt-[72px] md:mt-[136px]",
          "flex flex-col items-center sm:justify-center justify-end h-full",
        )}
      >
        <div className={clsx("pb-8")}>
          <Countdown
            key={dateKey}
            date={date}
            renderer={({ minutes, seconds, completed }) => {
              if (completed) {
                return (
                  <div
                    className="text-m font-bold text-black cursor-pointer font-lato text-red-600"
                    onClick={resendOTP}

                  >
                    Resend OTP
                  </div>
                );
              } else {
                return (
                  <div className="flex items-center space-x-2">
                    <div className="text-m font-bold font-lato text-red-600">Resend OTP</div>
                    <div className={"text-m font-bold text-primary font-lato"}>
                      {zeroPad(minutes)} :{zeroPad(seconds)}
                    </div>
                  </div>
                );
              }
            }}
          />
        </div>
        <div className="flex">
          <Button
            id="signup-step2-confirm"
            bgColor={`${otpValue.length == 6 ? "bg-primary" : "bg-grey-500"}`}
            fontStyle={{
              fontFamily: "font-ssp",
              fontSize: "text-lg sm:text-base ",
              fontWeight: "font-bold",
              fontColor: "text-white",
            }}
            borderStyle={{
              borderWidth: "border-none",
              borderRadius: "rounded-[105px]",
            }}
            className={"py-[14px] sm:py-[18px] px-[123px]"}
            onClickHandler={verifyOTP}
          >
            {buttonText}
          </Button>
        </div>
      </div>
    </div>
  );
};

export default StepTwo;
