import React from "react";
import { IIcons } from "./icons";

const NetBanking = ({ fillColor }: IIcons.IconProps) => (
  <svg
    width="82"
    height="82"
    viewBox="0 0 82 82"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    className={fillColor}
  >
    <path
      d="M41 0.375C28.4573 0.375 17.2869 6.09471 9.83545 15.0295L9.79272 14.9929C8.98133 15.9656 8.22315 16.9851 7.50391 18.0325C7.49814 18.0409 7.49136 18.0485 7.4856 18.0569C3.00786 24.5875 0.375 32.4962 0.375 41C0.375 63.3985 18.6015 81.625 41 81.625C43.5092 81.625 45.9607 81.3728 48.3486 80.9353C48.3569 80.9338 48.3648 80.9307 48.373 80.9292C49.7293 80.6798 51.0702 80.3885 52.377 80.0076L52.3586 79.9343C57.367 78.4645 61.9914 76.1006 66 72.958L58.0532 64.0713L48.9529 72.2622L32.5649 54.0676L36.7947 50.259L25.7473 37.991L30.2212 33.9626L19.1067 21.6274L36.7031 5.36768L42.7517 11.7825L51.9558 3.5L56.8142 8.89551L60.635 5.45923C54.8131 2.23735 48.1281 0.375 41 0.375ZM65.5605 10.1528L61.6177 13.7051L65.1699 17.6541L69.1128 14.1018L65.5605 10.1528ZM52.0229 11.1416L45.4495 17.062L51.3699 23.6416L57.9434 17.7212L52.0229 11.1416ZM36.3064 13.9309L27.4258 21.9265L35.4214 30.8071L44.302 22.8115L36.3064 13.9309ZM11.8191 22.8726L21.3894 33.4988L16.9216 37.5271L21.1025 42.1658L27.9629 49.7891L23.7332 53.5977L43.2705 75.2957C42.5202 75.3445 41.7637 75.375 41 75.375C21.9797 75.375 6.625 60.0203 6.625 41C6.625 34.3254 8.54003 28.1343 11.8191 22.8726ZM71.7251 24.6792L67.7822 28.2314L71.3345 32.1743L75.2773 28.6221L71.7251 24.6792ZM58.7612 25.8999L52.1877 31.8203L58.1082 38.3999L64.6816 32.4795L58.7612 25.8999ZM43.2339 30.4897L34.3533 38.4853L42.3489 47.366L51.2295 39.3704L43.2339 30.4897ZM77.8835 39.053L73.9346 42.6052L77.4868 46.5481L81.4358 42.9958L77.8835 39.053ZM64.9624 41.3052L58.3828 47.2256L64.3032 53.8052L70.8828 47.8848L64.9624 41.3052ZM50.1248 47.1951L41.2441 55.1907L49.2397 64.0713L58.1204 56.0757L50.1248 47.1951ZM71.3833 56.0146L64.8037 61.9351L70.7241 68.5146L77.3037 62.5942L71.3833 56.0146Z"
      fill="#0F5BF1"
    />
  </svg>
);
export default NetBanking;
