import axios from "axios";
import { Axios } from "../axios";
import { IAuth } from "./auth";
import { isEmpty } from "../../utils";

const baseApi = process.env.REACT_APP_NEW_AUTH_SERVER_ID;
// const baseApi = "https://authapi.moneyhop.co";
// const baseApi = "https://authapi-dev.moneyhop.co";
// const baseApi = "https://auth-lookup-merger.moneyhop.co";
const oldBaseApi = process.env.REACT_APP_SERVER_ID;
const vendorKey = process.env.REACT_APP_VENDOR_KEY;

export const sendOtp = async ({ body, apiType }: IAuth.ISendOtpProps) => {
  let end_of_url = "";

  if (body.otpType === "mobile") {
    end_of_url = "sendMobileOtp";
  }

  if (body.otpType === "email") {
    end_of_url = "sendEmailOtp";
  }

  if (apiType === "resend") {
    end_of_url = "resendOtp";
  }

  return await Axios.post(`${baseApi}/remit/auth/${end_of_url}`, body, {
    headers: {
      "Access-Control-Allow-Origin": "true",
      "Content-Type": "application/json",
      "partner-type": "moneyhop",
      accept: "*/*",
      "X-Route-To": "authservice",
    },
  });
};

export const validateOtp = async (body: IAuth.IValidateOtpProps) => {
  return await Axios.post(`${baseApi}/remit/auth/validateOtp`, body, {
    headers: {
      "Access-Control-Allow-Origin": "true",
      "Content-Type": "application/json",
      "partner-type": "moneyhop",
      accept: "*/*",
      "X-Route-To": "authservice",
    },
  });
};

export const registerUserData = async (body: IAuth.IRegisterUserDataProps) => {
  return await Axios.post(`${baseApi}/remit/auth/signup`, body, {
    headers: {
      "Access-Control-Allow-Origin": "true",
      "Content-Type": "application/json",
      "partner-type": "moneyhop",
      remitter: true,
      vendorCode: "MHREMIT",
      accept: "*/*",
      "X-Route-To": "authservice",
    },
  });
};

export const handleLogin = async (body: IAuth.IHandleLoginProps) => {
  return await Axios.post(`${baseApi}/remit/auth/signin`, body, {
    headers: {
      "Access-Control-Allow-Origin": "*",
      "Content-Type": "application/json",
      accept: "*/*",
      remitter: true,
      vendorCode: "MHREMIT",
      "partner-type": "moneyhop",
      "X-Route-To": "authservice",
    },
  });
};

export const isAuthorised = async (currentUser: IAuth.IIsAuthorisedProps) => {
  if (!(currentUser === null || currentUser === undefined || isEmpty(currentUser))) {
    window.console.log("url is ", `${oldBaseApi}/remit/auth/user/${currentUser?.id}`);
    return await Axios.get(`${oldBaseApi}/remit/auth/user/${currentUser?.id}`, {
      headers: {
        "Access-Control-Allow-Origin": "true",
        "Content-Type": "application/json",
        Authorization: `${currentUser.idToken}`,
      },
    });
  }
};

export const forgotPassword = async (body: IAuth.IForgotPassword) => {
  return await Axios.post(`${baseApi}/remit/auth/forgotPassword`, body, {
    headers: {
      "Access-Control-Allow-Origin": "true",
      "Content-Type": "application/json",
      remitter: true,
      vendorCode: "MHREMIT",
      "X-Route-To": "authservice",
    },
  });
};

export const refreshToken = async () => {
  return await Axios.get(`${baseApi}/remit/auth/user/${vendorKey}/getToken`, {
    headers: { "X-Route-To": "authservice" },
  });
};

export const initialXSRFToken = async () => {
  return await Axios.post(
    `${baseApi}/remit/csrf`,
    {},
    { headers: { "X-Route-To": "authservice" } },
  );
};

export const getRequestTime = async () => {
  return await Axios.get(`${baseApi}/remit/currentTime`, {
    headers: { "X-Route-To": "authservice" },
  });
};

export const verifyReCaptcha = async (body: IAuth.IReCaptcha) => {
  return await Axios.post(`${baseApi}/remit/auth/recaptcha/verify`, body, {
    headers: {
      "Access-Control-Allow-Origin": "*",
      "Content-Type": "application/json",
      "X-Route-To": "authservice",
    },
  });
};

export const referAndEarnEnrollment = async (body: IAuth.IReferAndEarnEnrollmentProps) => {
  return await axios.post(`${process.env.REACT_APP_REFFER_BASE_URL}/user/enrollment`, body, {
    headers: {
      "Access-Control-Allow-Origin": "*",
      "Content-Type": "application/json",
      "x-api-key": process.env.REACT_APP_REFFER_AND_EARN_API_ID as string,
      "x-brand-id": process.env.REACT_APP_REFFER_AND_EARN_BID as string,
      accept: "*/*",
    },
  });
};
