import React from "react";
import { IIcons } from "./icons";

const DownCircleIcon = ({ fillColor }: IIcons.IconProps) => {
  return (
    <svg width="17" height="17" viewBox="0 0 17 17" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M8.18856 1.4533e-07C7.1133 -0.000204413 6.04852 0.215536 5.05505 0.634902C4.06158 1.05427 3.15886 1.66904 2.39847 2.44412C1.63807 3.2192 1.03488 4.13939 0.623348 5.15215C0.211816 6.16491 -1.87205e-08 7.2504 0 8.34663C-1.87205e-08 9.44286 0.211816 10.5283 0.623348 11.5411C1.03488 12.5539 1.63807 13.4741 2.39847 14.2491C3.15886 15.0242 4.06158 15.639 5.05505 16.0584C6.04852 16.4777 7.1133 16.6935 8.18856 16.6933C10.3599 16.6933 12.4423 15.8139 13.9777 14.2486C15.513 12.6833 16.3756 10.5603 16.3756 8.34663C16.3756 6.13297 15.513 4.00997 13.9777 2.44467C12.4423 0.879375 10.3599 1.4533e-07 8.18856 1.4533e-07ZM10.8728 5.29549H9.05172C9.31552 5.59435 9.48668 5.9661 9.54365 6.36394H10.8773V7.20343H9.53143C9.35727 8.37311 8.44216 9.0475 7.29332 9.13005C8.09384 9.96954 9.03339 11.1034 9.92558 12.245H8.01593C7.35443 11.418 6.4271 10.2951 5.49978 9.2858V8.07718H6.21781C7.20319 8.07718 7.70275 7.73453 7.84177 7.21433H5.49978V6.36394H7.79136C7.6065 5.9621 7.16499 5.702 6.37669 5.702H5.49673V4.44821H10.8651L10.8728 5.29549Z"
        fill="white"
        className={fillColor}
      />
    </svg>
  );
};

export default DownCircleIcon;
