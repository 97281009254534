import { IAuth } from "../auth/auth";
import { Axios } from "../axios";
import PaymentOptionsAPIProps from "./payments";

const vendorKey = process.env.REACT_APP_VENDOR_KEY;

export const getBankNames = async (currentUser: PaymentOptionsAPIProps.AuthorisationProps) => {
  if (currentUser) {
    return await Axios.get(`/remit/bank/fetch/net-banks`, {
      headers: {
        Authorization: `${currentUser.idToken}`,
      },
    });
  }
};

export const pennyDropAPI = async (body: PaymentOptionsAPIProps.PennyDropAPIProps) => {
  const currentUser = JSON.parse(sessionStorage.getItem("currentUser") || "{}");

  if (currentUser !== null && currentUser !== undefined) {
    return await Axios.post("/remit/bank/pennydrop/razorpay", body, {
      headers: {
        Authorization: `${currentUser.idToken}`,
      },
    });
  }
};

export const requestRazor = async (body: PaymentOptionsAPIProps.RequestRazorPayProps) => {
  const currentUser = JSON.parse(sessionStorage.getItem("currentUser") || "{}");

  if (currentUser !== null && currentUser !== undefined) {
    return await Axios.post("/remit/bank/payment/requestRazor", body, {
      headers: {
        Authorization: `${currentUser.idToken}`,
      },
    });
  }
};

export const razorPayResponse = async (body: any) => {
  const currentUser = JSON.parse(sessionStorage.getItem("currentUser") || "{}");

  if (currentUser !== null && currentUser !== undefined) {
    return await Axios.post("/remit/bank/payment/razorPayResponse", body, {
      headers: {
        Authorization: `${currentUser.idToken}`,
      },
    });
  }
};

export const requestRazorLink = async (body: PaymentOptionsAPIProps.RequestRazorPayProps) => {
  const currentUser = JSON.parse(sessionStorage.getItem("currentUser") || "{}");

  if (currentUser !== null && currentUser !== undefined) {
    return await Axios.post("/remit/bank/payment/requestRazorLink", body, {
      headers: {
        Authorization: `${currentUser.idToken}`,
      },
    });
  }
};

export const fetchNeftBankDetails = async () => {
  const currentUser = JSON.parse(sessionStorage.getItem("currentUser") || "{}");

  if (currentUser !== null && currentUser !== undefined) {
    return await Axios.get(`remit/user/${vendorKey}/neftBankDetails`, {
      headers: {
        Authorization: `${currentUser.idToken}`,
      },
    });
  }
};

export const getDetails = async (currentUser: IAuth.IIsAuthorisedProps) => {
  if (currentUser !== null && currentUser !== undefined) {
    return await Axios.get(`/remit/auth/user/${currentUser.id}`, {
      headers: {
        Authorization: `${currentUser.idToken}`,
      },
    });
  }
};

export const getPersonalAccountDetails = async (currentUser: IAuth.IIsAuthorisedProps) => {
  if (currentUser !== null && currentUser !== undefined) {
    return await Axios.get(`/remit/bank/${currentUser.id}/fetchSavedAccounts`, {
      headers: {
        Authorization: `${currentUser.idToken}`,
      },
    });
  }
};

export const bankDownTime = async () => {
  const currentUser = JSON.parse(sessionStorage.getItem("currentUser") || "{}");

  if (currentUser !== null && currentUser !== undefined) {
    return await Axios.get(`remit/bank/downtime`, {
      headers: {
        Authorization: `${currentUser.idToken}`,
      },
    });
  }
};

export const neftBankDetails = async (isNeft?: boolean) => {
  const currentUser = JSON.parse(sessionStorage.getItem("currentUser") || "{}");
  const id = process.env.REACT_APP_VENDOR_KEY;
  if (currentUser !== null && currentUser !== undefined) {
    return await Axios.get(
      `remit/user/${id}/neftBankDetails?ad1Transaction=${isNeft}`,
      {
        headers: {
          Authorization: `${currentUser.idToken}`,
        },
      },
    );
  }
};
