/*eslint-disable */
import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import PaymentDetailsCard from "./PaymentDetailsCard";
import GoBackandStepperSection from "./GoBackandStepper";
import { getCurrentIP, stepperSteps, TransactioncancellationReasons } from "../../utils";
import PaymentOverview from "./PaymentOverview";
import { useRecoilState, useRecoilValue } from "recoil";
import {
  recipientDetails,
  transactionDetails,
  transactionId,
  transactionLeadIDS,
  userDetails,
} from "../../store";
import { getSignedStatus, initializeTransaction, signLaterStatus } from "../../services/reviewPage";
import { UserTransactionDetails } from "../../store/store";
import Modal from "../../components/ui/Modal";
import Checkbox from "../../components/ui/Checkbox";
import Button from "../../components/ui/Button";
import { Axios } from "../../services/axios";
import TermsModal from "../auth/SignUp/TermsModal";
import download from "downloadjs";
import { motion } from "framer-motion";
// import { RemitLoader } from "../../components/ui/Loader";
import mixpanel from "mixpanel-browser";
import EsignModal from "./EsignModal";
import { isEsigned } from "../../store";

const ReviewPage = () => {
  const apiUrl = process.env.REACT_APP_SERVER_ID;
  const vendorKey = process.env.REACT_APP_VENDOR_KEY;
  const navigate = useNavigate();
  const currentUser = JSON.parse(sessionStorage.getItem("currentUser") || "{}");
  const transactionData: UserTransactionDetails = useRecoilValue(transactionDetails);
  const recipientData = useRecoilValue(recipientDetails);
  const userData = useRecoilValue(userDetails);
  const eSignStatus = useRecoilState(isEsigned)[0];
  const recipientId = sessionStorage.getItem("recipient_id");
  const [cancellationReasons, setCancellationReasons] = React.useState<string>("");
  const [otherReason, setOtherReason] = React.useState<string>("");
  const [isModalOpen, setIsModalOpen] = useState<string>("");
  const [thisRecipient, setThisRecipient] = useState<any>();
  const setTransactionData = useRecoilState(transactionDetails)[1];
  const [isTermsModalOpen, setIsTermsModalOpen] = React.useState<boolean>(false);
  const [isEsignModal, setIsEsignModal] = React.useState<boolean>(false);
  const [signA2FormStatus, setSignA2FormStatus] = React.useState<boolean>(false);
  const transactionLeadIDSArray: any = useRecoilValue(transactionLeadIDS);
  const [loading, setLoading] = React.useState<boolean>(false);
  const [currentIP, setCurrentIP] = React.useState("");
  const [isAdvancePay, setIsAdvancePay] = useState(false);
  const [a2Loading, setA2Loading] = useState(false);
  const [eSign, setEsign] = React.useState<boolean>(false);
  const [transactionNumber, setTransactionNumber] = useRecoilState(transactionId);
  const [isViewingA2Form, setIsViewingA2Form] = useState(false);
  const [signLater, setSignLater] = React.useState<boolean>(false);
  const [buttonLoader, setButtonLoader] = useState(false);
  const [A2errorMessage, A2setErrorMessage] = useState('');
  const [sourceOfFunds, setSourceOfFunds] = useState('');

  useEffect(() => {
    const handleBeforeUnload = (event: any) => {
      event.preventDefault();
      sessionStorage.setItem("navigatingFromPage", "true");
    };
    window.addEventListener("beforeunload", handleBeforeUnload);
    return () => {
      window.removeEventListener("beforeunload", handleBeforeUnload);
    };
  }, []);

  useEffect(() => {
    setTimeout(() => {
      const shouldNavigate = sessionStorage.getItem("navigatingFromPage") === "true";
      if (shouldNavigate) {
        sessionStorage.removeItem("navigatingFromPage");
        navigate("/send-money", { replace: true });
      }
    }, 500);
  }, [navigate]);

  const mixpanelToken: any = process.env.REACT_APP_MIXPANEL_TOKEN;
  mixpanel.init(mixpanelToken, {
    debug: true,
    api_host: process.env.REACT_APP_MIXPANEL_API,
  });

  // const transactionLeadIDSArray: any = useRecoilValue(transactionLeadIDS);

  const isMobile = window.matchMedia("only screen and (max-width: 768px)").matches;
  const isPurposeCodeValid =
    transactionData?.purposeCode === "S1301" || transactionData?.purposeCode === "S1302"
      ? true
      : false;

  useEffect(() => {
    window.console.log(transactionData, "xxxx");
    window.console.log(userData, "xxxx");
    window.onbeforeunload = function () {
      return true;
    };

    return () => {
      window.onbeforeunload = null;
    };
  }, []);

  useEffect(() => {
    handleEsignedStatus();
  }, [isEsignModal]);

  const handleEsignedStatus = () => {
    const currentUser = JSON.parse(sessionStorage.getItem("currentUser") || "{}");
    getSignedStatus({
      userId: currentUser.id,
    })
      .then((response) => {
        setSignLater(response?.data?.signedLatter);
        setEsign(response?.data?.signed);
      })
      .catch((error) => {
        alert(error?.response?.data?.error?.type);
      });
  };

  useEffect(() => {
    if (eSignStatus.signed && eSignStatus.signedLater) {
      setSignA2FormStatus(true);
    } else {
      setSignA2FormStatus(false);
    }
  }, []);

  const handleSignA2Form = () => {
    mixpanel.track("Sign A2 form clicked ");
    setIsEsignModal(true);
  };

  const handleDoLaterA2Form = () => {
    const currentUser = JSON.parse(sessionStorage.getItem("currentUser") || "{}");
    signLaterStatus({
      userId: currentUser.id,
    })
      .then((response) => {
        window.console.log("RESPONSE", response);
        if (response) {
          mixpanel.track("Do_it_later_esign");
          setIsEsignModal(false);
        }
      })
      .catch((error) => {
        alert(error?.response?.data?.error?.type);
      });
  };

  const payAmount = (event: any) => {
    mixpanel.track(`Confirm_Pay_review`);
    setLoading(true);
    setButtonLoader(true);
    event.preventDefault();
    const body = {
      advance_pay: transactionData.advance_pay,
      base_currency: transactionData.base_currency,
      cgst: transactionData.cgst,
      conversion_amount: transactionData.conversion_amount,
      conversion_rate: transactionData.conversion_rate,
      dest_currency: transactionData.dest_currency,
      hop_coins: transactionData.hop_coins,
      hop_fees: transactionData.hop_fees,
      id_recipient: recipientId,
      igst_fee: transactionData.igst_fee,
      isA2FormConsent: true,
      isEducation: transactionData?.isEducation,
      isAdvancePay: isAdvancePay ? true : false,
      isFullPay: isAdvancePay ? false : true,
      isInward: false,
      isMobWebTxn: isMobile ? true : false,
      isfromKarnataka: false,
      mgr_initial: transactionData.mgr_initial,
      nostro_charges: transactionData.nostro_charges,
      promo_code: transactionData.promo_code,
      promo_code_discount: transactionData.promo_code_discount,
      purposeCode: transactionData.purposeCode,
      reciepient_recieve: transactionData.reciepient_recieve,
      service_tax: transactionData.service_tax,
      sgst: transactionData.sgst,
      tcs: transactionData.tcs,
      total_pay: transactionData.total_pay,
      total_tax: transactionData.total_tax,
      transaction_purpose: transactionData.transaction_purpose,
      user_send: transactionData.user_send,
      vendor_key: vendorKey,
      sourceOfFunds: sourceOfFunds,
      isBene:
        (transactionData?.purposeCode === "S1301" || transactionData?.purposeCode === "S1302") ??
        true,
      ipAddress: currentIP,
      transactionLeadIds: transactionLeadIDSArray,
    };

    initializeTransaction(body)
      .then((response) => {
        console.error("erroreee3response", response);
        localStorage.setItem("onlyNeft", String(isPurposeCodeValid));
        setLoading(false);
        setTransactionData(response?.data);
        const transactionId = response?.data?.id_transaction;
        sessionStorage.setItem("transactionId", transactionId);
        setTransactionNumber(response?.data?.transactionNumber);
        navigate("/payment-options", { state: { purpose: transactionData?.transaction_purpose } });
      })
      .catch(() => {
        setLoading(false);
        // console.error(error);
      })
      .finally(() => setButtonLoader(false));
  };

  useEffect(() => {
    const currentRecipient = recipientData.filter((data: any) => {
      return data.id == recipientId;
    });
    setThisRecipient(currentRecipient);
    getCurrentIP()
      .then((response) => {
        setCurrentIP(response.data.IPv4);
      })
      .catch((error) => {
        console.error(error);
        // throw "Something went wrong!";
      });
  }, []);

  const handleA2Form = () => {
    setA2Loading(true);
    const address1 = encodeURIComponent(`${userData.addressLine1}`)
    Axios.get(
      `${apiUrl}/remit/user/${currentUser.id}/generateA2Form/?city=${userData.city}&dest_curr=${transactionData.dest_currency
      }&pan_num=&pincode=${userData.pinCode}&purpose_code=${transactionData.purposeCode
      }&purpose_description=${transactionData.transaction_purpose}&reciepient_recieve=${transactionData.reciepient_recieve
      }&recipeint_accnt_num=${thisRecipient[0].bankAccountNumber}&recipient_bank_name=${thisRecipient[0].bankName
      }&recipient_first_name=${thisRecipient[0].firstName}&recipient_last_name=${thisRecipient[0].lastName
      }&reicpient_bank_accnt_country=${thisRecipient[0].bankAccountCountry}&state=${thisRecipient[0].bankAccountCountry
      }&total_pay=${transactionData.total_pay}&user_address1=${address1
      }&user_first_name=${userData.firstName}&user_last_name=${userData.lastName
      }&user_middle_name=${userData.middleName}&recipient_middle_name=${thisRecipient[0].middleName
      }&recipientId=${recipientId}&bene=${transactionData?.purposeCode === "S1301" || transactionData?.purposeCode === "S1302"
        ? true
        : false
      }`,
      {
        headers: {
          "Access-Control-Allow-Origin": "true",
          "Content-Type": "application/pdf",
          Authorization: `${currentUser.idToken}`,
        },
        responseType: "blob",
      },
    )
      .then((response) => {
        setA2Loading(false);
        setLoading(false);
        A2setErrorMessage('')
        const content = response.headers["content-type"];
        download(response.data, "A2_Form.pdf", content);
      })
      .catch((error) => {
        console.log("error", error);
        setA2Loading(false);
        setLoading(false);
        if (error.response && error.response.data && error.response.data.error && error.response.data.error.type) {
          const errorMessage = error.response.data.error.type;
          A2setErrorMessage(errorMessage);
        } else {
          A2setErrorMessage('An unexpected error occurred in Downloading A2.');
        }
      });
  };


  const viewA2Form = () => {
    setIsViewingA2Form(true);
    const address1 = encodeURIComponent(`${userData.addressLine1}`)

    Axios.get(
      `${apiUrl}/remit/user/${currentUser.id}/generateA2Form/?city=${userData.city}&dest_curr=${transactionData.dest_currency
      }&pan_num=&pincode=${userData.pinCode}&purpose_code=${transactionData.purposeCode
      }&purpose_description=${transactionData.transaction_purpose}&reciepient_recieve=${transactionData.reciepient_recieve}&recipeint_accnt_num=${thisRecipient[0].bankAccountNumber}&recipient_bank_name=${thisRecipient[0].bankName
      }&recipient_first_name=${thisRecipient[0].firstName}&recipient_last_name=${thisRecipient[0].lastName}&reicpient_bank_accnt_country=${thisRecipient[0].bankAccountCountry}&state=${thisRecipient[0].bankAccountCountry}&total_pay=${transactionData.total_pay
      }&user_address1=${address1}&user_first_name=${userData.firstName}&user_last_name=${userData.lastName}&user_middle_name=${userData.middleName}&recipient_middle_name=${thisRecipient[0].middleName}&recipientId=${recipientId}&bene=${transactionData?.purposeCode === "S1301" || transactionData?.purposeCode === "S1302" ? true : false}`,
      {
        headers: {
          "Access-Control-Allow-Origin": "true",
          "Content-Type": "application/pdf",
          Authorization: `${currentUser.idToken}`,
        },
        responseType: "blob",
      },
    )
      .then((response) => {

        const file = new Blob([response.data], { type: 'application/pdf' });
        const fileURL = URL.createObjectURL(file);

        window.open(fileURL, '_blank');
        setIsViewingA2Form(false);
        A2setErrorMessage('')
        console.log("After setting setIsViewingA2Form to false");
      })
      .catch((error) => {
        console.log("error", error);
        setIsViewingA2Form(false);
        setLoading(false);
        if (error.response && error.response.data && error.response.data.error && error.response.data.error.type) {
          const errorMessage = error.response.data.error.type;
          A2setErrorMessage(errorMessage);
        } else {

          A2setErrorMessage('An unexpected error occurred in Viewing A2.');
        }
      });
  };

  return (
    <motion.div
      className="flex flex-col justify-between flex-1 lg:mt-12"
    // initial={{ width: 0 }}
    // animate={{ width: "100%" }}
    // exit={{ x: window.innerWidth, transition: { duration: 0.5 } }}
    >
      <div>
        <GoBackandStepperSection setIsModalOpen={setIsModalOpen} stepperSteps={stepperSteps} />
      </div>
      <div className="flex flex-col lg:px-[10%] px-[3%] lg:flex-row lg:justify-between">
        {/* <div>{errorMessage}</div> */}
        <PaymentDetailsCard />
        <PaymentOverview
          // errorMessage={errorMessage}
          handleClick={payAmount}
          buttonLoader={buttonLoader}
          setLoading={setLoading}
          sendCurrency={transactionData.base_currency}
          destCurrency={transactionData.dest_currency}
          handleA2Form={handleA2Form}
          setIsTermsModalOpen={setIsTermsModalOpen}
          isAdvancePay={isAdvancePay}
          setIsAdvancePay={setIsAdvancePay}
          a2Loading={a2Loading}
          loading={loading}
          signA2FormStatus={signA2FormStatus}
          handleSignA2Form={handleSignA2Form}
          eSign={eSign}
          signLater={signLater}
          viewA2Form={viewA2Form}
          isViewingA2Form={isViewingA2Form}
          A2errorMessage={A2errorMessage}
          A2seterrorMessage={A2setErrorMessage}
          sourceOfFunds={sourceOfFunds}
          setSourceOfFunds={setSourceOfFunds}
        />
      </div>
      <Modal
        header="Resons for your Cancellation"
        headerClass={"bg-white !my-0 py-6 px-12 font-bold text-lg"}
        modalClass="!p-0 !rounded !overflow-hidden"
        visible={isModalOpen === "cancel"}
        closeHandler={() => setIsModalOpen("")}
        closable={false}
      >
        <div className="px-5 pb-5">
          {TransactioncancellationReasons.map((data, index) => {
            return (
              <div className="space-x-2 px-7 border-b-[1px] py-6 first:pt-0" key={index}>
                <Checkbox
                  label={data}
                  value={data}
                  name={"cancellationReason"}
                  handleChange={(e) => {
                    setCancellationReasons(e.target.value);
                  }}
                />
              </div>
            );
          })}
          {cancellationReasons === "Other Reason..." && (
            <div className="flex items-center justify-center mt-2">
              <textarea
                name="otherReasonDescription"
                value={otherReason}
                rows={4}
                cols={50}
                placeholder="Write your reason here....."
                onChange={(e) => {
                  setOtherReason(e.target.value);
                }}
                className="w-full p-3 bg-white-100 focus-visible:border-0 placeholder:text-center"
              />
            </div>
          )}
          <div className="flex items-center justify-center mt-7 px-[78px]">
            <Button
              id="backsy-snbjcyu"
              bgColor={`${cancellationReasons === "" ? "bg-grey-350" : "bg-primary"}`}
              fontStyle={{
                fontFamily: "",
                fontSize: "text-lg",
                fontWeight: "font-bold",
                fontColor: "text-white",
              }}
              borderStyle={{
                borderWidth: "border-none",
                borderRadius: "rounded-[100px]",
              }}
              disabled={cancellationReasons === ""}
              className={"py-3 px-28"}
              onClickHandler={() =>
                cancellationReasons !== "" && cancellationReasons === "Other Reason..."
                  ? otherReason !== "" && navigate("/")
                  : navigate("/")
              }
            >
              Submit
            </Button>
          </div>
        </div>
      </Modal>
      <TermsModal isTermsModalOpen={isTermsModalOpen} setIsTermsModalOpen={setIsTermsModalOpen} />
      <EsignModal
        handleA2Form={handleA2Form}
        isEsignModal={isEsignModal}
        setIsEsignModal={setIsEsignModal}
        handleDoLaterA2Form={handleDoLaterA2Form}
        viewA2Form={viewA2Form}
        isViewingA2Form={isViewingA2Form}
        setIsViewingA2Form={setIsViewingA2Form}
      />
    </motion.div>
  );
};

export default ReviewPage;
