/* eslint-disable */
import React, { FunctionComponent, useState } from "react";
import { motion } from "framer-motion";
import mixpanel from "mixpanel-browser";
import RecipientProps from "./recipientDetails";
// import TextField from "../../../components/ui/TextField";
import Button from "../../../components/ui/Button";
import Dropdown from "../../../components/ui/Dropdown";
import { DropdownIcon } from "../../../assets/Icons";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { SearchableDropdown } from "../../../components/ui/SearchableDropdown";
import { indianStatesList } from "../../../utils";
import { TextField } from "@mui/material";

export const StepTwo: FunctionComponent<RecipientProps.StepTwo> = ({
  phoneNumber,
  pinCode,
  emailId,
  city,
  addressLine1,
  addressLine2,
  state,
  relationship,
  setRelationship,
  setState,
  setPhoneNumber,
  setPinCode,
  setAddressLine1,
  setAddressLine2,
  setCity,
  setStep = 2,
  setIsModalOpen,
  setEmailId,
  handleFinalClick,
}) => {
  const enabled =
    "md:w-1/5 w-4/5 h-12 rounded-full  bg-primary text-white font-bold text-[18px] mt-20";
  const disabled =
    "md:w-1/5 w-4/5 h-12 rounded-full bg-gray-500 text-white font-bold text-[18px] mt-24";
  //   const phoneNumber = sessionStorage.getItem("phoneNumber") || "";
  const mixpanelToken: any = process.env.REACT_APP_MIXPANEL_TOKEN;
  mixpanel.init(mixpanelToken, {
    debug: true,
    api_host:process.env.REACT_APP_MIXPANEL_API,
  });
  const specialCharRegExp = "[a-zA-Z0-9 ]{1,}";

  const [timeoutId, setTimeoutId] = useState(null);

  const stepTwoDisabled =
    phoneNumber === "" ||
    emailId === "" ||
    relationship === "" ||
    pinCode === "" ||
    addressLine1 === "" ||
    addressLine2 === "" ||
    city === "" ||
    state === "";

  React.useEffect(() => {
    window.console.log("relationship", relationship);
  }, [relationship]);

  const handlePincodeChange = async (event: any) => {
    const newPincode = event.target.value.slice(0, 6);
    setPinCode(newPincode);

    if (newPincode.length === 6) {
      if (timeoutId) {
        clearTimeout(timeoutId);
      }

      const newTimeoutId: any = setTimeout(async () => {
        try {
          const response = await fetch(`https://api.postalpincode.in/pincode/${newPincode}`);
          const data = await response.json();

          if (data && data[0].Status === "Success") {
            const city = data[0].PostOffice[0].District;
            const state = data[0].PostOffice[0].State;

            setCity(city);
            setState(state);
          } else {
            toast(data[0].Message);
            setCity("");
            setState("");
          }
        } catch (error) {
          console.error(error);
          setCity("");
          setState("");
        }
      }, 1000);

      setTimeoutId(newTimeoutId);
    } else {
      setCity("");
      setState("");
    }
  };

  return (
    <motion.div
      initial={{ width: 0 }}
      animate={{ width: "100%" }}
      exit={{ x: window.innerWidth, transition: { duration: 0.5 } }}
    >
      <div id="step1" className="flex flex-col items-center justify-center">
        <ToastContainer autoClose={500} />
        <div className="flex flex-col justify-center text-center lg:mt-10 md:w-[50%] w-[80%]">
          <div className="flex flex-col w-full space-y-4 md:space-y-0 md:flex-row md:justify-evenly md:space-x-5">
            <TextField
              placeholder="Phone Number"
              required={true}
              fullWidth
              type="text"
              className="my-3 text-center bg-white border-solid md:mx-6"
              value={phoneNumber}
              onChange={(e) => setPhoneNumber(e.target.value)}
            />
            <TextField
              placeholder="Email ID"
              required={true}
              fullWidth
              type="text"
              className="text-center bg-white border-solid"
              value={emailId}
              onChange={(e) => setEmailId(e.target.value)}
            />
          </div>
        </div>
        <div className="flex flex-col justify-center w-full px-12 text-center md:px-0 md:w-1/2">
          <div className="flex flex-col items-center w-full my-4 space-y-4 md:space-x-4 md:space-y-0 md:flex-row">
            <div
              onClick={() => setIsModalOpen("relationship")}
              className="flex items-center justify-between md:w-[95%] w-full h-16 px-5 bg-white border rounded cursor-pointer p-x-10"
            >
              {relationship ? relationship : "Select Relationship"}
              {<DropdownIcon />}
            </div>
            <TextField
              placeholder="PIN CODE"
              required={true}
              fullWidth
              type="text"
              className="text-center bg-white border-solid"
              value={pinCode}
              onChange={handlePincodeChange}
            />
          </div>
        </div>
        <div className="flex flex-col justify-center text-center md:w-[50%] w-[80%]">
          <div className="flex flex-col w-full space-y-4 md:flex-row md:justify-evenly md:space-y-0 md:space-x-5">
            <TextField
              placeholder="Address Line 1"
              required={true}
              fullWidth
              type="text"
              className="my-3 text-center bg-white border-solid md:mx-6"
              value={addressLine1}
              onChange={(e) => setAddressLine1(e.target.value)}
            />
            <TextField
              placeholder="Address Line 2"
              required={true}
              fullWidth
              type="text"
              className="text-center bg-white border-solid"
              value={addressLine2}
              onChange={(e) => setAddressLine2(e.target.value)}
            />
          </div>
        </div>
        <div className="flex flex-col justify-center mt-4 text-center md:w-[50%] w-[80%]">
          <div className="flex flex-col w-full space-y-4 md:flex-row md:justify-between md:space-x-5 md:space-y-0">
            <TextField
              placeholder="City"
              required={true}
              type="text"
              fullWidth
              className="my-3 text-center bg-white border-solid md:mx-6"
              value={city}
              onChange={(e) => setCity(e.target.value)}
            />
            <TextField
              placeholder="State"
              required={true}
              fullWidth
              type="text"
              className="my-3 text-center bg-white border-solid md:mx-6"
              value={state}
              onChange={(e) => setState(e.target.value)}
            />
            {/* <SearchableDropdown
              options={indianStatesList}
              label="Select state"
              mainClassname={"w-full bg-white"} // add an onclick prop here ffs retard
              onChange={(e: any, v: any) => setState(v)}
            /> */}
          </div>
          {/* <TextField
            placeholder="Country"
            required={true}
            disabled={true}
            value={`${(
              <img
                src={`/images/png/INR.png`}
                alt="country-image"
                className="rounded-[200px] w-full h-full"
              />
            )} India`}
            type="text"
            className="text-center border-black border-solid"
          /> */}
          <div className="flex items-center md:w-[46%] w-full h-16 md:mx-6 my-3 bg-gray-200 rounded hover:cursor-not-allowed justify-evenly">
            <img
              src={`/images/png/INR.png`}
              alt="country-image"
              className="rounded-[200px] w-[30px] h-[30px]"
              width="2px"
              height="2px"
            />
            <span>INDIA</span>
          </div>
        </div>
        <Button
          id="submit-btn-step-one-1 "
          className={stepTwoDisabled ? disabled : enabled}
          onClickHandler={handleFinalClick}
          disabled={stepTwoDisabled}
        >
          Save & Proceed
        </Button>
      </div>
    </motion.div>
  );
};
