import React from "react";
import LocationLogo from "../../assets/svg/location.svg";
import PhoneLogo from "../../assets/svg/Phone.svg";
import EmailLogo from "../../assets/svg/Email.svg";
import InstaLogo from "../../assets/svg/insta.svg";
import FacebookLogo from "../../assets/svg/facebook.svg";
import LinkedInLogo from "../../assets/svg/linkedin.svg";
import { NewAccordion } from "../../components/ui/NewAccordian";
import { SupportPageFAQ } from "../../utils";

interface CardDataProps {
  subHeading: string;
  subText: string;
}

interface CardProps {
  image: string;
  heading: string;
  data: CardDataProps[];
}

const Data = {
  image: LocationLogo,
  heading: "Address",
  data: [
    {
      subHeading: "UK",
      subText: "13 Hanover Square, Mayfair, London, W1S 1HN",
    },
    {
      subHeading: "Headquarter",
      subText: "2082, 24th Main Rd, Sector-1, HSR Layout, Bengaluru, Karnataka 560102",
    },
  ],
};

const EmailContact = {
  image: EmailLogo,
  heading: "E-mail",
  data: [
    {
      subHeading: "",
      subText: "care@moneyhop.co.",
    },
  ],
};

const PhoneContact = {
  image: PhoneLogo,
  heading: "Phone",
  data: [
    {
      subHeading: "",
      subText: " +91-6364001001",
    },
  ],
};

export const Card = ({ image, heading, data }: CardProps) => {
  return (
    <div
      className={`p-4 my-4 flex flex-col h-fit bg-white items-center justify-center rounded-md ${
        data?.length > 1 ? "w-full" : "md:w-[49.5%] w-full"
      }`}
    >
      <img src={image} />
      <h3 className="text-[20px] font-semibold">{heading}</h3>
      {data?.map((textData, index) => (
        <div key={index} className="flex items-center w-full justify-center">
          <h4 className="text-base font-semibold">{textData?.subHeading || ""}</h4>
          {textData?.subHeading && <span className="px-1">:</span>}
          <p className="">{textData?.subText || ""}</p>
        </div>
      ))}
    </div>
  );
};

export const SupportPage = () => {
  const handleClick = (link: string) => window.open(`${link}`);

  return (
    <div className="p-8 max-w-full">
      <Card image={Data?.image} data={Data?.data} heading={Data?.heading} />
      <div className="flex items-center max-w-full justify-between gap-2 flex-wrap">
        <Card
          image={EmailContact?.image}
          data={EmailContact?.data}
          heading={EmailContact?.heading}
        />
        <Card
          image={PhoneContact?.image}
          data={PhoneContact?.data}
          heading={PhoneContact?.heading}
        />
      </div>
      {/*  */}
      <div className="w-full flex items-center justify-center">
        <p className="text-xl font-normal">Or get in touch with us at</p>
        <div className="min-h-[50px] w-[1px] bg-[#CCCCCC] mx-5" />
        <div className="max-w-20 flex items-center justify-start">
          <img
            src={InstaLogo}
            onClick={() => handleClick("https://www.instagram.com/moneyhop_/")}
            className="pl-2 cursor-pointer"
          />
          <img
            src={FacebookLogo}
            onClick={() => handleClick("https://www.facebook.com/moneyhop1/")}
            className="pl-4 cursor-pointer"
          />
          <img
            src={LinkedInLogo}
            onClick={() => handleClick("https://in.linkedin.com/company/moneyhop")}
            className="pl-4 cursor-pointer"
          />
        </div>
      </div>
      <div className="w-full py-4">
        <h3 className="text-center text-2xl font-semibold">Frequently Asked Questions</h3>
        <div>
          {SupportPageFAQ &&
            SupportPageFAQ?.map((data, index) => <NewAccordion key={index} number={index+1} data={data} showNumber={true} />)}
        </div>
      </div>
    </div>
  );
};
