/* eslint-disable */
import React, { FunctionComponent, useEffect, useState } from "react";
import { DownIcon, DropdownIcon, UserPhoto } from "../../assets/Icons";
import Button from "../../components/ui/Button";
import RecipientProps from "./Recipient";
import { motion } from "framer-motion";
import mixpanel from "mixpanel-browser";
import { TextField } from "@mui/material";
import FloatingDropDown from "../../components/ui/FloatingDropDown";
import { transactionDetails } from "../../store";
import { useRecoilValue } from "recoil";

export const StepOne: FunctionComponent<RecipientProps.StepOne> = ({
  setIsModalOpen,
  setStep,
  relationship,
  setName,
  name,
  setMobile,
  mobile,
  category,
  setCategory,
  fromSendMoney
}) => {
  const transactionDetailsData = useRecoilValue(transactionDetails);
  const enabled = "w-1/5 h-12 rounded-full  bg-primary text-white font-bold text-[18px] mt-28";
  const disabled = "w-1/5 h-12 rounded-full bg-gray-500 text-white font-bold text-[18px] mt-36";
  const mixpanelToken: any = process.env.REACT_APP_MIXPANEL_TOKEN;
  mixpanel.init(mixpanelToken, {
    debug: true,
    api_host:process.env.REACT_APP_MIXPANEL_API,
  });
  const specialCharRegExp = "[a-zA-Z0-9 ]{1,}";

  const handleCategory = (e: any) => {
    setCategory(e);
  }


  const allCategoryList = [
    {
      value: "Institution",
      label: "Institution"
    },
    {
      value: 'Individual',
      label: 'Individual'
    },
    {
      value: 'Rent Payments',
      label: 'Rent Payments'
    },
    {
      value: 'Flywire',
      label: 'Flywire'
    },
    {
      value: 'GIC',
      label: 'GIC'
    },
    {
      value: 'Others',
      label: 'Others'
    }
  ]

  const categoryListForUni = [
    {
      value: 'Institution',
      label: 'Institution'
    },
    {
      value: 'Flywire',
      label: 'Flywire'
    },
    {
      value: 'Others',
      label: 'Others'
    }
  ]

  const categoryListForUsdNonUsdAndLivi = [
    {
      value: 'Individual',
      label: 'Individual'
    },
    {
      value: 'Rent Payments',
      label: 'Rent Payments'
    },
    {
      value: 'Others',
      label: 'Others'
    }
  ]

  const categoryListForCadAndLivi = [
    {
      value: 'GIC',
      label: 'GIC'
    },
    {
      value: 'Others',
      label: 'Others'
    }
  ]

  const categoryListForFamily = [
    {
      value: 'Individual',
      label: 'Individual'
    },
    {
      value: 'Others',
      label: 'Others'
    }
  ]

  const categoryListForGift = [
    {
      value: 'Individual',
      label: 'Individual'
    },
    {
      value: 'Institution',
      label: 'Institution'
    },
    {
      value: 'Others',
      label: 'Others'
    }
  ]

  const categoryList =  fromSendMoney ? transactionDetailsData?.transaction_purpose === "Overseas Education - University Fees"
      ? categoryListForUni : transactionDetailsData?.dest_currency === "CAD" && 
      transactionDetailsData?.transaction_purpose === "Overseas Education - Living Expenses"
      ? categoryListForCadAndLivi : transactionDetailsData?.dest_currency !== "CAD" && 
      transactionDetailsData?.transaction_purpose === "Overseas Education - Living Expenses"
      ? categoryListForUsdNonUsdAndLivi : transactionDetailsData?.transaction_purpose === "Family Maintenance"
      ? categoryListForFamily : transactionDetailsData?.transaction_purpose === "Personal Gift or Donation"
      ? categoryListForGift : allCategoryList : allCategoryList
  return (
    <motion.div
      initial={{ width: 0 }}
      animate={{ width: "100%" }}
      exit={{ x: window.innerWidth, transition: { duration: 0.5 } }}
    >
      <div
        id="step1"
        className="flex flex-col items-center justify-center mt-32 -ml-5 space-x-10 md:ml-0"
      >
        <div className="flex flex-col items-center justify-center space-x-10 md:flex-row">
          <UserPhoto />
          <div id="right-form" className="flex flex-col mt-10 space-y-5 md:mt-0">
            <TextField
              onChange={(e) => setName(e.target.value)}
              value={name}
              type="text"
              className="w-[400px] p-5 border-2 border-gray-300 bg-white h-14 rounded-md"
              required
              autoComplete="off"
              label="Full Name"
            />
            <TextField
              label="Mobile Number"
              onChange={(e) => setMobile(e.target.value)}
              value={mobile}
              type="number"
              className="w-[400px] p-5 border-2 border-gray-300 bg-white h-14 rounded-md"
              required
            />
            {/* <div
              onClick={() => setIsModalOpen("category")}
              className="flex items-center justify-between w-full p-5 py-[15px] text-black text-[14px] bg-white border-2 border-gray-300 rounded-md cursor-pointer h-full mb-4"
            > */}
              {/* {category ? (
                <div className="flex items-center justify-between w-full">
                  <span className="text-black">{category}</span>
                  <div>
                    <DropdownIcon fillColor="" />
                  </div>
                </div>
              ) : (
                <div className="flex items-center justify-between w-full">
                  <span className="text-gray-500">Category</span>
                  <div>
                    <DropdownIcon fillColor="" />
                  </div>
                </div>
              )} */}
              <FloatingDropDown
                options={categoryList}
                onSelect={handleCategory}
                placeHolder="Category"
                required
                dropDownvalue={category}
                dropdownClassname="px -5 md:w-[25rem] w-full"
                bgColor="bg-[#e8f0fe]"
                selectedBgColor="bg-[#e8f0fe]"
              />
            {/* </div> */}
          </div>
        </div>
        <Button
          id="submit-btn-step-one-1"
          onClickHandler={() => {
            mixpanel.track("Save and Proceed on Add Recipient Step 1", {
              "Full Name": name,
              "Phone Number": mobile,
              // eslint-disable-next-line prettier/prettier
              Relationship: relationship,
            });
            setStep(2);
          }}
          disabled={name === "" || mobile === "" || category === ""}
          className={name === "" || mobile === "" || category === "" ? disabled : enabled}
        >
          Save & Proceed
        </Button>
      </div>
    </motion.div>
  );
};
