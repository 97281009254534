import React from "react";

const PrimeTag = () => (
  <svg width="23" height="25" viewBox="0 0 23 25" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      d="M0.789177 15.2479L0.790052 3.26541C0.790173 1.60864 2.13328 0.265625 3.79005 0.265625H19.2662C20.9231 0.265625 22.2663 1.60891 22.2662 3.26585L22.2653 15.2484C22.2652 16.2313 21.7837 17.1518 20.9764 17.7124L13.2392 23.0848C12.2104 23.7991 10.8462 23.7992 9.81735 23.085L2.07838 17.7125C1.27076 17.1519 0.789105 16.2311 0.789177 15.2479Z"
      fill="#F2AD00"
    />
    <path
      d="M11.5295 3.58594L13.5379 7.6521L18.0238 8.30647L14.7767 11.4713L15.5421 15.9408L11.5295 13.8295L7.51272 15.9408L8.28232 11.4713L5.03516 8.30647L9.5211 7.6521L11.5295 3.58594Z"
      fill="white"
    />
  </svg>
);
export default PrimeTag;
