/* eslint-disable */
import clsx from "clsx";
import { CloseIcon } from "../../../assets/Icons"

interface WarningModalProps {
  isOpen: boolean;
  onClose: () => void;
  onCancel: () => void;
  onConfirm: () => void;
  data: string
}

const WarningModal: React.FC<WarningModalProps> = ({
  isOpen,
  onClose,
  onConfirm,
  onCancel,
  data
}) => {
  if (!isOpen) return null;
  return (
    <div
      className="fixed inset-0 bg-gray-600 bg-opacity-50 overflow-y-auto h-full w-full"
      id="my-modal"
    >
      <div className="relative top-[25%] mx-auto p-5 border w-[500px] shadow-lg rounded-md bg-white">
        <div className="mt-3 text-center">
          <div className="mx-4">
            <div className=" mb-4 flex justify-between">
                <h3 className="text-lg leading-6 font-normal text-gray-900">Warning</h3>
                <button onClick={onClose}>
                    <CloseIcon fill="#1E2226"/>
                </button>
            </div>
            <div className="border border-gray-100"></div>
            <div className="my-2 py-3  flex justify-start">
              <p className="text-sm font-medium text-left text-gray-800">
                {data}
              </p>
            </div>
            <div className="border border-gray-100"></div>
          </div>
          <div className="flex justify-end px-4 mt-5 py-3">
            <button
              id="cancel-btn"
              className="px-4 py-2 border h-[40px] border-red-100 bg-white text-black rounded hover:bg-gray-400 mr-2"
              onClick={onCancel}
            >
              Cancel Transaction
            </button>
            <button
              id="confirm-btn"
              className="px-4 py-2 h-[40px] bg-primary text-white rounded hover:bg-primary"
              onClick={onConfirm}
            >
              Continue
            </button>
          </div>
        </div>
      </div>
    </div>
  );
};

export default WarningModal;
