//Global Imports
import clsx from "clsx";
import React from "react";
import { Controller, useForm } from "react-hook-form";
//End Global Imports

//Local Imports
import TextField from "../../../components/ui/TextField";
import Button from "../../../components/ui/Button";
import { getErrorMessage, regexForPassword } from "../../../utils";
import Tooltip from "../../../components/ui/Tooltip";
import { DoubleVerifiedIcon } from "../../../assets/Icons";
//End Local Imports

const StepThree = ({ saveNewPassword }: any) => {
  const labelStyle =
    "pb-3 text-sm md:text-base font-bold sm:font-normal text-black font-ssp px-8 text-start pl-4 w-full justify-start";

  type FormInputs = {
    password: string;
    confirmPassword: string;
  };

  const {
    formState: { errors, isValid, isDirty },
    handleSubmit,
    getValues,
    control,
  }: any = useForm<FormInputs>({ mode: "onChange" });

  return (
    <form
      onSubmit={handleSubmit(saveNewPassword)}
      className="flex flex-col items-start md:items-center mx-7 sm:mx-[52px] h-full justify-between"
    >
      <div>
        <div className="relative w-screen sm:w-[400px] px-6 sm:px-0 pb-8">
          <div className={clsx(labelStyle, "md:pb-4 pb-3")}>New Password</div>
          <Controller
            control={control}
            name="password"
            rules={{ required: true, pattern: regexForPassword }}
            render={({ field: { name, value, onChange } }) => {
              return (
                <TextField
                  name={name}
                  type="password"
                  placeholder="Enter new password"
                  inputClasses={clsx(
                    errors.password
                      ? "border"
                      : isDirty && !errors.password && getValues("password") !== undefined
                      ? "border-success"
                      : "border-none ",
                    "bg-primary-350",
                  )}
                  placeholderStyle="text-black font-lato font-bold text-sm"
                  value={value}
                  onChange={(v) => onChange(v)}
                  error={errors.password}
                  errorMessage={getErrorMessage(errors, "password", "Password")}
                  required
                  fullWidth
                />
              );
            }}
          />
          {errors.password && (
            <Tooltip
              positionOfTip="right"
              bgColor="bg-secondary-800"
              tooltipStyles="!bottom-auto pb-4 md:pb-0 !top-2/3"
            >
              <div className="z-10 px-5 py-4 rounded-md sm:w-[372px] w-fit">
                <div className="pb-[10px] text-black font-ssp font-bold text-sm">
                  Password criteria
                </div>
                <div className="flex items-center pb-2 text-sm font-normal font-ssp text-primary">
                  <span className="mr-1">
                    <DoubleVerifiedIcon />
                  </span>
                  Should be 8 characters at least
                </div>
                <div className="flex items-center pb-2 text-sm font-normal font-ssp text-error">
                  <span className="mr-1">
                    <DoubleVerifiedIcon fillColor="stroke-error" />
                  </span>
                  Should contain the special characters @$#+*
                </div>
                <div className="flex items-center pb-2 text-sm font-normal font-ssp text-error">
                  <span className="mr-1">
                    <DoubleVerifiedIcon fillColor="stroke-error" />
                  </span>
                  Should contain a numeric value 1,2..
                </div>
                <div className="flex items-center pb-2 text-sm font-normal font-ssp text-grey-600">
                  <span className="mr-1">
                    <DoubleVerifiedIcon fillColor="stroke-grey-600" />
                  </span>
                  Should not be the same as your email address
                </div>
                <div className="flex items-center pb-2 text-sm font-normal font-ssp text-primary">
                  <span className="mr-1">
                    <DoubleVerifiedIcon />
                  </span>
                  Should contain atleast one uppercase letter
                </div>
                <div className="flex items-center pb-2 text-sm font-normal font-ssp text-error">
                  <span className="mr-1">
                    <DoubleVerifiedIcon fillColor="stroke-error" />
                  </span>
                  Should contain atleast one lowercase letter
                </div>
              </div>
            </Tooltip>
          )}
        </div>
        <div className="w-screen sm:w-[400px] px-6 sm:px-0 md:pb-[99px] pb-6">
          <div className={clsx(labelStyle, "md:pb-4 pb-3")}>Confirm password</div>
          <Controller
            control={control}
            name="confirmPassword"
            rules={{
              required: true,
              validate: {
                sameAs: () => {
                  return getValues("password") === getValues("confirmPassword");
                },
              },
            }}
            render={({ field: { name, value, onChange } }) => {
              return (
                <TextField
                  name={name}
                  type="password"
                  placeholder="Enter confirm password"
                  inputClasses={clsx(
                    errors.confirmPassword
                      ? "border"
                      : isDirty &&
                        !errors.confirmPassword &&
                        getValues("confirmPassword") !== undefined
                      ? "border-success"
                      : "border-none ",
                    `bg-primary-350`,
                  )}
                  placeholderStyle="text-black font-lato font-bold text-sm"
                  value={value}
                  onChange={(v) => onChange(v)}
                  error={errors.confirmPassword}
                  errorMessage={getErrorMessage(errors, "confirmPassword", "Confirm Password")}
                  required
                  fullWidth
                />
              );
            }}
          />
        </div>
      </div>
      <div className="flex justify-center w-full">
        <Button
          id="step--3-confirm"
          bgColor={isValid ? "bg-primary" : "bg-grey-550"}
          fontStyle={{
            fontFamily: "font-ssp",
            fontSize: "text-lg",
            fontWeight: "font-bold",
            fontColor: "text-white",
          }}
          borderStyle={{
            borderRadius: "rounded-[100px]",
          }}
          className="w-[307px] h-[50px]"
        >
          Confirm
        </Button>
      </div>
    </form>
  );
};

export default StepThree;
