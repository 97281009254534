import React, { FunctionComponent } from "react";
import clsx from "clsx";

import { IDatePicker } from "./DatePicker";

export const DatePicker: FunctionComponent<IDatePicker.DatePickerProps> = ({
  id,
  handleSubmit,
  submitColor = "bg-primary",
  submitTextColor = "text-white",
  bgDatePicker = "bg-gray-200",
  submitButtonClassname,
  datepickerClassname,
  selectedDate,
  dateChangeHandler,
  isSubmit = false,
  disabled = false,
  maxDate,
}) => {
  return (
    <form onSubmit={handleSubmit}>
      <input
        className={clsx(
          "text-[16px] p-[3px]",
          bgDatePicker,
          datepickerClassname,
          "font-sans rounded-sm w-200px",
        )}
        type="date"
        id={id}
        value={selectedDate}
        onChange={(event) => dateChangeHandler(event.target.value)}
        disabled={disabled}
        max={maxDate}
      />
      {isSubmit && (
        <input
          type="submit"
          className={clsx(
            "ml-[10px]",
            submitColor,
            submitTextColor,
            submitButtonClassname,
            "px-[6px] border-2 rounded-md",
          )}
        />
      )}
    </form>
  );
};
