//#Global Imports
import React, { useRef } from "react";
import clsx from "clsx";
//#end Global Imports

//#Local Imports
import Button from "../../../components/ui/Button";
import { CloseIcon, PassportIcon, UploadIcon, VisibilityOnIcon } from "../../../assets/Icons";
import Typograpy from "../../../components/ui/Typography";
import TextField from "../../../components/ui/TextField";
import Typography from "../../../components/ui/Typography";
import IUserDocuments from "../userDocuments";
import ErrorField from "../../../components/ui/ErrorField";
import { getAdditionalDocFile } from "../../../services/kyc";
import Eye from "../../../assets/svg/Eye.svg";
import UploadCloud from "../../../assets/svg/UploadCloud.svg";
import Refresh from "../../../assets/svg/Refresh.svg";
import fileImage from "../../../assets/svg/file.svg";
//#end Local Imports

const AdditionalDocumentsList: React.FC<IUserDocuments.IAdditionalDocumentsListProps> = ({
  data,
  setIsModalOpen,
  handleUploadAdditionalDocs,
  selectedPurposeValue,
  handleAdditionalDocRemove,
  setPreViewImageSrc,
  index,
  buttonLoader,
}) => {
  const fileInputRef = useRef<HTMLInputElement | null>(null);
  const truncatedText = (originalText: string) => {
    const maxLength = 10;
    return originalText.slice(0, maxLength) + '...' + originalText.slice(-7);
  };

  const handleDivClick = () => {
    if (fileInputRef.current) {
      fileInputRef.current.click();
    }
  };

  const handleUploadButtonClick = () => {
    if (fileInputRef.current) {
      fileInputRef.current.click();
    }
  };

  const handleRemoveAndUpload = () => {
    handleAdditionalDocRemove(index, "otherDocuments", data.code);
    if (fileInputRef.current) {
      fileInputRef.current.click();
    }
  };

  const handlePreviewDoc =(data:any)=>{
    getAdditionalDocFile(data?.uploadedId)
    .then((res: any) => {
      if (res && res.data) {
        // const blob = new Blob([res.data], {type: 'application/pdf'});
        const blob = new Blob([res.data], {type: 'application/json'});
        const fileURL = URL.createObjectURL(blob);
        setPreViewImageSrc(fileURL);
        setIsModalOpen("preViewUploadedDoc");
      } else {
        console.error('Invalid response or missing data');
      }
    })
    .catch((error: any) => {
      console.error('Error fetching or processing data:', error);
    });
    setIsModalOpen("preViewUploadedDoc");
  };

  return (
    <div className="flex flex-col w-full lg:w-auto mb-3 lg:mr-11">
      <div
        className={`hidden  w-[190px] lg:flex flex-col items-center justify-center px-1 relative rounded overflow-hidden ${
          data?.isUploaded ? "bg-[#2E7D331A] h-[133px]" : "bg-[#fffefe] h-[165px]"
        }`}
        onClick={handleDivClick}
        style={{ cursor: 'pointer' }}
      >
        <PassportIcon />
        <Typography tagType="div" className="relative text-sm font-normal text-black-200 mt-[22px] text-center">
          {data.name}
          {data.isMandatory && (
            <span className="absolute text-lg top-[-6px] text-red-500">*</span>
          )}
        </Typography>

        <Typograpy
          tagType="div"
          text="(If available)"
          className={clsx(
            data.name === "Rent Agreement Invoice" ? "block" : "hidden",
            "text-sm font-normal text-black-200 text-center",
          )}
        />
      </div>
      {data?.isUploaded && (
          <div className="flex justify-start items-center pt-2">
            <img src={fileImage} />
            <Typography
              tagType="div"
              text={data?.fileName.length > 15 ? truncatedText(data.fileName) : data.fileName}
              className="hidden pl-1 text-base font-semibold text-black-500 lg:flex"
            />
          </div>
        )}
      <input
        ref={fileInputRef}
        type="file"
        accept="image/*,application/pdf"
        className="hidden"
        onChange={(e) => {
          handleUploadAdditionalDocs(e, data.code, selectedPurposeValue, data.id);
        }}
      />
      <Button
        bgColor={data.isUploaded ? "bg-[#2E7D33] text-white" : "bg-[#7C8694] text-white"}
        className={
          "relative h-[50px] w-[190px] hidden items-center justify-center lg:flex rounded cursor-pointer mt-2"
        }
        onClickHandler={ () => { 
          data?.isUploaded ? handlePreviewDoc(data) : handleUploadButtonClick();
        }}
        isLoading={buttonLoader[data?.code as keyof typeof buttonLoader]}
      >
        {data.isUploaded ? (
          <div className="flex justify-center items-center w-full">
            <img src={Eye} />
            <p className="pl-2 font-semibold">View</p>
          </div>
        ) : (
          <div className="flex items-center justify-center">
            <img src={UploadCloud} />
            <span className="pl-2 text-white font-semibold">Upload</span>
          </div>
        )}
      </Button>
      <div className="flex flex-col items-center">
        {data.isUploaded && (
          <div
            className="hidden mt-2 text-base font-semibold text-center cursor-pointer text-[#586372] underline lg:flex"
            onClick={handleRemoveAndUpload}
          >
            <img src={Refresh} className="pr-2" />
            Re-Upload
        </div>
        )}
        {data.errorMessage !== "" && <ErrorField errorMessage={data.errorMessage} />}
      </div>
      <div className="relative">
        <div className="flex items-center space-x-8">
          <Button
            id="dajkk-=akj-=n"
            bgColor="bg-white"
            fontStyle={{
              fontFamily: "",
              fontSize: "text-sm",
              fontWeight: "font-normal ",
              fontColor: "text-black-200",
            }}
            borderStyle={{
              borderRadius: "rounded",
            }}
            className="w-full h-16 mb-5 lg:hidden"
          >
            <div className="flex items-center justify-between w-full px-6">
              <div className="flex items-center space-x-6">
                <PassportIcon
                  width="23px"
                  height="29px"
                  fillColor={data?.isUploaded ? "fill-success-150" : "fill-primary"}
                />
                <div>{data.name}</div>
              </div>
              <div className="flex items-center space-x-4">
                {data.isUploaded && data.errorMessage === "" && (
                  <div
                    className="z-50 cursor-pointer"
                    onClick={(e) => (
                      e.stopPropagation(),
                      setPreViewImageSrc(data.uploadedFile), setIsModalOpen("preViewUploadedDoc")
                    )}
                  >
                    <VisibilityOnIcon fillColor="fill-black-150" />
                  </div>
                )}
                <UploadIcon fillColor={data?.isUploaded ? "fill-success-150" : "fill-primary"} />
              </div>
            </div>
          </Button>
          {data.isUploaded && data.errorMessage === "" && (
            <div
              className="bg-gray-500 w-[35px] lg:w-[30px] h-[30px] flex justify-center items-center rounded-full cursor-pointer mb-5 z-50 lg:hidden"
              onClick={() => handleAdditionalDocRemove(index, "otherDocuments", data.code)}
            >
              <CloseIcon />
            </div>
          )}
        </div>
        <div className="lg:hidden">
          <TextField
            accept="image/*,application/pdf"
            name={data.name}
            type="file"
            disabled={data.isUploaded}
            inputClasses="!h-[50px] !cursor-pointer !h-16"
            rootClassName="absolute top-0 opacity-0 left-0 right-0"
            onChange={(e) => {
              handleUploadAdditionalDocs(e, data.code, selectedPurposeValue, data.id);
            }}
            onClick={(event) => {
              event.currentTarget.value = "";
            }}
            fullWidth
          />
        </div>
      </div>
    </div>
  );
};

export default AdditionalDocumentsList;


