import { Axios } from "../axios";

export const primeGenerateOrder = () => {
  const currentUser = JSON.parse(sessionStorage.getItem("currentUser") || "{}");
  return Axios.get(`/remit/bank/payment/${currentUser.id}/payForPrime`, {
    headers: {
      Authorization: `${currentUser.idToken}`,
    },
  });
};

export const primePaymentResponse = (body: any) => {
  const currentUser = JSON.parse(sessionStorage.getItem("currentUser") || "{}");
  return Axios.post(`/remit/bank/payment/${currentUser.id}/PrimePaymentFromHopremit`, body, {
    headers: {
      Authorization: `${currentUser.idToken}`,
    },
  });
};
