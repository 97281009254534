/* eslint-disable */
import React from "react";
import { motion } from "framer-motion";
import { IDocumentUpload } from "./DocumentUpload";
import Modal from "../../../components/ui/Modal";
import clsx from "clsx";
import Typograpy from "../../../components/ui/Typography";
import { text } from "stream/consumers";
import { KYCVerification } from "../../../assets/Icons";
import Checkbox from "../../../components/ui/Checkbox";
import Button from "../../../components/ui/Button";
import { useNavigate } from "react-router-dom";

const DocumentUpload: React.FC<IDocumentUpload.IProps> = ({
  isKycModalOpen,
  setIsKycModalOpen,
}) => {
  const navigate = useNavigate();
  const [isAgree, setIsAgree] = React.useState<boolean>(false);
  const [showKycVerification, setShowKycVerification] = React.useState<boolean>();
  window.console.log("Modal status", isKycModalOpen);
  return (
    <motion.div id="#documentUpload">
      <Modal
        modalClass="!pt-6 md:pr-11 !rounded md:overflow-hidden overflow-y-scroll md:max-h-[80%]"
        visible={isKycModalOpen}
        closeHandler={() => {
          setIsKycModalOpen(false);
          setShowKycVerification(false);
        }}
      >
        <div
          className={clsx(
            " mt-10 sm:p-[30px] pb-5 md:pl-[30px] md:pr-[18px] w-4/5 flex items-start justify-between",
          )}
        >
          <div className={clsx("items-center flex flex-col md:mr-12 lg:relative")}>
            <div className="md:pb-[37px] pb-12">
              <Typograpy
                tagType={"div"}
                text="Welcome to"
                className={clsx("hidden md:flex text-black font-semibold text-[28px] font-lato")}
              />
              <Typograpy
                tagType={"div"}
                text="moneyHOP"
                className={clsx("hidden md:flex text-black font-semibold text-[38px] font-lato")}
              />
              <Typograpy
                tagType={"div"}
                text="We will be verifying your documents to provide you the seamless experience."
                className="2xs: p-[10px] text-sm font-normal text-black md:text-lg font-lato"
              />
            </div>
            <div className="md:hidden mb-[10px]">
              <KYCVerification width={"293px"} />
            </div>
            <div className="mb-16">
              <Checkbox
                label={
                  "To ensure a smooth process, please provide proper documents that are legible. By proceeding, you agree to provide the necessary documents for verification authentication."
                }
                value="true"
                name="conditionalNote"
                labelClassName="w-full ml-[26px]"
                checkboxClassname="lg:m-[2px]"
                handleChange={(e) => {
                  setIsAgree(e.target.checked);
                }}
              />
            </div>
            <Button
              id="sfkdj902d987--090489"
              // bgColor={"bg-grey-350"}
              bgColor={isAgree ? "bg-primary" : "bg-grey-350"}
              disabled={!isAgree && location.pathname !== "/inward/user-documents"}
              fontStyle={{
                fontFamily: "",
                fontSize: "text-lg",
                fontWeight: "font-bold",
                fontColor: "text-white",
              }}
              className="py-[14px] px-[120px] 2xs:w-10"
              borderStyle={{
                borderWidth: "border-none",
                borderRadius: "rounded-[100px]",
              }}
              onClickHandler={() => {
                setIsKycModalOpen(false);
                // setShowKycVerification(true)
                navigate("/user-documents", {
                  state: {
                    from: "profile",
                    showKycSidePopUp: false,
                  },
                });
              }}
            >
              Confirm
            </Button>
          </div>
          <div className="hidden md:flex">
            {/* {
              showKycVerification && 
              <motion.div
              className="flex flex-col flex-1 lg:mt-12"
              initial={{ width: 0 }}
              animate={{ width: "100%" }}
              exit={{ x: window.innerWidth, transition: { duration: 0.5 } }}
              >
                <UserDocuments/>
              </motion.div>
            } */}
            {/* {showKycVerification && <UserDocuments/>} */}
          </div>
        </div>
      </Modal>
    </motion.div>
  );
};

export default DocumentUpload;
