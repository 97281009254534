import clsx from "clsx";
import React, { FunctionComponent } from "react";

import { ICheckbox } from "./Checkbox";
import ErrorField from "../ErrorField";

const Checkbox: FunctionComponent<ICheckbox.CheckboxProps> = ({
  label,
  disabled,
  labelFirst,
  value,
  checkboxClassname,
  name,
  errorMsg,
  errorMessageClassname = "",
  handleBlur,
  handleChange,
  labelClassName,
  rootclassName,
  inputClassName,
}) => {
  return (
    <React.Fragment>
      {/* <div className={clsx("flex justify-center w-fit", inputClassName)}> */}
      <div className={inputClassName ? inputClassName : "flex justify-center w-fit"}>
        <div className={clsx(labelFirst ? "flex-row-reverse pr-3" : "", `flex items-center`)}>
          <div
            className={clsx(
              rootclassName,
              "border border-blue-600 w-[17px] h-[17px] mr-2 flex justify-center items-center",
            )}
          >
            <input
              type="checkbox"
              value={value}
              name={name}
              onBlur={handleBlur}
              onChange={handleChange}
              disabled={disabled}
              className={clsx(
                checkboxClassname,
                disabled ? "text-gray-300" : "",
                `appearance-none h-3 w-3 border bg-white checked:bg-blue-600 
                    border-none focus:outline-none transition duration-100 align-top 
                    bg-no-repeat bg-center bg-contain cursor-pointer
                    `,
              )}
            />
          </div>
          <label htmlFor={`${name}`} className={clsx(labelClassName, `inline-block text-gray-800`)}>
            {label}
          </label>
        </div>
        {errorMsg && (
          <ErrorField
            errorMessage={errorMsg}
            errorClassname={clsx("ml-2", errorMessageClassname)}
          />
        )}
      </div>
    </React.Fragment>
  );
};

export default Checkbox;
