import React, { useEffect, useRef, useState } from "react";
import clsx from "clsx";
import { useRecoilState, useRecoilValue } from "recoil";
import { isVoidExpression } from "typescript";
import { Link } from "react-router-dom";

import { GAP_FROM_SIDEBAR } from "../../components/layout/ProtectedPageLayout";
import Button from "../../components/ui/Button";
import RecipientCard from "../../components/ui/RecipientCard";
import Typograpy from "../../components/ui/Typography";
import { getUserRecipients } from "../../services/userDetails";
import { recipientDetails, searchValue } from "../../store";
import { RecipientDetais } from "../../store/store";
import { SendMoneyIcon } from "../../assets/Icons";

const RecipientSearch = () => {
  const isUseEffectFired = useRef(false);
  const searchTerm = useRecoilValue(searchValue);
  const [recipient, setRecipient] = useRecoilState(recipientDetails);
  const [recipientFiltered, setFileteredRecipient] = useState<RecipientDetais>([]);
  const currentUser = JSON.parse(sessionStorage.getItem("currentUser") || "{}");
  const getRecipients = async () => {
    try {
      const response = await getUserRecipients(currentUser);
      const { data } = response;

      setRecipient(data.recipient);
    } catch (error) {
      //TODO : Show error as per the error message provided
      /* eslint-disable-next-line */
      console.error(error);
    }
  };

  useEffect(() => {
    if (!isUseEffectFired.current) {
      isUseEffectFired.current = true;
      if (!recipient.length) getRecipients();
    }
  }, []);

  useEffect(() => {
    if (searchTerm) {
      setFileteredRecipient(
        [...recipient].filter(
          (el) =>
            el.firstName.toLowerCase().includes(searchTerm.toLowerCase()) ||
            el.lastName.toLowerCase().includes(searchTerm.toLowerCase()),
        ),
      );
    }
  }, [searchTerm]);

  const enterASearchTerm = (
    <div className="flex items-center justify-center w-full h-full">
      <Typograpy
        tagType="h1"
        className="mx-5 md:mx-0 text-center text-[24px] md:text-[50px] text-grey"
        text="Please enter recipient name in search bar "
      />
    </div>
  );

  const noRecipientFound = (
    <div>
      <div className="w-full py-5 md:py-10 pl-8 md:pl-10 flex items-center bg-primary-150 gap-6 md:gap-12 rounded-[6px]">
        <Link to={"/recipient-details"}>
          <Button
            id="+-icon-say"
            className="w-9 h-9 md:w-[56px] md:h-[56px] bg-[transparent] rounded-full opacity-50 border-primary border-[3px] text-xl md:text-[40px] text-primary"
          >
            +
          </Button>
        </Link>
        <Typograpy
          className="text-primary opacity-50 text-base md:text-[30px]"
          text={"Click the “ + “ button to add recipients"}
          tagType="div"
        />
      </div>
      <div className="flex flex-col text-center mt-[94px] md:mt-[160px]">
        <Typograpy
          tagType="div"
          className="text-[32px] md:text-[48px] text-primary-600"
          text="No recipients found"
        />
        <Typograpy
          tagType="div"
          className="text-singleHistoryText text-[48px] md:text-[72px]"
          text={
            <span>
              Try the <span className="opacity-50 text-primary-600">“ + ”</span> button above
            </span>
          }
        />
      </div>
    </div>
  );

  return searchTerm ? (
    <div className={clsx("h-full", GAP_FROM_SIDEBAR)}>
      <div className="mb-8 mt-11">
        <Typograpy
          tagType="span"
          text={`Search results for "${searchTerm}"`}
          className="font-xl text-primary-900"
        />
        <Typograpy
          tagType="span"
          text={`${recipientFiltered.length} Found`}
          className="ml-5 font-xl text-primary-600"
        />
      </div>
      {recipientFiltered.length === 0
        ? noRecipientFound
        : recipientFiltered.map((el) => (
            <RecipientCard
              key={el.bankAccountNumber}
              firstName={el.firstName}
              lastName={el.lastName}
              coutryOfResidence={el.countryOfResidence}
              accNo={el.bankAccountNumber}
              onButtonClick={() => isVoidExpression}
              icon={<SendMoneyIcon className="w-3 h-3 md:w-5 md:h-5" />}
            />
          ))}
    </div>
  ) : (
    enterASearchTerm
  );
};

export default RecipientSearch;
