//#Global Imports
import React from "react";
//#end Global Imports

//#Local Imports
import UnverifiedKycModal from "./UnverifiedKycModal";
import KycVerifiedAndIsAgreeModal from "./KycVerifiedAndIsAgreeModal";
import UploadedDocPreviewModal from "./UploadedDocPreviewModal";
import UploadDocumentsModal from "./UploadDocumentsModal";
import IUserDocuments from "../userDocuments";
//#end Local Imports

const KycModals: React.FC<IUserDocuments.IKycModalProps> = ({
  isModalOpen,
  kycDetailsData,
  setIsModalOpen,
  setIsAgree,
  isAgree,
  state,
  // kycVerificationAgreementNote,
  docType,
  numberOfVerifiedDoc,
  panCardDetails,
  setDocType,
  verifiedDocumentsData,
  getColor,
  handlePreviewUploadedDoc,
  handleDeleteDoc,
  handleChangeUpload,
  handleEVerifyPanCard,
  adharCardDetails,
  setDrivingLicenseDetails,
  sendAdharOtp,
  entityId,
  dateKey,
  date,
  saveAdharOTPValue,
  adharOTPError,
  drivingLicenseDetails,
  handleEverifyDL,
  passportDetails,
  handlePassportBackVerification,
  handlePassportVerification,
  voterIDCardDetails,
  setVoterIDCardDetails,
  handleEVerifyVoterCard,
  preViewModalHeader,
  preViewModalButton,
  preViewImageSrc,
  isPDFfile,
  selectedPurpose,
  listOfPurposeforAdditionalDocuments,
  setPanCardDetails,
  setIsMobileDocumentListOpen,
  docDob,
  handleDlDobChangeHandler,
  setPassportDetails,
  handlePassportDobChangeHandler,
  ckycPhase,
  validateCkycOtpHandler,
  verifyCKYCHandler,
  downloadCkycHandler,
  sendCkycOtpHandler,
  saveCkycAdharOTPValue,
  ckycMessage,
  ckycStatus,
  ckycData,
  aadhaarLinked,
  handleNoAadhaar,
  buttonLoader,
  loading
}) => {
  return (
    <>
      <UnverifiedKycModal isModalOpen={isModalOpen} setIsModalOpen={setIsModalOpen} />
      <KycVerifiedAndIsAgreeModal
        isModalOpen={isModalOpen}
        isAgree={isAgree}
        setIsAgree={setIsAgree}
        setIsModalOpen={setIsModalOpen}
        kycVerificationAgreementNote={""}
        selectedPurpose={selectedPurpose}
        state={state}
        listOfPurposeforAdditionalDocuments={listOfPurposeforAdditionalDocuments}
        setDocType={setDocType}
        setIsMobileDocumentListOpen={setIsMobileDocumentListOpen}
      />
      <UploadDocumentsModal
        KycDetailsData={kycDetailsData}
        isModalOpen={isModalOpen}
        setIsModalOpen={setIsModalOpen}
        docType={docType}
        numberOfVerifiedDoc={numberOfVerifiedDoc}
        panCardDetails={panCardDetails}
        setDocType={setDocType}
        verifiedDocumentsData={verifiedDocumentsData}
        getColor={getColor}
        handlePreviewUploadedDoc={handlePreviewUploadedDoc}
        handleDeleteDoc={handleDeleteDoc}
        handleChangeUpload={handleChangeUpload}
        handleEVerifyPanCard={handleEVerifyPanCard}
        adharCardDetails={adharCardDetails}
        setDrivingLicenseDetails={setDrivingLicenseDetails}
        handleDlDobChangeHandler={handleDlDobChangeHandler}
        sendAdharOtp={sendAdharOtp}
        handleNoAadhaar={handleNoAadhaar}
        buttonLoader={buttonLoader}
        entityId={entityId}
        dateKey={dateKey}
        date={date}
        saveAdharOTPValue={saveAdharOTPValue}
        adharOTPError={adharOTPError}
        drivingLicenseDetails={drivingLicenseDetails}
        handleEverifyDL={handleEverifyDL}
        passportDetails={passportDetails}
        handlePassportBackVerification={handlePassportBackVerification}
        handlePassportVerification={handlePassportVerification}
        voterIDCardDetails={voterIDCardDetails}
        setVoterIDCardDetails={setVoterIDCardDetails}
        handleEVerifyVoterCard={handleEVerifyVoterCard}
        isPDFfile={isPDFfile}
        setPanCardDetails={setPanCardDetails}
        setIsMobileDocumentListOpen={setIsMobileDocumentListOpen}
        docDob={docDob}
        setPassportDetails={setPassportDetails}
        handlePassportDobChangeHandler={handlePassportDobChangeHandler}
        ckycPhase={ckycPhase}
        validateCkycOtpHandler={validateCkycOtpHandler}
        verifyCKYCHandler={verifyCKYCHandler}
        downloadCkycHandler={downloadCkycHandler}
        sendCkycOtpHandler={sendCkycOtpHandler}
        saveCkycAdharOTPValue={saveCkycAdharOTPValue}
        ckycMessage={ckycMessage}
        ckycStatus={ckycStatus}
        ckycData={ckycData}
        aadhaarLinked={aadhaarLinked}
        loading={loading}
      />
      <UploadedDocPreviewModal
        isModalOpen={isModalOpen}
        setIsModalOpen={setIsModalOpen}
        preViewModalHeader={preViewModalHeader}
        preViewModalButton={preViewModalButton}
        preViewImageSrc={preViewImageSrc}
        docType={docType}
      />
    </>
  );
};

export default KycModals;
