const RECOIL_KEYS = {
  USER_DETAILS: "userDetails",
  SEARCH_VALUE: "searchValue",
  RECIPIENT_DETAILS: "recipientDetails",
  USER_TRANSACTION_DETAILS: "userTransactionDetails",
  KYC_DETAILS: "kycDetails",
  ADDITIONAL_DATA: "additionalDocData",
  ADDITIONAL_DOC: 'additionalDocument',
  CONFIRMED_PURPOSE: "confirmedPurpose",
  FIRST_LOGIN: "isFirstLogin",
  OPEN_SIDEBAR: "openSidebar",
  TRANSACTION_LEADIDS: "transactionLeadIds",
  USER_ADDITIONAL_DOC: "userAdditionalDocs",
  INWARD_TRANSACTION: "isInwardTransaction",
  SIGNUP_SUCCESS: "isSignupSuccess",
  ESIGN_STATUS: "esignStatus",
  TRANSACTION_ID: "transactionId",
};

export default RECOIL_KEYS;
