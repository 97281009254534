import RecipientProps from "../../pages/reciepientDetails/Recipient";
import { Axios } from "../axios";

// const currentUser = JSON.parse(sessionStorage.getItem("currentUser") || "{}");

export const sendRecipients = async (body: RecipientProps.SendRecipientProps) => {
  const currentUser = JSON.parse(sessionStorage.getItem("currentUser") || "{}");
  return await Axios.post(`/remit/user/${currentUser.id}/recipients`, body, {
    headers: {
      Authorization: `${currentUser.idToken}`,
    },
  });
};
export const deleteRecipient = async (recipientId: any) => {
  const currentUser = JSON.parse(sessionStorage.getItem("currentUser") || "{}");
  return await Axios.delete(`/remit/user/${currentUser.id}/recipients/${recipientId}`, {
    headers: {
      Authorization: `${currentUser.idToken}`,
    },
  });
};

export const validateRecipient = async (type: string, number: string) => {
  const currentUser = JSON.parse(sessionStorage.getItem("currentUser") || "{}");
  return await Axios.get(`/remit/user/validateRecipient?typeOfAccount=${type}&number=${number}`, {
    headers: {
      Authorization: `${currentUser?.idToken}`,
    },
  });
};

export const getRecipientType = async (purposeCode: string, currency: string, type: any) => {
  const purposeCodeValue =
    purposeCode === "Overseas Education - Living Expenses" ? "Overseas-Education-Living-Expenses" :
    purposeCode === "Overseas Education - University Fees" ? "Overseas-Education-University-Fees" :
    purposeCode === "Family Maintenance" ? "Family-Maintenance" :
    "Personal-Gift-Donation";

  const currentUser = JSON.parse(sessionStorage.getItem("currentUser") || "{}");

  try {
    const response = await Axios.get(`remit/user/recipient/docs`, {
      params: {
        purposeCode: purposeCodeValue,
        currency: currency,
        recipientType: type === null ? "Others" : type,
      },
      headers: {
        Authorization: `${currentUser?.idToken}`,
      },
    });

    return response.data;
  } catch (error) {
    return error;
  }
};
