//#Global Imports
import React from "react";
import clsx from "clsx";
import moment from "moment";
//#end Global Imports

//#Local Imports
import ErrorField from "../../../../components/ui/ErrorField";
import TextField from "../../../../components/ui/TextField";
import Button from "../../../../components/ui/Button";
import {
  InfoIcon,
  PassportIcon,
  ReUploadIcon,
  UploadIcon,
  VisibilityOnIcon,
} from "../../../../assets/Icons";
import IUserDocuments from "../../userDocuments";
import Tooltip from "../../../../components/ui/Tooltip";
import { DatePicker } from "../../../../components/ui/DatePicker";
import mixpanel from "mixpanel-browser";
//#end Local Imports

const IndianPassportSection: React.FC<IUserDocuments.IIndianPassportSectionProps> = ({
  passportDetails,
  handlePreviewUploadedDoc,
  handleDeleteDoc,
  handleChangeUpload,
  handlePassportBackVerification,
  handlePassportVerification,
  isPDFfile,
  docDob,
  setPassportDetails,
  handlePassportDobChangeHandler,
  buttonLoader,
  loading,
}) => {
  const convertedDate = moment(docDob, "DD/MM/YYYY").toDate();
  const maxDate = moment(new Date()).format("YYYY-MM-DD").toString();
  const mixpanelToken: any = process.env.REACT_APP_MIXPANEL_TOKEN;
  mixpanel.init(mixpanelToken, {
    debug: true,
    api_host: process.env.REACT_APP_MIXPANEL_API,
  });
  const specialCharRegExp = "[a-zA-Z0-9 ]{1,}";

  return (
    <div className="col-span-4 lg:pl-10 lg:pt-7">
      <div>
        <div className="flex items-start justify-between">
          <div className="mb-5 text-base font-medium text-black font-lato">
            Upload Passport<span className="text-error">*</span>
          </div>
          {(passportDetails?.uploadedFrontDocError === "Please upload the right file format.." ||
            passportDetails?.uploadedBackDocError) && (
              <div className="relative cursor-pointer">
                <div className="peer">
                  <InfoIcon fillColor="fill-secondary" />
                </div>
                <Tooltip
                  title="Your file cant upload"
                  subtitle="Try file formats like jpg, png or pdf"
                  positionOfTip="bottom"
                  tooltipStyles="peer-hover:block hidden !top-8 !min-h-[96px] !min-w-[236px] !-translate-x-full z-1"
                  titleClasses="font-semibold text-lg text-white !pt-3"
                  subtitleClasses="text-sm font-normal text-white"
                  buttonClasses="!w-0 h-0"
                />
              </div>
            )}
        </div>
        <div className="relative">
          <div className="flex items-center space-x-8">
            <Button
              id="2sn9nkj87-321asd3ds"
              bgColor="bg-primary-350"
              fontStyle={{
                fontFamily: "",
                fontSize: "text-sm",
                fontWeight: "font-normal ",
                fontColor: "text-black-200",
              }}
              borderStyle={{
                borderColor: passportDetails.uploadedFrontDocError
                  ? "border-error border"
                  : passportDetails.isPassportDocUploaded || passportDetails.isPassportDocVerified
                    ? "border-success border"
                    : "border-none",
                borderRadius: "rounded",
              }}
              className={clsx(
                passportDetails.messagePassport || passportDetails.uploadedFrontDocError
                  ? ""
                  : "mb-5",
                "w-full lg:!w-[48%] h-[68px]",
              )}
            >
              <div className="flex items-center justify-between w-full px-4 lg:px-6">
                <div className="flex items-center space-x-2 lg:space-x-6">
                  <PassportIcon
                    width="23px"
                    height="29px"
                    fillColor={
                      passportDetails.isPassportDocVerified ? "fill-success-150" : "fill-primary"
                    }
                  />
                  <div>Upload passport Front</div>
                </div>
                <div className="flex items-center space-x-4">
                  {passportDetails.isPassportDocUploaded &&
                    !passportDetails.isPassportDocVerified &&
                    !passportDetails.isPassportBackUploaded &&
                    !isPDFfile && (
                      <div
                        className="z-50 cursor-pointer"
                        onClick={() => {
                          handlePreviewUploadedDoc("INDIAN_PASSPORT_FRONT");
                          mixpanel.track("Upload Passport Clicked");
                        }}
                      >
                        <VisibilityOnIcon fillColor="fill-black-150" />
                      </div>
                    )}
                  {passportDetails.isPassportDocUploaded &&
                    !passportDetails.isPassportDocVerified && (
                      <div className="z-50 cursor-pointer" onClick={handleDeleteDoc}>
                        <ReUploadIcon
                          fillColor={
                            passportDetails?.uploadedFrontDocError ? "fill-error" : "fill-black"
                          }
                        />
                      </div>
                    )}
                  {!passportDetails.isPassportDocUploaded && (
                    <UploadIcon
                      fillColor={
                        passportDetails.isPassportDocVerified ? "fill-success-150" : "fill-primary"
                      }
                    />
                  )}
                </div>
              </div>
            </Button>
          </div>
          {(passportDetails.uploadedFrontDocError !== "" ||
            passportDetails.messagePassport !== "") && (
              <ErrorField
                errorMessage={
                  passportDetails.isPassportDocUploaded
                    ? passportDetails.messagePassport
                    : passportDetails.uploadedFrontDocError
                }
                errorClassname="mb-5"
              />
            )}
          <form encType="multipart/form-data" action="">
            <TextField
              accept="image/*,application/pdf"
              name="passportFront"
              type="file"
              disabled={passportDetails.isPassportDocUploaded}
              rootClassName="absolute top-0 opacity-0 !w-full lg:!w-[48%]"
              onChange={(e) => handleChangeUpload(e, "PASSPORT_FRONT")}
              onClick={(event) => {
                event.currentTarget.value = "";
              }}
            />
          </form>
        </div>
        <div className="relative">
          <div className="flex items-center space-x-8">
            <Button
              id="dkjlsa2ek982ds"
              bgColor="bg-primary-350"
              fontStyle={{
                fontFamily: "",
                fontSize: "text-sm",
                fontWeight: "font-normal ",
                fontColor: "text-black-200",
              }}
              borderStyle={{
                borderColor: passportDetails.uploadedBackDocError
                  ? "border-error border"
                  : passportDetails.isPassportBackUploaded ||
                    passportDetails.isPassportBackDocVerified
                    ? "border-success border"
                    : "border-none",
                borderRadius: "rounded",
              }}
              className={clsx(
                passportDetails.messageBackPassport || passportDetails.uploadedBackDocError
                  ? ""
                  : "mb-5",
                "w-full h-[68px] lg:!w-[48%]",
              )}
            >
              <div className="flex items-center justify-between w-full px-4 lg:px-6">
                <div className="flex items-center space-x-2 lg:space-x-6">
                  <PassportIcon
                    width="23px"
                    height="29px"
                    fillColor={
                      passportDetails.isPassportBackDocVerified
                        ? "fill-success-150"
                        : "fill-primary"
                    }
                  />
                  <div>Upload passport Back</div>
                </div>
                <div className="flex items-center space-x-4">
                  {passportDetails.isPassportBackUploaded &&
                    !passportDetails.isPassportBackDocVerified &&
                    !isPDFfile && (
                      <div
                        className="z-50 cursor-pointer"
                        onClick={() => {
                          handlePreviewUploadedDoc("INDIAN_PASSPORT_BACk");
                          mixpanel.track("Passport uploaded_back");
                        }}
                      >
                        <VisibilityOnIcon fillColor="fill-black-150" />
                      </div>
                    )}
                  {passportDetails.isPassportBackUploaded &&
                    !passportDetails.isPassportBackDocVerified && (
                      <div className="z-50 cursor-pointer" onClick={handleDeleteDoc}>
                        <ReUploadIcon
                          fillColor={
                            passportDetails?.uploadedBackDocError ? "fill-error" : "fill-black"
                          }
                        />
                      </div>
                    )}
                  {!passportDetails.isPassportBackUploaded && (
                    <UploadIcon
                      fillColor={
                        passportDetails.isPassportBackDocVerified
                          ? "fill-success-150"
                          : "fill-primary"
                      }
                    />
                  )}
                </div>
              </div>
            </Button>
          </div>
          {(passportDetails.uploadedBackDocError !== "" ||
            passportDetails.messageBackPassport !== "") && (
              <ErrorField
                errorMessage={
                  passportDetails.isPassportBackUploaded
                    ? passportDetails.messageBackPassport
                    : passportDetails.uploadedBackDocError
                }
                errorClassname="mb-5"
              />
            )}
          <form encType="multipart/form-data" action="">
            <TextField
              accept="image/*,application/pdf"
              name="passportBack"
              type="file"
              rootClassName="absolute top-0 opacity-0 lg:!w-[48%] !w-full"
              disabled={
                passportDetails.isPassportBackUploaded || !passportDetails.isPassportDocVerified
              }
              onChange={(e) => handleChangeUpload(e, "PASSPORT_BACK")}
              onClick={(event) => {
                event.currentTarget.value = "";
              }}
            />
          </form>
        </div>
        <div className="mb-5 text-base font-medium text-black font-lato">
          Passport No<span className="text-error">*</span>
        </div>
        <TextField
          name="passportIdNumber"
          type="text"
          placeholderStyle="!text-primary-850"
          inputClasses={clsx(
            passportDetails?.isPassportDocVerified && passportDetails?.isPassportBackDocVerified
              ? "border-success-150"
              : "border-grey-150",
            `border !pt-1 lg:!w-[48%]`,
          )}
          value={passportDetails.passportIdNumber}
          onChange={(event) =>
            setPassportDetails((preValue: any) => {
              return {
                ...preValue,
                passportIdNumber: event.target.value,
              };
            })
          }
          disabled={!passportDetails?.isPassportDocUploaded}
          fullWidth
          pattern={specialCharRegExp}
        />
        {passportDetails.isPassportDocVerified && !passportDetails.isPassportBackDocVerified && (
          <div className="flex flex-col items-start w-full space-x-8 lg:items-start lg:flex-row">
            <div className="w-full">
              <div className="mb-5 text-base font-medium text-black font-lato">
                Passport file no.<span className="text-error">*</span>
              </div>
              <TextField
                type="text"
                fullWidth
                name="passportFileNumber"
                placeholderStyle="!text-primary-850"
                inputClasses={clsx(
                  passportDetails?.isPassportBackDocVerified
                    ? "border-success-150"
                    : "border-grey-150",
                  `border !pt-1 lg:!w-full`,
                )}
                onChange={(event) =>
                  setPassportDetails((preValue: any) => {
                    return {
                      ...preValue,
                      passportBackIdNumber: event.target.value,
                    };
                  })
                }
                pattern={specialCharRegExp}
              />
            </div>
            <div className="w-full !ml-0 md:!ml-8">
              <div className="mb-5 text-base font-medium text-black font-lato">
                DOB as per passport
              </div>
              <DatePicker
                selectedDate={
                  docDob === "" ? new Date().toString() : moment(convertedDate).format("YYYY-MM-DD")
                }
                dateChangeHandler={handlePassportDobChangeHandler}
                datepickerClassname="bg-transparent !p-4 !rounded border !border-grey-150 w-[400px] ml-0 lg:w-[300px]"
                disabled={
                  !passportDetails?.isPassportBackUploaded ||
                  passportDetails?.isPassportBackDocVerified
                }
                maxDate={maxDate}
              />
            </div>
          </div>
        )}
        {!passportDetails.isPassportDocVerified ? (
          <Button
            id="dsnaji9=-dksa8"
            bgColor={passportDetails?.isPassportDocUploaded ? "bg-primary" : "bg-grey-350"}
            fontStyle={{
              fontFamily: "",
              fontSize: "text-base",
              fontWeight: "font-bold",
              fontColor: "text-white",
            }}
            borderStyle={{
              borderWidth: "border",
              borderColor: passportDetails?.isPassportDocUploaded
                ? "border-primary"
                : "border-grey-350",
              borderRadius: "rounded",
            }}
            className={"py-5 w-full mt-9 lg:!w-[48%]"}
            disabled={!passportDetails?.isPassportDocUploaded}
            onClickHandler={handlePassportVerification}
            isLoading={buttonLoader.passportBackLoader || buttonLoader.passportFrontLoader}
          >
            {loading ? (
              <div className="flex items-center justify-center animate-spin-slow py-1">
                <img src="/images/svg/smallLoader.svg" alt="" />
              </div>
            ) : (
              "Verify Passport Front"
            )}
          </Button>
        ) : (
          !passportDetails.isPassportBackDocVerified &&
          passportDetails.isPassportDocVerified && (
            <Button
              id="dsnaji9=-dksa8-dajsaj18cxn9i"
              bgColor={
                passportDetails?.isPassportBackUploaded &&
                  passportDetails.passportBackIdNumber !== ""
                  ? "bg-primary"
                  : "bg-grey-350"
              }
              fontStyle={{
                fontFamily: "",
                fontSize: "text-base",
                fontWeight: "font-bold",
                fontColor: "text-white",
              }}
              borderStyle={{
                borderWidth: "border",
                borderColor:
                  passportDetails?.isPassportBackUploaded &&
                    passportDetails.passportBackIdNumber !== ""
                    ? "border-primary"
                    : "border-grey-350",
                borderRadius: "rounded",
              }}
              className={"py-5 w-full mt-9 lg:!w-[48%]"}
              disabled={
                !passportDetails?.isPassportBackUploaded ||
                passportDetails.passportBackIdNumber === ""
              }
              onClickHandler={handlePassportBackVerification}
              isLoading={buttonLoader.passportBackLoader || buttonLoader.passportFrontLoader}
            >
              {loading ? (
                <div className="flex items-center justify-center animate-spin-slow py-1">
                  <img src="/images/svg/smallLoader.svg" alt="" />
                </div>
              ) : (
                "Verify Passport Back"
              )}
            </Button>
          )
        )}
      </div>
    </div>
  );
};

export default IndianPassportSection;
