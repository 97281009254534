/* eslint-disable */
//Global Imports
import axios from "axios";
import { useNavigate } from "react-router-dom";
import { encryptData } from "../utils";
//End Global Imports
import { decryptData } from "../utils";

const baseAPIUrl = process.env.REACT_APP_SERVER_ID;

// const naviagte = useNavigate();

let validationKey: any = null;
let validationArray: any[] = [];

export const Axios = axios.create({
  baseURL: baseAPIUrl,
  headers: {
    "Access-Control-Allow-Origin": "true",
    "Content-Type": "application/json",
    accept: "*/*",
    "X-Route-To": "remittance"
  },
  withCredentials: true,
});

Axios.interceptors.request.use(
  function (request:any) {
    // window.console.log("payload", request.data);
    validationKey = window.crypto.getRandomValues(new Uint32Array(1))[0].toString();
    validationArray.push(validationKey);
    if (request?.data && !(request?.data instanceof FormData)) {
      let validatedRequest = { ...request?.data, validationKey: validationKey };
      let newValidatedRequest = { data: request?.data, validationKey: validationKey };
      const requestData = JSON.stringify(newValidatedRequest);
      const encryptedRequest = encryptData(requestData);
      const requestObj = JSON.stringify({ data: encryptedRequest });
      request.data = requestObj;
      return request;
    } else {
      request.headers = { ...request?.headers, validationKey: validationKey };
      return request;
    }
  },
  function (error) {
    window.console.error("request interceptor error", error);
  },
);

Axios.interceptors.response.use(
  function (response:any) {
const isMatched = response?.config?.url?.includes('/recipient/docId/');
    if (response.config.responseType === "blob"||isMatched) {
      return response;
    } else {
      // Handle other response types
      const decryptResponse = decryptData(response.data.body);
      response["data"] = decryptResponse?.data;
      if (validationArray.includes(decryptResponse?.validationKey)) {
        const index = validationArray.indexOf(decryptResponse?.validationKey);
        let newArray: any[] = [];
        if (index > -1) {
          newArray = [...validationArray.slice(0, index), ...validationArray.slice(index + 1)];
        }
        validationArray = newArray;
        const modifiedResponse = { ...response, success: response?.data?.success };
        // window.console.log("Resposne", modifiedResponse);
        return modifiedResponse;
      } else {
        alert("Not Authorized");
        localStorage.clear();
        sessionStorage.clear();
        window.location.href = "/auth/sign-in";
        // return response;
      }
    }
  },
  function (error) {
    // if (error?.response?.data?.body) {
    //   window.console.log('entered');
    // }
    if (
      error?.response?.headers["content-type"] === "application/octet-stream" ||
      error?.config.url ==
        "https://dev-assisted-api.moneyhop.co/remit/user/4165/kycdocsandlogs/export/pdf"
    ) {
      return Promise.reject(error);
    } else {
      window.console.error(error);
      window.console.error(error?.response);
      const decryptResponse = decryptData(error?.response?.data?.body);
      window.console.log(decryptResponse);
      error.response = decryptResponse;
      let modifiedResponse = { ...error, message: error?.message };
      window.console.error(modifiedResponse);
      return Promise.reject(modifiedResponse);
    }
  },
);
