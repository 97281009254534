import { FunctionComponent, useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { CloseIcon, DeleteBin, LeftArrowIcon } from "../../assets/Icons";
import Accordion from "../../components/ui/Accordion";
import Button from "../../components/ui/Button";
import Checkbox from "../../components/ui/Checkbox";
import Modal from "../../components/ui/Modal";
import { getUserRecipients } from "../../services/userDetails";
import { TransactioncancellationReasons } from "../../utils";
import { motion } from "framer-motion";
import { deleteRecipient } from "../../services/recipientDetailsPage";
import mixpanel from "mixpanel-browser";
import { handleTransactionComplete } from "../../services/transaction";
import { transactionDetails } from "../../store";
import { useResetRecoilState } from "recoil";

export const RecipientProfile: FunctionComponent<any> = () => {
  const recipientId = sessionStorage.getItem("recipient_id");
  const currentUser = JSON.parse(sessionStorage.getItem("currentUser") || "{}");
  const [thisRecipient, setThisRecipient] = useState<any>();
  const navigate = useNavigate();
  const [isModalOpen, setIsModalOpen] = useState<string>("");
  const [cancellationReasons, setCancellationReasons] = useState<string>("");
  const [otherReason, setOtherReason] = useState<string>("");
  const resetTransactionDetails = useResetRecoilState(transactionDetails);
  const mixpanelToken: any = process.env.REACT_APP_MIXPANEL_TOKEN;
  mixpanel.init(mixpanelToken, {
    debug: true,
    api_host:process.env.REACT_APP_MIXPANEL_API,
  });

  useEffect(() => {
    getUserRecipients(currentUser).then((response) => {
      const currentRecipient = response?.data?.recipient?.filter((data: any) => {
        return data.id == recipientId;
      });
      setThisRecipient(currentRecipient[0]);
    });
  }, []);

  const handleDeleteClicked = () => {
    deleteRecipient(thisRecipient?.id)
      .then(() => {
        navigate("/pick-recipient");
      })
      .catch((error) => {
        window.alert(error);
      });
  };

  const handleNavigate = () => {
    handleTransactionComplete();
    resetTransactionDetails();
    navigate("/");
  };

  return (
    <motion.div
      initial={{ width: 0 }}
      animate={{ width: "100%" }}
      exit={{ x: window.innerWidth, transition: { duration: 0.5 } }}
    >
      <div className="flex justify-between items-center bg-white md:hidden w-full py-[25px] pr-[22px] pl-7 mb-6">
        <div className="flex items-center w-fit" onClick={() => history.back()}>
          <LeftArrowIcon />
          <div className="ml-6 text-xl font-semibold text-grey-1100">Recipient Profile</div>
        </div>
        <div className="flex items-center">
          <div
            className="mr-3 text-sm font-normal text-primary"
            onClick={() => navigate("/support")}
          >
            Need help ?
          </div>
          <div
            className="bg-gray-400 w-[30px] h-[30px] flex justify-center items-center rounded-full"
            onClick={() => setIsModalOpen("cancelReasons")}
          >
            <CloseIcon />
          </div>
        </div>
      </div>
      <div className="lg:p-10">
        <div id="topBar flex flex-col p-1 items-center justify-center">
          <div
            id="headerBanner"
            className="w-full hidden lg:flex bg-gradient-to-r from-sky-600 via-sky-400 to-sky-200 h-[150px] rounded-md shadow-lg shadow-sky-400 drop-shadow-xl drop-shadow-green-200 relative z-0"
          ></div>
          <div
            id="circle"
            className="cursor-pointer lg:w-[180px] lg:h-[180px] h-[90px] w-[90px] lg:border-8 border-4 border-rounded-full font-bold border-bg-blue-100 hidden lg:flex justify-center items-center absolute z-50 rounded-full lg:-mt-20 ml-10 lg:ml-20 bg-sky-500 mb-10 text-white font-ssp text-[20px] lg:text-[60px] hover:text-[80px] transition-all"
          >
            {thisRecipient?.firstName?.charAt(0) + thisRecipient?.lastName?.charAt(0)}
          </div>
          <div className="lg:ml-[260px] lg:mt-2 w-[calc(100% - 100px)] h-[100px] flex flex-col lg:flex-row items-center lg:px-8 lg:space-x-16">
            <div className="flex items-center space-x-4 lg:space-x-10">
              <div className="text-[20px] lg:text-[36px] font-semibold">
                {thisRecipient?.firstName + " " + thisRecipient?.lastName}
              </div>
              <div className="items-center hidden space-x-3 lg:flex">
                <span className="font-bold text-[28px] text-gray-500">
                  {thisRecipient?.countryOfResidence ? thisRecipient?.countryOfResidence : ""}
                </span>
              </div>
            </div>
            <div className="flex flex-col items-center justify-center lg:flex-row lg:space-x-10">
              {thisRecipient?.bankAccountNumber && (
                <div className="flex items-center space-x-3">
                  <span className="font-semibold text-[12px] lg:text-[28px] text-gray-500">
                    A/C Number:
                  </span>
                  <span className="lg:text-[24px] font-semibold">
                    {thisRecipient?.bankAccountNumber}
                  </span>
                </div>
              )}
              <div
                // onClick={handleDeleteClicked}
                onClick={() => {
                  setIsModalOpen("deleteRecipient");
                  mixpanel.track("Delete Recipient Clicked");
                }}
                className="flex items-center justify-center float-right w-16 h-16 transition-all scale-50 bg-red-600 border-4 border-red-500 rounded-full shadow-xl cursor-pointer lg:scale-100 hover:scale-105 hover:bg-red-500"
              >
                <DeleteBin />
              </div>
            </div>
          </div>
        </div>
      </div>
      <div id="accordions" className="flex flex-col justify-center w-full p-10 mt-8 space-y-4">
        <Accordion
          title={"Recipient Details"}
          titleClassname="font-semibold w-auto text-[16px] lg:text-[20px] py-2"
        >
          <div className="flex flex-col items-center w-full space-y-5 lg:space-y-0 lg:flex-row lg:space-x-20">
            <div id="left" className="w-full space-y-3 lg:px-20 lg:w-1/2">
              {thisRecipient?.firstName && (
                <div className="flex items-center w-full space-x-5">
                  <span className="font-bold text-[12px] lg:text-[20px]">Recipient Name: </span>
                  <span className="font-ssp text-[12px] lg:text-[20px] lg:px-10 lg:py-2 lg:border lg:border-gray-300 rounded">
                    {thisRecipient?.firstName + " " + thisRecipient?.lastName}
                  </span>
                </div>
              )}
              {/* {thisRecipient?.firstName && (
                <div className="flex items-center w-full space-x-5">
                  <span className="font-bold w-1/2 text-[12px] lg:text-[20px]">
                    Recipient Name:{" "}
                  </span>
                  <span className="font-ssp text-[12px] w-1/2 lg:text-[20px] lg:px-10 lg:py-2 lg:border lg:border-gray-300 rounded">
                    {thisRecipient?.firstName + " " + thisRecipient?.lastName}
                  </span>
                </div>
              )} */}
            </div>
            <div id="right" className="w-full space-y-3 lg:px-20 lg:w-1/2">
              {thisRecipient?.relationship && (
                <div className="flex items-center w-full space-x-5">
                  <span className="font-bold text-[12px] lg:text-[20px]">Relationship: </span>
                  <span className="font-ssp text-[12px] lg:text-[20px] lg:px-10 lg:py-2 lg:border lg:border-gray-300 rounded">
                    {thisRecipient?.relationship}
                  </span>
                </div>
              )}
            </div>
          </div>
        </Accordion>
        <Accordion
          title={"Address"}
          titleClassname="font-semibold text-[16px] lg:text-[20px] py-2 "
        >
          <div className="flex flex-col items-center w-full space-y-3 lg:space-y-0 lg:flex-row lg:space-x-20">
            <div
              id="left"
              className="flex flex-col items-center w-full space-y-3 lg:px-20 lg:w-1/2"
            >
              {thisRecipient?.category && (
                <div className="flex items-center w-full space-x-5">
                  <span className="font-bold text-[12px] lg:text-[20px]">Recipient Category: </span>
                  <span className="font-ssp text-[12px] lg:text-[20px] lg:px-10 lg:py-2 lg:border lg:border-gray-300 rounded">
                    {thisRecipient?.category}
                  </span>
                </div>
              )}
              {thisRecipient?.city && (
                <div className="flex items-center w-full space-x-5">
                  <div className="font-bold text-[12px] flex justify-end lg:text-[20px]">
                    City:{" "}
                  </div>
                  <div className="font-ssp text-[12px] lg:text-[20px] lg:px-10 lg:py-2 lg:border lg:border-gray-300 rounded">
                    {thisRecipient?.city}
                  </div>
                </div>
              )}
              {thisRecipient?.recipientAddress && (
                <div className="flex items-center w-full space-x-5">
                  <span className="font-bold text-[12px] lg:text-[20px]">Recipient Address: </span>
                  <span className="font-ssp text-[12px] lg:text-[20px] lg:px-10 lg:py-2 lg:border lg:border-gray-300 rounded">
                    {thisRecipient?.recipientAddress}
                  </span>
                </div>
              )}
            </div>
            <div id="right" className="w-full space-y-3 lg:px-20 lg:w-1/2">
              {thisRecipient?.countryOfResidence && (
                <div className="flex items-center w-full space-x-5">
                  <span className="font-bold text-[12px] lg:text-[20px]">
                    Country of Residence:{" "}
                  </span>
                  <span className="font-ssp text-[12px] lg:text-[20px] lg:px-10 lg:py-2 lg:border lg:border-gray-300 rounded">
                    {thisRecipient?.countryOfResidence}
                  </span>
                </div>
              )}
              {thisRecipient?.pincode && (
                <div className="flex items-center space-x-5">
                  <span className="font-bold text-[12px] lg:text-[20px]">PINCODE: </span>
                  <span className="font-ssp text-[12px] lg:text-[20px] lg:px-10 lg:py-2 lg:border lg:border-gray-300 rounded">
                    {thisRecipient?.pincode}
                  </span>
                </div>
              )}
            </div>
          </div>
        </Accordion>
        <Accordion
          title={"Bank Details"}
          titleClassname="font-semibold text-[16px] lg:text-[20px] py-2 "
        >
          <div className="flex flex-col items-center w-full space-y-3 lg:space-y-0 lg:flex-row lg:space-x-20">
            <div id="left" className="w-full space-y-3 lg:px-20 lg:w-1/2">
              {thisRecipient?.bankAccountCountry && (
                <div className="flex items-center w-full space-x-5">
                  <span className="font-bold text-[12px] lg:text-[20px]">
                    Bank Account Country:
                  </span>
                  <span className="font-ssp text-[12px] lg:text-[20px] lg:px-10 lg:py-2 lg:border lg:border-gray-300 rounded">
                    {thisRecipient?.bankAccountCountry}
                  </span>
                </div>
              )}
              {thisRecipient?.bankAccountNumber && (
                <div className="flex items-center w-full space-x-5">
                  <div className="font-bold text-[12px] lg:text-[20px]">Bank Account Number: </div>
                  <div className="font-ssp text-[12px] lg:text-[20px] lg:px-10 lg:py-2 lg:border lg:border-gray-300 rounded">
                    {thisRecipient?.bankAccountNumber}
                  </div>
                </div>
              )}
              {thisRecipient?.ibanCode && (
                <div className="flex items-center w-full space-x-5">
                  <span className="font-bold text-[12px] lg:text-[20px]">IBAN Code: </span>
                  <span className="font-ssp text-[12px] lg:text-[20px] lg:px-10 lg:py-2 lg:border lg:border-gray-300 rounded">
                    {thisRecipient?.ibanCode}
                  </span>
                </div>
              )}
              {(thisRecipient?.sortCode ||
                thisRecipient?.bsbCode ||
                thisRecipient?.transitCode) && (
                <div className="flex items-center w-full space-x-5">
                  <span className="font-bold text-[12px] lg:text-[20px]">
                    Sort/BSB/Transit/Routing:
                  </span>
                  <span className="font-ssp text-[12px] lg:text-[20px] lg:px-10 lg:py-2 lg:border lg:border-gray-300 rounded">
                    {thisRecipient?.sortCode ||
                      thisRecipient?.bsbCode ||
                      thisRecipient?.transitCode}
                  </span>
                </div>
              )}
            </div>

            <div id="right" className="w-full space-y-3 lg:px-20 lg:w-1/2">
              {thisRecipient?.bankName && (
                <div className="flex items-center w-full space-x-5">
                  <span className="font-bold text-[12px] lg:text-[20px]">Bank Name: </span>
                  <span className="font-ssp text-[12px] lg:text-[20px] lg:px-10 lg:py-2 lg:border lg:border-gray-300 rounded">
                    {thisRecipient?.bankName}
                  </span>
                </div>
              )}
              {thisRecipient?.swiftCode && (
                <div className="flex items-center space-x-5">
                  <span className="font-bold text-[12px] lg:text-[20px]">Swift Code: </span>
                  <span className="font-ssp text-[12px] lg:text-[20px] lg:px-10 lg:py-2 lg:border lg:border-gray-300 rounded">
                    {thisRecipient?.swiftCode}
                  </span>
                </div>
              )}
              {thisRecipient?.unitNumber && (
                <div className="flex items-center space-x-5">
                  <span className="font-bold text-[12px] lg:text-[20px]">Unit Number: </span>
                  <span className="font-ssp text-[12px] lg:text-[20px] lg:px-10 lg:py-2 lg:border lg:border-gray-300 rounded">
                    {thisRecipient?.unitNumber}
                  </span>
                </div>
              )}
              {thisRecipient?.refrence && (
                <div className="flex items-center space-x-5">
                  <span className="font-bold text-[12px] lg:text-[20px]">
                    Reference ID/ Field 70:{" "}
                  </span>
                  <span className="font-ssp text-[12px] lg:text-[20px] lg:px-10 lg:py-2 lg:border lg:border-gray-300 rounded">
                    {thisRecipient?.refrence}
                  </span>
                </div>
              )}
            </div>
          </div>
        </Accordion>
      </div>
      <div className="p-10 mt-10 group" onClick={() => history.back()}>
        <span className="px-10 group-hover:text-[12px] py-4 font-bold text-[14px] rounded-lg border-2 border-blue-200 hover:bg-blue-200 transition-all cursor-pointer bg-blue-300">
          Go Back
        </span>
      </div>
      <Modal
        header="Reason for cancellation"
        headerClass={"bg-white !my-0 py-6 px-12 font-bold text-lg"}
        modalClass="!p-0 !rounded !overflow-hidden !w-full lg:!w-auto"
        visible={isModalOpen === "cancelReasons"}
        closeHandler={() => setIsModalOpen("")}
        closable={false}
      >
        <div className="px-5 pb-5">
          {TransactioncancellationReasons.map((data, index) => {
            return (
              <div className="space-x-2 px-7 border-b-[1px] py-6 first:pt-0" key={index}>
                <Checkbox
                  label={data}
                  value={data}
                  name={"cancellationReason"}
                  handleChange={(e) => {
                    setCancellationReasons(e.target.value);
                  }}
                />
              </div>
            );
          })}
          {cancellationReasons === "Other Reason..." && (
            <div className="flex items-center justify-center mt-2">
              <textarea
                name="otherReasonDescription"
                value={otherReason}
                rows={4}
                cols={50}
                placeholder="Write your reason here....."
                onChange={(e) => {
                  setOtherReason(e.target.value);
                }}
                className="w-full p-3 bg-white-100 focus-visible:border-0 placeholder:text-center"
              />
            </div>
          )}
          <div className="flex items-center justify-center mt-7 px-[78px]">
            <Button
              id="cancellationReasons-receipient-profile"
              bgColor={`${cancellationReasons === "" ? "bg-grey-350" : "bg-primary"}`}
              fontStyle={{
                fontFamily: "",
                fontSize: "text-lg",
                fontWeight: "font-bold",
                fontColor: "text-white",
              }}
              borderStyle={{
                borderWidth: "border-none",
                borderRadius: "rounded-[100px]",
              }}
              disabled={cancellationReasons === ""}
              className={"py-3 px-28"}
              onClickHandler={() =>
                cancellationReasons !== "" && cancellationReasons === "Other Reason..."
                  ? otherReason !== "" && handleNavigate()
                  : handleNavigate()
              }
            >
              Submit
            </Button>
          </div>
        </div>
      </Modal>
      <Modal
        header="Are you sure you want to delete this recipient?"
        headerClass={"bg-white !my-0 py-6 px-12 font-bold text-lg"}
        modalClass="!p-0 !rounded !overflow-hidden !w-full lg:!w-auto mb-40 md:mb-0"
        visible={isModalOpen === "deleteRecipient"}
        closeHandler={() => setIsModalOpen("")}
        closable={false}
      >
        <div className="flex items-center justify-center mb-10 space-x-10">
          <div
            onClick={handleDeleteClicked}
            className="flex justify-center cursor-pointer px-8 py-2 w-[100px] transition-all font-bold text-white bg-primary hover:bg-primary-100 hover:text-primary hover:border-2 hover:border-primary"
          >
            Yes
          </div>
          <div
            onClick={() => setIsModalOpen("")}
            className="flex justify-center cursor-pointer px-8 py-2 w-[100px] transition-all font-bold text-white bg-primary hover:bg-primary-100 hover:text-primary hover:border-2 hover:border-primary"
          >
            No
          </div>
        </div>
      </Modal>
    </motion.div>
  );
};
