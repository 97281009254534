import React from "react";
import { IIcons } from "./icons";

const Dartboard = ({ fillColor }: IIcons.IconProps) => {
  return (
    <svg
      width="27"
      height="27"
      viewBox="0 0 27 27"
      fill="none"
      className={fillColor}
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M13.3571 2.33621e-05C12.7675 -0.00107824 12.1706 0.0367734 11.5654 0.114607C5.46944 0.898607 0.603019 5.98286 0.055019 12.1042C-0.718314 20.7535 6.77177 27.8855 15.5238 26.4922C21.0318 25.6149 25.4947 21.2095 26.4561 15.7162C26.8734 13.3322 26.6499 11.0431 25.9352 8.98179C25.6152 8.05913 24.4566 7.76902 23.766 8.45836L23.6956 8.52867C23.3463 8.878 23.2544 9.39407 23.4144 9.8594C24.0344 11.6527 24.1922 13.6594 23.7295 15.7474C22.8255 19.8181 19.4805 23.0473 15.3805 23.8099C7.87254 25.2073 1.42352 18.7308 2.86752 11.2162C3.66352 7.0735 6.98025 3.72398 11.1149 2.89065C14.4259 2.22288 17.523 3.10347 19.8571 4.9219L17.9509 6.82815C16.3712 5.69979 14.3753 5.11997 12.2269 5.40888C8.75089 5.87554 5.90773 8.68815 5.41439 12.1615C4.65439 17.5161 9.20283 22.0422 14.5628 21.2422C17.3895 20.8195 19.7811 18.797 20.8024 16.1276C21.0344 15.5223 21.1821 14.929 21.2607 14.349C21.4314 13.0916 19.8716 12.354 18.9743 13.25C18.7583 13.4674 18.6354 13.7585 18.5941 14.0625C18.1834 17.0665 15.2927 19.2783 12.0394 18.5156C10.1341 18.069 8.59016 16.5213 8.14616 14.6146C7.33283 11.1133 9.96839 8.00002 13.3311 8.00002C14.3206 8.00002 15.2414 8.27545 16.0316 8.74742L14.0186 10.7604C13.7945 10.6994 13.5633 10.6679 13.3311 10.6667C12.6238 10.6667 11.9455 10.9476 11.4454 11.4477C10.9453 11.9478 10.6644 12.6261 10.6644 13.3334C10.6644 14.0406 10.9453 14.7189 11.4454 15.219C11.9455 15.7191 12.6238 16 13.3311 16C14.0383 16 14.7166 15.7191 15.2167 15.219C15.7168 14.7189 15.9977 14.0406 15.9977 13.3334C15.9976 13.1003 15.967 12.8683 15.9066 12.6433L25.7217 2.82815C26.243 2.30815 26.243 1.46407 25.7217 0.942732C25.2017 0.421398 24.3576 0.421398 23.8363 0.942732L21.7529 3.02606C19.4609 1.15102 16.5476 0.00598456 13.3571 2.33621e-05Z"
        fill="#5B5B5B"
      />
    </svg>
  );
};

export default Dartboard;
