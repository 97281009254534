import React from "react";
import { IIcons } from "./icons";

const ReUploadIcon = ({ fillColor }: IIcons.IconProps) => (
  <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      d="M12 0C5.38453 0 0 5.38453 0 12C0 18.6155 5.38453 24 12 24C18.6155 24 24 18.6155 24 12C24.0019 11.8675 23.9774 11.7359 23.928 11.613C23.8786 11.49 23.8052 11.3781 23.7122 11.2837C23.6191 11.1893 23.5082 11.1144 23.386 11.0632C23.2637 11.0121 23.1325 10.9858 23 10.9858C22.8675 10.9858 22.7363 11.0121 22.614 11.0632C22.4918 11.1144 22.3809 11.1893 22.2878 11.2837C22.1948 11.3781 22.1214 11.49 22.072 11.613C22.0226 11.7359 21.9981 11.8675 22 12C22 17.5345 17.5345 22 12 22C6.46547 22 2 17.5345 2 12C2 6.46547 6.46547 2 12 2C14.512 2 16.7905 2.93102 18.5449 4.45508L16 7H22V1L19.9609 3.03906C17.8405 1.15537 15.0539 0 12 0Z"
      fill="#060606"
      className={fillColor}
    />
  </svg>
);
export default ReUploadIcon;
