import React from 'react';
import { Swiper, SwiperSlide } from 'swiper/react';

// Import Swiper styles
import 'swiper/css';
import 'swiper/css/effect-flip';
import 'swiper/css/pagination';
import 'swiper/css/navigation';

import './styles.css';

// import required modules
import { EffectFlip, Pagination, Autoplay } from 'swiper/modules';

const CustomSwiper = () => {

  return (
    <Swiper
        effect={'flip'}
        grabCursor={true}
        pagination={true}
        navigation={false}
        autoplay={{
            delay: 3500,
            disableOnInteraction: false,
        }}
        modules={[EffectFlip, Pagination,Autoplay]}
        className="mySwiper"
    >
        <SwiperSlide>
            <img
            src="https://remit-test.moneyhop.co/images/svg/signUpBanner1.svg"
            alt=""
            className="object-cover w-1/3"
            />
        </SwiperSlide>
        <SwiperSlide>
            <img
            src="https://remit-test.moneyhop.co/images/svg/signUpBanner2.svg"
            alt=""
            className="object-cover w-1/3"
            />
        </SwiperSlide>
        <SwiperSlide>
            <img
            src="https://remit-test.moneyhop.co/images/svg/signUpBanner3.svg"
            alt=""
            className="object-cover w-1/3"
            />
        </SwiperSlide>
        <SwiperSlide>
            <img
            src="https://remit-test.moneyhop.co/images/svg/signUpBanner4.svg"
            alt=""
            className="object-cover w-1/3"
            />
        </SwiperSlide>
    </Swiper>
  );
};

export default CustomSwiper;
