import React from "react";
import { IIcons } from "./icons";

const One = ({ fillColor }: IIcons.IconProps) => (
  <svg
    width="87"
    height="87"
    viewBox="0 0 87 87"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    className={fillColor}
  >
    <path
      d="M64.6198 64.5951C76.4288 52.703 76.4288 33.422 64.6198 21.5299C52.8108 9.63785 33.6647 9.63785 21.8557 21.5299C10.0467 33.422 10.0467 52.703 21.8557 64.5951C33.6647 76.4872 52.8108 76.4872 64.6198 64.5951Z"
      fill="#5BA6FF"
    />
    <path
      d="M59.0567 17.0739C62.0685 21.8074 63.8139 27.4376 63.8139 33.475C63.8139 50.2906 50.2767 63.9267 33.5751 63.9267C27.7749 63.9267 22.3579 62.2798 17.7568 59.4289C23.1313 67.8778 32.5357 73.4758 43.2384 73.4758C59.9365 73.4758 73.4772 59.8433 73.4772 43.0242C73.4772 32.0461 67.709 22.429 59.0602 17.0703L59.0567 17.0739Z"
      fill="#1D90FF"
    />
    <path
      d="M17.108 46.2756C17.108 30.6604 29.6803 18.0031 45.1828 18.0031C54.2467 18.0031 62.303 22.3294 67.4362 29.042C62.5904 20.5646 53.5017 14.8594 43.0934 14.8594C27.5908 14.8594 15.0186 27.5166 15.0186 43.1318C15.0186 49.6194 17.1896 55.5961 20.84 60.3653C18.4667 56.2177 17.108 51.4056 17.108 46.2756Z"
      fill="#A0C9F2"
    />
    <path
      d="M26.9271 45.92C26.544 45.92 26.1679 45.7486 25.9161 45.4235C25.4797 44.8626 25.5826 44.0516 26.1396 43.6122L30.4994 40.1898L25.9906 37.5212C25.3804 37.1604 25.1782 36.3673 25.5365 35.7528C25.8948 35.1384 26.6823 34.9347 27.2925 35.2955L33.4261 38.9287C33.7915 39.1467 34.0292 39.5289 34.054 39.9576C34.0824 40.3827 33.8979 40.7971 33.5645 41.0615L27.7146 45.6521C27.4805 45.8343 27.2038 45.9236 26.9271 45.9236V45.92Z"
      fill="#303030"
    />
    <path
      d="M59.5458 45.9236C59.2691 45.9236 58.9924 45.8343 58.7582 45.6521L52.9084 41.0615C52.575 40.7971 52.3905 40.3863 52.4189 39.9576C52.4473 39.5325 52.6814 39.1467 53.0468 38.9287L59.1804 35.2955C59.7905 34.9347 60.5745 35.1384 60.9364 35.7528C61.2947 36.3673 61.0925 37.1568 60.4823 37.5212L55.9735 40.1898L60.3333 43.6122C60.8903 44.0516 60.9931 44.8626 60.5568 45.4235C60.3049 45.7486 59.9254 45.92 59.5458 45.92V45.9236Z"
      fill="#303030"
    />
    <path
      d="M20.6022 31.8065C20.9782 31.7387 21.34 31.6779 21.6948 31.6208C22.4752 31.4922 23.2131 31.3671 23.9297 31.2028C25.0011 30.9634 25.9731 30.6705 26.9025 30.299C27.8816 29.9096 28.7791 29.4523 29.6518 28.9021C30.6061 28.3127 31.5071 27.5982 32.3408 26.9159C32.3869 26.8766 32.4543 26.873 32.5075 26.9016C32.5607 26.9337 32.5891 26.9909 32.5785 27.0516C32.3976 28.2055 31.8122 29.3523 30.8828 30.374C30.0385 31.2957 28.96 32.0316 27.7645 32.5032C26.64 32.9497 25.4161 33.1605 24.21 33.1212C24.1638 33.1212 24.1177 33.1176 24.0716 33.1141C22.7236 33.0355 21.5387 32.6889 20.5454 32.0745C20.4922 32.0423 20.4674 31.9816 20.478 31.9209C20.4886 31.8601 20.5383 31.8137 20.5986 31.803L20.6022 31.8065Z"
      fill="#414042"
    />
    <path
      d="M65.875 31.8065C65.499 31.7387 65.1371 31.6779 64.7824 31.6208C64.0019 31.4922 63.2641 31.3671 62.5475 31.2028C61.4761 30.9634 60.5041 30.6705 59.5747 30.299C58.5956 29.9096 57.6981 29.4523 56.8254 28.9021C55.8711 28.3127 54.97 27.5982 54.1364 26.9159C54.0903 26.8766 54.0229 26.873 53.9696 26.9016C53.9164 26.9337 53.8881 26.9909 53.8987 27.0516C54.0796 28.2055 54.665 29.3523 55.5944 30.374C56.4387 31.2957 57.5171 32.0316 58.7126 32.5032C59.8372 32.9497 61.0611 33.1605 62.2672 33.1212C62.3133 33.1212 62.3595 33.1176 62.4056 33.1141C63.7536 33.0355 64.9385 32.6889 65.9318 32.0745C65.985 32.0423 66.0098 31.9816 65.9992 31.9209C65.9885 31.8601 65.9389 31.8137 65.8786 31.803L65.875 31.8065Z"
      fill="#414042"
    />
    <path
      d="M53.6576 48.0312C54.0336 48.0312 54.3884 48.2027 54.6261 48.4992L58.7163 53.6186C59.1456 54.158 59.0604 54.944 58.5283 55.3762C57.9926 55.8085 57.2122 55.7228 56.7829 55.1869L53.7534 51.3965L49.4858 57.8376C49.2552 58.1842 48.8685 58.3949 48.4534 58.3949C48.0384 58.3949 47.6517 58.1877 47.4211 57.8376L43.2422 51.5358L39.0632 57.8376C38.8327 58.1842 38.446 58.3949 38.0309 58.3949C37.6159 58.3949 37.2292 58.1842 36.9986 57.8376L32.731 51.3965L29.7014 55.1869C29.2722 55.7263 28.4882 55.8085 27.9561 55.3762C27.4204 54.944 27.3388 54.1544 27.768 53.6186L31.8583 48.4992C32.1066 48.1884 32.4897 48.0134 32.88 48.0348C33.2737 48.0527 33.6391 48.2599 33.8591 48.5886L38.0345 54.8904L42.2134 48.5886C42.444 48.242 42.8307 48.0312 43.2457 48.0312C43.6608 48.0312 44.0475 48.2385 44.278 48.5886L48.457 54.8904L52.6324 48.5886C52.8523 48.2563 53.2142 48.0527 53.6115 48.0348C53.6292 48.0348 53.647 48.0348 53.6682 48.0348L53.6576 48.0312Z"
      fill="#5E4939"
    />
  </svg>
);
export default One;
