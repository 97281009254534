import React from "react";
import { IIcons } from "./icons";

const ReloadIcon = ({ fillColor }: IIcons.IconProps) => (
  <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      d="M8 6.66713e-05C7.91166 -0.00118269 7.82395 0.0151381 7.74197 0.0480805C7.65999 0.0810228 7.58538 0.12993 7.52247 0.191959C7.45955 0.253989 7.4096 0.327903 7.3755 0.409408C7.3414 0.490912 7.32384 0.578381 7.32384 0.666731C7.32384 0.75508 7.3414 0.842549 7.3755 0.924053C7.4096 1.00556 7.45955 1.07947 7.52247 1.1415C7.58538 1.20353 7.65999 1.25244 7.74197 1.28538C7.82395 1.31832 7.91166 1.33464 8 1.33339C11.6897 1.33339 14.6667 4.31036 14.6667 8.00003C14.6667 11.6897 11.6897 14.6667 8 14.6667C4.31031 14.6667 1.33333 11.6897 1.33333 8.00003C1.33333 6.43353 1.87236 4.99794 2.77344 3.86203L4 5.33338L5.33333 0.666731L0.666667 1.33339L1.91146 2.82688C0.724443 4.22343 0 6.0279 0 8.00003C0 12.4103 3.58969 16 8 16C12.4103 16 16 12.4103 16 8.00003C16 3.58974 12.4103 6.66713e-05 8 6.66713e-05Z"
      fill="white"
      className={fillColor}
    />
  </svg>
);
export default ReloadIcon;
