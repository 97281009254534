import { GrayPentagon } from "../../assets/Icons";

export const RegularFeatures = () => {
  return (
    <div className="p-4 bg-white rounded-lg">
      <div id="regularTitle" className="flex flex-col">
        <div className="relative">
          <GrayPentagon />
          <div className="absolute font-bold text-white text-[28px] -mt-20 ml-16">Regular</div>
        </div>
      </div>
      <div id="features" className="flex flex-col items-start space-y-3 mt-7">
        <span className="font-bold text-[#003399] lg:text-[20px]">Features Included</span>
        <span className="font-semibold lg:text-[16px] font-sans">Real Time Rates</span>
        <span className="font-semibold lg:text-[16px] font-sans">100% Digital</span>
        <span className="font-semibold lg:text-[16px] font-sans">Settlement in upto 48 hours</span>
        <span className="font-semibold lg:text-[16px] font-sans">Customer Support</span>
        <span className="font-semibold lg:text-[16px] font-sans">0.3% Convenience Charge</span>
        <span className="font-semibold lg:text-[16px] font-sans">Rs.250 Bank Fees</span>
        <span className="font-semibold lg:text-[16px] font-sans">Rs.1000 Nostro Charges</span>
        <span className="font-semibold lg:text-[16px] font-sans">
          No multicurrency bank account
        </span>
      </div>
    </div>
  );
};
