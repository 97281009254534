import React from "react";
import { IIcons } from "./icons";

const VisibilityOnIcon = ({ width, height, fillColor }: IIcons.IconProps) => (
  <svg
    width={width || "30"}
    height={height || "20"}
    viewBox="0 0 30 20"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M15 0C6.0817 0 0.320988 9.21118 0.216797 9.37891C0.0765534 9.55556 0.000151623 9.77444 0 10C0.000177403 10.1935 0.05649 10.3828 0.162109 10.5449C0.163405 10.5469 0.164707 10.5488 0.166016 10.5508C0.183209 10.5863 5.01883 20 15 20C24.9388 20 29.7673 10.6787 29.8262 10.5645C29.8302 10.558 29.8341 10.5515 29.8379 10.5449C29.9435 10.3828 29.9998 10.1935 30 10C30 9.77535 29.9243 9.55725 29.7852 9.38086C29.7845 9.38021 29.7839 9.37956 29.7832 9.37891C29.679 9.21118 23.9183 0 15 0ZM15 3C18.866 3 22 6.134 22 10C22 13.866 18.866 17 15 17C11.134 17 8 13.866 8 10C8 6.134 11.134 3 15 3ZM15 7C14.2044 7 13.4413 7.31607 12.8787 7.87868C12.3161 8.44129 12 9.20435 12 10C12 10.7956 12.3161 11.5587 12.8787 12.1213C13.4413 12.6839 14.2044 13 15 13C15.7956 13 16.5587 12.6839 17.1213 12.1213C17.6839 11.5587 18 10.7956 18 10C18 9.20435 17.6839 8.44129 17.1213 7.87868C16.5587 7.31607 15.7956 7 15 7Z"
      fill="#E5E5E5"
      className={fillColor}
    />
  </svg>
);
export default VisibilityOnIcon;
