/* eslint-disable */
import step1 from "../assets/images/HowToSend/step1.png";
import step2 from "../assets/images/HowToSend/step2.png";
import step3 from "../assets/images/HowToSend/step3.png";
import step4 from "../assets/images/HowToSend/step4.png";
import { CopyIcon } from "../assets/Icons";
import dealOne from "../assets/images/DealCardOne.png";
// import dealTwo from "../assets/images/DealCardTwo.png";
import dealFour from "../assets/images/DealCardFour.png";
import DealApp from "../assets/images/DealApp.svg";
import DealRef from "../assets/images/DealRef.svg";
import DealBlog from "../assets/images/DealBlog.svg";
import HopPrime from "../assets/images/hopPrime.svg";
import refferalCoinsCard from "../assets/images/RefferalCoinsCard.png";

import axios from "axios";
import sjcl from "sjcl";
import React from "react";

const Key: any = process.env.REACT_APP_CIPHER_KEY;

export const isEmpty = (obj: any) => {
  for (var x in obj) {
    return false;
  }
  return true;
};
export function sum(a: number, b: number) {
  return a + b;
}

// regex for Email Address input field
export const regexForEmailAddress =
  // eslint-disable-next-line no-useless-escape, no-control-regex
  /^((([a-z]|\d|[!#\$%&'\*\+\-\/=\?\^_`{\|}~]|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])+(\.([a-z]|\d|[!#\$%&'\*\+\-\/=\?\^_`{\|}~]|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])+)*)|((\x22)((((\x20|\x09)*(\x0d\x0a))?(\x20|\x09)+)?(([\x01-\x08\x0b\x0c\x0e-\x1f\x7f]|\x21|[\x23-\x5b]|[\x5d-\x7e]|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])|(\\([\x01-\x09\x0b\x0c\x0d-\x7f]|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF]))))*(((\x20|\x09)*(\x0d\x0a))?(\x20|\x09)+)?(\x22)))@((([a-z]|\d|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])|(([a-z]|\d|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])([a-z]|\d|-|\.|_|~|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])*([a-z]|\d|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])))\.)+(([a-z]|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])|(([a-z]|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])([a-z]|\d|-|\.|_|~|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])*([a-z]|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])))$/i;

// regex for Email Address input field
export const regexForMobileNumber = /^[2-9]\d{9}$/gi;

export const regexForPassword = /^(?=.*[0-9])(?=.*[a-z])(?=.*[A-Z])(?=.*[@#$%^&+=*])(?=\S+$).{8,}$/;

export const regexForName = /^[a-zA-Z ]{2,30}$/;

export const regexForExcludeSpecialCharacter =
  /^(?![a-zA-Z._])(?!.*[a-zA-Z._]$)(?!.*\d_)(?!.*_\d)[0-9_]+$/;

export const regExpForSpecialChar = /^[a-zA-Z0-9 ]{1,}$/;

export const inwardIdentityProofDocList = [
  { title: "Passport", subtitle: "Verify Passport", slug: "passport", docKey: "PASSPORT" },
  {
    title: "Driving License",
    subtitle: "Verify Driving License",
    slug: "dl",
    docKey: "EEA_OR_UK_PHOTO_CARD_DRIVING_LICENCE",
  },
  {
    title: "National ID Card   ",
    subtitle: "Verify National ID Card",
    slug: "idcard",
    docKey: "EEA_NATIONAL_ID_CARD",
  },
  {
    title: "Residential Permit",
    subtitle: "Veify Residential Permit",
    slug: "permit",
    docKey: "EU_NATIONAL_RESIDENT_PERMIT",
  },
];

export const inwardAddressProofDocList = [
  {
    title: "Local Authority Tax Bill",
    subtitle: "Verify Local Authority Tax Bill",
    slug: "taxbill",
    docKey: "LOCAL_AUTHORITY_TAX_BILL",
  },
  {
    title: "Utility Bill",
    subtitle: "Verify Utility Bill",
    slug: "utilitybill",
    docKey: "BANK_STATEMENT_OR_UTILITY_BILL",
  },
  {
    title: "Bank Statement",
    subtitle: "Verify Bank Statement",
    slug: "statement",
    docKey: "BANK_STATEMENT_OR_UTILITY_BILL",
  },
];
export const accountTypes = ["Savings", "NRO", "NRE"];
export const sourceOfFundArray = [
  "Business",
  "Salary",
  "Company Funds",
  "Inheritance",
  "Pension",
  "Sale of property",
  "Sale of another investment/asset",
  "Savings",
];
export const remmitanceFrequencyArray = ["Weekly", "Monthly", "Quarterly", "Annually", "One-Off"];

/* Global Errors state Utils */
export const getErrorMessage = (errors: any, fieldName: string, fieldLabel: string) => {
  if (errors[fieldName]) {
    const { type } = errors[fieldName];
    switch (type) {
      case "required":
        return `${fieldLabel} is required`;
      case "sameAs":
        return "Passwords do not match. Please check again.";
      case "pattern":
        return `Invalid ${fieldLabel}`;
      case "maxLength":
        return `Please Enter 10 digit ${fieldLabel}`;
      case "minLength":
        return `Please Enter At least 10 digit ${fieldLabel}`;
      default:
        return type;
    }
  } else {
    return false;
  }
};

export const indianStatesList = [
  "Andhra Pradesh",
  "Arunachal Pradesh",
  "Assam",
  "Bihar",
  "Chhattisgarh",
  "Goa",
  "Gujarat",
  "Haryana",
  "Himachal Pradesh",
  "Jharkhand",
  "Karnataka",
  "Kerala",
  "Madhya Pradesh",
  "Maharashtra",
  "Manipur",
  "Meghalaya",
  "Mizoram",
  "Nagaland",
  "Odisha",
  "Punjab",
  "Rajasthan",
  "Sikkim",
  "Tamil Nadu",
  "Telangana",
  "Tripura",
  "Uttar Pradesh",
  "Uttarakhand",
  "West Bengal",
];

export const commaSeperation = (num: any) => {
  if (!num) {
    return 0;
  } else {
    let n1;
    num = num.toString();
    num = num + "" || "";
    n1 = num.split(".");
    const n2 = n1[1] || null;
    n1 = n1[0].replace(/(\d)(?=(\d\d)+\d$)/g, "$1,");
    num = n2 ? n1 + "." + n2 : n1;
    return num;
  }
};

export function formatNumber(number: any) {
  const formatter = new Intl.NumberFormat("en-IN");
  return formatter.format(number);
}

export const getSendMoneyErrorMessage = (
  sendingCurrency: string,
  sendingAmount: number,
  error: any,
) => {
  if (sendingCurrency === "GBP") {
    if (sendingAmount < 1 || sendingAmount === null) {
      return "The minimum you can send in one transaction is 1 GBP/EUR";
    } else if (sendingAmount > 1000000) {
      return "The maximum you can send in one transaction is 1000000 GBP/EUR";
    } else {
      return error;
    }
  } else if (sendingCurrency === "EUR") {
    if (sendingAmount < 1 || sendingAmount === null) {
      return "The minimum you can send in one transaction is 1 GBP/EUR";
    } else if (sendingAmount > 100) {
      return "The maximum you can send in one transaction is 100 GBP/EUR";
    } else {
      return error;
    }
  } else {
    if (sendingAmount < 1000 || sendingAmount === null) {
      return "You Send should be more than or equal to 5000";
    } else if (sendingAmount > 2000000) {
      return "The maximum you can send in one transaction is 20,00,000 INR";
    } else {
      return error;
    }
  }
};

export const HowToSend = [
  {
    image: step1,
    title: "Where?",
    subtitle: `Select where you want
to send the money.`,
    alt: "Select place",
  },
  {
    image: step2,
    title: "How much?",
    subtitle: `Enter the amount
you want to send.`,
    alt: "Select Money",
  },
  {
    image: step3,
    title: "Who?",
    subtitle: `Select or Add recipient’s
account details.`,
    alt: "Select receiver",
  },
  {
    image: step4,
    title: "Transaction Successful ",
    subtitle: `International Money Transfer
completed within minutes.`,
    alt: "Successfully done",
  },
];

//TODO: Remove this one real deal card are there as text might change
const TEXT_CLASSNAME =
  "pt-2 px-2 min-w-[120px] max-w-[160px] text-xl border rounded border-[#BC4F11] font-medium text-[#BC4F11] leading-5 text-center h-[38px]";

// TODO : Remove this as button can also change
const BUTTON_TEXT = (
  <div className="flex items-center w-full gap-2 mt-1 text-[13px] group-hover:font-bold transition-all">
    {"Copy Code"}
    <span className="bg-[#BC4F1140] rounded-full w-7 h-7 flex items-center justify-center">
      <CopyIcon />
    </span>
  </div>
);

const BUTTON_TEXT_TWO = (
  <div className="flex items-center w-full gap-2 mt-3 text-white text-[13px] group-hover:font-bold transition-all">
    {"Copy Code"}
    <span className="bg-[#40C7BB] rounded-full w-7 h-7 flex items-center justify-center">
      <CopyIcon fill="white" />
    </span>
  </div>
);

const handleCopyToClipboard = async (text: string) => {
  try {
    // Some browsers require explicit user permisiion for clipboard accesss thats why using permissions.query.
    const permissionStatus = await navigator.permissions.query({
      name: "clipboard-write" as PermissionName,
    });

    if (permissionStatus.state === "granted" || permissionStatus.state === "prompt") {
      await navigator.clipboard.writeText(text);
    } else {
      console.error("Clipboard write permission denied");
    }
  } catch (error) {
    console.error("Failed to copy text to clipboard:", error);
  }
};

//TODO : Remove this as this classname will not remain same for every card
const BUTTON_CLASSNAME = "mt-6";

//TODO : Add real deal card config.
export const DEAL_CARD_CONFIG = [
  // {
  //   text: "SUPERHOP",
  //   onClick: () => {
  //     window.alert("Code SUPERHOP copied to clipboard");
  //     handleCopyToClipboard("SUPERHOP");
  //   },
  //   bgUrl: dealOne,
  //   textClassname: TEXT_CLASSNAME,
  //   buttonText: BUTTON_TEXT,
  //   buttonClassname: BUTTON_CLASSNAME,
  // },
  // {
  //   text: "HOPPRIME",
  //   onClick: () => window.open("https://moneyhop.co/hopprime"),
  //   bgUrl: dealTwo,
  //   textClassname:
  //     "pt-2 px-2 min-w-[120px] max-w-[160px] text-lg hover:font-bold transition-all border rounded border-[#0F5BF1] text-[16px] text-[#0F5BF1] leading-5 text-center h-[38px]",
  //   buttonText: "Buy Prime Now",
  //   buttonClassname: BUTTON_CLASSNAME,
  // },
  // {
  //   text: "Adawga",
  //   onClick: () => navigator.clipboard.writeText("Adawga"),
  //   bgUrl: dealThree,
  //   textClassname: TEXT_CLASSNAME,
  //   buttonText: BUTTON_TEXT,
  //   buttonClassname: BUTTON_CLASSNAME,
  // },
  // {
  //   text: "",
  //   onClick: () => {
  //     window.location.href = "/hopapp";
  //   },
  //   bgUrl: DealApp,
  //   textClassname: "",
  //   buttonText: "",
  //   buttonClassname: "mt-5 w-fullvp-12 opacity-0 text-opacity-0 text-white z-0",
  // },
  // {
  //   text: "",
  //   onClick: () => {
  //     window.alert("AWjSuQ copied to clipboard");
  //     handleCopyToClipboard("AWjSuQ");
  //   },
  //   bgUrl: DealRef,
  //   textClassname: "",
  //   buttonText: "",
  //   buttonClassname: "mt-5 w-fullvp-12 opacity-0 text-opacity-0 text-white z-0",
  // },
  {
    text: "",
    onClick: () => {
      window.open("https://hopremit.moneyhop.co/hopprime");
    },
    bgUrl: HopPrime,
    textClassname: "",
    buttonText: "",
    buttonClassname: "mt-5 w-full p-12 opacity-0 text-opacity-0 text-white z-0",
  },
  {
    text: "",
    onClick: () => {
      window.open("https://moneyhop.co/blog");
    },
    bgUrl: DealBlog,
    textClassname: "",
    buttonText: "",
    buttonClassname: "mt-5 w-fullvp-12 opacity-0 text-opacity-0 text-white z-0",
  },
  // {
  //   text: "AWjSuQ",
  //   onClick: () => {
  //     window.alert("Cod text-opacity-0 text-whitee AWjSuQ copied to clipboard");
  //     navigator.clipboard.writeText("AWjSuQ");
  //   },
  //   bgUrl: refferalCoinsCard,
  //   textClassname:
  //     "pt-2 px-2 min-w-[120px] max-w-[160px] text-xl rounded border-[#00544D] border font-medium text-white leading-5 text-center h-[38px]",
  //   buttonText: BUTTON_TEXT_TWO,
  //   buttonClassname: BUTTON_CLASSNAME,
  // },
];

export const kycStepperSteps: Array<string> = [
  "Amount",
  "KYC",
  "Recipients",
  "Purpose and Document Verification",
  "Review",
  "Send",
];

export const stepperSteps: Array<string> = [
  "Amount",
  "Recipients",
  "Purpose and Document Verification",
  "Review",
  "Send",
];

export const youSendFeedData = [
  { id: 1, type: "dropdown" },
  { id: 2, type: "minus" },
  { id: 3, type: "equalto" },
];

export const taxesFeedData = [
  { id: 1, type: "dropdown" },
  { id: 2, type: "plus" },
  { id: 3, type: "plus" },
  { id: 4, type: "plus" },
];

export const sendCurrencyCountryData = [
  { countryName: "Indian Rupee", currencyName: "INR" },
  // { countryName: "Euro", currencyName: "EUR" },
  { countryName: "Great Britain Pound", currencyName: "GBP" },
];

export const receivingCountryData = [
  { countryName: "United States Dollar", currencyName: "USD" },
  { countryName: "Euro", currencyName: "EUR" },
  { countryName: "Great Britain Pound", currencyName: "GBP" },
  { countryName: "Canadian Dollar", currencyName: "CAD" },
  { countryName: "Singapore Dollar", currencyName: "SGD" },
  { countryName: "Emirati Dirham", currencyName: "AED" },
  // { countryName: "Mauritius Rupee", currencyName: "MUR" },
  { countryName: "Japanese Yen", currencyName: "JPY" },
  { countryName: "Australian Dollar", currencyName: "AUD" },
  { countryName: "New Zealand Dollar", currencyName: "NZD" },
  { countryName: "Swiss Franc", currencyName: "CHF" },
];

export const hopCoinsRewardsFAQ = [
  {
    question: "What are HOP coins?",
    answer:
      "HOP coins represent an innovative reward system introduced by HOP Remit for international money transfers. They enable users to accumulate points, which can be utilized during transactions to waive off the convenience fee, adding an element of excitement and benefit to the user.",
  },
  {
    question: "How to get HOP coins?",
    answer: () => (
      <div>
        Here’s how you can start racking up those valuable coins:
        <br />
        <ul className="list-disc pl-5">
          <li>
            <strong>Sign Up:</strong> Sign up today and instantly pocket 200 HOP coins. It’s as
            simple as that! Your journey with HOP Remit begins with a rewarding welcome.
          </li>
          <li>
            <strong>Feedback:</strong> Earn 300 HOP coins every time you provide feedback. Your
            insights help us grow, and we make sure you’re rewarded for it.
          </li>
          <li>
            <strong>Referral:</strong> When you refer a friend to HOP Remit, you both benefit by
            earning a generous 1500 HOP coins each once your friend makes their first transaction.
            There’s no cap on referrals.
          </li>
        </ul>
        Each HOP coin is worth 50 paise, adding real value to your rewards. The more you earn, the
        more benefits you unlock!
      </div>
    ),
  },
  {
    question: "How do I redeem the HOP coins?",
    answer:
      "For international remittances over INR 80,000, convenience fees apply. But don't worry! You can redeem your HOP coins to waive these fees instantly at the time of the transaction.",
  },
];

export const SupportPageFAQ = [
  {
    question: "Do you have an RBI License?",
    answer:
      "We hold an FFMC license from RBI. All the remittance transactions are processed through RBI authorized partners and Banks. ",
  },
  {
    question: "How do I send money via moneyHOP? ",
    answer: () => (
      <div>
        You can send money abroad with 4 easy steps:
        <br />
        <ul className="list-disc pl-5">
          <li>
            <strong>Send money screen:</strong> You have to select details like amount, currency,
            and the purpose code on this screen.
          </li>
          <li>
            <strong>KYC:</strong> For first-time users only, upload your Proof of Identity and
            address for real-time KYC verification.
          </li>
          <li>
            <strong>Add Recipient:</strong> Select or add recipients.
          </li>
          <li>
            <strong>Supporting document:</strong> As per the purpose code selected, documents need
            to be uploaded.
          </li>
          <li>
            <strong>Payment:</strong> Choose the payment mode as per your convenience and transfer
            the funds.
          </li>
        </ul>
        <p>
          <strong>Note:</strong> Users can choose to save the supporting documents and payment
          details. This makes transaction creation for repeat transactions seamless.
        </p>
      </div>
    ),
  },
  {
    question: "How will I know my transaction is confirmed or my recipient has received money ?",
    answer:
      "HOPRemit keeps you updated with information related to your transaction and share the real time update and communication to your registered email id and mobile number. You can also check the status of the transaction on your Remit dashboard. In case you have any other query, you can always write to us at care@moneyhop.co or call us at +91-6364001001.",
  },
  {
    question: "What document do I need for KYC to get completed?",
    answer:
      "PAN card is the mandatory document as proof of identity. Any one of the following (Aadhar, Passport, Voter ID, Driving License) can be provided as proof of address. KYC verification will be done in real time on the platform.",
  },
  {
    question: "What payment methods can I use for the fund transfer ?",
    answer:
      "We have NEFT (Bank transfers), Net Banking, Debit card and UPI as the payment modes for the fund transfer. Any one of the following can be used. ",
  },
  {
    question: "How do I cancel the transaction ?",
    answer:
      "You can cancel the transaction from the HOPRemit dashboard post logging into your Hop- Remit Account. Alternatively, you can also call us at +91-6364001001 and request the customer support executive to cancel the transaction post-identity verification.",
  },
  {
    question: "How much is the GST/SGST on Foreign Currency Conversion?",
    answer: () => (
      <div>
        As stipulated by the Government of India, any conversion from one currency to another -
        sale, encashment & remittance will be charged a GST/SGST on the gross amount of currency
        exchanged as per the following slabs:
        <ul className="list-disc pl-5">
          <li>
            0.18% of the gross amount of currency exchanged for an amount up to Rs 100,000, subject
            to the minimum amount of Rs 4
          </li>
          <li>
            Rs 180 and 0.09% of the gross amount of currency exchanged for an amount of rupees
            exceeding Rs 100,000 and up to Rs 10,00,000
          </li>
          <li>
            Rs 990 and 0.018% of the gross amount of currency exchanged for an amount of rupees
            exceeding Rs 10,00,000, subject to a maximum of Rs.10,800/-
          </li>
        </ul>
      </div>
    ),
  },
  {
    question: "How much time will it take to complete the transfer and receive the SWIFT copy?",
    answer:
      "It will take anywhere between 24 to 48 working hours. SWIFT copy will be shared with the remitter via email and WhatsApp.",
  },
  {
    question: "Can I get my TCS amount back?",
    answer:
      "Yes, most certainly. Unlike TDS, Tax Collected at Source (TCS) can be adjusted while filing tax returns.  Any TCS collected by the service provider will be reflected in Form 26AS. You can view your Form 26AS after logging into the Income Tax portal using your login credentials.",
  },
  {
    question: "What about the annual limit of remittances?",
    answer:
      "Foreign Outward Remittances undertaken through this facility by would be reckoned under your annual LRS limits USD 250,000 per Financial Year (April to March).",
  },
  {
    question: "My transaction has been put on hold. What should I do?",
    answer:
      "If the transaction has been put on hold, user will get the real time communication from us via email and WhatsApp. There will be a reason mentioned, as well as the action required by the user. In case of any query you can reach out to us at care@moneyhop.co. or call us at +91 6364001001",
  },
];

export const purposeCodeList = [
  {
    value: "Overseas Education - University Fees",
    code: "S0305",
  },
  {
    value: "Overseas Education - Living Expenses",
    code: "S0305",
  },
  {
    value: "Family Maintenance",
    code: "S1301",
  },
  {
    value: "Personal Gift or Donation",
    code: "S1302",
  },
  // {
  //   value: "Medical Treatment Abroad",
  //   code: "S1108",
  // },
  // {
  //   value: "Private Visits",
  //   code: "S0306",
  // },
  // {
  //   value: "Business Travel",
  //   code: "S0301",
  // },
  // {
  //   value: "Emigration and Consultancy Fees",
  //   code: "S0306",
  // },
  // {
  //   value: "Employment and overseas job application fees",
  //   code: "S0306",
  // },
  // {
  //   value: "Educational tieup with Foreign Universities",
  //   code: "S1107",
  // },
  // {
  //   value: "Fees for examinations held in India or abroad for GRE, TOEFL etc.",
  //   code: "S0305",
  // },
];
export const signUpPurposeCodeList = ["Family Maintenance", "Foreign Education", "Gift", "Other"];
export const purposeCodeListInward = [
  {
    value: "Family Maintenance",
    code: "I1000",
  },
  {
    value: "Transfer to NRE/Own Account",
    code: "I1001",
  },
  {
    value: "Savings",
    code: "I1002",
  },
  {
    value: "Bill Payment",
    code: "I1003",
  },
  {
    value: "Education",
    code: "I1004",
  },
];

export const documents = [
  {
    docName: "PAN Card",
    date: "12/12/2021",
  },
  {
    docName: "Passport",
    date: "12/12/2021",
  },
  {
    docName: "Driving License",
    date: "12/12/2021",
  },
  {
    docName: "Aadhaar Card",
    date: "12/12/2021",
  },
];

export const offersModalData = [
  {
    offer: "Get flat INR 250 off on the convenience fee",
    code: "HOPNEW",
    // toActiveDescription: "Add 1000 INR more to avail this offer",
  },
  // {
  //   offer: "Get 25% off on convenience fee if sending more than 1L ",
  //   code: "",
  //   // toActiveDescription: "Add 1000 INR more to avail this offer",
  // },
  // {
  //   offer: "Get 5% off on your First USD transaction",
  //   code: "HOP_HEIST",
  //   toActiveDescription: "Add 1000 INR more to avail this offer",
  // },
  // {
  //   offer: "Get 10% off on your First USD transaction",
  //   code: "HOP_HEIST",
  //   toActiveDescription: "Add 1000 INR more to avail this offer",
  // },
  // {
  //   offer: "Get 10% off on your First USD transaction",
  //   code: "HOP_HEIST",
  //   // toActiveDescription: "Add 1000 INR more to avail this offer",
  // },
];

export const TransactioncancellationReasons = [
  "Do not have documents yet",
  "Just trying the platform",
  "Connectivity issue",
  "Other Reason...",
];

export const selectPurposeHeader = (
  <div className="font-bold text-[12px] text-white lg:text-base">Select the purpose of payment</div>
);

export const offerHeader = (
  <div className="flex items-start lg:items-center">
    <div className="mr-4 text-lg font-bold text-white w-max">Redeem HOP Coins</div>
  </div>
);

export const deliveryOptionOne = (
  <div className="flex flex-col justify-center">
    <div className="text-base font-medium text-black lg:text-lg">Express Delivery</div>
    <div className="text-sm font-normal text-grey-750">
      Reaches in 2 days from the day of initialisation
    </div>
  </div>
);

export const deliveryOptionTwo = (
  <div className="flex flex-col justify-center">
    <div className="text-base font-medium text-black text-[16px] lg:text-[18px]">
      Standard Delivery
    </div>
    <div className="text-[10px] lg:text-[18px] font-normal text-grey-750">
      Money is credited within 48 working hours post completion of transaction
    </div>
  </div>
);

export const deliveryOptionThree = (
  <div className="flex flex-col justify-center">
    <div className="text-base text-black lg:text-lg">Custom Delivery</div>
    <div className="text-sm font-normal text-grey-750">This option is apt for paying Uni Fees</div>
  </div>
);

export const deliveryOptions = [
  { value: "Standard Delivery", label: deliveryOptionTwo, charges: "Free", disabled: false },
  // { value: "Express Delivery", label: deliveryOptionOne, charges: "$1", disabled: true },
];

export const addRecipientSteps = ["Recipient details", "Address", "Bank Details"];

export const uploadDocumentsData = ["Student Passport", "Universtity letter"];

export const verifiedDocumentsData = [
  // { title: "CKYC", subtitle: "Verify CKYC", value: "CKYC" },
  { title: "Aadhaar Card", subtitle: "Verify Aadhaar Card", value: "AADHAR_CARD" },
  { title: "Driving License", subtitle: "Verify Driving License", value: "DRIVING_LICENCE" },
  { title: "Indian Passport", subtitle: "Verify Indian Passport", value: "INDIAN_PASSPORT" },
  { title: "Voter ID", subtitle: "Verify Voter ID", value: "VOTER_CARD" },
];

export const kycDocsId = {
  E_VERIFY_VOTERID: 8,
  VERIFY_VOTERID_DOC: 12,
  E_VERIFY_DL: 5,
  VERIFY_DL_DOC: 10,
  E_VERIFY_PAN: 1,
  VERIFY_AADHAR_CARD: 2,
  UPLOAD_AADHAR_BACK: 16,
  PASSPORT_FRONT: 3,
  PASSPORT_BACK: 4,
};

export const kycVerificationAgreementNote = (
  <div className="flex items-center">
    <div className="text-base font-bold text-grey-1200">
      Note :
      <span className="ml-1 text-xs font-normal md:text-base text-grey-1200">
        These documents play an important role in International money transfers so make sure that
        the uploaded images are crisp and clear or the chance of cancellation increases.
      </span>
    </div>
  </div>
);

export const additionalDocumentsList = [
  {
    name: "Overseas Education - University Fees",
    code: "Overseas-Education-University-Fees",
    longDisc: "Upload the following documents for confirming your universtiy fees",
    shortDisc: "Eg. Student Passport",
    isUploaded: false,
    mandatoryDocs: 2,
    uploadDoc: [
      {
        name: "Student Passport (Front)",
        code: "Student-Passport",
        isUploaded: false,
        uploadedId: 0,
        fileName: "",
        isMandatory: true,
        uploadedFile: "",
        errorMessage: "",
      },
      {
        name: "Student Passport (Back)",
        code: "Student-Passport Back",
        isUploaded: false,
        uploadedId: 0,
        fileName: "",
        isMandatory: true,
        uploadedFile: "",
        errorMessage: "",
      },
      {
        name: "University Offer Letter",
        code: "University-Offer-Letter",
        isUploaded: false,
        uploadedId: 0,
        fileName: "",
        isMandatory: true,
        uploadedFile: "",
        errorMessage: "",
      },
      {
        name: "Visa (If available)",
        code: "Visa",
        isUploaded: false,
        uploadedId: 0,
        fileName: "",
        isMandatory: false,
        uploadedFile: "",
        errorMessage: "",
      },
      {
        name: "I20 letter (If available)",
        code: "i-20",
        isUploaded: false,
        uploadedId: 0,
        fileName: "",
        isMandatory: false,
        uploadedFile: "",
        errorMessage: "",
      },
    ],
  },
  {
    name: "Overseas Education - Living Expenses",
    code: "Overseas-Education-Living-Expenses",
    longDisc: "Upload the following documents for confirming your universtiy fees",
    shortDisc: "Eg. Student Passport",
    isUploaded: false,
    mandatoryDocs: 2,
    uploadDoc: [
      {
        name: "Student Passport (Front)",
        code: "Student-Passport",
        isUploaded: false,
        uploadedId: 0,
        fileName: "",
        uploadedFile: "",
        isMandatory: true,
        errorMessage: "",
      },
      {
        name: "Student Passport (Back)",
        code: "Student-Passport Back",
        isUploaded: false,
        uploadedId: 0,
        fileName: "",
        isMandatory: true,
        uploadedFile: "",
        errorMessage: "",
      },
      {
        name: "University Offer Letter",
        code: "University-Offer-Letter",
        isUploaded: false,
        uploadedId: 0,
        fileName: "",
        uploadedFile: "",
        isMandatory: true,
        errorMessage: "",
      },
      {
        name: "Rent Agreement Invoice",
        code: "Rent-Agreement-Invoice",
        isUploaded: false,
        uploadedId: 0,
        fileName: "",
        uploadedFile: "",
        isMandatory: false,
        errorMessage: "",
      },
      {
        name: "Visa (If available)",
        code: "Visa",
        isUploaded: false,
        uploadedId: 0,
        fileName: "",
        uploadedFile: "",
        isMandatory: false,
        errorMessage: "",
      },
      {
        name: "I20 letter (If available)",
        code: "i-20",
        isUploaded: false,
        uploadedId: 0,
        fileName: "",
        uploadedFile: "",
        isMandatory: false,
        errorMessage: "",
      },
    ],
  },
  {
    name: "Family Maintenance",
    code: "Family-Maintenance",
    longDisc:
      "Upload the following documents for confirming your relationship with the beneficiary",
    shortDisc:
      "Choose any one of these Eg. Recipient Passport, PAN Card or other supporting document",
    isUploaded: false,
    mandatoryDocs: 1,
    uploadDoc: [
      {
        name: "Relationship Proof",
        code: "Relationship-Proof",
        isUploaded: false,
        uploadedId: 0,
        isMandatory: true,
        fileName: "",
        uploadedFile: "",
        errorMessage: "",
      },
      {
        name: "Beneficiary ID",
        code: "Beneficiary-ID",
        isUploaded: false,
        uploadedId: 0,
        fileName: "",
        uploadedFile: "",
        isMandatory: true,
      },
    ],
  },
  {
    name: "Personal Gift or Donation",
    code: "Personal-Gift-Donation",
    longDisc: "Upload the following documents for confirming your Recipient",
    shortDisc: "Eg. Recipient Passport, Driving License, SSN Number etc.",
    isUploaded: false,
    mandatoryDocs: 1,
    uploadDoc: [
      {
        name: "Recipient passport/Driving license SSN-number",
        code: "Recipient-passport-Driving-license-SSN-number",
        isUploaded: false,
        uploadedId: 0,
        fileName: "",
        uploadedFile: "",
        isMandatory: true,
        errorMessage: "",
      },
      // {
      //   name: "Gift declaration",
      //   code: "Gift-Declaration",
      //   isUploaded: false,
      //   uploadedId: 0,
      //   fileName: "",
      //   uploadedFile: "",
      //   errorMessage: "",
      // },
    ],
  },
  {
    name: "Business Travel",
    code: "Business-Travel",
    longDisc: "Upload the following documents for confirming your Recipient",
    shortDisc: "Eg. Recipient Passport, Driving License, SSN Number etc.",
    isUploaded: false,
    mandatoryDocs: 2,
    uploadDoc: [
      {
        name: "Air Ticket Copy",
        code: "Air-Ticket-Copy",
        isUploaded: false,
        uploadedId: 0,
        isMandatory: true,
        fileName: "",
        uploadedFile: "",
        errorMessage: "",
      },
      {
        name: "Visa",
        code: "Visa",
        isUploaded: false,
        uploadedId: 0,
        isMandatory: true,
        fileName: "",
        uploadedFile: "",
        errorMessage: "",
      },
    ],
  },
  {
    name: "Private Visits",
    code: "Private-Visits",
    longDisc: "Upload the following documents for confirming your Recipient",
    shortDisc: "Eg. Recipient Passport, Driving License, SSN Number etc.",
    isUploaded: false,
    mandatoryDocs: 2,
    uploadDoc: [
      {
        name: "Air Ticket Copy",
        code: "Air-Ticket-Copy",
        isUploaded: false,
        uploadedId: 0,
        isMandatory: true,
        fileName: "",
        uploadedFile: "",
        errorMessage: "",
      },
      {
        name: "Visa",
        code: "Visa",
        isUploaded: false,
        uploadedId: 0,
        isMandatory: true,
        fileName: "",
        uploadedFile: "",
        errorMessage: "",
      },
    ],
  },
];

export const additionalDocumentListWithGic = [
  {
    name: "Overseas Education - Living Expenses",
    code: "Overseas-Education-Living-Expenses",
    longDisc: "Upload the following documents for confirming your universtiy fees",
    shortDisc: "Eg. Student Passport",
    isUploaded: false,
    mandatoryDocs: 2,
    uploadDoc: [
      {
        name: "Student Passport (Front)",
        code: "Student-Passport",
        isUploaded: false,
        uploadedId: 0,
        fileName: "",
        uploadedFile: "",
        isMandatory: true,
        errorMessage: "",
      },
      {
        name: "Student Passport (Back)",
        code: "Student-Passport Back",
        isUploaded: false,
        uploadedId: 0,
        fileName: "",
        isMandatory: true,
        uploadedFile: "",
        errorMessage: "",
      },
      {
        name: "University Offer Letter",
        code: "University-Offer-Letter",
        isUploaded: false,
        uploadedId: 0,
        fileName: "",
        uploadedFile: "",
        isMandatory: true,
        errorMessage: "",
      },
      {
        name: "Rent Agreement Invoice",
        code: "Rent-Agreement-Invoice",
        isUploaded: false,
        uploadedId: 0,
        fileName: "",
        uploadedFile: "",
        isMandatory: false,
        errorMessage: "",
      },
      {
        name: "Visa (If available)",
        code: "Visa",
        isUploaded: false,
        uploadedId: 0,
        fileName: "",
        uploadedFile: "",
        isMandatory: false,
        errorMessage: "",
      },
      {
        name: "I20 letter (If available)",
        code: "i-20",
        isUploaded: false,
        uploadedId: 0,
        fileName: "",
        uploadedFile: "",
        isMandatory: false,
        errorMessage: "",
      },
      {
        name: "GIC Letter (If available)",
        code: "gic-letter",
        isUploaded: false,
        uploadedId: 0,
        fileName: "",
        uploadedFile: "",
        isMandatory: false,
      },
    ],
  },
];

export const additionalDocumentsListWithFlywire = [
  {
    name: "Overseas Education - University Fees",
    code: "Overseas-Education-University-Fees",
    longDisc: "Upload the following documents for confirming your universtiy fees",
    shortDisc: "Eg. Student Passport",
    isUploaded: false,
    mandatoryDocs: 2,
    uploadDoc: [
      {
        name: "Student Passport (Front)",
        code: "Student-Passport",
        isUploaded: false,
        uploadedId: 0,
        fileName: "",
        isMandatory: true,
        uploadedFile: "",
        errorMessage: "",
      },
      {
        name: "Student Passport (Back)",
        code: "Student-Passport Back",
        isUploaded: false,
        uploadedId: 0,
        fileName: "",
        isMandatory: true,
        uploadedFile: "",
        errorMessage: "",
      },
      {
        name: "University Offer Letter",
        code: "University-Offer-Letter",
        isUploaded: false,
        uploadedId: 0,
        fileName: "",
        isMandatory: true,
        uploadedFile: "",
        errorMessage: "",
      },
      {
        name: "Visa (If available)",
        code: "Visa",
        isUploaded: false,
        uploadedId: 0,
        fileName: "",
        isMandatory: false,
        uploadedFile: "",
        errorMessage: "",
      },
      {
        name: "I20 letter (If available)",
        code: "i-20",
        isUploaded: false,
        uploadedId: 0,
        fileName: "",
        isMandatory: false,
        uploadedFile: "",
        errorMessage: "",
      },
      {
        name: "Flywire Letter (If available)",
        code: "flywire-letter",
        isUploaded: false,
        uploadedId: 0,
        fileName: "",
        uploadedFile: "",
        isMandatory: false,
      },
    ],
  },
];

export const additionalIsTcsDocumentsList = [
  {
    name: "Overseas Education - University Fees",
    code: "Overseas-Education-University-Fees",
    longDisc: "Upload the following documents for confirming your universtiy fees",
    shortDisc: "Eg. Student Passport",
    isUploaded: false,
    mandatoryDocs: 2,
    uploadDoc: [
      {
        name: "Student Passport (Front)",
        code: "Student-Passport",
        isUploaded: false,
        uploadedId: 0,
        fileName: "",
        uploadedFile: "",
        isMandatory: true,
      },
      {
        name: "Student Passport (Back)",
        code: "Student-Passport Back",
        isUploaded: false,
        uploadedId: 0,
        fileName: "",
        isMandatory: true,
        uploadedFile: "",
        errorMessage: "",
      },
      {
        name: "University Offer Letter",
        code: "University-Offer-Letter",
        isUploaded: false,
        uploadedId: 0,
        fileName: "",
        uploadedFile: "",
        isMandatory: true,
      },
      {
        name: "Visa (If available)",
        code: "Visa",
        isUploaded: false,
        uploadedId: 0,
        fileName: "",
        uploadedFile: "",
        isMandatory: false,
      },
      {
        name: "I20 letter (If available)",
        code: "i-20",
        isUploaded: false,
        uploadedId: 0,
        fileName: "",
        uploadedFile: "",
        errorMessage: "",
        isMandatory: false,
      },
    ],
  },
  {
    name: "Overseas Education - Living Expenses",
    code: "Overseas-Education-Living-Expenses",
    longDisc: "Upload the following documents for confirming your universtiy fees",
    shortDisc: "Eg. Student Passport",
    isUploaded: false,
    mandatoryDocs: 3,
    uploadDoc: [
      {
        name: "Student Passport (Front)",
        code: "Student-Passport",
        isUploaded: false,
        uploadedId: 0,
        fileName: "",
        uploadedFile: "",
        isMandatory: true,
      },
      {
        name: "Student Passport (Back)",
        code: "Student-Passport Back",
        isUploaded: false,
        uploadedId: 0,
        fileName: "",
        isMandatory: true,
        uploadedFile: "",
        errorMessage: "",
      },
      {
        name: "University Offer Letter",
        code: "University-Offer-Letter",
        isUploaded: false,
        uploadedId: 0,
        fileName: "",
        uploadedFile: "",
        isMandatory: true,
      },
      {
        name: "Rent Agreement Invoice",
        code: "Rent-Agreement-Invoice",
        isUploaded: false,
        uploadedId: 0,
        fileName: "",
        uploadedFile: "",
        isMandatory: true,
      },
      {
        name: "Visa (If available)",
        code: "Visa",
        isUploaded: false,
        uploadedId: 0,
        fileName: "",
        uploadedFile: "",
        isMandatory: false,
      },
      {
        name: "I20 letter (If available)",
        code: "i-20",
        isUploaded: false,
        uploadedId: 0,
        fileName: "",
        uploadedFile: "",
        errorMessage: "",
        isMandatory: false,
      },
    ],
  },
  {
    name: "Family Maintenance",
    code: "Family-Maintenance",
    longDisc:
      "Upload the following documents for confirming your relationship with the beneficiary",
    shortDisc:
      "Choose any one of these Eg. Recipient Passport, PAN Card or other supporting document",
    isUploaded: false,
    mandatoryDocs: 2,
    uploadDoc: [
      {
        name: "Relationship Proof",
        code: "Relationship-Proof",
        isUploaded: false,
        uploadedId: 0,
        fileName: "",
        uploadedFile: "",
        isMandatory: true,
      },
      {
        name: "Beneficiary ID",
        code: "Beneficiary-ID",
        isUploaded: false,
        uploadedId: 0,
        fileName: "",
        uploadedFile: "",
        isMandatory: true,
      },
      {
        name: "Bank Statement (1 year)",
        code: "bank statement",
        isUploaded: false,
        uploadedId: 0,
        fileName: "",
        uploadedFile: "",
        isMandatory: true,
      },
    ],
  },
  {
    name: "Personal Gift or Donation",
    code: "Personal-Gift-Donation",
    longDisc: "Upload the following documents for confirming your Recipient",
    shortDisc: "Eg. Recipient Passport, Driving License, SSN Number etc.",
    isUploaded: false,
    mandatoryDocs: 2,
    uploadDoc: [
      {
        name: "Recipient passport/Driving license SSN-number",
        code: "Recipient-passport-Driving-license-SSN-number",
        isUploaded: false,
        uploadedId: 0,
        fileName: "",
        uploadedFile: "",
        isMandatory: true,
      },
      // {
      //   name: "Gift declaration",
      //   code: "Gift-Declaration",
      //   isUploaded: false,
      //   uploadedId: 0,
      //   fileName: "",
      //   uploadedFile: "",
      // },
      {
        name: "Bank Statement (1 year)",
        code: "bank statement",
        isUploaded: false,
        uploadedId: 0,
        fileName: "",
        uploadedFile: "",
        isMandatory: true,
      },
    ],
  },
  {
    name: "Business Travel",
    code: "Business-Travel",
    longDisc: "Upload the following documents for confirming your Recipient",
    shortDisc: "Eg. Recipient Passport, Driving License, SSN Number etc.",
    isUploaded: false,
    mandatoryDocs: 2,
    uploadDoc: [
      {
        name: "Air Ticket Copy",
        code: "Air-Ticket-Copy",
        isUploaded: false,
        uploadedId: 0,
        fileName: "",
        uploadedFile: "",
        isMandatory: true,
      },
      {
        name: "Visa",
        code: "Visa",
        isUploaded: false,
        uploadedId: 0,
        fileName: "",
        uploadedFile: "",
        isMandatory: true,
      },
    ],
  },
  {
    name: "Private Visits",
    code: "Private-Visits",
    longDisc: "Upload the following documents for confirming your Recipient",
    shortDisc: "Eg. Recipient Passport, Driving License, SSN Number etc.",
    isUploaded: false,
    mandatoryDocs: 2,
    uploadDoc: [
      {
        name: "Air Ticket Copy",
        code: "Air-Ticket-Copy",
        isUploaded: false,
        uploadedId: 0,
        fileName: "",
        uploadedFile: "",
        isMandatory: true,
      },
      {
        name: "Visa",
        code: "Visa",
        isUploaded: false,
        uploadedId: 0,
        fileName: "",
        uploadedFile: "",
        isMandatory: true,
      },
    ],
  },
];

export const additionalIsTcsDocumentsListWithGic = [
  {
    name: "Overseas Education - Living Expenses",
    code: "Overseas-Education-Living-Expenses",
    longDisc: "Upload the following documents for confirming your universtiy fees",
    shortDisc: "Eg. Student Passport",
    isUploaded: false,
    mandatoryDocs: 3,
    uploadDoc: [
      {
        name: "Student Passport (Front)",
        code: "Student-Passport",
        isUploaded: false,
        uploadedId: 0,
        fileName: "",
        uploadedFile: "",
        isMandatory: true,
      },
      {
        name: "Student Passport (Back)",
        code: "Student-Passport Back",
        isUploaded: false,
        uploadedId: 0,
        fileName: "",
        isMandatory: true,
        uploadedFile: "",
        errorMessage: "",
      },
      {
        name: "University Offer Letter",
        code: "University-Offer-Letter",
        isUploaded: false,
        uploadedId: 0,
        fileName: "",
        uploadedFile: "",
        isMandatory: true,
      },
      {
        name: "Rent Agreement Invoice",
        code: "Rent-Agreement-Invoice",
        isUploaded: false,
        uploadedId: 0,
        fileName: "",
        uploadedFile: "",
        isMandatory: true,
      },
      {
        name: "Visa (If available)",
        code: "Visa",
        isUploaded: false,
        uploadedId: 0,
        fileName: "",
        uploadedFile: "",
        isMandatory: false,
      },
      {
        name: "I20 letter (If available)",
        code: "i-20",
        isUploaded: false,
        uploadedId: 0,
        fileName: "",
        uploadedFile: "",
        errorMessage: "",
        isMandatory: false,
      },
      {
        name: "GIC Letter (If available)",
        code: "gic-letter",
        isUploaded: false,
        uploadedId: 0,
        fileName: "",
        uploadedFile: "",
        isMandatory: false,
      },
    ],
  },
];

export const additionalIsTcsDocumentsListWithFlywire = [
  {
    name: "Overseas Education - University Fees",
    code: "Overseas-Education-University-Fees",
    longDisc: "Upload the following documents for confirming your universtiy fees",
    shortDisc: "Eg. Student Passport",
    isUploaded: false,
    mandatoryDocs: 2,
    uploadDoc: [
      {
        name: "Student Passport (Front)",
        code: "Student-Passport",
        isUploaded: false,
        uploadedId: 0,
        fileName: "",
        uploadedFile: "",
        isMandatory: true,
      },
      {
        name: "Student Passport (Back)",
        code: "Student-Passport Back",
        isUploaded: false,
        uploadedId: 0,
        fileName: "",
        isMandatory: true,
        uploadedFile: "",
        errorMessage: "",
      },
      {
        name: "University Offer Letter",
        code: "University-Offer-Letter",
        isUploaded: false,
        uploadedId: 0,
        fileName: "",
        uploadedFile: "",
        isMandatory: true,
      },
      {
        name: "Visa (If available)",
        code: "Visa",
        isUploaded: false,
        uploadedId: 0,
        fileName: "",
        uploadedFile: "",
        isMandatory: false,
      },
      {
        name: "I20 letter (If available)",
        code: "i-20",
        isUploaded: false,
        uploadedId: 0,
        fileName: "",
        uploadedFile: "",
        errorMessage: "",
        isMandatory: false,
      },
      {
        name: "Flywire Letter (If available)",
        code: "flywire-letter",
        isUploaded: false,
        uploadedId: 0,
        fileName: "",
        uploadedFile: "",
        isMandatory: false,
      },
    ],
  },
];

export const loanSanctionLetterDocData = {
  id: 1,
  name: "Loan Disbursement letter",
  code: "Loan-Sanction-letter",
  isUploaded: false,
  uploadedId: 0,
  fileName: "",
  uploadedFile: "",
  errorMessage: "",
};

export const countryOfResidence = [
  "United States",
  "United Kingdom",
  "Canada",
  "United Arab Emirates",
  "Singapore",
  "Australia",
  "Germany",
  "New Zealand",
  "France",
  "Italy",
  "Afghanistan",
  "Åland Islands",
  "Albania",
  "Algeria",
  "American Samoa",
  "AndorrA",
  "Angola",
  "Anguilla",
  "Antarctica",
  "Antigua and Barbuda",
  "Argentina",
  "Armenia",
  "Aruba",
  "Austria",
  "Azerbaijan",
  "Bahamas",
  "Bahrain",
  "Bangladesh",
  "Barbados",
  "Belarus",
  "Belgium",
  "Belize",
  "Benin",
  "Bermuda",
  "Bhutan",
  "Bolivia",
  "Bosnia and Herzegovina",
  "Botswana",
  "Bouvet Island",
  "Brazil",
  "British Indian Ocean Territory",
  "Brunei Darussalam",
  "Bulgaria",
  "Burkina Faso",
  "Russia",
  "Thailand",
  "Burundi",
  "Cambodia",
  "Cameroon",
  "Cape Verde",
  "Cayman Islands",
  "Central African Republic",
  "Chad",
  "Chile",
  "China",
  "Christmas Island",
  "Cocos (Keeling) Islands",
  "Colombia",
  "Comoros",
  "Congo",
  "Congo, The Democratic Republic of the",
  "Cook Islands",
  "Costa Rica",
  "Cote D'Ivoire",
  "Croatia",
  "Cyprus",
  "Czech Republic",
  "Denmark",
  "Djibouti",
  "Dominica",
  "Dominican Republic",
  "Ecuador",
  "Egypt",
  "El Salvador",
  "Equatorial Guinea",
  "Eritrea",
  "Estonia",
  "Ethiopia",
  "Falkland Islands (Malvinas)",
  "Faroe Islands",
  "Fiji",
  "Finland",
  "French Guiana",
  "French Polynesia",
  "French Southern Territories",
  "Gabon",
  "Gambia",
  "Georgia",
  "Ghana",
  "Gibraltar",
  "Greece",
  "Greenland",
  "Grenada",
  "Guadeloupe",
  "Guam",
  "Guatemala",
  "Guernsey",
  "Guinea",
  "Guinea-Bissau",
  "Guyana",
  "Haiti",
  "Heard Island and Mcdonald Islands",
  "Holy See (Vatican City State)",
  "Honduras",
  "Hong Kong",
  "Hungary",
  "Iceland",
  "Indonesia",
  "India",
  "Ireland",
  "Isle of Man",
  "Israel",
  "Jamaica",
  "Japan",
  "Jersey",
  "Jordan",
  "Kazakhstan",
  "Kenya",
  "Kiribati",
  "Korea, Republic of",
  "Kuwait",
  "Kyrgyzstan",
  "Lao People'S Democratic Republic",
  "Latvia",
  "Lebanon",
  "Lesotho",
  "Liberia",
  "Libyan Arab Jamahiriya",
  "Liechtenstein",
  "Lithuania",
  "Luxembourg",
  "Macao",
  "Macedonia, The Former Yugoslav Republic of",
  "Madagascar",
  "Malawi",
  "Malaysia",
  "Maldives",
  "Mali",
  "Malta",
  "Marshall Islands",
  "Martinique",
  "Mauritania",
  "Mauritius",
  "Mayotte",
  "Mexico",
  "Micronesia, Federated States of",
  "Moldova, Republic of",
  "Monaco",
  "Mongolia",
  "Montserrat",
  "Morocco",
  "Mozambique",
  "Myanmar",
  "Namibia",
  "Nauru",
  "Nepal",
  "Netherlands",
  "Netherlands Antilles",
  "New Caledonia",
  "Nicaragua",
  "Niger",
  "Nigeria",
  "Niue",
  "Norfolk Island",
  "Northern Mariana Islands",
  "Norway",
  "Oman",
  "Pakistan",
  "Palau",
  "Palestinian Territory, Occupied",
  "Panama",
  "Papua New Guinea",
  "Paraguay",
  "Peru",
  "Philippines",
  "Pitcairn",
  "Poland",
  "Portugal",
  "Puerto Rico",
  "Qatar",
  "Reunion",
  "Romania",
  "RWANDA",
  "Saint Helena",
  "Saint Kitts and Nevis",
  "Saint Lucia",
  "Saint Pierre and Miquelon",
  "Saint Vincent and the Grenadines",
  "Samoa",
  "San Marino",
  "Sao Tome and Principe",
  "Saudi Arabia",
  "Senegal",
  "Serbia and Montenegro",
  "Seychelles",
  "Sierra Leone",
  "Slovakia",
  "Slovenia",
  "Solomon Islands",
  "Somalia",
  "South Africa",
  "South Georgia and the South Sandwich Islands",
  "Spain",
  "Sri Lanka",
  "Sudan",
  "Suriname",
  "Svalbard and Jan Mayen",
  "Swaziland",
  "Sweden",
  "Switzerland",
  "Taiwan, Province of China",
  "Tajikistan",
  "Tanzania, United Republic of",
  "Timor-Leste",
  "Togo",
  "Tokelau",
  "Tonga",
  "Trinidad and Tobago",
  "Tunisia",
  "Turkey",
  "Turkmenistan",
  "Turks and Caicos Islands",
  "Tuvalu",
  "Uganda",
  "United States Minor Outlying Islands",
  "Uruguay",
  "Uzbekistan",
  "Vanuatu",
  "Venezuela",
  "Vietnam",
  "Virgin Islands, British",
  "Virgin Islands, U.S.",
  "Wallis and Futuna",
  "Western Sahara",
  "Yemen",
  "Zambia",
  "Zimbabwe",
];

export const sideBannerSubText = [
  "",
  "No problem you can reset it using the forgot password flow.",
  "No problem you can reset it using the forgot password flow.",
  "We have sent an OTP to your registered email address.",
  "Now you can setup a new password.",
];

export const relationShips = [
  "Spouse",
  "Son",
  "Daughter",
  "Brother",
  "Sister",
  "Father",
  "Mother",
  "Son-in-law",
  "Daughter-in-law",
  "Self",
  "Others",
];

export const countryFlagCode = [
  { code: "AF", country: "Afghanistan" },
  { code: "AX", country: "Aland Islands" },
  { code: "AL", country: "Albania" },
  { code: "DZ", country: "Algeria" },
  { code: "AS", country: "American Samoa" },
  { code: "AD", country: "Andorra" },
  { code: "AO", country: "Angola" },
  { code: "AI", country: "Anguilla" },
  { code: "AQ", country: "Antarctica" },
  { code: "AG", country: "Antigua and Barbuda" },
  { code: "AR", country: "Argentina" },
  { code: "AM", country: "Armenia" },
  { code: "AW", country: "Aruba" },
  { code: "AU", country: "Australia" },
  { code: "AT", country: "Austria" },
  { code: "AZ", country: "Azerbaijan" },
  { code: "BS", country: "Bahamas" },
  { code: "BH", country: "Bahrain" },
  { code: "BD", country: "Bangladesh" },
  { code: "BB", country: "Barbados" },
  { code: "BY", country: "Belarus" },
  { code: "BE", country: "Belgium" },
  { code: "BZ", country: "Belize" },
  { code: "BJ", country: "Benin" },
  { code: "BM", country: "Bermuda" },
  { code: "BT", country: "Bhutan" },
  { code: "BO", country: "Bolivia (Plurinational State of)" },
  { code: "BQ", country: "Bonaire, Sint Eustatius and Saba" },
  { code: "BA", country: "Bosnia and Herzegovina" },
  { code: "BW", country: "Botswana" },
  { code: "BV", country: "Bouvet Island" },
  { code: "BR", country: "Brazil" },
  { code: "IO", country: "British Indian Ocean Territory" },
  { code: "BN", country: "Brunei Darussalam" },
  { code: "BG", country: "Bulgaria" },
  { code: "BF", country: "Burkina Faso" },
  { code: "BI", country: "Burundi" },
  { code: "CV", country: "Cabo Verde" },
  { code: "KH", country: "Cambodia" },
  { code: "CM", country: "Cameroon" },
  { code: "CA", country: "Canada" },
  { code: "KY", country: "Cayman Islands" },
  { code: "CF", country: "Central African Republic" },
  { code: "TD", country: "Chad" },
  { code: "CL", country: "Chile" },
  { code: "CN", country: "China" },
  { code: "CX", country: "Christmas Island" },
  { code: "CC", country: "Cocos (Keeling) Islands" },
  { code: "CO", country: "Colombia" },
  { code: "KM", country: "Comoros" },
  { code: "CG", country: "Congo" },
  { code: "CD", country: "Congo (Democratic Republic of the)" },
  { code: "CK", country: "Cook Islands" },
  { code: "CR", country: "Costa Rica" },
  { code: "CI", country: "Côte d'Ivoire" },
  { code: "HR", country: "Croatia" },
  { code: "CU", country: "Cuba" },
  { code: "CW", country: "Curaçao" },
  { code: "CY", country: "Cyprus" },
  { code: "CZ", country: "Czechia" },
  { code: "DK", country: "Denmark" },
  { code: "DJ", country: "Djibouti" },
  { code: "DM", country: "Dominica" },
  { code: "DO", country: "Dominican Republic" },
  { code: "EC", country: "Ecuador" },
  { code: "EG", country: "Egypt" },
  { code: "SV", country: "El Salvador" },
  { code: "GQ", country: "Equatorial Guinea" },
  { code: "ER", country: "Eritrea" },
  { code: "EE", country: "Estonia" },
  { code: "SZ", country: "Eswatini" },
  { code: "ET", country: "Ethiopia" },
  { code: "FK", country: "Falkland Islands (Malvinas)" },
  { code: "FO", country: "Faroe Islands" },
  { code: "FJ", country: "Fiji" },
  { code: "FI", country: "Finland" },
  { code: "FR", country: "France" },
  { code: "GF", country: "French Guiana" },
  { code: "PF", country: "French Polynesia" },
  { code: "TF", country: "French Southern Territories" },
  { code: "GA", country: "Gabon" },
  { code: "GM", country: "Gambia" },
  { code: "GE", country: "Georgia" },
  { code: "DE", country: "Germany" },
  { code: "GH", country: "Ghana" },
  { code: "GI", country: "Gibraltar" },
  { code: "GR", country: "Greece" },
  { code: "GL", country: "Greenland" },
  { code: "GD", country: "Grenada" },
  { code: "GP", country: "Guadeloupe" },
  { code: "GU", country: "Guam" },
  { code: "GT", country: "Guatemala" },
  { code: "GG", country: "Guernsey" },
  { code: "GN", country: "Guinea" },
  { code: "GW", country: "Guinea-Bissau" },
  { code: "GY", country: "Guyana" },
  { code: "HT", country: "Haiti" },
  { code: "HM", country: "Heard Island and McDonald Islands" },
  { code: "VA", country: "Holy See" },
  { code: "HN", country: "Honduras" },
  { code: "HK", country: "Hong Kong" },
  { code: "HU", country: "Hungary" },
  { code: "IS", country: "Iceland" },
  { code: "IN", country: "India" },
  { code: "ID", country: "Indonesia" },
  { code: "IR", country: "Iran (Islamic Republic of)" },
  { code: "IQ", country: "Iraq" },
  { code: "IE", country: "Ireland" },
  { code: "IM", country: "Isle of Man" },
  { code: "IL", country: "Israel" },
  { code: "IT", country: "Italy" },
  { code: "JM", country: "Jamaica" },
  { code: "JP", country: "Japan" },
  { code: "JE", country: "Jersey" },
  { code: "JO", country: "Jordan" },
  { code: "KZ", country: "Kazakhstan" },
  { code: "KE", country: "Kenya" },
  { code: "KI", country: "Kiribati" },
  { code: "KP", country: "Korea (Democratic People's Republic of)" },
  { code: "KR", country: "Korea (Republic of)" },
  { code: "KW", country: "Kuwait" },
  { code: "KG", country: "Kyrgyzstan" },
  { code: "LA", country: "Lao People's Democratic Republic" },
  { code: "LV", country: "Latvia" },
  { code: "LB", country: "Lebanon" },
  { code: "LS", country: "Lesotho" },
  { code: "LR", country: "Liberia" },
  { code: "LY", country: "Libya" },
  { code: "LI", country: "Liechtenstein" },
  { code: "LT", country: "Lithuania" },
  { code: "LU", country: "Luxembourg" },
  { code: "MO", country: "Macao" },
  { code: "MK", country: "Macedonia (the former Yugoslav Republic of)" },
  { code: "MG", country: "Madagascar" },
  { code: "MW", country: "Malawi" },
  { code: "MY", country: "Malaysia" },
  { code: "MV", country: "Maldives" },
  { code: "ML", country: "Mali" },
  { code: "MT", country: "Malta" },
  { code: "MH", country: "Marshall Islands" },
  { code: "MQ", country: "Martinique" },
  { code: "MR", country: "Mauritania" },
  { code: "MU", country: "Mauritius" },
  { code: "YT", country: "Mayotte" },
  { code: "MX", country: "Mexico" },
  { code: "FM", country: "Micronesia (Federated States of)" },
  { code: "MD", country: "Moldova (Republic of)" },
  { code: "MC", country: "Monaco" },
  { code: "MN", country: "Mongolia" },
  { code: "ME", country: "Montenegro" },
  { code: "MS", country: "Montserrat" },
  { code: "MA", country: "Morocco" },
  { code: "MZ", country: "Mozambique" },
  { code: "MM", country: "Myanmar" },
  { code: "NA", country: "Namibia" },
  { code: "NR", country: "Nauru" },
  { code: "NP", country: "Nepal" },
  { code: "NL", country: "Netherlands" },
  { code: "NC", country: "New Caledonia" },
  { code: "NZ", country: "New Zealand" },
  { code: "NI", country: "Nicaragua" },
  { code: "NE", country: "Niger" },
  { code: "NG", country: "Nigeria" },
  { code: "NU", country: "Niue" },
  { code: "NF", country: "Norfolk Island" },
  { code: "MP", country: "Northern Mariana Islands" },
  { code: "NO", country: "Norway" },
  { code: "OM", country: "Oman" },
  { code: "PK", country: "Pakistan" },
  { code: "PW", country: "Palau" },
  { code: "PS", country: "Palestine, State of" },
  { code: "PA", country: "Panama" },
  { code: "PG", country: "Papua New Guinea" },
  { code: "PY", country: "Paraguay" },
  { code: "PE", country: "Peru" },
  { code: "PH", country: "Philippines" },
  { code: "PN", country: "Pitcairn" },
  { code: "PL", country: "Poland" },
  { code: "PT", country: "Portugal" },
  { code: "PR", country: "Puerto Rico" },
  { code: "QA", country: "Qatar" },
  { code: "RE", country: "Réunion" },
  { code: "RO", country: "Romania" },
  { code: "RU", country: "Russian Federation" },
  { code: "RW", country: "Rwanda" },
  { code: "BL", country: "Saint Barthélemy" },
  { code: "SH", country: "Saint Helena, Ascension and Tristan da Cunha" },
  { code: "KN", country: "Saint Kitts and Nevis" },
  { code: "LC", country: "Saint Lucia" },
  { code: "MF", country: "Saint Martin (French part)" },
  { code: "PM", country: "Saint Pierre and Miquelon" },
  { code: "VC", country: "Saint Vincent and the Grenadines" },
  { code: "WS", country: "Samoa" },
  { code: "SM", country: "San Marino" },
  { code: "ST", country: "Sao Tome and Principe" },
  { code: "SA", country: "Saudi Arabia" },
  { code: "SN", country: "Senegal" },
  { code: "RS", country: "Serbia" },
  { code: "SC", country: "Seychelles" },
  { code: "SL", country: "Sierra Leone" },
  { code: "SG", country: "Singapore" },
  { code: "SX", country: "Sint Maarten (Dutch part)" },
  { code: "SK", country: "Slovakia" },
  { code: "SI", country: "Slovenia" },
  { code: "SB", country: "Solomon Islands" },
  { code: "SO", country: "Somalia" },
  { code: "ZA", country: "South Africa" },
  { code: "GS", country: "South Georgia and the South Sandwich Islands" },
  { code: "SS", country: "South Sudan" },
  { code: "ES", country: "Spain" },
  { code: "LK", country: "Sri Lanka" },
  { code: "SD", country: "Sudan" },
  { code: "SR", country: "Suriname" },
  { code: "SJ", country: "Svalbard and Jan Mayen" },
  { code: "SE", country: "Sweden" },
  { code: "CH", country: "Switzerland" },
  { code: "SY", country: "Syrian Arab Republic" },
  { code: "TW", country: "Taiwan, Province of China" },
  { code: "TJ", country: "Tajikistan" },
  { code: "TZ", country: "Tanzania, United Republic of" },
  { code: "TH", country: "Thailand" },
  { code: "TL", country: "Timor-Leste" },
  { code: "TG", country: "Togo" },
  { code: "TK", country: "Tokelau" },
  { code: "TO", country: "Tonga" },
  { code: "TT", country: "Trinidad and Tobago" },
  { code: "TN", country: "Tunisia" },
  { code: "TR", country: "Turkey" },
  { code: "TM", country: "Turkmenistan" },
  { code: "TC", country: "Turks and Caicos Islands" },
  { code: "TV", country: "Tuvalu" },
  { code: "UG", country: "Uganda" },
  { code: "UA", country: "Ukraine" },
  { code: "AE", country: "United Arab Emirates" },
  { code: "GB", country: "United Kingdom" },
  { code: "US", country: "United States" },
  { code: "UM", country: "United States Minor Outlying Islands" },
  { code: "UY", country: "Uruguay" },
  { code: "UZ", country: "Uzbekistan" },
  { code: "VU", country: "Vanuatu" },
  { code: "VE", country: "Venezuela (Bolivarian Republic of)" },
  { code: "VN", country: "Viet Nam" },
  { code: "VG", country: "Virgin Islands (British)" },
  { code: "VI", country: "Virgin Islands (U.S.)" },
  { code: "WF", country: "Wallis and Futuna" },
  { code: "EH", country: "Western Sahara" },
  { code: "YE", country: "Yemen" },
  { code: "ZM", country: "Zambia" },
  { code: "ZW", country: "Zimbabwe" },
];

const netBankingName = [
  {
    name: "HDFC Bank",
    code: "HDFCR",
  },
  {
    name: "UTIB",
    code: "UTIBR",
  },
  {
    name: "ICIC",
    code: "ICICR",
  },
  {
    name: "SBIN",
    code: "SBINR",
  },
];

export const findNetBankingName = (bank: string) => {
  return netBankingName?.find((value: any) => value?.name === bank)?.code;
};

export const netbankingBankList = [
  {
    code: "AUBL",
    country: "AU Small Finance Bank",
  },
  {
    code: "UTIB",
    country: "Axis Bank",
  },
  {
    code: "BDBL",
    country: "Bandhan Bank",
  },
  {
    code: "BARB_R",
    country: "Bank of Baroda - Retail Banking",
  },
  {
    code: "BKID",
    country: "Bank of India",
  },
  {
    code: "BKID_C",
    country: "Bank of India - Corporate Banking",
  },
  {
    code: "MAHB",
    country: "Bank of Maharashtra",
  },
  {
    code: "CNRB",
    country: "Canara Bank",
  },
  {
    code: "CSBK",
    country: "Catholic Syrian Bank",
  },
  {
    code: "CBIN",
    country: "Central Bank of India",
  },
  {
    code: "CIUB",
    country: "City Union Bank",
  },
  {
    code: "DCBL",
    country: "DCB Bank",
  },
  {
    code: "DEUT",
    country: "Deutsche Bank",
  },
  {
    code: "DBSS",
    country: "Development Bank of Singapore",
  },
  {
    code: "DLXB",
    country: "Dhanlaxmi Bank",
  },
  {
    code: "ESFB",
    country: "Equitas Small Finance Bank",
  },
  {
    code: "FDRL",
    country: "Federal Bank",
  },
  {
    code: "ICIC",
    country: "ICICI Bank",
  },
  {
    code: "IBKL",
    country: "IDBI",
  },
  {
    code: "IDFB",
    country: "IDFC FIRST Bank",
  },
  {
    code: "IDIB",
    country: "Indian Bank",
  },
  {
    code: "ALLA",
    country: "Indian Bank (Erstwhile Allahabad Bank)",
  },
  {
    code: "IOBA",
    country: "Indian Overseas Bank",
  },
  {
    code: "INDB",
    country: "Indusind Bank",
  },
  {
    code: "JAKA",
    country: "Jammu and Kashmir Bank",
  },
  {
    code: "KARB",
    country: "Karnataka Bank",
  },
  {
    code: "KVBL",
    country: "Karur Vysya Bank",
  },
  {
    code: "KKBK",
    country: "Kotak Mahindra Bank",
  },
  {
    code: "LAVB_R",
    country: "Lakshmi Vilas Bank - Retail Banking",
  },
  {
    code: "PUNB_R",
    country: "Punjab National Bank - Retail Banking",
  },
  {
    code: "RATN",
    country: "RBL Bank",
  },
  {
    code: "SVCB",
    country: "SVC Co-Operative Bank Ltd.",
  },
  {
    code: "SRCB",
    country: "Saraswat Co-operative Bank",
  },
  {
    code: "SBIN",
    country: "State Bank of India",
  },
  {
    code: "SIBL",
    country: "South Indian Bank",
  },
  {
    code: "SCBL",
    country: "Standard Chartered Bank",
  },
  {
    code: "TMBL",
    country: "Tamilnad Mercantile Bank",
  },
  {
    code: "UCBA",
    country: "UCO Bank",
  },
  {
    code: "UJVN",
    country: "Ujjivan Small Finance Bank",
  },
  {
    code: "UBIN",
    country: "Union Bank of India",
  },
  {
    code: "CORP",
    country: "Union Bank of India (Erstwhile Corporation Bank)",
  },
  {
    code: "YESB",
    country: "Yes Bank",
  },
];
export const getCurrentIP = async () => {
  return await axios.get("https://geolocation-db.com/json/");
};

export function getParameterByName(name: string, url: string) {
  // eslint-disable-next-line no-useless-escape
  name = name.replace(/[\[\]]/g, "\\$&");
  const regex = new RegExp("[?&]" + name + "(=([^&#]*)|&|#|$)"),
    results = regex.exec(url);
  if (!results) return null;
  if (!results[2]) return "";
  return decodeURIComponent(results[2].replace(/\+/g, " "));
}
export const decryptData = (dataToEncrypt: string) => {
  const aes = new sjcl.cipher.aes(sjcl.codec.hex.toBits(Key));
  const decryptedData = sjcl.mode.ccm.decrypt(
    aes,
    sjcl.codec.hex.toBits(dataToEncrypt),
    sjcl.codec.utf8String.toBits("moneyhop_123456789"),
  );
  const res = decryptedData && sjcl.codec.utf8String.fromBits(decryptedData);
  return typeof res === "string" && res !== "" ? JSON.parse(res) : {};
};

export const encryptData = (dataToEncrypt: string) => {
  const aes = new sjcl.cipher.aes(sjcl.codec.hex.toBits(Key)); //cipher key   //string

  const encryptedData: any = sjcl.mode.ccm.encrypt(
    aes,
    sjcl.codec.utf8String.toBits(dataToEncrypt),
    sjcl.codec.utf8String.toBits("moneyhop_123456789"),
  );
  const res = sjcl.codec.hex.fromBits(encryptedData);
  return res;
};

export const convertToTwoDigit = (x: any) => {
  return Math.round(x * 100) / 100;
};

export const USERDETAILS_DATA = {
  nationality: [
    {
      value: "Indian",
      label: "Indian",
    },
  ],
  industryType: [
    {
      value: "Entertainment",
      label: "Entertainment",
    },
    {
      value: "Agriculture",
      label: "Agriculture",
    },
    {
      value: "Information Technology",
      label: "Information Technology",
    },
    {
      value: "Legal",
      label: "Legal",
    },
    {
      value: "Finance",
      label: "Finance",
    },
    {
      value: "Business Consultancy",
      label: "Business Consultancy",
    },
    {
      value: "Charity",
      label: "Charity",
    },
    {
      value: "Computer - Hardware/software/electronics",
      label: "Computer - Hardware/software/electronics",
    },
    {
      value: "Education",
      label: "Education",
    },
    {
      value: "Food & Beverages",
      label: "Food & Beverages",
    },
    {
      value: "Furniture & Flooring",
      label: "Furniture & Flooring",
    },
    {
      value: "Manufacturing/Construction",
      label: "Manufacturing/Construction",
    },
    {
      value: "Insurance",
      label: "Insurance",
    },
    {
      value: "Marketing or Media",
      label: "Marketing or Media",
    },
    {
      value: "Mining & Energy",
      label: "Mining & Energy",
    },
    {
      value: "Chemical",
      label: "Chemical",
    },
    {
      value: "Online Gaming/ Betting",
      label: "Online Gaming/ Betting",
    },
    {
      value: "Recruitment/Staffing",
      label: "Recruitment/Staffing",
    },
    {
      value: "Retail/Wholesale Trade",
      label: "Retail/Wholesale Trade",
    },
    {
      value: "Security",
      label: "Security",
    },
    {
      value: "Telecom",
      label: "Telecom",
    },
    {
      value: "Transportation",
      label: "Transportation",
    },
    {
      value: "Healthcare",
      label: "Healthcare",
    },
    {
      value: "Textiles",
      label: "Textiles",
    },
    {
      value: "Others",
      label: "Others",
    },
  ],
  states: [
    {
      value: "ANDHRA PRADESH",
      label: "ANDHRA PRADESH",
    },
    {
      value: "ARUNACHAL PRADESH",
      label: "ARUNACHAL PRADESH",
    },
    {
      value: "ASSAM",
      label: "ASSAM",
    },
    {
      value: "BIHAR",
      label: "BIHAR",
    },
    {
      value: "CHHATTISGARH",
      label: "CHHATTISGARH",
    },
    {
      value: "GOA",
      label: "GOA",
    },
    {
      value: "GUJARAT",
      label: "GUJARAT",
    },
    {
      value: "HARYANA",
      label: "HARYANA",
    },
    {
      value: "HIMACHAL PRADESH",
      label: "HIMACHAL PRADESH",
    },
    {
      value: "JAMMU AND KASHMIR",
      label: "JAMMU AND KASHMIR",
    },
    {
      value: "JHARKHAND",
      label: "JHARKHAND",
    },
    {
      value: "KARNATAKA",
      label: "KARNATAKA",
    },
    {
      value: "KERALA",
      label: "KERALA",
    },
    {
      value: "MADHYA PRADESH",
      label: "MADHYA PRADESH",
    },
    {
      value: "MAHARASHTRA",
      label: "MAHARASHTRA",
    },
    {
      value: "MANIPUR",
      label: "MANIPUR",
    },
    {
      value: "MEGHALAYA",
      label: "MEGHALAYA",
    },
    {
      value: "MIZORAM",
      label: "MIZORAM",
    },
    {
      value: "NAGALAND",
      label: "NAGALAND",
    },
    {
      value: "ODISHA",
      label: "ODISHA",
    },
    {
      value: "PUNJAB",
      label: "PUNJAB",
    },
    {
      value: "RAJASTHAN",
      label: "RAJASTHAN",
    },
    {
      value: "SIKKIM",
      label: "SIKKIM",
    },
    {
      value: "TAMILNADU",
      label: "TAMILNADU",
    },
    {
      value: "TELANGANA",
      label: "TELANGANA",
    },
    {
      value: "TRIPURA",
      label: "TRIPURA",
    },
    {
      value: "UTTARAKHAND",
      label: "UTTARAKHAND",
    },
    {
      value: "UTTAR PRADESH",
      label: "UTTAR PRADESH",
    },
    {
      value: "WEST BENGAL",
      label: "WEST BENGAL",
    },
    {
      value: "ANDAMAN AND NICOBAR ISLANDS",
      label: "ANDAMAN AND NICOBAR ISLANDS",
    },
    {
      value: "CHANDIGARH",
      label: "CHANDIGARH",
    },
    {
      value: "DADRA AND NAGAR HAVELI",
      label: "DADRA AND NAGAR HAVELI",
    },
    {
      value: "DAMAN AND DIU",
      label: "DAMAN AND DIU",
    },
    {
      value: "DELHI",
      label: "DELHI",
    },
    {
      value: "LAKSHADWEEP",
      label: "LAKSHADWEEP",
    },
    {
      value: "PUDUCHERRY",
      label: "PUDUCHERRY",
    },
  ],

  fundSource: [
    {
      value: "Salary Or Wages",
      label: "Salary Or Wages",
    },
    {
      value: "Student Loan",
      label: "Student Loan",
    },
    {
      value: "Investments",
      label: "Investments",
    },
    {
      value: "Personal Savings",
      label: "Personal Savings",
    },
    {
      value: "Personal Wealth",
      label: "Personal Wealth",
    },
    {
      value: "Retirement Funds",
      label: "Retirement Funds",
    },
    {
      value: "Business Owner/ShareHolder",
      label: "Business Owner/ShareHolder",
    },
  ],
  employmentStatus: [
    {
      value: "Employed",
      label: "Employed",
    },
    {
      value: "Self Employed",
      label: "Self Employed",
    },
    {
      value: "Unemployed",
      label: "Unemployed",
    },
    {
      value: "Student",
      label: "Student",
    },
    {
      value: "Retired",
      label: "Retired",
    },
  ],
  gender: [
    {
      value: "Male",
      label: "Male",
    },
    {
      value: "Female",
      label: "Female",
    },
    {
      value: "Others",
      label: "Others",
    },
  ],
};
