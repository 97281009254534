/* eslint-disable */
// import { AppArtwork } from "../../assets/Icons";
import React, { useState } from "react";
import { AppCard } from "../../components/ui/AppCard";
import { sendAppLink } from "../../services/appPage";
import { TextField } from "@mui/material";

export const HopApp = () => {
  const [phoneNumber, setPhoneNumber] = useState<number>();
  console.log("====================================");
  console.log(phoneNumber);
  console.log("====================================");
  const sendLink = () => {
    const body = {
      phoneNumber: phoneNumber,
    };
    sendAppLink(body).then((response) => {
      console.log("hahaha", response);
    });
  };

  return (
    <div className="px-10 py-4">
      <div id="divider" className="flex w-full mt-8 border-b-2 border-blue-400"></div>
      <div id="text" className="flex flex-col w-full mt-10">
        <span className="text-[26px] font-bold">Hop App</span>
        <span className="mt-2 text-[22px] font-ssp">
          HOPApp is India&apos;s first cross-border, neo banking application that enables smarter
          and faster cross-border banking. HOPApp lets users Send and Spend seamlessly in INR as
          well as foreign currencies.
        </span>
      </div>
      <div id="hero" className="flex items-center justify-between w-full mt-10">
        <div className="flex flex-col w-2/3">
          <div id="line1" className="flex items-center w-full justify-evenly">
            <div className="flex items-center min-w-1/4 max-w-1/4 py-5 transition-all bg-[#0F5BF0] justify-evenly h-[150px] rounded-xl hover:scale-105 my-3 px-5 cursor-pointer group">
              <div className="z-50 transition-all group-hover:scale-125">
                <img src={`/images/hopapp/appGrid1.png`} alt="" />
              </div>
            </div>
            <AppCard image="appGrid2.png" title="Instant, Digital, Paperless Onboarding" />
            <AppCard image="appGrid3.png" title="Multicurrency Account Linked Card" />
          </div>
          <div id="line2" className="flex items-center w-full justify-evenly">
            <AppCard image="appGrid4.png" title="Single-Tap Currency exchange" />
            <AppCard image="appGrid5.png" title="International & Domestic Money Transfers" />
            <AppCard image="appGrid6.png" title="Smart Card Controls" />
          </div>
          <div id="line3" className="flex items-center w-full justify-evenly">
            <AppCard image="appGrid7.png" title="Zero Balance Account" />
            <AppCard image="appGrid8.png" title="Free ATM Withdrawals" />
            <AppCard image="appGrid9.png" title="Exciting Cashback and Rewards" />
          </div>
        </div>
        <div className="w-1/3">
          {/* <AppArtwork /> */}
          <img src="/images/png/AppArt.png" />
        </div>
      </div>
      <div id="divider" className="flex w-full mt-10 border-b-2 border-blue-400"></div>
      <div className="flex items-center justify-between mt-10">
        <div className="flex flex-col">
          <span className="text-[22px] font-bold">Download App Now</span>
          <span className="text-[20px]">
            India&apos;s first cross-border, new banking application
          </span>
        </div>
        <div id="field" className="flex items-center space-x-10">
          {/* <input
            placeholder="Enter your mobile number"
            className="px-10 py-2 border-2 border-blue-600 rounded-xl"
            value={phoneNumber}
            onChange={(e: any) => setPhoneNumber(e)}
          /> */}
          <TextField
            label="Enter your mobile number"
            className="px-10 py-2 border-2 border-blue-600 rounded-xl"
            value={phoneNumber}
            onChange={(e: any) => setPhoneNumber(e.target.value)}
          />
          <button onClick={sendLink} className="px-10 py-2 text-white bg-blue-600 rounded-xl">
            Get App Link
          </button>
        </div>
        <div className="flex flex-col">
          <span className="text-[22px] font-bold">Payment Partner</span>
          <img src="/images/hopapp/sbmLogo.png" alt="" />
        </div>
        <div className="flex flex-col">
          <span className="text-[22px] font-bold">Network Partner</span>
          <img src="/images/hopapp/visaLogo.png" alt="" className="scale-75" />
        </div>
      </div>
    </div>
  );
};
