import React from "react";
import { IIcons } from "./icons";

const VerifiedIcon = ({ fillColor }: IIcons.IconProps) => (
  <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      d="M10 20C4.48649 20 0 15.5135 0 10C0 4.48649 4.48649 0 10 0C15.5135 0 20 4.48649 20 10C20 15.5135 15.5135 20 10 20Z"
      fill="#46DE52"
    />
    <path d="M5.24316 10.0537L8.3783 13.1888L15.5134 6.05371" fill={fillColor || "#46DE52"} />
    <path d="M5.24316 10.0537L8.3783 13.1888L15.5134 6.05371" stroke="white" strokeWidth="3" />
  </svg>
);
export default VerifiedIcon;
