import React from "react";
import { IIcons } from "./icons";

const PassportIcon = ({ width, height, fillColor }: IIcons.IconProps) => (
  <svg
    width={width || "39"}
    height={height || "50"}
    viewBox="0 0 39 50"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M3.9 0C1.74525 0 0 1.72115 0 3.84615V46.1538C0 48.2788 1.74525 50 3.9 50H35.1C37.2547 50 39 48.2788 39 46.1538V3.84615C39 1.72115 37.2547 0 35.1 0H3.9ZM19.5 5.76923C27.0387 5.76923 33.15 11.7962 33.15 19.2308C33.15 26.6654 27.0387 32.6923 19.5 32.6923C11.9613 32.6923 5.85 26.6654 5.85 19.2308C5.85 11.7962 11.9613 5.76923 19.5 5.76923ZM16.8416 9.61538C16.8416 9.61538 9.75 12.2385 9.75 19.2308C9.75 27.9712 19.5 28.8462 19.5 28.8462L16.8111 26.2583L16.8416 22.7276L14.1832 20.1059H11.521V18.3594L15.9542 15.7339V13.9874H17.7252L18.6126 14.8625L20.3836 13.9874V9.61538H18.6126V12.2371H16.8416V9.61538ZM25.5176 11.7075L23.9332 12.2371V13.1122H23.0458L22.1584 13.9874V15.7377L23.9332 14.8625L26.5916 15.7377L25.7042 17.4842L23.9332 16.6091H22.1584L20.3874 18.3594V20.1059L22.1584 21.8525H23.9332L24.8206 23.6028L22.1584 28.8462C22.1584 28.8462 29.2519 26.2231 29.2538 19.2308C29.2538 14.8615 25.5176 11.7075 25.5176 11.7075ZM11.7 38.4615H27.3C28.3764 38.4615 29.25 39.3231 29.25 40.3846C29.25 41.4462 28.3764 42.3077 27.3 42.3077H11.7C10.6236 42.3077 9.75 41.4462 9.75 40.3846C9.75 39.3231 10.6236 38.4615 11.7 38.4615Z"
      fill="#3B74FA"
      className={fillColor}
    />
  </svg>
);
export default PassportIcon;
