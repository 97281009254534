/* eslint-disable */
import { Box, Modal, TextField } from "@mui/material";
import React, { useEffect, useMemo, useState } from "react";
import { CloseIcon, EyeIcon, LaptopPrime, MoneyHopLogoWhite } from "../../assets/Icons";
import { primeGenerateOrder, primePaymentResponse } from "../../services/prime";
import { PrimeFeatures } from "./PrimeFeatures";
import { RegularFeatures } from "./Regular";
import PrimeComputerIcon from "../../assets/images/PrimeComputerLogo.png";
import SuccessImage from "../../assets/images/SuccessImage.png";
import FailureImage from "../../assets/images/FailureImage.png";
import { useRecoilValue } from "recoil";
import { userDetails } from "../../store";
import { customAlert } from "../../utils/customAlert";

declare global {
  interface Window {
    Razorpay?: any;
  }
}

export const PrimePage = () => {
  const [isModalOpen, setIsModalOpen] = useState<string>("");
  const [upiId, setUpiId] = useState<string>("");
  const [loading, setLoading] = useState(false);
  const [upiBackdrop, setUpiBackdrop] = useState<boolean>(false);
  const [paymentSuccess, setPaymentSuccess] = useState<boolean>(false);
  const [paymentFailure, setPaymentFailure] = useState<boolean>(false);
  const userData = useRecoilValue(userDetails);

  const queryParameters = new URLSearchParams(window.location.search);
  const cashfreeOrderId = queryParameters.get("order_id");

  const style = {
    position: "absolute" as "absolute",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    boxShadow: 24,
  };

  const buttonDisable = useMemo(() => {
    if (upiId.includes("@")) {
      return false;
    } else {
      return true;
    }
  }, [upiId]);

  useEffect(() => {
    if (cashfreeOrderId) {
      const body: any = {
        razorpay_order_id: cashfreeOrderId,
        razorpay_payment_id: "",
        isRazorPay: false,
        razorpay_signature: "",
      };
      primePaymentResponse(body)
        .then((response: any) => {
          if (response.status === 200) {
            setPaymentSuccess(true);
            setPaymentFailure(false);
            setIsModalOpen("success");
          }
        })
        .catch((error: any) => {
          setPaymentSuccess(false);
          setPaymentFailure(true);
        });
    }
  },[cashfreeOrderId]);

  const initCash = (data: any) => {
    setLoading(false);
    let paymentBtn: any = document.getElementById("pay-collect");
    paymentBtn.disabled = false;
    let cashfree = window.Cashfree({ mode: `${process.env.REACT_APP_CASHFREE_ENV}` });
    let opt = {
      values: {
        upiId: upiId,
      },
    };
    let component = cashfree.create("upiCollect", opt);
    component.mount(document.getElementById("vpainput"));

    function toggleBtn() {
      if (component.isComplete()) {
        paymentBtn.disabled = false;
      } else {
        paymentBtn.disabled = true;
      }
    }
    component.on("change", function (data: any) {
      toggleBtn();
    });

    let paymentOptions = {
      paymentMethod: component,
      paymentSessionId: data?.session_id,
      // returnUrl: "https://test.cashfree.com/pgappsdemos/v3success.php?myorder={order_id}",
    };

    paymentBtn.addEventListener("click", function () {
      paymentBtn.disabled = true;
      setUpiBackdrop(false);
      cashfree
        .pay(paymentOptions)
        .then((data: any) => {
          data?.error && customAlert(data?.error?.message);
        })
        .catch((error: any) => {
          console.error(error);
        });
    });
  };

  const handlePayNowClicked = () => {
    setLoading(true);
    primeGenerateOrder()
      .then((response: any) => {
        console.log(response, "orderAPI response...");
        const data = {
          amount: response?.data?.amount,
          currency: "INR",
          order_id: response?.data?.orderID,
          notes: {
            address: "moneyHOP HQ Bengaluru",
          },
          method: "upi",
          vpa: upiId,
          contact: "7700072670",
          email: "akshit@moenyhop.co",
          session_id: response?.data?.order,
        };
        setIsModalOpen("");
        setUpiBackdrop(true);
        initCash(data);
      })
      .catch((error) => {
        console.error(error);
      })
      .finally(() => {
        setLoading(false);
      });
  };

  return (
    <div className="flex flex-col px-6 py-2 bg-[#ECF2FF] pb-20">
      <div className="w-full mt-3 border-b-4 border-blue-200"></div>
      <div className="flex flex-col items-center w-full px-5 mt-16 lg:flex-row lg:px-10 lg:justify-evenly">
        <div className="flex items-center space-x-5">
          <div style={{ zoom: "69%" }} className="flex lg:hidden">
            <RegularFeatures />
          </div>
          <div style={{ zoom: "69%" }} className="flex lg:hidden">
            <PrimeFeatures />
          </div>
          <div className="flex">
            <div className="w-[50%]">
              <div>
                <span className="font-bold text-[23px]">What is HOPPrime?</span>
                <span
                  onClick={() => window.open("https://moneyhop.co/hopprime")}
                  className="text-[17px] ml-4 text-blue-500 group-hover:text-[16px] font-bold transition-all cursor-pointer"
                >
                  {"Know More >"}
                </span>
                <div className="text-[12px] lg:text-[20px] mt-2 font-bold">
                  One subscription for best international money transfer experience for a year. No
                  extra charges on sending money abroad.
                </div>
              </div>
              <div className="flex flex-row justify-center mt-10">
                <div className="hidden lg:flex mx-10">
                  <RegularFeatures />
                </div>
                <div className="hidden lg:flex mx-10">
                  <PrimeFeatures />
                </div>
              </div>
            </div>
            <div className="w-[100%] hidden lg:flex flex-col items-center">
              <div className="flex flex-col items-center">
                <h1 className="text-[#1D428B] font-bold text-2xl mb-5">Introducing HOP Prime</h1>
                <h1 className="text-[#326CDE] font-medium text-2xl mb-5">
                  Send Money Abroad At Zero Extra Charges For A Year!
                </h1>
                <ul className="flex items-start list-disc">
                  <li className="text-[#1D428B] font-bold text-2xl mx-10">One Membership</li>
                  <li className="text-[#1D428B] font-bold text-2xl mx-10">Unlimited Savings</li>
                </ul>
              </div>
              <div>
                <img width={800} className="mb-40" src={PrimeComputerIcon} />
              </div>
            </div>
          </div>
        </div>
        {/* <div style={{ zoom: "69%" }} id="image" className="flex lg:hidden">
          <svg xmlns="http://www.w3.org/2000/svg" width="568" height="568" viewBox="0 0 1080 858">
            <LaptopPrime />
          </svg>
        </div>
        <div className="hidden lg:flex">
          <svg xmlns="http://www.w3.org/2000/svg" width="568" height="568" viewBox="0 0 1080 858">
            <LaptopPrime />
          </svg>
        </div> */}
      </div>
      <div className="w-full text-center py-6 text-lg font-bold">
        For more details reach out to us via email at hello@moneyhop.co or call us at +91 6364001001
      </div>
      <div className="w-full my-5 border-b-4 border-blue-200"></div>
      <div
        id="footer"
        className="flex flex-col items-center w-full px-8 mt-5 space-x-5 lg:flex-row justify-evenly"
      >
        <div className="flex items-center space-x-3 lg:space-x-10">
          <div className="flex flex-col items-start transition-all cursor-pointer lg:px-2 group hover:scale-105">
            <span className="font-extrabold text-gray-400 text-[18px] lg:text-[28px] group-hover:text-gray-500 transition-all lg:group-hover:translate-x-2">
              Saved
            </span>
            <span className="font-semibold text-[15px] lg:text-[25px] group-hover:translate-x-4 transition-all">
              ₹ 20,000
            </span>
            <span className="font-semibold text-[10px] lg:text-[14px] transition-all group-hover:translate-x-1">
              Our prime user saves annually.
            </span>
          </div>
          <div id="divider" className="border-r-2 border-gray-400 h-[60px]"></div>
          <div className="flex flex-col items-start px-5 transition-all cursor-pointer group hover:scale-105">
            <span className="font-extrabold text-gray-400 text-[18px] lg:text-[28px] group-hover:text-gray-500 transition-all group-hover:translate-x-2">
              Processed
            </span>
            <span className="font-semibold text-[15px] lg:text-[25px] group-hover:translate-x-4 transition-all">
              ₹2,000Cr+
            </span>
            <span className="font-semibold text-[10px] lg:text-[14px] transition-all group-hover:translate-x-1">
              transaction and counting...
            </span>
          </div>
        </div>
        <div id="divider" className="hidden lg:flex border-r-2 border-gray-400 h-[60px] my-5"></div>
        <div className="text-[14px] my-4 font-bold lg:my-0 lg:text-[24px] font-ssp max-w-[500px]">
          Become a prime member and enjoy unlimited transaction at zero fees.
        </div>
        {!userData?.isPrime && <button
            onClick={() => setIsModalOpen("upi")}
            className="font-bold px-16 py-2 text-white text-[25px] scale-75 lg:scale-100 rounded-full bg-primary hover:bg-white border-2 border-blue-400 hover:text-primary hover:border-2 hover:border-primary transition-all hover:shadow-2xl hover:scale-105"
          >
            Pay Now
          </button>}
      </div>
      <Modal
        open={isModalOpen === "upi"}
        onClose={() => {
          setIsModalOpen("");
        }}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
        // className="rounded-lg"
      >
        <Box
          sx={{
            position: "absolute" as "absolute",
            top: "50%",
            left: "50%",
            transform: "translate(-50%, -50%)",
            width: "auto",
            bgcolor: "background.paper",
            borderRadius: "50%",
            boxShadow: 24,
          }}
        >
          <div className="flex flex-col w-full rounded-lg h-auto bg-white-100">
            <div
              id="header"
              className="flex px-5 items-center rounded-t-lg w-full justify-between bg-primary-200"
            >
              <MoneyHopLogoWhite />
              <div
                className="cursor-pointer"
                onClick={() => {
                  setIsModalOpen("");
                }}
              >
                <CloseIcon />
              </div>
            </div>
            {/* <div className="px-5 py-5">
              <span className="font-bold text-lg ml-2">Pay with UPI QR</span>
              <div className="flex self-center justify-center mt-5">
                <div className="border flex items-center justify-between rounded-lg border-black-250 px-5">
                  <div className="py-16 w-[45%]">
                    <p className="font-semibold text-base">
                      Scan the QR using any UPI app on your phone.
                    </p>
                    <img src="/images/png/weAcceptUpi.png" width={100} className="scale-60 py-3" />
                    <p className="font-medium text-base text-left">
                      QR Code is valid for 11:46 minutes.
                    </p>
                  </div>
                  <div className="flex">
                    <img src={QRCodeImage} className="opacity-40" alt="" />
                    {!showQr && (
                      <button 
                        className=" cursor-pointer absolute right-[65px] px-5 top-60 flex items-center justify-center bg-black bg-opacity-75 text-white text-lg font-bold"
                      >
                        Show QR Code
                      </button>
                    )}
                  </div>
                </div>
              </div>
            </div> */}
            <div className="px-5 py-5 w-[70%]">
              <span className="font-bold text-xl ml-2">Pay via UPI ID</span>
              <div className="my-5">
                <span className="font-semibold text-base ml-2">UPI ID/Mobile Number</span>
              </div>
              <div className="w-full flex justify-start">
                <TextField
                  variant="outlined"
                  label="Enter UPI Id/Number"
                  type="text"
                  value={upiId}
                  onChange={(e: any) => setUpiId(e.target.value)}
                  className="w-[80%] px-5 py-5 bg-white border border-black rounded-lg"
                  inputProps={{ pattern: "^.+@.+$" }}
                />
              </div>
              <div className="my-5">
                <span>
                  Facing any issue? Reach out to our customer support{" "}
                  <span className="font-bold">care@moneyhop.co</span> or call us at
                  <span className="font-bold">+91 6364001001</span>
                </span>
              </div>
            </div>
            <div className="flex rounded-b-xl items-center justify-between bg-[#FFFFFF] px-20 py-5">
              <div>
                <h2 className="font-extrabold text-3xl">₹5,999</h2>
                <span className="font-normal text-[14px]">View Details</span>
              </div>
              <div>
                <button
                  disabled={buttonDisable}
                  onClick={handlePayNowClicked}
                  className={`font-semibold px-16 py-2 text-white text-[18px] rounded-full ${
                    buttonDisable ? "bg-gray-500" : "bg-primary"
                  }`}
                >
                  {loading ? (
                    <div className="flex px-7 py-0.5 items-center justify-center animate-spin-slow">
                      <img src="/images/svg/smallLoader.svg" alt="" />
                    </div>
                  ) : (
                    "Pay Now"
                  )}
                </button>
              </div>
            </div>
          </div>
        </Box>
      </Modal>
      <Modal
        open={isModalOpen === "success"}
        onClose={() => setIsModalOpen("")}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
        className="rounded-lg"
      >
        <Box sx={style}>
          <div className="flex flex-col items-center rounded-lg w-full bg-white-100 justify-evenly">
            <div
              id="header"
              className="flex px-5 items-center rounded-t-lg w-full justify-between bg-primary-200"
            >
              <MoneyHopLogoWhite />
              <div
                className="cursor-pointer"
                onClick={() => {
                  setIsModalOpen("");
                }}
              >
                <CloseIcon />
              </div>
            </div>
            <div className="my-10">
              <p className="text-xl text-black font-bold text-center">
                {paymentSuccess && 'Great Hopper! you are now a PRIME MEMBER'}
                {paymentFailure && 'SOMETHING WENT WRONG'}
              </p>
              <p className="text-xl text-black font-normal text-center">
                {paymentSuccess && 'Enjoy unlimited zero fees on all your transaction'}
                {paymentFailure && 'Transaction Failed !'}
              </p>
            </div>
            {paymentSuccess && <img src={SuccessImage} alt="" />}
            {paymentFailure && <img src={FailureImage} alt="" />}
            <div className="my-10 flex justify-center">
              <div className="text-center w-4/6">
                {paymentSuccess && <span>Facing any issue ?</span>}
                <span> Reach out to our customer support{" "}</span>
                <span className="font-bold">care@moneyhop.co</span> or call us at
                <span className="font-bold"> +91 6364001001</span>
                {paymentFailure && <span> for any queries.</span>}
              </div>
            </div>
            <div className="flex justify-center rounded-t-xl w-full bg-[#ffffff] py-5">
              <button
                onClick={() => setIsModalOpen("")}
                className="px-10 py-3 text-white flex self-center rounded-lg bg-primary fonr-bold"
              >
                Go to Dashboard
              </button>
            </div>
          </div>
        </Box>
      </Modal>
      <div
        className=" justify-center items-center"
        style={{
          position: "fixed",
          left: "0px",
          top: "0px",
          height: "100%",
          width: "100%",
          display: upiBackdrop ? "block" : "none",
          backgroundColor: "rgba(0,0,0,0.5)",
        }}
      >
        <div
          style={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            height: "100vh",
            width: "100%",
          }}
        >
          <div className="bg-white flex justify-center items-center rounded-lg shadow-lg p-8 w-1/4">
            <div className="mb-2">
              <h4 className="text-lg font-bold"></h4>
            </div>
            <div className="w-full flex flex-col justify-center items-center">
              <div className="flex rounded-lg items-center w-fit justify-center py-2 bg-primary-200 lg:space-x-20">
                <MoneyHopLogoWhite />
              </div>
              <div className="flex items-center w-full mb-4 justify-center mt-5 py-2">
                <div id="vpainput" className="w-0 h-0"></div>
                <span className="text-center text-lg">
                  Press continue to receive payment request in your UPI app.
                </span>
              </div>
              <div className="flex justify-center w-full py-2">
                <button
                  className="px-8 py-3 disabled:bg-grey-150 text-white transition-all rounded-full bg-primary-200 hover:bg-white hover:text-primary-200 hover:border-2 hover:border-primary-200"
                  id="pay-collect"
                  type="button"
                >
                  <span className="font-semibold mt-3">Continue</span>
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};
