import React, { ReactNode, useState } from "react";
import UpArrow from "../../../assets/svg/UpArrow.svg";
import DownArrow from "../../../assets/svg/DownArrow.svg";

interface AccordionDataProps {
  key: number;
  data: {
    question: string;
    answer: ReactNode | (() => ReactNode);
  };
  showNumber: boolean;
  number: number;
}

// RewardAccordion Component
export const NewAccordion: React.FC<AccordionDataProps> = ({ key, data, showNumber, number }) => {
  const [isOpen, setIsOpen] = useState(false);

  const toggleAccordion = () => {
    setIsOpen(!isOpen);
  };

  const renderAnswer = () => {
    if (typeof data.answer === "function") {
      return data.answer();
    }
    return data.answer;
  };

  return (
    <div key={key} className="coins-faq py-4 rounded-2xl">
      <div className="accordion flex items-center relative shadow-lg bg-white rounded-2xl flex-col justify-between">
        <div
          className="accordion-header px-4 flex w-full justify-between bg-[#006EFF14] p-4 rounded-2xl cursor-pointer"
          onClick={toggleAccordion}
        >
          <p className="text-lg font-medium px-4">{`${showNumber? number+".":""} ${data.question}`}</p>
          <img src={isOpen ? UpArrow : DownArrow} className="px-4" alt="Toggle icon" />
        </div>
        <div
          className={`accordion-content overflow-hidden transition-all w-full duration-300 ${
            isOpen ? "max-h-96" : "max-h-0"
          }`}
        >
          <div className="content w-full rounded-b-2xl bg-white p-4 px-8 text-base font-medium">
            <div>{renderAnswer()}</div>
          </div>
        </div>
      </div>
    </div>
  );
};
