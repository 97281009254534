import React from "react";
import { IIcons } from "./icons";

const CoinStar = ({ fillColor }: IIcons.IconProps) => {
  return (
    <svg
      width="36"
      height="36"
      viewBox="0 0 36 36"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      className={fillColor}
    >
      <path
        d="M0 17V19C0 28.389 8.059 36 18 36C27.941 36 36 28.389 36 19V17H0Z"
        fill="url(#paint0_linear_885_1088)"
      />
      <path
        d="M18 33.056C27.3888 33.056 35 25.8675 35 17C35 8.13252 27.3888 0.944 18 0.944C8.61116 0.944 1 8.13252 1 17C1 25.8675 8.61116 33.056 18 33.056Z"
        fill="url(#paint1_linear_885_1088)"
      />
      <path
        d="M1 18C1 9.133 8.611 1.944 18 1.944C27.389 1.944 35 9.133 35 18"
        stroke="url(#paint2_linear_885_1088)"
        strokeWidth="2"
      />
      <path
        d="M18 2C26.822 2 34 8.729 34 17C34 25.271 26.822 32 18 32C9.178 32 2 25.271 2 17C2 8.729 9.178 2 18 2ZM18 0C8.059 0 0 7.611 0 17C0 26.389 8.059 34 18 34C27.941 34 36 26.389 36 17C36 7.611 27.941 0 18 0Z"
        fill="url(#paint3_linear_885_1088)"
      />
      <path
        d="M18.6631 7.958L20.7001 13.966H27.2001C27.349 13.9661 27.4939 14.0138 27.6139 14.1019C27.7339 14.1901 27.8227 14.3142 27.8673 14.4563C27.9119 14.5984 27.91 14.751 27.8619 14.8919C27.8139 15.0328 27.7222 15.1548 27.6001 15.24L22.3671 18.889L24.3811 24.838C24.4287 24.9776 24.4309 25.1287 24.3875 25.2697C24.3441 25.4107 24.2573 25.5344 24.1394 25.6231C24.0215 25.7119 23.8786 25.7611 23.7311 25.7637C23.5836 25.7664 23.4391 25.7224 23.3181 25.638L18.0001 21.931L12.6851 25.637C12.564 25.7214 12.4195 25.7654 12.272 25.7627C12.1245 25.7601 11.9816 25.7109 11.8637 25.6221C11.7458 25.5334 11.659 25.4097 11.6156 25.2687C11.5722 25.1277 11.5745 24.9766 11.6221 24.837L13.6361 18.888L8.40005 15.24C8.27795 15.1548 8.18621 15.0328 8.13816 14.8919C8.09011 14.751 8.08825 14.5984 8.13285 14.4563C8.17746 14.3142 8.2662 14.1901 8.38619 14.1019C8.50617 14.0138 8.65115 13.9661 8.80005 13.966H15.3001L17.3341 7.958C17.3803 7.81831 17.4694 7.69676 17.5887 7.61061C17.708 7.52446 17.8514 7.47809 17.9986 7.47809C18.1457 7.47809 18.2891 7.52446 18.4084 7.61061C18.5277 7.69676 18.6168 7.81831 18.6631 7.958Z"
        fill="url(#paint4_linear_885_1088)"
      />
      <defs>
        <linearGradient
          id="paint0_linear_885_1088"
          x1="0"
          y1="26.5"
          x2="36"
          y2="26.5"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#CC6700" />
          <stop offset="0.116" stopColor="#CE7507" />
          <stop offset="0.351" stopColor="#D38C13" />
          <stop offset="0.5" stopColor="#D49417" />
          <stop offset="1" stopColor="#CC6700" />
        </linearGradient>
        <linearGradient
          id="paint1_linear_885_1088"
          x1="6.308"
          y1="28.692"
          x2="29.692"
          y2="5.308"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#E07000" />
          <stop offset="0.144" stopColor="#E38309" />
          <stop offset="0.362" stopColor="#E79A15" />
          <stop offset="0.5" stopColor="#E8A219" />
          <stop offset="1" stopColor="#E07000" />
        </linearGradient>
        <linearGradient
          id="paint2_linear_885_1088"
          x1="1"
          y1="250.362"
          x2="35"
          y2="250.362"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#CC6700" />
          <stop offset="0.116" stopColor="#CE7507" />
          <stop offset="0.351" stopColor="#D38C13" />
          <stop offset="0.5" stopColor="#D49417" />
          <stop offset="1" stopColor="#CC6700" />
        </linearGradient>
        <linearGradient
          id="paint3_linear_885_1088"
          x1="5.621"
          y1="29.379"
          x2="30.379"
          y2="4.621"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#F2AD00" />
          <stop offset="0.124" stopColor="#F6C43B" />
          <stop offset="0.249" stopColor="#FAD86E" />
          <stop offset="0.358" stopColor="#FDE794" />
          <stop offset="0.445" stopColor="#FEF0AB" />
          <stop offset="0.5" stopColor="#FFF3B3" />
          <stop offset="1" stopColor="#F2AD00" />
        </linearGradient>
        <linearGradient
          id="paint4_linear_885_1088"
          x1="11.1771"
          y1="24.945"
          x2="24.8231"
          y2="11.299"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#F2AD00" />
          <stop offset="0.145" stopColor="#F6C43B" />
          <stop offset="0.29" stopColor="#FAD86E" />
          <stop offset="0.416" stopColor="#FDE794" />
          <stop offset="0.517" stopColor="#FEF0AB" />
          <stop offset="0.581" stopColor="#FFF3B3" />
          <stop offset="1" stopColor="#F2AD00" />
        </linearGradient>
      </defs>
    </svg>
  );
};

export default CoinStar;
