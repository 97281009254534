/* eslint-disable */
import React, { FunctionComponent, useEffect, useMemo, useState } from "react";
import Button from "../../components/ui/Button";
import RecipientProps from "./Recipient";
import { motion } from "framer-motion";
import Checkbox from "../../components/ui/Checkbox";
import { InfoIcon } from "../../assets/Icons";
// import TextField from "../../components/ui/TextField";
import mixpanel from "mixpanel-browser";
import { TextField } from "@mui/material";
import { Link } from "react-router-dom";
import TabPanel from "./TabPanel";

export const StepThree: FunctionComponent<RecipientProps.StepThree> = ({
  setIsModalOpen,
  bankCountry,
  setAcNumber,
  acNumber,
  confirmAcNumber,
  setConfirmAcNumber,
  setIBAN,
  IBAN,
  setBankName,
  bankName,
  setSwiftCode,
  swiftCode,
  setUnitNumber,
  unitNumber,
  routingNumber,
  setRoutingNumber,
  transitCode,
  setTransitCode,
  bspCode,
  setBspCode,
  sortCode,
  setSortCode,
  refrence,
  setRefrence,
  bankAddress,
  setBankAddress,
  handleFinalClick,
  setStep,
  errorMessage,
  setErrorMessage,
  isCorrespondingAccount,
  setIsCorrespondingAccount,
  isVerified,
  setIsVerified,
  handleVerify,
  validationError,
  country,
  loading
}) => {
  const [isSwift, setIsSwift] = useState<boolean>(false);
  const mixpanelToken: any = process.env.REACT_APP_MIXPANEL_TOKEN;
  mixpanel.init(mixpanelToken, {
    debug: true,
    api_host: process.env.REACT_APP_MIXPANEL_API,
  });
  const ibanCountryArray: any = [
    "Germany",
    "France",
    "United Kingdom",
    "Belgium",
    "United Arab Emirates",
    "Italy",
    "Brazil",
    "Portugal",
  ];

  const enableSwitch = useMemo(() => {
    const tabSwitch = isVerified ? false : true;
    return tabSwitch;
  }, [IBAN, swiftCode, isVerified]);


  const enabled = "w-[200px] h-12 rounded-full bg-primary text-white font-bold text-[18px]";
  const disabled = "w-[200px] h-12 rounded-full bg-gray-500 text-white font-bold text-[18px]";
  const btnDisable =
    bankCountry === "" ||
    acNumber === "" ||
    confirmAcNumber != acNumber ||
    (!ibanCountryArray.includes(bankCountry) && bankName === "") ||
    (bankCountry === "Australia" && bspCode === "") ||
    (bankCountry === "Canada" && transitCode === "") ||
    (bankCountry === "United Kingdom" && sortCode === "") ||
    (bankCountry === "United States" && routingNumber === "");

  const codeCountry = ["Australia", "Canada", "United Kingdom", "United States"];
  const [errorAccNumber, setErrorAccNumber] = React.useState("");
  const specialCharRegExp = "[a-zA-Z0-9 ]{1,}";

  const ibanCountries =
    bankCountry === "France" ||
    bankCountry === "United Kingdom" ||
    bankCountry === "Belgium" ||
    bankCountry === "United Arab Emirates" ||
    bankCountry === "Italy" ||
    bankCountry === "Portugal" ||
    bankCountry === "Cananda" ||
    bankCountry === "Brazil";

  // const [isVerified, setIsVerified] = React.useState(true);

  useEffect(() => {
    setIBAN("");
    setSwiftCode("");
    setIsVerified(false);
  }, []);

  return (
    <motion.div
      className="flex flex-col justify-center items-center mt-20"
      initial={{ width: 0 }}
      animate={{ width: "100%" }}
      exit={{ x: window.innerWidth, transition: { duration: 0.5 } }}
    >
      {/* mb-3 text-sm font-bold flex justify-center self-center space-x-6 */}
      <div className="w-[100%] flex-col justify-center items-center">
        <div className="flex flex-col items-center justify-center self-center">
          <div className="mb-3 text-sm font-bold">
            Fill {!ibanCountryArray.includes(country) && <span>any one of</span>} the following to
            verify
          </div>
          {!ibanCountryArray.includes(country) && (
            <TabPanel switchTab={enableSwitch} isSwift={isSwift} setIsSwift={setIsSwift} />
          )}
          {isVerified && (
            <span
              onClick={() => {
                if (IBAN) {
                  setSwiftCode("");
                }
                setIsVerified(!isVerified);
              }}
              className="text-blue-600 cursor-pointer"
            >
              Edit Field
            </span>
          )}
        </div>
        <div>
          <div className="flex sm:flex-row flex-col justify-center items-center">
            <div className="flex gap-4">
              <TextField
                className="bg-white"
                type="text"
                fullWidth
                label={isSwift ? "Swift" : "IBAN Number"}
                onChange={(e) => (isSwift ? setSwiftCode(e.target.value) : setIBAN(e.target.value))}
                value={isSwift ? swiftCode : IBAN}
                tabIndex={2}
                disabled={isVerified}
              />
              <button onClick={handleVerify} className={`${isVerified ? "bg-green-500 text-white px text-lg font-roboto px-10 rounded-md" : "bg-black text-white px text-lg font-roboto px-10 rounded-md"}`}>
                {isVerified ? "Verified" : "Verify"}
              </button>
            </div>
          </div>
          {validationError && (
            <span className="text-sm flex justify-center self-center mt-5" style={{ color: "red" }}>
              {validationError}
            </span>
          )}
        </div>
        {isVerified && (
          <div className="w-[80%] mx-auto mb-4 overflow-hidden border border-blue-300 mt-2 border-solid"></div>
        )}{" "}
        {isVerified && (
          <div className="w-[85%] lg:w-[72%] mx-auto">
            <div className="flex flex-col ml-4">
              <div className="flex flex-col justify-between mb-4 lg:space-x-8 lg:flex-row">
                <TextField
                  className="mb-4 bg-white lg:mb-0"
                  name="username"
                  type="number"
                  label="Bank Account Number"
                  fullWidth
                  onBlur={() => {
                    if (confirmAcNumber !== "") {
                      if (acNumber !== confirmAcNumber) {
                        setErrorAccNumber("Account number does not match");
                      } else setErrorAccNumber("");
                    }
                  }}
                  required={true}
                  onChange={(e) => {
                    if (e.target.value.length == 0) {
                      setAcNumber(e.target.value);
                    }
                    if (/^[0-9a-zA-Z]+$/.test(e.target.value)) {
                      // setAlphaNeumeric(false);
                      setAcNumber(e.target.value);
                      window.console.log(acNumber, e.target.value);
                    } else {
                      // setAlphaNeumeric(true);
                    }
                    if (confirmAcNumber === e.target.value) setErrorAccNumber("");
                  }}
                  value={acNumber}
                  tabIndex={2}
                  disabled={!isVerified}
                // error={alphaNeumeric}
                // errorMessage="Please enter correct account number"
                />
                <div className="mb-4 lg:hidden" />
                <div className="w-full">
                  <TextField
                    className="bg-white"
                    name="username"
                    type="number"
                    label="Confirm Bank Account Numbe"
                    fullWidth
                    required={true}
                    onChange={(e) => setConfirmAcNumber(e.target.value)}
                    value={confirmAcNumber}
                    tabIndex={3}
                    disabled={!isVerified}
                  />
                  {confirmAcNumber !== acNumber && confirmAcNumber && (
                    <span className="text-red-500 text-[14px] ml-3">
                      The account numbers do not match.
                    </span>
                  )}
                </div>
              </div>
              <div className="flex flex-col items-center justify-between mb-4 lg:flex-row lg:space-x-8">
                <TextField
                  onClick={() => setIsModalOpen("bankCountry")}
                  value={bankCountry}
                  type="text"
                  label="Bank Account Country*"
                  fullWidth
                  tabIndex={1}
                  className="mb-4 bg-white lg:mb-0"
                  disabled={!isVerified}
                />
                <div className="mb-4 lg:hidden" />
                <TextField
                  className="w-1/2 mb-4 lg:mb-0 focus-visible:!border-black bg-white"
                  name="username"
                  label="Bank Name"
                  fullWidth
                  required
                  onChange={(e) => setBankName(e.target.value)}
                  value={bankName}
                  tabIndex={5}
                  disabled={!isVerified}
                />
              </div>
              <div className="flex flex-col items-center justify-between mb-4 lg:flex-row lg:space-x-8">
                <TextField
                  className="mb-4 lg:mb-0 bg-white focus-visible:!border-black"
                  name="code"
                  type="text"
                  fullWidth
                  label={
                    bankCountry === "United States"
                      ? "Routing Number*"
                      : bankCountry === "Canada"
                        ? "Transit Code*"
                        : bankCountry === "Australia"
                          ? "BSB Code*"
                          : bankCountry === "United Kingdom"
                            ? "Sort Code*"
                            : "Sort/BSB/Transit/Routing"
                  }
                  onChange={(e) =>
                    bankCountry === "United States"
                      ? setRoutingNumber(e.target.value)
                      : bankCountry === "Canada"
                        ? setTransitCode(e.target.value)
                        : bankCountry === "Australia"
                          ? setBspCode(e.target.value)
                          : bankCountry === "United Kingdom"
                            ? setSortCode(e.target.value)
                            : "Code*"
                  }
                  value={
                    bankCountry === "United States"
                      ? routingNumber
                      : bankCountry === "Canada"
                        ? transitCode
                        : bankCountry === "Australia"
                          ? bspCode
                          : bankCountry === "United Kingdom"
                            ? sortCode
                            : "Not Required"
                  }
                  disabled={!codeCountry.includes(bankCountry)}
                  tabIndex={7}
                />
                <div className="mb-4 lg:hidden" />
                <TextField
                  name="refrence"
                  label="Reference ID"
                  fullWidth
                  onChange={(e) => setRefrence(e.target.value)}
                  value={refrence}
                  tabIndex={8}
                  disabled={!isVerified}
                  className="bg-white"
                />
              </div>
              <TextField
                className="mb-4 bg-white"
                label="Bank Address"
                fullWidth
                onChange={(e) => setBankAddress(e.target.value)}
                value={bankAddress}
                tabIndex={8}
                disabled={!isVerified}
              />
              <div className="flex flex-col items-center justify-between mt-4 mb-4 space-x-8 lg:flex-row">
                <TextField
                  className="bg-inherit border-b-2 border-0 border-blue-200 rounded-none w-[100%] justify-center mb-4 lg:mb-0 bg-white"
                  name="username"
                  type="number"
                  fullWidth
                  label="Unit Number (Optional)"
                  onChange={(e) => setUnitNumber(e.target.value)}
                  value={unitNumber}
                  tabIndex={6}
                />
                <div className="mb-4 lg:hidden" />

                <div className="relative flex items-center w-full space-x-3 group">
                  <Checkbox
                    label={"Click if your recipient has a corresponding account"}
                    value={unitNumber}
                    name={"checkbox"}
                    checked={isCorrespondingAccount}
                    inputClassName="flex justify-center w-full"
                    labelClassName="text-sm text-gray-500"
                    handleChange={(e) => {
                      setIsCorrespondingAccount(!isCorrespondingAccount);
                      mixpanel.track("Corresponding Account Clicked");
                    }}
                  />

                  <div className="text-blue-300 transition-all cursor-pointer hover:scale-105">
                    <InfoIcon fillColor="blue" />
                  </div>
                  <div className="absolute flex-col items-center justify-center hidden px-5 w-[500px] text-[14px] py-4 mt-[160px] font-semibold text-white rounded-lg shadow-xl bg-primary group-hover:flex animate-fade">
                    <span className="font-bold text-[16px]">What is a corresponding account?</span>
                    <span>
                      A correspondent account is a bank account set up to handle transactions for
                      another financial institution.
                    </span>
                  </div>
                </div>
              </div>
            </div>
          </div>
        )}
      </div>
      <span className="text-[14px] text-red-200 mt-4 md:mt-8">{errorMessage && errorMessage}</span>
      <div className="text-sm font-bold flex justify-center self-center space-x-6">
        <span>
          (To know more in detail about the SWIFT/IBAN
          <Link
            to="https://www.moneyhop.co/blog/understanding-swift-code-and-iban"
            rel="noopener noreferrer"
            target="_blank"
            className="text-blue-600 pl-2"
          >
            click here
          </Link>
          )
        </span>
      </div>
      {!isCorrespondingAccount && isVerified ? (
        <div className="flex flex-col items-center mt-10">
          <div className="flex -ml-8 space-x-10 md:space-x-10 md:ml-0">
            <Button
              id="back-recepient-btn-2"
              onClickHandler={() => {
                setStep(2);
                setErrorMessage("");
              }}
              className="w-[200px] bg-blue-200 h-12 rounded-full text-primary font-bold text-[18px]"
            >
              Go Back
            </Button>
            <Button
              id="submit-recepient-btn-1"
              onClickHandler={handleFinalClick}
              disabled={btnDisable}
              className={btnDisable ? disabled : enabled}
              isLoading={loading}
            >
              Save & Proceed
            </Button>
          </div>
        </div>
      ) : (
        <Button
          bgColor="bg-blue-200"
          fontStyle={{
            fontFamily: "font-roboto",
            fontSize: "text-lg",
            fontWeight: "font-bold",
            fontColor: "text-primary",
          }}
          borderStyle={{
            borderRadius: "rounded-full",
          }}
          className="px-[4.2rem] py-2 sm:mb-0 mt-5 sm: mr-10 mb-5"
          onClickHandler={() => {
            setStep(2);
            setErrorMessage("");
          }}
        >
          Go Back
        </Button>
      )}
    </motion.div>
  );
};
