import { Axios } from "../axios";
import IReviewPage from "./reviewPage";

export const initializeTransaction = async (body: IReviewPage.InitializeTransactionProps) => {
  const currentUser = JSON.parse(sessionStorage.getItem("currentUser") || "{}");
  return await Axios.post(`/remit/user/${currentUser.id}/initializeTransaction`, body, {
    headers: {
      Authorization: `${currentUser.idToken}`,
    },
  });
};

export const sendA2Form = async (body: IReviewPage.SendA2FormProps) => {
  const currentUser = JSON.parse(sessionStorage.getItem("currentUser") || "{}");
  return await Axios.post(`/remit/auth/sendA2FromLink`, body, {
    headers: {
      Authorization: `${currentUser.idToken}`,
    },
  });
};

export const saveEsign = async (body: FormData, userId: any) => {
  return await Axios.post(`/remit/users/${userId}/signedImage`, body);
};

export const validateEsignLink = async (body: IReviewPage.ValidateEsign) => {
  return await Axios.post(`/remit/auth/signature/check`, body);
};

export const getSignedStatus = async (body: IReviewPage.EsignStatus) => {
  const currentUser = JSON.parse(sessionStorage.getItem("currentUser") || "{}");
  return await Axios.get(`remit/user/signedStatus/user/${body.userId}`, {
    headers: {
      "Access-Control-Allow-Origin": "true",
      "Content-Type": "application/json",
      Authorization: `${currentUser.idToken}`,
    },
  });
};

export const signLaterStatus = async (body: IReviewPage.SignLaterStatus) => {
  const currentUser = JSON.parse(sessionStorage.getItem("currentUser") || "{}");
  return await Axios.post(`/remit/auth/signature/flag/doLater/${body.userId}`, body, {
    headers: {
      Authorization: `${currentUser.idToken}`,
    },
  });
};
