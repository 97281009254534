/* eslint-disable */
import clsx from "clsx";
import React, { useEffect, useState } from "react";
import { useRecoilState, useRecoilValue, useResetRecoilState } from "recoil";
import { useNavigate, Link } from "react-router-dom";
import { motion } from "framer-motion";
import {
  isInwardTransaction,
  recipientDetails,
  searchValue,
  transactionDetails,
} from "../../../store";
import { RecipientDetais } from "../../../store/store";
import { getUserRecipients } from "../../../services/userDetails";
import {
  CloseIcon,
  LeftArrowIcon,
  SearchIcon,
  TelegramEllipse,
  UfoIcon,
} from "../../../assets/Icons";
import TextField from "../../../components/ui/TextField";
import Typograpy from "../../../components/ui/Typography";
import RecipientCard from "../../../components/ui/RecipientCard";
import Modal from "../../../components/ui/Modal";
import { TransactioncancellationReasons } from "../../../utils";
import Checkbox from "../../../components/ui/Checkbox";
import Button from "../../../components/ui/Button";
import { handleTransactionComplete } from "../../../services/transaction";

// import TextField from "../../components/ui/TextField";
// import { CloseIcon, LeftArrowIcon, SearchIcon, TelegramEllipse, UfoIcon } from "../../assets/Icons";
// import { isInwardTransaction, recipientDetails, searchValue } from "../../store";
// import { RecipientDetais } from "../../store/store";
// import Typograpy from "../../components/ui/Typography";
// import RecipientCard from "../../components/ui/RecipientCard";
// import { getUserRecipients } from "../../services/userDetails";
// import { TransactioncancellationReasons } from "../../utils";
// import Modal from "../../components/ui/Modal";
// import Checkbox from "../../components/ui/Checkbox";
// import Button from "../../components/ui/Button";

const PickInwardRecipient = () => {
  const navigate = useNavigate();
  const [recipient, setRecipient] = useRecoilState(recipientDetails);
  const recipientDetailsData: RecipientDetais = useRecoilValue(recipientDetails);
  const [searchInput, setSearchInput] = useRecoilState(searchValue);
  const [filteredRecipient, setFileteredRecipient] =
    React.useState<RecipientDetais>(recipientDetailsData);
  const [isModalOpen, setIsModalOpen] = useState<string>("");
  const [cancellationReasons, setCancellationReasons] = useState<string>("");
  const [otherReason, setOtherReason] = useState<string>("");
  const [isInward, setIsInward] = useRecoilState(isInwardTransaction);
  const isUseEffectFired = React.useRef(false);

  const currentUser = JSON.parse(sessionStorage.getItem("currentUser") || "{}");
  const resetTransactionDetails = useResetRecoilState(transactionDetails);

  useEffect(() => {
    window.onbeforeunload = function () {
      return true;
    };

    return () => {
      window.onbeforeunload = null;
    };
  }, []);

  const getRecipients = async () => {
    try {
      const response = await getUserRecipients(currentUser);
      const { data } = response;

      window.console.log(data);

      const filteredRecipients: any = data.recipient.filter((recipient: any) => {
        // console.log();
        return recipient.ifscCode !== null;
      });

      console.log(filteredRecipient, "ssssss");
      console.log(data.recipient, "ssssss");

      setRecipient(filteredRecipients);

      // setRecipient(data.recipient);
    } catch (error) {
      //TODO : Show error as per the error message providedd
      /* eslint-disable-next-line */
      console.error(error);
    }
  };

  useEffect(() => {
    window.console.log(isInward);
  }, []);

  React.useEffect(() => {
    if (searchInput) {
      setFileteredRecipient(
        [...recipientDetailsData].filter(
          (el) =>
            el.firstName.toLowerCase().includes(searchInput.toLowerCase()) ||
            el.lastName.toLowerCase().includes(searchInput.toLowerCase()),
        ),
      );
    } else if (searchInput === "") {
      setFileteredRecipient(recipientDetailsData);
    }
  }, [searchInput, recipient]);

  React.useEffect(() => {
    if (!isUseEffectFired.current) {
      isUseEffectFired.current = true;
      getRecipients();
    }
  }, []);

  const handleChoseRecipient = () => {
    navigate("/inward/review");
  };

  const handleNavigate = () => {
    handleTransactionComplete();
    resetTransactionDetails();
    navigate("/");
  };

  return (
    <motion.div
      initial={{ width: 0 }}
      animate={{ width: "100%" }}
      exit={{ x: window.innerWidth, transition: { duration: 0.5 } }}
    >
      <div className="flex justify-between items-center bg-white md:hidden w-full py-[25px] pr-[22px] pl-7 mb-4">
        <div className="flex items-center w-fit" onClick={() => history.back()}>
          <LeftArrowIcon />
          <div className="ml-6 text-xl font-semibold text-grey-1100">Pick Recipient</div>
        </div>
        <div className="flex items-center">
          <div
            className="mr-3 text-sm font-normal text-primary"
            onClick={() => navigate("/support")}
          >
            Need help ?
          </div>
          <div
            className="bg-gray-400 w-[30px] h-[30px] flex justify-center items-center rounded-full"
            onClick={() => setIsModalOpen("cancelReasons")}
          >
            <CloseIcon />
          </div>
        </div>
      </div>
      <div className="grid grid-cols-1 px-6 md:pl-9 md:pr-[85px] md:mt-[54px]">
        <div className="hidden text-sm font-medium text-black md:block mb-[23px]">
          Select your recipients
        </div>
        <div className="w-full h-12 mb-7">
          <TextField
            name="searchRecipient"
            type="text"
            label={
              <div className="flex items-center justify-between gap-2 lg:justify-start">
                <div className="order-2 md:order-1">
                  <SearchIcon fillColor="fill-grey-650" />
                </div>
                <span className="order-1 md:ml-4 md:order-2">Search Recipient Name</span>
              </div>
            }
            value={searchInput}
            fullWidth
            inputClasses="h-16 !pt-1 bg-white w-full !border-0"
            placeholderStyle={clsx(
              searchInput && "hidden",
              "peer-placeholder-shown:!top-6 !text-sm !text-grey-650 !font-normal text-sm peer-focus:hidden w-full px-6",
            )}
            onChange={(e) => setSearchInput(e.target.value)}
            pattern="[a-zA-Z0-9 ]{1,}"
          />
        </div>
        <div className="w-full py-5 md:py-10 pl-8 md:pl-10 flex items-center bg-primary-150 gap-6 md:gap-12 rounded-[6px] mb-4">
          {isInward ? (
            <Link to={"/inward/recipient-details"}>
              <div className="w-9 h-9 md:w-[56px] md:h-[56px] bg-[transparent] opacity-50 rounded-full border-primary border-[3px] text-xl md:text-[40px] text-primary flex justify-center items-center text-center cursor-pointer">
                <div className="">+</div>
              </div>
            </Link>
          ) : (
            <Link to={"/recipient-details"}>
              <div className="w-9 h-9 md:w-[56px] md:h-[56px] bg-[transparent] opacity-50 rounded-full border-primary border-[3px] text-xl md:text-[40px] text-primary flex justify-center items-center text-center cursor-pointer">
                <div className="">+</div>
              </div>
            </Link>
          )}
          <Typograpy
            className="text-primary opacity-50 text-base md:text-[30px] font-normal"
            text={"Add a new recipient"}
            tagType="div"
          />
        </div>
        {recipientDetailsData?.length === 0 ? (
          <div className="flex flex-col items-center mt-10 text-center">
            <UfoIcon />
            <Typograpy
              tagType="div"
              className="text-[32px] md:text-[48px] text-primary-600 font-normal mb-9 md:mb-[17px] mt-10"
              text="No recipients found"
            />
            <Typograpy
              tagType="div"
              className="text-singleHistoryText text-[20px] md:text-[72px]"
              text={
                <span>
                  Try the<span className="opacity-50 text-primary-600">“ + ”</span>button above
                </span>
              }
            />
          </div>
        ) : (
          filteredRecipient?.map((el) => (
            <RecipientCard
              key={el.bankAccountNumber}
              firstName={el.firstName}
              lastName={el.lastName}
              coutryOfResidence={el.countryOfResidence}
              accNo={el.bankAccountNumber}
              id={el.id}
              onButtonClick={handleChoseRecipient}
              icon={<TelegramEllipse />}
              onEyeClick={() => navigate("/recipient-profile")}
            />
          ))
        )}
      </div>
      {/* Cancel Transaction Modal */}
      <Modal
        header="Reason for cancellation"
        headerClass={"bg-white !my-0 py-6 px-12 font-bold text-lg"}
        modalClass="!p-0 !rounded !overflow-hidden mb-20"
        visible={isModalOpen === "cancelReasons"}
        closeHandler={() => setIsModalOpen("")}
        closable={false}
      >
        <div className="px-5 pb-5">
          {TransactioncancellationReasons.map((data, index) => {
            return (
              <div className="space-x-2 px-7 border-b-[1px] py-6 first:pt-0" key={index}>
                <Checkbox
                  label={data}
                  value={data}
                  name={"cancellationReason"}
                  handleChange={(e) => {
                    setCancellationReasons(e.target.value);
                  }}
                />
              </div>
            );
          })}
          {cancellationReasons === "Other Reason..." && (
            <div className="flex items-center justify-center mt-2">
              <textarea
                name="otherReasonDescription"
                value={otherReason}
                rows={4}
                cols={50}
                placeholder="Write your reason here....."
                onChange={(e) => {
                  setOtherReason(e.target.value);
                }}
                className="w-full p-3 bg-white-100 focus-visible:border-0 placeholder:text-center"
              />
            </div>
          )}
          <div className="flex items-center justify-center mt-7 px-[78px]">
            <Button
              id="Submit-cancellationReasons-Btn"
              bgColor={`${cancellationReasons === "" ? "bg-grey-350" : "bg-primary"}`}
              fontStyle={{
                fontFamily: "",
                fontSize: "text-lg",
                fontWeight: "font-bold",
                fontColor: "text-white",
              }}
              borderStyle={{
                borderWidth: "border-none",
                borderRadius: "rounded-[100px]",
              }}
              disabled={cancellationReasons === ""}
              className={"py-3 px-28"}
              onClickHandler={() =>
                cancellationReasons !== "" && cancellationReasons === "Other Reason..."
                  ? otherReason !== "" && handleNavigate()
                  : handleNavigate()
              }
            >
              Submit
            </Button>
          </div>
        </div>
      </Modal>
    </motion.div>
  );
};

export default PickInwardRecipient;
