import React, { useEffect } from "react";
import { loadCaptchaEnginge, LoadCanvasTemplate } from "react-simple-captcha";

interface CaptchaProps {
  captchaValue?: any;
  setCaptchaValue?: React.Dispatch<React.SetStateAction<string>>;
}

const CaptchaTest: React.FC<CaptchaProps> = ({ captchaValue, setCaptchaValue }) => {
  useEffect(() => {
    loadCaptchaEnginge(6,'white','black','numbers');
  }, []);

  return (
    <div className="w-full flex items-center justify-center">
      <div className="w-full flex-col ">
        <div className="col mt-3">
          <LoadCanvasTemplate />
        </div>

        <div className="col mt-3">
          <div>
            <input
              placeholder="Enter Captcha"
              value={captchaValue as string}
              onChange={(e) => setCaptchaValue?.(e.target.value)}
              type="text"
              className="border p-2 w-full border-gray-200 rounded-sm"
            />
          </div>
        </div>
      </div>
    </div>
  );
};

export default CaptchaTest;
