/* eslint-disable */
import { TextField } from "@mui/material";
import React, { useEffect } from "react";
import { DropdownIcon, PassportIcon, UploadIcon } from "../../../assets/Icons";
import { SimpleDropdown } from "../../../components/ui/SimpleDropdown";
import InwardDocumentProps from "./userDocument";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { SearchableDropdown } from "../../../components/ui/SearchableDropdown";
import { countryFlagCode } from "../../../utils";
import clsx from "clsx";
import moment from "moment";
import { DatePicker, LocalizationProvider } from "@mui/x-date-pickers";
import AccountTypeModal from "../../userDocuments/KycModals/kycStatusModals/AccountTypeModal";
import Modal from "../../../components/ui/Modal";

export const InwardDocumentWindows: React.FC<InwardDocumentProps.DocumentWindowProps> = ({
  documentWindow,
  setIdentityData,
  identityData,
  setAddressData,
  addressData,
  stepCompleted,
  sourceOfFundP,
  remmitanceFrequencyP,
  setIsModalOpenP,
}) => {
  useEffect(() => {
    setFileName(null);
  }, [documentWindow]);

  const [fileName, setFileName] = React.useState(null);
  const [filetype, setFiletype] = React.useState("");
  const [isModalOpen, setIsModalOpen] = React.useState("");
  const [showAccountTypeModal, setshowAccountTypeModal] = React.useState(false);
  const [searchInput, setSearchInput] = React.useState("");
  const [countryData, setCountryData] = React.useState<any>(countryFlagCode);

  React.useEffect(() => {
    const countryList = countryFlagCode;
    let tempdata = [...countryList];
    if (searchInput) {
      tempdata = tempdata?.filter((data: any) =>
        data?.country?.toLowerCase()?.includes(searchInput?.toLowerCase()),
      );
    }
    setCountryData(tempdata);
  }, [searchInput]);

  const data = [
    { label: "Mr.", value: "MR" },
    { label: "Ms.", value: "MS" },
    { label: "Mrs.", value: "MISS" },
  ];

  const genderList = ["MALE", "FEMALE", "Non-Binary"];
  const handleSelectIdentityTitle = (selectedValue: string) => {
    window.console.log(selectedValue);

    setIdentityData({
      ...identityData,
      title: selectedValue,
    });
  };

  useEffect(() => {
    if (sourceOfFundP) setAddressData({ ...addressData, source: sourceOfFundP });
    if (remmitanceFrequencyP) setAddressData({ ...addressData, frequency: remmitanceFrequencyP });
  }, [sourceOfFundP, remmitanceFrequencyP]);

  React.useEffect(() => {
    if (stepCompleted.identityApproved) setFileName(null);
  }, [stepCompleted]);

  const handleFileChange = (e: any) => {
    window.console.log("yayayayaya", e.target.files[0]);
    const file = e.target.files[0];
    setIdentityData({ ...identityData, file: file });
    if (file) {
      setFileName(file.name);
      if (
        file.type === "application/pdf" ||
        file.type === "image/jpeg" ||
        file.type === "image/png"
      ) {
        setFiletype("true");
      } else {
        setFiletype("false");
      }
    } else {
      setFileName(null);
      setFiletype("");
    }
  };
  useEffect(() => {
    //blah
  }, [filetype]);

  const handleAddressFileChange = (e: any) => {
    window.console.log(e.target.files[0]);
    const file = e.target.files[0];
    setAddressData({ ...addressData, file: file });
    if (file) {
      setFileName(file.name);
      if (
        file.type === "application/pdf" ||
        file.type === "image/jpeg" ||
        file.type === "image/png"
      ) {
        setFiletype("true");
      } else {
        setFiletype("false");
      }
    } else {
      setFileName(null);
      setFiletype("");
    }
  };

  const handleRemove = () => {
    if (
      documentWindow === "passport" ||
      documentWindow === "dl" ||
      documentWindow === "idcard" ||
      documentWindow === "permit"
    ) {
      setIdentityData({
        ...identityData,
        file: null,
      });
    } else if (
      documentWindow === "taxbill" ||
      documentWindow === "utilitybill" ||
      documentWindow === "statement"
    ) {
      setAddressData({ ...addressData, file: null });
    }
    setFileName(null);
    setFiletype("");
  };

  const options = ["Option 1", "Option 2", "Option 3"];

  return (
    <div className="">
      <AccountTypeModal
        options={options}
        showAccountTypeModal={showAccountTypeModal}
        setshowAccountTypeModal={setshowAccountTypeModal}
      />
      {documentWindow === "passport" ? (
        <div
          id="passport"
          className="flex-col items-center w-full px-4 py-4 space-y-5 lg:px-10 lg:py-10 lg:space-y-10"
        >
          <div
            id="line1"
            className="flex flex-col w-full space-y-5 lg:flex-row lg:justify-evenly lg:space-x-10 lg:space-y-0"
          >
            <div className="flex flex-col items-center justify-between lg:w-1/2">
              <div
                className={clsx(
                  fileName ? "border-green-500" : "border-primary",
                  filetype === ""
                    ? "border-primary"
                    : filetype === "true"
                    ? "border-green-500"
                    : "border-red-500",
                  "flex items-center justify-between w-full px-5 py-4 border-2 rounded-lg group",
                )}
              >
                <div className="flex items-center space-x-2">
                  <PassportIcon
                    width="23px"
                    height="29px"
                    fillColor={clsx(
                      fileName
                        ? filetype === "true"
                          ? "fill-green-500"
                          : "fill-red-500"
                        : "fill-primary",
                    )}
                  />
                  {!fileName ? (
                    <div className="text-primary">Upload Passport Front</div>
                  ) : (
                    <div
                      className={clsx(
                        filetype === "true"
                          ? "font-semibold text-green-500"
                          : "font-semibold text-red-500",
                      )}
                    >
                      {fileName}
                    </div>
                  )}
                </div>
                <div>
                  {fileName ? (
                    <div className="flex items-center space-x-2">
                      <button
                        onClick={handleRemove}
                        className="transition-all cursor-pointer text-primary group-hover:scale-125"
                      >
                        Remove
                      </button>
                    </div>
                  ) : (
                    <label htmlFor="file-input">
                      <div className="transition-all cursor-pointer group-hover:scale-125">
                        <UploadIcon fillColor={"fill-primary"} />
                      </div>
                      <input
                        id="file-input"
                        type="file"
                        onChange={handleFileChange}
                        style={{ display: "none" }}
                      />
                    </label>
                  )}
                </div>
              </div>
              {filetype === "false" ? (
                <div className="text-red-500">
                  This file format is not supported. Please try file formats like jpg, png or pdf.
                </div>
              ) : null}
            </div>

            <TextField
              variant="outlined"
              label="Passport File Number"
              type="text"
              onChange={(e: any) =>
                setIdentityData({
                  ...identityData,
                  docNumber: e.target.value,
                })
              }
              className="h-full px-5 py-5 border border-black rounded-lg lg:w-1/2"
              sx={{ backgroundColor: "white" }}
            />
          </div>
          <div
            id="line2"
            className="flex flex-col items-center w-full space-y-5 lg:flex-row lg:justify-evenly lg:space-x-10 lg:space-y-0"
          >
            <LocalizationProvider dateAdapter={AdapterDayjs}>
              <DatePicker
                label="Passport DOB"
                className="w-full bg-white lg:w-1/2"
                value={identityData?.doc_dob}
                onChange={(newValue: any) =>
                  setIdentityData({ ...identityData, doc_dob: newValue })
                }
              />
            </LocalizationProvider>
            <div className="flex w-full lg:w-1/2 group">
              <SimpleDropdown options={data} onSelect={(e: any) => handleSelectIdentityTitle(e)} />
              <TextField
                type="text"
                variant="outlined"
                label="Name as per Passport"
                onChange={(e: any) =>
                  setIdentityData({
                    ...identityData,
                    name: e.target.value,
                  })
                }
                className={
                  "w-5/6 px-5 py-5 border-t border-b border-r rounded-r-lg bg-white border-black"
                }
              />
            </div>
          </div>
          <div
            id="line3"
            className="flex flex-col items-center w-full space-y-5 lg:flex-row lg:justify-evenly lg:space-x-10 lg:space-y-0"
          >
            <SearchableDropdown
              options={genderList}
              label="Select Gender"
              mainClassname={"bg-white lg:w-1/2 w-full"} // add an onclick prop here ffs retard
              onChange={(e: any, v: any) => setIdentityData({ ...identityData, gender: v })}
            />
            <LocalizationProvider dateAdapter={AdapterDayjs}>
              <DatePicker
                label="Valid Till"
                value={identityData?.validTill || null}
                onChange={(newValue) => setIdentityData({ ...identityData, validTill: newValue })}
                className="w-full bg-white lg:w-1/2"
              />
            </LocalizationProvider>
          </div>
          <div
            id="line4"
            className="flex flex-col items-center w-full space-y-5 lg:flex-row lg:justify-evenly lg:space-x-10 lg:space-y-0"
          >
            <div
              onClick={() => setIsModalOpen("identity-nationality")}
              className={
                "bg-white lg:w-1/2 w-full py-4 pl-4 border border-gray-300 text-gray-600 rounded cursor-pointer"
              }
            >
              {identityData?.nationality ? identityData?.nationality : "Nationality"}
            </div>
            <div
              onClick={() => setIsModalOpen("country-one")}
              className={
                "bg-white lg:w-1/2 w-full py-4 pl-4 border border-gray-300 text-gray-600 rounded cursor-pointer"
              }
            >
              {identityData?.issueCountry ? identityData?.issueCountry : "Issue Country"}
            </div>
          </div>
        </div>
      ) : null}
      {documentWindow === "dl" ? (
        <div
          id="dl"
          className="flex-col items-center w-full px-4 py-4 space-y-5 lg:px-10 lg:py-10 lg:space-y-10"
        >
          <div
            id="line2"
            className="flex flex-col w-full space-y-5 lg:flex-row lg:justify-evenly lg:space-x-10 lg:space-y-0"
          >
            <div className="flex flex-col items-center justify-between lg:w-1/2">
              <div
                className={clsx(
                  fileName ? "border-green-500" : "border-primary",
                  filetype === ""
                    ? "border-primary"
                    : filetype === "true"
                    ? "border-green-500"
                    : "border-red-500",
                  "flex items-center justify-between w-full px-5 py-4 border-2 rounded-lg group",
                )}
              >
                <div className="flex items-center space-x-2">
                  <PassportIcon
                    width="23px"
                    height="29px"
                    fillColor={clsx(
                      fileName
                        ? filetype === "true"
                          ? "fill-green-500"
                          : "fill-red-500"
                        : "fill-primary",
                    )}
                  />
                  {!fileName ? (
                    <div className="text-primary">Upload Driving License</div>
                  ) : (
                    <div
                      className={clsx(
                        filetype === "true"
                          ? "font-semibold text-green-500"
                          : "font-semibold text-red-500",
                      )}
                    >
                      {fileName}
                    </div>
                  )}
                </div>
                <div>
                  {fileName ? (
                    <div className="flex items-center space-x-2">
                      <button
                        onClick={handleRemove}
                        className="transition-all cursor-pointer text-primary group-hover:scale-125"
                      >
                        Remove
                      </button>
                    </div>
                  ) : (
                    <label htmlFor="file-input">
                      <div className="transition-all cursor-pointer group-hover:scale-125">
                        <UploadIcon fillColor={"fill-primary"} />
                      </div>
                      <input
                        id="file-input"
                        type="file"
                        onChange={handleFileChange}
                        style={{ display: "none" }}
                      />
                    </label>
                  )}
                </div>
              </div>
              {filetype === "false" ? (
                <div className="text-red-500">
                  This file format is not supported. Please try file formats like jpg, png or pdf.
                </div>
              ) : null}
            </div>

            <TextField
              variant="outlined"
              label="Driving License Number"
              type="text"
              onChange={(e: any) =>
                setIdentityData({
                  ...identityData,
                  docNumber: e.target.value,
                })
              }
              className="h-full px-5 py-5 border border-black rounded-lg lg:w-1/2"
              sx={{ backgroundColor: "white" }}
            />
          </div>
          <div
            id="line2"
            className="flex flex-col items-center w-full space-y-5 lg:flex-row lg:justify-evenly lg:space-x-10 lg:space-y-0"
          >
            <LocalizationProvider dateAdapter={AdapterDayjs}>
              <DatePicker
                label="Driving License DOB"
                value={identityData?.doc_dob || null}
                onChange={(newValue) => setIdentityData({ ...identityData, doc_dob: newValue })}
                className="w-full bg-white lg:w-1/2"
              />
            </LocalizationProvider>
            <div className="flex w-full lg:w-1/2 group">
              <SimpleDropdown options={data} onSelect={(e: any) => handleSelectIdentityTitle(e)} />
              <TextField
                type="text"
                variant="outlined"
                label="Name as per Driving License"
                onChange={(e: any) =>
                  setIdentityData({
                    ...identityData,
                    name: e.target.value,
                  })
                }
                className={
                  "w-5/6 px-5 py-5 border-t border-b border-r rounded-r-lg bg-white border-black"
                }
              />
            </div>
          </div>
          <div
            id="line3"
            className="flex flex-col items-center w-full space-y-5 lg:flex-row lg:justify-evenly lg:space-x-10 lg:space-y-0"
          >
            <SearchableDropdown
              options={genderList}
              label="Select Gender"
              mainClassname={"bg-white lg:w-1/2 w-full"} // add an onclick prop here ffs retard
              onChange={(e: any, v: any) => setIdentityData({ ...identityData, gender: v })}
            />
            <LocalizationProvider dateAdapter={AdapterDayjs}>
              <DatePicker
                label="Valid Till"
                value={identityData?.validTill || null}
                onChange={(newValue) => setIdentityData({ ...identityData, validTill: newValue })}
                className="w-full bg-white lg:w-1/2"
              />
            </LocalizationProvider>
          </div>
          <div
            id="line4"
            className="flex flex-col items-center w-full space-y-5 lg:flex-row lg:justify-evenly lg:space-x-10 lg:space-y-0"
          >
            <div
              onClick={() => setIsModalOpen("identity-nationality")}
              className={
                "bg-white lg:w-1/2 w-full py-4 pl-4 border border-gray-300 text-gray-600 rounded cursor-pointer"
              }
            >
              {identityData?.nationality ? identityData?.nationality : "Nationality"}
            </div>
            <div
              onClick={() => setIsModalOpen("country-one")}
              className={
                "bg-white lg:w-1/2 w-full py-4 pl-4 border border-gray-300 text-gray-600 rounded cursor-pointer"
              }
            >
              {identityData?.issueCountry ? identityData?.issueCountry : "Issue Country"}
            </div>
          </div>
        </div>
      ) : null}
      {documentWindow === "idcard" ? (
        <div
          id="idcard"
          className="flex-col items-center w-full px-4 py-4 space-y-5 lg:px-10 lg:py-10 lg:space-y-10"
        >
          <div
            id="line3"
            className="flex flex-col w-full space-y-5 lg:flex-row lg:justify-evenly lg:space-x-10 lg:space-y-0"
          >
            <div className="flex flex-col items-center justify-between w-full lg:w-1/2">
              <div
                className={clsx(
                  fileName ? "border-green-500" : "border-primary",
                  filetype === ""
                    ? "border-primary"
                    : filetype === "true"
                    ? "border-green-500"
                    : "border-red-500",
                  "flex items-center justify-between w-full px-5 py-4 border-2 rounded-lg group",
                )}
              >
                <div className="flex items-center space-x-2">
                  <PassportIcon
                    width="23px"
                    height="29px"
                    fillColor={clsx(
                      fileName
                        ? filetype === "true"
                          ? "fill-green-500"
                          : "fill-red-500"
                        : "fill-primary",
                    )}
                  />
                  {!fileName ? (
                    <div className="text-primary">Upload National ID Card</div>
                  ) : (
                    <div
                      className={clsx(
                        filetype === "true"
                          ? "font-semibold text-green-500"
                          : "font-semibold text-red-500",
                      )}
                    >
                      {fileName}
                    </div>
                  )}
                </div>
                <div>
                  {fileName ? (
                    <div className="flex items-center space-x-2">
                      <button
                        onClick={handleRemove}
                        className="transition-all cursor-pointer text-primary group-hover:scale-125"
                      >
                        Remove
                      </button>
                    </div>
                  ) : (
                    <label htmlFor="file-input">
                      <div className="transition-all cursor-pointer group-hover:scale-125">
                        <UploadIcon fillColor={"fill-primary"} />
                      </div>
                      <input
                        id="file-input"
                        type="file"
                        onChange={handleFileChange}
                        style={{ display: "none" }}
                      />
                    </label>
                  )}
                </div>
              </div>
              {filetype === "false" ? (
                <div className="text-red-500">
                  This file format is not supported. Please try file formats like jpg, png or pdf.
                </div>
              ) : null}
            </div>

            <TextField
              variant="outlined"
              label="National identity card"
              type="text"
              onChange={(e: any) =>
                setIdentityData({
                  ...identityData,
                  docNumber: e.target.value,
                })
              }
              className="h-full px-5 py-5 border border-black rounded-lg lg:w-1/2"
              sx={{ backgroundColor: "white" }}
            />
          </div>
          <div
            id="line2"
            className="flex flex-col w-full space-y-5 lg:flex-row lg:justify-evenly lg:space-x-10 lg:space-y-0"
          >
            <LocalizationProvider dateAdapter={AdapterDayjs}>
              <DatePicker
                label="Enter DOB as per National identity card (DD - MM - YYYY)"
                value={identityData?.doc_dob || null}
                onChange={(newValue) => setIdentityData({ ...identityData, doc_dob: newValue })}
                className="w-full bg-white lg:w-1/2"
              />
            </LocalizationProvider>
            <div className="flex w-full lg:w-1/2 group">
              <SimpleDropdown options={data} onSelect={(e: any) => handleSelectIdentityTitle(e)} />
              <TextField
                type="text"
                variant="outlined"
                label="Name as per National ID Card"
                onChange={(e: any) =>
                  setIdentityData({
                    ...identityData,
                    name: e.target.value,
                  })
                }
                className={
                  "w-5/6 px-5 py-5 border-t border-b border-r rounded-r-lg bg-white border-black"
                }
              />
            </div>
          </div>
          <div
            id="line3"
            className="flex flex-col w-full space-y-5 lg:flex-row lg:justify-evenly lg:space-x-10 lg:space-y-0"
          >
            <SearchableDropdown
              options={genderList}
              label="Select Gender"
              mainClassname={"bg-white lg:w-1/2 w-full"} // add an onclick prop here ffs retard
              onChange={(e: any, v: any) => setIdentityData({ ...identityData, gender: v })}
            />
            <LocalizationProvider dateAdapter={AdapterDayjs}>
              <DatePicker
                label="Valid Till"
                value={identityData?.validTill || null}
                onChange={(newValue) => setIdentityData({ ...identityData, validTill: newValue })}
                className="w-full bg-white lg:w-1/2"
              />
            </LocalizationProvider>
          </div>
          <div
            id="line4"
            className="flex flex-col items-center w-full space-y-5 lg:flex-row lg:justify-evenly lg:space-x-10 lg:space-y-0"
          >
            <div
              onClick={() => setIsModalOpen("identity-nationality")}
              className={
                "bg-white lg:w-1/2 w-full py-4 pl-4 border border-gray-300 text-gray-600 rounded cursor-pointer"
              }
            >
              {identityData?.nationality ? identityData?.nationality : "Nationality"}
            </div>
            <div
              onClick={() => setIsModalOpen("country-one")}
              className={
                "bg-white lg:w-1/2 w-full py-4 pl-4 border border-gray-300 text-gray-600 rounded cursor-pointer"
              }
            >
              {identityData?.issueCountry ? identityData?.issueCountry : "Issue Country"}
            </div>
          </div>
        </div>
      ) : null}
      {documentWindow === "permit" ? (
        <div
          id="dl"
          className="flex-col items-center w-full px-4 py-4 space-y-5 lg:px-10 lg:py-10 lg:space-y-10"
        >
          <div
            id="line4"
            className="flex flex-col w-full space-y-5 lg:flex-row lg:justify-evenly lg:space-x-10 lg:space-y-0"
          >
            <div className="flex flex-col items-center justify-between w-full lg:w-1/2">
              <div
                className={clsx(
                  fileName ? "border-green-500" : "border-primary",
                  filetype === ""
                    ? "border-primary"
                    : filetype === "true"
                    ? "border-green-500"
                    : "border-red-500",
                  "flex items-center justify-between w-full px-5 py-4 border-2 rounded-lg group",
                )}
              >
                <div className="flex items-center space-x-2">
                  <PassportIcon
                    width="23px"
                    height="29px"
                    fillColor={clsx(
                      fileName
                        ? filetype === "true"
                          ? "fill-green-500"
                          : "fill-red-500"
                        : "fill-primary",
                    )}
                  />
                  {!fileName ? (
                    <div className="text-primary">Upload Residential permit </div>
                  ) : (
                    <div
                      className={clsx(
                        filetype === "true"
                          ? "font-semibold text-green-500"
                          : "font-semibold text-red-500",
                      )}
                    >
                      {fileName}
                    </div>
                  )}
                </div>
                <div>
                  {fileName ? (
                    <div className="flex items-center space-x-2">
                      <button
                        onClick={handleRemove}
                        className="transition-all cursor-pointer text-primary group-hover:scale-125"
                      >
                        Remove
                      </button>
                    </div>
                  ) : (
                    <label htmlFor="file-input">
                      <div className="transition-all cursor-pointer group-hover:scale-125">
                        <UploadIcon fillColor={"fill-primary"} />
                      </div>
                      <input
                        id="file-input"
                        type="file"
                        onChange={handleFileChange}
                        style={{ display: "none" }}
                      />
                    </label>
                  )}
                </div>
              </div>
              {filetype === "false" ? (
                <div className="text-red-500">
                  This file format is not supported. Please try file formats like jpg, png or pdf.
                </div>
              ) : null}
            </div>

            <TextField
              variant="outlined"
              label="Enter Residential permit card number"
              type="text"
              onChange={(e: any) =>
                setIdentityData({
                  ...identityData,
                  docNumber: e.target.value,
                })
              }
              className="h-full px-5 py-5 border border-black rounded-lg lg:w-1/2"
              sx={{ backgroundColor: "white" }}
            />
          </div>
          <div
            id="line2"
            className="flex flex-col w-full space-y-5 lg:flex-row lg:justify-evenly lg:space-x-10 lg:space-y-0"
          >
            <LocalizationProvider dateAdapter={AdapterDayjs}>
              <DatePicker
                label="DOB as per Residential permit (DD - MM - YYYY)"
                value={identityData?.doc_dob || null}
                onChange={(newValue) => setIdentityData({ ...identityData, doc_dob: newValue })}
                className="w-full bg-white lg:w-1/2"
              />
            </LocalizationProvider>
            <div className="flex w-full lg:w-1/2 group">
              <SimpleDropdown options={data} onSelect={(e: any) => handleSelectIdentityTitle(e)} />
              <TextField
                type="text"
                variant="outlined"
                label="Name as on Residential permit"
                onChange={(e: any) =>
                  setIdentityData({
                    ...identityData,
                    name: e.target.value,
                  })
                }
                className={
                  "w-5/6 px-5 py-5 border-t border-b border-r rounded-r-lg bg-white border-black"
                }
              />
            </div>
          </div>
          <div
            id="line3"
            className="flex flex-col w-full space-y-5 lg:flex-row lg:justify-evenly lg:space-x-10 lg:space-y-0"
          >
            <SearchableDropdown
              options={genderList}
              label="Select Gender"
              mainClassname={"bg-white lg:w-1/2 w-full"} // add an onclick prop here ffs retard
              onChange={(e: any, v: any) => setIdentityData({ ...identityData, gender: v })}
            />
            <LocalizationProvider dateAdapter={AdapterDayjs}>
              <DatePicker
                label="Valid Till"
                value={identityData?.validTill || null}
                onChange={(newValue) => setIdentityData({ ...identityData, validTill: newValue })}
                className="w-full bg-white lg:w-1/2"
              />
            </LocalizationProvider>
          </div>
          <div
            id="line4"
            className="flex flex-col items-center w-full space-y-5 lg:flex-row lg:justify-evenly lg:space-x-10 lg:space-y-0"
          >
            <div
              onClick={() => setIsModalOpen("identity-nationality")}
              className={
                "bg-white lg:w-1/2 w-full py-4 pl-4 border border-gray-300 text-gray-600 rounded cursor-pointer"
              }
            >
              {identityData?.nationality ? identityData?.nationality : "Nationality"}
            </div>
            <div
              onClick={() => setIsModalOpen("country-one")}
              className={
                "bg-white lg:w-1/2 w-full py-4 pl-4 border border-gray-300 text-gray-600 rounded cursor-pointer"
              }
            >
              {identityData?.issueCountry ? identityData?.issueCountry : "Issue Country"}
            </div>
          </div>
        </div>
      ) : null}
      {documentWindow === "taxbill" ? (
        <div
          id="taxbill"
          className="flex-col items-center w-full px-4 py-4 space-y-5 lg:px-10 lg:py-10 lg:space-y-10"
        >
          <div id="line1" className="flex items-center w-full lg:justify-start lg:space-x-10">
            <div className="flex flex-col items-center justify-between lg:w-[47%] w-full">
              <div
                className={clsx(
                  fileName ? "border-green-500" : "border-primary",
                  filetype === ""
                    ? "border-primary"
                    : filetype === "true"
                    ? "border-green-500"
                    : "border-red-500",
                  "flex items-center justify-between w-full px-5 py-4 border-2 rounded-lg group",
                )}
              >
                <div className="flex items-center space-x-2">
                  <PassportIcon
                    width="23px"
                    height="29px"
                    fillColor={clsx(
                      fileName
                        ? filetype === "true"
                          ? "fill-green-500"
                          : "fill-red-500"
                        : "fill-primary",
                    )}
                  />
                  {!fileName ? (
                    <div className="text-primary">Upload Tax Bill </div>
                  ) : (
                    <div
                      className={clsx(
                        filetype === "true"
                          ? "font-semibold text-green-500"
                          : "font-semibold text-red-500",
                      )}
                    >
                      {fileName}
                    </div>
                  )}
                </div>
                <div>
                  {fileName ? (
                    <div className="flex items-center space-x-2">
                      <button
                        onClick={handleRemove}
                        className="transition-all cursor-pointer text-primary group-hover:scale-125"
                      >
                        Remove
                      </button>
                    </div>
                  ) : (
                    <label htmlFor="file-input2">
                      <div className="transition-all cursor-pointer group-hover:scale-125">
                        <UploadIcon fillColor={"fill-primary"} />
                      </div>
                      <input
                        id="file-input2"
                        type="file"
                        onChange={handleAddressFileChange}
                        style={{ display: "none" }}
                      />
                    </label>
                  )}
                </div>
              </div>
            </div>
            {filetype === "false" ? (
              <div className="text-red-500">
                This file format is not supported. Please try file formats like jpg, png or pdf.
              </div>
            ) : null}
          </div>
          <div
            id="line2"
            className="flex flex-col items-center w-full space-y-5 lg:flex-row lg:space-y-0 lg:justify-evenly lg:space-x-10"
          >
            <TextField
              variant="outlined"
              label="Address Line 1 as per Tax bill"
              type="text"
              onChange={(e: any) =>
                setAddressData({
                  ...addressData,
                  addOne: e.target.value,
                })
              }
              className="w-full h-full px-5 py-5 border border-black rounded-lg lg:w-1/2"
              sx={{ backgroundColor: "white" }}
            />

            <TextField
              variant="outlined"
              label="(Address Line 2)"
              type="text"
              onChange={(e: any) =>
                setAddressData({
                  ...addressData,
                  addTwo: e.target.value,
                })
              }
              className="w-full h-full px-5 py-5 border border-black rounded-lg lg:w-1/2"
              sx={{ backgroundColor: "white" }}
            />
          </div>
          <div
            id="line3"
            className="flex flex-col items-center w-full space-y-5 lg:flex-row lg:space-y-0 lg:justify-evenly lg:space-x-10"
          >
            <div
              onClick={() => setIsModalOpen("address-country")}
              className={
                "bg-white lg:w-1/2 w-full py-4 pl-4 border border-gray-300 text-gray-600 rounded cursor-pointer"
              }
            >
              {addressData?.country ? addressData?.country : "Issue Country"}
            </div>
            <TextField
              variant="outlined"
              label="Enter Pincode"
              type="text"
              onChange={(e: any) =>
                setAddressData({
                  ...addressData,
                  pincode: e.target.value,
                })
              }
              className="w-full h-full px-5 py-5 border border-black rounded-lg lg:w-1/2"
              sx={{ backgroundColor: "white" }}
            />
          </div>
          <div
            id="line4"
            className="flex flex-col items-center w-full space-y-5 lg:flex-row lg:space-y-0 lg:justify-evenly lg:space-x-10"
          >
            <TextField
              variant="outlined"
              label="Enter City"
              type="text"
              onChange={(e: any) =>
                setAddressData({
                  ...addressData,
                  city: e.target.value,
                })
              }
              className="w-full h-full px-5 py-5 border border-black rounded-lg lg:w-1/2"
              sx={{ backgroundColor: "white" }}
            />

            <TextField
              variant="outlined"
              label="Enter State"
              type="text"
              onChange={(e: any) =>
                setAddressData({
                  ...addressData,
                  state: e.target.value,
                })
              }
              className="w-full h-full px-5 py-5 border border-black rounded-lg lg:w-1/2"
              sx={{ backgroundColor: "white" }}
            />
          </div>
          <div
            id="line5"
            className="flex flex-col items-center w-full space-y-5 lg:flex-row lg:space-y-0 lg:justify-evenly lg:space-x-10"
          >
            <div
              onClick={() => setIsModalOpenP("sourceOfFund")}
              className="flex items-center justify-between w-full h-16 px-5 bg-white border border-gray-300 rounded cursor-pointer"
            >
              {sourceOfFundP ? sourceOfFundP : "Source Of Fund*"}
              {<DropdownIcon />}
            </div>
            <div
              onClick={() => setIsModalOpenP("remmitanceFrequency")}
              className="flex items-center justify-between w-full h-16 px-5 bg-white border border-gray-300 rounded cursor-pointer"
            >
              {remmitanceFrequencyP ? remmitanceFrequencyP : "Remmitance Frequency *"}
              {<DropdownIcon />}
            </div>
          </div>
        </div>
      ) : null}
      {documentWindow === "utilitybill" ? (
        <div
          id="utilitybill"
          className="flex-col items-center w-full px-4 py-4 space-y-5 lg:px-10 lg:py-10 lg:space-y-10"
        >
          <div id="line1" className="flex items-center w-full lg:justify-start lg:space-x-10">
            <div className="flex flex-col items-center justify-between lg:w-[47%] w-full">
              <div
                className={clsx(
                  fileName ? "border-green-500" : "border-primary",
                  filetype === ""
                    ? "border-primary"
                    : filetype === "true"
                    ? "border-green-500"
                    : "border-red-500",
                  "flex items-center justify-between w-full px-5 py-4 border-2 rounded-lg group",
                )}
              >
                <div className="flex items-center space-x-2">
                  <PassportIcon
                    width="23px"
                    height="29px"
                    fillColor={clsx(
                      fileName
                        ? filetype === "true"
                          ? "fill-green-500"
                          : "fill-red-500"
                        : "fill-primary",
                    )}
                  />
                  {!fileName ? (
                    <div className="text-primary">Upload Tax Bill </div>
                  ) : (
                    <div
                      className={clsx(
                        filetype === "true"
                          ? "font-semibold text-green-500"
                          : "font-semibold text-red-500",
                      )}
                    >
                      {fileName}
                    </div>
                  )}
                </div>
                <div>
                  {fileName ? (
                    <div className="flex items-center space-x-2">
                      <button
                        onClick={handleRemove}
                        className="transition-all cursor-pointer text-primary group-hover:scale-125"
                      >
                        Remove
                      </button>
                    </div>
                  ) : (
                    <label htmlFor="file-input2">
                      <div className="transition-all cursor-pointer group-hover:scale-125">
                        <UploadIcon fillColor={"fill-primary"} />
                      </div>
                      <input
                        id="file-input2"
                        type="file"
                        onChange={handleAddressFileChange}
                        style={{ display: "none" }}
                      />
                    </label>
                  )}
                </div>
              </div>
            </div>
            {filetype === "false" ? (
              <div className="text-red-500">
                This file format is not supported. Please try file formats like jpg, png or pdf.
              </div>
            ) : null}
          </div>
          <div
            id="line2"
            className="flex flex-col items-center w-full space-y-5 lg:flex-row lg:space-y-0 lg:justify-evenly lg:space-x-10"
          >
            <TextField
              variant="outlined"
              label="Enter address as on Utility bill (Address Line 1)"
              type="text"
              onChange={(e: any) =>
                setAddressData({
                  ...addressData,
                  addOne: e.target.value,
                })
              }
              className="w-full h-full px-5 py-5 border border-black rounded-lg lg:w-1/2"
              sx={{ backgroundColor: "white" }}
            />

            <TextField
              variant="outlined"
              label="(Address Line 2)"
              type="text"
              onChange={(e: any) =>
                setAddressData({
                  ...addressData,
                  addTwo: e.target.value,
                })
              }
              className="w-full h-full px-5 py-5 border border-black rounded-lg lg:w-1/2"
              sx={{ backgroundColor: "white" }}
            />
          </div>
          <div
            id="line3"
            className="flex flex-col items-center w-full space-y-5 lg:flex-row lg:space-y-0 lg:justify-evenly lg:space-x-10"
          >
            <div
              onClick={() => setIsModalOpen("address-country")}
              className={
                "bg-white lg:w-1/2 w-full py-4 pl-4 border border-gray-300 text-gray-600 rounded cursor-pointer"
              }
            >
              {addressData?.country ? addressData?.country : "Issue Country"}
            </div>
            <TextField
              variant="outlined"
              label="Enter Pincode"
              type="text"
              onChange={(e: any) =>
                setAddressData({
                  ...addressData,
                  pincode: e.target.value,
                })
              }
              className="w-full h-full px-5 py-5 border border-black rounded-lg lg:w-1/2"
              sx={{ backgroundColor: "white" }}
            />
          </div>
          <div
            id="line4"
            className="flex flex-col items-center w-full space-y-5 lg:flex-row lg:space-y-0 lg:justify-evenly lg:space-x-10"
          >
            <TextField
              variant="outlined"
              label="Enter City"
              type="text"
              onChange={(e: any) =>
                setAddressData({
                  ...addressData,
                  city: e.target.value,
                })
              }
              className="w-full h-full px-5 py-5 border border-black rounded-lg lg:w-1/2"
              sx={{ backgroundColor: "white" }}
            />

            <TextField
              variant="outlined"
              label="Enter State"
              type="text"
              onChange={(e: any) =>
                setAddressData({
                  ...addressData,
                  state: e.target.value,
                })
              }
              className="w-full h-full px-5 py-5 border border-black rounded-lg lg:w-1/2"
              sx={{ backgroundColor: "white" }}
            />
          </div>
          <div
            id="line5"
            className="flex flex-col items-center w-full space-y-5 lg:flex-row lg:space-y-0 lg:justify-evenly lg:space-x-10"
          >
            <div
              onClick={() => setIsModalOpenP("sourceOfFund")}
              className="flex items-center justify-between w-full h-16 px-5 bg-white border border-gray-300 rounded cursor-pointer"
            >
              {sourceOfFundP ? sourceOfFundP : "Source Of Fund*"}
              {<DropdownIcon />}
            </div>
            <div
              onClick={() => setIsModalOpenP("remmitanceFrequency")}
              className="flex items-center justify-between w-full h-16 px-5 bg-white border border-gray-300 rounded cursor-pointer"
            >
              {remmitanceFrequencyP ? remmitanceFrequencyP : "Remmitance Frequency *"}
              {<DropdownIcon />}
            </div>
          </div>
        </div>
      ) : null}
      {documentWindow === "statement" ? (
        <div
          id="statement"
          className="flex-col items-center w-full px-4 py-4 space-y-5 lg:px-10 lg:py-10 lg:space-y-10"
        >
          <div id="line1" className="flex items-center w-full lg:justify-start lg:space-x-10">
            <div className="flex flex-col items-center justify-between lg:w-[47%] w-full">
              <div
                className={clsx(
                  fileName ? "border-green-500" : "border-primary",
                  filetype === ""
                    ? "border-primary"
                    : filetype === "true"
                    ? "border-green-500"
                    : "border-red-500",
                  "flex items-center justify-between w-full px-5 py-4 border-2 rounded-lg group",
                )}
              >
                <div className="flex items-center space-x-2">
                  <PassportIcon
                    width="23px"
                    height="29px"
                    fillColor={clsx(
                      fileName
                        ? filetype === "true"
                          ? "fill-green-500"
                          : "fill-red-500"
                        : "fill-primary",
                    )}
                  />
                  {!fileName ? (
                    <div className="text-primary">Upload 3 months of bank statetment </div>
                  ) : (
                    <div
                      className={clsx(
                        filetype === "true"
                          ? "font-semibold text-green-500"
                          : "font-semibold text-red-500",
                      )}
                    >
                      {fileName}
                    </div>
                  )}
                </div>
                <div>
                  {fileName ? (
                    <div className="flex items-center space-x-2">
                      <button
                        onClick={handleRemove}
                        className="transition-all cursor-pointer text-primary group-hover:scale-125"
                      >
                        Remove
                      </button>
                    </div>
                  ) : (
                    <label htmlFor="file-input2">
                      <div className="transition-all cursor-pointer group-hover:scale-125">
                        <UploadIcon fillColor={"fill-primary"} />
                      </div>
                      <input
                        id="file-input2"
                        type="file"
                        onChange={handleAddressFileChange}
                        style={{ display: "none" }}
                      />
                    </label>
                  )}
                </div>
              </div>
            </div>
            {filetype === "false" ? (
              <div className="text-red-500">
                This file format is not supported. Please try file formats like jpg, png or pdf.
              </div>
            ) : null}
          </div>
          <div
            id="line2"
            className="flex flex-col items-center w-full space-y-5 lg:flex-row lg:space-y-0 lg:justify-evenly lg:space-x-10"
          >
            <TextField
              variant="outlined"
              label="Enter address as on Bank statement (Address Line 1)"
              type="text"
              onChange={(e: any) =>
                setAddressData({
                  ...addressData,
                  addOne: e.target.value,
                })
              }
              className="w-full h-full px-5 py-5 border border-black rounded-lg lg:w-1/2"
              sx={{ backgroundColor: "white" }}
            />

            <TextField
              variant="outlined"
              label="(Address Line 2)"
              type="text"
              onChange={(e: any) =>
                setAddressData({
                  ...addressData,
                  addTwo: e.target.value,
                })
              }
              className="w-full h-full px-5 py-5 border border-black rounded-lg lg:w-1/2"
              sx={{ backgroundColor: "white" }}
            />
          </div>
          <div
            id="line3"
            className="flex flex-col items-center w-full space-y-5 lg:flex-row lg:space-y-0 lg:justify-evenly lg:space-x-10"
          >
            <div
              onClick={() => setIsModalOpen("address-country")}
              className={
                "bg-white lg:w-1/2 w-full py-4 pl-4 border border-gray-300 text-gray-600 rounded cursor-pointer"
              }
            >
              {addressData?.country ? addressData?.country : "Issue Country"}
            </div>
            <TextField
              variant="outlined"
              label="Enter Pincode"
              type="text"
              onChange={(e: any) =>
                setAddressData({
                  ...addressData,
                  pincode: e.target.value,
                })
              }
              className="w-full h-full px-5 py-5 border border-black rounded-lg lg:w-1/2"
              sx={{ backgroundColor: "white" }}
            />
          </div>
          <div
            id="line4"
            className="flex flex-col items-center w-full space-y-5 lg:flex-row lg:space-y-0 lg:justify-evenly lg:space-x-10"
          >
            <TextField
              variant="outlined"
              label="Enter City"
              type="text"
              onChange={(e: any) =>
                setAddressData({
                  ...addressData,
                  city: e.target.value,
                })
              }
              className="w-full h-full px-5 py-5 border border-black rounded-lg lg:w-1/2"
              sx={{ backgroundColor: "white" }}
            />

            <TextField
              variant="outlined"
              label="Enter State"
              type="text"
              onChange={(e: any) =>
                setAddressData({
                  ...addressData,
                  state: e.target.value,
                })
              }
              className="w-full h-full px-5 py-5 border border-black rounded-lg lg:w-1/2"
              sx={{ backgroundColor: "white" }}
            />
          </div>
          <div
            id="line5"
            className="flex flex-col items-center w-full space-y-5 lg:flex-row lg:space-y-0 lg:justify-evenly lg:space-x-10"
          >
            <div
              onClick={() => setIsModalOpenP("sourceOfFund")}
              className="flex items-center justify-between w-full h-16 px-5 bg-white border border-gray-300 rounded cursor-pointer"
            >
              {sourceOfFundP ? sourceOfFundP : "Source Of Fund*"}
              {<DropdownIcon />}
            </div>
            <div
              onClick={() => setIsModalOpenP("remmitanceFrequency")}
              className="flex items-center justify-between w-full h-16 px-5 bg-white border border-gray-300 rounded cursor-pointer"
            >
              {remmitanceFrequencyP ? remmitanceFrequencyP : "Remmitance Frequency *"}
              {<DropdownIcon />}
            </div>
          </div>
        </div>
      ) : null}

      {/* issue country address */}
      <Modal
        header="Issue Country"
        headerClass={
          "bg-primary font-bold flex justify-center itms-center pl-12 text-[20px] !m-0 py-4 text-white"
        }
        modalClass="!p-0 !rounded-t-[10px] overflow-y-scroll max-h-[90%] !w-full lg:!w-auto"
        visible={isModalOpen === "address-country"}
        closeHandler={() => {
          setIsModalOpen("");
          setSearchInput("");
        }}
      >
        <div className="flex flex-col items-center px-2 pt-4 ">
          <div className="h-12 lg:border-none w-full flex justify-center lg:w-[404px] mb-9">
            <TextField
              name="searchValue"
              type="text"
              placeholder={"Start typing country name..."}
              value={searchInput}
              fullWidth
              className="border rounded"
              onChange={(e) => setSearchInput(e.target.value)}
            />
          </div>
          <div className="lg:mx-[68px] overflow-y-auto">
            {countryData?.length === 0 ? (
              <div className="pb-64 text-xl font-normal text-center text-primary-650">
                {`"${searchInput}" cannot be found`}
              </div>
            ) : (
              countryData.map((data: any, index: any) => {
                return (
                  <div
                    className="flex items-center justify-between w-full py-6 transition-all border-b cursor-pointer lg:mb-11 lg:border-none lg:py-0 border-grey-1000"
                    key={index}
                    onClick={() => {
                      setAddressData({
                        ...addressData,
                        country: data.code,
                      });
                      setIsModalOpen("");
                    }}
                  >
                    <div className="mr-8 text-[18px] hover:text-[20px] transition-all hover:font-bold font-normal text-primary-650">
                      {data.country}
                    </div>
                  </div>
                );
              })
            )}
          </div>
        </div>
      </Modal>

      {/* country of origin, country-one */}
      <Modal
        header="Select Nationality"
        headerClass={
          "bg-primary font-bold flex justify-center itms-center pl-12 text-[20px] !m-0 py-4 text-white"
        }
        modalClass="!p-0 !rounded-t-[10px] overflow-y-scroll max-h-[90%] !w-full lg:!w-auto"
        visible={isModalOpen === "identity-nationality"}
        closeHandler={() => {
          setIsModalOpen("");
          setSearchInput("");
        }}
      >
        <div className="flex flex-col items-center px-2 pt-4 ">
          <div className="h-12 lg:border-none w-full flex justify-center lg:w-[404px] mb-9">
            <TextField
              name="searchValue"
              type="text"
              placeholder={"Start typing country name..."}
              value={searchInput}
              fullWidth
              className="border rounded"
              onChange={(e) => setSearchInput(e.target.value)}
            />
          </div>
          <div className="lg:mx-[68px] overflow-y-auto">
            {countryData?.length === 0 ? (
              <div className="pb-64 text-xl font-normal text-center text-primary-650">
                {`"${searchInput}" cannot be found`}
              </div>
            ) : (
              countryData.map((data: any, index: any) => {
                return (
                  <div
                    className="flex items-center justify-between w-full py-6 transition-all border-b cursor-pointer lg:mb-11 lg:border-none lg:py-0 border-grey-1000"
                    key={index}
                    onClick={() => {
                      setIdentityData({
                        ...identityData,
                        nationality: data.code,
                      });
                      setIsModalOpen("");
                    }}
                  >
                    <div className="mr-8 text-[18px] hover:text-[20px] transition-all hover:font-bold font-normal text-primary-650">
                      {data.country}
                    </div>
                  </div>
                );
              })
            )}
          </div>
        </div>
      </Modal>

      {/* nationaltiy */}
      <Modal
        header="Country of Origin"
        headerClass={
          "bg-primary font-bold flex justify-center itms-center pl-12 text-[20px] !m-0 py-4 text-white"
        }
        modalClass="!p-0 !rounded-t-[10px] overflow-y-scroll max-h-[90%] !w-full lg:!w-auto"
        visible={isModalOpen === "country-one"}
        closeHandler={() => {
          setIsModalOpen("");
          setSearchInput("");
        }}
      >
        <div className="flex flex-col items-center px-2 pt-4 ">
          <div className="h-12 lg:border-none w-full flex justify-center lg:w-[404px] mb-9">
            <TextField
              name="searchValue"
              type="text"
              placeholder={"Start typing country name..."}
              value={searchInput}
              fullWidth
              className="border rounded"
              onChange={(e) => setSearchInput(e.target.value)}
            />
          </div>
          <div className="lg:mx-[68px] overflow-y-auto">
            {countryData?.length === 0 ? (
              <div className="pb-64 text-xl font-normal text-center text-primary-650">
                {`"${searchInput}" cannot be found`}
              </div>
            ) : (
              countryData.map((data: any, index: any) => {
                return (
                  <div
                    className="flex items-center justify-between w-full py-6 transition-all border-b cursor-pointer lg:mb-11 lg:border-none lg:py-0 border-grey-1000"
                    key={index}
                    onClick={() => {
                      setIdentityData({
                        ...identityData,
                        issueCountry: data.code,
                      });
                      setIsModalOpen("");
                    }}
                  >
                    <div className="mr-8 text-[18px] hover:text-[20px] transition-all hover:font-bold font-normal text-primary-650">
                      {data.country}
                    </div>
                  </div>
                );
              })
            )}
          </div>
        </div>
      </Modal>
    </div>
  );
};
