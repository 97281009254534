import React from "react";
import { IIcons } from "./icons";

const InfoIcon = ({ fillColor }: IIcons.IconProps) => (
  <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      d="M11.8125 0C5.28906 0 0 5.28906 0 11.8125C0 18.3359 5.28906 23.625 11.8125 23.625C18.3359 23.625 23.625 18.3359 23.625 11.8125C23.625 5.28906 18.3359 0 11.8125 0ZM14.2734 18.3086C13.6641 18.5469 13.1797 18.7305 12.8164 18.8555C12.4531 18.9805 12.0352 19.043 11.5547 19.043C10.8203 19.043 10.2461 18.8633 9.83594 18.5039C9.42969 18.1484 9.22656 17.6914 9.22656 17.1367C9.22656 16.9219 9.24219 16.7031 9.27344 16.4805C9.30078 16.2539 9.35156 16.0039 9.41797 15.7188L10.1797 13.0312C10.2461 12.7734 10.3047 12.5312 10.3516 12.3008C10.3984 12.0703 10.418 11.8594 10.418 11.668C10.418 11.3281 10.3477 11.0859 10.207 10.9531C10.0625 10.8164 9.79297 10.75 9.39453 10.75C9.19922 10.75 8.99609 10.7812 8.78906 10.8398C8.58203 10.9023 8.40625 10.9609 8.25781 11.0156L8.46094 10.1875C8.95703 9.98438 9.43359 9.8125 9.89062 9.66797C10.3438 9.52344 10.7773 9.44922 11.1797 9.44922C11.9102 9.44922 12.4766 9.62891 12.8711 9.98047C13.2656 10.332 13.4648 10.793 13.4648 11.3555C13.4648 11.4727 13.4531 11.6797 13.4258 11.9727C13.3984 12.2656 13.3477 12.5352 13.2734 12.7852L12.5156 15.4648C12.4531 15.6797 12.3984 15.9258 12.3477 16.1992C12.3008 16.4727 12.2773 16.6836 12.2773 16.8242C12.2773 17.1797 12.3555 17.4258 12.5156 17.5547C12.6719 17.6836 12.9492 17.7461 13.3438 17.7461C13.5273 17.7461 13.7344 17.7148 13.9688 17.6523C14.1992 17.5859 14.3672 17.5312 14.4727 17.4805L14.2734 18.3086ZM14.1367 7.42969C13.7852 7.75781 13.3594 7.92188 12.8633 7.92188C12.3672 7.92188 11.9375 7.75781 11.582 7.42969C11.2266 7.10156 11.0508 6.70312 11.0508 6.23828C11.0508 5.77344 11.2305 5.37109 11.582 5.03906C11.9375 4.70703 12.3672 4.54297 12.8633 4.54297C13.3594 4.54297 13.7852 4.70703 14.1367 5.03906C14.4922 5.37109 14.668 5.77344 14.668 6.23828C14.668 6.70312 14.4922 7.10156 14.1367 7.42969Z"
      // fill={"#FF4E42" || fillColor}
      fill={"blue"}
      className={fillColor}
    />
  </svg>
);
export default InfoIcon;
