/**
 * @description Component for using dropdown
 * @param {boolean} showDropdown - Flag to toggle the dropdown state
 * @param {string | React.ReactNode} dropdownLabel -  Question/Label shown when no value is selected on the button
 * @param {string | number} dropdownValue - Value of the selected item shown on the button
 * @param {React.ReactNode} dropdownIcon - What should be the icon of the dropdown
 * @param {string[] | number[] | React.ReactNode[]} list - Items to be shown when dropdown is open
 * @param {boolean} disabled - Flag to disbale the dropdown button
 * @param {string} errorMessage - To display the error message
 * @param {function} onBlur - Method to handle when onBlur event happens
 * @param {function} dropdownButtonOnClick - Method to handle when button is clicked
 * @param {string} dropdownClassname - Styings to be applied to dropdown
 * @param {string} errorMessageClassname - Styings to be applied to error body
 * @param {string} dropdownBodyClassname - Styings to be applied to dropdown body.
 * @param {string} width - Width of the dropdown
 * @param {string} height - Height of the dropdown
 * @param {string} id - ID passed to the dropdown
 * @param {string[]} listId - ID passed to the list items
 * @param {React.ReactNode} children - To display childrens of Dropdown element
 * @param {function} listItemOnClick - Methiod to handle lost items click event
 * @param {LegacyRef<HTMLDivElement>} dropdownRef - Ref to access the dropdown
 * @returns {React.FunctionComponent<IDropdown.IProps>} - Returns JSX for dropdown
 */
/* eslint-disable */
import React from "react";
import clsx from "clsx";

import { IDropdown } from "./Dropdown";
import { DropdownIcon } from "../../../assets/Icons";
import ErrorField from "../ErrorField";

const Dropdown: React.FunctionComponent<IDropdown.IProps> = ({
  showDropdown,
  dropdownLabel,
  dropdownValue,
  dropdownIcon = <DropdownIcon fillColor="stroke-black" />,
  list = [],
  disabled,
  errorMessage,
  dropdownClassname = "bg-white",
  errorMessageClassname,
  dropdownBodyClassname,
  width = "w-96",
  height,
  id,
  onBlur,
  listId = [],
  children,
  listItemOnClick,
  dropdownButtonOnClick,
  dropdownRef,
  countryList,
  dropdownButtonClassname,
  floatingLabel,
}) => {
  const getRandomId = () => {
    const id = window.crypto.getRandomValues(new Uint32Array(1))[0];
    return id;
  };

  return (
    <div id="dropdown-button-upload" className="w-full" ref={dropdownRef}>
      <button
        onClick={dropdownButtonOnClick}
        onBlur={onBlur}
        id={id}
        className={clsx(
          "focus:outline-none inline-flex text-left flex-col relative",
          showDropdown ? "rounded-t" : "rounded",
          disabled ? "cursor-not-allowed" : "",
          height,
          !countryList && width,
          dropdownClassname,
        )}
        type="button"
        disabled={disabled}
      >
        <div
          className={clsx(
            !countryList && "px-8 py-6",
            "flex items-center w-max sm:w-full xs:w-full md:mx-auto sm:mx-0 justify-end space-x-2",
            dropdownButtonClassname,
          )}
        >
          <div
            className={`flex justify-start absolute left-3 ${
              floatingLabel && showDropdown
                ? "-top-3 text-blue-600 text-sm bg-white"
                : "top-half text-gray-600"
            }`}
          >
            {dropdownValue ? dropdownValue : dropdownLabel}
          </div>
          <div>{dropdownIcon}</div>
        </div>
        <div
          className={clsx(
            "z-20 bg-white divide-y divide-gray-100 rounded-b absolute top-[50px] left-0 w-max overflow-auto shadow-2xl",
            !showDropdown && "hidden",
            dropdownBodyClassname,
            !countryList && width,
          )}
        >
          {children ? (
            children
          ) : countryList ? (
            <ul className="py-1 text-sm text-gray-700 dark:text-black">
              {countryList.map((el, id) => (
                <li
                  onClick={listItemOnClick}
                  className="block px-8 py-6 hover:bg-gray-100"
                  key={id + getRandomId()}
                  id={listId[id]}
                >
                  <span className="flex items-center justify-start space-x-2 text-base font-medium text-black font-lato">
                    <img src={`/images/png/${el.icon}`} alt="" width="35px" height="35px" />
                    <div>{el.name}</div>
                  </span>
                </li>
              ))}
            </ul>
          ) : (
            <ul className="py-1 text-sm text-gray-700 xs:h-24 md:h-72 md:overflow-auto xs:w-full xs:overflow-scroll dark:text-black">
              {list.map((el, id) => (
                <li
                  onClick={listItemOnClick}
                  className="block px-8 py-6 hover:bg-gray-100"
                  key={id + getRandomId()}
                  id={listId[id]}
                >
                  {el}
                </li>
              ))}
            </ul>
          )}
        </div>
      </button>
      {errorMessage && (
        <ErrorField errorClassname={errorMessageClassname} errorMessage={errorMessage} />
      )}
    </div>
  );
};

export default Dropdown;
