import React from "react";

const LoginSuccessFullLoader = () => {
  return (
    <div className="flex flex-col items-center justify-center h-screen bg-primary-100">
      <div className="w-[163px] h-[163px] rounded-full bg-primary-1000 mb-[88px] animate-bounce-slow" />
      <div className="font-bold text-[32px] text-primary-1050">Just there! please wait</div>
      <div className="w-[64%] text-lg italic font-normal text-center sm:text-2xl sm:w-1/5 text-primary-1150">
        We are fetching your data, just hold back and relax!
      </div>
    </div>
  );
};

export default LoginSuccessFullLoader;
