import React from "react";
import { IIcons } from "./icons";

const MobileIconNew = ({ width, height }: IIcons.IconProps) => (
  <svg
    id="Layer_1"
    data-name="Layer 1"
    xmlns="http://www.w3.org/2000/svg"
    viewBox="0 0 114.82 121.44"
    width={width}
    height={height}
  >
    <defs>
      <style>
        {`
            .cls-1 {
              fill: #322e33;
              opacity: .15;
            }
      
            .cls-1, .cls-2, .cls-3, .cls-4, .cls-5, .cls-6, .cls-7, .cls-8, .cls-9, .cls-10, .cls-11, .cls-12, .cls-13, .cls-14, .cls-15 {
              : 0px;
            }
      
            .cls-2 {
              fill: #6bc148;
            }
      
            .cls-3 {
              fill: #eceff1;
            }
      
            .cls-4 {
              fill: #000;
            }
      
            .cls-5 {
              fill: #559b38;
            }
      
            .cls-6 {
              fill: #f3a845;
            }
      
            .cls-7 {
              fill: #f2f2f2;
            }
      
            .cls-8 {
              fill: #a7c6f9;
            }
      
            .cls-9 {
              fill: #aa2b24;
            }
      
            .cls-10 {
              fill: #d24034;
            }
      
            .cls-11 {
              fill: #ccc;
            }
      
            .cls-12 {
              fill: #fff;
            }
      
            .cls-13 {
              fill: tomato;
            }
      
            .cls-14 {
              fill: #0f5bf1;
            }
      
            .cls-15 {
              fill: #eecc61;
            }
          `}
      </style>
    </defs>
    <ellipse className="cls-1" cx="57.41" cy="110.83" rx="55.92" ry="9.03" />
    <g>
      <path
        className="cls-12"
        d="M82.13,100.5c0,3.96-3.24,7.19-7.19,7.19h-39.57c-3.96,0-7.19-3.24-7.19-7.19V10.57c0-3.96,3.24-7.19,7.19-7.19h39.57c3.96,0,7.19,3.24,7.19,7.19v89.94Z"
      />
      <path
        className="cls-3"
        d="M82.13,100.48V8.9c-.05-.05-.08-.08-.14-.14h-44.83c-1.99,0-3.6,1.61-3.6,3.6v93.53c.65.65,1,1.01,1.65,1.65l39.7.12c3.98.01,7.22-3.21,7.22-7.19Z"
      />
      <path className="cls-14" d="M29.96,12.37h52.16v86.34H29.96V12.37Z" />
      <path className="cls-8" d="M28.16,12.37h5.4v86.34h-5.4V12.37Z" />
      <g>
        <path
          className="cls-4"
          d="M60.54,12.37h-10.79c-.99,0-1.8-.81-1.8-1.8s.81-1.8,1.8-1.8h10.79c.99,0,1.8.81,1.8,1.8s-.81,1.8-1.8,1.8Z"
        />
        <path
          className="cls-4"
          d="M74.93,109.5h-39.57c-4.96,0-8.99-4.04-8.99-8.99v-14.39c0-.99.81-1.8,1.8-1.8s1.8.81,1.8,1.8v14.39c0,2.98,2.42,5.4,5.4,5.4h39.57c2.98,0,5.4-2.42,5.4-5.4V10.57c0-2.98-2.42-5.4-5.4-5.4h-39.57c-2.98,0-5.4,2.42-5.4,5.4v17.99c0,1-.81,1.8-1.8,1.8s-1.8-.8-1.8-1.8V10.57c0-4.96,4.03-8.99,8.99-8.99h39.57c4.96,0,8.99,4.04,8.99,8.99v89.94c0,4.96-4.03,8.99-8.99,8.99Z"
        />
      </g>
      <g id="Layer_6" data-name="Layer 6">
        <path className="cls-15" d="M68.91,95.32h21.8v14.22h-21.8v-14.22Z" />
        <path className="cls-6" d="M67.97,90.58h23.7v4.74h-23.7v-4.74Z" />
        <path className="cls-15" d="M67.97,90.58v2.84h18.49c1.05,0,1.9-.85,1.9-1.9v-.95h-20.38Z" />
        <path
          className="cls-10"
          d="M75.36,90.04l1.62.54h.47c0-.75.36-1.45.96-1.9l-1.81-2.01c-1.27-1.43-3.1-2.25-5.01-2.25h-.78c.29,2.6,2.06,4.8,4.55,5.62Z"
        />
        <path className="cls-9" d="M77.92,95.32h4.27v14.22h-4.27v-14.22Z" />
        <path className="cls-5" d="M73.65,112.86v-11.38h22.75v11.38" />
        <path
          className="cls-11"
          d="M92.61,103.38c0,1.05.85,1.9,1.9,1.9v3.79c-1.05,0-1.9.85-1.9,1.9h-15.17c0-1.05-.85-1.9-1.9-1.9v-3.79c1.05,0,1.9-.85,1.9-1.9h15.17Z"
        />
        <path
          className="cls-7"
          d="M77.45,103.38c0,1.05-.85,1.9-1.9,1.9v3.79c.46,0,.9.17,1.24.47h12.03c1.57,0,2.84-1.27,2.84-2.84v-3.32h-14.22Z"
        />
        <circle className="cls-5" cx="85.03" cy="107.17" r="3.79" />
        <path
          className="cls-2"
          d="M85.03,103.38c-2.09,0-3.79,1.7-3.79,3.79,0,.86.29,1.7.83,2.37h3.43c1.05,0,1.9-.85,1.9-1.9v-3.43c-.67-.54-1.51-.83-2.37-.83h0Z"
        />
        <path
          className="cls-12"
          d="M85.03,106.7c-.26,0-.47-.21-.47-.47s.21-.47.47-.47.47.21.47.47h.95c0-.6-.38-1.13-.95-1.33v-.56h-.95v.56c-.74.26-1.13,1.07-.87,1.81.2.57.74.95,1.34.95.26,0,.47.21.47.47s-.21.47-.47.47-.47-.21-.47-.47h-.95c0,.6.38,1.13.95,1.33v.56h.95v-.56c.74-.26,1.13-1.07.87-1.81-.2-.57-.74-.95-1.34-.95Z"
        />
        <circle className="cls-5" cx="91.67" cy="107.17" r=".95" />
        <path
          className="cls-2"
          d="M90.72,107.17c0,.16.04.32.12.46.47-.06.82-.46.82-.94v-.47c-.52,0-.95.42-.95.95Z"
        />
        <circle className="cls-5" cx="78.39" cy="107.17" r=".95" />
        <path
          className="cls-2"
          d="M77.45,107.17c0,.16.04.32.12.46.47-.06.82-.46.82-.94v-.47c-.52,0-.95.42-.95.95Z"
        />
        <path
          className="cls-6"
          d="M79.82,88.21c-1.31,0-2.37,1.06-2.37,2.37h4.74c0-1.31-1.06-2.37-2.37-2.37Z"
        />
        <path
          className="cls-10"
          d="M87.57,84.42c-1.92,0-3.74.82-5.01,2.25l-1.63,1.83c.77.41,1.26,1.21,1.26,2.08l1.62-.54c2.48-.83,4.26-3.02,4.55-5.62h-.78Z"
        />
        <path className="cls-10" d="M77.45,90.58h5.21v4.74h-5.21v-4.74Z" />
        <path className="cls-2" d="M96.41,101.48h-22.75l1.9-1.9h18.96l1.9,1.9Z" />
        <path
          className="cls-15"
          d="M79.82,89.63c.62,0,1.17-.4,1.35-1-1.07-.75-2.54-.49-3.29.58-.09.13-.17.27-.23.41h2.17Z"
        />
      </g>
      <g>
        <path
          className="cls-12"
          d="M44.9,23.47c.23.23.34.55.34.96v1.79h-.77v-1.68c0-.24-.06-.42-.18-.55-.12-.13-.29-.19-.5-.19s-.38.06-.5.19c-.12.13-.18.31-.18.55v1.68h-.77v-1.68c0-.24-.06-.42-.18-.55-.12-.13-.29-.19-.5-.19s-.38.06-.5.19c-.12.13-.18.31-.18.55v1.68h-.77v-3.05h.77v.37c.1-.13.23-.23.38-.3.16-.07.33-.11.51-.11.24,0,.45.05.64.15.19.1.33.25.43.43.1-.18.24-.32.43-.42s.39-.16.61-.16c.37,0,.68.11.91.34Z"
        />
        <path
          className="cls-12"
          d="M46.54,26.07c-.23-.13-.42-.31-.55-.55-.13-.24-.2-.51-.2-.83s.07-.59.21-.83c.14-.24.33-.42.56-.55.24-.13.5-.2.8-.2s.56.06.8.2.43.31.56.55c.14.24.21.51.21.83s-.07.59-.21.83c-.14.24-.33.42-.57.55-.24.13-.51.2-.81.2s-.56-.06-.79-.2ZM47.73,25.5c.12-.07.22-.17.29-.31.07-.14.11-.3.11-.5,0-.29-.08-.51-.23-.67-.15-.16-.34-.23-.56-.23s-.4.08-.55.23c-.15.16-.22.38-.22.67s.07.51.22.67c.14.16.33.23.55.23.14,0,.27-.03.39-.1Z"
        />
        <path
          className="cls-12"
          d="M52.06,23.47c.22.23.34.55.34.96v1.79h-.77v-1.68c0-.24-.06-.43-.18-.56s-.29-.2-.5-.2-.38.06-.5.2-.18.32-.18.56v1.68h-.77v-3.05h.77v.38c.1-.13.23-.24.39-.31s.33-.11.53-.11c.36,0,.66.11.88.34Z"
        />
        <path
          className="cls-12"
          d="M55.94,24.93h-2.23c.02.22.1.39.23.52.14.12.3.19.5.19.29,0,.49-.12.61-.37h.83c-.09.29-.26.54-.51.72s-.56.28-.92.28c-.29,0-.56-.06-.79-.2-.23-.13-.41-.31-.54-.55-.13-.24-.2-.51-.2-.83s.06-.59.19-.83c.13-.24.31-.42.54-.55.23-.13.5-.19.8-.19s.55.06.78.19c.23.12.41.3.53.53s.19.49.19.79c0,.11,0,.21-.02.3ZM55.17,24.41c0-.2-.08-.36-.21-.48-.14-.12-.31-.18-.51-.18-.19,0-.35.06-.48.17-.13.12-.21.28-.24.48h1.45Z"
        />
        <path
          className="cls-12"
          d="M59.44,23.17l-1.89,4.49h-.82l.66-1.52-1.22-2.97h.86l.79,2.13.8-2.13h.82Z"
        />
        <path
          className="cls-12"
          d="M63.04,22.38v3.84h-.77v-1.63h-1.65v1.63h-.77v-3.84h.77v1.58h1.65v-1.58h.77Z"
        />
        <path
          className="cls-12"
          d="M64.6,26.01c-.3-.17-.54-.4-.72-.7-.18-.3-.26-.64-.26-1.02s.09-.71.26-1.01c.18-.3.41-.53.72-.7.3-.17.63-.25.99-.25s.69.08.99.25c.3.17.54.4.71.7.17.3.26.64.26,1.01s-.09.72-.26,1.02c-.17.3-.41.53-.71.7-.3.17-.63.25-.99.25s-.69-.08-.99-.25ZM66.2,25.41c.18-.1.31-.25.41-.45.1-.19.15-.42.15-.68s-.05-.48-.15-.67c-.1-.19-.24-.34-.41-.44-.18-.1-.38-.15-.61-.15s-.44.05-.61.15c-.18.1-.32.25-.42.44-.1.19-.15.42-.15.67s.05.48.15.68c.1.19.24.34.42.45.18.1.38.16.61.16s.44-.05.61-.16Z"
        />
        <path
          className="cls-12"
          d="M70.78,24.14c-.1.18-.25.32-.46.43-.21.11-.47.17-.78.17h-.64v1.48h-.77v-3.84h1.42c.3,0,.55.05.76.15.21.1.37.24.47.42.11.18.16.38.16.61,0,.21-.05.4-.15.58ZM69.98,23.98c.1-.1.15-.23.15-.41,0-.37-.21-.56-.63-.56h-.61v1.12h.61c.21,0,.37-.05.47-.15Z"
        />
      </g>
      <g>
        <path
          className="cls-12"
          d="M57.5,55.64c-.52.52-1.01,1.01-1.51,1.51-.18.18-.36.36-.54.54-.29.29-.58.57-.86.86-.16.17-.29.37-.47.52-.33.28-.64.61-1.13.65-.62.05-1.25-.41-1.34-1.03-.07-.44.05-.81.37-1.13,1.39-1.38,2.77-2.77,4.15-4.15.54-.54,1.07-1.08,1.61-1.62.64-.64,1.61-.55,2.06.2.11.18.17.41.18.62.01,2.01,0,4.01,0,6.02,0,.61-.51,1.17-1.09,1.23-.7.06-1.3-.36-1.42-1.02-.01-.07,0-.14,0-.21,0-.94,0-1.87,0-2.81,0-.05,0-.1-.01-.17Z"
        />
        <path
          className="cls-12"
          d="M45.8,58.35c.01-.39.14-.7.41-.96.78-.78,1.56-1.57,2.34-2.35.94-.94,1.88-1.87,2.82-2.8.22-.22.43-.44.66-.65.52-.48,1.24-.5,1.78,0,.51.47.49,1.28-.01,1.79-.8.81-1.61,1.61-2.41,2.42-1.12,1.13-2.25,2.25-3.38,3.38-.52.52-1.26.55-1.79.07-.27-.24-.4-.56-.41-.9Z"
        />
        <path
          className="cls-12"
          d="M62.42,55.46c0-1.01,0-2.01,0-3.02,0-.63.51-1.17,1.15-1.22.63-.05,1.23.4,1.34,1,.02.1.03.2.03.3,0,1.97,0,3.94,0,5.91,0,.43-.15.82-.49,1.05-.78.53-1.71.21-1.99-.64-.04-.11-.04-.23-.04-.35,0-1.01,0-2.01,0-3.02Z"
        />
      </g>
      <g>
        <path
          className="cls-8"
          d="M46.15,37.55h-17.99v46.77h16.19c5.96,0,10.79-4.83,10.79-10.79v-26.98c0-4.97-4.03-8.99-8.99-8.99Z"
        />
        <path
          className="cls-13"
          d="M49.75,42.94v28.78c0,3.96-3.24,7.19-7.19,7.19H13.77c-3.96,0-7.19-3.24-7.19-7.19v-28.78c0-3.96,3.24-7.19,7.19-7.19h28.78c3.96,0,7.19,3.24,7.19,7.19Z"
        />
        <path
          className="cls-4"
          d="M42.55,80.72H13.77c-4.96,0-8.99-4.04-8.99-8.99v-28.78c0-4.96,4.03-8.99,8.99-8.99h28.78c4.96,0,8.99,4.04,8.99,8.99v28.78c0,4.96-4.03,8.99-8.99,8.99ZM13.77,37.55c-2.98,0-5.4,2.42-5.4,5.4v28.78c0,2.98,2.42,5.4,5.4,5.4h28.78c2.98,0,5.4-2.42,5.4-5.4v-28.78c0-2.98-2.42-5.4-5.4-5.4H13.77Z"
        />
        <g>
          <path
            className="cls-4"
            d="M38.96,73.52h-21.58c-.99,0-1.8-.81-1.8-1.8s.81-1.8,1.8-1.8h21.58c.99,0,1.8.81,1.8,1.8s-.81,1.8-1.8,1.8Z"
          />
          <path
            className="cls-4"
            d="M28.16,66.33c-.99,0-1.8-.81-1.8-1.8v-17.99c0-.99.81-1.8,1.8-1.8s1.8.81,1.8,1.8v17.99c0,.99-.81,1.8-1.8,1.8Z"
          />
          <path
            className="cls-4"
            d="M28.16,66.33c-.46,0-.92-.18-1.27-.53l-5.4-5.4c-.7-.7-.7-1.84,0-2.54s1.84-.7,2.54,0l4.12,4.12,4.12-4.12c.7-.7,1.84-.7,2.54,0s.7,1.84,0,2.54l-5.4,5.4c-.35.35-.81.53-1.27.53h0Z"
          />
        </g>
      </g>
    </g>
  </svg>
);
export default MobileIconNew;
