/* eslint-disable */
import React, { useEffect, useRef, useState } from "react";
import clsx from "clsx";
import IReviewPage from "./review";
import Avatar from "../../components/ui/Avatar";
import Button from "../../components/ui/Button";
import Typograpy from "../../components/ui/Typography";
import { RightFilledBG, Dartboard, InfographicIcon, ProfilePhotoIcon } from "../../assets/Icons";
import { useRecoilValue } from "recoil";
import { userDetails } from "../../store";
import { transactionDetails } from "../../store";
import mixpanel from "mixpanel-browser";
import Tooltip from "../../components/ui/Tooltip";
import { CircularLoader } from "../../components/ui/CircularLoader";
import { Person } from "@material-ui/icons";
import { SimpleDropdown } from "../../components/ui/SimpleDropdown";
import Modal from "../../components/ui/Modal";
import ProfileOutline from "../../assets/Icons/ProfileOutline";

const PaymentOverview: React.FunctionComponent<IReviewPage.PaymentOverviewProps> = ({
  errorMessage,
  sendCurrency,
  handleClick,
  destCurrency,
  handleA2Form,
  setIsTermsModalOpen,
  isAdvancePay,
  setIsAdvancePay,
  a2Loading,
  loading,
  signA2FormStatus,
  handleSignA2Form,
  eSign,
  signLater,
  buttonLoader,
  viewA2Form,
  isViewingA2Form,
  A2errorMessage,
  A2seterrorMessage,
  setSourceOfFunds,
  sourceOfFunds
}) => {
  const [modalOpen, setModalOpen] = useState(false);
  const userData = useRecoilValue(userDetails);
  const transactionData = useRecoilValue(transactionDetails);
  const name = sessionStorage.getItem("recipient_name") || "";
  const firstName = userData.firstName || "";
  const lastName = userData.lastName || "";
  const mixpanelToken: any = process.env.REACT_APP_MIXPANEL_TOKEN;
  const modalRef = useRef<HTMLDivElement>(null);
  const dropdownRef = useRef<HTMLDivElement>(null);
  mixpanel.init(mixpanelToken, {
    debug: true,
    api_host: process.env.REACT_APP_MIXPANEL_API,
  });
  const myRef: any = useRef(null);
  const on2PercentClick = () => {
    mixpanel.track("Pay 2% advance clicked");
    setIsAdvancePay(!isAdvancePay);
    window.scrollTo({
      top: myRef.current.offsetTop,
      behavior: "smooth",
    });
  };

  const handleClickOutside = (event: MouseEvent) => {
    if (modalRef.current && !modalRef.current.contains(event.target as Node)) {
      setModalOpen(false);
    }
  };

  useEffect(() => {
    if (modalOpen) {
      document.addEventListener('mousedown', handleClickOutside);
    } else {
      document.removeEventListener('mousedown', handleClickOutside);
    }

    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, [modalOpen]);

  const sourceData = [
    {
      label : 'salary or wages',
      value : "Salary or Wages"
    },
    {
      label : 'student loan',
      value : "Student Loan"
    },
    {
      label : 'investments',
      value : "Investments"
    },
    {
      label : 'personal savings',
      value : "Personal Savings"
    },
    {
      label : 'personal wealth',
      value : "Personal Wealth"
    },
    {
      label : 'retirement funds',
      value : "Retirement Funds"
    },
    {
      label : 'business owner/share holder',
      value : "Business Owner/Share Holder"
    }
  ]

  const selectSource = (source: {label: string, value: string}) => {
    setSourceOfFunds(source.value);
    setModalOpen(false)
  }

  return (
    <div className="flex flex-col mx-4 lg:w-[50%] lg:px-[5%] px-[2%] mt-3 lg:mt-0">
      <div id="overview-names" className="flex items-center justify-between mb-6 space-y-4 md:mb-2">
        <div id="left-of-arrow" className="flex mt-5 space-x-2 md:space-x-5">
          <Avatar
            name={`${firstName.charAt(0)}${lastName.charAt(0)}`}
            customClassname="!w-[50px] !h-[50px] md:!w-20 md:!h-20"
            textClassname="text-[40px]"
          />
          <div className="flex flex-col">
            <Typograpy
              className="font-medium md:text-[20px] text-[14px] font-sans overflow-hidden"
              tagType={"span"}
            >
              {userData.firstName}
            </Typograpy>
            <div className="flex items-center mt-1">
              <img
                src={`/images/png/${sendCurrency}.png`}
                className="rounded-[200px] w-[32px] h-[32px] hidden md:flex lg:flex"
              />
              <Typograpy className="ml-2 font-bold text-gray-500" tagType={"symbol"}>
                {transactionData.base_currency}
              </Typograpy>
            </div>
          </div>
        </div>
        <div className="">
          <RightFilledBG />
        </div>
        <div id="right-of-arrow" className="flex mt-5 space-x-2 md:space-x-5">
          <Avatar
            name={`${name.charAt(0)}`}
            customClassname="!w-[50px] !h-[50px] md:!w-20 md:!h-20"
          />
          <div className="flex flex-col">
            <Typograpy
              className="font-medium md:text-[20px] text-[14px] font-sans"
              tagType={"span"}
            >
              {name}
            </Typograpy>
            <div className="flex items-center mt-1">
              <img
                src={`/images/png/${destCurrency}.png`}
                className="rounded-[200px] w-[32px] h-[32px] hidden md:flex lg:flex"
              />
              <Typograpy className="ml-2 font-bold text-gray-500" tagType={"symbol"}>
                {transactionData.dest_currency}
              </Typograpy>
            </div>
          </div>
        </div>
      </div>
      <div
        id="purpose-payment"
        className="flex items-center justify-between mt-5 space-x-16 lg:mt-16"
      >
        <div id="left-side" className="flex items-center space-x-2 md:space-x-5">
          <Dartboard />
          <Typograpy className="font-medium text-[12px] lg:text-[18px]" tagType={"span"}>
            Purpose Of Payment
          </Typograpy>
        </div>
        <span className="font-bold text-[12px] lg:text-[18px] text-gray-500">
          {transactionData.transaction_purpose}
        </span>
      </div>
      <div className="relative mt-10 w-[80%]">
      <div
        ref={dropdownRef}
        onClick={() => setModalOpen(!modalOpen)}
        className="flex items-center cursor-pointer justify-between py-3 px-5 bg-white-100 rounded-lg border border-gray-300"
      >
        <div className="flex items-center gap-6">
          <ProfileOutline />
          <span className="text-[#262A30] text-lg">
            {sourceOfFunds === "" ? "Source of Funds" : sourceOfFunds}
          </span>
        </div>
        <div>
          <svg
            xmlns="http://www.w3.org/2000/svg"
            width="24"
            height="24"
            viewBox="0 0 24 24"
            fill="none"
            stroke="currentColor"
            strokeWidth="2"
            strokeLinecap="round"
            strokeLinejoin="round"
            className="feather feather-chevron-down"
          >
            <polyline points="6 9 12 15 18 9"></polyline>
          </svg>
        </div>
      </div>
      {modalOpen && (
        <div
          ref={modalRef}
          className="absolute left-0 right-0 w-full mt-2 rounded-2xl bg-white border shadow-2xl z-50"
          style={{
            top: dropdownRef.current ? `${dropdownRef?.current.clientHeight &&dropdownRef?.current?.clientHeight}px` : 'auto',
          }}
        >
          <div className="flex flex-col w-full">
            {sourceData.map((element, index) => (
              <div
                key={index}
                onClick={() => selectSource(element)}
                className="py-2 px-6 w-full cursor-pointer"
              >
                <div className="text-[16px]">{element.value}</div>
                {index < sourceData.length - 1 && <hr />}
              </div>
            ))}
          </div>
        </div>
      )}
    </div>
      <div id="a2-form" className="flex flex-row items-center justify-start mt-5 space-x-8 lg:mt-8">
        <div className="relative">
          <div
            id="left-side"
            className="flex items-center cursor-pointer peer space-x-2 md:space-x-5"
          >
            <InfographicIcon fill="gray" width={30} height={30} />
            <Typograpy className="font-medium text-[12px] px-0 lg:text-[18px]" tagType={"span"}>
              A2 Form
            </Typograpy>
          </div>
          <Tooltip
            positionOfTip="top"
            bgColor="bg-primary"
            tooltipStyles="absolute cursor-default !min-h-[fit-content] ml-44 sm:ml-0 opacity-0 peer-hover:opacity-100 sm:-translate-x-[12%] min-w-[300px] md:min-w-[400px] md:!-translate-y-[90%]"
          >
            <Typograpy
              tagType="div"
              text="Under the Liberalised Remittance Scheme (LRS), the Indian government and the Reserve Bank of India (RBI) now require an LRS Declaration form (fully titled “A2 cum LRS Declaration”) to transfer funds abroad."
              className="mx-4 my-5 text-sm font-medium text-center text-white"
            />
          </Tooltip>
        </div>
        {eSign && (
          <div id="right-side" className="mb-1 flex items-center justify-center space-x-2">
            <Button
              id="view-a2-form-button"
              onClickHandler={(e: any) => {
                if (viewA2Form) {
                  viewA2Form(e);
                }
              }}
              className="lg:h-[50px] lg:w-[150px] px-10 py-3 rounded-md font-medium text-white bg-primary flex items-center justify-center"
            >
              {isViewingA2Form ? (
                <div className="flex items-center justify-center animate-spin-slow py-3">
                  <img src="/images/svg/smallLoader.svg" alt="" />
                </div>
              ) : (
                <span>VIEW</span>
              )}
            </Button>
            <Button
              id="dhjadadonwo-dajk-"
              onClickHandler={(e: any) => {
                handleA2Form(e);
                mixpanel.track("Download A2 form clicked");
              }}
              className="lg:h-[50px] lg:w-[150px] px-10 py-3 rounded-md font-medium text-white bg-black flex items-center justify-center"
            >
              {a2Loading ? (
                <div className="flex items-center justify-center animate-spin-slow py-3">
                  <img src="/images/svg/smallLoader.svg" alt="" />
                </div>
              ) : (
                <span>DOWNLOAD</span>
              )}
            </Button>
            {eSign && <span className="text-green-600 mx-[-100px] font-bold"> {" Signed "} </span>}
          </div>
        )}

        {!eSign && (
          <h1 className="flex font-bold">
            {signLater ? (
              <>
                <div className="flex items-center">
                  <span className="text-red-600 mr-2">(A2 form not signed</span>
                  <span onClick={handleSignA2Form} className="text-primary cursor-pointer">
                    View and Sign Now)
                  </span>
                </div>
              </>
            ) : (
              <>
                <div className="flex items-center">
                  <span className="text-red-600 mr-2">(A2 form not signed</span>
                  <span onClick={handleSignA2Form} className="text-primary cursor-pointer">
                    view and Sign Now)
                  </span>
                </div>
              </>
            )}
          </h1>
        )}
      </div>

      <div id="a2-form-error-message" className="flex flex-col mt-2 w-full">
        {A2errorMessage && (
          <div className=" flex flex-col px-4 py-2 mb-0 text-m text-center text-red-600">
            {A2errorMessage}
          </div>
        )}
      </div>
      <div className="w-[105%] border border-cyan-600 items-center justify-center ml-[-12px] mt-8"></div>
      <div id="checkbox-group" className="mt-[32px] justify-center items-center">
        <form className="space-y-4" onSubmit={handleClick}>
          {/* <div>
            <input type="checkbox" onChange={on2PercentClick} />
            <label className="ml-2 font-medium font-sans text-[12px] lg:text-[18px]">
              {`Pay 2% advance (${transactionData.advance_pay} INR)`}
            </label>
          </div> */}
          {isAdvancePay && (
            <div
              ref={myRef}
              className="border border-[#0F5BF1] rounded-md justify-between bg-[#D5E3FF] flex flex-col lg:w-[120%] w-[110%] lg:ml-[-8%] ml-[-5%] py-[4%] lg:px-[6%] px-[4%] space-x-4"
            >
              <div className="z-20 flex flex-col lg:flex-row lg:space-x-8">
                <div className="flex flex-col items-center text-base font-semibold w-[90%]">
                  <div>
                    Pay just 2% of the transaction value and you get to lock-in the exchange rates
                    for the next 48 hours. You can make the rest of the payment later in the next
                    two working days!
                  </div>
                </div>
                <div className="flex flex-col w-full mt-2 lg:mt-0">
                  <div className="mb-4 font-bold">Payment Details</div>
                  <div className="flex flex-col">
                    <div className="flex items-center justify-between">
                      <span>You Send</span>
                      <span>{transactionData?.user_send}</span>
                    </div>
                    <div className="flex items-center justify-between">
                      <span>Advance (2%)</span>
                      <span>{transactionData?.advance_pay}</span>
                    </div>
                  </div>
                  <div className="w-[105%] border border-solid border-cyan-600 items-center justify-center ml-[-8px] mt-4"></div>
                  <div className="flex items-center justify-between mt-3">
                    <span>Amount to be paid later</span>
                    <span>{transactionData?.user_send - transactionData?.advance_pay}</span>
                  </div>
                </div>
              </div>
              <div className="text-[#0F5BF1] mt-4">
                <span className="font-bold">Note: </span>
                The advance would be forfeited in case you fail to make full payment in the next 2
                working days.
              </div>
            </div>
          )}
          {((!eSign && signLater) || eSign) && (
            <div>
              <input type="checkbox" className="h-4 w-4" required />
              <label className="ml-2 font-medium font-sans text-[12px] lg:text-[18px]">
                I agree to{" "}
                <span
                  className="text-blue-600 cursor-pointer hover:underline"
                  onClick={() => setIsTermsModalOpen(true)}
                >
                  Terms & Conditions
                </span>
                <span> and the </span>
                <span
                  className="text-blue-600 cursor-pointer hover:underline"
                  onClick={() => setIsTermsModalOpen(true)}
                >
                  Privacy Policy.
                </span>
              </label>
            </div>
          )}
          {((!eSign && signLater) || eSign) && (
            <div className="mt-8">
              <input type="checkbox" className="bg-primary-200 h-4 w-4" required />
              <label className="ml-2 font-medium font-sans text-[12px] lg:text-[18px]">
                {clsx(
                  "I confirm that the information captured on this A2 Form is accurate and is in compliance of FEMA declaration.",
                )}
              </label>
            </div>
          )}
          {((!eSign && signLater) || eSign) && (
            <div className="flex justify-center w-full rounded-lg">
              <div className="flex items-center w-1/3 h-[50px] rounded-lg justify-evenly bg-primary">
                {loading ? (
                  <div className="flex items-center justify-center animate-spin-slow py-3">
                    <img src="/images/svg/smallLoader.svg" alt="" />
                  </div>
                ) : (
                  <Button
                    className="py-3 font-bold text-white rounded-lg h-[50px] cursor-pointer justify-evenly bg-primary text-[18px]"
                    isLoading={buttonLoader}
                  >
                    Confirm & Pay
                  </Button>
                )}
              </div>
            </div>
          )}
          <div>
            <span>{errorMessage}</span>
          </div>
        </form>
        {!eSign && !signLater && (
          <div className="flex justify-center w-full rounded-lg">
            <div className="flex items-center w-1/3 h-full rounded-lg justify-evenly bg-primary">
              <input
                type={"submit"}
                onClick={handleSignA2Form}
                className="py-3 font-bold text-white rounded-lg cursor-pointer justify-center bg-primary text-[18px]"
                value={`Sign A2 Form`}
              />
            </div>
          </div>
        )}
      </div>
    </div>
  );
};

export default PaymentOverview;
