/* eslint-disable */
import React from "react";
import {
  CloseIcon,
  MoneyHopLogoWhite,
  RotatePhoneIcon,
  SuccessIcon,
  WhiteCloseBtn,
} from "../../assets/Icons";
import ReactSignatureCanvas from "react-signature-canvas";
import { saveEsign, validateEsignLink } from "../../services/reviewPage";
import Modal from "../../components/ui/Modal";
import ErrorField from "../../components/ui/ErrorField";
import clsx from "clsx";
import "./styles.css";

const Key: any = process.env.REACT_APP_CIPHER_KEY;

export const ESignPage = () => {
  const [sign, setSign] = React.useState<any>();
  const [validUser, setValidUser] = React.useState<boolean>(false);
  const [step, setStep] = React.useState<string>("1");
  const [isEsignModal, setIsEsignModal] = React.useState<boolean>(true);
  const [error, setError] = React.useState<string>("");
  const [rotateScreen, setRotateScreen] = React.useState<boolean>(true);
  const [userId, setUserId] = React.useState();

  const handleRetry = () => {
    sign.clear();
  };

  React.useEffect(() => {
    setRotateScreen(true);
  }, []);

  React.useEffect(() => {
    const queryString = window.location.search;
    const token = queryString.substring(1);
    validateEsignLink({
      token: token,
    })
      .then((res: any) => {
        setValidUser(true);
        setUserId(res?.data?.userId);
      })
      .catch((error: any) => {
        setValidUser(false);
        if (error?.response?.data?.error?.type === 2576) {
          setStep("2");
        } else {
          alert(error?.response?.data?.error?.type);
          window.location.href = "review";
        }
      });
  }, []);

  const dataUrlToPngFile = (dataUrl: any) => {
    const byteString = dataUrl.split(",");
    let mime = byteString[0].match(/:(.*?);/)[1];
    let data = byteString[1];
    let decodedData = atob(data);
    let length = decodedData.length;
    let dataArray = new Uint8Array(length);
    while (length--) {
      dataArray[length] = decodedData.charCodeAt(length);
    }
    let file = new File([dataArray], "File.png", { type: mime });
    return file;
  };

  const handleGenerateSign = () => {
    const dataUrl = sign.getTrimmedCanvas().toDataURL("image/png");
    const pngFile = dataUrlToPngFile(dataUrl);
    handleSaveEsign(pngFile);
  };

  const handleSaveEsign = (data: any) => {
    let payload = new FormData();
    payload.append("file", data);
    saveEsign(payload, userId)
      .then((response: any) => {
        setStep("2");
        setIsEsignModal(true);
      })
      .catch((error: any) => {
        setError(error?.response?.data?.error?.type);
      });
  };

  const closeHandler = () => {
    setRotateScreen(false);
  };

  return (
    <div className="w-screen overflow-x-scroll min-h-screen">
      {validUser && (
        <div>
          {rotateScreen && (
            <div
              onClick={() => setRotateScreen(false)}
              className="top-0 left-0 right-0 bottom-0 fixed z-50 bg-grey-600 bg-opacity-80 shadow-2xl"
            >
              <div
                className={clsx(
                  "bottom-2/4 w-auto md:w-fit bg-white/100 p-8 left-2/4 rounded-2xl  absolute -translate-x-1/2 md:translate-y-1/2 shadow-3xl transition-[bottom]  duration-500",
                )}
              >
                <div className="flex justify-end">
                  <button onClick={closeHandler}>
                    <CloseIcon fill="black" />{" "}
                  </button>
                </div>
                <div className="flex justify-center">
                  <RotatePhoneIcon />
                </div>
              </div>
            </div>
          )}
          {step === "1" ? (
            <div className="w-screen h-full">
              <div className="w-screen h-auto p-3 flex justify-between bg-primary items-center">
                <div>
                  <MoneyHopLogoWhite />
                </div>
                <div>
                  <h1 className="text-[15px] md:text-[20px] text-white font-bold">
                    A2 Form Signature
                  </h1>
                </div>
                {/* <div>
                  <WhiteCloseBtn/>
                </div> */}
              </div>
              <div className="flex w-full h-auto items-center justify-center p-10">
                <div className="w-[100vw] h-[50vh] flex justify-center">
                  <ReactSignatureCanvas
                    penColor="#0404FF"
                    canvasProps={{ className: "sigCanvas" }}
                    ref={(data) => setSign(data)}
                  />
                </div>
              </div>
              <div className="flex flex-col justify-center items-center">
                <div className="flex justify-center">
                  <button
                    onClick={handleGenerateSign}
                    className="bg-primary border-2 border-primary py-2 px-16 rounded-full text-white font-semibold mx-2"
                  >
                    Submit
                  </button>
                  <button
                    onClick={handleRetry}
                    className="border-2 border-primary rounded-full py-2 px-16 text-primary font-semibold mx-2"
                  >
                    Retry
                  </button>
                </div>
                <ErrorField errorMessage={error} />
              </div>
            </div>
          ) : (
            <Modal
              modalClass="!p-0 md:pr-11 p-0 flex-col !rounded lg:!w-6/12 !w-full"
              headerClass="!mt-0 text-white sticky h-24 pr-4 pl-[42%] bg-primary font-bold text-2xl"
              visible={isEsignModal}
              header={"Sign A2 Form"}
              closeHandler={() => {
                setIsEsignModal(false);
              }}
              closable={false}
            >
              <div className="flex flex-col items-center py-10">
                <div className="mb-7">
                  <h1 className="text-[20px] lg:text-[25px] font-bold">
                    Your form has been signed
                  </h1>
                </div>
                <div className="mb-8">
                  <h1 className="text-[15px] lg:text-[20px] font-semibold">
                    Thanks for completion!
                  </h1>
                </div>
                <div className="mb-5">
                  <SuccessIcon />
                </div>
              </div>
            </Modal>
          )}
          ;
        </div>
      )}
      ;
    </div>
  );
};
