import React from "react";
import { IIcons } from "./icons";

const DownIcon = ({ fillColor }: IIcons.IconProps) => {
  return (
    <svg
      width="30"
      height="30"
      viewBox="0 0 30 30"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      className={fillColor}
    >
      <circle cx="15" cy="15" r="15" fill="#FF6347" />
      <path d="M10 13L15 18L20 13" stroke="white" strokeWidth="2" strokeLinecap="round" />
    </svg>
  );
};

export default DownIcon;
