import React from "react";
import { IIcons } from "./icons";

const DownCircleIcon = ({ fillColor }: IIcons.IconProps) => {
  return (
    <svg
      width="19"
      height="20"
      viewBox="0 0 19 20"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      className={fillColor}
    >
      <path
        d="M9 0C4.582 0 1 3.582 1 8V9V9.90234C0.426291 10.4494 0 11.1531 0 12C0 13.242 0.857551 14.1629 1.94336 14.6133C3.24439 17.7231 5.85024 20 9 20H11C11.1325 20.0019 11.2641 19.9774 11.387 19.928C11.51 19.8786 11.6219 19.8052 11.7163 19.7122C11.8107 19.6191 11.8856 19.5082 11.9368 19.386C11.9879 19.2637 12.0142 19.1325 12.0142 19C12.0142 18.8675 11.9879 18.7363 11.9368 18.614C11.8856 18.4918 11.8107 18.3809 11.7163 18.2878C11.6219 18.1948 11.51 18.1214 11.387 18.072C11.2641 18.0226 11.1325 17.9981 11 18H9C6.74155 18 4.69096 16.2995 3.66016 13.6133C3.59595 13.4459 3.48793 13.2988 3.34739 13.1875C3.20686 13.0762 3.03898 13.0047 2.86133 12.9805C2.36883 12.9127 2 12.5151 2 12C2 11.615 2.19441 11.313 2.49805 11.1387C2.65059 11.051 2.77733 10.9247 2.86546 10.7725C2.95359 10.6202 3 10.4474 3 10.2715V10V9C3 8.448 3.448 8 4 8H14C14.552 8 15 8.448 15 9V10V14H10.9121C10.8088 13.7079 10.6176 13.4549 10.3648 13.2759C10.1119 13.0968 9.80983 13.0004 9.5 13C9.10218 13 8.72064 13.158 8.43934 13.4393C8.15804 13.7206 8 14.1022 8 14.5C8.00051 14.8789 8.14438 15.2435 8.4027 15.5207C8.66103 15.7979 9.01467 15.967 9.39258 15.9941C9.42825 15.998 9.46411 16 9.5 16H16H17C18.1 16 19 15.1 19 14V12C19 10.9 18.1 10 17 10V8C17 3.582 13.418 0 9 0Z"
        fill="white"
      />
    </svg>
  );
};

export default DownCircleIcon;
