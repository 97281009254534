//Global Imports
import React, { ReactElement } from "react";
import clsx from "clsx";
import moment from "moment";
//End Global Imports

//Local Imports
import { ITextField } from "./TextField";
import { VerifiedIcon, VisibilityOffIcon, VisibilityOnIcon } from "../../../assets/Icons";
import ErrorField from "../ErrorField";
//End Local Imports

const TextField = React.forwardRef<HTMLInputElement, ITextField.IProps>(
  ({
    rootClassName,
    disabled = false,
    error = false,
    errorClasses,
    errorMessage,
    inputClasses,
    fullWidth = false,
    hint,
    label,
    type,
    name,
    placeholder,
    value,
    required,
    onChange,
    onClick,
    onBlur,
    autoComplete = "off",
    placeholderStyle = "text-gray-600 text-sm",
    isValidate,
    accept,
    pattern,
  }: ITextField.IProps): ReactElement => {
    const [showPassword, setShowPassword] = React.useState<string>("password");

    const labelStyles = [
      error && !disabled && "text-error text-base font-ssp mb-4 tracking-wide",
      !error &&
      !disabled &&
      "text-black text-base mb-4 font-ssp tracking-wide font-medium leading-5",
    ];

    const rootStyles = ["font-ssp", fullWidth && "w-full", !fullWidth && "w-64", rootClassName];
    const currentDate = moment(new Date()).format("YYYY-MM-DD").toString();

    return (
      <div className={clsx(rootStyles)}>
        {label && <label className={clsx(labelStyles)}>{label}</label>}
        <div className={clsx("relative h-16 rounded-[5px] overflow-hidden", label && "mt-4")}>
          <input
            accept={accept}
            id={name}
            name={name}
            type={type === "password" ? showPassword : type}
            value={value}
            onChange={onChange}
            onBlur={onBlur}
            className={clsx(
              error && "border-red-500",
              "rounded-[5px] w-full pl-6 pt-3 h-full text-gray-900 placeholder-transparent border peer focus:outline-none focus:border",
              disabled && "bg-[#EFEFEF] cursor-not-allowed",
              inputClasses,
            )}
            onClick={onClick}
            // placeholder="john@doe.com"
            autoComplete={autoComplete}
            disabled={disabled}
            min={currentDate}
            pattern={pattern}
          />
          <label
            htmlFor={name}
            className={clsx(
              "absolute pl-6 left-0 transition-all peer-placeholder-shown:top-3 peer-focus:text-gray-600",
              !value && "top-1 peer-focus:top-2",
              value && "text-xs top-2",
              placeholderStyle,
            )}
          >
            {placeholder} {required && <span className="text-red-500"></span>}
          </label>
          {type === "password" && (
            <div
              className="absolute cursor-pointer right-5 top-6"
              onClick={() =>
                setShowPassword((preValue) => (preValue === "password" ? "text" : "password"))
              }
            >
              {showPassword === "password" ? <VisibilityOnIcon /> : <VisibilityOffIcon />}
            </div>
          )}
          {isValidate && (
            <div className={clsx(type === "password" ? "right-16" : "right-5", "absolute top-6")}>
              <VerifiedIcon />
            </div>
          )}
        </div>
        {error && errorMessage && (
          <ErrorField errorMessage={errorMessage} errorClassname={errorClasses} />
        )}
        {!error && hint && <div className="py-1 text-xs text-gray-700 ">{hint}</div>}
      </div>
    );
  },
);

export default TextField;
