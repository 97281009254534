//#Global Imports
import React from "react";
import clsx from "clsx";
//#end Global Imports

//#Local Imports
import Typograpy from "../../../../components/ui/Typography";
import { FlagIcon } from "../../../../assets/Icons";
import IUserDocuments from "../../userDocuments";
//#end Local Imports

const DocumentsListSection: React.FC<IUserDocuments.IDocumentListSectionProps> = ({
  docType,
  panCardDetails,
  setDocType,
  verifiedDocumentsData,
  getColor,
  setIsModalOpen,
  ckycStatus,
  adharCardDetails,
  drivingLicenseDetails,
  passportDetails,
  voterIDCardDetails
}) => {

  const title = (title: string) => {
    if(title === "Aadhaar Card"){
      return adharCardDetails?.isAadharDocVerified ? 'Verified' : 'Verify Aadhaar Card';
    }
    else if(title === "Driving License"){
      return drivingLicenseDetails?.isDlDocVerified ? 'Verified' : 'Verify Driving License';
    }
    else if(title === "Indian Passport"){
      return passportDetails?.isPassportDocVerified ? 'Verified' : 'Verify Indian Passport';
    }
    else if(title === "Voter ID"){
      return voterIDCardDetails?.isVIDocVerified ? 'Verified' : 'Verify Voter ID';
    }
  };
  return (
    <div>
      {docType === "PAN_CARD" && (
        <div className="w-[3px] bg-primary h-[83px] py-1 absolute my-2 rounded" />
      )}
      <div
        className={clsx(
          panCardDetails.isPanVerified ? "bg-grey-1450" : "bg-transparent",
          "relative flex items-center justify-between px-10 py-[17px] border rounded border-grey-350 cursor-pointer",
        )}
        onClick={() => (setIsModalOpen("2"), setDocType("PAN_CARD"))}
      >
        <div className="mr-[90px]">
          <div
            className={clsx(
              panCardDetails.isPanVerified
                ? "text-grey-550"
                : docType === "PAN_CARD"
                ? "text-black"
                : "text-grey-550",
              "mb-3 text-lg font-medium",
            )}
          >
            Pan Card <span className="text-error">*</span>
          </div>
          <Typograpy
            tagType="div"
            text={panCardDetails.isPanVerified ? 'Verified' : 'Verify PAN Card'}
            className={clsx(
              panCardDetails.isPanVerified
                ? "text-success-150"
                : docType === "PAN_CARD"
                ? "text-black-200"
                : "text-grey-550",
              "text-base  font-normal",
            )}
          />
        </div>
        <FlagIcon
          fillColor={
            panCardDetails.isPanVerified
              ? "fill-success-150"
              : docType === "PAN_CARD"
              ? "fill-grey-550"
              : "fill-grey-1500"
          }
        />
        <Typograpy
          tagType="div"
          text="Mandatory"
          className="bg-rewardButton rounded absolute py-[6px] px-[18px] text-white font-semibold text-sm -top-6 left-4 "
        />
      </div>
      <Typograpy
        tagType="div"
        text="and choose anyone"
        className="my-6 text-base font-normal text-center text-black-200"
      />
      {verifiedDocumentsData.map((data, index) => {
        return (
          <>
            {docType === data.value && (
              <div className="w-[3px] bg-primary h-[83px] py-1 absolute my-2 rounded" />
            )}
            <div
              className={clsx(
                getColor(data.value) ? "bg-grey-1450" : "bg-transparent",
                "relative flex items-center justify-between px-10 py-[17px] border rounded border-grey-350 mb-2 cursor-pointer",
              )}
              key={index}
              onClick={() =>
                panCardDetails?.isPanVerified && (setIsModalOpen("2"), setDocType(data.value))
              }
            >
              <div className="mr-[90px]">
                <div
                  className={clsx(
                    getColor(data.value)
                      ? "text-grey-550"
                      : docType === data.value
                      ? "text-black"
                      : "text-grey-550",
                    "mb-3 text-lg font-medium",
                  )}
                >
                  {data.title}
                </div>
                <Typograpy
                  tagType="div"
                  text={title(data?.title)}
                  className={clsx(
                    getColor(data.value)
                      ? "text-success-150"
                      : docType === data.value
                      ? "text-black-200"
                      : "text-grey-550",
                    "text-base font-normal",
                  )}
                />
              </div>
              <FlagIcon
                fillColor={
                  data?.value === "CKYC"
                    ? ckycStatus === "COMPLETED"
                      ? "fill-success-150"
                      : ckycStatus === "FAILED"
                      ? "fill-error"
                      : docType === data.value
                      ? "fill-grey-550"
                      : "fill-grey-1500"
                    : getColor(data.value)
                    ? "fill-success-150"
                    : docType === data.value
                    ? "fill-grey-550"
                    : "fill-grey-1500"
                }
              />
            </div>
          </>
        );
      })}
    </div>
  );
};

export default DocumentsListSection;
