//#Global Imports
import React from "react";
import Button from "../../../components/ui/Button";
//#end Global Imports

//#Local Imports
import Modal from "../../../components/ui/Modal";
import Typograpy from "../../../components/ui/Typography";
import IUserDocuments from "../userDocuments";
//#end Local Imports

const UnverifiedKycModal: React.FC<IUserDocuments.IUnverifiedKycModalProps> = ({
  isModalOpen,
  setIsModalOpen,
}) => {
  return (
    <Modal
      modalClass="!pt-[26px] lg:pr-10 !rounded lg:!bottom-[25%] lg:-translate-x-0 lg:!left-[64%] !w-full lg:!w-1/3"
      visible={isModalOpen === "UnverifiedKYC"}
      closeHandler={() => setIsModalOpen("")}
    >
      <div className="pb-5 lg:pl-[51px] lg:pr-[72px]">
        <div className="pb-[37px]">
          <Typograpy
            tagType="div"
            text="It looks like you haven’t completed KYC"
            className="text-black font-bold text-[32px] mb-3"
          />
          <Typograpy
            tagType="div"
            text="Your transaction could not be processed because your document verification is not completed."
            className="text-error font-normal lg:text-[22px] text-xl"
          />
        </div>
        <div className="flex items-center">
          <Button
            id="sfkdj902d987--0904890-sabhF"
            bgColor="bg-primary"
            fontStyle={{
              fontFamily: "",
              fontSize: "text-sm",
              fontWeight: "font-semibold",
              fontColor: "text-white",
            }}
            borderStyle={{
              borderWidth: "border-none",
              borderRadius: "rounded",
            }}
            className={"py-[15px] px-[38px]"}
            onClickHandler={() => {
              setIsModalOpen("1");
            }}
          >
            Complete KYC
          </Button>
          <div
            className="text-sm font-semibold cursor-pointer text-primary-400 ml-11"
            onClick={() => history.back()}
          >
            Go Back
          </div>
        </div>
      </div>
    </Modal>
  );
};

export default UnverifiedKycModal;
