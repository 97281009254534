import { FunctionComponent } from "react";

export const AppCard: FunctionComponent<AppCard.Props> = ({
  title = "Instant, Digital, paperless onboarding",
  image = "appGrid1",
}) => {
  return (
    <div className="flex items-center min-w-1/4 max-w-1/4 py-5 transition-all bg-white justify-evenly max-h-[150px] rounded-xl hover:scale-105 my-3 px-5 cursor-pointer group">
      <div className="z-50 w-1/3 transition-all group-hover:scale-110">
        <img src={`/images/hopapp/${image}`} alt="" />
      </div>
      <div className="font-bold text-[20px] text-blue-700 w-2/3">{title}</div>
    </div>
  );
};
