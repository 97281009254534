import React from "react";
import { IIcons } from "./icons";

const DownCircleIcon = ({ fillColor }: IIcons.IconProps) => {
  return (
    <svg
      width="27"
      height="27"
      viewBox="0 0 27 27"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      className={fillColor}
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M3.91199 13.3253C3.91199 20.7886 6.40045 23.277 13.8637 23.277C21.3269 23.277 23.8154 20.7886 23.8154 13.3253C23.8154 5.86212 21.3269 3.37366 13.8637 3.37366C6.40045 3.37366 3.91199 5.86212 3.91199 13.3253Z"
        stroke={fillColor || "#130F26"}
        strokeWidth="1.13914"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M10.1287 11.774C10.1287 11.774 12.7021 15.5245 13.864 15.5245C15.026 15.5245 17.5973 11.774 17.5973 11.774"
        stroke={fillColor || "#130F26"}
        strokeWidth="1.13914"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
};

export default DownCircleIcon;
