/* eslint-disable */
import React from "react";
import Button from "../../../../components/ui/Button";
import Modal from "../../../../components/ui/Modal";
import { KycUnsuccessful } from "../../../../assets/Icons";

interface Props {
  showKycIncompleteModal?: any;
  setShowKycIncompleteModal?: any;
  setDocumentWindow?: any;
}

const KycIncomplete: React.FC<Props> = ({
  showKycIncompleteModal,
  setShowKycIncompleteModal,
  setDocumentWindow,
}) => {
  const handleCompleteKycButton = () => {
    window.console.log("clicked");
    setDocumentWindow("taxbill");
    setShowKycIncompleteModal(false);
  };

  React.useEffect(() => {
    window.console.log(showKycIncompleteModal);
  }, [showKycIncompleteModal]);

  // const [visible, setVisible] = useState(false);
  return (
    <div>
      {/* <Button
        id="open-Modal-basic-btn"
        bgColor="bg-primary"
        fontStyle={{
          fontFamily: "font-roboto",
          fontSize: "text-base",
          fontWeight: "font-bold",
          fontColor: "text-white",
        }}
        borderStyle={{
          borderRadius: "rounded",
        }}
        className={"py-4 px-14"}
        onClickHandler={() => setVisible(true)}
      >
        kycIncomplete
      </Button> */}
      <Modal
        visible={showKycIncompleteModal}
        closeHandler={() => setShowKycIncompleteModal(false)}
        modalClass="h-[75%] md:h-3/5 w-full md:!w-[968px] p-4 justify-evenly "
        headerClass="mt-0"
      >
        <div className="flex flex-col items-center text-center lg:flex-row md:items-start lg:text-left">
          <div className="relative">
            <h2 className="mt-10 mb-10 text-4xl font-bold md:mt-4">
              Please finish your document verification to continue creating a transactions.
            </h2>
            {/* <p className="font-normal text-xl  text-[#949494] mt-12 mb-12">
              <span className="font-bold">Note</span> - The process is completed within 24 hrs. In
              case of any help, you can reach us at care@moneyhop.co
            </p> */}
            <Button
              bgColor="bg-primary"
              className="hidden w-40 h-10 font-bold text-white rounded-full md:flex mt-7 "
              onClickHandler={handleCompleteKycButton}
            >
              Complete KYC
            </Button>
          </div>
          <div className="mt-16 md:mt-0">
            <KycUnsuccessful className="" />
          </div>
          <Button
            bgColor="bg-primary"
            className="w-3/5 h-10 mt-16 font-bold text-white rounded-full md:w-40 md:hidden"
            onClickHandler={handleCompleteKycButton}
          >
            Complete KYC
          </Button>
        </div>
      </Modal>
    </div>
  );
};

export default KycIncomplete;
