import React from "react";
import clsx from "clsx";

import { IAvatar } from "./Avatar";
import Typograpy from "../Typography";

const Avatar: React.FunctionComponent<IAvatar.IProps> = ({
  name = "MH",
  customClassname,
  textClassname,
  imgSrc,
  onClick,
}) => (
  <div
    onClick={onClick}
    className={clsx(
      "flex bg-primary justify-center items-center rounded-full w-20 h-20",
      customClassname,
      imgSrc && "!bg-transparent",
    )}
  >
    {!imgSrc ? (
      <Typograpy
        tagType="span"
        text={name}
        className={clsx("text-white text-[34px]", textClassname)}
      />
    ) : (
      <img src={imgSrc} />
    )}
  </div>
);

export default Avatar;
