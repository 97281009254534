import { IIcons } from "../assets/Icons/icons";
// import Maintenance from "../assets/Icons/Maintenance";

export const UnderMaintenance = () => {
  const Maintenance = ({ fillColor }: IIcons.IconProps) => (
    <svg
      width="758"
      height="444"
      viewBox="0 0 758 444"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      className={fillColor}
    >
      <path
        d="M644.638 441.033C644.638 441.033 758.58 390.361 755.93 288.129C753.279 185.896 645.293 138.355 571.981 201.474C498.672 264.592 451.225 211.663 413.248 158.326C375.268 104.987 290.541 37.6707 179.375 68.7754C62.9279 101.36 27.0942 219.29 38.6272 297.986C46.9136 354.513 77.214 402.438 118.505 439.263C119.849 440.462 121.223 441.644 122.632 442.812L644.638 441.033Z"
        fill="#BDC5EA"
      />
      <path d="M430.313 430.739H335.032V407.464H430.313V430.739Z" fill="#404DC4" />
      <path
        d="M448.072 442.725H316.86C313.503 442.725 310.779 440.001 310.779 436.644C310.779 433.288 313.503 430.566 316.86 430.566H448.072C451.432 430.566 454.154 433.288 454.154 436.644C454.154 440.001 451.432 442.725 448.072 442.725Z"
        fill="#5B62DB"
      />
      <path
        d="M596.753 139.068C596.753 131.478 590.602 125.328 583.013 125.328H181.919C174.329 125.328 168.178 131.478 168.178 139.068V371.227H596.753V139.068Z"
        fill="#5B62DB"
      />
      <path d="M578.69 353.641H186.655V144.895H578.69V353.641Z" fill="#BDC5EA" />
      <path
        d="M181.919 409.645H583.013C590.602 409.645 596.753 403.492 596.753 395.905V371.226H168.178V395.905C168.178 403.492 174.329 409.645 181.919 409.645Z"
        fill="white"
      />
      <path
        d="M390.613 390.435C390.613 394.935 386.965 398.581 382.467 398.581C377.969 398.581 374.319 394.935 374.319 390.435C374.319 385.935 377.969 382.289 382.467 382.289C386.965 382.289 390.613 385.935 390.613 390.435Z"
        fill="#404DC4"
      />
      <g opacity="0.570007">
        <path d="M295.368 144.982L205.922 353.319H237.577L328.003 144.982H295.368Z" fill="white" />
        <path d="M523.56 144.982L434.114 353.319H465.767L556.194 144.982H523.56Z" fill="white" />
        <path d="M368.366 144.982L278.92 353.319H372.61L463.037 144.982H368.366Z" fill="white" />
      </g>
      <path
        d="M380.854 306.364H214.294V188.535H380.854V306.364Z"
        fill="white"
        stroke="#A2C9F8"
        strokeWidth="4.01867"
        strokeMiterlimit="10"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path d="M399.814 197.354H551.797" stroke="white" strokeWidth="3.74" strokeMiterlimit="10" />
      <path d="M399.814 232.893H493.263" stroke="white" strokeWidth="3.74" strokeMiterlimit="10" />
      <path d="M399.814 214.94H426.616" stroke="white" strokeWidth="3.74" strokeMiterlimit="10" />
      <path d="M399.814 255.993H551.797" stroke="white" strokeWidth="3.74" strokeMiterlimit="10" />
      <path d="M399.814 291.534H551.797" stroke="white" strokeWidth="3.74" strokeMiterlimit="10" />
      <path d="M399.814 273.58H489.483" stroke="white" strokeWidth="3.74" strokeMiterlimit="10" />
      <path
        d="M330.846 246.67C330.846 266.842 314.491 283.199 294.315 283.199C274.143 283.199 257.786 266.842 257.786 246.67C257.786 226.496 274.143 210.139 294.315 210.139C314.491 210.139 330.846 226.496 330.846 246.67Z"
        fill="#F04365"
      />
      <path
        d="M275.8 246.67H311.688"
        stroke="white"
        strokeWidth="5.61067"
        strokeMiterlimit="10"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M406.229 1.73852L408.898 8.68729C410.544 12.9628 413.921 16.3416 418.195 17.9838L425.146 20.655L418.195 23.3261C413.921 24.9704 410.544 28.3471 408.898 32.6226L406.229 39.5714L403.558 32.6226C401.914 28.3471 398.537 24.9704 394.261 23.3261L387.313 20.655L394.261 17.9838C398.537 16.3416 401.914 12.9628 403.558 8.68729L406.229 1.73852Z"
        fill="#BDC5EA"
      />
      <path
        d="M28.9693 101.42L30.4225 105.202C31.3192 107.529 33.1567 109.368 35.4834 110.261L39.2654 111.716L35.4834 113.169C33.1567 114.064 31.3192 115.901 30.4225 118.228L28.9693 122.012L27.5162 118.228C26.6216 115.901 24.782 114.064 22.4553 113.169L18.6753 111.716L22.4553 110.261C24.782 109.368 26.6216 107.529 27.5162 105.202L28.9693 101.42Z"
        fill="#BDC5EA"
      />
      <path
        d="M301.827 4.42196e-06L303.28 3.78203C304.175 6.10879 306.012 7.94626 308.341 8.84084L312.121 10.294L308.341 11.7493C306.012 12.6439 304.175 14.4813 303.28 16.8081L301.827 20.5901L300.374 16.8081C299.479 14.4813 297.64 12.6439 295.313 11.7493L291.533 10.294L295.313 8.84084C297.64 7.94626 299.479 6.10879 300.374 3.78203L301.827 4.42196e-06Z"
        fill="#BDC5EA"
      />
      <path
        d="M193.171 43.3387L200.659 50.9511C202.291 52.6101 204.74 53.1602 206.921 52.356C211.063 50.8335 212.138 45.4765 208.904 42.4735L201.352 35.0061L193.171 43.3387Z"
        fill="#F89A6E"
      />
      <path
        d="M198.522 48.7765C198.522 48.7765 203.202 39.123 201.352 35.0049C197.322 26.0444 190.54 37.0335 190.54 37.0335L194.25 44.4359L198.522 48.7765Z"
        fill="#E88057"
      />
      <path
        d="M171.15 29.1702C170.982 38.7712 178.63 46.6923 188.231 46.8603C197.832 47.0283 205.753 39.3802 205.921 29.7792C206.089 20.1782 198.441 12.2592 188.84 12.0912C179.239 11.9232 171.318 19.5692 171.15 29.1702Z"
        fill="#F89A6E"
      />
      <path
        d="M195.819 29.9762C195.819 29.9762 198.998 43.8107 208.469 39.9153C217.942 36.0198 202.081 14.1551 202.081 14.1551L180.638 5.87071L160.487 21.549L162.727 35.1274C162.727 35.1274 169.918 30.0077 191.222 21.7779L195.819 29.9762Z"
        fill="#243051"
      />
      <path d="M185.425 26.1699L194.43 31.7075L197.158 23.2447L185.425 26.1699Z" fill="#243051" />
      <path
        d="M196.817 33.249C196.817 33.249 211.817 74.1163 230.488 55.4708C247.048 38.9315 222.006 28.8748 215.224 27.1045C210.037 25.75 208.05 23.9777 201.504 17.9004L188.523 19.7672L196.817 33.249Z"
        fill="#243051"
      />
      <path
        d="M192.248 28.5192C192.204 31.0874 194.247 33.2042 196.818 33.2483C199.384 33.2945 201.503 31.2491 201.549 28.6809C201.591 26.1126 199.548 23.9959 196.979 23.9518C194.411 23.9056 192.292 25.9509 192.248 28.5192Z"
        fill="#F89A6E"
      />
      <path
        d="M169.439 31.8517C169.439 31.8517 162.585 21.438 168.515 10.6526C173.404 1.75928 184.985 -1.42216 193.54 4.03564C197.56 6.59969 200.691 9.56064 203.397 17.3788L169.439 31.8517Z"
        fill="#FDD63B"
      />
      <path
        d="M203.833 19.2047C203.776 19.2383 203.713 19.2698 203.65 19.2971L165.099 35.8952C164.179 36.2879 163.117 35.8574 162.726 34.9376C162.336 34.02 162.756 32.9763 163.684 32.5647L202.235 15.9666C203.155 15.5739 204.219 16.0044 204.608 16.9242C204.971 17.7789 204.625 18.7595 203.833 19.2047Z"
        fill="#F2A62F"
      />
      <path
        d="M168.709 129.027L130.303 168.41L120.483 164.067L153.721 116.919C156.978 111.52 164.673 111.154 168.429 116.219C170.645 119.21 170.756 125.919 168.709 129.027Z"
        fill="#1B1754"
      />
      <path
        d="M105.672 163.855L125.086 177.461L130.342 168.269L120.355 161.272C115.586 157.931 109.013 159.086 105.672 163.855Z"
        fill="#243051"
      />
      <path
        d="M195.263 105.551L156.403 112.466L164.414 127.632H216.544C216.544 127.632 232.218 127.263 224.981 110.083L195.263 105.551Z"
        fill="#2A2B7B"
      />
      <path
        d="M170.75 117.417L189.253 178.639L178.823 184.622L149.564 125.485C146.582 119.928 150.192 113.122 156.464 112.476C160.167 112.096 169.123 114.07 170.75 117.417Z"
        fill="#2A2B7B"
      />
      <path
        d="M174.044 198.557L193.435 188.35L189.252 178.639L178.462 184.32C173.309 187.031 171.333 193.406 174.044 198.557Z"
        fill="#243051"
      />
      <path
        d="M193.034 59.4608C193.034 59.4608 194.602 52.596 196.593 50.4792C198.008 48.9736 203.111 44.1647 205.915 43.1315C206.746 42.8228 207.668 42.9215 208.426 43.3835C211.78 45.4351 220.997 52.1046 225.518 65.6871C229.871 78.7699 230.801 116 230.801 116C218.426 124.041 195.264 105.551 195.264 105.551C195.858 82.3524 190.127 81.1765 193.034 59.4608Z"
        fill="#F04365"
      />
      <path
        d="M190.54 106.391L208.789 107.227C214.988 107.584 217.739 102.943 218.035 99.0411L218.845 67.5585L190.54 106.391Z"
        fill="#D72E57"
      />
      <path
        d="M174.112 101.923L163.316 98.065C162.442 97.7521 161.468 97.918 160.745 98.5059L155.943 102.418C154.534 103.567 154.851 105.801 156.524 106.513C157.377 106.876 158.362 106.719 159.061 106.108L161.365 104.086C161.365 104.086 169.271 108.609 173.015 107.368L174.112 101.923Z"
        fill="#F97D7D"
      />
      <path
        d="M201.624 53.9761L199.734 89.3226L170.986 96.7859L172.179 107.546L207.305 103.873C209.825 103.705 212.145 102.55 213.789 100.713C214.701 99.6943 215.179 98.3629 215.341 97.0084L219.703 60.3494L201.624 53.9761Z"
        fill="#F04365"
      />
      <path
        d="M153.28 116.006L194.064 108.868C195.315 108.65 196.151 107.459 195.932 106.207C195.712 104.956 194.521 104.12 193.272 104.338L152.489 111.476C151.237 111.697 150.399 112.887 150.62 114.137C150.838 115.388 152.029 116.224 153.28 116.006Z"
        fill="#243051"
      />
      <path
        d="M153.579 113.815C153.342 113.815 153.103 113.716 152.933 113.523L128.38 85.7005C128.067 85.3435 128.101 84.7996 128.455 84.4846C128.812 84.1696 129.356 84.2011 129.671 84.5602L154.224 112.385C154.539 112.74 154.505 113.286 154.148 113.598C153.985 113.745 153.781 113.815 153.579 113.815Z"
        fill="#243051"
      />
      <path
        d="M201.292 42.736C201.292 42.736 212.921 70.7054 228.824 56.7658C244.73 42.8242 211.481 34.7876 211.481 34.7876L201.292 42.736Z"
        fill="#243051"
      />
      <path d="M126.286 422.011H110.284V376.188H126.286V422.011Z" fill="#8BBDF8" />
      <path d="M212.112 422.011H195.383V376.188H212.112V422.011Z" fill="#8BBDF8" />
      <path
        d="M220.741 442.477H100.119C96.2094 442.477 93.0384 439.306 93.0384 435.394V426.421C93.0384 422.511 96.2094 419.34 100.119 419.34H220.741C224.649 419.34 227.82 422.511 227.82 426.421V435.394C227.82 439.306 224.649 442.477 220.741 442.477Z"
        fill="#5B62DB"
      />
      <path
        d="M218.512 379.759H103.835C97.8714 379.759 93.0394 374.925 93.0394 368.961V349.192C93.0394 343.228 97.8714 338.396 103.835 338.396H218.512C224.476 338.396 229.31 343.228 229.31 349.192V368.961C229.31 374.925 224.476 379.759 218.512 379.759Z"
        fill="#F2A62F"
      />
      <path d="M91.3321 394.272H66.7731L123.997 330.788H148.556L91.3321 394.272Z" fill="white" />
      <path d="M191.085 394.272H166.526L223.752 330.788H248.309L191.085 394.272Z" fill="white" />
      <path d="M140.184 394.272H115.625L172.851 330.788H197.408L140.184 394.272Z" fill="white" />
      <path
        d="M1 442.811H757.504"
        stroke="#102236"
        strokeWidth="0.872"
        strokeMiterlimit="10"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M622.369 316.836L630.263 432.943L642.724 432.911L645.313 316.782L622.369 316.836Z"
        fill="#1B1754"
      />
      <path
        d="M619.92 442.454H642.657V431.91H630.464C624.641 431.91 619.92 436.633 619.92 442.454Z"
        fill="#243051"
      />
      <path
        d="M646.83 442.454H670.539L669.567 431.91H657.374C651.551 431.91 646.83 436.633 646.83 442.454Z"
        fill="#243051"
      />
      <path
        d="M661.624 316.906L669.566 431.912L657.37 432.292L636.996 316.906H661.624Z"
        fill="#2A2B7B"
      />
      <path
        d="M640.346 257.122L672.713 286.016C675.396 288.412 679.424 288.5 682.213 286.224C685.388 283.63 685.812 278.933 683.151 275.81C683.151 275.81 667.517 254.836 658.76 247.87L640.346 257.122Z"
        fill="#F04365"
      />
      <path
        d="M690.306 327.451C690.306 331.745 686.824 335.227 682.529 335.227C678.235 335.227 674.755 331.745 674.755 327.451C674.755 323.156 678.235 319.677 682.529 319.677C686.824 319.677 690.306 323.156 690.306 327.451Z"
        fill="#F89A6E"
      />
      <path
        d="M672.514 282.755L676.781 321.432H687.877L685.351 281.495C685.082 277.723 681.644 274.991 677.908 275.581C674.469 276.125 672.081 279.298 672.514 282.755Z"
        fill="#F04365"
      />
      <path
        d="M637.017 242.439C637.017 242.439 621.142 242.939 620.295 258.821C619.443 274.795 622.148 316.906 622.148 316.906H661.625L662.622 265.329C665.088 251.545 658.105 242.781 643.947 242.25C641.822 242.17 639.516 242.225 637.017 242.439Z"
        fill="#F04365"
      />
      <path d="M662.401 276.812L655.606 270.745L662.158 289.386L662.401 276.812Z" fill="#D72E57" />
      <path
        d="M583.265 228.765C583.265 233.059 579.785 236.541 575.491 236.541C571.197 236.541 567.715 233.059 567.715 228.765C567.715 224.47 571.197 220.989 575.491 220.989C579.785 220.989 583.265 224.47 583.265 228.765Z"
        fill="#F89A6E"
      />
      <path
        d="M600.006 270.996L583.265 232.974L572.591 236.342L587.687 274.824C588.712 278.465 592.635 280.446 596.173 279.112C599.432 277.884 601.127 274.293 600.006 270.996Z"
        fill="#F04365"
      />
      <path
        d="M630.851 243.83C609.933 252.519 591.088 265.86 591.088 265.86C587.934 267.591 586.508 271.358 587.724 274.747C589.108 278.607 593.398 280.575 597.226 279.107L629.127 263.584L630.851 243.83Z"
        fill="#F04365"
      />
      <path
        d="M620.13 267.963C620.132 267.961 620.295 258.819 620.295 258.819L613.067 271.4L620.13 267.963Z"
        fill="#D72E57"
      />
      <path
        d="M632.422 233.42L632.921 244.088C633.031 246.41 634.486 248.452 636.649 249.31C640.75 250.94 645.124 247.666 644.719 243.271L644.076 232.67L632.422 233.42Z"
        fill="#F89A6E"
      />
      <path
        d="M632.78 241.038C632.78 241.038 642.665 242.395 644.076 232.67C645.487 222.945 634.668 226.967 634.668 226.967L632.493 234.957L632.78 241.038Z"
        fill="#E88057"
      />
      <path
        d="M625.472 208.174C618.941 215.213 619.352 226.215 626.391 232.746C633.433 239.277 644.434 238.865 650.965 231.824C657.496 224.785 657.084 213.783 650.043 207.252C643.004 200.722 632 201.133 625.472 208.174Z"
        fill="#F89A6E"
      />
      <path
        d="M643.309 225.231C643.309 225.231 636.446 237.659 646.102 241.076C655.758 244.494 658.53 217.625 658.53 217.625L648.082 197.149L622.612 195.383L621.19 204.202C621.19 204.202 623.622 212.789 644.983 220.872L643.309 225.231Z"
        fill="#243051"
      />
      <path d="M638.106 215.463L641.122 225.595L648.797 221.109L638.106 215.463Z" fill="#243051" />
      <path
        d="M641.621 221.764C639.876 223.645 639.988 226.589 641.869 228.336C643.753 230.081 646.695 229.972 648.44 228.091C650.187 226.205 650.076 223.265 648.194 221.518C646.311 219.771 643.369 219.882 641.621 221.764Z"
        fill="#F89A6E"
      />
      <path
        d="M620.441 214.393C620.441 214.393 619.587 201.955 630.051 195.475C638.68 190.13 650.328 193.049 655.099 202.008C657.34 206.214 658.612 210.332 657.13 218.474L620.441 214.393Z"
        fill="#FDD63B"
      />
      <path
        d="M656.612 220.277C656.545 220.277 656.478 220.275 656.406 220.266L614.674 215.785C613.681 215.674 612.967 214.777 613.078 213.784C613.189 212.793 614.067 212.089 615.079 212.186L656.812 216.669C657.803 216.78 658.519 217.677 658.408 218.67C658.305 219.594 657.519 220.277 656.612 220.277Z"
        fill="#F2A62F"
      />
      <path
        d="M646.387 59.6473C646.387 77.5956 631.837 92.1462 613.888 92.1462C595.942 92.1462 581.391 77.5956 581.391 59.6473C581.391 41.6989 595.942 27.1483 613.888 27.1483C631.837 27.1483 646.387 41.6989 646.387 59.6473Z"
        fill="#5B62DB"
      />
      <path
        d="M618.081 41.7187L617.862 65.169H609.859C609.859 56.2987 609.698 50.6435 609.698 41.7187H618.081ZM618.65 73.7452C618.65 80.0199 609.129 80.0199 609.129 73.7452C609.129 67.4159 618.65 67.4159 618.65 73.7452"
        fill="white"
      />
      <path
        d="M480.798 61.6191L482.591 66.2768C483.692 69.1432 485.956 71.407 488.82 72.5095L493.48 74.3007L488.82 76.0899C485.956 77.1924 483.692 79.4561 482.591 82.3226L480.798 86.9803L479.007 82.3226C477.906 79.4561 475.643 77.1924 472.776 76.0899L468.118 74.3007L472.776 72.5095C475.643 71.407 477.906 69.1432 479.007 66.2768L480.798 61.6191Z"
        fill="#BDC5EA"
      />
      <path
        d="M518.195 437.838L538.947 384.207C539.936 381.65 543.552 381.65 544.544 384.207L565.295 437.838H518.195Z"
        fill="#F2A62F"
      />
      <path d="M559.779 406.009H522.685V395.826H559.779V406.009Z" fill="#FAFAFA" />
      <path d="M562.688 425.647H521.23V415.465H562.688V425.647Z" fill="#FAFAFA" />
      <path
        d="M568.537 442.358H515.189C513.127 442.358 511.456 440.686 511.456 438.626C511.456 436.564 513.127 434.893 515.189 434.893H568.537C570.597 434.893 572.268 436.564 572.268 438.626C572.268 440.686 570.597 442.358 568.537 442.358Z"
        fill="#EB9924"
      />
    </svg>
  );
  return (
    <div className="">
      <div className="flex flex-col items-center justify-center h-full mt-40 space-y-4">
        <Maintenance />
        <span className="text-[40px] font-bold text-primary-200">
          Site is currently under maintenance
        </span>
        <span className="text-2xl font-semibold text-primary-200">
          Email us at
          <span className="font-bold cursor-pointer text-primary-200">care@moneyhop.co</span> to get
          in touch
        </span>
      </div>
    </div>
  );
};
