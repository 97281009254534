import React from "react";
import clsx from "clsx";

import { IRecipientCard } from "./RecipientCard";
import Avatar from "../Avatar";
import Typograpy from "../Typography";
import Button from "../Button";
import { EyeIcon } from "../../../assets/Icons";
import mixpanel from "mixpanel-browser";

const RecipientCard: React.FunctionComponent<IRecipientCard.IProps> = ({
  firstName,
  lastName,
  coutryOfResidence,
  accNo,
  onButtonClick,
  cardContainerClassname,
  icon,
  id,
  onEyeClick,
  type
}) => {
  const mixpanelToken: any = process.env.REACT_APP_MIXPANEL_TOKEN;
  mixpanel.init(mixpanelToken, {
    debug: true,
    api_host: process.env.REACT_APP_MIXPANEL_API,
  });
  return (
    <div
      className={clsx(
        "my-1 py-3 md:py-4 group bg-white pl-5 shadow-xl hover:shadow-blue-200 transition-all cursor-pointer md:pl-8 pr-7 md:pr-12 w-full flex justify-evenly items-center rounded-xl",
        cardContainerClassname,
      )}
    >
      <div
      className="flex w-full justify-evenly"         
      onClick={(e) => {
            e.stopPropagation();
            sessionStorage.setItem("recipient_id", id);
            sessionStorage.setItem("recipient_name", firstName);
            sessionStorage.setItem("categoryType", type === null ? "Others" : type.replace(/\s+/g, '_'));
            mixpanel.track("Recipient Viewed", {
              "Recipient Name": firstName,
              "Recipient ID": id,
            });
            onButtonClick();
        }}>
      <div className="">
        <Avatar
          name={`${firstName.charAt(0)}`}
          customClassname="!w-[50px] !h-[50px] md:!w-20 md:!h-20"
        />
      </div>
      <div className="ml-10 flex flex-col gap-1.5 flex-1">
        <Typograpy
          tagType="span"
          className="transition-all duration-200 lg:text-xl group-hover:font-bold group-hover:translate-x-2"
          text={`${firstName} ${lastName}`}
        />
        <Typograpy
          tagType="span"
          className="text-sm transition-all text-grey-700 group-hover:translate-x-5 group-hover:text-black group-hover:font-semibold"
        >
          <>{coutryOfResidence}</>
        </Typograpy>
        <div className="flex gap-1">
          <Typograpy tagType="span" className="text-[10px] font-bold">
            Account No.
          </Typograpy>
          <Typograpy tagType="span" className="text-[10px]">
            {accNo?.slice(0, 10)}
          </Typograpy>
        </div>
      </div>
      </div>
      <div
        className="mx-10 transition-all cursor-pointer hover:scale-150"
        onClick={(e) => {
          {
            e.stopPropagation();
            sessionStorage.setItem("recipient_id", id);
            sessionStorage.setItem("recipient_name", firstName);
            sessionStorage.setItem("categoryType", type === null ? "Others" : type.replace(/\s+/g, '_'));
            onEyeClick(e);
            mixpanel.track("Recipient Viewed", {
              "Recipient Name": firstName,
              "Recipient ID": id,
            });
            // onButtonClick();
          }
        }}
      >
        <EyeIcon />
      </div>
      <div className="transition-all group-hover:rotate-45">
        <Button
          id="icon-btn-RecipientCard"
          className="w-[34px] h-[34px] md:w-[76px] md:h-[76px] rounded-full opacity-50 group-hover:opacity-100 transition-all bg-[transparent] group-hover:scale-105"
          onClickHandler={() => {
            mixpanel.track("Select Recipient", {
              "Recipient Name": firstName,
              "Recipient ID": id,
            });
            sessionStorage.setItem("recipient_id", id);
            sessionStorage.setItem("recipient_name", firstName);
            sessionStorage.setItem("categoryType", type === null ? "Others" : type.replace(/\s+/g, '_'));
            onButtonClick();
          }}
        >
          {icon}
        </Button>
      </div>
    </div>
  );
};

export default RecipientCard;
