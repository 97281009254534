/* eslint-disable */
import React, { FunctionComponent, useState } from "react";
import { motion } from "framer-motion";
import mixpanel from "mixpanel-browser";
import RecipientProps from "./recipientDetails";
import { TextField } from "@mui/material";
import Button from "../../../components/ui/Button";
import { DropdownIcon } from "../../../assets/Icons";
import { validateAccount } from "../../../services/inward";

export const StepOne: FunctionComponent<RecipientProps.StepOne> = ({
  bankAccountNumber,
  ifscCode,
  bankName,
  accountHolderName,
  accountType,
  bankBranch,
  setBankAccountNumber,
  setIfscCode,
  setAccountType,
  setBankBranch,
  setBankName,
  setAccountHolderName,
  setStep = 1,
  setIsModalOpen,
  acNumber,
  setAcNumber,
  branchName,
  name,
  setBranchName,
  setIsfcCode,
  setName,
  firstName,
  lastName,
  setFirstName,
  setLastName,
  // handleValidateAccount,
}) => {
  const enabled =
    "md:w-1/5 w-4/5 h-12 rounded-full  bg-primary text-white font-bold text-[18px] mt-28";
  const disabled =
    "md:w-1/5 w-4/5  h-12 rounded-full bg-gray-500 text-white font-bold text-[18px] mt-36";
  const phoneNumber = sessionStorage.getItem("phoneNumber") || "";
  const mixpanelToken: any = process.env.REACT_APP_MIXPANEL_TOKEN;
  mixpanel.init(mixpanelToken, {
    debug: true,
    api_host:process.env.REACT_APP_MIXPANEL_API,
  });
  const specialCharRegExp = "[a-zA-Z0-9 ]{1,}";

  const stepOneDisabled = bankName === "" || name === "" || accountType === "" || branchName === "";

  const [showDetails, setShowDetails] = useState(false);

  const handleValidateAccount = () => {
    if (!acNumber || !ifscCode) {
      alert("Enter details");
      return;
    }

    const body = { accountNumber: acNumber, ifsc: ifscCode };
    setShowDetails(true);

    validateAccount(body).then((res) => {
      window.console.log(res);

      setBankName(res?.data?.bankName);
      setName(res?.data?.nameAtBank);
      // setAccountType(res?.data?.nameAtBank)
      setBranchName(res?.data?.branch);
      setFirstName(res?.data?.firstName);
      setLastName(res?.data?.lastName);
    });
  };

  return (
    <motion.div
      initial={{ width: 0 }}
      animate={{ width: "100%" }}
      exit={{ x: window.innerWidth, transition: { duration: 0.5 } }}
    >
      <div id="step1" className="flex flex-col items-center justify-center">
        <div className="flex flex-col justify-start md:w-[50%] w-[80%]">
          <p className="m-2 mt-8 md:mt-2">
            Fill the following bank details
            <span
              onClick={() => setShowDetails(false)}
              className="ml-3 cursor-pointer text-primary"
            >
              Edit Field
            </span>
          </p>

          <div className="flex flex-col w-full space-y-4 md:space-y-0 md:flex-row md:justify-between md:space-x-10">
            <TextField
              label="Bank Account Number"
              required={true}
              fullWidth
              type="text"
              className="m-2 text-center bg-white border-solid"
              value={acNumber}
              onChange={(e) => setAcNumber(e.target.value)}
            />
            <TextField
              label="IFSC CODE"
              required={true}
              fullWidth
              // label="IFSC CODE"
              type="text"
              className="m-2 text-center bg-white border-solid"
              value={ifscCode}
              onChange={(e) => setIsfcCode(e.target.value)}
            />
          </div>

          {showDetails && (
            <div className="flex flex-col items-center md:mt-1">
              <hr className="w-full my-3 border-[#A4C3FF]" />
              <div className="flex flex-col space-y-4 md:space-y-0 w-full md:space-x-[5%] md:flex-row md:justify-between">
                <TextField
                  label="Bank Name"
                  required={true}
                  fullWidth
                  // label="IFSC CODE"
                  type="text"
                  className="m-2 text-center bg-white border-solid"
                  disabled={true}
                  value={bankName}
                  onChange={(e) => setBankName(e.target.value)}
                />
                <TextField
                  label="Account holder name"
                  required={true}
                  fullWidth
                  // label="IFSC CODE"
                  type="text"
                  className="m-2 text-center bg-white border-solid"
                  disabled={true}
                  value={name}
                  onChange={(e) => setName(e.target.value)}
                />
              </div>
              <div className="flex md:space-x-[5%] justify-between space-y-4 md:space-y-0 items-center flex-col w-full mt-2 pt-2 md:flex-row md:justify-between">
                <div
                  id="checkthis"
                  style={{ paddingRight: 5 }}
                  onClick={() => setIsModalOpen("accountType")}
                  className="flex items-center w-full h-16 bg-white space-x-3 border rounded cursor-pointer justify-center"
                >
                  <div className="px-4 flex items-center justify-between w-full">
                    {accountType ? accountType : "Select Account Type*"}
                    {<DropdownIcon />}
                  </div>
                </div>
                <TextField
                  label="Branch"
                  required={true}
                  fullWidth
                  type="text"
                  className="text-center bg-white border-solid"
                  disabled={true}
                  value={branchName}
                  onChange={(e) => setBranchName(e.target.value)}
                />
              </div>
              <Button
                id="submit-btn-step-one-1 "
                className={stepOneDisabled ? disabled : enabled}
                onClickHandler={() =>{ 
                  mixpanel.track("Submit _Step 1", {
                    "Full Name": name,
                    // eslint-disable-next-line prettier/prettier
                  });
                  setStep(2);
                }
                }
                disabled={stepOneDisabled}
              >
                Next
              </Button>
            </div>
          )}
        </div>

        {!showDetails && (
          <Button
            id="submit-btn-step-one-1 "
            className="md:w-1/5 w-4/5 h-10 space-x-0 text-white rounded-full bg-primary md:mt-[15%] mt-[30%]"
            onClickHandler={handleValidateAccount}
          >
            Save & Proceed
          </Button>
        )}
      </div>
    </motion.div>
  );
};
