import clsx from "clsx";
import moment from "moment";
import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { CancellationBanner, CloseIcon } from "../../assets/Icons";
import Button from "../../components/ui/Button";
import Checkbox from "../../components/ui/Checkbox";
import Modal from "../../components/ui/Modal";
import { getChartData } from "../../services/fxRates";
import { receivingCountryData, TransactioncancellationReasons } from "../../utils";
import AreaCharts from "./chart";
import { TransferIcon } from "../../assets/Icons";
import { motion } from "framer-motion";
import { CircularLoader } from "../../components/ui/CircularLoader";
import { handleTransactionComplete } from "../../services/transaction";
import { transactionDetails } from "../../store";
import { useResetRecoilState } from "recoil";

const symbols = ["USD", "EUR", "GBP", "CAD", "SGD", "AED", "SAR", "MUR", "NZD", "JPY", "AUD"];

const FxRates = () => {
  const [currentCurrency, setCurrentCurrency] = useState("USD");
  const [isLoading, setIsLoading] = useState(false);
  const [ratesArray, setRatesArray] = useState([]);
  const [datesArray, setDatesArray] = useState([]);
  const [graphRatesArray, setGraphRatesArray] = useState([]);
  const [startDate, setStartDate] = React.useState("");
  const [endDate, setEndDate] = React.useState("");
  const [graphData, setGraphData] = React.useState<any>();
  const [cancellationReasons, setCancellationReasons] = React.useState<string>("");
  const [otherReason, setOtherReason] = React.useState<string>("");
  const [isModalOpen, setIsModalOpen] = React.useState<string>("");
  const [currency, setCurrency] = React.useState<string>("USD");
  const [latestRates, setLatestRates] = useState<string>("");
  const symbolsString = symbols.toString();
  const navigate = useNavigate();
  const resetTransactionDetails = useResetRecoilState(transactionDetails);

  useEffect(() => {
    const latestRate = graphRatesArray[graphRatesArray.length - 1];
    const latestRateString = latestRate + "";
    const stringArray = latestRateString.split(".");
    const latestRateDisplay = stringArray[0] + "." + stringArray[1]?.substring(0, 2);
    setLatestRates(latestRateDisplay);
  }, [graphRatesArray]);

  useEffect(() => {
    const today = new Date();
    const yesterday = new Date(today);

    yesterday.setDate(yesterday.getDate() - 1);
    const year = new Date(today);
    year.setDate(yesterday.getDate() - 365);
    let startDateValue = "";

    startDateValue = moment(yesterday).subtract(1, "months").format("YYYY-MM-DD");
    const prevDate = moment(today.toDateString()).format("YYYY-MM-DD");

    setStartDate(startDateValue);
    setEndDate(prevDate);
  }, []);

  const handleFxRates = (start: string, end: string) => {
    setIsLoading(true);
    const chartData = {
      symbolsString: symbolsString,
      startDate: start,
      endDate: end,
    };

    getChartData(chartData)
      .then((res) => {
        const dateArr: any = [],
          dataArr: any = [];

        const data = res.data.rates;
        Object.keys(data).map((item) => {
          dateArr.push(item);
          dataArr.push(data[item]);

          return { [item]: [data[item]] };
        });

        setDatesArray(dateArr);
        setRatesArray(dataArr);
        setIsLoading(false);
      })
      .catch(() => {
        setIsLoading(false);
      });
  };

  const handleDropdown = () => {
    const dataArr: any = [];

    if (currentCurrency === "USD") {
      dataArr.length = 0;
      ratesArray.map((item: any) => {
        dataArr.push((1 / item.USD).toFixed(3));
      });
      setGraphRatesArray(dataArr);
    } else if (currentCurrency === "EUR") {
      dataArr.length = 0;
      ratesArray.map((item: any) => {
        dataArr.push((1 / item.EUR).toFixed(3));
      });
      setGraphRatesArray(dataArr);
    } else if (currentCurrency === "GBP") {
      dataArr.length = 0;
      ratesArray.map((item: any) => dataArr.push((1 / item.GBP).toFixed(3)));
      setGraphRatesArray(dataArr);
    } else if (currentCurrency === "AUD") {
      dataArr.length = 0;
      ratesArray.map((item: any) => dataArr.push((1 / item.AUD).toFixed(3)));
      setGraphRatesArray(dataArr);
    } else if (currentCurrency === "CAD") {
      dataArr.length = 0;
      ratesArray.map((item: any) => dataArr.push((1 / item.CAD).toFixed(3)));
      setGraphRatesArray(dataArr);
    } else if (currentCurrency === "SGD") {
      dataArr.length = 0;
      ratesArray.map((item: any) => dataArr.push((1 / item.SGD).toFixed(3)));
      setGraphRatesArray(dataArr);
    } else if (currentCurrency === "NZD") {
      dataArr.length = 0;
      ratesArray.map((item: any) => dataArr.push((1 / item.NZD).toFixed(3)));
      setGraphRatesArray(dataArr);
    } else if (currentCurrency === "AED") {
      dataArr.length = 0;
      ratesArray.map((item: any) => dataArr.push((1 / item.AED).toFixed(3)));
      setGraphRatesArray(dataArr);
    } else if (currentCurrency === "SAR") {
      dataArr.length = 0;
      ratesArray.map((item: any) => dataArr.push((1 / item.SAR).toFixed(3)));
      setGraphRatesArray(dataArr);
    }
    // else if (currentCurrency === "MUR") {
    //   dataArr.length = 0;
    //   ratesArray.map((item: any) => dataArr.push((1 / item.MUR).toFixed(3)));
    //   setGraphRatesArray(dataArr);
    // }
    else if (currentCurrency === "JPY") {
      dataArr.length = 0;
      ratesArray.map((item: any) => dataArr.push((1 / item.JPY).toFixed(3)));
      setGraphRatesArray(dataArr);
    }
  };

  useEffect(() => {
    handleDropdown();
  }, [ratesArray, datesArray, currentCurrency]);

  useEffect(() => {
    handleFxRates(startDate, endDate);
  }, [currentCurrency, startDate, endDate]);

  useEffect(() => {
    if (graphRatesArray && datesArray) {
      const mixedArray: any = graphRatesArray.map((rates: any, idx) => {
        return { date: datesArray[idx], rate: rates };
      });
      setGraphData(mixedArray);
    }
  }, [graphRatesArray, datesArray]);

  const handleNavigate = () => {
    handleTransactionComplete();
    resetTransactionDetails();
    navigate("/");
  };

  return (
    <motion.div
      initial={{ width: 0 }}
      animate={{ width: "100%" }}
      exit={{ x: window.innerWidth, transition: { duration: 0.5 } }}
    >
      <div className="p-4 lg:ml-6">
        <div className="border w-full border-[#0F5BF1] mb-12 overflow-hidden"></div>
        <div className="text-2xl font-bold">Know Currency Rates</div>
        <div className="mb-4 text-lg">Know how much is your currency value</div>
        <div className="flex flex-col items-center justify-between p-4 md:flex-row">
          <div className="flex items-center mb-4 space-x-4 md:mb-0">
            <div className="hidden text-lg font-bold lg:block">Select Currency</div>
            <div
              onClick={() => setIsModalOpen("currency")}
              className="flex items-center px-20 py-3 space-x-5 font-bold text-white rounded-lg cursor-pointer font-ssp bg-[#354C7E]"
            >
              <img
                src={`/images/png/${currency}.png`}
                alt="selectedCurrency-image"
                className="rounded-[20px] w-[20px] h-[20px]"
              />
              <span>{currency}</span>
            </div>
          </div>
          <div className="flex items-center px-4 py-2 ml-4 text-xl font-bold text-black bg-gray-300 rounded-md lg:ml-0">
            <span className="mr-1">1</span>
            <img
              src={`/images/png/${currency}.png`}
              alt="selectedCurrency-image"
              className="rounded-[20px] w-[30px] h-[30px] ml-2 mr-5"
            />
            <TransferIcon className="mr-2" height={"30px"} width="30px" />
            {isLoading ? (
              <div className="">
                <CircularLoader />
              </div>
            ) : (
              <span className="ml-2">{latestRates}</span>
            )}
            <img
              src={`/images/png/INR.png`}
              alt="selectedCurrency-image"
              className="rounded-[20px] w-[30px] h-[30px] ml-2 mr-2"
            />
          </div>
        </div>
        <div className="px-2 mt-4 lg:px-8">
          <div className="mb-2 ml-2 text-xl font-bold">Your currency rate</div>
          <div className="border w-full border-[#D5D5D5] mb-12 overflow-hidden"></div>
        </div>
        <div
          className="lg:w-[80vw] w-full lg:h-[500px] h-[380px] lg:p-2 lg:m-auto rounded-b-lg shadow-[0px_2px_0px_0px_rgba(236, 236, 236, 1)]"
          style={{ zoom: "124%" }}
        >
          {isLoading ? (
            "Fetching Data...."
          ) : (
            <AreaCharts data={graphData} graphRatesArray={graphRatesArray} />
          )}
        </div>
      </div>
      <Modal
        headerClass="!m-0"
        modalClass="!p-0 !rounded !overflow-hidden !w-full lg:!w-min"
        visible={isModalOpen === "cancelReasons"}
        closeHandler={() => setIsModalOpen("")}
        closable={false}
      >
        <div className="flex items-start">
          <div className="px-[34px] pt-[34px] pb-[54px] md:pb-0">
            <div className="pb-3 text-lg font-bold text-black px-7">Reason for cancellation</div>
            {TransactioncancellationReasons.map((data, index) => {
              return (
                <div className="space-x-2 px-7 border-b-[1px] py-6 first:pt-0" key={index}>
                  <Checkbox
                    label={data}
                    value={data}
                    name={"cancellationReason"}
                    handleChange={(e) => {
                      setCancellationReasons(e.target.checked ? e.target.value : "");
                    }}
                  />
                </div>
              );
            })}
            {cancellationReasons === "Other Reason..." && (
              <div className="flex items-center justify-center mt-2">
                <textarea
                  name="otherReasonDescription"
                  value={otherReason}
                  rows={3}
                  cols={40}
                  placeholder="Write your reason here ..... in less than 50 words"
                  onChange={(e) => {
                    setOtherReason(e.target.value);
                  }}
                  className="w-full p-3 bg-white-100 focus-visible:border-0 placeholder:text-center"
                />
              </div>
            )}
            <div
              className={clsx(
                cancellationReasons === "Other Reason..." ? "mt-7" : "mt-24",
                "flex items-center justify-center px-[78px]",
              )}
            >
              <Button
                id="Other-Reason-cancellation"
                bgColor={`${
                  cancellationReasons === "" ||
                  (cancellationReasons === "Other Reason..." && otherReason === "")
                    ? "bg-grey-550"
                    : "bg-primary"
                }`}
                fontStyle={{
                  fontFamily: "",
                  fontSize: "text-lg",
                  fontWeight: "font-bold",
                  fontColor: "text-white",
                }}
                borderStyle={{
                  borderWidth: "border-none",
                  borderRadius: "rounded-[100px]",
                }}
                disabled={
                  cancellationReasons === "" ||
                  (cancellationReasons === "Other Reason..." && otherReason === "")
                }
                className={"py-3 px-28"}
                onClickHandler={() =>
                  cancellationReasons !== "" && cancellationReasons === "Other Reason..."
                    ? otherReason !== "" && handleNavigate()
                    : handleNavigate()
                }
              >
                Confirm
              </Button>
            </div>
          </div>
          <div className="hidden w-1/2 h-full pb-6 pt-7 pl-9 pr-11 lg:block bg-red-250">
            <button className="float-right" onClick={() => setIsModalOpen("")}>
              <CloseIcon fill="fill-grey-1200" />
            </button>
            <div id="text" className="flex flex-col mt-10">
              <span
                className={clsx(
                  cancellationReasons === "" ? "text-[32px]" : "text-[26px]",
                  "text-black font-semibold",
                )}
              >
                {cancellationReasons === "" ? "Sorry!" : "Did we made any problems??"}
              </span>
              <span className="mt-2 text-lg font-normal text-black">
                {cancellationReasons === ""
                  ? "Did you face any issue?"
                  : "Somwtimes there might be an issue so relax and try again."}
              </span>
            </div>
            <div className="flex justify-center px-4 mt-10">
              <CancellationBanner />
            </div>
          </div>
        </div>
      </Modal>
      <Modal
        header="Select Currency"
        headerClass="text-[28px] font-bold"
        visible={isModalOpen === "currency"}
        closeHandler={() => setIsModalOpen("")}
        closable={false}
        modalClass="w-full p-4 rounded-xl"
      >
        <div>
          {receivingCountryData.map((element, index) => {
            return (
              <div
                key={index}
                onClick={() => {
                  setCurrency(element.currencyName);
                  setCurrentCurrency(element.currencyName);
                  setIsModalOpen("");
                }}
                className="flex items-center p-4 space-x-10 border-b-2 cursor-pointer group"
              >
                <div className="transition-all duration-1000 group-hover:rotate-180">
                  <img
                    src={`/images/png/${element.currencyName}.png`}
                    alt="selectedCurrency-image"
                    className="rounded-[20px] w-[20px] h-[20px]"
                  />
                </div>
                <span>{element.countryName}</span>
              </div>
            );
          })}
        </div>
      </Modal>
    </motion.div>
  );
};

export default FxRates;
