import React from "react";
import { IIcons } from "./icons";

const LeftArrowIcon = ({ fillColor }: IIcons.IconProps) => (
  <svg width="14" height="23" viewBox="0 0 14 23" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      d="M12.5 2L3 11.5L12.5 21"
      stroke="#524F4F"
      strokeWidth="3"
      strokeLinecap="round"
      className={fillColor}
    />
  </svg>
);
export default LeftArrowIcon;
