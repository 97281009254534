import React from "react";
import { IIcons } from "./icons";

const DebitCard = ({ fillColor }: IIcons.IconProps) => (
  <svg
    width="85"
    height="61"
    viewBox="0 0 85 61"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    className={fillColor}
  >
    <path
      d="M9.84375 0.8125C4.96025 0.8125 0.9375 4.83525 0.9375 9.71875V51.2812C0.9375 56.1648 4.96025 60.1875 9.84375 60.1875H75.1562C80.0398 60.1875 84.0625 56.1648 84.0625 51.2812V9.71875C84.0625 4.83525 80.0398 0.8125 75.1562 0.8125H9.84375ZM9.84375 6.75H75.1562C76.8337 6.75 78.125 8.04132 78.125 9.71875V51.2812C78.125 52.9587 76.8337 54.25 75.1562 54.25H9.84375C8.16632 54.25 6.875 52.9587 6.875 51.2812V9.71875C6.875 8.04132 8.16632 6.75 9.84375 6.75ZM12.8125 15.6562V27.5312H27.6562V15.6562H12.8125ZM54.375 15.6736C51.3057 15.6736 48.238 16.2583 45.3528 17.4247C42.4676 18.5912 39.7652 20.3393 37.4323 22.6722L41.6302 26.8702C43.3957 25.1048 45.4278 23.7811 47.5967 22.8984C49.7657 22.0156 52.0716 21.5764 54.375 21.5764C58.9818 21.5764 63.5888 23.3393 67.1198 26.8702L71.3177 22.6722C68.9848 20.3393 66.2824 18.5912 63.3972 17.4247C60.512 16.2583 57.4443 15.6736 54.375 15.6736ZM54.375 27.7632C51.3296 27.7632 48.2856 28.9124 45.979 31.219L50.177 35.417C52.5226 33.0714 56.2274 33.0714 58.573 35.417L62.7768 31.219C60.4702 28.9124 57.4204 27.7632 54.375 27.7632ZM54.375 39.615C53.5876 39.615 52.8325 39.9278 52.2758 40.4845C51.719 41.0413 51.4062 41.7964 51.4062 42.5837C51.4062 43.3711 51.719 44.1262 52.2758 44.683C52.8325 45.2397 53.5876 45.5525 54.375 45.5525C55.1624 45.5525 55.9175 45.2397 56.4742 44.683C57.031 44.1262 57.3438 43.3711 57.3438 42.5837C57.3438 41.7964 57.031 41.0413 56.4742 40.4845C55.9175 39.9278 55.1624 39.615 54.375 39.615Z"
      fill="#0F5BF1"
    />
  </svg>
);
export default DebitCard;
