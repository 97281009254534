/* eslint-disable @typescript-eslint/no-unused-vars */
import { TextField } from "@mui/material";
import { motion } from "framer-motion";
import React, { FunctionComponent, useEffect } from "react";
import Button from "../../components/ui/Button";
import RecipientProps from "./Recipient";

export const StepFour: FunctionComponent<RecipientProps.StepFour> = ({
  bankAccountNameCorresponding,
  setBankAccountNameCorresponding,
  bankAccountNumberCorresponding,
  setBankAccountNumberCorresponding,
  refrenceCorresponding,
  setRefrenceCorresponding,
  setStep,
  handleFinalClick,
  setIsCorrespondingAccount,
  errorMessage,
  setErrorMessage,
  loading
}) => {
  const [confrmAccNumber, setConfrmAccNumber] = React.useState("");
  const enabled = "w-[200px] h-12 rounded-full bg-primary text-white font-bold text-[18px]";
  const disabled = "w-[200px] h-12 rounded-full bg-gray-500 text-white font-bold text-[18px]";
  const [cnfrmAccNumber, setCnfrmAccNumber] = React.useState("");
  const specialCharRegExp = "[a-zA-Z0-9 ]{1,}";

  const btnDisable =
    !bankAccountNameCorresponding ||
    !bankAccountNumberCorresponding ||
    bankAccountNumberCorresponding !== cnfrmAccNumber;

  return (
    <motion.div
      className="flex flex-col items-center mt-20"
      initial={{ width: 0 }}
      animate={{ width: "100%" }}
      exit={{ x: window.innerWidth, transition: { duration: 0.5 } }}
    >
      <div className="w-[90%] lg:w-[70%] 2xl:w-[50%]">
        <div className="w-[72%] mx-auto">
          <div className="flex flex-col ml-4">
            <div className="flex flex-col mb-4 lg:space-x-8 lg:justify-between lg:flex-row">
              <TextField
                value={bankAccountNumberCorresponding}
                onChange={(e) => {
                  setBankAccountNumberCorresponding(e.target.value);
                }}
                label="Bank Account Number"
                fullWidth
                className="w-full p-5 py-[30px] bg-white font-medium text-black border-2 border-gray-300 rounded-md h-14"
                tabIndex={1}
                required
              />
              <div className="w-full">
                <TextField
                  className="w-1/2 mb-4 lg:mb-0 bg-white focus-visible:!border-black"
                  name="username"
                  label="Confirm Bank Account Number"
                  fullWidth
                  onChange={(e) => setCnfrmAccNumber(e.target.value)}
                  value={cnfrmAccNumber}
                  tabIndex={2}
                  required
                />
                {cnfrmAccNumber !== bankAccountNumberCorresponding && cnfrmAccNumber && (
                  <span className="text-red-500 text-[14px] ml-3">
                    The account numbers do not match.
                  </span>
                )}
              </div>
            </div>
            <div className="flex flex-col items-center justify-between mb-4 lg:space-x-8 lg:flex-row">
              <TextField
                className="mb-4 bg-white lg:mb-0"
                name="username"
                type="text"
                label="Bank Account Name"
                fullWidth
                // variant="outlined"
                required={true}
                onChange={(e) => setBankAccountNameCorresponding(e.target.value)}
                value={bankAccountNameCorresponding}
                tabIndex={3}
              />
            </div>
            <div className="flex flex-col mx-auto mt-20">
              <span className="text-[14px] text-red-200 mx-auto">
                {errorMessage && errorMessage}
              </span>
              <div className="flex items-center mt-4 space-x-10">
                <Button
                  id="back-recepient-btn-2"
                  onClickHandler={() => {
                    setStep(3);
                    setIsCorrespondingAccount(false);
                    setErrorMessage("");
                  }}
                  className="w-[200px] bg-blue-200 h-12 rounded-full text-primary font-bold text-[18px]"
                >
                  Go Back
                </Button>
                <Button
                  id="submit-recepient-btn-1"
                  onClickHandler={handleFinalClick}
                  disabled={btnDisable}
                  className={btnDisable ? disabled : enabled}
                >
                  {loading ? (
                    <div className="flex items-center justify-center animate-spin-slow py-1">
                      <img src="/images/svg/smallLoader.svg" alt="" />
                    </div>
                  ) : (
                    "Save & Proceed"
                  )}
                </Button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </motion.div>
  );
};
