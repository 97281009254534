import React from "react";
import { IIcons } from "./icons";

const MobileOtpIcon = ({ fillColor, width, height }: IIcons.IconProps) => (
  <svg
    width={width || "18"}
    height={height || "22"}
    viewBox="0 0 18 22"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M3.22852 0C1.84852 0 0.728516 1.12 0.728516 2.5V19.5C0.728516 20.88 1.84852 22 3.22852 22H12.2285C13.6085 22 14.7285 20.88 14.7285 19.5V15H12.7285V17H2.72852V3H14.7285V2.5C14.7285 1.12 13.6085 0 12.2285 0H3.22852ZM8.72852 5C8.33052 5 7.99303 5.23527 7.83203 5.57227L12.7285 8.64062L17.6348 5.58789C17.4768 5.24289 17.1325 5 16.7285 5H8.72852ZM7.72852 7.75781V12C7.72852 12.552 8.17652 13 8.72852 13H16.7285C17.2805 13 17.7285 12.552 17.7285 12V7.7793L12.7285 10.8926L7.72852 7.75781ZM7.72852 17.875C8.35052 17.875 8.85352 18.378 8.85352 19C8.85352 19.622 8.35052 20.125 7.72852 20.125C7.10652 20.125 6.60352 19.622 6.60352 19C6.60352 18.378 7.10652 17.875 7.72852 17.875Z"
      fill="#354C7E"
      className={fillColor}
    />
  </svg>
);
export default MobileOtpIcon;
