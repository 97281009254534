import { Axios } from "../axios";

export const uploadInwardDocuments = (body: FormData) => {
  const currentUser = JSON.parse(sessionStorage.getItem("currentUser") || "{}");
  return Axios.post(`/remit/user/${currentUser.id}/inwardIdentityDetails`, body, {
    headers: {
      Authorization: `${currentUser.idToken}`,
    },
  });
};

export const uploadInwardAddressDocuments = (body: FormData) => {
  const currentUser = JSON.parse(sessionStorage.getItem("currentUser") || "{}");
  return Axios.post(`/remit/user/${currentUser.id}/inwardAddressDetails`, body, {
    headers: {
      Authorization: `${currentUser.idToken}`,
    },
  });
};

export const inwardKycStatus = () => {
  const currentUser = JSON.parse(sessionStorage.getItem("currentUser") || "{}");
  return Axios.get(`/remit/user/${currentUser.id}/inwardKycStatus`, {
    headers: {
      Authorization: `${currentUser.idToken}`,
    },
  });
};

export const validateAccount = async (body: any) => {
  const currentUser = JSON.parse(sessionStorage.getItem("currentUser") || "{}");
  return await Axios.post(`/remit/bank/${currentUser?.id}/accountValidate`, body, {
    headers: {
      Authorization: `${currentUser?.idToken}`,
    },
  });
};

export const sendInwardRecipients = async (body: any) => {
  const currentUser = JSON.parse(sessionStorage.getItem("currentUser") || "{}");
  return await Axios.post(`/remit/user/${currentUser.id}/recipients`, body, {
    headers: {
      Authorization: `${currentUser.idToken}`,
    },
  });
};

export const updateNewKycStatus = async (body: any) => {
  const currentUser = JSON.parse(sessionStorage.getItem("currentUser") || "{}");
  return await Axios.put(`/remit/user/${currentUser.id}/updateInwardKycStatus`, body, {
    headers: {
      Authorization: `${currentUser.idToken}`,
    },
  });
};
