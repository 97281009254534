/* eslint-disable*/
import React, { FC } from "react";
import { TextField, Typography } from "@mui/material";
import FloatingDropDown from "../../components/ui/FloatingDropDown";

const AddressDetails: FC<UserDetailsProps.AddressDetailsProps> = ({
  address,
  pinCode,
  city,
  stateList,
  state,
  onSelectState,
  setAddress,
  setCity,
  setPinCode,
}) => {
  return (
    <div className="my-5 w-full md:flex justify-center">
      <div>
        <Typography className="text-primary">Address Details</Typography>
        <div className="xl:grid xs:grid xs:grid-cols-1 xs:gap-x-24 xs:gap-y-5 xl:grid-cols-3 xl:justify-between md:grid md:grid-cols-2 md:gap-x-24 md:gap-y-5 md:space-x-0 pace-y-5 xl:space-x-5 my-5">
          <TextField
            variant="outlined"
            id="outlined-basic"
            required
            label="Address"
            className="px-5 md:w-96 w-full"
            value={address && address}
            onChange={(e) => setAddress(e.target.value)}
            disabled={false}
          />
          <TextField
            variant="outlined"
            id="outlined-basic"
            required
            label="Pincode"
            className="px -5 md:w-96 w-full"
            value={pinCode}
            onChange={(e) => setPinCode(e.target.value)}
            disabled={false}
          />
          <TextField
            variant="outlined"
            id="outlined-basic"
            required
            label="City"
            className="px -5 md:w-96 w-full"
            value={city}
            onChange={(e) => setCity(e.target.value)}
            disabled={false}
          />
        </div>
        <div>
          <FloatingDropDown
            options={stateList}
            dropDownvalue={state}
            onSelect={(e) => onSelectState(e)}
            placeHolder="State"
            required
            dropdownClassname="md:w-96 w-full"
          />
        </div>
      </div>
    </div>
  );
};

export default AddressDetails;
