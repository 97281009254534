import { IAuth } from "../auth/auth";
import { Axios } from "../axios";

const vendorKey = process.env.REACT_APP_VENDOR_KEY;

export const getTransactionHistory = async (currentUser: IAuth.IIsAuthorisedProps) => {
  if (currentUser !== null && currentUser !== undefined) {
    return await Axios.get(
      `/remit/user/${vendorKey}/getTransactionHistoryDashboard?userId=${currentUser.id}`,
      {
        headers: {
          Authorization: `${currentUser.idToken}`,
        },
      },
    );
  }
};

export const downloadInvoice = async (
  currentUser: IAuth.IIsAuthorisedProps,
  id: string,
  doc: string,
) => {
  if (currentUser !== null && currentUser !== undefined) {
    return await Axios.get(`/remit/user/${vendorKey}/transaction/${id}/getPDF/${doc}`, {
      headers: {
        Authorization: `${currentUser.idToken}`,
      },
      responseType: "blob",
    });
  }
};

export const getPaymentDetails = async (currentUser: IAuth.IIsAuthorisedProps, txnNo: any) => {
  if (currentUser !== null && currentUser !== undefined) {
    return await Axios.get(`/remit/user/${currentUser.id}/transaction/${txnNo}/getPaymentDetails`, {
      headers: {
        Authorization: `${currentUser.idToken}`,
      },
    });
  }
};

export const uploadDocs = async (currentUser: IAuth.IIsAuthorisedProps, txnNo: any, body: any) => {
  if (currentUser !== null && currentUser !== undefined) {
    return await Axios.post(
      `/remit/user/${currentUser.id}/transaction/${txnNo}/transactionDoc`,
      body,
      {
        headers: {
          Authorization: `${currentUser.idToken}`,
        },
      },
    );
  }
};
