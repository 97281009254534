/* eslint-disable */
import React, { useState } from "react";
import IReviewPage from "./review";
import Modal from "../../components/ui/Modal";
import { MailIcon, MobileIcon, SuccessIcon } from "../../assets/Icons";
import clsx from "clsx";
import { getSignedStatus, sendA2Form } from "../../services/reviewPage";
import ErrorField from "../../components/ui/ErrorField";
import mixpanel from "mixpanel-browser";
import Button from "../../components/ui/Button";

export const EsignModal: React.FC<IReviewPage.EsignModalProps> = ({
  isEsignModal,
  setIsEsignModal,
  modalTitle,
  handleDoLaterA2Form,
  handleA2Form,
  viewA2Form,
  isViewingA2Form,
  setIsViewingA2Form,
}) => {
  const [mobileActive, setMobileActive] = React.useState<boolean>(false);
  const [mailActive, setMailActive] = React.useState<boolean>(false);
  const [step, setStep] = React.useState<string>("1");
  const [error, setError] = React.useState<string>("");
  const [buttonLoader, setButtonLoader] = useState({
    mobileLoader: false,
    emailLoader: false
  })
  const mixpanelToken: any = process.env.REACT_APP_MIXPANEL_TOKEN;
  mixpanel.init(mixpanelToken, {
    debug: true,
    api_host: process.env.REACT_APP_MIXPANEL_API,
  });

  React.useEffect(() => {
    let interval: any;
    if (isEsignModal) {
      interval = setInterval(() => {
        handleEsignedStatus();
      }, 3000);
    }
    return () => clearInterval(interval);
  }, [isEsignModal, step]);

  const handleEsignedStatus = () => {
    const currentUser = JSON.parse(sessionStorage.getItem("currentUser") || "{}");
    if (isEsignModal) {
      if (step === "2") {
        getSignedStatus({
          userId: currentUser.id,
        })
          .then((response) => {
            setError("");
            if (response?.data?.signed) {
              if (step === "2") {
                setStep("3");
              }
            }
          })
          .catch((error) => {
            setError(error?.response?.data?.error?.type);
          });
      }
    }
  };

  const handleMobileNumber = () => {
    setMobileActive(true);
    setMailActive(false);
    setButtonLoader((prev) => ({ ...prev, mobileLoader: true }));
    const currentUser = JSON.parse(sessionStorage.getItem("currentUser") || "{}");
    sendA2Form({
      sendType: "sms",
      userId: currentUser.id,
      transactionNumber: null,
    })
      .then((response) => {
        mixpanel.track("Sendlink_mobile")
        setStep("2");
        setError("");
      })
      .catch((error) => {
        setError(error?.response?.data?.error?.type);
        setTimeout(() => {
          setError("");
        }, 3000);
      })
      .finally(() =>
        setButtonLoader((prev) => ({ ...prev, mobileLoader: false })));
  };

  const handleMail = () => {
    setMobileActive(false);
    setMailActive(true);
    setButtonLoader((prev) => ({ ...prev, emailLoader: true }));
    const currentUser = JSON.parse(sessionStorage.getItem("currentUser") || "{}");
    sendA2Form({
      sendType: "Mail",
      userId: currentUser.id,
      transactionNumber: null,
    })
      .then((response) => {
        mixpanel.track("Sendlink_email")
        setStep("2");
        setError("");
      })
      .catch((error) => {
        setError(error?.response?.data?.error?.type);
        setTimeout(() => {
          setError("");
        }, 3000);
      })
      .finally(() =>
        setButtonLoader((prev) => ({ ...prev, emailLoader: false })));
  };

  return (
    <Modal
      modalClass="!p-0 md:pr-11 flex p-0 flex-col !rounded lg:!w-6/12 !w-full"
      headerClass="!mt-0 text-white sticky h-24 pr-4 pl-[42%] bg-primary font-bold text-2xl"
      visible={isEsignModal}
      header={modalTitle ? modalTitle : "Sign A2 Form"}
      closeHandler={() => {
        setIsEsignModal(false), setMobileActive(false), setMailActive(false);
      }}
    >
      {step === "1" && (
        <div>
          <div className="flex flex-col items-center my-4">
            <h1 onClick={(e) => {
              if (viewA2Form) {
                viewA2Form(e);
              }
            }}
              className="text-[15px] py-4 lg:text-[20px] font-semibold text-primary first-letter cursor-pointer">
              View A2 Form </h1>
            {isViewingA2Form && (
              <div className="flex justify-center items-center mt-2">
                <div className="border-t-4 border-blue-500 rounded-full animate-spin w-8 h-8 border-4" role="status">
                </div>
                <span className="ml-3 text-primary">Opening A2 form...</span>
              </div>
            )}
            <h1 className="font-semibold py-4 text-[20px]">Receive link on</h1>
            <div className="flex mt-4">
              <Button onClickHandler={() => handleMobileNumber()} className="w-64 h-auto p-5">
                <div
                  className={clsx(
                    "flex justify-between items-5center rounded-lg p-5",
                    mobileActive ? "bg-primary-300 text-white" : "bg-primary-800",
                  )}
                >
                  <MobileIcon />
                  <h1 className="ml-3 text-[20px]">Mobile Number</h1>
                </div>
              </Button>
              <Button onClickHandler={() => handleMail()} className="w-64 h-auto p-5">
                <div
                  className={clsx(
                    "flex justify-between items-center rounded-lg p-5",
                    mailActive ? "bg-primary-300 text-white" : "bg-primary-800",
                  )}
                >
                  <MailIcon />
                  <h1 className="text-[20px]">Mail</h1>
                </div>
              </Button>
            </div>
            <div
              onClick={handleDoLaterA2Form}
              className="mt-10 flex flex-col items-center cursor-pointer"
            >
              <h1 className="text-primary font-semibold text-[20px]">Do it later</h1>
              <ErrorField errorMessage={error} />
            </div>
          </div>
        </div>
      )}
      {step === "2" && (
        <div className="flex flex-col items-center py-10">
          <div className="w-2/5 mb-10">
            {mobileActive ? (
              <h1 className="text-center text-[15px] lg:text-[20px] text-primary font-semibold">
                A link has been sent to your registered mobile number. Please draw your signature
                and submit the same
              </h1>
            ) : (
              <h1 className="text-center text-[15px] lg:text-[20px] text-primary font-semibold">
                A link has been sent to your registered email Id. Please draw your signature and
                submit the same
              </h1>
            )}
          </div>
          <div className="mb-10">
            <h1 className="text-[15px] lg:text-[20px] font-semibold">
              Note- Please draw your signature as on your PAN card
            </h1>
          </div>
          <div className="mb-5">
            <h1 className="text-[15px] lg:text-[20px] font-semibold">Awaiting your signature.</h1>
          </div>
          <div className="flex space-x-2 animate-pulse mb-10">
            <div className="w-3 h-3 bg-gray-500 rounded-full"></div>
            <div className="w-3 h-3 bg-gray-500 rounded-full"></div>
            <div className="w-3 h-3 bg-gray-500 rounded-full"></div>
          </div>
          <button
            onClick={mobileActive ? handleMobileNumber : handleMail}
            className="border border-primary text-primary font-semibold py-2 px-4 rounded-full text-[15px] lg:text-[20px]"
          >
            Resend link
          </button>
        </div>
      )}

      {step === "3" && (
        <div className="flex flex-col items-center py-10">
          <div className="mb-7">
            <h1 className="text-[20px] lg:text-[25px] font-bold">Your form has been signed</h1>
          </div>
          <div className="mb-8">
            <h1 className="text-[15px] lg:text-[20px] font-semibold">Thanks for completion!</h1>
          </div>
          <div className="mb-5">
            <SuccessIcon />
          </div>
          <div className="mb-5">
            <h1
              onClick={(e) => handleA2Form(e)}
              className="cursor-pointer text-[15px] lg:text-[20px] text-primary font-bold"
            >
              Download A2
            </h1>
          </div>
          <div>
            <h1
              onClick={() => {
                setIsEsignModal(false, setStep("1"));
              }}
              className="cursor-pointer text-[15px] lg:text-[20px] text-primary font-bold"
            >
              Go Back
            </h1>
          </div>
        </div>
      )}
    </Modal>
  );
};

export default EsignModal;
