/**
 * @description Component for using radio buttons
 * @param {number} numberOfRadioButtons - How many radio buttons do we need
 * @param {string[] | numner[]} value -  array of string or number representing what the value will be
 * @param {string} name - Name of the radio input
 * @param {string} errorMsg - What error message to be printed when needed
 * @param {string[] | number[] | React.ReactNode[]} labelName - Text to be printed besides the radio button
 * @param {boolean | boolean[]} disabled - Is the input disabled
 * @param {function | function[]} onChangeHandler - Method to handle when onChange event happens
 * @param {function | function[]} onBlur - Method to handle when onBlur event happens
 * @param {boolean} labelFirst - Flag to show text besides radio button first
 * @param {boolean} showAsColumn - Flag to show rafio buttons vertically
 * @param {string} radioClassName - Styings to be applied to individual radio button
 * @param {string} radioGroupClassName - Styings to be applied to radio group
 * @param {string} labelClassName - Styings to be applied to individual label
 * @param {string} errorMessageClassname - Styings to be applied to error message div
 * @param {boolean} checked - Flag to show which radio button is checked
 * @param {string[]} id - ID passed to the input element.
 * @returns {React.FunctionComponent<IRadioGroup.IProps>} - Returns JSX for radio group
 */
import clsx from "clsx";
import React from "react";
import ErrorField from "../ErrorField";

import { IRadio } from "./Radio";

const Radio: React.FunctionComponent<IRadio.IProps> = ({
  numberOfRadioButtons,
  value,
  name,
  errorMsg,
  labelName,
  disabled = false,
  onChangeHandler,
  onBlur,
  labelFirst,
  showAsColumn = false,
  radioClassName = "",
  radioGroupClassName = "",
  labelClassName = "",
  errorMessageClassname = "",
  checked,
  id = [],
}) => {
  return (
    <div className="flex flex-row flex-col w-fit">
      <div
        id="1"
        className={clsx(
          "flex hover:cursor-pointer",
          showAsColumn ? "flex-col justify-center" : "items-center justify-center",
          radioGroupClassName,
        )}
      >
        {Array(numberOfRadioButtons)
          .fill(undefined)
          .map((_, index) => (
            <div
              id="2"
              className={clsx(
                "flex items-center lg:px-4 py-1 rounded-lg transition-all hover:cursor-pointer",
                labelFirst ? "flex-row-reverse" : "",
              )}
              key={`${value[index]} ${index} ${labelName[index]}`}
              // onChange={Array.isArray(onChangeHandler) ? onChangeHandler[index] : onChangeHandler}
            >
              <label
                id="3"
                htmlFor={id[index]}
                // onChange={Array.isArray(onChangeHandler) ? onChangeHandler[index] : onChangeHandler}
                className="flex items-center align-center"
              >
                <input
                  className={clsx(
                    "mr-6 accent-primary hover:cursor-pointer md:w-4 md:h-4 min-w-2 min-h-2",
                    radioClassName,
                  )}
                  type="radio"
                  name={name}
                  disabled={Array.isArray(disabled) ? disabled[index] : disabled}
                  onChange={
                    Array.isArray(onChangeHandler) ? onChangeHandler[index] : onChangeHandler
                  }
                  value={value[index]}
                  onBlur={Array.isArray(onBlur) ? onBlur[index] : onBlur}
                  checked={checked}
                  id={id[index]}
                />
                <span className={clsx("mr-14 flex items-center align-center", labelClassName)}>
                  {labelName[index]}
                </span>
              </label>
            </div>
          ))}
      </div>
      {errorMsg && <ErrorField errorMessage={errorMsg} errorClassname={errorMessageClassname} />}
    </div>
  );
};
export default Radio;
