import { Axios } from "../axios";
import ConfirmationProps from "./confirmation";

export const paymentReview = async (body: ConfirmationProps.PaymentReviewProps, currentUser: string) => {
  // window.console.log("CURRENT USER", currentUser);
  if (currentUser !== null && currentUser !== undefined) {
    return await Axios.post(`/remit/bank/payment-review`, body, {
      headers: {
        Authorization: `${currentUser}`,
      },
    });
  }
};
