import React from "react";
import { IIcons } from "./icons";

const Four = ({ fillColor }: IIcons.IconProps) => (
  <svg
    width="86"
    height="87"
    viewBox="0 0 86 87"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    className={fillColor}
  >
    <path
      d="M64.57 64.6966C76.3789 52.8045 76.3789 33.5236 64.57 21.6315C52.761 9.73941 33.6149 9.73941 21.8059 21.6315C9.9969 33.5236 9.9969 52.8045 21.8059 64.6966C33.6149 76.5887 52.761 76.5887 64.57 64.6966Z"
      fill="#FFC32F"
    />
    <path
      d="M59.0069 17.1833C62.0187 21.9168 63.7641 27.547 63.7641 33.5844C63.7641 50.4 50.2269 64.036 33.5253 64.036C27.7251 64.036 22.3081 62.3891 17.707 59.5383C23.0815 67.9872 32.4859 73.5852 43.1886 73.5852C59.8867 73.5852 73.4274 59.9527 73.4274 43.1336C73.4274 32.1554 67.6592 22.5384 59.0104 17.1797L59.0069 17.1833Z"
      fill="#FFAB06"
    />
    <path
      d="M17.0611 46.3814C17.0611 30.7662 29.6299 18.1089 45.136 18.1089C54.1998 18.1089 62.2561 22.4352 67.3893 29.1478C62.5399 20.674 53.4548 14.9688 43.0465 14.9688C27.5404 14.9688 14.9717 27.626 14.9717 43.2412C14.9717 49.7288 17.1427 55.7055 20.7931 60.4747C18.4198 56.3271 17.0611 51.515 17.0611 46.3849V46.3814Z"
      fill="#FFD77F"
    />
    <path
      d="M54.622 47.0426C54.9377 47.0069 55.1186 47.3999 55.1151 47.6928C55.1009 49.0468 54.7426 50.4293 54.0508 51.8011C53.3945 53.0622 52.4545 54.234 51.3299 55.1914C49.0985 57.0705 46.1328 58.153 43.192 58.1637C40.1979 58.2209 37.2073 57.1348 34.9973 55.2093C32.702 53.2337 31.3043 50.4258 31.2618 47.7C31.2582 47.3892 31.464 46.9855 31.8081 47.0498C32.0919 47.0998 32.3225 47.4892 32.5318 47.6642C32.8546 47.9357 33.188 48.1894 33.5321 48.4323C34.2168 48.9182 34.9334 49.354 35.6748 49.7506C38.3993 51.2153 41.4856 52.0048 44.579 51.7333C46.5479 51.5618 48.46 50.9652 50.216 50.0578C51.2696 49.5148 52.27 48.8646 53.2101 48.1394C53.5684 47.8643 54.1537 47.1284 54.5936 47.0426C54.6042 47.0426 54.6113 47.0426 54.622 47.0391V47.0426Z"
      fill="#5E4939"
    />
    <path
      d="M33.9224 37.7207C33.9224 39.8106 32.9256 41.504 31.6946 41.504C30.4636 41.504 29.4668 39.8106 29.4668 37.7207C29.4668 35.6308 30.4636 33.9375 31.6946 33.9375C32.9256 33.9375 33.9224 35.6308 33.9224 37.7207Z"
      fill="#303030"
    />
    <path
      d="M58.7552 38.9771C57.9002 36.8229 55.8568 35.4297 53.5545 35.4297C51.2522 35.4297 49.2763 36.7801 48.3965 38.87C48.2262 39.2701 48.361 39.7238 48.7158 39.9703C49.0741 40.2168 49.5388 40.1775 49.8509 39.8738C51.5289 38.2341 54.1789 36.9194 57.29 39.9631C57.4639 40.1346 57.6909 40.2239 57.918 40.2239C58.0918 40.2239 58.2656 40.1739 58.4217 40.0667C58.78 39.8274 58.9148 39.3773 58.7552 38.9736V38.9771Z"
      fill="#303030"
    />
    <path
      d="M67.3862 15.2031C65.1619 26.0777 56.7012 28.6356 56.7012 28.6356C56.7012 28.6356 65.1619 31.1934 67.3862 42.068C69.6105 31.1934 78.0713 28.6356 78.0713 28.6356C78.0713 28.6356 69.6105 26.0777 67.3862 15.2031V15.2031Z"
      fill="white"
    />
    <path
      d="M67.3862 15.2031C65.1619 26.0777 56.7012 28.6356 56.7012 28.6356C56.7012 28.6356 68.0567 30.311 67.3862 15.2031Z"
      fill="#F1F2F2"
    />
  </svg>
);
export default Four;
