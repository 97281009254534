import React from "react";
import { IIcons } from "./icons";

const RightNavigationIcon = ({ fillColor }: IIcons.IconProps) => (
  <svg width="33" height="33" viewBox="0 0 33 33" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      d="M18.4799 25.7398C18.3793 25.7398 18.2788 25.7166 18.1886 25.6702C17.9617 25.5593 17.8199 25.3324 17.8199 25.0798V19.7998H3.29989C2.93638 19.7998 2.63989 19.5059 2.63989 19.1398V13.8598C2.63989 13.4963 2.93638 13.1998 3.29989 13.1998H17.8199V7.91978C17.8199 7.6697 17.9617 7.44025 18.1886 7.32939C18.4129 7.21853 18.681 7.24431 18.8821 7.39642L30.1021 15.9764C30.2645 16.1002 30.3599 16.2935 30.3599 16.4998C30.3599 16.706 30.2645 16.8994 30.0995 17.0231L18.8795 25.6031C18.7635 25.6934 18.6217 25.7398 18.4799 25.7398Z"
      fill={fillColor || "#0F5BF1"}
    />
  </svg>
);
export default RightNavigationIcon;
